export function isResourceAction(action) { return action.type.match(RESOURCE_POSTFIX) }
export function isRequestAction(action) { return action.type.match(RSAA_POSTFIX.REQUEST) }
export function isSuccessAction(action) { return action.type.match(RSAA_POSTFIX.SUCCESS) }
export function isFailureAction(action) { return action.type.match(RSAA_POSTFIX.FAILURE) }

export const RSAA_POSTFIX = {
  REQUEST: '//REQUEST',
  SUCCESS: '//SUCCESS',
  FAILURE: '//FAILURE',
}

export const RESOURCE_POSTFIX = '//RESOURCE'
