import React from 'react'
import _ from 'lodash'

import {
  formatDisplayTime,
  addMinutesToTime
} from 'utils'

import SafeLink from 'components/SafeLink'

export default class ShowTemplateScheduleItem extends React.Component {

  renderMobile() {
    const tsi = this.props.template_schedule_item || {}
    return (
      <tr>
        <td>
          <strong>
            {formatDisplayTime(tsi.time)}
            {!_.isNil(tsi.duration) && tsi.duration > 0 && (
              <span>-{addMinutesToTime(tsi.time, tsi.duration)}</span>
            )}
          </strong>
        </td>
        <td>
          {tsi.label}
          {!_.isNil(tsi.band_name) && !_.isEmpty(tsi.band_name) && (
            <span>&nbsp;({tsi.band_name})</span>
          )}
        </td>
        {this.props.is_editable && (
          <td>
            {this.props.onEdit && !tsi.is_loading && (
              <a
                onClick={() => this.props.onEdit()}
                disabled={tsi.is_loading}
              ><i className='ms-2 edit-icon fi-pencil'></i></a>
            )}
            {tsi.is_loading && (
              <i className='ms-2 fi-clock'></i>
            )}
          </td>
        )}
      </tr>
    )
  }

  renderDesktop() {
    const tsi = this.props.template_schedule_item || {}
    return (
      <tr>
        <td>
          <strong>
            {formatDisplayTime(tsi.time)}
            {!_.isNil(tsi.duration) && tsi.duration > 0 && (
              <span>&nbsp;-&nbsp;{addMinutesToTime(tsi.time, tsi.duration)}</span>
            )}
          </strong>
        </td>
        {this.props.show_duration && (
          <td>
            {tsi.duration > 0 && tsi.duration}{tsi.duration > 0 && 'm'}
          </td>
        )}
        <td>{tsi.label}</td>
        <td>
          {tsi.band_name}
        </td>
        {this.props.is_editable && this.props.onEdit && !tsi.is_loading && (
          <td>
            <a
              onClick={() => this.props.onEdit()}
              disabled={tsi.is_loading}
            ><i className='edit-icon fi-pencil'></i></a>
            {tsi.is_loading && (
              <i className='fi-clock'></i>
            )}
          </td>
        )}
      </tr>
    )
  }

  render() {
    if (this.props.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

}
