import React from 'react'
import _ from 'lodash'

import Overlay from 'components/Overlay'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { linkAllGigScheduleItems } from 'actions/api/schedule_items'
import {
  dismissLinkScheduleOverlay,
  setForceEditableListReload
} from 'actions/ui'

import LoadingState from 'components/LoadingState'
import ErrorLabel from 'components/ErrorLabel'
import Selector from 'components/Selector'

import {
  getBandNamesFromScheduleItems
} from 'utils'

const initial_state = {
  is_loading: false,
  show_success: false,
  band_names: {},
}

class LinkScheduleOverlay extends LoadingState {

  constructor(props) {
    super(props)
    this.state = { ...initial_state }
  }

  resetState() {
    this.setState({ ...initial_state })
  }

  onDismiss() {
    this.props.dismissLinkScheduleOverlay()
    this.resetState()
  }

  updateBandNames(update) {
    this.setState({
      band_names: {
        ...this.state.band_names,
        ...update,
      }
    })
  }

  linkSchedule() {
    const gig_id = this.props.gig_id
    const band_names = { ...this.state.band_names }
    this.setLoading(() => this.props.linkAllGigScheduleItems(gig_id, band_names).then((res) => {
      if (!res.error) { this.props.setForceEditableListReload(true) }
      this.setState({
        is_loading: false,
        show_success: !res.error,
      })
    }))
  }

  getBandNames() {
    return getBandNamesFromScheduleItems(_.get(this, 'props.gig.schedule_items', []))
  }

  renderAssignableBands() {
    const band_names = this.getBandNames()
    const performances = _.get(this, 'props.gig.performances', [])
    const performance_options = [
      { label: '---', value: '' },
      ..._.map(performances, (p) => ({ label: p.band_name, value: p.id })),
    ]

    return _.map(band_names, (bn) => (
      <div className='mb-2'>
        <strong>{bn}</strong>
        <Selector
          name={`band_name-${bn}`}
          classes='form-select'
          options={performance_options}
          value={this.state.band_names[bn]}
          onChange={(v) => this.updateBandNames({ [bn]: v })}
          disabled={this.props.is_disabled}
        />
      </div>
    ))
  }

  render() {
    const is_hidden = _.isNil(this.props.gig_id) || this.props.server_error
    const gig = _.get(this, 'props.gig', {})
    const band_names = this.getBandNames()

    return (
      <Overlay is_hidden={is_hidden} onDismiss={() => this.onDismiss()}>
        <h4>
          Link Bands
        </h4>

        {this.state.show_success &&
          <div className='alert alert-success mt-2'>
            Bands successfully linked!
          </div>
        }

        {this.props.error_message &&
          <div className='alert alert-danger mt-2'>
            {this.props.error_message}
          </div>
        }

        {band_names.length > 0 ? (
          <>
            <div className='mb-2'>
              Connect bands to existing schedule items.
            </div>

            {this.renderAssignableBands()}

            <button
              className='btn btn-primary mb-0'
              onClick={() => this.linkSchedule()}
              disabled={this.state.is_loading}
            >Link Schedule</button>
          </>
        ) : (
          <div className='alert alert-warning'>
            You have no unlinked band names in your current schedule.  To add a custom band name, simply edit the band of a schedule item and select the "- Enter Band Name" option.
          </div>
        )}
      </Overlay>
    )
  }
}

export default connect((state, props) => {
  const gig_id = _.get(state, 'overlay.link_schedule_overlay.gig_id')
  const gig = selectModel('gigs', gig_id, Resources.gig, state)
  return {
    gig_id,
    gig,
    api_errors: state.api.errors,
    server_error: state.api.server_error,
  }
}, (dispatch) => bindActionCreators({
  linkAllGigScheduleItems,
  dismissLinkScheduleOverlay,
  setForceEditableListReload,
}, dispatch))(LinkScheduleOverlay)
