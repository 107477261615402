import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { selectApiErrors, selectAuthContext } from 'selectors'
import { getPerson } from 'actions/api/people'
import { registerPerson } from 'actions/api/users'

import PageTitle from 'components/PageTitle'
import SafeLink from 'components/SafeLink'
import LoadingState from 'components/LoadingState'
import ErrorLabel from 'components/ErrorLabel'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'
import BottomBar from 'components/BottomBar'

import { AUTH_TYPE } from 'static/Auth'

import { isSuccessStatus } from 'utils'

const PersonRegisterPage = ({ personId, personData, registerPerson, apiErrors, history }) => {

  useEffect(() => {
    if (!_.isNil(personId)) { getPerson(personId) }
  }, [personId])

  useEffect(() => {
    setPerson({
      ...personData,
      ...person,
    })
  }, [personData])

  const [isLoading, setIsLoading] = useState(false)
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [user, setUser] = useState({})
  const [person, setPerson] = useState({
    ...personData,
    user: { },
  })

  const updatePerson = (update) => setPerson({
    ...person,
    ...update,
  })

  const updateUser = (update) => setUser({
    ...user,
    ...update,
  })

  const submit = (e) => {
    if (e) { e.preventDefault() }

    if (isLoading) { return null }

    setIsLoading(true)
    registerPerson(personId, { person, user }).then((response) => {
      setIsLoading(false)
      if (isSuccessStatus(response.payload.request_status)) {
        history.push(`/user/profile?tutorial=true`)
      }
    })
  }

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-9 col-lg-6'>
          <div className='mt-3 border bg-light p-3 rounded'>
            <div className='mb-3'>
              <PageTitle>Register</PageTitle>
            </div>

            <div className='callout'>
              <form onSubmit={(e) => submit(e)}>
                <div className='mb-3'>
                  <FormField
                    name='email'
                    label='Email'
                    placeholder='example@example.com'
                    type='email'
                    errors={apiErrors}
                    value={person.email || ''}
                    onChange={(e) => updatePerson({ email: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    name='name'
                    label='Full Name'
                    placeholder='Full Name'
                    type='text'
                    errors={apiErrors}
                    value={person.name || ''}
                    onChange={(e) => updatePerson({ name: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    name='username'
                    label='Username'
                    type='text'
                    errors={apiErrors}
                    value={person.username || ''}
                    onChange={(e) => updatePerson({ username: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    name='password'
                    label='Password'
                    type='password'
                    errors={apiErrors}
                    value={user.password || ''}
                    onChange={(e) => updateUser({ password: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    name='password_confirmation'
                    label='Password Confirmation'
                    type='password'
                    errors={apiErrors}
                    value={user.password_confirmation || ''}
                    onChange={(e) => updateUser({ password_confirmation: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormCheck
                    name='agree_to_terms'
                    errors={apiErrors}
                    checked={agreeToTerms}
                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                  >
                    By registering for Backline.me, you agree to our <SafeLink to='/terms'>terms and conditions</SafeLink> and our <SafeLink to='/privacy'>privacy policy</SafeLink>.
                  </FormCheck>
                </div>

                <input
                  className='btn btn-primary'
                  type='submit'
                  value='Register'
                  disabled={isLoading || !agreeToTerms}
                  onClick={(e) => submit(e)}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect((state, props) => {
  const auth_context = selectAuthContext(state)
  const auth_type = _.get(auth_context, 'auth_type', null)

  if (auth_type === AUTH_TYPE.PERSON) {
    return {
      personData: auth_context,
      personId: auth_context.id,
      apiErrors: selectApiErrors(state),
    }
  } else {
    return {
      personData: {},
      personId: null,
      isError: true,
      apiErrors: selectApiErrors(state),
    }
  }

}, (dispatch) => bindActionCreators({
  registerPerson
}, dispatch))(PersonRegisterPage)
