import React from 'react'

import EditArray from 'components/EditArray'
import OtherSelect from 'components/OtherSelect'

import { TICKET_TYPES } from 'static'

export default class EditTicketSales extends EditArray {

  getDataFromProps(props) {
    return props.ticket_sales
  }

  render() {
    return (
      <>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th>Ticket Type</th>
              <th width='90'>Sales</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {_.map(this.state.array, (ts, i) => (
              <TicketSaleForm
                key={`ticket-sale-${i}-${ts.id}`}
                ticket_sale={ts}
                onChange={this.genUpdateObject(i)}
                onDelete={() => this.deleteObject(i)}
              />
            ))}
          </tbody>
        </table>
        <a
          className='btn btn-primary'
          onClick={() => this.addObject()}
        >Add Tickets</a>
      </>
    )
  }

}

class TicketSaleForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      ticket_sale: props.ticket_sale || {},
    }
  }

  updateTicketSale(update, callback) {
    this.setState({
      ticket_sale: {
        ...this.state.ticket_sale,
        ...update,
      }
    }, () => {
      this.props.onChange(this.state.ticket_sale)
    })
  }

  render() {
    return (
      <tr>
        <td>
          <OtherSelect
            classes='mb-0'
            name='ticket_type'
            value={this.state.ticket_sale.ticket_type || ''}
            options={_.map(TICKET_TYPES, (tt) => {
              return { label: tt, value: tt }
            })}
            onChange={(v) => this.updateTicketSale({ ticket_type: v })}
          />
        </td>

        <td>
          <input
            className='form-control mb-0'
            type='number'
            name='tickets_sold'
            value={this.state.ticket_sale.tickets_sold || ''}
            onChange={(e) => this.updateTicketSale({ tickets_sold: e.target.value })}
          />
        </td>

        <td>
          <a onClick={() => this.props.onDelete()}>
            <i className='fi-trash delete-icon'></i>
          </a>
        </td>
      </tr>
    )
  }

}
