import { DISMISS_ERROR_OVERLAY } from 'actions/ui'
import { isFailureAction } from 'actions'

export default function serverErrorReducer(state=false, action) {
  if (action.type === DISMISS_ERROR_OVERLAY) {
    return false
  }

  if (isFailureAction(action) && action.error && action.payload.request_status === 500) {
    return true
  }

  return state
}
