import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectCurrentUser } from 'selectors'
import { bindActionCreators } from 'redux'
import { updateMembership, deleteMembership } from 'actions/api/memberships'

import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import OtherSelect from 'components/OtherSelect'
import InviteButton from 'components/invite/InviteButton'
import LoadingState from 'components/LoadingState'
import RadioButtons from 'components/RadioButtons'
import ShowEmail from 'components/people/ShowEmail'
import ShowPhone from 'components/people/ShowPhone'
import MembershipDetailsForm from 'components/memberships/MembershipDetailsForm'

import {
  INVITE_STATUS,
  PERMISSIONS_OPTIONS
} from 'static'

class MembershipEdit extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      membership: props.membership || {},
      is_expanded: false,
    }
  }

  componentWillReceiveProps(props) {
    if (_.isNil(this.state.membership.id)) {
      this.setState({ membership: props.membership || {} })
    }
  }

  isCurrentUser() {
    const current_user = this.props.current_user || {}
    const membership = this.state.membership || {}
    return membership.person_id === current_user.person_id
  }

  updateMembership(update) {
    this.setState({
      membership: {
        ...this.state.membership,
        ...update,
      }
    })
  }

  save() {
    this.setLoading(() => {
      this.props.updateMembership(this.state.membership.id, this.state.membership)
        .then((res) => {
          if (res.error) {
            this.clearLoading()
          } else {
            this.setState({
              is_loading: false,
              is_expanded: false,
              membership: {
                ...this.state.membership,
                apply_to_all: false,
              }
            })
          }
        })
    })
  }

  onDelete() {
    this.setLoading(() => this.props.deleteMembership(this.state.membership.id)
      .then((data) => this.clearLoading()))
  }

  renderEditForm() {
    const membership = this.state.membership || {}
    return (
      <div className={classnames('height-transition', { 'expanded': this.state.is_expanded })}>
        <div className='row mt-2'>
          <div className='col-lg-4 col-md-6 col-12'>
            <label>Title</label>
            <OtherSelect
              name='title'
              classes='mb-2'
              options={_.map(this.props.title_options || [], (ct) => (
                { value: ct, label: ct }
              ))}
              onChange={(v) => this.updateMembership({ title: v })}
              value={membership.title}
              disabled={this.state.is_loading}
            />
          </div>
        </div>

        <MembershipDetailsForm
          membership={membership}
          onChange={(v) => this.updateMembership(v)}
          disabled={this.state.is_loading}
          show_apply_to_all={membership.invite_status === INVITE_STATUS.ACCEPTED}
          two_column
        />

        <div className='mb-2'>
          <a
            className='btn btn-primary'
            onClick={() => this.save()}
          >save</a>
          <a
            className='btn btn-gray'
            onClick={() => this.setState({ is_expanded: false }) }
          >cancel</a>
        </div>
        {this.state.is_loading && (
          <div> <strong> loading...  </strong> </div>
        )}
      </div>
    )
  }

  render() {
    const membership = this.state.membership || {}

    return (
      <div className={classnames('list-group-item', {
        'faded': this.state.is_loading,
        'list-group-item-primary': membership.invite_status === INVITE_STATUS.PENDING,
        'list-group-item-danger': membership.invite_status === INVITE_STATUS.REJECTED,
      })}>
        <div onClick={() => this.setState({ is_expanded: !this.state.is_expanded })}>
          <div className='d-flex flex-row align-items-center'>
            <div className='flex-grow-1'>
              <span className='text-dark me-2'>
                <span className={classnames('d-inline-block', 'me-2', 'transition', {
                  'rotate-arrow-down': this.state.is_expanded
                })}>
                  <i className='fi-play'></i>
                </span>

                <Avatar
                  classes='small-avatar'
                  url={membership.avatar_url}
                  blank_icon='Person'
                />
                <strong>
                  {membership.name}
                </strong>
                {membership.username && (<span className='d-none d-md-inline-block'>&nbsp;@{membership.username}</span>)}
                {membership.title && (<i className='d-none d-md-inline-block'>&nbsp;&ndash;&nbsp;{membership.title}</i>)}
              </span>

              {!membership.is_person_owned && (
                <InviteButton
                  person={{
                    id: membership.person_id,
                    name: membership.name,
                    email: membership.email,
                  }}
                />
              )}
            </div>

            <div className='flex-grow-1 text-end'>
              {membership.invite_status === INVITE_STATUS.PENDING && (
                <><strong>(Invite Pending)</strong>&nbsp;</>
              )}

              {membership.invite_status === INVITE_STATUS.REJECTED && (
                <><strong>(Invite Rejected)</strong>&nbsp;</>
              )}

              {!this.state.is_loading && !this.isCurrentUser() && (
                <a
                  className='text-danger'
                  onClick={() => this.onDelete()}
                >
                  <i className='fi-trash delete-icon small'></i>
                </a>
              )}
            </div>
          </div>

          {(membership.username || membership.title) && (
            <div className='d-block d-md-none'>
              {membership.username && (<span>@{membership.username}</span>)}
              {membership.username && membership.title && (<span>&nbsp;&ndash;&nbsp;</span>)}
              {membership.title && (<i>{membership.title}</i>)}
            </div>
          )}
        </div>

        {this.renderEditForm()}
        {this.renderLoadingPopup()}
      </div>
    )
  }
}

export default connect((state) => ({
  current_user: selectCurrentUser(state)
}), (dispatch) => bindActionCreators({
  updateMembership, deleteMembership
}, dispatch))(MembershipEdit)
