import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import SafeLink from 'components/SafeLink'
import Avatar from 'components/Avatar'
import InviteButton from 'components/invite/InviteButton'

import {
  INVITE_STATUS
} from 'static'

export default class PersonnelList extends React.Component {

  countPresentCrew() {
    const crewable = this.props.crewable || {}
    const crew_members = crewable.crew_members || []
    const present_crew_members = _.filter(crew_members, (cm) => cm.is_on_site)
    return present_crew_members.length
  }

  getPersonUrl(person_id) {
    if (this.props.is_admin_view) {
      return `/admin/people/${person_id}`
    } else {
      return `/people/${person_id}`
    }
  }

  getCrewMembersPageUrl() {
    const crewable = this.props.crewable || {}
    if (this.props.is_admin_view) {
      return `/admin${crewable.url}/crew_members`
    } else {
      return `${crewable.url}/crew_members`
    }
  }

  render() {
    const crewable = this.props.crewable || {}
    const crew_members = crewable.crew_members || []

    if (_.isEmpty(crew_members)) {
      if (this.props.is_editable) {
        return (
          <>
            <h5>
              Personnel
            </h5>
            <SafeLink
              className='btn rounded bg-light border hover-bg-gray text-center d-block p-3 mb-3 text-decoration-none text-dark'
              to={this.getCrewMembersPageUrl()}
            >
              <h5 className='m-0'>
                <i className='fi-plus'></i> Add Personnel
              </h5>
            </SafeLink>
          </>
        )
      } else {
        return null
      }
    } else {
      return (
        <>
          <h5>
            Personnel
            {this.props.is_editable && (
              <SafeLink to={this.getCrewMembersPageUrl()}>
                <i className='ms-2 edit-icon fi-pencil'></i>
              </SafeLink>
            )}
          </h5>
          <div className='list-group list-group-striped'>
            {_.map(crew_members, (cm,i) => (
              <div
                key={`${crewable.id}-personnel-${cm.id}-${i}`}
                className={classnames('list-group-item', {
                  'list-group-item-primary': cm.invite_status === INVITE_STATUS.PENDING,
                  'list-group-item-danger': cm.invite_status === INVITE_STATUS.REJECTED,
                })}
              >
                <div className='d-flex flex-row align-items-center'>
                  <div className='flex-grow-0'>
                    <Avatar
                      url={cm.avatar_url}
                      classes='small-avatar'
                      blank_icon='Person'
                    />
                  </div>
                  <div className='flex-grow-1'>
                    <SafeLink to={this.getPersonUrl(cm.person_id)} className='text-decoration-none me-2'>
                      <strong>{cm.name}</strong>
                    </SafeLink>
                    {!this.props.hide_invite && !cm.is_person_owned && (
                      <span>
                        <InviteButton
                          person={{
                            id: cm.person_id,
                            name: cm.name,
                            email: cm.email,
                          }}
                        />
                      </span>
                    )}

                    {window.is_mobile ? (
                      <>
                        {cm.username && (<>@{cm.username}</>)}
                        {cm.title && (
                          <>
                            <br/>
                            <i>{cm.title}</i>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {cm.username && (<>@{cm.username}</>)}
                        {cm.title && (<i>&nbsp;&ndash;&nbsp;{cm.title}</i>)}
                      </>
                    )}
                  </div>

                  {cm.is_on_site && (
                    <div className='flex-grow-0'>
                      <div className='badge bg-success'>DOS</div>
                    </div>
                  )}
                </div>

              </div>
            ))}

            {/**
              <div className='item last'>
                <i>{this.countPresentCrew()} present day of show</i>
              </div>
            **/}
          </div>
        </>
      )
    }
  }
}
