import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel, selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getVenue, updateVenue } from 'actions/api/venues'

import AddressForm from 'components/addresses/AddressForm'
import LoadingState from 'components/LoadingState'
import DirectFileUploader from 'components/DirectFileUploader'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'

import SaveBar from 'components/SaveBar'
import PhoneInput from 'components/people/PhoneInput'

import { MAX_AVATAR_SIZE, MAX_CAPACITY } from 'static'

import { iff } from 'utils'

class VenueWelcomeDetailsPage extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      venue: props.venue || {},
      fileData: null,
      is_loading: false,
    }
  }

  componentDidMount() {
    this.setLoading(() => this.props.getVenue(this.props.match.params.id)
      .then((res) => {
        if (res.error) {
          this.clearLoading()
        } else {
          this.setState({ venue: res.payload.raw.venue }, () => this.clearLoading())
        }
      })
    )
  }

  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      props.getVenue(props.match.params.id)
    }

    if (_.isEmpty(this.state.venue)) {
      this.setState({ venue: props.venue || {} })
    }
  }

  updateVenue(update, callback) {
    this.setState({
      venue: {
        ...this.state.venue,
        ...update,
      }
    }, () => iff(callback))
  }

  updateCapacity(value) {
    let update_value = value
    if (value) {
      update_value = Math.round(Math.min(Math.max(value, 0), MAX_CAPACITY), 0)
    }
    this.updateVenue({ capacity: update_value })
  }

  save() {
    this.setLoading(() => {
      this.props.updateVenue(this.props.match.params.id, this.state.venue).then(
        (response) => this.clearLoading()
      )
    })
  }

  submit() {
    this.setLoading(() => {
      this.props.updateVenue(this.props.match.params.id, this.state.venue).then(
        (response) => {
          this.clearLoading()
          if (!response.error) {
            this.props.history.push(`/welcome/venues/${this.props.venue.id}/members`)
          }
        }
      )
    })
  }

  renderPublicDetails() {
    return (
      <div className='border rounded p-3 mb-3'>
        <p><i>These details are visible to all backline users, and will help other users identify your venue.</i></p>
        <div className='row'>
          <div className='col mb-3'>
            <FormField
              label='Venue Name'
              type='text'
              errors={this.props.api_errors}
              name='venue_name'
              value={this.state.venue.name || ''}
              onChange={(e) => this.updateVenue({ name: e.target.value })}
            />
          </div>

          <div className='col mb-3'>
            <FormField
              label='Capacity'
              type='number'
              name='capacity'
              errors={this.props.api_errors}
              value={this.state.venue.capacity || ''}
              onChange={(e) => this.updateCapacity(e.target.value)}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-lg-6 mb-3'>
            <label className='form-label'>Main Phone</label>
            <PhoneInput
              name='main_phone'
              value={this.state.venue.main_phone || ''}
              onChange={(v) => this.updateVenue({ main_phone: v })}
            />
          </div>

          <div className='col-12 col-lg-6 mb-3'>
            <label className='form-label'>Box Office Phone</label>
            <PhoneInput
              name='box_office_phone'
              value={this.state.venue.box_office_phone || ''}
              onChange={(v) => this.updateVenue({ box_office_phone: v })}
            />
          </div>
        </div>

        {this.props.venue.is_owned && (
          <div className='mb-3'>
            <FormField
              label='Venue Username'
              type='text'
              name='username'
              errors={this.props.api_errors}
              value={this.state.venue.username || ''}
              onChange={(e) => this.updateVenue({ username: e.target.value })}
            />
          </div>
        )}

        <div className='mb-3'>
          <label className='form-label'>Website</label>
          <div className="input-group">
            <span className="input-group-text">http://</span>
            <input
              className='form-control'
              type='text'
              name='website'
              value={this.state.venue.website || ''}
              onChange={(e) => this.updateVenue({ website: e.target.value })}
            />
          </div>
        </div>

        {this.props.venue.is_owned && (
          <div className='mb-3'>
            <FormField
              type='textarea'
              label='Description'
              name='bio'
              value={this.state.venue.bio || ''}
              onChange={(e) => this.updateVenue({ bio: e.target.value })}
            />
          </div>
        )}
      </div>
    )
  }

  renderPrivateDetails() {
    return (
      <div className='border rounded p-3 mb-3'>
        <p><i>These details will only be visible on shows that you are hosting.</i></p>
        <div className='row'>
          <div className='col mb-3'>
            <FormField
              type='text'
              label='Wifi'
              name='wifi'
              value={this.state.venue.wifi || ''}
              onChange={(e) => this.updateVenue({ wifi: e.target.value })}
            />
          </div>

          <div className='col mb-3'>
            <FormField
              type='text'
              label='Wifi-Password'
              name='wifi_password'
              value={this.state.venue.wifi_password || ''}
              onChange={(e) => this.updateVenue({ wifi_password: e.target.value })}
            />
          </div>
        </div>

        <h5>Amenities</h5>
        <div className='border rounded p-3 mb-3'>
          <FormCheck
            name='has_showers'
            type='checkbox'
            checked={this.state.venue.has_showers || false}
            onChange={(e) => this.updateVenue({ has_showers: e.target.checked })}
          >Showers</FormCheck>

          <FormCheck
            name='has_laundry'
            type='checkbox'
            checked={this.state.venue.has_laundry || false}
            onChange={(e) => this.updateVenue({ has_laundry: e.target.checked })}
          >Laundry</FormCheck>
        </div>

        <div className='mb-3'>
          <FormField
            type='textarea'
            name='parking'
            label='Parking'
            value={this.state.venue.parking || ''}
            onChange={(e) => this.updateVenue({ parking: e.target.value })}
          />
        </div>

        <div className='mb-3'>
          <FormField
            type='textarea'
            label='Load-In Info'
            name='load_in_info'
            value={this.state.venue.load_in_info || ''}
            onChange={(e) => this.updateVenue({ load_in_info: e.target.value })}
          />
        </div>

        <div className='mb-3'>
          <FormField
            type='textarea'
            name='green_room_info'
            label='Green Room Info'
            value={this.state.venue.green_room_info || ''}
            onChange={(e) => this.updateVenue({ green_room_info: e.target.value })}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        <div className='container mt-3'>
          <h2>Edit Venue - {this.state.venue.name}</h2>

          <div className='row'>
            <div className='col-12 col-lg-6'>
              <h4>Address</h4>
              <div className='border rounded p-3 mb-3'>
                <AddressForm
                  address={this.state.venue.address || {}}
                  onChange={(a) => this.updateVenue({ address: a })}
                />
              </div>

              <h4>Venue Logo</h4>
              <div className='mb-3'>
                {this.props.venue.avatar_url && (
                  <img
                    src={this.props.venue.avatar_url}
                    className='avatar'
                  />
                )}
                <DirectFileUploader
                  onUploadSuccess={(signedIds) => this.updateVenue({
                    avatar_image: _.first(signedIds)
                  }, () => this.save())}
                  setFileData={(fd) => this.setState({ fileData: fd })}
                  fileData={this.state.fileData}
                  maxFileSize={MAX_AVATAR_SIZE}
                />
              </div>

              <h4>Public Details</h4>
              {this.renderPublicDetails()}
            </div>

            <div className='col-12 col-lg-6'>
              <h4>Private Details</h4>
              {this.renderPrivateDetails()}

              <h4>Default Information</h4>
              <div className='border rounded p-3 mb-3'>
                <p><i>This information is filled out automatically on each show you host, but can be edited on a show-by-show basis.</i></p>

                <div className='mb-3'>
                  <FormField
                    type='textarea'
                    label='Merch Info'
                    name='default_merch_info'
                    value={this.state.venue.default_merch_info || ''}
                    onChange={(e) => this.updateVenue({ default_merch_info: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    type='textarea'
                    label='Hospitality Details'
                    name='hospitality_details'
                    value={this.state.venue.default_hospitality_details || ''}
                    onChange={(e) => this.updateVenue({ default_hospitality_details: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    type='textarea'
                    label='Additional Show Notes'
                    name='default_show_notes'
                    value={this.state.venue.default_show_notes || ''}
                    onChange={(e) => this.updateVenue({ default_show_notes: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
        />
      </>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
  venue: selectModel('venues', props.match.params.id, Resources.venue, state),
}), (dispatch) => bindActionCreators({
  getVenue, updateVenue
}, dispatch))(VenueWelcomeDetailsPage)
