import React from 'react'

const PreviousCountRow = ({ prevCount }) => {
  return prevCount?.counts?.map(count => {
    return (
      <tr key={count.ticket_type_name}>
        <td>{count.ticket_type_name}</td>
        <td>{count.total_sellable}</td>
        <td>${count.price.toFixed(2)}</td>
        <td>{count.count}</td>
      </tr>
    )
  })
}

const PreviousTicketCountsTable = ({ previousCounts }) => {
  return (
    <table className='table table-striped'>
      <thead>
        <tr>
          <th>Ticket Type</th>
          <th width='180'>Total Sellable</th>
          <th width='90'>Price</th>
          <th width='90'>Count</th>
        </tr>
      </thead>
      <tbody>
        <PreviousCountRow key={previousCounts?.date} prevCount={previousCounts} />
      </tbody>
    </table>
  )
}

export default PreviousTicketCountsTable
