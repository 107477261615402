import React from 'react'

import PaginatedList from 'components/PaginatedList'

export default class CachedPaginatedList extends PaginatedList {

  // Override
  getListData(props=this.props) {
    this.setLoading(() => {
      this.requestListData(this.state.page, props).then((res) => {
        if (res.error) {
          this.clearLoading()
        } else {
          const data = res.payload.raw || res.payload || {}
          this.setState({ page: data.page, is_loading: false })
        }
      })
      this.onSubmitRequest()
    })
  }

  // Override
  getNumPages() { return this.props.num_pages }

}
