import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import WelcomeNewMemberForm from 'components/welcome/WelcomeNewMemberForm'
import ShowPhone from 'components/people/ShowPhone'
import ShowEmail from 'components/people/ShowEmail'

export default class WelcomeMembersList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      expanded_member_id: null,
      show_new_form: false,
    }
  }

  selectMember(member_id) {
    if (this.state.expanded_member_id === member_id) {
      this.setState({ expanded_member_id: null })
    } else {
      this.setState({ expanded_member_id: member_id })
    }
  }

  renderNewForm() {
    if (this.state.show_new_form) {
      return (
        <div className='border rounded bg-light p-3'>
          <h5>Add A Member</h5>
          <WelcomeNewMemberForm
            parent_url={this.props.parent_url}
            title_options={this.props.title_options}
            onCancel={() => this.setState({ show_new_form: false })}
          />
        </div>
      )
    } else {
      return (
        <button
          className='btn btn-primary'
          onClick={() => this.setState({ show_new_form: true })}
        >Add Member</button>
      )
    }
  }

  render() {
    const memberships = this.props.memberships || []
    const active_memberships = _.filter(memberships, (m) => !m['_destroyed'])
    const sorted_memberships = _.sortBy(active_memberships, (m) => m.is_admin ? 0 : m.is_primary ? 1 : 2)
    return (
      <>
        <div className='list-group list-group-striped mb-3'>
          {_.map(sorted_memberships, (m, i) => (
            <div className='list-group-item' key={`membership-${m.id}-${i}`}>
              <a className='block text-dark text-decoration-none' onClick={() => this.selectMember(m.id)}>
                <span className='me-2'>
                  <span className={classnames('d-inline-block me-2 transition', {
                    'rotate-arrow-down': this.state.expanded_member_id === m.id
                  })}>
                    <i className='fi-play'></i>
                  </span>
                  <strong>{m.name}</strong>
                  {m.username && (<span className='d-none d-md-inline-block'>&nbsp;@{m.username}</span>)}
                  {m.title && (<i className='d-none d-md-inline-block'>&nbsp;&ndash;&nbsp;{m.title}</i>)}
                </span>
              </a>

              {(this.state.expanded_member_id === m.id) && (
                <>
                  {(m.username || m.title) && (
                    <div className='d-block d-md-none'>
                      {m.username && (<span>@{m.username}</span>)}
                      {m.username && m.title && (<span>&nbsp;&ndash;&nbsp;</span>)}
                      {m.title && (<i>{m.title}</i>)}
                    </div>
                  )}

                  {!_.isEmpty(m.email) && (<div><ShowEmail email={m.email} show_icon /></div>)}
                  {!_.isEmpty(m.phone) && (<div><ShowPhone number={m.phone} show_icon /></div>)}
                </>
              )}
            </div>
          ))}
        </div>
        {this.renderNewForm()}
      </>
    )
  }

}
