import React from 'react'
import _ from 'lodash'

import Overlay from 'components/Overlay'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { applyScheduleTemplateToGig } from 'actions/api/schedule_templates'
import {
  dismissApplyScheduleOverlay,
  setForceEditableListReload
} from 'actions/ui'
import { getVenue } from 'actions/api/venues'
import { getBand } from 'actions/api/bands'
import { getScheduleTemplate } from 'actions/api/schedule_templates'

import LoadingState from 'components/LoadingState'
import ErrorLabel from 'components/ErrorLabel'
import Selector from 'components/Selector'

import {
  getBandNamesFromScheduleItems
} from 'utils'

import { PERSPECTIVE_TYPE } from 'static'

const initial_state = {
  is_loading: false,
  show_success: false,
  schedule_template_id: '',
  band_names: {},
}

class ApplyScheduleOverlay extends LoadingState {

  constructor(props) {
    super(props)
    this.state = { ...initial_state }
  }

  resetState() {
    this.setState({ ...initial_state })
  }

  componentWillReceiveProps(props) {
    const old_schedule_templatable_id = _.get(this, 'props.schedule_templatable.id')
    const old_schedule_templatable_type = _.get(this, 'props.schedule_templatable.schedule_templatable_type')
    const new_schedule_templatable_id = _.get(props, 'schedule_templatable.id')
    const new_schedule_templatable_type = _.get(props, 'schedule_templatable.schedule_templatable_type')
    if (!_.isNil(new_schedule_templatable_id) && !_.isNil(new_schedule_templatable_type) && (
          old_schedule_templatable_id !== new_schedule_templatable_id ||
          old_schedule_templatable_type !== new_schedule_templatable_type
        )) {
      if (new_schedule_templatable_type === PERSPECTIVE_TYPE.VENUE) {
        this.setLoading(() => props.getVenue(new_schedule_templatable_id).then((res) => this.clearLoading()))
      } else if (new_schedule_templatable_type === PERSPECTIVE_TYPE.BAND) {
        this.setLoading(() => props.getBand(new_schedule_templatable_id).then((res) => this.clearLoading()))
      }
    }
  }

  onDismiss() {
    this.props.dismissApplyScheduleOverlay()
    this.resetState()
  }

  updateBandNames(update) {
    this.setState({
      band_names: {
        ...this.state.band_names,
        ...update,
      }
    })
  }

  selectScheduleTemplate(id) {
    if (_.isNil(id)) {
      this.setState({ schedule_template_id: id, band_names: {} })
    } else {
      this.setState({
        schedule_template_id: id,
        band_names: {},
        is_loading: true,
      }, () => this.props.getScheduleTemplate(id).then((res) => this.clearLoading()))
    }
  }

  applyScheduleTemplate() {
    const st_id = this.state.schedule_template_id
    const gig_id = this.props.gig_id
    const band_names = { ...this.state.band_names }
    this.setLoading(() => this.props.applyScheduleTemplateToGig(st_id, gig_id, band_names).then((res) => {
      if (!res.error) { this.props.setForceEditableListReload(true) }
      this.setState({
        is_loading: false,
        show_success: !res.error,
      })
    }))
  }

  renderAssignableBands() {
    const schedule_templates = _.get(this, 'props.schedule_templatable.schedule_templates', [])
    const selected_schedule_template = _.find(schedule_templates, { id: parseInt(this.state.schedule_template_id, 10) }) || {}
    const template_schedule_items = _.get(selected_schedule_template, 'template_schedule_items', [])
    const band_names = getBandNamesFromScheduleItems(template_schedule_items)

    const performances = _.get(this, 'props.gig.performances', [])
    const performance_options = [
      { label: '---', value: '' },
      ..._.map(performances, (p) => ({ label: p.band_name, value: p.id })),
    ]

    return _.map(band_names, (bn, i) => (
      <div key={`${bn}-${i}`} className='mb-3'>
        <label className='form-label'>{bn}</label>
        <Selector
          name={`band_name-${bn}`}
          classes='form-select'
          options={performance_options}
          value={this.state.band_names[bn]}
          onChange={(v) => this.updateBandNames({ [bn]: v })}
          disabled={this.props.is_disabled}
        />
      </div>
    ))
  }

  render() {
    const is_hidden = _.isNil(this.props.gig_id) || this.props.server_error
    const gig = _.get(this, 'props.gig', {})
    const schedule_templatable = _.get(this, 'props.schedule_templatable', {})
    const schedule_templates = _.get(schedule_templatable, 'schedule_templates', [])
    const template_options = _.map(schedule_templates, (st) => ({ value: st.id, label: st.name }))
    const options = [ { value: '', label: '---' }, ...template_options ]

    return (
      <Overlay is_hidden={is_hidden} onDismiss={() => this.onDismiss()}>
        <h4>
          Apply Schedule Template
        </h4>
        {schedule_templates.length > 0 ? (
          <>
            <p>
              Apply a {schedule_templatable.name} schedule template to this show!
            </p>

            <div className='alert alert-warning'>
              <strong>WARNING:</strong> This action will completely overwrite the existing schedule.
            </div>

            <div className='mb-3'>
              <label className='form-label'>Template Name</label>
              <Selector
                name='schedule_template'
                options={options}
                classes='form-select'
                value={this.state.schedule_template_id}
                onChange={(v) => this.selectScheduleTemplate(v)}
                disabled={this.props.is_disabled}
              />
              <ErrorLabel field='name' errors={this.props.api_errors} />
            </div>

            {this.renderAssignableBands()}

            <button
              className='btn btn-primary mb-0'
              onClick={() => this.applyScheduleTemplate()}
              disabled={this.state.is_loading}
            >Apply Template</button>

            {this.state.show_success &&
              <div className='alert alert-success mt-3'>
                Template applied!
              </div>
            }

            {this.props.error_message &&
              <div className='alert alert-danger mt-3'>
                {this.props.error_message}
              </div>
            }
          </>
        ) : (
          <div className='alert alert-warning'>
            You have no schedule templates.  In order to apply a schedule template, you must first create one.  Try going to the schedule of a past show and saving it as a template!
          </div>
        )}
      </Overlay>
    )
  }
}

export default connect((state, props) => {
  const gig_id = _.get(state, 'overlay.apply_schedule_overlay.gig_id')
  const gig = selectModel('gigs', gig_id, Resources.gig, state)

  let schedule_templatable = {}
  const schedule_templatable_id = _.get(state, 'overlay.apply_schedule_overlay.schedule_templatable_id')
  const schedule_templatable_type = _.get(state, 'overlay.apply_schedule_overlay.schedule_templatable_type')
  if (schedule_templatable_type === PERSPECTIVE_TYPE.VENUE) {
    schedule_templatable = selectModel('venues', schedule_templatable_id, Resources.venue, state)
  } else if (schedule_templatable_type === PERSPECTIVE_TYPE.BAND) {
    schedule_templatable = selectModel('bands', schedule_templatable_id, Resources.band, state)
  }
  // TODO -- CHECK that schedule_templatable is actually related to this gig?

  return {
    gig_id,
    gig,
    schedule_templatable,
    api_errors: state.api.errors,
    server_error: state.api.server_error,
  }
}, (dispatch) => bindActionCreators({
  applyScheduleTemplateToGig,
  dismissApplyScheduleOverlay,
  getVenue,
  getBand,
  getScheduleTemplate,
  setForceEditableListReload,
}, dispatch))(ApplyScheduleOverlay)
