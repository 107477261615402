import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel, selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getBand } from 'actions/api/bands'

import WelcomeAddMembersPage from 'components/welcome/WelcomeAddMembersPage'
import LoadingState from 'components/LoadingState'
import SaveBar from 'components/SaveBar'
import ErrorLabel from 'components/ErrorLabel'
import Row from 'components/Row'

import { PERSPECTIVE_TYPE } from 'static'

class BandWelcomeMembersPage extends LoadingState {

  componentDidMount() {
    this.setLoading(() => this.props.getBand(this.props.match.params.id).then((res) => this.clearLoading()))
  }

  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      props.getBand(props.match.params.id)
    }
  }

  submit() {
    this.props.history.push(`/welcome/bands/${this.props.band.id}/documents`)
  }

  render() {
    const band = this.props.band || {}
    const attachments = band.attachments || []

    return (
      <>
        <div className='container mt-3'>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-9'>
              <h3>
                Band Members
              </h3>

              <div>
                <WelcomeAddMembersPage page_type={PERSPECTIVE_TYPE.BAND} org={band} />
              </div>
            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
          save_text='continue'
          hide_cancel
        />
      </>
    )
  }

}

export default connect((state, props) => ({
  band: selectModel('bands', props.match.params.id, Resources.band, state),
}), (dispatch) => bindActionCreators({
  getBand
}, dispatch))(BandWelcomeMembersPage)
