import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateAttachment, deleteAttachment } from 'actions/api/attachments'

import ConfirmationButton from 'components/ConfirmationButton'
import DocumentForm from 'components/documents/DocumentForm'
import LoadingState from 'components/LoadingState'

import { iff } from 'utils'

class EditableDocument extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      is_editing: false,
      is_confirm_delete: false,
      attachment: props.attachment || {},
    }
  }

  componentWillReceiveProps(props) {
    if (!this.state.attachment.id) {
      this.setState({ attachment: props.attachment || {} })
    }
  }

  save() {
    const attachmentSaveData = _.omit(this.state.attachment, ['apply_to_all'])
    this.setLoading(() => this.props.updateAttachment(this.state.attachment.id, attachmentSaveData).then((res) => {
      if (res.error) {
        this.clearLoading()
      } else {
        this.setState({
          is_loading: false,
          attachment: {
            ...(res.payload.raw.attachment || this.state.attachment),
          },
        })
      }
    }))
  }

  submit() {
    this.setLoading(() => this.props.updateAttachment(this.state.attachment.id, this.state.attachment).then((res) => {
      if (res.error) {
        this.clearLoading()
      } else {
        this.setState({
          is_loading: false,
          is_editing: false,
          attachment: {
            ...(res.payload.raw.attachment || this.state.attachment),
            apply_to_all: false,
          },
        })
      }
    }))
  }

  onDelete() {
    this.setLoading(() => this.props.deleteAttachment(this.state.attachment.id).then((res) => {
      if (res.error) {
        this.clearLoading()
      }
    }))
  }

  updateAttachment(update, callback) {
    this.setState({
      attachment: {
        ...this.state.attachment,
        ...update,
      }
    }, () => iff(callback))
  }

  render() {
    const attachment = this.state.attachment || {}
    if (this.state.is_editing) {
      return (
        <div className='list-group-item clearfix'>
          <DocumentForm
            attachment={attachment}
            updateAttachment={(update, callback) => this.updateAttachment(update, callback)}
            onUploadSuccess={() => this.save()}
            nameOptions={this.props.name_options}
            isDisabled={this.state.is_loading}
            isShowDocumentSettings
          />
          <div>
            {!this.state.is_confirm_delete && (
              <>
                <a
                  className='btn btn-primary m-0'
                  onClick={() => this.submit()}
                  disabled={this.state.is_loading}
                >save</a>
                <a
                  className='btn btn-gray m-0'
                  onClick={() => this.setState({ is_editing: false })}
                  disabled={this.state.is_loading}
                >cancel</a>
              </>
            )}

            <ConfirmationButton
              button_classes='m-0 float-end'
              button_type='danger'
              button_text='delete'
              confirm_text='really delete?'
              onConfirm={() => this.onDelete()}
              onStateChange={(v) => this.setState({ is_confirm_delete: v })}
              disabled={this.state.is_loading}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className='list-group-item d-flex flex-row align-items-center'>
          <div className='flex-grow-1'>
            <a href={attachment.url} target='_blank' className='text-decoration-none'>
              <i className='fi-page-filled me-2'></i>{attachment.name}
            </a>
            {attachment.is_primary && (<span className='badge bg-success ms-2'>default attachment</span>)}
            {attachment.is_public && (<span className='badge bg-warning ms-2'>public</span>)}
          </div>

          {this.props.is_editable && (
            <div className='flex-grow-1 text-end'>
              <a
                onClick={() => this.setState({ is_editing: true })}
                className='float-end'
              >
                <i className='edit-icon fi-pencil'></i>
              </a>
            </div>
          )}
        </div>
      )
    }
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  updateAttachment, deleteAttachment
}, dispatch))(EditableDocument)
