import React from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { adminGetBands } from 'actions/api/admin/bands'

import BasicListPage from 'components/admin/BasicListPage'

class BandsPage extends BasicListPage {

  requestListData(page) {
    return this.props.adminGetBands(page)
  }

  getPageName() {
    return 'bands'
  }

  getItemUrl(url) {
    return `/admin${url}`
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  adminGetBands
}, dispatch))(withRouter(BandsPage))
