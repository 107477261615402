import React from 'react'
import classnames from 'classnames'

import SafeLink from 'components/SafeLink'
import Avatar from 'components/Avatar'

import {
  PERFORMANCE_TYPE_LABEL,
  PERFORMANCE_TYPES,
  PERSPECTIVE_TYPE,
  INVITE_STATUS
} from 'static'

import {
  sortPerformances
} from 'utils'

export default class PerformanceList extends React.Component {

  getPerformanceUrl(p) {
    if (this.props.admin_url) {
      return `/admin${p.url}`
    } else {
      return p.url
    }
  }

  renderBandInterior(p) {
    const performance = p || {}
    const band = performance.band || {}

    return (
      <>
        <div className='d-flex flex-row align-items-center'>
          <div className='flex-grow-0'>
            <Avatar
              url={band.avatar_url}
              classes='small-avatar'
              blank_icon='Band'
            />
          </div>

          <div className='flex-grow-1'>
            {band.name}<wbr/>
            {performance.performance_type === PERFORMANCE_TYPES.HEADLINE && (
              <span>
                &nbsp;<i className='fi-star'></i>
              </span>
            )}
            {band.username &&
              <span className='smaller-text'>
                &nbsp;(@{band.username})
              </span>
            }
          </div>

          <div className='flex-grow-0'>
            {performance.invite_status === INVITE_STATUS.PENDING && (<strong>(pending)</strong>)}
            {!this.props.not_clickable && (<i className='fi-arrow-right ms-2'></i>)}
          </div>

        </div>
      </>
    )
  }

  renderBand(p) {
    const performance = p || {}
    const band = performance.band || {}

    if (this.props.not_clickable) {
      return (
        <div
          className={classnames('list-group-item', 'list-group-item-action', 'word-wrap', {
            'list-group-item-info': performance.invite_status === INVITE_STATUS.PENDING,
            'list-group-item-danger': performance.invite_status === INVITE_STATUS.REJECTED,
          })}
          key={performance.id}
        >
          {this.renderBandInterior(p)}
        </div>
      )
    } else {
      return (
        <SafeLink
          className={classnames('list-group-item', 'list-group-item-action', 'word-wrap', {
            'list-group-item-info': performance.invite_status === INVITE_STATUS.PENDING,
            'list-group-item-danger': performance.invite_status === INVITE_STATUS.REJECTED,
          })}
          key={performance.id}
          to={this.getPerformanceUrl(p)}
        >
          {this.renderBandInterior(p)}
        </SafeLink>
      )
    }
  }

  render() {
    return (
      <div className='list-group list-group-striped'>
        {_.map(sortPerformances(this.props.performances), (p) => this.renderBand(p))}
        {this.props.is_editable && (
          <SafeLink
            className='list-group-item list-group-item-action text-center bold hover-bg-gray'
            to={`${this.props.gig_url}/performances/new`}
          ><i className='fi-plus'></i> Add a Band</SafeLink>
        )}
        {!this.props.is_editable && _.isEmpty(this.props.performances) && (
          <div className='list-group-item'>
            There are currently no performing bands.
          </div>
        )}
      </div>
    )
  }

}
