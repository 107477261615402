import { resourceRSAA, jsonRSAA } from 'utils'
import { basicGet } from 'actions/api'

export const NOTIFICATION_ACTION = 'NOTIFICATIONS'
export function getNotifications(page) {
  return basicGet(`/api/notifications`, NOTIFICATION_ACTION, {page: page})
}

export function dismissAllNotifications() {
  return jsonRSAA(NOTIFICATION_ACTION, `/api/notifications/dismiss_all`)
}

export function notificationClicked(id) {
  return jsonRSAA(NOTIFICATION_ACTION, `/api/notifications/${id}/clicked`)
}

export function getNotificationCount() {
  return jsonRSAA(NOTIFICATION_ACTION, `/api/notifications/count`)
}

