import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { adminGetPersonVisibilities } from 'actions/api/admin/people'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import PaginatedList from 'components/PaginatedList'

class VisibilitiesList extends PaginatedList {

  requestListData(page) {
    return this.props.adminGetPersonVisibilities(this.props.person_id, page)
  }

  getListFromData(data) {
    return data.data
  }

  render() {
    return (
      <div>
        {this.renderPageButtons()}
        <table className='table table-striped hover'>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Name</th>
              <th>User Name</th>
              <th>Email</th>
            </tr>
          </thead>

          <tbody>
            {_.map(this.state.list, (u) => (
              <tr key={u.id}>
                <td> {u.id} </td>
                <td> <SafeLink href={`/admin${u.person_url}`}>{u.person_name}</SafeLink> </td>
                <td> {u.username} </td>
                <td> {u.email} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  adminGetPersonVisibilities
}, dispatch))(VisibilitiesList)
