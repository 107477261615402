import React from 'react'
import classnames from 'classnames'

import SafeLink from 'components/SafeLink'


const ADMIN_LINKS = ['search', 'gigs', 'bands', 'venues', 'people', 'users']

export default class AdminPage extends React.Component {

  render() {
    return (
      <div className="container">
        <div className="row g-1">
          {ADMIN_LINKS.map(link => {
            return (
              <div className="col-12 col-md-2">
                <SafeLink
                  className={classnames('btn', 'btn-primary', 'col-12', {
                    'btn-secondary': this.props.page !== link
                  })}
                  to={`/admin/${link}`}
                >{link.toUpperCase()}</SafeLink>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

}
