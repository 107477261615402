import React from 'react'

import SafeLink from 'components/SafeLink'
import { staticAssetUrl } from 'utils'

export default () => {
  return (
    <div className='container mt-2'>

      <h1>Backline FAQ</h1>

      <h3>Personnel, Members, and Permissions</h3>

      <p className='mb-2'>
        Backline has a complex but powerful set of features for managing your band and/or venue staff.
        In this document, we'll help you understand those features so that we can save you time on
        every show advance!
      </p>

      <p>
        First let's talk about the difference between <strong>Members</strong> and <strong>Personnel</strong>.
      </p>

      <div className='row'>
        <div className='col-12 col-lg-6'>
          <div className='border rounded p-3 mb-4 bg-light'>
            <h4>Members</h4>

            <p className='mb-1'>
              <b>Members</b> are designed to help you organize the people
              who are <strong>consistently part of your venue staff or band crew</strong>.  These people might
              not work every show, but they work with you often enough that it's worth keeping their
              information on file so that they can quickly be added to any upcoming show.
            </p>

            <ul className='mb-2'>
              <li>
                <strong className='text-success'>By default, members will be able to view your organization page</strong>,
                including the <b>Public Details</b>, <b>Private Details</b>, <b>Member List</b> (with contact information),
                and all <b>Documents</b>.
              </li>
              <li>
                <strong className='text-danger'>By default, members will not see or edit any of the shows on your schedule</strong>.
              </li>
              <li>
                <strong className='text-danger'>By default, members will not be able to edit any organization information</strong>.
              </li>
              <li>
                <strong className='text-danger'>By default, members will not be able to see your Schedule Templates</strong>.
              </li>
            </ul>

            <p className='mb-0'>
              <strong>If you would like to add members</strong> or <strong>edit their settings</strong>, you'll need to
              go to the <strong>Members</strong> page. First, navigate to your user page by clicking your avatar
              on the top right corner of the screen. Then click on the band or venue for which you would like to
              add/edit <strong>Members</strong> and navigate to the <strong>Members</strong> tab.
            </p>
          </div>
        </div>

        <div className='col-12 col-lg-6'>
          <div className='border rounded p-3 mb-4 bg-light'>
            <h4>Personnel</h4>
            <p className='mb-1'>
              <b>Personnel</b> is the term we use to describe your staff or crew for any <strong>one specific show</strong>.
              It is likely that on most nights your <b>Personnel</b> will consist of people who
              are also <b>Members</b> of your organization.
            </p>

            <ul className='mb-2'>
              <li>
                <strong className='text-success'>By default, personnel will only be able to view the "Public" information
                on your organization page</strong>. This includes your <strong>Public Details</strong>, any&nbsp;
                <strong>Members</strong> who are marked <strong>Public</strong>, and any <strong>Documents</strong>&nbsp;
                that are marked as <strong>Public</strong>.
              </li>
              <li>
                <strong className='text-success'>By default, personnel will only be able to view the most basic show information</strong>.
                This includes the show date, public venue information, and the names of the bands involved.
              </li>
              <li>
                <strong className='text-danger'>By default, personnel will not be able to edit any show information</strong>.
              </li>
            </ul>

            <p className='mb-0'>
              <strong>If you would like to add personnel</strong> or <strong>edit their settings</strong>, you'll need to
              go to the <strong>Personnel</strong> page.  First, find the show for which you would like to
              edit your <strong>Personnel</strong> list and click on your band or venue. Then click the pencil icon
              (<i className='edit-icon fi-pencil'></i>) next to either <strong>Personnel</strong> or <strong>Contacts</strong>.
            </p>
          </div>
        </div>
      </div>


      <div className='alert alert-primary'>
        <i className='fi-info'></i>&nbsp;
        The <strong>Quick Add Members</strong> feature allows you to quickly add <strong>Members</strong>
        &nbsp;of your organization to your <strong>Personnel</strong> list with their default settings.
      </div>


      <div className='row'>
        <div className='col-12 col-lg-6'>
          <div className='border rounded p-3 mb-4 bg-light'>
            <h4>Member Attributes</h4>

            <p>
              These attributes allow every member of your organization to see and edit only
              the information that you want them to be able to access. Let's get into what all of the terms mean.
            </p>

            <div className='accordion' id='memberAttributesAccordion'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='memberAdminHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#memberAdminContent"
                    aria-expanded="false"
                    aria-controls="memberAdminContent"
                  >Admin</button>
                </h2>
                <div
                  id='memberAdminContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="memberAdminHeader"
                >
                  <div className='accordion-body'>
                    Members that are marked as <strong>Admin</strong> will
                    be able to edit any aspect of the band or venue profile, including the Public and Private Details, the
                    Members and their Permissions, the Documents, and their Schedule Templates.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='memberDefaultPersonnelHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#memberDefaultPersonnelContent"
                    aria-expanded="false"
                    aria-controls="memberDefaultPersonnelContent"
                  >Default Personnel</button>
                </h2>
                <div
                  id='memberDefaultPersonnelContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="memberDefaultPersonnelHeader"
                >
                  <div className='accordion-body'>
                    Members that are marked as Default Personnel will <strong>automatically
                    be added to the Personnel List</strong> of this organization on
                    any future show they create or join.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='memberPublicHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#memberPublicContent"
                    aria-expanded="false"
                    aria-controls="memberPublicContent"
                  >Public</button>
                </h2>
                <div
                  id='memberPublicContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="memberPublicHeader"
                >
                  <div className='accordion-body'>
                    Members that are marked as Public <strong>will have their name and contact information
                    visible on your band or venue page</strong> for all users, even for users who are
                    not a member of your organization. Any non-public members of your
                    organization will only be visible to other members of your organization.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='memberInternalNotesHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#memberInternalNotesContent"
                    aria-expanded="false"
                    aria-controls="memberInternalNotesContent"
                  >Internal Notes</button>
                </h2>
                <div
                  id='memberInternalNotesContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="memberInternalNotesHeader"
                >
                  <div className='accordion-body'>
                    Internal Notes is an editable text field on a show that is <strong>only visible to
                    members of your organization that have this attribute</strong>.  Every band
                    or venue associated with a show has their own independent "Internal Notes" field
                    which only the members of your organization will be able to view or edit.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='memberCanViewAllShowsHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#memberCanViewAllShowsContent"
                    aria-expanded="false"
                    aria-controls="memberCanViewAllShowsContent"
                  >Can View All Shows</button>
                </h2>
                <div
                  id='memberCanViewAllShowsContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="memberCanViewAllShowsHeader"
                >
                  <div className='accordion-body'>
                    Users who have this attribute will be able to see all of the shows that your
                    organization is advancing, <strong>even if they are not on your Personnel List
                    for that show</strong>. The permissions this member has for viewing and/or editing this
                    show are based on the <strong>Default Permissions</strong> set for them as a member.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='memberDefaultAttributeHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#memberDefaultAttributeContent"
                    aria-expanded="false"
                    aria-controls="memberDefaultAttributeContent"
                  >Default Personnel Attributes</button>
                </h2>
                <div
                  id='memberDefaultAttributeContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="memberDefaultAttributeHeader"
                >
                  <div className='accordion-body'>
                    <p className='mb-2'>
                      There is a section titled "Default Personnel Attributes".
                      These attribute settings allow you to determine the initial state of the
                      <strong>Personnel Attributes</strong> of any member of your organization when
                      you <strong>initially add them to the Personnel List for one of your
                      show advances</strong>.
                    </p>
                    <p className='mb-0'>
                      This is useful for members of your organization that consitently play the same
                      role in your show advances. For example, you might want someone who always
                      works the door to have "Guest List Permissions" and "Present Day of Show"
                      automatically checked off every time you add them to an advance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-12 col-lg-6'>
          <div className='border rounded p-3 mb-4 bg-light'>
            <h4>Personnel Attributes</h4>

            <p>
              These attributes allow every member of your organization to see and edit only
              the information that you want them to be able to access. Let's get into what all of the terms mean.
            </p>

            <div className='accordion' id='personnelAttributesAccordion'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='personnelContactHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#personnelContactContent"
                    aria-expanded="false"
                    aria-controls="personnelContactContent"
                  >Show Contact</button>
                </h2>
                <div
                  id='personnelContactContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="personnelContactHeader"
                >
                  <div className='accordion-body'>
                    Users that are marked as "Show Contact" will be listed as contacts on the show summary,
                    and will have their contact information made available to any Backline users who are participating
                    in the advance for that show.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='personnelPresentDayOfShowHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#personnelPresentDayOfShowContent"
                    aria-expanded="false"
                    aria-controls="personnelPresentDayOfShowContent"
                  >Present Day of Show</button>
                </h2>
                <div
                  id='personnelPresentDayOfShowContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="personnelPresentDayOfShowHeader"
                >
                  <div className='accordion-body'>
                    This attribute indicates to other parties on the advance that this member
                    of your staff or crew will be present at the event on the day of the show.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='personnelShowDetailPermissionsHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#personnelShowDetailPermissionsContent"
                    aria-expanded="false"
                    aria-controls="personnelShowDetailPermissionsContent"
                  >Show Detail Permissions</button>
                </h2>
                <div
                  id='personnelShowDetailPermissionsContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="personnelShowDetailPermissionsHeader"
                >
                  <div className='accordion-body'>
                    <p className='mb-2'>
                      This permission field allows the user to view or edit most basic information
                      on the advance for which they are listed as personnel. If they do not have either
                      view or edit privileges, they will not be able to see any of this information when
                      they view the advance page.
                    </p>
                    <ul>
                      <li>Public Notes</li>
                      <li>Schedule</li>
                      <li>Band and Venue Documents</li>
                      <li>Band and Venue Personnel</li>
                      <li>Venue - Merch Info</li>
                      <li>Venue - Hospitality Details</li>
                      <li>Venue - Additional Show Notes</li>
                      <li>Band - Number of Comps Allowed and Used</li>
                      <li>Band - Production Details</li>
                      <li>Band - Transportation</li>
                      <li>Band - Additional Show Notes</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='personnelGuestListPermissionsHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#personnelGuestListPermissionsContent"
                    aria-expanded="false"
                    aria-controls="personnelGuestListPermissionsContent"
                  >Guest List Permissions</button>
                </h2>
                <div
                  id='personnelGuestListPermissionsContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="personnelGuestListPermissionsHeader"
                >
                  <div className='accordion-body'>
                    <p className='mb-2'>
                      This permission field allows the user to view or edit the guest list
                      on the advance for which they are listed as personnel. If they do not
                      have either view or edit privileges, they will not be able to see the guest list
                      at all.
                    </p>
                    <p className='mb-0'>
                      Users who are on the Personnel List for a supporting band will <strong>
                      only have access to their own guest list</strong>.  Users who are on the
                      Personnel List for a <strong>venue or headlining band will have access to all
                      guest lists</strong> associated with the advance.
                    </p>
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='personnelTicketCountPermissionsHeader'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle="collapse"
                    data-bs-target="#personnelTicketCountPermissionsContent"
                    aria-expanded="false"
                    aria-controls="personnelTicketCountPermissionsContent"
                  >Ticket Count Permissions</button>
                </h2>
                <div
                  id='personnelTicketCountPermissionsContent'
                  className="accordion-collapse collapse"
                  aria-labelledby="personnelTicketCountPermissionsHeader"
                >
                  <div className='accordion-body'>
                    <p className='mb-2'>
                      This permission field allows the user to view or edit the ticket counts
                      on the advance for which they are listed as personnel. If they do not
                      have either view or edit privileges, they will not be able to see the
                      ticket counts for this advance.
                    </p>
                    <p className='mb-0'>
                      Only users who are on the Personnel List for the <strong>venue or the headlining
                      band will have access to the ticket counts</strong>. Users who are listed as
                      Personnel for a supporting band will not have access to ticket counts
                      regardless of how this permission field is set.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
