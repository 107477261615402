export const MAX_CAPACITY = 8388607

export const VENUE_CREW_MEMBER_TITLES = [
  'Manager',
  'Production Manager',
  'Stage Manager',
  'Sound Guy',
  'Lighting Guy',
]

export const VENUE_CONTACT_TITLES = [
  'Manager',
  'Production Manager',
  'Stage Manager',
  'Sound Guy',
  'Lighting Guy',
]

export const VENUE_DOCUMENT_NAMES = [
  'Gear List',
  'Stage Plot',
  'Lighting Plot',
  'General Info',
  'Parking Map',
  'Production Specs',
  'Local Services',
]
