import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { Switch, Route, withRouter } from 'react-router'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'

import {
  adminGetPerson,
  adminDeletePerson
} from 'actions/api/admin/people'

import {
  adminDeleteUser
} from 'actions/api/admin/users'

import LoadingState from 'components/LoadingState'

import AdminHeader from 'components/admin/AdminHeader'
import PageTitle from 'components/PageTitle'
import RenderValue from 'components/RenderValue'
import ConfirmationButton from 'components/ConfirmationButton'
import SafeLink from 'components/SafeLink'
import Avatar from 'components/Avatar'

import CrewMembersList from 'components/admin/CrewMembersList'
import MembershipList from 'components/admin/MembershipList'
import VisibilitiesList from 'components/admin/VisibilitiesList'
import EmailInvitesList from 'components/admin/EmailInvitesList'

const PATH_BASE = '/admin/people/:id'

const PAGES = {
  MEMBERSHIPS: 'memberships',
  CREW_MEMBERS: 'crew_members',
  VISIBILITIES: 'visibilities',
  EMAIL_INVITES: 'email_invites',
}

class AdminPersonPage extends LoadingState {

  componentWillMount() {
    this.props.adminGetPerson(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    let matched = false
    _.each(PAGES, (page) => {
      if (this.props.match.params.page === page) { matched = true }
    })

    if (!matched) {
      const id = this.props.match.params.id
      this.props.history.replace(`/admin/people/${id}/${PAGES.MEMBERSHIPS}`)
    }

    if (props.match.params.id !== this.props.match.params.id) {
      this.props.adminGetPerson(props.match.params.id)
    }
  }

  deletePerson() {
    this.setLoading(() => {
      this.props.adminDeletePerson(this.props.match.params.id).then(
        (response) => {
          this.clearLoading()
          if (!response.error) {
            this.props.history.push('/admin')
          }
        }
      )
    })
  }

  deleteUser() {
    this.setLoading(() => {
      this.props.adminDeleteUser(this.props.person.user_id).then(() => this.clearLoading())
    })
  }

  render() {
    const person = this.props.person || {}
    const path = `/admin/people/${person.id}`
    const page = this.props.match.params.page
    return (
      <div className="container">
        <AdminHeader/>
        <div className='clearfix'>
          <Avatar
            url={person.avatar_url}
            classes='float-left mt-2'
          />
          <PageTitle
            edit_link={`/admin${person.url}/edit`}
            subtitle={person.username && (
              <h5>@{person.username}</h5>
            )}
            is_editable
          >
            {person.name}
          </PageTitle>
        </div>

        <SafeLink to={person.url}>See their page on Backline</SafeLink>

        {person.is_test && (
          <div className='test-data'>
            TEST DATA
          </div>
        )}

        <h4>Details</h4>
        <div className='card p-3 mb-3'>
          <RenderValue value={person.user_id} label='User ID' always_show />
          <RenderValue value={person.user_email} label='User Email' always_show />
          <RenderValue value={person.email} label='Email' always_show />
          <RenderValue value={person.phone} label='Phone' always_show />
        </div>

        <div>
          <SafeLink
            className={classnames({
              btn: true,
              'btn-primary': true,
              'btn-secondary': page !== PAGES.MEMBERSHIPS,
            })}
            to={`${path}/${PAGES.MEMBERSHIPS}`}
          >MEMBERSHIPS</SafeLink>
          <SafeLink
            className={classnames({
              btn: true,
              'btn-primary': true,
              'btn-secondary': page !== PAGES.CREW_MEMBERS,
            })}
            to={`${path}/${PAGES.CREW_MEMBERS}`}
          >PERSONNEL</SafeLink>
          <SafeLink
            className={classnames({
              btn: true,
              'btn-primary': true,
              'btn-secondary': page !== PAGES.VISIBILITIES,
            })}
            to={`${path}/${PAGES.VISIBILITIES}`}
          >VISIBILITIES</SafeLink>
          <SafeLink
            className={classnames({
              btn: true,
              'btn-primary': true,
              'btn-secondary': page !== PAGES.EMAIL_INVITES,
            })}
            to={`${path}/${PAGES.EMAIL_INVITES}`}
          >EMAIL INVITES</SafeLink>
        </div>

        {!_.isEmpty(person) && (
          <Switch>
            <Route path={`${PATH_BASE}/${PAGES.MEMBERSHIPS}`} render={() => (
              <MembershipList memberships={person.memberships} />
            )} />
            <Route path={`${PATH_BASE}/${PAGES.CREW_MEMBERS}`} render={() => (
              <CrewMembersList person_id={person.id} />
            )} />
            <Route path={`${PATH_BASE}/${PAGES.VISIBILITIES}`} render={() => (
              <VisibilitiesList person_id={person.id} />
            )} />
            <Route path={`${PATH_BASE}/${PAGES.EMAIL_INVITES}`} render={() => (
              <EmailInvitesList person_id={person.id} />
            )} />
          </Switch>
        )}


        {this.props.api_errors.base && (
          <div className='card bg-danger bg-opacity-25 p-3 mt-3 mb-3'>
            {this.props.api_errors.base}
          </div>
        )}

        <div>
          {person.is_owned && (
            <p>
              <strong>NOTE:</strong> Deleting an owned person will also delete their user.  However, deleting a user will not also delete the person.  If you're unsure of what this means, please ask someone before using this tool.
            </p>
          )}
          {person.is_owned && (
            <span className='me-3'>
              <ConfirmationButton
                button_type="danger"
                button_text="Delete User"
                confirm_text="Really Delete User"
                onConfirm={() => this.deleteUser()}
                disabled={this.state.is_loading}
              />
            </span>
          )}

          <ConfirmationButton
            button_type="danger"
            button_text="Delete Person"
            confirm_text="Really Delete Person"
            onConfirm={() => this.deletePerson()}
            disabled={this.state.is_loading}
          />
        </div>
      </div>
    )
  }

}

export default connect((state, ownProps) => {
  const api = state.api || {}
  return {
    api_errors: api.errors,
    person: selectModel('people', ownProps.match.params.id, Resources.gig, state),
  }
}, (dispatch) => bindActionCreators({
  adminGetPerson,
  adminDeletePerson,
  adminDeleteUser
}, dispatch))(withRouter(AdminPersonPage))
