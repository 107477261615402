import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { adminGetPersonCrewMembers } from 'actions/api/admin/people'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import PaginatedList from 'components/PaginatedList'

class CrewMembersList extends PaginatedList {

  requestListData(page) {
    return this.props.adminGetPersonCrewMembers(this.props.person_id, page)
  }

  getListFromData(data) {
    return data.data
  }

  render() {
    return (
      <div>
        {this.renderPageButtons()}
        <table className='table table-striped hover'>
          <thead>
            <tr>
              <th>Gig ID</th>
              <th>Gig Date</th>
              <th>Personnel For</th>
              <th>Type</th>
              <th>Invite Status</th>
              <th>Created</th>
            </tr>
          </thead>

          <tbody>
            {_.map(this.state.list, (c) => {
              const gig = c.gig || {}
              return (
                <tr key={c.id}>
                  <td> <SafeLink href={`/admin${gig.url}`}>{gig.id}</SafeLink> </td>
                  <td> {moment(c.gig.show_date).format("MM/DD/YYYY")} </td>
                  <td> {c.crewable.owner_name} </td>
                  <td> {c.crewable_type} </td>
                  <td> {c.invite_status} </td>
                  <td> {moment(c.created_at).fromNow()} </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  adminGetPersonCrewMembers
}, dispatch))(CrewMembersList)
