import _ from 'lodash'

import {
  PERFORMANCE_TYPES
} from 'static'

import {
  timeToMinutes
} from 'utils'

export function sortPerformances(ps) {
  const headliners = _.filter(ps, (p) => p.performance_type === PERFORMANCE_TYPES.HEADLINE)
  const support = _.filter(ps, (p) => p.performance_type === PERFORMANCE_TYPES.SUPPORT)

  return _.sortBy(headliners, (h) => -timeToMinutes(h.set_time))
    .concat(_.sortBy(support, (s) => -timeToMinutes(s.set_time)))
}

export function countGuestList(gls) {
  return _.reduce(gls, (sum, gl) => {
    if (gl.num_tickets) {
      return sum + parseInt(gl.num_tickets, 10)
    } else {
      return sum
    }
  }, 0)
}
