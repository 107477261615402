import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import OtherSelect from 'components/OtherSelect'
import Row from 'components/Row'

import { GUEST_TYPES, MAX_NUM_TICKETS } from 'static'

export default class GuestListSpotForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      guest_list_spot: props.guest_list_spot || {},
    }
  }

  componentDidMount() {
    if (!window.is_mobile) {
      this.first_input.focus()
    }
  }

  updateNumTickets(value) {
    this.updateGuestListSpot({
      num_tickets: Math.round(Math.max(Math.min(value, MAX_NUM_TICKETS), 0), 0) // 0 < value < MAX_DURATION
    })
  }

  updateGuestListSpot(update) {
    this.setState({
      guest_list_spot: {
        ...this.state.guest_list_spot,
        ...update,
      }
    }, () => this.props.onUpdate(this.state.guest_list_spot))
  }

  save(e) {
    if (e) {
      e.preventDefault()
    }

    if (this.props.onSave) {
      this.props.onSave(this.state.guest_list_spot)
    }
  }

  destroy() {
    if (this.props.onDelete) {
      this.props.onDelete()
    }
  }

  renderDesktop() {
    return (
      <tr>
        <td>
          <form onSubmit={(e) => this.save(e)} className='mb-0'>
            <input
              ref={(ref) => this.first_input = ref}
              className='mb-0 form-control'
              type='number'
              name='num_tickets'
              value={this.state.guest_list_spot.num_tickets || ''}
              onChange={(e) => this.updateNumTickets(e.target.value)}
              disabled={this.props.is_disabled}
            />
          </form>
        </td>

        <td>
          <form onSubmit={(e) => this.save(e)} className='mb-0'>
            <input
              className='mb-0 form-control'
              type='text'
              name='name'
              value={this.state.guest_list_spot.name || ''}
              onChange={(e) => this.updateGuestListSpot({ name: e.target.value })}
              disabled={this.props.is_disabled}
            />
          </form>
        </td>

        <td>
          <OtherSelect
            classes='mb-0'
            name='guest_type'
            options={_.map(GUEST_TYPES, (gt) => (
              { value: gt, label: gt }
            ))}
            value={this.state.guest_list_spot.guest_type}
            onChange={(v) => this.updateGuestListSpot({ guest_type: v })}
            disabled={this.props.is_disabled}
          />
        </td>

        <td>
          <form onSubmit={(e) => this.save(e)} className='mb-0'>
            <input
              className='mb-0 form-control'
              type='text'
              name='notes'
              value={this.state.guest_list_spot.notes || ''}
              onChange={(e) => this.updateGuestListSpot({ notes: e.target.value })}
              disabled={this.props.is_disabled}
            />
          </form>
        </td>

        <td>{ moment(this.state.guest_list_spot.created_at).format("MM/DD/YYYY") }</td>

        <td>
          <a onClick={() => this.save()}>
            <i className='fi-check icon-base success-color'></i>
          </a>

          <a onClick={() => this.destroy()}>
            <i className='fi-trash ms-2 delete-icon'></i>
          </a>
        </td>
      </tr>
    )
  }

  renderMobile() {
    return (
      <div className='list-group-item bg-light p-3'>
        <div className='row'>
          <div className='col-12'>
            <input
              ref={(ref) => this.first_input = ref}
              type='text'
              name='name'
              placeholder='Name'
              className='form-control mb-2'
              value={this.state.guest_list_spot.name || ''}
              onChange={(e) => this.updateGuestListSpot({ name: e.target.value })}
              disabled={this.props.is_disabled}
            />
          </div>

          <div className='col-3'>
            <input
              type='number'
              placeholder='# Tickets'
              name='num_tickets'
              className='form-control mb-2'
              value={this.state.guest_list_spot.num_tickets || ''}
              onChange={(e) => this.updateGuestListSpot({ num_tickets: e.target.value })}
              disabled={this.props.is_disabled}
            />
          </div>

          <div className='col-9'>
            <OtherSelect
              name='guest_type'
              className='form-control mb-2'
              options={_.map(GUEST_TYPES, (gt) => (
                { value: gt, label: gt }
              ))}
              value={this.state.guest_list_spot.guest_type}
              onChange={(v) => this.updateGuestListSpot({ guest_type: v })}
              placeholder='Type'
              disabled={this.props.is_disabled}
            />
          </div>
          <div className='col-12'>
            <input
              type='text'
              name='notes'
              placeholder='Notes'
              className='form-control mb-2'
              value={this.state.guest_list_spot.notes || ''}
              onChange={(e) => this.updateGuestListSpot({ notes: e.target.value })}
              disabled={this.props.is_disabled}
            />
          </div>
        </div>

        <div className='d-flex flex-row'>
          <div className='flex-grow-1'>
            <a
              className='btn btn-success'
              onClick={() => this.save()}
            >Save</a>
          </div>

          <div className='flex-grow-1 text-end'>
            <a
              className='btn btn-danger'
              onClick={() => this.destroy()}
            >Delete</a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

}
