export const BAND_CREW_MEMBER_TITLES = [
  'Tour Manager',
  'Production Manager',
  'Manager',
  'Agent',
  'Musician',
]

export const BAND_CONTACT_TITLES = [
  'Tour Manager',
  'Production Manager',
  'Manager',
  'Agent',
  'Musician',
]

export const BAND_DOCUMENT_NAMES = [
  'Stage Plot',
  'Rider',
]
