import { resourceRSAA } from 'utils'

export const LOCATION_ACTION = 'LOCATION'

export function getLocation(id) {
  return resourceRSAA(LOCATION_ACTION, `/api/locations/${id}`)
}

export function updateLocation(id, data) {
  return resourceRSAA(LOCATION_ACTION, `/api/locations/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ location: data }),
  })
}

export function deleteLocation(id) {
  return resourceRSAA(LOCATION_ACTION, `/api/locations/${id}`, {
    method: 'DELETE',
  })
}

export function createLocation(gig_id, data) {
  return resourceRSAA(LOCATION_ACTION, `/api/gigs/${gig_id}/locations/`, {
    method: 'POST',
    body: JSON.stringify({ location: data }),
  })
}

export function acceptLocation(id) {
  return resourceRSAA(LOCATION_ACTION, `/api/locations/${id}/accept`)
}

export function rejectLocation(id) {
  return resourceRSAA(LOCATION_ACTION, `/api/locations/${id}/reject`)
}

export function generateLocationAdvanceLink(id) {
  return resourceRSAA(LOCATION_ACTION, `/api/locations/${id}/advance_link`)
}

export function sendLocationAdvanceLink(id, data) {
  return resourceRSAA(LOCATION_ACTION, `/api/locations/${id}/send_advance_link`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}
