import React from 'react'
import _ from 'lodash'


export default ({ errors, field }) => {
  if (!errors || !field) {
    return null
  }

  let errorMessage = errors[field]

  if (_.isArray(errorMessage)) {
    errorMessage = _.first(errorMessage)
  }

  if (!_.isNil(errorMessage)) {
    return (
      <div className='invalid-feedback d-block'>{errorMessage}</div>
    )
  }

  return null
}
