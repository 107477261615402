import { resourceRSAA } from 'utils'

export const TEMPLATE_SCHEDULE_ITEM_ACTION = 'TEMPLATE_SCHEDULE_ITEM'
export function createTemplateScheduleItem(id, data) {
  return resourceRSAA(TEMPLATE_SCHEDULE_ITEM_ACTION, `/api/schedule_templates/${id}/template_schedule_items`, {
    method: 'POST',
    body: JSON.stringify({ template_schedule_item: data }),
  })
}

export function getTemplateScheduleItem(id) {
  return resourceRSAA(TEMPLATE_SCHEDULE_ITEM_ACTION, `/api/template_schedule_items/${id}`)
}

export function updateTemplateScheduleItem(id, data) {
  return resourceRSAA(TEMPLATE_SCHEDULE_ITEM_ACTION, `/api/template_schedule_items/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ template_schedule_item: data }),
  })
}

export function deleteTemplateScheduleItem(id) {
  return resourceRSAA(TEMPLATE_SCHEDULE_ITEM_ACTION, `/api/template_schedule_items/${id}`, {
    method: 'DELETE',
  })
}
