import _ from 'lodash'

import { isSuccessAction, isRequestAction } from 'actions'

import {
  LOGOUT_ACTION, LOGIN_ACTION,
  CONFIRM_EMAIL_ACTION, REGISTER_ACTION,
  CLAIM_INVITE_ACTION
} from 'actions/api/users'

const DEFAULT_STATE = { pages: [], num_pages: 0 }

export default function generateListReducer(list_action, entity_name) {
  return (state={...DEFAULT_STATE}, action) => {
    // LOGIN REQUESTS wipes list
    if ((action.type.match(LOGIN_ACTION) ||
          action.type.match(CONFIRM_EMAIL_ACTION) ||
          action.type.match(REGISTER_ACTION) ||
          action.type.match(CLAIM_INVITE_ACTION)
        ) && isRequestAction(action) && isSuccessAction(action)) {
      return {...DEFAULT_STATE}
    }

    // LOGOUT SUCCESS wipes list
    if (action.type.match(LOGOUT_ACTION) && isSuccessAction(action)) {
      return {...DEFAULT_STATE}
    }

    if (action.type.match(list_action) && isSuccessAction(action) &&
        action.payload) {

      const {
        entities,
        raw: { num_pages, page }
      } = action.payload

      const new_pages = [...state.pages]
      new_pages[page] = _.keys(entities[entity_name])

      return {
        pages: new_pages,
        num_pages,
      }
    }

    return state
  }
}
