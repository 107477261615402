import React from 'react'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { withRouter } from 'react-router'
import { Resources } from 'schema'
import { bindActionCreators } from 'redux'
import {
  adminGetVenueUpcomingGigs,
  adminGetVenuePreviousGigs
} from 'actions/api/admin/venues'

import OrganizationGigsList from 'components/admin/OrganizationGigsList'

class VenueGigsList extends OrganizationGigsList {

  requestListData(page) {
    if (this.isPast()) {
      return this.props.adminGetVenuePreviousGigs(this.props.venue_id, page)
    } else {
      return this.props.adminGetVenueUpcomingGigs(this.props.venue_id, page)
    }
  }

}

export default connect((state, ownProps) => ({
  org: selectModel('venues', ownProps.venue_id, Resources.venue, state)
}), (dispatch) => bindActionCreators({
  adminGetVenueUpcomingGigs,
  adminGetVenuePreviousGigs,
}, dispatch))(withRouter(VenueGigsList))
