import React from 'react'

export default class RedirectBase extends React.Component {

  componentWillReceiveProps(props) {
    props.history.push(this.getRedirectUrl())
  }

  // Override
  getRedirectUrl() {
    throw "Please define getRedirectUrl() for class extending RedirectBase"
  }

  render() {
    return null
  }

}
