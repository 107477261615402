import _ from 'lodash'

import {
  CREWABLE_TYPE,
  INVITE_STATUS,
  PERFORMANCE_TYPES,
  EDITABLE_PERMISSIONS,
  VIEWABLE_PERMISSIONS
} from 'static'

export function isViewable(permission) {
  if (_.isNil(permission)) { return false }
  return _.indexOf(VIEWABLE_PERMISSIONS, permission) >= 0
}

export function isEditable(permission) {
  if (_.isNil(permission)) { return false }
  return _.indexOf(EDITABLE_PERMISSIONS, permission) >= 0
}

export function isEditableByPerspective(perspective, perf_or_loc, permission) {
  return isEditable(permission) && (
    (
      perspective.id === perf_or_loc.owner_id &&
      perspective.type === perf_or_loc.owner_type
    ) || (
      !perf_or_loc.is_owned &&
      perspective.is_gig_owner
    ) || (
      perf_or_loc.invite_status === INVITE_STATUS.PENDING &&
      perspective.is_gig_owner
    ) || (
      perf_or_loc.crewable_type === CREWABLE_TYPE.PERFORMANCE &&
      perf_or_loc.performance_type === PERFORMANCE_TYPES.SUPPORT &&
      perspective.is_gig_owner
    )
  )
}
