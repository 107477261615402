import React, { useState } from 'react'
import _ from 'lodash'

import NewFinancialDocumentForm from 'components/documents/NewFinancialDocumentForm'
import EditableFinancialDocument from 'components/documents/EditableFinancialDocument'

const FinancialDocumentsList = ({ gig }) => {
  const { financial_documents: financialDocuments, id: gigId } = gig || {}

  const [showNewForm, setShowNewForm] = useState(false)

    return (
      <>
        <div className='list-group list-group-striped mb-2'>
          {_.map(financialDocuments, doc => (
            <EditableFinancialDocument
              key={`financial-document-${doc.id}-gig-${gigId}`}
              financialDocument={doc}
              gig={gig}
            />
          ))}

          {showNewForm && (
            <div className='list-group-item'>
              <NewFinancialDocumentForm
                gigId={gigId}
                onUploadSuccess={() => setShowNewForm(false)}
              />
              <a
                className='btn btn-gray'
                onClick={() => setShowNewForm(false)}
              >Cancel</a>
            </div>
          )}
        </div>

        {!showNewForm && (
          <a
            className='btn btn-primary mb-0'
            onClick={() => setShowNewForm(true)}
          >+ Add Document</a>
        )}
      </>
    )
}

export default FinancialDocumentsList
