import React from 'react'
import _ from 'lodash'

import EditableField from 'components/EditableField'

import { MAX_NUM_DAY_OF_SHOW } from 'static'

export default class EditableNumDayOfShow extends EditableField {

  getValueFromProps(props) {
    return props.num_day_of_show || 0
  }

  getPostData() {
    return { num_day_of_show: this.state.value }
  }

  // Override
  onChange(num) {
    let update_value = num
    if (num) {
      update_value = Math.round(Math.max(Math.min(num, MAX_NUM_DAY_OF_SHOW), 0), 0)
    }
    this.setState({ value: update_value })
  }

  render() {
    if (this.state.is_editing && this.props.is_editable) {
      return (
        <div className='my-2'>
          <input
            className='form-control d-inline-block num-comps-input'
            ref={(ref) => this.input = ref}
            type='number'
            name='num_day_of_show'
            value={this.state.value}
            onChange={(e) => this.onChange(e.target.value)}
            onBlur={(e) => this.onBlur(e.target.value)}
          />

          <a
            key='save'
            onClick={() => this.save()}>
            <i className='fi-check icon-base ms-2 success-color'></i>
          </a>
        </div>
      )
    } else {
      return (
        <span>
          Day of Show Personnel:&nbsp;{this.state.value}
          {this.props.is_editable && (
            <a
              key='edit'
              onClick={() => this.onEdit()}
            ><i className='ms-2 edit-icon fi-pencil'></i></a>
          )}
        </span>
      )
    }
  }
}
