import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Resources } from 'schema'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { createCrewMember } from 'actions/api/crew_members'
import { getBand } from 'actions/api/bands'
import { getVenue } from 'actions/api/venues'

import LoadingState from 'components/LoadingState'
import SafeLink from 'components/SafeLink'
import Avatar from 'components/Avatar'

import {
  CREWABLE_TYPE
} from 'static'

import { iff } from 'utils'

class QuickAddCrewMembers extends LoadingState {

  componentWillMount() {
    const crewable = this.props.crewable || {}
    if (!_.isNil(crewable.band_id)) {
      this.setLoading(() => this.props.getBand(crewable.band_id).then((res) => this.clearLoading()))
    } else if (!_.isNil(crewable.venue_id)) {
      this.setLoading(() => this.props.getVenue(crewable.venue_id).then((res) => this.clearLoading()))
    }
  }

  genAddCrewMember(person_id) {
    const crewable = this.props.crewable || {}
    return () => {
      if (!this.state.is_loading) {
        this.setLoading(() =>
          this.props.createCrewMember(crewable.url, { person_id }).then((res) => this.clearLoading()))
      }
    }
  }

  render() {
    const crewable = this.props.crewable || {}
    const crew_members = crewable.crew_members || []
    const active_crew_members = _.filter(crew_members, (cm) => !cm['_destroyed'])
    const crew_member_person_ids = _.map(active_crew_members, (cm) => cm.person_id)

    const joinable = this.props.joinable || {}
    const memberships = joinable.memberships || []
    const active_memberships = _.filter(memberships, (m) => !m['_destroyed'])
    const unused_memberships = _.filter(active_memberships, (m) => _.indexOf(crew_member_person_ids, m.person_id) === -1)
    const sorted_memberships = _.sortBy(unused_memberships, ['title', 'name'])

    return (
      <>
        <h5>Quick Add Members</h5>

        <div className='list-group list-group-striped mb-3'>
          {_.map(sorted_memberships, (m) => (
            <a
              key={`quick-add-membership-${m.id}-person-${m.person_id}`}
              className='list-group-item list-group-item-action pointer'
              onClick={this.genAddCrewMember(m.person_id)}
            >
              <span className='text-dark me-2'>
                <i className='fi-plus'></i>
              </span>

              {m.avatar_url && (
                <>
                  <Avatar
                    classes='small-avatar'
                    url={m.avatar_url}
                  />
                </>
              )}

              <strong>{m.name}</strong>

              <span className='text-dark d-none d-md-inline'>
                {m.username && (<>&nbsp;@{m.username}</>)}
                {m.title && (<i>&nbsp;&ndash;&nbsp;{m.title}</i>)}
              </span>

              {(m.username || m.title) && (
                <div className='d-md-none text-dark'>
                  {m.username && (<span>@{m.username}</span>)}
                  {m.username && m.title && (<span>&nbsp;&ndash;&nbsp;</span>)}
                  {m.title && (<i>{m.title}</i>)}
                </div>
              )}
            </a>
          ))}

          {_.isEmpty(sorted_memberships) && (
            <div className='padding text-center gray-bg'>
              You have no members eligible for Quick Add.  To learn more about how
              to quick add members, see the <SafeLink to='/help/personnel'>Personnel</SafeLink> help page.
            </div>
          )}
        </div>
        {this.renderLoadingPopup()}
      </>
    )
  }

}

export default connect((state, props) => {
  const crewable = props.crewable || {}
  let joinable = {}
  if (crewable.crewable_type === CREWABLE_TYPE.PERFORMANCE) {
    joinable = selectModel('bands', crewable.band_id, Resources.band, state)
  } else if (crewable.crewable_type === CREWABLE_TYPE.LOCATION) {
    joinable = selectModel('venues', crewable.venue_id, Resources.venue, state)
  }
  return { crewable, joinable }
}, (dispatch) => bindActionCreators({
  createCrewMember, getBand, getVenue
}, dispatch))(QuickAddCrewMembers)
