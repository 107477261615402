import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectCurrentUser } from 'selectors'
import moment from 'moment'

import ShowAddress from 'components/addresses/ShowAddress'
import SafeLink from 'components/SafeLink'

import {
  MAPS_URL_BASE
} from 'static'

import {
  getDriveDistance
} from 'utils'

import {
  createOrUpdateDrivingDistance,
} from 'actions/api/driving_distances'

class LodgingGigPanel extends React.Component {

  constructor(props) {
    super(props)
    this.state = this.getStateFromProps(props)
  }

  getStateFromProps(props = this.props) {
    const gig = props.gig || {}
    const location = gig.location || {}
    const venue = location.venue || {}
    const venue_address = venue.address || {}
    const lodging = this.props.lodging || {}
    const lodging_address = lodging.address || {}
    const lodging_id = lodging?.id || 0
    const drive_time = gig.driving_distances?.find(dd => dd.lodging_id === lodging_id)?.drive_time

    return {
      showDriveTime: this.canShowDriveTime(props.current_user?.id),
      drivingDistance: drive_time,
      lodging_address,
      venue_address,
    }
  }

  canShowDriveTime(userId) {
    if (!window.maps_api_available) return false

    return window.drive_time_user_ids?.includes(userId)
  }

  fetchDriveTime(venue, lodging, onDriveTimeFetched) {
    const originString = venue.address_string
    const destinationString = lodging.address_string

    getDriveDistance(
      originString,
      destinationString,
      onDriveTimeFetched,
    )
  }

  componentDidMount() {
    if (this.state.drivingDistance || !this.state.showDriveTime) return null

    this.fetchDriveTime(this.state.venue_address, this.state.lodging_address, driveTime => {
      this.setState({ drivingDistance: driveTime })
      this.props.createOrUpdateDrivingDistance(this.state.lodging_address.id, this.state.venue_address.id, driveTime)
    })
  }

  renderGigDirections() {
    if (!this.state.venue_address.address_string) { return null }

    const directions_link = `${MAPS_URL_BASE}/dir/?api=1&origin=${encodeURI(this.state.venue_address.address_string)}&destination=${encodeURI(this.state.lodging_address.address_string)}`

    return (
      <div className='half-margin-bottom'>
        <a target='_blank' href={directions_link}>Get Directions</a>
        {this.state.showDriveTime && this.state.drivingDistance && (
          <div>Estimated Drive Time: {this.state.drivingDistance} minutes</div>
        )}
      </div>
    )
  }

  render() {
    const lodging = this.props.lodging || {}
    const address = lodging.address || {}
    return (
      <div>
        <SafeLink
          className='float-end'
          to={`${lodging.url}/edit`}
        >edit</SafeLink>

        <h5>
          <SafeLink to={lodging.url}>
            {lodging.name}
          </SafeLink>
        </h5>

        <a href={address.maps_url} target="_blank" className='d-block mb-2'>
          <ShowAddress address={address} />
        </a>

        <div className='row mb-2'>
          <div className='col'> Check In </div>
          <div className='col'> Check Out </div>
        </div>

        <strong>
          <div className='row mb-2'>
            <div className='col'> {moment(lodging.check_in_date).format('ddd, MMM Do')} </div>
            <div className='col'> {moment(lodging.check_out_date).format('ddd, MMM Do')} </div>
          </div>
        </strong>

        <div className='prewrap'>
          {lodging.notes}
        </div>

        {this.renderGigDirections()}
      </div>
    )
  }

}

export default connect((state, _) => {
  return { current_user: selectCurrentUser(state) }
}, (dispatch) => bindActionCreators({
  createOrUpdateDrivingDistance,
}, dispatch))(LodgingGigPanel)
