import React from 'react'
import _ from 'lodash'

import { Switch, Route } from 'react-router-dom'

import { AUTH_TYPE } from 'static/Auth'

import AdvanceIntro from 'components/advance/AdvanceIntro'
import PerformanceAdvanceDetails from 'components/performances/PerformanceAdvanceDetails'
import PerformanceAdvanceComplete from 'components/performances/PerformanceAdvanceComplete'
import LocationAdvanceDetails from 'components/locations/LocationAdvanceDetails'
import LocationAdvanceComplete from 'components/locations/LocationAdvanceComplete'

import AdvanceDocumentsPage from 'components/documents/AdvanceDocumentsPage'
import AdvanceCrewMembersPage from 'components/crew_members/advance/AdvanceCrewMembersPage'
import AdvanceSchedulePage from 'components/schedule/AdvanceSchedulePage'
import AdvanceGuestListPage from 'components/guest_list/AdvanceGuestListPage'
import AdvanceRegisterPage from 'components/login/AdvanceRegisterPage'

import TicketCountInputForm from 'components/ticket_count/TicketCountInputForm'

import NonUserGigSummary from 'components/gigs/NonUserGigSummary'
import PersonRegisterPage from 'components/login/PersonRegisterPage'

import UnauthorizedPage from 'components/UnauthorizedPage'
import NotFoundPage from 'components/NotFoundPage'

export default class AuthLinkRoutes extends React.Component {

  render() {
    if (_.isNil(_.get(this.props, 'auth_context.id'))) {
      return this.props.children || null
    }

    const auth_type = _.get(this.props, 'auth_context.auth_type')
    if (auth_type === AUTH_TYPE.PERFORMANCE) {
      return (
        <Switch>
          <Route exact path='/performances/:id/advance' component={AdvanceIntro} />
          <Route exact path='/performances/:id/advance/details' component={PerformanceAdvanceDetails} />
          <Route exact path='/performances/:id/advance/documents' component={AdvanceDocumentsPage} />
          <Route exact path='/performances/:id/advance/crew_members' component={AdvanceCrewMembersPage} />
          <Route exact path='/performances/:id/advance/guest_list' component={AdvanceGuestListPage} />
          <Route exact path='/performances/:id/advance/summary' component={PerformanceAdvanceComplete} />
          <Route exact path='/performances/:id/advance/register' component={AdvanceRegisterPage} />
          <Route exact path='/unauthorized' component={UnauthorizedPage} />
          <Route exact path='/not_found' component={NotFoundPage} />
          <Route render={() => this.props.children} />
        </Switch>
      )
    } else if (auth_type === AUTH_TYPE.LOCATION) {
      return (
        <Switch>
          <Route exact path='/locations/:id/advance' component={AdvanceIntro} />
          <Route exact path='/locations/:id/advance/details' component={LocationAdvanceDetails} />
          <Route exact path='/locations/:id/advance/documents' component={AdvanceDocumentsPage} />
          <Route exact path='/locations/:id/advance/crew_members' component={AdvanceCrewMembersPage} />
          <Route exact path='/locations/:id/advance/schedule' component={AdvanceSchedulePage} />
          <Route exact path='/locations/:id/advance/summary' component={LocationAdvanceComplete} />
          <Route exact path='/locations/:id/advance/register' component={AdvanceRegisterPage} />
          <Route exact path='/unauthorized' component={UnauthorizedPage} />
          <Route exact path='/not_found' component={NotFoundPage} />
          <Route render={() => this.props.children} />
        </Switch>
      )
    } else if (auth_type === AUTH_TYPE.TICKET_INPUTTER) {
      return (
        <Switch>
          <Route exact path='/gigs/:gig_id/ticket_counts/input' component={TicketCountInputForm} />
          <Route render={() => this.props.children} />
        </Switch>
      )

    } else if (auth_type === AUTH_TYPE.PERSON) {
      return (
        <Switch>
          <Route exact path='/gigs/:id' component={NonUserGigSummary} />
          <Route exact path='/register_person' component={PersonRegisterPage} />
          <Route render={() => this.props.children} />
        </Switch>
      )

    }

    return this.props.children || null
  }
}
