import React from 'react'

import Row from 'components/Row'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from 'actions/api/users'

class UnauthorizedPage extends React.Component {

  render() {
    return (
      <Row>
        <div className='callout alert'>
          You do not have access to the page you are attempting to view. If you think you're logged in as the wrong person,&nbsp;
          <a onClick={() => this.props.logout()}>
            click here to logout
          </a>.
        </div>
      </Row>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  logout
}, dispatch))(UnauthorizedPage)
