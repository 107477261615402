import _ from 'lodash'

export * from './TimeUtil'
export * from './UrlUtil'
export * from './PerformanceUtil'
export * from './ActionUtil'
export * from './PermissionsUtil'
export * from './ScheduleUtil'
export * from './TicketUtil'
export * from './AddressUtil'
export * from './ScheduleApprovalUtil'

export function iff(f, ...args) {
  if (_.isFunction(f)) {
    f.apply(null, args)
  }
}
