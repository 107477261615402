import { resourceRSAA } from 'utils'

export const LODGING_ACTION = 'LODGING'
export function getLodging(id) {
  return resourceRSAA(LODGING_ACTION, `/api/lodgings/${id}`)
}

export function updateLodging(id, data) {
  return resourceRSAA(LODGING_ACTION, `/api/lodgings/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ lodging: data }),
  })
}

export function createLodging(band_id, data) {
  return resourceRSAA(LODGING_ACTION, `/api/bands/${band_id}/lodgings`, {
    method: 'POST',
    body: JSON.stringify({ lodging: data }),
  })
}

export function deleteLodging(id) {
  return resourceRSAA(LODGING_ACTION, `/api/lodgings/${id}`, {
    method: 'DELETE',
  })
}

export const UPCOMING_LODGINGS_ACTION = 'UPCOMING_LODGINGS_ACTION'
export function getUpcomingLodgings(page) {
  return resourceRSAA(UPCOMING_LODGINGS_ACTION, `/api/lodgings?page=${page}`)
}

export const PAST_LODGINGS_ACTION = 'PAST_LODGINGS_ACTION'
export function getPastLodgings(page) {
  return resourceRSAA(PAST_LODGINGS_ACTION, `/api/lodgings?page=${page}&is_past=true`)
}

