import React from 'react'
import _ from 'lodash'
import Linkify from 'react-linkify'

import { Switch, Route } from 'react-router-dom'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel, selectCurrentUser } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getVenue } from 'actions/api/venues'
import { leaveJoinable } from 'actions/api/memberships'

import Tabs from 'components/Tabs'
import ShowAddress from 'components/addresses/ShowAddress'
import ShowPhone from 'components/people/ShowPhone'
import ShowContacts from 'components/contacts/ShowContacts'
import OrgMembersPage from 'components/OrgMembersPage'
import EditableDocumentsList from 'components/documents/EditableDocumentsList'
import ScheduleTemplateList from 'components/schedule_templates/ScheduleTemplateList'
import RenderValue from 'components/RenderValue'
import InviteHeader from 'components/InviteHeader'
import GigsList from 'components/gigs/GigsList'
import PageTitle from 'components/PageTitle'
import SafeLink from 'components/SafeLink'
import ConfirmationButton from 'components/ConfirmationButton'
import LoadingState from 'components/LoadingState'
import Avatar from 'components/Avatar'
import VenueSharedGigs from 'components/venues/VenueSharedGigs'

import {
  formatUrl
} from 'utils'

import {
  VENUE_DOCUMENT_NAMES,
  PERSPECTIVE_TYPE
} from 'static'

import { format, parse } from 'libphonenumber-js'

class VenuePage extends LoadingState {

  componentWillMount() {
    this.setLoading(() => this.props.getVenue(this.props.match.params.id)
      .then(() => this.clearLoading()))
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      props.getVenue(props.match.params.id)
    }
  }

  onLeave() {
    const venue = this.props.venue || {}
    this.setLoading(() => this.props.leaveJoinable(venue.url)
      .then((res) => this.clearLoading(() => {
        if (!res.error) {
          this.props.history.push('/')
        }
      })))
  }

  renderVenueDetails() {
    const venue = this.props.venue || {}
    return (
      <div className='p-2'>
        <div className='row'>
          <div className='col-12 col-md-6 mb-3'>
            <h5>Public Details</h5>
            <div className='border rounded p-3'>
              <a
                target="_blank"
                className='mb-3 d-block text-decoration-none'
                href={venue.address && venue.address.maps_url}
              > <ShowAddress address={venue.address} /> </a>

              <div className='mb-3'>
                {venue.main_phone && (
                  <div> <strong>Main Phone:</strong> <ShowPhone number={venue.main_phone}/> </div>
                )}
                {venue.box_office_phone && (
                  <div> <strong>Box Office Phone:</strong> <ShowPhone number={venue.box_office_phone}/> </div>
                )}
                <RenderValue value={venue.capacity} label='Capacity' />
              </div>

              <a
                target="_blank"
                href={formatUrl(venue.website)}
              >{venue.website}</a>
              <Linkify properties={{ target: '_blank' }}>
                <p className='prewrap mb-0'>{_.trim(venue.bio)}</p>
              </Linkify>
            </div>
          </div>

          <div className='col-12 col-md-6 mb-3'>
            <h5>Private Details</h5>
            <div className='border rounded p-3'>
              <RenderValue value={venue.wifi} label='Wifi' />
              <RenderValue value={venue.wifi_password} label='Wifi Password' />
              <RenderValue linkify class_name='prewrap mb-3' value={venue.parking} label='Parking' />
              <RenderValue linkify class_name='prewrap mb-3' value={venue.load_in_info} label='Load-In Info' />
              <RenderValue linkify class_name='prewrap mb-3' value={venue.green_room_info} label='Green Room Info' />
              {venue.has_showers && (
                <div><strong>Showers Available</strong></div>
              )}
              {venue.has_laundry && (
                <div><strong>Laundry Available</strong></div>
              )}
            </div>
          </div>

          {!_.isNil(venue.default_merch_info) && (
            <div className='col-12 col-md-6 mb-3'>
              <h5>Default Merch Info</h5>
              <div className='border rounded p-3'>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap mb-0'>{_.trim(venue.default_merch_info)}</p>
                </Linkify>
              </div>
            </div>
          )}

          {!_.isNil(venue.default_hospitality_details) && (
            <div className='col-12 col-md-6 mb-3'>
              <h5>Default Hospitality Details</h5>
              <div className='border rounded p-3'>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap mb-0'>{_.trim(venue.default_hospitality_details)}</p>
                </Linkify>
              </div>
            </div>
          )}

          {!_.isNil(venue.default_show_notes) && (
            <div className='col-12 col-md-6 mb-3'>
              <h5>Default Show Notes</h5>
              <div className='border rounded p-3'>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap mb-0'>{_.trim(venue.default_show_notes)}</p>
                </Linkify>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const venue = _.get(this, 'props.venue', {})
    const memberships = _.get(venue, 'memberships', [])
    const attachments = _.get(venue, 'attachments', [])
    const schedule_templates = _.get(venue, 'schedule_templates', [])

    if (_.isNil(venue.id)) { return null }

    return (
      <div className='container'>
        <InviteHeader invite={venue.pending_invite} />

        <div className='d-flex flex-row align-items-center mb-3 mt-2'>
          <div className='flex-grow-0'>
            <Avatar
              url={venue.avatar_url}
              classes='float-left half-margin-top'
            />
          </div>
          <div className='flex-grow-1'>
            <PageTitle
              is_editable={venue.is_editable}
              edit_link={`${venue.url}/edit`}
              subtitle={venue.username &&
                <h5>@{venue.username}</h5>
              }
            >
              {venue.name}
            </PageTitle>
          </div>
        </div>

        <Tabs tabs={[
          {
            name: 'Details',
            url: venue.url,
            show_tab: true
          }, {
            name: 'Members',
            url: `${venue.url}/members`,
            show_tab: memberships.length > 0
          }, {
            name: 'Documents',
            url: `${venue.url}/documents`,
            show_tab: attachments.length > 0 || venue.is_admin,
          }, {
            name: 'Schedule Templates',
            url: `${venue.url}/schedule_templates`,
            show_tab: venue.is_admin,
          }, {
            name: 'Shared Shows',
            url: `${venue.url}/shared_gigs`,
            show_tab: !venue.is_member,
          }
        ]} />

        <div className='mt-3 mb-4'>
          <Switch>
            {(memberships.length > 0 || venue.is_admin) && (
              <Route exact path='/venues/:id/members' render={() => (
                <div className='p-2'>
                  {venue.is_admin ? (
                    <OrgMembersPage page_type={PERSPECTIVE_TYPE.VENUE} org={venue} />
                  ) : (
                    <ShowContacts
                      contacts={memberships}
                      show_admin={venue.is_editable}
                      show_primary={venue.is_editable}
                      show_public={venue.is_editable}
                      show_invite={venue.is_editable}
                      show_avatar
                      split_large
                      hide_title
                    />
                  )}
                </div>
              )} />
            )}
            {(attachments.length > 0 || venue.is_admin) && (
              <Route exact path='/venues/:id/documents' render={() => (
                <div className='half-padding'>
                  <EditableDocumentsList
                    attachable={venue}
                    attachments={attachments}
                    name_options={VENUE_DOCUMENT_NAMES}
                    is_editable={venue.is_admin}
                  />
                </div>
              )} />
            )}
            {venue.is_admin && (
              <Route exact path='/venues/:id/schedule_templates' render={() => (
                <div className='half-padding'>
                  <ScheduleTemplateList
                    venue={venue}
                    schedule_templates={schedule_templates}
                    is_editable={venue.is_admin}
                  />
                </div>
              )} />
            )}
            {!venue.is_member && (
              <Route exact path='/venues/:id/shared_gigs' render={() => (
                <div className='half-padding'>
                  <VenueSharedGigs venue_id={venue.id} />
                </div>
              )} />
            )}
            <Route render={() => this.renderVenueDetails()} />
          </Switch>
        </div>

        {venue.is_member && (
          <ConfirmationButton
            button_classes='m-0'
            button_type='danger'
            button_text='Leave Venue'
            confirm_text='Really Leave?'
            onConfirm={() => this.onLeave()}
            disabled={this.state.is_loading}
          />
        )}
      </div>
    )
  }

}

export default connect((state, ownProps) => ({
  current_user: selectCurrentUser(state),
  venue: selectModel('venues', ownProps.match.params.id, Resources.venue, state),
}), (dispatch) => bindActionCreators({
  getVenue, leaveJoinable
}, dispatch))(withRouter(VenuePage))
