
export const MAX_DURATION = 1440

export const SCHEDULE_LABELS = {
  SET: 'Set',
  DOORS: 'Doors',
  CURFEW: 'Curfew',
  LOAD_IN: 'Load-In',
  SOUNDCHECK: 'Soundcheck',
}
