import React from 'react'
import moment from 'moment'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getGig, updateGig, leaveGig } from 'actions/api/gigs'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { selectModel } from 'selectors'

import SafeLink from 'components/SafeLink'

import Row from 'components/Row'
import BreadCrumbs from 'components/BreadCrumbs'
import EditableGigName from 'components/gigs/editable/EditableGigName'
import EditableGigDate from 'components/gigs/editable/EditableGigDate'
import EditableGigNotes from 'components/gigs/editable/EditableGigNotes'
import EditableGigPrivateNotes from 'components/gigs/editable/EditableGigPrivateNotes'

import LoadingState from 'components/LoadingState'
import ShowScheduleItems from 'components/schedule/ShowScheduleItems'
import LocationCard from 'components/gigs/LocationCard'
import PerformanceList from 'components/performances/PerformanceList'

import ConfirmationButton from 'components/ConfirmationButton'

import LodgingGigPanel from 'components/lodgings/LodgingGigPanel'

import InviteHeader from 'components/InviteHeader'
import GigBottomBar from 'components/gigs/GigBottomBar'

import {
  INVITE_STATUS,
  PERSPECTIVE_TYPE,
  DATE_FORMAT
} from 'static'

import {
  setUrlParams,
  parseUrlParams,
  isEditable,
  isViewable,
  isSuccessStatus,
  isTicketCountsEnabledForGig
} from 'utils'

class EditableGigPage extends LoadingState {

  componentWillMount() {
    this.setLoading(() => {
      this.props.getGig(this.props.match.params.id).then(() => this.clearLoading())
    })
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setLoading(() => {
        props.getGig(props.match.params.id).then(() => this.clearLoading())
      })
    }
  }

  canViewTicketSales() {
    const gig = this.props.gig || {}
    const perspective = gig.perspective || {}
    return isViewable(perspective.ticket_sales_permissions) && perspective.is_gig_owner
  }

  updateGig(update) {
    this.setLoading(() => {
      this.props.updateGig(this.props.match.params.id, update).then(() => this.clearLoading())
    })
  }

  onLeave() {
    this.setLoading(() => {
      this.props.leaveGig(this.props.match.params.id).then(
        (response) => {
          if (isSuccessStatus(response.payload.request_status)) {
            this.props.history.push('/')
          } else {
            this.clearLoading()
          }
        }
      )
    })
  }

  renderVenue() {
    const gig = this.props.gig || {}
    const lodgings = gig.lodgings || []
    const perspective = gig.perspective || {}

    return (
      <>
        <h4>
          Venue
        </h4>
        {gig.location && gig.location.venue_id && (
          <div className={classnames('border rounded mb-3', {
            'border-primary': gig.location.invite_status === INVITE_STATUS.PENDING,
            'border-danger': gig.location.invite_status === INVITE_STATUS.REJECTED,
          })}>
            <LocationCard
              gig_id={gig.id}
              perspective={perspective}
              location={gig.location || {}}
              show_view={isViewable(perspective.gig_permissions)}
            />
          </div>
        )}

        {(!gig.location || !gig.location.venue_id) && (
          <div className='rounded bg-light border hover-bg-gray text-center p-3 mb-3'>
            {isEditable(perspective.gig_permissions) && perspective.is_gig_owner && (
              <SafeLink
                to={`${gig.url}/locations/new`}
                className='text-dark text-decoration-none'>
                <h5 className='m-0'>
                  <i className='fi-plus'></i> Add Venue
                </h5>
              </SafeLink>
            )}
            {(!isEditable(perspective.gig_permissions) || !perspective.is_gig_owner) && (
              <h4 className='m-0'> Venue TBA </h4>
            )}
          </div>
        )}
      </>
    )
  }

  render() {
    const gig = this.props.gig || {}
    const lodgings = gig.lodgings || []
    const perspective = gig.perspective || {}
    const new_lodging_url = `/new/lodging?start_date=${moment(gig.show_date).format(DATE_FORMAT)}&band_id=${perspective.id}`
    return (
      <>
        <div className='container'>
          <BreadCrumbs
            items={[
              { name: 'Shows', url: '/' },
              { name: gig.gig_name || gig.default_name },
            ]}
          />
          <InviteHeader invite={perspective.pending_invite} />

          <div className='mb-3'>
            <EditableGigName
              update={(update) => this.updateGig(update)}
              name={gig.gig_name}
              default_name={gig.default_name}
              is_editable={isEditable(perspective.gig_permissions) && perspective.is_gig_owner}
            />

            <EditableGigDate
              update={(update) => this.updateGig(update)}
              date={gig.show_date}
              is_editable={isEditable(perspective.gig_permissions) && perspective.is_gig_owner}
            />
          </div>

          <div className='mb-3'>
            {isViewable(perspective.guest_list_permissions) && (
              <SafeLink
                className='btn btn-gray me-1'
                to={`${gig.url}/guest_list`}
              >See Guest List</SafeLink>
            )}

            {this.canViewTicketSales() && isTicketCountsEnabledForGig(gig) && (
              <SafeLink
                className='btn btn-primary'
                to={`${gig.url}/ticket_counts`}
              >See Ticket Counts</SafeLink>
            )}
          </div>

          <div className='row'>
            <div className='col-12 col-md-6'>
              {isViewable(perspective.gig_permissions) && (
                <ShowScheduleItems
                  perspective={perspective}
                  gig={gig}
                  show_header
                  is_editable={isEditable(perspective.gig_permissions) && perspective.is_gig_owner}
                />
              )}

              {!isViewable(perspective.gig_permissions) && this.renderVenue()}
            </div>

            <div className='col-12 col-md-6'>
              {isViewable(perspective.gig_permissions) && this.renderVenue()}

              <h4>Bands</h4>
              <div className='mb-3'>
                <PerformanceList
                  gig_url={gig.url}
                  performances={gig.performances}
                  not_clickable={!isViewable(perspective.gig_permissions)}
                  is_editable={(isEditable(perspective.gig_permissions) && perspective.is_gig_owner)}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            {isViewable(perspective.gig_permissions) && (
              <div className='col-12 col-md-6'>
                <EditableGigNotes
                  update={(update) => this.updateGig(update)}
                  notes={gig.notes}
                  is_editable={isEditable(perspective.gig_permissions) && perspective.is_gig_owner}
                />
              </div>
            )}
            {isViewable(perspective.gig_permissions) && perspective.can_view_private_notes && (
              <div className='col-12 col-md-6'>
                <EditableGigPrivateNotes
                  update={(update) => this.updateGig(update)}
                  notes={gig.private_notes}
                  is_editable={isEditable(perspective.gig_permissions) && perspective.can_view_private_notes}
                />
              </div>
            )}
          </div>

          {perspective.type === PERSPECTIVE_TYPE.BAND && (
            <>
              <h5>
                Housing
                {!_.isEmpty(lodgings) && (
                  <SafeLink
                    className='btn btn-primary p-2 m-0 ms-2'
                    to={new_lodging_url}
                  ><i className='fi-plus'></i></SafeLink>
                )}
              </h5>
              <div className='row'>
                {_.map(lodgings, (l) => (
                  <div key={l.id} className='col-12 col-md-6'>
                    <div className='card'>
                      <div className='card-body'>
                        <LodgingGigPanel lodging={l} gig={gig} />
                      </div>
                    </div>
                  </div>
                ))}
                {_.isEmpty(lodgings) && (
                  <div className='col-12 col-md-6'>
                    <SafeLink
                      to={new_lodging_url}
                      className='btn rounded bg-light border hover-bg-gray text-center d-block p-3 mb-3 text-decoration-none text-dark'
                    >
                      <h5 className='m-0'>
                        <i className='fi-plus'></i> Add Housing
                      </h5>
                    </SafeLink>
                  </div>
                )}
              </div>
            </>
          )}

          {perspective.is_personnel && (
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='alert alert-warning'>
                  <p>
                    <strong>
                      Leaving this gig will remove you as personnel from this show.
                    </strong>
                    &nbsp;&nbsp;If you want to remove your entire band or venue from this gig, go to your performance or location details page.
                  </p>
                  <ConfirmationButton
                    button_type='danger'
                    button_text='leave gig'
                    confirm_text='really leave?'
                    onConfirm={() => this.onLeave()}
                    disabled={this.state.is_loading}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <GigBottomBar gig={gig} />

        {this.renderLoadingPopup()}
      </>
    )
  }
}

export default connect((state, ownProps) => ({
  gig: selectModel('gigs', ownProps.match.params.id, Resources.gig, state),
}), (dispatch) => bindActionCreators({
  getGig, updateGig, leaveGig
}, dispatch))(withRouter(EditableGigPage))
