import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import SafeLink from 'components/SafeLink'

export default class MembershipList extends React.Component {

  render() {
    return (
      <table className='table table-striped hover'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Org ID</th>
            <th>Name</th>
            <th>Username</th>
            <th>Type</th>
            <th>Invite</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {_.map(this.props.memberships, (m) => (
            <tr key={`membership-${m.id}`}>
              <td>{m.id}</td>
              <td><SafeLink to={`/admin${m.joinable_url}`}>{m.joinable_id}</SafeLink></td>
              <td>{m.joinable_name}</td>
              <td>{m.joinable_username}</td>
              <td>{m.joinable_type}</td>
              <td>{m.invite_status}</td>
              <td>{moment(m.created_at).fromNow()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

}
