import React from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { selectPerformanceOrLocationFromPath } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getPerformance } from 'actions/api/performances'
import { getLocation } from 'actions/api/locations'

import Row from 'components/Row'
import BreadCrumbs from 'components/BreadCrumbs'
import SafeLink from 'components/SafeLink'
import ManageCrewMembers from 'components/crew_members/ManageCrewMembers'

import {
  BAND_CREW_MEMBER_TITLES,
  VENUE_CREW_MEMBER_TITLES,
  CREWABLE_TYPE
} from 'static'

import { isEditable } from 'utils'
import LoadingState from 'components/LoadingState'

class CrewMembersPage extends LoadingState {

  componentWillMount() {
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      this.props.getPerformance(this.props.match.params.id).then((res) => this.handleResponse(res))
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      this.props.getLocation(this.props.match.params.id).then((res) => this.handleResponse(res))
    }
  }

  render() {
    const crewable = this.props.crewable || {}
    const gig = crewable.gig || {}
    const perspective = gig.perspective || {}
    let titles = []
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      titles = BAND_CREW_MEMBER_TITLES
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      titles = VENUE_CREW_MEMBER_TITLES
    }

    const is_crewable_owner = perspective.relation_type === this.props.page_type && perspective.relation_id === crewable.id
    const can_edit_permissions = isEditable(perspective.gig_permissions) &&
      (is_crewable_owner || (!crewable.is_owned && perspective.is_gig_owner))

    return (
      <div className='container mt-2 mb-4'>
        <BreadCrumbs items={[
          { name: 'Shows', url: '/' },
          { name: crewable.gig_name, url: crewable.gig_url },
          { name: crewable.owner_name, url: crewable.url },
          { name: 'Personnel' },
        ]} />
        <h2>Edit Personnel</h2>
        <h4>
          {crewable.owner_name}&nbsp;&ndash;&nbsp;{moment(gig.show_date).format('ddd, MMMM Do YYYY')}
        </h4>

        <div className='alert alert-primary'>
          <i className='fi-info me-2'></i>
          To learn more about personnel settings, see our <a href='/help/personnel' target='_blank'>help pages</a>.
        </div>

        <ManageCrewMembers
          crewable={crewable}
          title_options={titles}
          can_edit_permissions={can_edit_permissions}
          is_crewable_owner={is_crewable_owner}
        />
      </div>
    )
  }

}

export default connect((state, props) => {
  const { entity, entity_type } = selectPerformanceOrLocationFromPath(state, props.match.path, props.match.params.id)
  return {
    crewable: entity,
    page_type: entity_type
  }
}, (dispatch) => bindActionCreators({
  getPerformance, getLocation
}, dispatch))(CrewMembersPage)
