import React from 'react'

import SharedGigs from 'components/gigs/SharedGigs'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getVenueSharedGigs } from 'actions/api/venues'
import { withRouter } from 'react-router'

class VenueSharedGigs extends SharedGigs {

  componentWillReceiveProps(props) {
    if (props.venue_id !== this.props.venue_id) {
      this.getListData(props)
    }
  }

  //Override
  requestListData(page, props=this.props) {
    return props.getVenueSharedGigs(props.venue_id, this.state.page)
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  getVenueSharedGigs,
}, dispatch))(withRouter(VenueSharedGigs))
