import React from 'react'
import _ from 'lodash'

import { computePartiesYetToApprove } from 'utils'

const ScheduleApprovalNotice = ({ gig, approveSchedule }) => {
  const approvedByPerspective = gig.is_schedule_approved_by_perspective
  const gigFullyApproved = gig.is_schedule_fully_approved

  if (_.isEmpty(gig)) return null

  const partiesYetToApprove = computePartiesYetToApprove(gig)

  return (
    gigFullyApproved ? (
      <div className="alert alert-success">
        This schedule is currently fully approved by all headlining bands and the venue!
      </div>
    ) : (
      approvedByPerspective ? (
        <div className="alert alert-warning">
          <div>
            You have approved this schedule, but we're still waiting on approval from:
            <div>
              {partiesYetToApprove.map(party => <li key={party}>{party}</li>)}
            </div>
          </div>
        </div>
      ) : (
        <div className="alert alert-warning">
          <div>
            This schedule is awaiting your approval. Hit the button below to confirm it!
          </div>
          <button className="btn btn-primary mt-2" onClick={() => approveSchedule(gig.id)}>
            Approve Schedule
          </button>
        </div>
      )
    )
  )
}

export default ScheduleApprovalNotice
