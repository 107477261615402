import _ from 'lodash'
import { isSuccessAction, isRequestAction } from 'actions'
import {
  LOGOUT_ACTION,
  LOGIN_ACTION,
  CONFIRM_EMAIL_ACTION,
  REGISTER_ACTION,
  CLAIM_INVITE_ACTION
} from 'actions/api/users'

export default function resourceReducer(state={}, action) {
  // LOGIN REQUESTS wipe resources
  if ((action.type.match(LOGIN_ACTION) ||
        action.type.match(CONFIRM_EMAIL_ACTION) ||
        action.type.match(REGISTER_ACTION) ||
        action.type.match(CLAIM_INVITE_ACTION)
      ) && isRequestAction(action) && isSuccessAction(action)) {
    return {}
  }

  if (action.type.match(LOGOUT_ACTION) && isSuccessAction(action)) {
    return {}
  }

  if (isSuccessAction(action) && action.payload && action.payload.entities) {
    const entities = action.payload.entities
    const newState = { ...state }
    // Look at each type of entity returned
    _.each(_.keys(entities), (entityType) => {
      // Make sure each entity type has it's own object
      if (!newState[entityType]) { newState[entityType] = {} }

      // Look at each entity
      _.each(_.keys(entities[entityType]), (id) => {
        // DELETE if _destroy key is present
        const newEntity = entities[entityType][id]
        if (newEntity._destroy) {
          delete(newState[entityType][id])

        // Merge if in state
        } else if (newState[entityType][id]) {
          newState[entityType][id] = {
            ...newState[entityType][id],
            ...newEntity
          }

        // Else, just add entity
        } else {
          newState[entityType][id] = { ...newEntity }
        }
      })
    })

    return newState
  }

  return state
}

