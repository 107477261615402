import React from 'react'

import Row from 'components/Row'

export default () => (
  <Row>
    <h1>Welcome to our Privacy Policy</h1>
    <h3>Your privacy is critically important to us.</h3>
    <p>It is Backline's policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <a href="https://backline.me">backline.me</a> (hereinafter, "us", "we", or "backline.me"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected by our Application, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and our Mobile Apps, and does not apply to our collection of information from other sources.</p>
    <p>This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Application. Depending on your activities when visiting our Application, you may be required to agree to additional terms and conditions.</p>
    <hr/>

    <h2>Website Visitors</h2>
    <p>Like most website operators, Backline collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Backline's purpose in collecting non-personally identifying information is to better understand how Backline's visitors use its website. From time to time, Backline may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
    <p>Backline also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on http://backline.me blog posts. Backline only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>
    <hr/>

    <h2>Gathering of Personally-Identifying Information</h2>
    <p>Certain visitors to Backline's websites choose to interact with Backline in ways that require Backline to gather personally-identifying information. The amount and type of information that Backline gathers depends on the nature of the interaction. For example, we ask visitors who sign up for an account at http://backline.me to provide a username and email address.</p>
    <hr/>

    <h2>Security</h2>
    <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee and are not responsible for its absolute security.</p>
    <hr/>

    <h2>Links To External Sites</h2>
    <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.</p>
    <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>
    <hr/>

    <h2>Protection of Certain Personally-Identifying Information</h2>
    <p>Backline discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Backline's behalf or to provide services available at Backline's website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Backline's website, you consent to the transfer of such information to them. Backline will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Backline discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Backline believes in good faith that disclosure is reasonably necessary to protect the property or rights of Backline, third parties or the public at large.</p>
    <p>If you are a registered user of https://backline.me and have supplied your email address, Backline may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Backline and our products. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Backline takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.</p>
    <hr/>

    <h2>Aggregated Statistics</h2>
    <p>Backline may collect statistics about the behavior of visitors to its website. Backline may display this information publicly or provide it to others. However, Backline does not disclose your personally-identifying information.</p>
    <hr/>

    <h2>Cookies</h2>
    <p>To enrich and perfect your online experience, Backline uses "Cookies", similar technologies and services provided by others to display personalized content and store preferences on your computer.</p>
    <p>A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Backline uses cookies to help Backline identify and track visitors, their usage of https://backline.me, and their website access preferences. Backline visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Backline's websites, with the drawback that certain features of Backline's websites may not function properly without the aid of cookies.</p>
    <p>By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Backline's use of cookies.</p>
    <hr/>

    <h2>Business Transfers</h2>
    <p>If Backline, or substantially all of its assets, were acquired, or in the unlikely event that Backline goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Backline may continue to use your personal information as set forth in this policy.</p>
    <hr/>

    <h2>Privacy Policy Changes</h2>
    <p>Although most changes are likely to be minor, Backline may change its Privacy Policy from time to time, and in Backline's sole discretion. Backline encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
    <hr/>

    <h2>Contact Information</h2>
    <p>If you have any questions about this Privacy Policy, please contact us via <a href="mailto:info@backline.me">email</a> or <a href="tel:5165545313">phone</a>.</p>
  </Row>
)
