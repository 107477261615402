import React from 'react'
import _ from 'lodash'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { resetPassword } from 'actions/api/users'
import { basicGet } from 'actions/api'

import { parseUrlParams, isSuccessStatus } from 'utils'

import SafeLink from 'components/SafeLink'
import ErrorLabel from 'components/ErrorLabel'

class UserResetPassword extends React.Component {

  constructor(props) {
    super(props)

    this.state = { user: {} }
  }

  componentWillMount() {
    this.props.basicGet(`/api/user`, 'GET_USER_RESET_PASSWORD', {
      auth_key: this.getAuthKey()
    }).then((res) => {
      const user = res.payload.user || {}
      this.setState({
        user: { email: user.email },
        is_not_authorized: !isSuccessStatus(res.payload.request_status)
      })
    })
  }

  getAuthKey() {
    const url_info = parseUrlParams(this.props.location.search)
    const params = url_info.params || {}
    return params.auth_key
  }

  updateUser(update) {
    this.setState({
      user: {
        ...this.state.user,
        ...update,
      }
    })
  }

  submit(e) {
    this.setState({ is_loading: true }, () => {
      this.props.resetPassword({
        auth_key: this.getAuthKey(),
        user: { ...this.state.user },
      }).then((res) => {
        this.setState({
          is_loading: false,
          is_success: res.payload.request_status === 200,
        })
      })
    })

    if (e) { e.preventDefault() }
  }

  render() {
    return (
      <div className='container'>
        <div className='col-12'>
          <div className='card mt-3 p-3'>
            <h3>Reset Password</h3>

            {this.state.is_not_authorized && (
              <div className='alert alert-warning'>
                This password reset link has expired.  You can <SafeLink to='/password_reset'>go here</SafeLink> to request another one.
              </div>
            )}

            <form onSubmit={(e) => this.submit(e)}>
              {this.state.user.email && (
                <label className='me-3 mb-3'>
                  Email
                  <input
                    className='form-control'
                    name='email'
                    type='email'
                    value={this.state.user.email}
                    disabled
                  />
                </label>
              )}

              <label className='me-3 mb-3'>
                Password
                <ErrorLabel
                  field='password'
                  errors={this.props.api_errors}
                />
                <input
                  className='form-control'
                  name='password'
                  type='password'
                  value={this.state.user.password || ''}
                  onChange={(e) => this.updateUser({ password: e.target.value })}
                />
              </label>

              <label className='me-3 mb-3'>
                Password Confirmation
                <ErrorLabel
                  field='password_confirmation'
                  errors={this.props.api_errors}
                />
                <input
                  className='form-control'
                  name='password_confirmation'
                  type='password'
                  value={this.state.user.password_confirmation || ''}
                  onChange={(e) => this.updateUser({ password_confirmation: e.target.value })}
                />
              </label>

              <input
                className='btn btn-primary'
                type='submit'
                value='Reset Password'
                disabled={this.state.is_loading || this.state.is_not_authorized}
                onClick={(e) => this.submit(e)}
              />
            </form>

            {this.state.is_success &&
              <div className='alert alert-success'>
                Password has been successfully updated.  <SafeLink to="/login">Login here</SafeLink>.
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

}

export default connect(state => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  resetPassword, basicGet
}, dispatch))(withRouter(UserResetPassword))
