import React from 'react'
import _ from 'lodash'

import LoadingState from 'components/LoadingState'

import { iff } from 'utils'

import showToast from 'showToast'

export default class EditableList extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      editable_list: [],
    }
  }

  componentWillMount() {
    this.handle_key_press = this.handleKeyPress.bind(this)
    document.addEventListener("keydown", this.handle_key_press)

    this.setLoading(() => this.loadListDataRequest(this.props)
      .then((res) => this.handleLoadListResponse(res)))
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handle_key_press)
  }

  componentWillReceiveProps(props) {
    if (this.getIdFromProps(props) !== this.getIdFromProps(this.props)) {
      this.setState({
        edtiable_list: [],
        is_loading: true,
      }, () => this.loadListDataRequest(props).then((res) => this.handleLoadListResponse(res)))
    }

    this.onReceiveProps(props)
  }

  handleLoadListResponse(res) {
    if (res.error) {
      this.clearLoading()
    } else {
      this.setState({
        is_loading: false,
        editable_list: this.sortEditableList(this.formatListItems(this.getListFromResponse(res))),
      })
    }
  }

  /**********************************
   * OVERRIDES
   **********************************/
  // Override
  getIdFromProps(props) {
    throw "EditableList must override getIdFromProps()"
  }

  // Override
  getDataFromListItem(li) {
    throw "EditableList must override getDataFromListItem()"
  }

  // Override
  getListItemFromResponse(response) {
    throw "EditableList must override getListItemFromResponse()"
  }

  // Override
  getListFromResponse(response) {
    throw "EditableList must override getListFromResponse()"
  }

  // Override
  isEditable() {
    throw "EditableList must override isEditable()"
  }

  // Override
  validateOnSaveAll() {
    return true
  }

  // Override
  getAddButtonText() { return "+ Add" }

  // Override
  getNewListItemProps() { return {} }

  // Override
  sortEditableList(list) { return list }

  formatListItems(list) { return list }

  // Override (optional)
  onReceiveProps(props) {}

  /**********************************
   * REQUESTS
   **********************************/
  // Override
  loadListDataRequest(props) {
    throw "EditableList must override loadListData()"
  }

  // Override
  updateListItemRequest(id, data) {
    throw "EditableList must override updateListItemRequest()"
  }

  // Override
  deleteListItemRequest(id) {
    throw "EditableList must override deleteListItemRequest()"
  }

  // Override
  createListItemRequest(data) {
    throw "EditableList must override createListItemRequest()"
  }

  // Override
  saveListRequest() {
    throw "EditableList must override saveListRequest()"
  }
  /****************************************/
  /****************************************/

  isEditing() {
    return _.reduce(this.state.editable_list, (memo, li) => {
      return memo || li.is_editing
    }, false)
  }

  showSuccessToast() {
    const toastProps = {
      message: 'Saved!',
      durationMs: 1000,
      isSuccess: true,
    }
    showToast(toastProps)
  }

  handleKeyPress(event) {
    switch (event.key) {
      case 'Enter':
        if (!this.isEditing()) {
          event.preventDefault()
          this.addListItem()
        }
        break;
    }
  }

  addListItem() {
    this.setState({
      editable_list: [
        ...this.state.editable_list,
        {
          is_editing: true,
          ...this.getNewListItemProps(),
        },
      ]
    })
  }

  editListItem(i) {
    this.updateListItem(i, {
      is_editing: true,
    })
  }

  saveListItem(i) {
    this.updateListItem(i, {
      is_loading: true,
      is_editing: false,
    }, () => this.saveListItemRequest(i))
  }

  saveListItemRequest(i) {
    const li = this.state.editable_list[i]
    const data = this.getDataFromListItem(li)
    if (li.id) {
      this.updateListItemRequest(li.id, data).then((response) => {
        if (response.error) {
          this.updateListItem(i, { is_loading: false })
        } else {
          const updated_list = [
            ...this.state.editable_list.slice(0, i),
            ...this.formatListItems([this.getListItemFromResponse(response)]),
            ...this.state.editable_list.slice(i+1)
          ]
          this.showSuccessToast()
          this.setState({
            editable_list: this.sortEditableList(updated_list),
          })
        }
      })
    } else {
      if (_.isEmpty(data)) {
        this.removeListItemByIndex(i)
      } else {
        this.createListItemRequest(data).then((response) => {
          if (response.error) {
            this.updateListItem(i, { is_loading: false })
          } else {
            const updated_list = [
              ...this.state.editable_list.slice(0, i),
              ...this.formatListItems([this.getListItemFromResponse(response)]),
              ...this.state.editable_list.slice(i+1),
            ]
            this.showSuccessToast()
            this.setState({
              editable_list: this.sortEditableList(updated_list)
            })
          }
        })
      }
    }
  }

  removeEmptyItems(cb) {
    const new_list_item_props = ['is_editing'].concat(
      _.keys(this.getNewListItemProps()))
    const updated_list = _.filter(this.state.editable_list, (li) => {
      return !_.isEmpty(_.omit(li, new_list_item_props))
    })
    this.setState({ editable_list: updated_list }, () => iff(cb))
  }

  saveAll() {
    if (!this.validateOnSaveAll()) return null

    this.removeEmptyItems(() => {
      this.setState({
        loading_all: true,
      }, () => {
        this.saveListRequest().then((response) => {
          if (response.error) {
            this.setState({ loading_all: false })
          } else {
            this.showSuccessToast()
            this.setState({
              loading_all: false,
              editable_list: this.sortEditableList(this.formatListItems(this.getListFromResponse(response))),
            })
          }
        })
      })
    })
  }

  deleteListItem(i) {
    const li = this.state.editable_list[i]
    if (li.id) {
      this.updateListItem(i, {
        is_loading: true
      }, () => {
        this.deleteListItemRequest(li.id).then((response) => {
          if (!response.error) {
            this.removeListItemById(li.id)
          } else {
            this.updateListItem(i, { is_loading: false })
          }
        })
      })
    } else {
      this.removeListItemByIndex(i)
    }
  }

  removeListItemById(id) {
    const index = _.findIndex(this.state.editable_list, { id: id })
    this.removeListItemByIndex(index)
  }

  removeListItemByIndex(index) {
    if (index >= 0) {
      this.setState({
        editable_list: [
          ...this.state.editable_list.slice(0, index),
          ...this.state.editable_list.slice(index + 1),
        ]
      })
    }
  }

  updateListItem(i, update, callback) {
    this.setState({
      editable_list: [
        ...this.state.editable_list.slice(0,i),
        {
          ...this.state.editable_list[i],
          ...update,
        },
        ...this.state.editable_list.slice(i+1),
      ]
    }, () => iff(callback))
  }

  genUpdateListItem(i) {
    return (update, callback) => {
      this.updateListItem(i, update, callback)
    }
  }

  genSaveListItem(i) {
    return (update) => {
      this.updateListItem(i, update, () => this.saveListItem(i))
    }
  }

  renderButtons() {
    if (this.isEditable()) {
      return (
        <div className='d-flex flex-row'>
          <div className='flex-grow-1 text-start'>
            <a
              className='btn btn-primary'
              onClick={() => this.addListItem()}
            >{this.getAddButtonText()}</a>
          </div>
          <div className='flex-grow-1 text-end'>
            <a
              className='btn btn-primary'
              onClick={() => this.saveAll()}
              disabled={!this.isEditing()}
            >Save All</a>
          </div>
        </div>
      )
    }
  }

}

