import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import { countGuestList } from 'utils'

export default class GuestListCount extends React.Component {

  getCompsUsed() {
    if (this.props.comps_used) {
      return parseInt(this.props.comps_used, 10)
    } else {
      const gls = this.props.guest_list_spots || []
      return countGuestList(gls)
    }
  }

  render() {
    const comps_used = this.getCompsUsed()
    if (_.isNil(this.props.num_comps)) {
      if (this.props.show_parens) {
        return (<span>({comps_used})</span>)
      } else {
        return (<span>{comps_used}</span>)
      }
    } else {
      const num_comps = parseInt(this.props.num_comps, 10)
      const is_over = comps_used > num_comps

      if (this.props.show_parens) {
        return (
          <span className={classnames({ 'red-text': is_over })}>
            ({comps_used}/{num_comps})
          </span>
        )
      } else {
        return (
          <span className={classnames({ 'red-text': is_over })}>
            {comps_used}/{num_comps}
          </span>
        )
      }
    }
  }

}
