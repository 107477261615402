import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectApiErrors } from 'selectors'

import AddressForm from 'components/addresses/AddressForm'
import DateInput from 'components/DateInput'
import FormField from 'components/FormField'
import ErrorLabel from 'components/ErrorLabel'
import Row from 'components/Row'

import { DATE_FORMAT } from 'static'
import { trimTime } from 'utils'

class LodgingForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = this.getStateFromProps(props)
  }

  componentWillReceiveProps(props) {
    this.setState(this.getStateFromProps(props))
  }

  getStateFromProps(props) {
    const lodging = props.lodging || {
      check_in_date: trimTime(moment().format(DATE_FORMAT)),
      check_out_date: trimTime(moment().add(1, 'days').format(DATE_FORMAT)),
    }

    let stay_days = moment(lodging.check_out_date).diff(
      moment(lodging.check_in_date), 'days')

    if (stay_days < 1) {
      stay_days = 1
    }

    return { lodging, stay_days }
  }

  onChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.lodging)
    }
  }

  updateLodging(u) {
    this.setState({
      lodging: {
        ...this.state.lodging,
        ...u,
      }
    }, () => this.onChange())
  }

  updateCheckInDate(date) {
    const check_in_moment = moment(date)
    const check_out_moment = moment(date).add(this.state.stay_days, 'days')
    this.updateLodging({
      check_in_date: trimTime(check_in_moment.format(DATE_FORMAT)),
      check_out_date: trimTime(check_out_moment.format(DATE_FORMAT)),
    })
  }

  updateCheckOutDate(date) {
    const check_in_moment = moment(this.state.lodging.check_in_date)
    const check_out_moment = moment(date)
    let stay_days = check_out_moment.diff(check_in_moment, 'days')

    if (stay_days < 1) {
      stay_days = 1
    }

    const update_date = check_in_moment.add(stay_days, 'days').format(DATE_FORMAT)

    this.setState({
      lodging: {
        ...this.state.lodging,
        check_out_date: trimTime(update_date),
      },
      stay_days: stay_days,
    }, () => this.onChange())
  }

  render() {
    let days_text = 'day'
    if (this.state.stay_days > 1) { days_text = 'days' }
    return (
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <div className='mb-3'>
            <FormField
              label='Housing Name'
              name='lodging_name'
              errorField='name'
              type='text'
              errors={this.props.api_errors}
              value={this.state.lodging.name || ''}
              onChange={(e) => this.updateLodging({ name: e.target.value })}
            />
          </div>

          <div className='row'>
            <div className='col-6 mb-3'>
              <label className='form-label'>Check In Date</label>
              <DateInput
                name='check_in_date'
                value={this.state.lodging.check_in_date}
                onChange={(d) => this.updateCheckInDate(d)}
              />
            </div>
            <div className='col-6 mb-3'>
              <label className='form-label'>Check Out Date</label>
              <DateInput
                name='check_out_date'
                value={this.state.lodging.check_out_date}
                onChange={(d) => this.updateCheckOutDate(d)}
              />
            </div>
          </div>

          <div className='alert alert-primary'>
           You are staying for {this.state.stay_days} {days_text}.
          </div>

          <div className='mb-3'>
            <FormField
              label='Notes'
              name='notes'
              type='textarea'
              value={this.state.lodging.notes || ''}
              onChange={(e) => this.updateLodging({ notes: e.target.value })}
            />
          </div>
        </div>

        <div className='col-12 col-lg-6 mb-3'>
          <h5>Address</h5>
          <div className='rounded border p-3'>
            <AddressForm
              address={this.state.lodging.address}
              onChange={(a) => this.updateLodging({ address: a })}
            />
          </div>
        </div>
      </div>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
}))(LodgingForm)
