import _ from 'lodash'
import React from 'react'
import classnames from 'classnames'

import ErrorLabel from 'components/ErrorLabel'

export default ({
  className,
  label,
  type,
  name,
  errorField,
  value,
  placeholder,
  onChange,
  autoComplete,
  disabled,
  errors,
  passRef,
}) => {

  const errorFieldName = errorField || name
  const hasError = !_.isNil(_.get(errors, errorFieldName))

  if (type === 'textarea') {
    return (
      <>
        <label className='form-label' htmlFor={`formField-${name}`}>{label}</label>
        <textarea
          type={type}
          name={name}
          className={classnames('form-control', { 'is-invalid': hasError }, className)}
          id={`formField-${name}`}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
        />
        {hasError && (<ErrorLabel field={errorFieldName} errors={errors} />)}
      </>
    )
  }

  return (
    <>
      <label className='form-label' htmlFor={`formField-${name}`}>{label}</label>
      <input
        type={type}
        name={name}
        className={classnames('form-control', { 'is-invalid': hasError }, className)}
        id={`formField-${name}`}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      {hasError && (<ErrorLabel field={errorFieldName} errors={errors} />)}
    </>
  )
}
