import React from 'react'
import _ from 'lodash'

import ConfirmationButton from 'components/ConfirmationButton'
import AttachmentForm from 'components/documents/AttachmentForm'

import { iff } from 'utils'

export default ({
  documentable,
  attachment,
  nameOptions,
  isLoading,
  isEditable,
  onUpdate,
  onSave,
  onDelete,
}) => {
  if (attachment.is_editing) {
    return (
      <div className='list-group-item p-2'>
        <AttachmentForm
          attachment={attachment}
          documentable={documentable}
          updateAttachment={onUpdate}
          nameOptions={nameOptions}
          onUploadSuccess={onSave}
          uploadButtonText='Upload and Save'
        />

        <div className='d-flex flex-row'>
          <div className='flex-grow-1'>
            <div className='btn-group'>
              <a
                className='btn btn-primary mb-0'
                onClick={onSave}
                disabled={isLoading}
              >save</a>
              <a
                className='btn btn-gray mb-0'
                onClick={() => onUpdate({ is_editing: false })}
                disabled={isLoading}
              >cancel</a>
            </div>
          </div>

          <div className='flex-grow-1 text-end'>
            <ConfirmationButton
              button_classes='mb-0 '
              button_type='danger'
              button_text='delete'
              confirm_text='really delete?'
              onConfirm={onDelete}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='list-group-item d-flex flex-row align-items-center'>
        <a href={attachment.url} target='_blank' className='text-decoration-none flex-grow-1'>
          <i className='fi-page-filled me-2'></i>{attachment.name}
        </a>
        {isEditable && (
          <a
            onClick={() => onUpdate({ is_editing: true })}
            className='flex-grow-0'
          >
            <i className='edit-icon fi-pencil'></i>
          </a>
        )}
      </div>
    )
  }
}
