import React from 'react'

import { staticAssetUrl } from 'utils'

export default class LandingInfo extends React.Component {

  render() {
    return(
      <React.Fragment>
        <div className='row justify-content-center'>
          <div className='col-sm-12 col-md-6 col-lg-4'>
            <img src={staticAssetUrl('Landing_Page/MarketingIcons/ShareDocs.png')} className='marketing-icon share-docs' />
            <div className='marketing-blurb'>
              <h5 className='marketing-header'>Share Documents &amp; Info</h5>
              <p>
                Automatically share documents and information with the right people.  Stage plots, riders, tech packs, guest lists, etc. are always available and easily accessible.
              </p>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-4'>
            <img src={staticAssetUrl('Landing_Page/MarketingIcons/BuildShows.png')} className='marketing-icon' />
            <div className='marketing-blurb'>
              <h5 className='marketing-header'>Build Shows Together</h5>
              <p>
                When an artist and a venue connect on a show, a shared page is created to work out the details of the event.  Everyone can contribute their piece of the advance in the same place.
              </p>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-4'>
            <img src={staticAssetUrl('Landing_Page/MarketingIcons/ManageShows.png')} className='marketing-icon' />
            <div className='marketing-blurb'>
              <h5 className='marketing-header'>Plan All of Your Shows With Ease</h5>
              <p>
                Backline's intuitive progress indicator helps make sure that you have all of the information you need for your upcoming shows.  If you don't it will tell you what you're missing!
              </p>
            </div>
          </div>
        </div>

        <div className='row justify-content-center'>
          <div className='col-sm-12 col-md-6 col-lg-4'>
            <img src={staticAssetUrl('Landing_Page/MarketingIcons/JoinMultipleOrgs.png')} className='marketing-icon join-orgs' />
            <div className='marketing-blurb'>
              <h5 className='marketing-header'>Manage Multiple Organizations</h5>
              <p>
                Backline makes it easy for users to manage multiple bands and/or venues.  Your involvement can be anywhere from owning a band to subbing on just one show.
              </p>
            </div>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-4'>
            <img src={staticAssetUrl('Landing_Page/MarketingIcons/KeepYourTeam.png')} className='marketing-icon keep-your-team' />
            <div className='marketing-blurb'>
              <h5 className='marketing-header'>Keep Your Team In The Loop</h5>
              <p>
                On Backline, your crew will always have access to the most updated information.  And with Backline's permission system, you can keep your sensitive information private.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}
