import { resourceRSAA } from 'utils'

export const ADMIN_VENUE_ACTION = 'ADMIN//VENUE'
export function adminGetVenues(page) {
  return resourceRSAA(ADMIN_VENUE_ACTION, `/api/admin/venues?page=${page}`)
}

export function adminGetVenue(id) {
  return resourceRSAA(ADMIN_VENUE_ACTION, `/api/admin/venues/${id}`)
}

export function adminDeleteVenue(id) {
  return resourceRSAA(ADMIN_VENUE_ACTION, `/api/admin/venues/${id}`, { method: 'DELETE' })
}

export function adminGetVenueUpcomingGigs(id, page) {
  return resourceRSAA(ADMIN_VENUE_ACTION, `/api/admin/venues/${id}/upcoming_gigs?page=${page}`)
}

export function adminGetVenuePreviousGigs(id, page) {
  return resourceRSAA(ADMIN_VENUE_ACTION, `/api/admin/venues/${id}/previous_gigs?page=${page}`)
}

export function adminUpdateVenue(id, data) {
  return resourceRSAA(ADMIN_VENUE_ACTION, `/api/admin/venues/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ venue: data }),
  })
}
