import _ from 'lodash'
import { UNITED_STATES } from 'static'

export function formatGoogleAddress(googleAddress) {
  const address_line_one = computeAddressLineOne(googleAddress)
  let city = getValueForPlaceType(googleAddress, 'locality')
  if (_.isEmpty(city)) { city = getValueForPlaceType(googleAddress, 'sublocality') }
  const state = getValueForPlaceType(googleAddress, 'administrative_area_level_1', true)
  const region = getValueForPlaceType(googleAddress, 'postal_town')
  const zip = getValueForPlaceType(googleAddress, 'postal_code')
  const country = getValueForPlaceType(googleAddress, 'country')

  const return_data = {
    address_line_one,
    city,
    zip,
    country,
  }

  if (country === UNITED_STATES) {
    return { ...return_data, state }
  } else {
    return { ...return_data, region }
  }
}

function getValueForPlaceType(googleAddress, placeType, shortName = false) {
  const found = googleAddress.address_components.find(component => {
    return component.types.includes(placeType)
  })

  if (found) {
    return shortName ? found.short_name : found.long_name
  }

  return ''
}

function computeAddressLineOne(googleAddress) {
  const streetNum = getValueForPlaceType(googleAddress, 'street_number')

  let lineOne = ''
  if (_.isEmpty(streetNum)) {
    lineOne = getValueForPlaceType(googleAddress, 'premise')
  } else {
    lineOne = `${streetNum} ${getValueForPlaceType(googleAddress, 'route')}`
  }

  return lineOne
}

export function getDriveDistance(origin, destination, onSuccessfulFetch) {
  const service = new google.maps.DistanceMatrixService()
  service.getDistanceMatrix(
    {
      origins: [origin],
      destinations: [destination],
      travelMode: 'DRIVING',
      avoidHighways: false,
      avoidTolls: false,
    },
    (response, status) => {
      if (status === 'OK') {
        const results = response.rows[0].elements
        const element = results[0]

        const durationSeconds = element.duration.value
        const durationMinutes = Math.ceil(durationSeconds / 60)

        onSuccessfulFetch(durationMinutes)
      }
    }
  )
}
