import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { iff } from 'utils'

export default class RadioButtons extends React.Component {

  constructor(props) {
    super(props)

    this.state = this.getStateFromProps(props)
  }

  componentWillReceiveProps(props) {
    this.setState(this.getStateFromProps(props))
  }

  getStateFromProps(props) {
    return { value: _.isNil(props.value) ? null : props.value }
  }

  onChange(value) {
    this.setState({ value }, () => {
      iff(this.props.onChange, value)
    })
  }

  render() {
    const options = this.props.options || []
    return (
      <>
        {_.map(options, (opt, i) => (
          <div className='d-inline-block form-check'>
            <input
              name={this.props.name}
              id={`radio-${this.props.name}-${opt.value}`}
              type='radio'
              className={classnames('form-check-input', this.props.input_classes)}
              checked={opt.value === this.state.value}
              value={opt.value}
              onChange={(e) => this.onChange(opt.value)}
              disabled={this.props.disabled}
            />
            <label
              className={classnames('form-check-label', this.props.label_classes)}
              for={`radio-${this.props.name}-${opt.value}`}
              key={`${this.props.name}-${opt.value}-${i}`}
            >
              {opt.label}
            </label>
          </div>
        ))}
      </>
    )
  }

}
