const ACTIVE_TOAST_ID = 'active-toast-notification'

const showToast = ({ message, durationMs = 3000, isSuccess = false }) => {
  const existingEl = document.getElementById(ACTIVE_TOAST_ID)

  if (existingEl) return null

  const toastEl = document.createElement('div')
  toastEl.id = ACTIVE_TOAST_ID
  toastEl.classList.add('toast-notification')
  toastEl.innerText = message
  if (isSuccess) toastEl.classList.add('toast-notification--success')
  document.body.appendChild(toastEl)

  setTimeout(() => {
    toastEl.style.display = 'none'
    document.body.removeChild(toastEl)
  }, durationMs)
}

export default showToast
