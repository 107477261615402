import React from 'react'
import _ from 'lodash'

import AttachmentForm from 'components/documents/AttachmentForm'

import { iff } from 'utils'

export default ({
  attachment,
  documentable,
  onUpdate,
  onClose,
  onSave,
  isLoading,
  nameOptions,
}) => (
  <div className='list-group-item'>
    <AttachmentForm
      attachment={attachment}
      documentable={documentable}
      updateAttachment={onUpdate}
      nameOptions={nameOptions}
      isDisabled={isLoading}
      onUploadSuccess={onSave}
      uploadButtonText='Upload and Save'
    />
    <div className='btn-group'>
      <a
        className='btn btn-primary m-0'
        onClick={onSave}
        disabled={isLoading}
      >save</a>
      <a
        className='btn btn-gray m-0'
        onClick={onClose}
        disabled={isLoading}
      >cancel</a>
    </div>
  </div>
)

