import React from 'react'
import moment from 'moment'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { adminGetGig } from 'actions/api/admin/gigs'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { selectModel } from 'selectors'

import SafeLink from 'components/SafeLink'

import AdminHeader from 'components/admin/AdminHeader'
import LoadingState from 'components/LoadingState'
import ShowScheduleItems from 'components/schedule/ShowScheduleItems'
import LocationCard from 'components/gigs/LocationCard'
import PerformanceList from 'components/performances/PerformanceList'

import {
  INVITE_STATUS,
  PERSPECTIVE_TYPE
} from 'static'

import {
  setUrlParams,
  parseUrlParams
} from 'utils'

class AdminGigPage extends LoadingState {

  componentWillMount() {
    this.setLoading(() => {
      this.props.adminGetGig(this.props.match.params.id).then(() => this.clearLoading())
    })
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setLoading(() => {
        props.adminGetGig(props.match.params.id).then(() => this.clearLoading())
      })
    }
  }

  render() {
    const gig = this.props.gig || {}
    const lodgings = gig.lodgings || []
    const perspective = gig.perspective || {}
    return (
      <div>
        <div className='container p-3'>
          <div className="row">
            <AdminHeader />
            <h2>{gig.name}</h2>
            <h4>{moment(gig.show_date).format('dddd, MMMM Do YYYY')}</h4>
          </div>

          <div className='row pt-3'>
            <div className='col-12 col-md-6'>
              <h4>
                Schedule
                <SafeLink to={`${gig.url}/schedule`}>
                  <i className='edit-icon fi-pencil icon-base half-margin-left'></i>
                </SafeLink>
              </h4>
              <ShowScheduleItems gig={gig} />
            </div>

            <div className='col-12 col-md-6'>
              <h4>
                Venue
              </h4>
              {gig.location && gig.location.venue_id && (
                <div className={classnames({
                  'card': true,
                  'bg-warning bg-opacity-25': gig.location.invite_status === INVITE_STATUS.PENDING,
                  'bg-danger bg-opacity-25': gig.location.invite_status === INVITE_STATUS.REJECTED,
                })}>
                  <LocationCard
                    gig_id={gig.id}
                    location={gig.location || {}}
                    admin_url
                  />
                </div>
              )}

              {(!gig.location || !gig.location.venue_id) && (
                <div className='card text-center'>
                  <SafeLink
                    to={`/admin${gig.url}/locations/new`}
                    className='text-black'>
                    <h4 className='no-margin'>
                      <i className='fi-plus'></i> Add Venue
                    </h4>
                  </SafeLink>
                </div>
              )}

              <h4 className="pt-3">Bands</h4>

              <div className='margin-bottom'>
                <PerformanceList
                  performances={gig.performances}
                  admin_url
                />
              </div>
            </div>
          </div>

          <div className="row pt-3">
            <h5>Public Notes</h5>
            <div className='card'>
              <p className='prewrap'>{gig.notes}</p>
            </div>
          </div>

          {this.renderLoadingPopup()}
        </div>
      </div>
    )
  }
}

export default connect((state, ownProps) => ({
  gig: selectModel('gigs', ownProps.match.params.id, Resources.gig, state),
}), (dispatch) => bindActionCreators({
  adminGetGig
}, dispatch))(withRouter(AdminGigPage))
