import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel, selectPerformanceOrLocationFromPath  } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { withRouter } from 'react-router'
import { getPerformance } from 'actions/api/performances'
import { getLocation } from 'actions/api/locations'
import { getGig } from 'actions/api/gigs'
import {
  createScheduleItem,
  updateScheduleItem,
  deleteScheduleItem,
  updateGigScheduleItems
} from 'actions/api/schedule_items'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import BottomBar from 'components/BottomBar'
import EditableList from 'components/EditableList'
import EditableScheduleItem from 'components/schedule/EditableScheduleItem'
import LocationAdvanceProgress from 'components/locations/LocationAdvanceProgress'

import {
  timeToMinutes,
  isEditableByPerspective
} from 'utils'

import { CREWABLE_TYPE } from 'static'

class AdvanceSchedulePage extends EditableList {

  loadPerfOrLoc(props = this.props) {
    if (props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return props.getPerformance(props.match.params.id)
    } else if (props.page_type === CREWABLE_TYPE.LOCATION) {
      return props.getLocation(props.match.params.id)
    } else {
      throw "AdvanceSchedulePage loadPerfOrLoc() function was called without a page_type"
    }
  }

  componentWillMount() {
    this.handle_key_press = this.handleKeyPress.bind(this)
    document.addEventListener("keydown", this.handle_key_press)

    this.setLoading(() => this.loadPerfOrLoc().then((res) => this.handlePercOrLocResponse(res)))
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handle_key_press)
  }

  componentWillReceiveProps(props) {
    if (this.getIdFromProps(props) !== this.getIdFromProps(this.props)) {
      this.setState({
        edtiable_list: [],
        is_loading: true,
      }, () => this.loadPerfOrLoc(props).then((res) => this.handlePercOrLocResponse(res)))
    }
  }

  handlePercOrLocResponse(res) {
    if (res.error) {
      this.handleResponse(res)
    } else {
      this.props.getGig(this.getGigIdFromResponse(res)).then((res) => this.handleLoadListResponse(res))
    }
  }

  getGigIdFromResponse(response) {
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return _.get(response, 'payload.raw.performance.gig_id')
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      return _.get(response, 'payload.raw.location.gig_id')
    }
  }

  // Override
  getIdFromProps(props) { return props.match.params.id }

  // Override
  getDataFromListItem(li) {
    return _.pick(li, [ 'performance_id', 'label', 'time', 'duration' ])
  }

  // Override
  getListItemFromResponse(response) {
    return _.get(response, 'payload.raw.schedule_item', {})
  }

  // Override
  getListFromResponse(response) {
    return _.get(response, 'payload.raw.gig.schedule_items', [])
  }

  // Override
  isEditable() {
    const perf_or_loc = _.get(this.props, 'perf_or_loc', {})
    const perspective = _.get(this.props, 'gig.perspective', {})
    return isEditableByPerspective(perspective, perf_or_loc, perspective.gig_permissions)
  }

  // Override
  getAddButtonText() {
    return "+ Add Schedule Item"
  }

  // Override
  getNewListItemProps() {
    return { gig_id: this.getGigId() }
  }

  getGigId(props = this.props) {
    return _.get(props, 'perf_or_loc.gig.id')
  }

  /**********************************
   * REQUESTS
   **********************************/
  // Override
  loadListDataRequest(props) {
    return props.getGig(this.getGigId(props))
  }

  // Override
  updateListItemRequest(id, data) {
    return this.props.updateScheduleItem(id, data)
  }

  // Override
  deleteListItemRequest(id) {
    return this.props.deleteScheduleItem(id)
  }

  // Override
  createListItemRequest(data) {
    return this.props.createScheduleItem(this.getGigId(), data)
  }

  // Override
  saveListRequest() {
    return this.props.updateGigScheduleItems(this.getGigId(), this.state.editable_list)
  }
  /****************************************/
  /****************************************/

  sortEditableList(list) {
    return _.sortBy(list, (t) => timeToMinutes(t.time))
  }

  saveAndNext() {
    this.removeEmptyItems(() => {
      this.setState({ loading_all: true }, () => {
        this.saveListRequest().then((response) => {
          if (response.error) {
            this.setState({ loading_all: false })
          } else {
            this.setState({
              loading_all: false,
              editable_list: this.getListFromResponse(response),
            }, () => this.pushNextUrl())
          }
        })
      })
    })
  }

  pushNextUrl() {
    const perf_or_loc = _.get(this.props, 'perf_or_loc', {})
    this.props.history.push(`${perf_or_loc.url}/advance/summary`)
  }

  onNext() {
    if (this.isEditing() && this.isEditable()) {
      this.saveAndNext()
    } else {
      this.pushNextUrl()
    }
  }

  renderMobile() {
    const perf_or_loc = this.props.perf_or_loc || {}

    return (
      <>
        <div className='container mt-2'>
          {this.renderTitle()}
          <div className='mb-2'>
            {_.map(this.state.editable_list, (si, i) => (
              <EditableScheduleItem
                key={`schedule_item-${si.id}-${i}`}
                schedule_item={si}
                onUpdate={this.genUpdateListItem(i)}
                onSave={this.genSaveListItem(i)}
                onDelete={() => this.deleteListItem(i)}
                onEdit={() => this.editListItem(i)}
                is_editable={this.isEditable()}
                is_disabled={this.state.loading_all || this.state.is_loading}
                is_mobile
              />
            ))}
          </div>
          {this.renderButtons()}
          {this.renderLoadingPopup()}
        </div>

        <BottomBar>
          <div className='container py-2 d-flex flex-row align-items-center'>
            <div className='flex-grow-1'>
              <SafeLink
                to={`${perf_or_loc.url}/advance/crew_members`}
                className='btn btn-gray mb-0'
                disabled={this.state.is_loading}
              >&lt; go back</SafeLink>
            </div>

            <div className='flex-grow-1 text-end'>
              <a
                onClick={() => this.onNext()}
                className='btn btn-primary mb-0'
                disabled={this.state.is_loading}
              >next page &gt;</a>
            </div>
          </div>
        </BottomBar>
      </>
    )
  }

  renderDesktop() {
    const gig = this.props.gig || {}
    const perf_or_loc = this.props.perf_or_loc || {}
    return (
      <>
        <div className='container mt-3'>
          {this.renderTitle()}
          <table className='table table-striped align-middle'>
            <thead>
              <tr>
                <th>Time</th>
                {this.isEditing() && (<th width='150'>Length</th>)}
                <th>Label</th>
                <th>Band</th>
                {this.isEditable() && (
                  <th width='65'></th>
                )}
              </tr>
            </thead>
            <tbody>
              {_.map(this.state.editable_list, (si, i) => (
                <EditableScheduleItem
                  key={`schedule_item-${si.id}-${i}`}
                  schedule_item={si}
                  onUpdate={this.genUpdateListItem(i)}
                  onSave={this.genSaveListItem(i)}
                  onDelete={() => this.deleteListItem(i)}
                  onEdit={() => this.editListItem(i)}
                  is_editable={this.isEditable()}
                  is_disabled={this.state.loading_all || this.state.is_loading}
                  show_duration={this.isEditing()}
                />
              ))}
            </tbody>
          </table>
          {this.renderButtons()}
          {this.renderLoadingPopup()}

        </div>

        <BottomBar>
          <div className='container py-2 d-flex flex-row align-items-center'>
            <div className='flex-grow-1'>
              <SafeLink
                to={`${perf_or_loc.url}/advance/crew_members`}
                className='btn btn-gray mb-0'
                disabled={this.state.is_loading}
              >&lt; go back</SafeLink>
            </div>

            <div className='flex-grow-1 text-end'>
              <a
                onClick={() => this.onNext()}
                className='btn btn-primary mb-0'
                disabled={this.state.is_loading}
              >next page &gt;</a>
            </div>
          </div>
        </BottomBar>
      </>
    )
  }

  renderTitle() {
    const gig = this.props.gig || {}
    return(
      <>
        <LocationAdvanceProgress location={this.props.perf_or_loc} pageNum={4} />

        <PageTitle>
          Schedule
        </PageTitle>

        <h4>
          {`${moment(gig.show_date).format('MMMM Do YYYY')} - ${gig.headliners}`}
        </h4>

        {this.isEditable() ? (
          <i>
            Please enter the performance schedule here
            (e.g. load-in, soundcheck, set times, doors, curfew, etc).
          </i>
        ) : (
          <div className='alert alert-danger'>
            You are currently logged in as a user who does not
            have permission to edit the schedule for this show.
          </div>
        )}
      </>
    )
  }

  render() {
    if (window.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

}


export default connect((state, ownProps) => {
  const { entity, entity_type } = selectPerformanceOrLocationFromPath(state, ownProps.match.path, ownProps.match.params.id)
  let gig = null
  if (!_.isNil(entity)) {
    gig = selectModel('gigs', _.get(entity, 'gig.id'), Resources.gig, state)
  }

  return {
    perf_or_loc: entity,
    page_type: entity_type,
    gig,
  }
}, (dispatch) => bindActionCreators({
  getPerformance,
  getLocation,
  getGig,
  createScheduleItem,
  updateScheduleItem,
  deleteScheduleItem,
  updateGigScheduleItems
}, dispatch))(withRouter(AdvanceSchedulePage))
