import React from 'react'

export default class ShowAddress extends React.Component {

  render() {
    if (!this.props.address) {
      return null
    }
    const a = this.props.address
    const line_one = []
    const line_two = []

    if (a.address_line_one) { line_one.push(a.address_line_one) }
    if (a.address_line_two) { line_one.push(a.address_line_two) }

    if (a.city) { line_two.push(a.city) }
    if (a.state_region) { line_two.push(a.state_region) }
    if (a.zip) { line_two.push(a.zip) }

    return (
      <div>
        {line_one.length > 0 && (
          <div> {line_one.join(", ")} </div>
        )}

        {line_two.length > 0 && (
          <div> {line_two.join(", ")} </div>
        )}

        {this.props.show_country && a.country && (
          <div> {a.country} </div>
        )}
      </div>
    )
  }

}
