import React from 'react'
import Overlay from 'components/Overlay'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { dismissErrorOverlay } from 'actions/ui'

class ErrorOverlay extends React.Component {

  render() {
    const is_hidden = !this.props.server_error

    return (
      <Overlay is_hidden={is_hidden} onDismiss={() => this.props.dismissErrorOverlay()}>
        <div className='text-center'>
          <p>
            There was an unexpected error!
          </p>

          <p>
            Please send us an email (
              <a href='mailto:info@backline.me'>
                info@backline.me
              </a>
            ) with details about the error.  Make sure to include the time and date!
          </p>

          <p>
            <a onClick={() => this.props.dismissErrorOverlay()}>Dismiss this overlay</a>
          </p>
        </div>
      </Overlay>
    )
  }

}

export default connect((state, props) => ({
  server_error: state.api.server_error,
}), (dispatch) => {
  return bindActionCreators({ dismissErrorOverlay }, dispatch)
})(ErrorOverlay)
