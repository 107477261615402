import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createCrewMember } from 'actions/api/crew_members'
import { getMembershipForPersonId } from 'actions/api/memberships'
import { selectApiErrors } from 'selectors'
import { getPerson } from 'actions/api/people'

import PersonSelect from 'components/people/PersonSelect'
import OtherSelect from 'components/OtherSelect'
import PhoneInput from 'components/people/PhoneInput'
import ShowPhone from 'components/people/ShowPhone'
import ShowEmail from 'components/people/ShowEmail'
import FormField from 'components/FormField'
import LoadingState from 'components/LoadingState'
import ApiErrors from 'components/ApiErrors'

import CrewMemberDetailsForm from 'components/crew_members/CrewMemberDetailsForm'

import {
  INVITE_STATUS
} from 'static'

import { iff } from 'utils'

class NewCrewMemberForm extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      crew_member: {},
      selected_person: {},
      is_new_person: false,
    }
  }

  componentDidMount() {
    this.getPersonFromId()
  }

  crewableUrl() {
    return _.get(this.props, 'crewable.url')
  }

  ownerUrl() {
    return _.get(this.props, 'crewable.owner_url')
  }

  submit() {
    if (!this.crewableUrl()) {
      return null
    }

    this.setLoading(() =>
      this.props.createCrewMember(this.crewableUrl(), this.state.crew_member).then((res) => {
        if (res.error) {
          this.clearLoading()
        } else {
          this.clearLoading()
          iff(this.props.onCancel)
        }
      }))
  }

  isOwnedPerson() {
    return !this.state.is_new_person &&
      this.state.crew_member.person_id &&
      this.state.selected_person.is_owned
  }

  selectPerson(id) {
    this.setState({
      is_new_person: false,
      crew_member: {
        ...this.state.crew_member,
        person_id: id,
      },
    }, () => this.getPersonFromId())
  }

  newPerson(name) {
    this.setState({
      is_new_person: true,
      crew_member: {
        ..._.omit(this.state.crew_member, ['person_id']),
        person: { name: name },
      },
    })
  }

  onCancelNewPerson() {
    this.setState({
      is_new_person: false,
      crew_member: { ..._.omit(this.state.crew_member, ['person']) },
    })
  }

  updateCrewMember(update, callback) {
    this.setState({
      crew_member: {
        ...this.state.crew_member,
        ...update,
      }
    }, () => {
      if (callback) { callback() }
    })
  }

  updatePerson(update) {
    const person = this.state.crew_member.person || {}
    this.updateCrewMember({
      person: {
        ...person,
        ...update,
      }
    })
  }

  getPersonFromId() {
    if (this.state.crew_member.person_id) {
      const person_id = this.state.crew_member.person_id
      this.setLoading(() => {
        this.props.getMembershipForPersonId(this.ownerUrl(), person_id).then((response) => {
          if (response.error) {
            this.props.getPerson(person_id).then((response) => {
              if (!response.error) {
                const person_data = response.payload.raw.person
                this.setSelectedPersonData(person_data, null, () => this.clearLoading())
              } else {
                this.clearLoading()
              }
            })
          } else {
            const person_data = response.payload.raw.person
            const membership_data = response.payload.raw.membership
            this.setSelectedPersonData(person_data, membership_data, () => this.clearLoading())
          }
        })
      })
    } else {
      this.setState({ selected_person: {} })
    }
  }

  setSelectedPersonData(person, membership, callback) {
    const person_data = person || {}
    const m_data = membership || {}
    const crew_member = this.state.crew_member || {}
    const permissions = {
      gig_permissions: !_.isNil(m_data.default_gig_permissions) ?
        m_data.default_gig_permissions : crew_member.gig_permissions,
      guest_list_permissions: !_.isNil(m_data.default_guest_list_permissions) ?
        m_data.default_guest_list_permissions : crew_member.guest_list_permissions,
      ticket_sales_permissions: !_.isNil(m_data.default_ticket_sales_permissions) ?
        m_data.default_ticket_sales_permissions : crew_member.ticket_sales_permissions,
    }
    this.setState({
      crew_member: {
        ...crew_member,
        ...permissions,
        title: m_data.title || person_data.default_title || crew_member.title,
        is_contact: m_data.default_is_contact,
        is_on_site: m_data.default_is_on_site,
      },
      selected_person: person_data,
    }, () => iff(callback))
  }

  renderPersonFields() {
    if (this.state.is_new_person) {
      let person = this.state.crew_member.person || {}
      return (
        <>
          <div className='mb-2'>
            <FormField
              label='Name'
              type='text'
              name='name'
              value={person.name || ''}
              onChange={(e) => this.updatePerson({ name: e.target.value })}
            />
          </div>
          <div className='mb-2'>
            <FormField
              label='Email'
              type='text'
              name='email'
              value={person.email || ''}
              onChange={(e) => this.updatePerson({ email: e.target.value })}
            />
          </div>
          <div className='mb-2'>
            <label className='form-label'>Phone</label>
            <PhoneInput
              name='phone'
              value={person.phone || ''}
              onChange={(v) => this.updatePerson({ phone: v })}
            />
          </div>
        </>
      )
    } else {
      const person_id = this.state.crew_member.person_id || ''
      return (
        <PersonSelect
          person_id={person_id}
          onSelect={(id) => this.selectPerson(id)}
          onNew={(name) => this.newPerson(name)}
        />
      )
    }
  }

  render() {
    const crew_member = this.state.crew_member || {}
    return (
      <div>
        <div className='row mb-3'>
          <div className='col-12 col-md-6'>
            {this.renderPersonFields()}
          </div>

          <div className='col-12 col-md-6'>
            <label className='form-label'>Title</label>
            <OtherSelect
              name='title'
              options={_.map(this.props.title_options || [], (ct) => (
                { value: ct, label: ct }
              ))}
              value={this.state.crew_member.title}
              onChange={(v) => this.updateCrewMember({ title: v })}
            />

            <CrewMemberDetailsForm
              crew_member={this.state.crew_member}
              onChange={(v) => this.updateCrewMember(v)}
              can_edit_permissions={this.props.can_edit_permissions}
              disabled={this.state.is_loading}
            />
          </div>
        </div>

        <ApiErrors errors={this.props.api_errors} />

        <button
          className='btn btn-primary'
          onClick={() => this.submit()}
          disabled={this.state.is_loading}
        >save</button>

        <button
          className='btn btn-gray'
          onClick={() => iff(this.props.onCancel)}
          disabled={this.state.is_loading}
        >cancel</button>
      </div>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
}), (dispatch) => bindActionCreators({
  createCrewMember, getPerson, getMembershipForPersonId
}, dispatch))(NewCrewMemberForm)
