import {
  resourceRSAA,
  genRSAA,
  jsonRSAA,
  parseResources,
  setUrlParams
} from 'utils'

export const SEARCH_ACTION = 'SEARCH'
export function basicSearch(query, params={}) {
  return basicGet(`/api/search_data`, SEARCH_ACTION, {
    q: query,
    ...params,
  })
}

export const RESOURCE_GET_ACTION = 'RESOURCE_GET'
export function resourceGet(url, action_type) {
  return resourceRSAA(`${RESOURCE_GET_ACTION}//${action_type}`, url)
}


export const GET = 'GET'
export function basicGet(url, action_type, data) {
  if (data) {
    url = setUrlParams(url, data)
  }

  return jsonRSAA(`${GET}//${action_type}`, url)
}

export const POST = 'POST'
export function basicPost(url, action_type, data) {
  return jsonRSAA(`${POST}//${action_type}`, url, null, {
    body: JSON.stringify(data),
    method: 'POST',
  })
}

export const SEND_CLIENT_ERROR = 'SEND_CLIENT_ERROR'
export function sendClientError(error_message) {
  return basicPost('/api/client_error/', SEND_CLIENT_ERROR, { error_message })
}

export const UPLOAD_FILE = 'UPLOAD_FILE'
export function uploadFile(url, data) {
  const form_data = new FormData();
  _.each(_.keys(data), (key) => {
    form_data.append(key, data[key])
  })

  return genRSAA(UPLOAD_FILE, url, (res) => {
    return res.text().then((text) => text)
  }, {
    method: 'POST',
    headers: {
      'Accept': 'application/xml',
      'Origin': `${window.location.protocol}//${window.location.hostname}`,
    },
    body: form_data,
  })
}
