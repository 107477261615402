import React from 'react'
import _ from 'lodash'

import EditableField from 'components/EditableField'

import { MAX_NUM_COMPS } from 'static'

export default class EditableNumComps extends EditableField {

  getValueFromProps(props) {
    return props.num_comps || 0
  }

  getPostData() {
    return { num_comps: this.state.value }
  }

  // Override
  onChange(num) {
    let update_value = num
    if (num) {
      update_value = Math.round(Math.max(Math.min(num, MAX_NUM_COMPS), 0), 0)
    }
    this.setState({ value: update_value })
  }

  render() {
    const tooManyCompsClaimed = this.props.comps_used > this.props.num_comps

    return (
      <div className="my-3">
        <div className="mb-1"><strong>Guest List Comps</strong></div>
        {tooManyCompsClaimed && <div className="alert alert-danger">Oh, no! More comps have been claimed than are allotted!</div>}
        <div className="d-flex align-items-center">
          <div>{this.props.comps_used || 0}</div>
          <div className="mx-3 h5 my-0">/</div>
          <div>
            <>
              {this.state.is_editing && this.props.is_editable && (
                <div className="d-flex align-items-center">
                  <input
                    className='form-control d-inline-block num-comps-input'
                    ref={(ref) => this.input = ref}
                    type='number'
                    name='num_comps'
                    value={this.state.value}
                    onChange={(e) => this.onChange(e.target.value)}
                    onBlur={(e) => this.onBlur(e.target.value)}
                  />

                  <a
                    key='save'
                    onClick={() => this.save()}>
                    <i className='fi-check icon-base ms-2 success-color'></i>
                  </a>
                </div>
              )}
              {!this.state.is_editing && this.props.is_editable && (
                <div className="d-flex align-items-center">
                  <input
                    className='form-control d-inline-block num-comps-input'
                    disabled="disabled"
                    type="number"
                    name="num_comps"
                    value={this.state.value}
                  />
                  <a
                    role="button"
                    key='edit'
                    onClick={() => this.onEdit()}
                  >
                    <i className='edit-icon fi-pencil icon-base ms-2' />
                  </a>
                </div>
              )}
              {!this.props.is_editable && (
                <div className="d-flex align-items-center">
                  <input
                    className='form-control d-inline-block num-comps-input'
                    disabled="disabled"
                    type="number"
                    name="num_comps"
                    value={this.state.value}
                  />
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    )
  }
}
