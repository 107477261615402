import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel, selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getVenue } from 'actions/api/venues'

import EditableDocumentsList from 'components/documents/EditableDocumentsList'
import LoadingState from 'components/LoadingState'
import SaveBar from 'components/SaveBar'
import ErrorLabel from 'components/ErrorLabel'
import Row from 'components/Row'

import { VENUE_DOCUMENT_NAMES } from 'static'

class VenueWelcomeDocumentsPage extends LoadingState {

  componentDidMount() {
    this.setLoading(() => this.props.getVenue(this.props.match.params.id).then((res) => this.clearLoading()))
  }

  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      props.getVenue(props.match.params.id)
    }
  }

  submit() {
    this.props.history.push(`/welcome/wrap_up/venue`)
  }

  render() {
    const venue = this.props.venue || {}
    const attachments = venue.attachments || []

    return (
      <>
        <div className='container mt-3'>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-9'>
              <h3>
                Add Venue Documents
              </h3>

              <div>
                <EditableDocumentsList
                  attachable={venue}
                  attachments={attachments}
                  name_options={VENUE_DOCUMENT_NAMES}
                  is_editable={venue.is_admin}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
          save_text='continue'
          hide_cancel
        />
      </>
    )
  }

}

export default connect((state, props) => ({
  venue: selectModel('venues', props.match.params.id, Resources.venue, state),
}), (dispatch) => bindActionCreators({
  getVenue
}, dispatch))(VenueWelcomeDocumentsPage)
