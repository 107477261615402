import React from 'react'
import _ from 'lodash'

export default class AttachmentsList extends React.Component {

  render() {
    const attachments = _.get(this.props, 'attachments', [])

    if (_.isEmpty(attachments)) {
      return null
    }

    return (
      <div>
        <h5>Documents</h5>
        <div className='list-group list-group-striped'>
          {_.map(attachments, (attachment) => (
            <a className='list-group-item list-group-item-action text-decoration-none text-primary'
               key={`${attachment.id}-attachment`}
               href={attachment.url}
               target='_blank'
            >
              <i className='fi-page-filled'></i>&nbsp;{attachment.name}
            </a>
          ))}
        </div>
      </div>
    )
  }

}
