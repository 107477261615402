import React from 'react'

import { staticAssetUrl } from 'utils'

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const AUTOPLAY_INTERVAL = 5000

const IMAGES = [
  'Landing_Page/Screenshots/1_UpcomingEvents.png',
  'Landing_Page/Screenshots/2_GigPage.png',
  'Landing_Page/Screenshots/3_PerformanceDetails.png',
  'Landing_Page/Screenshots/4_GuestList.png',
  'Landing_Page/Screenshots/5_LodgingDetails.png',
  'Landing_Page/Screenshots/6_BandProfile.png',
]

export default class LandingScreenshots extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      carousel_index: 0,
    }
  }

  lightbox(i) {
    this.setState({
      carousel_index: i,
    })
  }

  render() {
    const index = this.state.carousel_index
    return(
      <React.Fragment>
        <div className='screenshot-container'>
          <div className='carousel-container'>
            <Carousel
              selectedItem={index}
              autoPlay={!this.state.show_lightbox}
              interval={AUTOPLAY_INTERVAL}
              onChange={(i) => this.setState({ carousel_index: i })}
              onClickItem={(i) => this.setState({ carousel_index: (index+1) % IMAGES.length })}
              showStatus={false}
              showThumbs={false}
              useKeyboardArrows
              dynamicHeight
              infiniteLoop
            >
              {_.map(IMAGES, (file) => (
                <div key={file}>
                  <img src={staticAssetUrl(file)} className='landing-screenshot' />
                </div>
              ))}
            </Carousel>
          </div>

          <div className='color-split'>
            <div className='left split'></div>
            <div className='right split'></div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}
