import React from 'react'

import Overlay from 'components/Overlay'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { invitePerson } from 'actions/api/people'
import { dismissInviteUserOverlay } from 'actions/ui'

const SUCCESS_DISMISS_DELAY_TIME = 3 * 1000

const initial_state = {
  is_loading: false,
  show_success: false,
}

class InviteOverlay extends React.Component {

  constructor(props) {
    super(props)

    this.state = { ...initial_state }
  }

  resetState() {
    this.setState({ ...initial_state })
  }

  onDismiss() {
    this.props.dismissInviteUserOverlay()
    this.resetState()
  }

  sendInvite() {
    if (!this.props.person) { return null }
    this.setState({
      is_loading: true,
    }, () => {
      this.props.invitePerson(this.props.person.id).then(
        (response) => {
          this.setState({
            is_loading: false,
            show_success: !response.error,
          }, () => {
            if (!response.error) {
              setTimeout(() => this.onDismiss(), SUCCESS_DISMISS_DELAY_TIME)
            }
          })
        }
      )
    })
  }

  render() {
    const is_hidden = !this.props.person || this.props.server_error
    const person = this.props.person || {}

    return (
      <Overlay is_hidden={is_hidden} onDismiss={() => this.onDismiss()}>
        <h4>
          Invite {person.name} to join Backline.me.
        </h4>
        {person.email &&
          <div className='mb-3'>
            {person.email}
          </div>
        }
        <button
          className='btn btn-primary'
          onClick={() => this.sendInvite()}
          disabled={this.state.is_loading}
        >Send Invite</button>

        {this.props.error_message &&
          <div className='alert alert-danger mt-3 mb-0'>
            {this.props.error_message}
          </div>
        }

        {this.state.show_success &&
          <div className='alert alert-success mt-3 mb-0'>
            Email invite successfully sent to {person.name} ({person.email}).
          </div>
        }
      </Overlay>
    )
  }
}

export default connect((state, props) => {
  return {
    person: state.overlay.invite_overlay_person,
    error_message: state.api.errors.invite_user,
    server_error: state.api.server_error,
  }
}, (dispatch) => bindActionCreators({
  invitePerson, dismissInviteUserOverlay
}, dispatch))(InviteOverlay)
