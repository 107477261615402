import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import Avatar from 'components/Avatar'
import LoadingState from 'components/LoadingState'
import SafeLink from 'components/SafeLink'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { resourceGet } from 'actions/api'

class MembershipInvites extends LoadingState {

  accept(url, index) {
    this.setLoading(() => {
      this.props.resourceGet(`/api${url}/accept`).then(
        (response) => this.clearLoading())
    })
  }

  reject(url, index) {
    this.setLoading(() => {
      this.props.resourceGet(`/api${url}/reject`).then(
        (response) => this.clearLoading())
    })
  }

  renderInvite(invite, index) {
    return (
      <div key={invite.id} className='p-2 border bg-light mb-3 rounded'>
        <div className='d-flex flex-column flex-md-row align-items-center'>
          <div className='flex-grow-0'>
            <Avatar
              url={invite.joinable_avatar_url}
              classes='medium-avatar'
              blank_icon={invite.joinable_type}
            />

            {invite.joinable_type}&nbsp;&ndash;&nbsp;
            <strong>
              <SafeLink to={invite.joinable_url}>
                {invite.joinable_name}
              </SafeLink>
            </strong>
          </div>
          <div className='flex-grow-1 text-end mb-1 mb-md-0'>
            <div className='btn-group'>
              <a
                className='btn btn-primary mb-0'
                onClick={() => this.accept(invite.url, index)}
                disabled={this.state.is_loading}
              >accept</a>
              <a
                className='btn btn-danger mb-0'
                onClick={() => this.reject(invite.url, index)}
                disabled={this.state.is_loading}
              >reject</a>
            </div>
          </div>
        </div>

      </div>
    )
  }

  render() {
    const invites = this.props.invites || []
    return _.map(this.props.invites, (invite, nx) => this.renderInvite(invite, nx, true))
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  resourceGet
}, dispatch))(MembershipInvites)
