export const PERMISSIONS = {
  UNAUTHORIZED: 0,
  VIEWABLE: 1,
  EDITABLE: 2,
}

export const VIEWABLE_PERMISSIONS = [
  PERMISSIONS.VIEWABLE,
  PERMISSIONS.EDITABLE,
]

export const EDITABLE_PERMISSIONS = [
  PERMISSIONS.EDITABLE,
]

export const PERMISSIONS_OPTIONS = [
  {
    label: 'None',
    value: PERMISSIONS.UNAUTHORIZED,
  },
  {
    label: 'View Only',
    value: PERMISSIONS.VIEWABLE,
  },
  {
    label: 'View & Edit',
    value: PERMISSIONS.EDITABLE,
  },
]

