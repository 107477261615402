import React from 'react'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { adminGetPerformance } from 'actions/api/admin/performances'

import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import AdminHeader from 'components/admin/AdminHeader'

import ShowContacts from 'components/contacts/ShowContacts'
import PersonnelList from 'components/crew_members/PersonnelList'
import LoadingState from 'components/LoadingState'
import ConfirmationButton from 'components/ConfirmationButton'

import EditableAttachmentsList from 'components/documents/EditableAttachmentsList'
import EditablePerformanceType from 'components/performances/EditablePerformanceType'
import EditableNumComps from 'components/performances/EditableNumComps'
import EditableNumDayOfShow from 'components/performances/EditableNumDayOfShow'
import EditableProductionDescription from 'components/performances/EditableProductionDescription'
import EditableTextArea from 'components/gigs/editable/EditableTextArea'

import {
  PERSPECTIVE_TYPE,
  BAND_DOCUMENT_NAMES,
  INVITE_STATUS,
  CREWABLE_TYPE
} from 'static'

class AdminPerformancePage extends LoadingState {

  componentWillMount() {
    this.setLoading(() => this.props.adminGetPerformance(this.props.match.params.id).then((res) =>
      this.setState({ is_loading: false, is_loaded: true })))
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setState(
        { is_loading: true, is_loaded: false },
        () => props.adminGetPerformance(props.match.params.id).then((res) =>
          this.setState({ is_loading: false, is_loaded: true })
        )
      )
    }
  }

  canEditPerformance() { return false }
  canEditPerformanceType() { return false }

  renderContacts() {
    const performance = this.props.performance || {}
    const contacts = performance.contacts || []
    if (contacts.length < 1) { return null }

    return (
      <div>
        <h5>
          Contacts
          {this.canEditPerformance() && (
            <SafeLink to={`/admin${performance.url}/crew_members`}>
              <i className='half-margin-left edit-icon fi-pencil'></i>
            </SafeLink>
          )}
        </h5>
        <ShowContacts
          contacts={contacts}
          show_avatar
          show_invite
          show_is_on_site
          is_admin_view
          hide_title
        />
      </div>
    )
  }

  render() {
    if (!this.props.performance) { return null }

    const performance = this.props.performance || {}
    const gig = performance.gig || {}
    const band = performance.band || {}
    const attachments = performance.attachments || []

    return (
      <div className='container'>
        <AdminHeader />

        <SafeLink
          to={`/admin${gig.url}`}
          className='mb-1 display-block'
        >&lt; back to {gig.gig_name || gig.default_name}</SafeLink>

        <PageTitle>Performance Details</PageTitle>

        <div className='card mt-2'>
          <div className={classnames('p-2', 'header-bg', {
            'red': performance.invite_status === INVITE_STATUS.REJECTED,
            'blue': performance.invite_status === INVITE_STATUS.PENDING,
          })}>
            <SafeLink to={`/admin${band.url}`}>
              <div className='d-flex align-items-center'>
                <div>
                  <Avatar
                    url={band.avatar_url}
                    classes='medium-avatar'
                    small_classes='smaller-avatar'
                    responsive_size
                    blank_icon='Band'
                  />
                </div>

                <div>
                  <h4 className='m-0'>
                    {band.name}&nbsp;
                    {band.username &&
                      <span className='smaller-text'>
                        (@{band.username})
                      </span>
                    }
                  </h4>
                </div>
              </div>
            </SafeLink>
          </div>

          <hr className='m-0' />

          {performance.invite_status === INVITE_STATUS.REJECTED && (
            <div className='p-3 text-center'>
              <div>
                <strong> Performance Invite Rejected </strong>
              </div>

              <a
                className='text-danger'
                onClick={() => this.onDelete()}
                disabled={this.state.is_loading}
              >Click here to remove band</a>
            </div>
          )}

          {performance.invite_status !== INVITE_STATUS.REJECTED && (
            <div className='p-3'>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  {performance.invite_status === INVITE_STATUS.PENDING && (
                    <div>
                      <strong>
                        (pending)
                      </strong>
                    </div>
                  )}

                  <EditablePerformanceType
                    update={(update) => this.updatePerformance(update)}
                    performance_type={performance.performance_type}
                    is_editable={this.canEditPerformanceType()}
                  />

                  <div className='mb-3'>
                    <span>
                      <SafeLink to={`/admin${gig.url}/guest_list/${performance.id}`}>
                        View Guest List
                      </SafeLink>
                      <span>&nbsp;|&nbsp;</span>
                    </span>

                    <div>
                      <EditableNumComps
                        update={(update) => this.updatePerformance(update)}
                        num_comps={performance.num_comps}
                        comps_used={performance.comps_used}
                        is_editable={this.canEditPerformance()}
                      />
                    </div>
                    <div>
                      <EditableNumDayOfShow
                        update={(update) => this.updatePerformance(update)}
                        num_day_of_show={performance.num_day_of_show}
                        is_editable={this.canEditPerformance()}
                      />
                    </div>
                  </div>

                  {this.renderContacts()}
                  <EditableProductionDescription
                    update={(update) => this.updatePerformance(update)}
                    production_description={performance.production_description}
                    house_sound={performance.house_sound}
                    house_lights={performance.house_lights}
                    is_editable={this.canEditPerformance()}
                  />
                  <EditableTextArea
                    update={(update) => this.updatePerformance(update)}
                    value={performance.transportation}
                    is_editable={this.canEditPerformance()}
                    post_key='transportation'
                    title='Transportation'
                    placeholder='Add your transportation details here...'
                  />

                </div>

                <div className='col-12 col-md-6'>
                  <EditableAttachmentsList
                    documentable={performance}
                    name_options={BAND_DOCUMENT_NAMES}
                    is_editable={this.canEditPerformance()}
                    is_loaded={this.state.is_loaded}
                  />

                  <PersonnelList
                    is_editable={this.canEditPerformance()}
                    crewable={performance}
                    is_admin_view
                  />

                  <EditableTextArea
                    update={(update) => this.updatePerformance(update)}
                    value={performance.show_notes}
                    is_editable={this.canEditPerformance()}
                    post_key='show_notes'
                    title='Additional Show Notes'
                    placeholder='Add your additional show notes here...'
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {this.canEditPerformance() && (
          <div className='row d-flex'>
            <div className='col-12 col-lg-6 offset-lg-3 pt-3'>
              <div className='card p-3'>
                <p>
                  <strong>Removing this band will permanently delete all show specific band information for this gig (guest list, set time, personnel list, etc).</strong>
                  <span>&nbsp;This may also cause you to lose access to this gig advance.</span>
                </p>
                <ConfirmationButton
                  button_classes='m-0'
                  button_type='danger'
                  button_text='remove band'
                  confirm_text='really remove?'
                  onConfirm={() => this.onDelete()}
                  disabled={this.state.is_loading}
                />
              </div>
            </div>
          </div>
        )}

        {this.renderLoadingPopup()}
      </div>
    )
  }

}

export default connect((state, ownProps) => ({
  performance: selectModel('performances', ownProps.match.params.id, Resources.performance, state),
}), (dispatch) => bindActionCreators({
  adminGetPerformance
}, dispatch))(AdminPerformancePage)
