import React from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createAttachment, updateAttachment, deleteAttachment } from 'actions/api/attachments'

import NewAttachmentForm from 'components/documents/NewAttachmentForm'
import EditableAttachment from 'components/documents/EditableAttachment'
import LoadingState from 'components/LoadingState'

import { iff, isSuccessStatus } from 'utils'

class EditableAttachmentsList extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      show_new_form: false,
      new_attachment: {},
      attachments: [],
    }
  }

  componentWillReceiveProps(props) {
    if (!this.props.is_loaded && props.is_loaded) {
      this.setState({ attachments: _.get(props, `documentable.attachments`, []) })
    }
  }

  createRequest() {
    const documentable = this.props.documentable || {}
    const data = this.state.new_attachment || {}
    this.setLoading(() => this.props.createAttachment(documentable.url, data).then((res) => {
      if (res.error) {
        this.clearLoading()
      } else {
        this.setState({
          attachments: [
            ...this.state.attachments,
            _.get(res, 'payload.raw.attachment', {}),
          ],
          new_attachment: {},
          show_new_form: false,
          is_loading: false,
        })
      }
    }))
  }

  updateRequest(i) {
    const attachment = this.state.attachments[i]
    this.setLoading(() => this.props.updateAttachment(attachment.id, attachment).then((res) => {
      if (res.error) {
        this.clearLoading()
      } else {
        const attachments = [
          ...this.state.attachments.slice(0, i),
          _.get(res, 'payload.raw.attachment', {}),
          ...this.state.attachments.slice(i+1),
        ]
        this.setState({
          attachments,
          is_loading: false,
        })
      }
    }))
  }

  deleteRequest(i) {
    const attachment = this.state.attachments[i]
    this.setLoading(() => this.props.deleteAttachment(attachment.id).then((res) => {
      if (res.error) {
        this.clearLoading()
      } else {
        const attachments = [
          ...this.state.attachments.slice(0, i),
          ...this.state.attachments.slice(i+1),
        ]
        this.setState({
          attachments,
          is_loading: false,
        })
      }
    }))
  }

  genOnUpdate(i) {
    return (update, callback) => {
      const attachments = _.get(this.state, 'attachments', [])
      if (attachments.length <= i) {
        throw 'AdvanceDocumentsPage called attachment update function on an out of range index'
      }
      const attachment = attachments[i]
      this.setState({
        attachments: [
          ...this.state.attachments.slice(0, i),
          { ...attachment, ...update, },
          ...this.state.attachments.slice(i+1),
        ]
      }, () => iff(callback))
    }
  }

  updateNewAttachment(update, callback) {
    this.setState({
      new_attachment: {
        ...this.state.new_attachment,
        ...update,
      }
    }, () => iff(callback))
  }

  render() {
    const documentable = this.props.documentable || {}
    const attachments = this.state.attachments || []

    return (
      <div>
        <h5>Documents</h5>
        <div className='list-group list-group-striped'>
          {_.map(attachments, (a, i) => (
            <EditableAttachment
              key={`documentable-${documentable.id}-attachment-${a.id}`}
              attachment={a}
              documentable={documentable}
              isEditable={this.props.is_editable}
              isLoading={this.state.is_loading}
              nameOptions={this.props.name_options}
              onUpdate={this.genOnUpdate(i)}
              onSave={() => this.updateRequest(i)}
              onDelete={() => this.deleteRequest(i)}
            />
          ))}

          {this.props.is_editable && !this.state.show_new_form && (
            <a
              className='list-group-item list-group-item-action hover-bg-gray link text-dark bold text-center'
              onClick={() => this.setState({ show_new_form: true })}
            ><i className='fi-plus'></i> Add Document</a>
          )}

          {!this.props.is_editable && !this.state.show_new_form && _.isEmpty(attachments) && (
            <div className='list-group-item'>
              You have no documents.
            </div>
          )}

          {this.props.is_editable && this.state.show_new_form && (
            <NewAttachmentForm
              attachment={this.state.new_attachment}
              documentable={documentable}
              nameOptions={this.props.name_options}
              onClose={() => this.setState({ show_new_form: false })}
              onSave={() => this.createRequest()}
              onUpdate={(update, callback) => this.updateNewAttachment(update, callback)}
              isLoading={this.state.is_loading}
            />
          )}
        </div>
      </div>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  createAttachment, updateAttachment, deleteAttachment
}, dispatch))(EditableAttachmentsList)
