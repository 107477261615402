import { resourceRSAA } from 'utils'

export const ADMIN_SCHEDULE_TEMPLATE_ACTION = 'ADMIN//SCHEDULE_TEMPLATE'
export function adminCreateScheduleTemplate(id, data) {
  return resourceRSAA(ADMIN_SCHEDULE_TEMPLATE_ACTION, `/api/admin/schedule_templatable/${id}/schedule_templates`, {
    method: 'POST',
    body: JSON.stringify({ schedule_template: data }),
  })
}

export function adminGetScheduleTemplate(id) {
  return resourceRSAA(ADMIN_SCHEDULE_TEMPLATE_ACTION, `/api/admin/schedule_templates/${id}`)
}

export function adminUpdateScheduleTemplate(id, data) {
  return resourceRSAA(ADMIN_SCHEDULE_TEMPLATE_ACTION, `/api/admin/schedule_templates/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ schedule_template: data }),
  })
}

export function adminDeleteScheduleTemplate(id) {
  return resourceRSAA(ADMIN_SCHEDULE_TEMPLATE_ACTION, `/api/admin/schedule_templates/${id}`, {
    method: 'DELETE',
  })
}

export function adminSaveScheduleTemplateFromGig(gig_id, data) {
  return resourceRSAA(ADMIN_SCHEDULE_TEMPLATE_ACTION, `/api/admin/gigs/${gig_id}/save_schedule`, {
    method: 'POST',
    body: JSON.stringify({ schedule_template: data }),
  })
}

export function adminApplyScheduleTemplateToGig(id, gig_id, band_names) {
  return resourceRSAA(ADMIN_SCHEDULE_TEMPLATE_ACTION, `/api/admin/schedule_templates/${id}/apply`, {
    method: 'PUT',
    body: JSON.stringify({
      gig_id,
      band_names,
    }),
  })
}
