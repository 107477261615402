import React from 'react'

import ShowScheduleItem from 'components/schedule/ShowScheduleItem'
import ScheduleItemForm from 'components/schedule/ScheduleItemForm'

export default class EditableScheduleItem extends React.Component {

  onUpdate(update) {
    if (this.props.onUpdate) {
      this.props.onUpdate(update)
    }
  }

  onSave(update) {
    if (this.props.onSave) {
      this.props.onSave(update)
    }
  }

  onEdit() {
    if (this.props.onEdit) {
      this.props.onEdit()
    }
  }

  onDelete() {
    if (this.props.onDelete) {
      this.props.onDelete()
    }
  }

  renderEdit() {
    return (
      <ScheduleItemForm
        schedule_item={this.props.schedule_item}
        onUpdate={(u) => this.onUpdate(u)}
        onSave={(u) => this.onSave(u)}
        onDelete={() => this.onDelete()}
        is_mobile={this.props.is_mobile}
        is_disabled={this.props.is_disabled}
      />
    )
  }

  renderShow() {
    return (
      <ShowScheduleItem
        schedule_item={this.props.schedule_item}
        onEdit={() => this.onEdit()}
        show_notes={this.props.show_notes}
        is_mobile={this.props.is_mobile}
        is_editable={this.props.is_editable}
        show_duration={this.props.show_duration}
      />
    )
  }

  render() {
    if (this.props.schedule_item.is_editing) {
      return this.renderEdit()
    } else {
      return this.renderShow()
    }
  }
}
