import React, { useState } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { login } from 'actions/api/users'

import SafeLink from 'components/SafeLink'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'

class LoginForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      user: {},
    }
  }

  updateUser(update) {
    this.setState({
      user: {
        ...this.state.user,
        ...update,
      }
    })
  }

  submit(e) {
    this.setState({ is_loading: true }, () => {
      this.props.login({ user: this.state.user })
        .then((response) => {
          if (response.payload.request_status !== 200) {
            this.setState({ is_loading: false })
          }
        })
    })

    if (e) { e.preventDefault() }
  }

  render() {
    return (
      <div className='container mt-2'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-6'>
            <h1>Login</h1>
            <form onSubmit={(e) => this.submit(e)}>
              <div className='mb-3'>
                <FormField
                  name='email'
                  autoComplete='email'
                  type='email'
                  label='Email or Username'
                  errors={this.props.api_errors}
                  value={this.state.email}
                  onChange={(e) => this.updateUser({ email: e.target.value })}
                />
              </div>

              <div className='mb-3'>
                <FormField
                  name='password'
                  autoComplete='password'
                  type='password'
                  label='Password'
                  errors={this.props.api_errors}
                  value={this.state.password}
                  onChange={(e) => this.updateUser({ password: e.target.value })}
                />
              </div>

              <FormCheck
                className='mb-3'
                name='save_login'
                id='save_login'
                type='checkbox'
                label='Keep Me Logged In'
                checked={this.state.save_login}
                onChange={(e) => this.updateUser({ save_login: e.target.checked })}
              />

              <input
                type='submit'
                value='Login'
                className='btn btn-primary text-light'
                disabled={this.state.is_loading}
                onClick={(e) => this.submit(e)}
              />
            </form>

            <SafeLink to="/password_reset">
              Forgot your password?
            </SafeLink>
          </div>
        </div>
      </div>
    )
  }

}

export default connect((state, props) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  login
}, dispatch))(LoginForm)
