import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { selectApiIndex } from 'selectors'
import { Resources } from 'schema'

import FormField from 'components/FormField'

import {
  createTicketInputter,
  getTicketInputters,
  destroyTicketInputter,
} from 'actions/api/ticket_input'

const ErrorMessage = ({ message }) => <div className="red-text margin-bottom">{message}</div>

const ManageTicketInputters = ({
  gigId,
  getTicketInputters,
  ticket_inputters = [],
  createTicketInputter,
  destroyTicketInputter,
}) => {
  const ticketInputters = Object.values(ticket_inputters) || []
  const [newTicketInputter, setNewTicketInputter] = useState({ name: null, email: null })
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [isShowNewTicketInputterForm, setIsShowNewTicketInputterForm] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    getTicketInputters(gigId)
  }, [])

  const updateNewTicketInputter = update => {
    const newData = { ...newTicketInputter, ...update }
    setNewTicketInputter(newData)
  }

  const clearTicketInputter = () => {
    setNewTicketInputter({ name: null, email: null })
    setIsSuccessful(false)
    setErrorMessage()
  }

  const submitNewTicketInputter = () => {
    setErrorMessage()
    if (newTicketInputter.email) {
      createTicketInputter(gigId, newTicketInputter).then(res => {
        if (res?.payload?.request_status === 422) {
          setErrorMessage(res?.payload?.message)
        } else if (res?.payload?.request_status === 200) {
          setIsSuccessful(true)
        }
      })
    }
  }

  const removeTicketInputter = id => {
    destroyTicketInputter(id)
  }


  return (
    <>
      <div className='list-group list-group-striped mb-3'>
        {ticketInputters?.map((inputter, i) => (
          <div
            key={`ticket-inputter-${i}-${inputter.email}`}
            className='list-group-item d-flex flex-row align-items-center'
          >
            <div className='flex-grow-1 text-start'>
              {inputter.name}&nbsp;&ndash;&nbsp;{inputter.email}
            </div>
            <div className='flex-grow-0 text-end'>
              <a onClick={() => removeTicketInputter(inputter.id)} role="button">
                <i className='fi-trash delete-icon'></i>
              </a>
            </div>
          </div>
        ))}
      </div>

      {isSuccessful ? (
        <>
          <div className="alert alert-success">
            Successfully added ticket inputter: {newTicketInputter.email}
          </div>
          <a
            className='btn btn-primary'
            onClick={() => clearTicketInputter()}
          >Add New Ticket Contact</a>
        </>
      ) : isShowNewTicketInputterForm ? (
        <>
          <div className='p-3 bg-light rounded border'>
            <h6>Add Ticket Contact</h6>
            <div className='row mb-2'>
              <div className='col-12 col-md-6'>
                <FormField
                  type="text"
                  label="Name"
                  name="name"
                  placeholder="Billy Joel"
                  value={newTicketInputter.name}
                  onChange={e => updateNewTicketInputter({ name: e.target.value })}
                />
              </div>
              <div className='col-12 col-md-6'>
                <FormField
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="billyjoel81@example.com"
                  value={newTicketInputter.email}
                  onChange={e => updateNewTicketInputter({ email: e.target.value })}
                />
              </div>
            </div>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <a
              className='btn btn-primary me-1'
              onClick={() => submitNewTicketInputter()}
            >Save</a>
            <a
              className='btn btn-gray'
              onClick={() => setIsShowNewTicketInputterForm(false)}
            >Cancel</a>
          </div>
        </>
      ) : (
        <>
          <a
            className='btn btn-primary'
            onClick={() => setIsShowNewTicketInputterForm(true)}
          >Add New Ticket Contact</a>
        </>
      )}
    </>
  )
}

export default connect((state, _) => {
  const { index } = selectApiIndex('ticket_inputters', Resources.ticket_inputter, state) || {}
  const inputters = Object.values(index) || []
  const filteredIndex = inputters.filter(inputter => !inputter._destroyed)

  return { ticket_inputters: filteredIndex }
}, (dispatch) => bindActionCreators({
  createTicketInputter,
  getTicketInputters,
  destroyTicketInputter,
}, dispatch))(ManageTicketInputters)
