import _ from 'lodash'
import React from 'react'
import classnames from 'classnames'

import SafeLink from 'components/SafeLink'
import AdvanceFloatingRegistration from 'components/advance/AdvanceFloatingRegistration'

export default class AdvanceProgress extends React.Component {

  render() {
    if (!this.props.advanceable) { return null }

    const advanceable = this.props.advanceable|| {}
    const sectionData = this.props.sectionData || []
    return (
      <nav aria-label='breadcrumb' className='p-3 mt-2 mb-3 bg-light'>
        <ol className='breadcrumb mb-0'>
          {_.map(sectionData, (section, i) => (
            <li
              key={`${i}-${section.name}`}
              className={classnames('breadcrumb-item', {
                'active': this.props.pageNum === i,
              })}
            >
              {this.props.pageNum === i ? (
                <>
                  {section.name}
                </>
              ) : (
                <SafeLink to={`${advanceable.url}${section.url}`}>
                  {section.name}
                </SafeLink>
              )}
            </li>
          ))}
        </ol>
        <AdvanceFloatingRegistration />
      </nav>
    )
  }

}
