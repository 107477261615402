import React from 'react'

import RadioButtons from 'components/RadioButtons'

import { PERMISSIONS_OPTIONS } from 'static'

export default class PermissionFields extends React.Component {

  render() {
    return (
      <>
        <label htmlFor={this.props.name}>
          <strong> {this.props.label} </strong>
        </label>
        <div>
          <RadioButtons
            label_classes='d-inline-block me-3'
            input_classes='mb-2'
            name={this.props.name}
            value={this.props.value}
            options={PERMISSIONS_OPTIONS}
            onChange={(v) => this.props.onChange(v)}
            disabled={this.props.disabled}
          />
        </div>
      </>
    )
  }

}
