import React from 'react'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'

import { parseUrlParams } from 'utils'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { confirmEmail } from 'actions/api/users'
import { selectCurrentUser } from 'selectors'

class EmailConfirmed extends React.Component {

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentWillReceiveProps(props) {
    if (props.user && props.user.is_email_confirmed) {
      setTimeout(() => { props.history.push(`/welcome`) }, 2000)
    }
  }

  componentDidMount() {
    const url_info = parseUrlParams(this.props.location.search)
    const params = url_info.params || {}
    this.props.confirmEmail(params.c_key).then((response) => {
      this.setState({ is_confirmation_attempted: true })
    })
  }

  render() {
    return (
      <div className='container mt-3'>
        {!this.state.is_confirmation_attempted ? (
          <div className='alert alert-warning'>
            <p className='mb-0'>
              Your email address is being confirmed...
            </p>
          </div>
        ) : this.props.user.is_email_confirmed ? (
          <div className='alert alert-success'>
            <p>
              Your email address has been confirmed!
            </p>
            <p className='mb-0'>
              You will be redirected in a few seconds.
              If you are not redirected automatically <SafeLink to="/welcome">click here</SafeLink> to begin setting up your band or venue.
            </p>
          </div>
        ) : (
          <div className='alert alert-danger'>
            <p className='mb-0'>
              Your email has not been confirmed.  Chances are that your confirmation link has expired, <a href="/email_confirmation">go here</a> to send yourself a new one. If the problem persists, send us an email at <a href="mailto:info@backline.me">info@backline.me</a>.
            </p>
          </div>
        )}
      </div>
    )
  }

}


export default connect((state, props) => ({
  user: selectCurrentUser(state)
}), (dispatch) => bindActionCreators({
  confirmEmail
}, dispatch))(withRouter(EmailConfirmed))
