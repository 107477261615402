import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { withRouter } from 'react-router'
import { getGig, approveSchedule } from 'actions/api/gigs'
import {
  createScheduleItem,
  updateScheduleItem,
  deleteScheduleItem,
  updateGigScheduleItems
} from 'actions/api/schedule_items'

import {
  setSaveScheduleOverlay,
  setApplyScheduleOverlay,
  setLinkScheduleOverlay,
  setForceEditableListReload
} from 'actions/ui'

import PageTitle from 'components/PageTitle'
import EditableList from 'components/EditableList'
import EditableScheduleItem from 'components/schedule/EditableScheduleItem'
import ScheduleApprovalNotice from 'components/schedule/ScheduleApprovalNotice'
import BreadCrumbs from 'components/BreadCrumbs'

import {
  timeToMinutes,
  isEditable,
  getBandNamesFromScheduleItems,
  isScheduleApprovalEnabledForGig,
} from 'utils'

import showToast from 'showToast'

class EditableSchedule extends EditableList {

  onReceiveProps(props) {
    const new_force_reload = _.get(props, 'force_editable_list_reload', false)
    const old_force_reload = _.get(this, 'props.force_editable_list_reload', false)
    if (new_force_reload && !old_force_reload) {
      this.setLoading(() => this.loadListDataRequest(props).then((res) => {
        this.handleLoadListResponse(res)
        props.setForceEditableListReload(false)
      }))
    }
  }

  // Override
  getIdFromProps(props) { return props.match.params.id }

  // Override
  getDataFromListItem(li) {
    return _.pick(li, [ 'performance_id', 'label', 'time', 'duration', 'band_name' ])
  }

  // Override
  getListItemFromResponse(response) {
    return _.get(response, 'payload.raw.schedule_item', {})
  }

  // Override
  getListFromResponse(response) {
    return _.get(response, 'payload.raw.gig.schedule_items', [])
  }

  // Override
  validateOnSaveAll() {
    const allValid = this.state.editable_list.every(listItem => {
      return listItem.time?.length > 0 && listItem.label?.length > 0
    })

    if (!allValid) {
      const toastProps = {
        message: 'Each schedule item must have at least a time and a label.',
        isSuccess: false,
      }
      showToast(toastProps)
    }

    return allValid
  }

  // Override
  isEditable() {
    const gig = this.props.gig || {}
    const perspective = gig.perspective || {}
    const isScheduleApprovalEnabled = isScheduleApprovalEnabledForGig(gig)

    if (isScheduleApprovalEnabled) {
      const isApprovedByPerspective = gig.is_schedule_approved_by_perspective
      return isEditable(perspective.gig_permissions) && perspective.is_gig_owner && isApprovedByPerspective
    }

    return isEditable(perspective.gig_permissions) && perspective.is_gig_owner
  }

  // Override
  getAddButtonText() {
    return "+ Add Schedule Item"
  }

  // Override
  getNewListItemProps() {
    return { gig_id: this.props.match.params.id }
  }

  /**********************************
   * REQUESTS
   **********************************/
  // Override
  loadListDataRequest(props) {
    return this.props.getGig(props.match.params.id)
  }

  // Override
  updateListItemRequest(id, data) {
    return this.props.updateScheduleItem(id, data)
  }

  // Override
  deleteListItemRequest(id) {
    return this.props.deleteScheduleItem(id)
  }

  // Override
  createListItemRequest(data) {
    return this.props.createScheduleItem(this.props.match.params.id, data)
  }

  // Override
  saveListRequest(data) {
    return this.props.updateGigScheduleItems(this.props.match.params.id, this.state.editable_list)
  }
  /****************************************/
  /****************************************/

  showScheduleNotice() {
    return this.props.gig && isScheduleApprovalEnabledForGig(this.props.gig)
  }

  sortEditableList(list) {
    return _.sortBy(list, (t) => timeToMinutes(t.time))
  }

  renderMobile() {
    return (
      <div className='container mt-2 mb-4'>
        {this.renderTitle()}
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='160'>Time</th>
              <th>Details</th>
              {this.isEditable() && (
                <th width='30'></th>
              )}
            </tr>
          </thead>
          <tbody>
            {_.map(this.state.editable_list, (si, i) => (
              <EditableScheduleItem
                key={`schedule_item-${si.id}-${i}`}
                schedule_item={si}
                onUpdate={this.genUpdateListItem(i)}
                onSave={this.genSaveListItem(i)}
                onDelete={() => this.deleteListItem(i)}
                onEdit={() => this.editListItem(i)}
                is_editable={this.isEditable()}
                is_disabled={this.state.loading_all || this.state.is_loading}
                is_mobile
              />
            ))}
          </tbody>
        </table>
        {this.renderButtons()}
        {this.renderLoadingPopup()}
      </div>
    )
  }

  renderDesktop() {
    return (
      <div className='container mt-2 mb-4'>
        {this.renderTitle()}
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th>Time</th>
              {this.isEditing() && (<th width='150'>Length</th>)}
              <th>Label</th>
              <th>Band</th>
              {this.isEditable() && (
                <th width='65'></th>
              )}
            </tr>
          </thead>
          <tbody>
            {_.map(this.state.editable_list, (si, i) => (
              <EditableScheduleItem
                key={`schedule_item-${si.id}-${i}`}
                schedule_item={si}
                onUpdate={this.genUpdateListItem(i)}
                onSave={this.genSaveListItem(i)}
                onDelete={() => this.deleteListItem(i)}
                onEdit={() => this.editListItem(i)}
                is_editable={this.isEditable()}
                is_disabled={this.state.loading_all || this.state.is_loading}
                show_duration={this.isEditing()}
              />
            ))}
          </tbody>
        </table>
        {this.renderButtons()}
        {this.renderLoadingPopup()}
      </div>
    )
  }

  renderTitle() {
    const gig = this.props.gig || {}
    const band_names = getBandNamesFromScheduleItems(_.get(this, 'props.gig.schedule_items', []))
    const perspective = _.get(gig, 'perspective', {})

    return(
      <div>
        <BreadCrumbs items={[
          { name: 'Shows', url: '/' },
          { name: gig.gig_name || gig.default_name, url: gig.url },
          { name: 'Schedule' },
        ]} />

        <PageTitle>
          Schedule
        </PageTitle>

        <h4>
          {gig.name}
        </h4>

        {this.isEditable() && (
          <div className='mb-3'>
            {perspective.is_gig_owner && perspective.is_schedule_templatable_admin && (
              <>
                <a className='link text-decoration-none' onClick={() => this.props.setSaveScheduleOverlay(gig.id, perspective.id, perspective.type)}>
                  Save Schedule as Template
                </a>
                &nbsp;|&nbsp;
                <a className='link text-decoration-none' onClick={() => this.props.setApplyScheduleOverlay(gig.id, perspective.id, perspective.type)}>
                  Apply Schedule Template
                </a>
                {band_names.length > 0 && (<>&nbsp;|&nbsp;</>)}
              </>
            )}
            {band_names.length > 0 && (
              <a className='link text-decoration-none' onClick={() => this.props.setLinkScheduleOverlay(gig.id)}>
                Link Bands to Schedule
              </a>
            )}
          </div>
        )}
        {this.showScheduleNotice() && (
          <ScheduleApprovalNotice gig={gig} approveSchedule={this.props.approveSchedule} />
        )}
      </div>
    )
  }

  render() {
    if (window.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

}


export default connect((state, ownProps) => ({
  gig: selectModel('gigs', ownProps.match.params.id, Resources.gig, state),
  force_editable_list_reload: state.force_editable_list_reload,
}), (dispatch) => bindActionCreators({
  getGig,
  approveSchedule,
  createScheduleItem,
  updateScheduleItem,
  deleteScheduleItem,
  updateGigScheduleItems,
  setSaveScheduleOverlay,
  setApplyScheduleOverlay,
  setLinkScheduleOverlay,
  setForceEditableListReload,
}, dispatch))(withRouter(EditableSchedule))
