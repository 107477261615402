import { resourceRSAA } from 'utils'

export const CREATE_OR_UPDATE_DRIVING_DISTANCE_ACTION = 'CREATE_OR_UPDATE_DRIVING_DISTANCE'
export function createOrUpdateDrivingDistance(fromId, toId, driveTime) {
  const path = `/api/driving_distances/create_or_update`
  const data = {
    from_address_id: fromId,
    to_address_id: toId,
    drive_time_minutes: driveTime,
  }
  return resourceRSAA(CREATE_OR_UPDATE_DRIVING_DISTANCE_ACTION, path, {
   method: 'POST',
   body: JSON.stringify({ driving_distance: data }),
  })
}
