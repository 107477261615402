import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { selectModel } from 'selectors'
import {
  getGigTicketCounts,
  updateGigTicketCount,
} from 'actions/api/gigs'
import { Resources } from 'schema'
import { AUTH_TYPE } from 'static/Auth'

import BreadCrumbs from 'components/BreadCrumbs'
import Row from 'components/Row'
import SafeLink from 'components/SafeLink'

const TicketInputListItem = ({ gig, count, editingCount, setEditingCount, updateGigTicketCount }) => {
  const editTicketCount = (e, countToEdit) => {
    setEditingCount(_.cloneDeep(countToEdit))

    e.stopPropagation()
    e.preventDefault()
  }

  const updateEditingCount = (type, newTypeCount) => {
    const newCount = _.cloneDeep(editingCount)

    const newType = newCount.counts.find(ct => ct.ticket_type_name === type)
    newType.count = newTypeCount

    setEditingCount(newCount)
  }

  const updateCountNote = newNote => {
    const newCount = _.cloneDeep(editingCount)

    newCount.note = newNote

    setEditingCount(newCount)
  }

  const saveChanges = e => {
    updateGigTicketCount(gig.id, editingCount).then(() => setEditingCount(null))
    e.preventDefault()
  }

  return (
    <div className="list-group-item">
      <div className='d-flex flex-row align-items-center mb-2'>
        <div className='flex-grow-1'>
          <strong>
            Ticket Count - {moment(count.date).format("MMMM Do YYYY")}
          </strong>
        </div>
        <div className='flex-grow-0 text-end'>
          {editingCount?.id === count.id ? (
            <>
              <a onClick={e => saveChanges(e)}>
                <i className='fi-check icon-base me-2 success-color'></i>
              </a>
              <a onClick={e => editTicketCount(e, null)}>
                <i className='fi-x red-text icon-base'></i>
              </a>
            </>
          ) : (
            <a onClick={e => editTicketCount(e, count)}>
              <i className="edit-icon fi-pencil icon-base"></i>
            </a>
          )}
        </div>
      </div>
      {editingCount?.id === count.id ? (
        <input
          type="text"
          className="form-control mb-2"
          name={`count-${count.id}-note`}
          placeholder="Note"
          maxLength="21844"
          defaultValue={count.note}
          onClick={e => e.preventDefault()}
          onChange={(e) => updateCountNote(e.target.value)
          }
        />
      ) : (
        <div>
          {count.note ? (
            <div className="alert alert-primary">
              Note: {count.note}
            </div>
          ) : (
            <div className="mb-2">
              <i>
                Edit to add a Note
              </i>
            </div>
          )}
        </div>
      )}
      {_.map(count.counts, ticketCount => (
        <div key={ticketCount.ticket_type_id}>
          {editingCount?.id === count.id ? (
            <div className='row align-items-center mb-2'>
              <div className='col-3 col-md-2'>
                <strong>
                  {ticketCount.ticket_type_name}
                </strong>
              </div>
              <div className='col-6 col-md-4'>
                <input
                  type="number"
                  className='form-control mb-0'
                  name={`count-${count.id}-type-${ticketCount.id}`}
                  value={_.find(editingCount.counts,
                    ct => ct.ticket_type_name === ticketCount.ticket_type_name
                  ).count}
                  onClick={e => e.preventDefault()}
                  onChange={(e) => updateEditingCount(
                      ticketCount.ticket_type_name,
                      e.target.value,
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <div className='row align-items-center'>
              <div className='col-3 col-md-2'>
                <strong>
                  {ticketCount.ticket_type_name}
                </strong>
              </div>
              <div className='col-9 col-md-10'>
                {ticketCount.count}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

const TicketCountInputIndex = ({
  gig,
  gigId,
  authContext,
  ticketCounts,
  getGigTicketCounts,
  updateGigTicketCount,
}) => {
  const [editingCount, setEditingCount] = useState()

  const showDate = moment(gig.show_date).format('MMMM Do YYYY')
  const gigName = gig.gig_name || gig.default_name
  const latestCount = _.last(gig.ticket_counts)
  const hasExistingCounts = !!latestCount

  useEffect(() => {
    getGigTicketCounts(gigId)
  }, [])

  return (
    <div className='container'>
      <BreadCrumbs
        items={[
          { name: 'Shows', url: '/' },
          { name: gig.default_name, url: gig.url },
          { name: 'Ticket Count Summary', url: `/gigs/${gigId}/ticket_counts` },
          { name: 'Edit Ticket Counts' },
        ]}
      />

      <h2>Edit Ticket Counts</h2>
      <h4 className='mb-3'>{showDate}&nbsp;&ndash;&nbsp;{gigName}</h4>

      <div className='row'>
        <div className='col-12 col-lg-9 col-xl-6'>
          <div className="list-group list-group-striped">
            {_.map(ticketCounts, count => (
              <TicketInputListItem
                key={count.id}
                gig={gig}
                count={count}
                editingCount={editingCount}
                setEditingCount={setEditingCount}
                updateGigTicketCount={updateGigTicketCount}
              />
            ))}
            {!hasExistingCounts && (
              <div className='list-group-item'>
                This show does not currently have any ticket count data.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}


export default connect((state, props) => {
  const gigId = _.get(props, 'match.params.gig_id')
  const gig = selectModel('gigs', gigId, Resources.gig, state)
  const authContext = _.get(state, 'auth_context.auth_type')
  const ticketCounts = gig?.ticket_counts && _.reverse(gig.ticket_counts) || []

  return { gig, gigId, authContext, ticketCounts }

}, (dispatch) => bindActionCreators({
  getGigTicketCounts,
  updateGigTicketCount,
}, dispatch))(TicketCountInputIndex)
