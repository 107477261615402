import React from 'react'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel, selectCurrentUser } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getPerformance, updatePerformance, deletePerformance } from 'actions/api/performances'
import { updateUserFlags } from 'actions/api/users'
import { setShareAdvanceOverlay } from 'actions/ui'

import Row from 'components/Row'
import BreadCrumbs from 'components/BreadCrumbs'
import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import InviteHeader from 'components/InviteHeader'

import ShowContacts from 'components/contacts/ShowContacts'
import PersonnelList from 'components/crew_members/PersonnelList'
import LoadingState from 'components/LoadingState'
import ConfirmationButton from 'components/ConfirmationButton'

import EditableAttachmentsList from 'components/documents/EditableAttachmentsList'
import EditablePerformanceType from 'components/performances/EditablePerformanceType'
import EditableNumComps from 'components/performances/EditableNumComps'
import EditableNumDayOfShow from 'components/performances/EditableNumDayOfShow'
import EditableProductionDescription from 'components/performances/EditableProductionDescription'
import EditableTextArea from 'components/gigs/editable/EditableTextArea'

import {
  PERFORMANCE_TYPES,
  PERSPECTIVE_TYPE,
  BAND_DOCUMENT_NAMES,
  INVITE_STATUS,
  CREWABLE_TYPE
} from 'static'

import {
  isViewable,
  isEditable,
  isEditableByPerspective
} from 'utils'

class PerformancePage extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      hide_advance_form_banner: _.get(props, 'current_user.flags.hide_advance_form_banner', false),
    }
  }

  componentWillMount() {
    this.setLoading(() => this.props.getPerformance(this.props.match.params.id).then((res) =>
      this.setState({ is_loading: false, is_loaded: true })))
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setState(
        { is_loading: true, is_loaded: false },
        () => props.getPerformance(props.match.params.id).then((res) =>
          this.setState({ is_loading: false, is_loaded: true })
        )
      )
    }

    if (_.get(props, 'current_user.id') !== _.get(this.props, 'current_user.id')) {
      this.setState({
        hide_advance_form_banner: _.get(props, 'current_user.flags.hide_advance_form_banner', false)
      })
    }
  }

  isBandOwner() {
    const performance = this.props.performance || {}
    const gig = performance.gig || {}
    const perspective = gig.perspective || {}
    return perspective.type === PERSPECTIVE_TYPE.BAND &&
        perspective.id === performance.band_id
  }

  canViewGuestList() {
    const performance = this.props.performance || {}
    const gig = performance.gig || {}
    const perspective = gig.perspective || {}
    return isViewable(perspective.guest_list_permissions) && (perspective.is_gig_owner || this.isBandOwner())
  }

  canEditPerformance() {
    const performance = this.props.performance || {}
    const gig = performance.gig || {}
    const perspective = gig.perspective || {}
    return isEditableByPerspective(perspective, performance, perspective.gig_permissions)
  }

  canEditPerformanceType() {
    const performance = this.props.performance || {}
    const gig = performance.gig || {}
    const perspective = gig.perspective || {}
    // NOTE: Exception to normal permissions, only "gig owners" can edit any performance types
    return isEditableByPerspective(perspective, performance, perspective.gig_permissions) && perspective.is_gig_owner
  }

  updatePerformance(performance_data) {
    this.setLoading(() =>
      this.props.updatePerformance(this.props.match.params.id, performance_data).then((response) =>
        this.clearLoading()))
  }

  onDelete() {
    const performance = this.props.performance || {}
    const gig = performance.gig || {}
    const perspective = gig.perspective || {}
    this.setLoading(() =>
      this.props.deletePerformance(this.props.match.params.id).then((response) => {
        if (response.error) {
          this.clearLoading()
        } else {
          if (perspective.type === PERSPECTIVE_TYPE.BAND && perspective.id === performance.band_id) {
            this.props.history.push('/')
          } else {
            this.props.history.push(gig.url)
          }
        }
      }))
  }

  showShareAdvanceOverlay() {
    const performance = this.props.performance || {}
    this.props.setShareAdvanceOverlay(CREWABLE_TYPE.PERFORMANCE, performance.id)
  }

  dismissAdvanceFormBanner() {
    this.setState({ hide_advance_form_banner: true }, () => {
      this.props.updateUserFlags({ hide_advance_form_banner: true })
    })
  }

  render() {
    if (!this.props.performance) { return null }

    const performance = this.props.performance || {}
    const gig = performance.gig || {}
    const band = performance.band || {}
    const attachments = performance.attachments || []
    const perspective = gig.perspective || {}
    const show_advance_form = this.canEditPerformance() && !band.is_owned
    const contacts = performance.contacts || []

    return (
      <div className="container">
        <BreadCrumbs
          items={[
            { name: 'Shows', url: '/' },
            { name: gig.gig_name || gig.default_name, url: gig.url },
            { name: band.name },
          ]}
        />

        <InviteHeader invite={perspective.pending_invite} />

        <h2>Performance Details</h2>

        <div className='border rounded p-0 mb-4'>
          <div className={classnames('p-3', 'bg-light', {
            'red': performance.invite_status === INVITE_STATUS.REJECTED,
            'blue': performance.invite_status === INVITE_STATUS.PENDING,
          })}>
            <SafeLink to={band.url} className='text-decoration-none'>
              <div className='d-flex flex-row align-items-center'>
                <Avatar
                  url={band.avatar_url}
                  classes='medium-avatar flex-grow-0'
                  small_classes='smaller-avatar'
                  responsive_size
                  blank_icon='Band'
                />

                <div className='flex-grow-1'>
                  <h4 className='m-0'>
                    {band.name}&nbsp;
                    {band.username &&
                      <span className='smaller-text'>
                        (@{band.username})
                      </span>
                    }
                  </h4>
                </div>
              </div>
            </SafeLink>
          </div>

          {performance.invite_status === INVITE_STATUS.REJECTED && (
            <div className='p-3 text-center border-top'>
              <div>
                <strong> Performance Invite Rejected </strong>
              </div>

              <a
                className='text-danger'
                onClick={() => this.onDelete()}
                disabled={this.state.is_loading}
              >Click here to remove band</a>
            </div>
          )}

          {performance.invite_status !== INVITE_STATUS.REJECTED && (
            <div className='p-3 border-top'>
              {show_advance_form && !this.state.hide_advance_form_banner && (
                <div className='alert alert-warning clearfix'>
                  <div className='float-end'>
                    <a onClick={() => this.dismissAdvanceFormBanner()}>
                      <button type="button" className="btn-close text-dark" aria-label="Close"></button>
                    </a>
                  </div>

                  <h4>Send an Advance Form</h4>
                  <p>
                    Since this band does not use Backline, you can use the
                    advance forms feature!  An advance form is an authorized link
                    that allows anyone with the link to directly enter/edit information for this band.
                  </p>

                  <p className='mb-0'>
                    To get started, click the "Send Advance Form" button below.
                  </p>
                </div>
              )}

              <div className='row'>
                <div className='col-12 col-md-6'>
                  {performance.invite_status === INVITE_STATUS.PENDING && (
                    <div>
                      <strong>
                        (pending)
                      </strong>
                    </div>
                  )}

                  <EditablePerformanceType
                    update={(update) => this.updatePerformance(update)}
                    performance_type={performance.performance_type}
                    is_editable={this.canEditPerformanceType()}
                    is_own_headliner={this.isBandOwner() && performance.performance_type === PERFORMANCE_TYPES.HEADLINE}
                    is_owned_support={band.is_owned && performance.performance_type === PERFORMANCE_TYPES.SUPPORT}
                  />

                  <div className='mb-3'>

                    <div className='mb-2'>
                      {this.canViewGuestList() && (
                        <div>
                          <EditableNumComps
                            update={(update) => this.updatePerformance(update)}
                            num_comps={performance.num_comps}
                            comps_used={performance.comps_used}
                            is_editable={this.canEditPerformance()}
                          />
                        </div>
                      )}

                      <EditableNumDayOfShow
                        update={(update) => this.updatePerformance(update)}
                        num_day_of_show={performance.num_day_of_show}
                        is_editable={this.canEditPerformance()}
                      />
                    </div>

                    {this.canViewGuestList() && (
                      <>
                        <SafeLink className='btn btn-gray me-1' to={`${gig.url}/guest_list/${performance.id}`}>
                          View Guest List
                        </SafeLink>
                      </>
                    )}
                    {show_advance_form && (
                      <a
                        className='btn btn-primary'
                        onClick={() => this.showShareAdvanceOverlay()}
                      >Send Advance Form</a>
                    )}
                  </div>

                  <h5>
                    Contacts
                    {this.canEditPerformance() && (
                      <SafeLink to={`${performance.url}/crew_members`}>
                        <i className='ms-2 edit-icon fi-pencil'></i>
                      </SafeLink>
                    )}
                  </h5>

                  {_.isEmpty(contacts) ? (
                    <SafeLink
                      to={`${performance.url}/crew_members`}
                      className='btn rounded bg-light border hover-bg-gray text-center d-block p-3 mb-3 text-decoration-none text-dark'
                    >
                      <h5 className='m-0'>
                        <i className='fi-plus'></i> Add Contacts
                      </h5>
                    </SafeLink>
                  ) : (
                    <div className='mb-3'>
                      <ShowContacts
                        contacts={contacts}
                        show_avatar
                        show_invite
                        show_is_on_site
                        hide_title
                      />
                    </div>
                  )}

                  <EditableProductionDescription
                    update={(update) => this.updatePerformance(update)}
                    production_description={performance.production_description}
                    house_sound={performance.house_sound}
                    house_lights={performance.house_lights}
                    is_editable={this.canEditPerformance()}
                  />

                  <EditableTextArea
                    update={(update) => this.updatePerformance(update)}
                    value={performance.transportation}
                    is_editable={this.canEditPerformance()}
                    post_key='transportation'
                    title='Transportation'
                    placeholder='Add your transportation details here...'
                  />

                </div>

                <div className='col-12 col-md-6'>
                  <div className='mb-3'>
                    <EditableAttachmentsList
                      documentable={performance}
                      name_options={BAND_DOCUMENT_NAMES}
                      is_editable={this.canEditPerformance()}
                      is_loaded={this.state.is_loaded}
                    />
                  </div>

                  <div className='mb-3'>
                    <PersonnelList
                      is_editable={this.canEditPerformance()}
                      crewable={performance}
                    />
                  </div>

                  <div className='mb-3'>
                    <EditableTextArea
                      update={(update) => this.updatePerformance(update)}
                      value={performance.show_notes}
                      is_editable={this.canEditPerformance()}
                      post_key='show_notes'
                      title='Additional Show Notes'
                      placeholder='Add your additional show notes here...'
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {this.canEditPerformance() && performance.invite_status !== INVITE_STATUS.REJECTED && (
          <div className='row mb-3'>
            <div className='col-lg-6 col-12'>
              <div className='border rounded p-3 bg-light'>
                <p>
                  <strong>Removing this band will permanently delete all show specific band information for this gig (guest list, set time, personnel list, etc).</strong>
                  {this.isBandOwner() && (
                    <span>&nbsp;This may also cause you to lose access to this gig advance.</span>
                  )}
                </p>
                <ConfirmationButton
                  button_classes='m-0'
                  button_type='danger'
                  button_text='remove band'
                  confirm_text='really remove?'
                  onConfirm={() => this.onDelete()}
                  disabled={this.state.is_loading}
                />
              </div>
            </div>
          </div>
        )}

        {this.renderLoadingPopup()}
      </div>
    )
  }

}

export default connect((state, ownProps) => ({
  current_user: selectCurrentUser(state),
  performance: selectModel('performances', ownProps.match.params.id, Resources.performance, state),
}), (dispatch) => bindActionCreators({
  getPerformance,
  updatePerformance,
  deletePerformance,
  setShareAdvanceOverlay,
  updateUserFlags
}, dispatch))(withRouter(PerformancePage))
