import React from 'react'

import PermissionFields from 'components/PermissionFields'
import FormCheck from 'components/FormCheck'

import { iff } from 'utils'

export default class MembershipDetailsForm extends React.Component {

  getKey() {
    const membership = this.props.membership || {}
    const idKey = this.props.idKey || ''
    return `${membership.id}-${idKey}`
  }

  updateMembership(update) {
    iff(this.props.onChange, update)
  }

  renderAttributes() {
    const membership = this.props.membership || {}
    return (
      <div className='border rounded p-3 bg-light'>
        <div><strong>Member Attributes</strong></div>
        <FormCheck
          className='mb-1 me-3 d-inline-block'
          label='Admin'
          name={`${this.getKey()}-admin`}
          checked={membership.is_admin}
          onChange={(e) => this.updateMembership({ is_admin: e.target.checked })}
          disabled={this.props.disabled}
        />

        <FormCheck
          className='mb-1 me-3 d-inline-block'
          label='Default Personnel'
          name={`${this.getKey()}-primary`}
          checked={membership.is_primary}
          onChange={(e) => this.updateMembership({ is_primary: e.target.checked })}
          disabled={this.props.disabled}
        />

        <FormCheck
          className='mb-1 me-3 d-inline-block'
          label='Public'
          name={`${this.getKey()}-public`}
          checked={membership.is_public}
          onChange={(e) => this.updateMembership({ is_public: e.target.checked })}
          disabled={this.props.disabled}
        />

        <FormCheck
          className='mb-1 me-3 d-inline-block'
          label='Internal Notes'
          name={`${this.getKey()}-can_view_private_notes`}
          checked={membership.can_view_private_notes}
          onChange={(e) => this.updateMembership({ can_view_private_notes: e.target.checked })}
          disabled={this.props.disabled}
        />

        <FormCheck
          className='mb-1 me-3 d-inline-block'
          label='Can View All Shows'
          name={`${this.getKey()}-can_view_all_gigs`}
          checked={membership.can_view_all_gigs}
          onChange={(e) => this.updateMembership({ can_view_all_gigs: e.target.checked })}
          disabled={this.props.disabled}
        />

        {this.props.show_apply_to_all && membership.is_primary && (
          <div className='alert alert-primary p-2 mb-0 mt-2'>
            <FormCheck
              className='mb-0'
              label='Add as personnel to all upcoming shows?'
              name={`${this.getKey()}-apply_to_all`}
              checked={membership.apply_to_all}
              onChange={(e) => this.updateMembership({ apply_to_all: e.target.checked })}
              disabled={this.props.disabled}
            />
          </div>
        )}

      </div>
    )
  }

  renderDefaultPersonnelAttributes() {
    const membership = this.props.membership || {}
    return (
      <div className='border rounded p-3 bg-light'>
        <div><strong>Default Personnel Attributes</strong></div>
        <FormCheck
          className='mb-1 me-3 d-inline-block'
          label='Default Show Contact'
          name={`${this.getKey()}-is_contact`}
          checked={membership.default_is_contact}
          onChange={(e) => this.updateMembership({ default_is_contact: e.target.checked })}
          disabled={this.props.disabled}
        />

        <FormCheck
          className='mb-1 me-3 d-inline-block'
          label='Default Present Day of Show'
          name={`${this.getKey()}-default_is_on_site`}
          checked={membership.default_is_on_site}
          onChange={(e) => this.updateMembership({ default_is_on_site: e.target.checked })}
          disabled={this.props.disabled}
        />
        <PermissionFields
          label='Default Show Detail Permissions'
          name={`membership-${this.getKey()}-default_gig_permissions`}
          value={membership.default_gig_permissions}
          onChange={(v) => this.updateMembership({ default_gig_permissions: v })}
          disabled={this.props.disabled}
        />
        <PermissionFields
          label='Default Guest List Permissions'
          name={`membership-${this.getKey()}-default_guest_list_permissions`}
          value={membership.default_guest_list_permissions}
          onChange={(v) => this.updateMembership({ default_guest_list_permissions: v })}
          disabled={this.props.disabled}
        />
        <PermissionFields
          label='Default Ticket Counts Permissions'
          name={`membership-${this.getKey()}-default_ticket_sales_permissions`}
          value={membership.default_ticket_sales_permissions}
          onChange={(v) => this.updateMembership({ default_ticket_sales_permissions: v })}
          disabled={this.props.disabled}
        />
      </div>
    )
  }

  render() {
    if (this.props.two_column) {
      return (
        <div className='row'>
          <div className='col-md-6 col-12 mb-2'>
            {this.renderAttributes()}
          </div>
          <div className='col-md-6 col-12 mb-2'>
            {this.renderDefaultPersonnelAttributes()}
          </div>
        </div>
      )
    } else {
      return (
        <>
          <div className='mb-2'>
            {this.renderAttributes()}
          </div>
          <div className='mb-2'>
            {this.renderDefaultPersonnelAttributes()}
          </div>
        </>
      )
    }
  }
}
