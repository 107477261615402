import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import Row from 'components/Row'
import BreadCrumbs from 'components/BreadCrumbs'
import PageTitle from 'components/PageTitle'
import ShowGuestList from 'components/guest_list/ShowGuestList'

export default class MasterGuestList extends React.Component {

  render() {
    const gig = this.props.gig || {}
    const location = _.get(this, 'props.gig.location', {})
    return (
      <div className='container'>
        <BreadCrumbs items={[
          { name: 'Shows', url: '/' },
          { name: gig.gig_name || gig.default_name, url: gig.url },
          { name: location.venue_name, url: location.url },
          { name: 'Master Guest List' },
        ]} />

        <PageTitle>
          Master Guest List
        </PageTitle>

        <div className='mb-3'>
          {window.is_mobile ? (
            <a href={`${gig.url}/printable_guest_list`}>
              <i className='fi-print'></i>&nbsp;Printable View
            </a>
          ) : (
            <a target='_blank' href={`${gig.url}/printable_guest_list`}>
              <i className='fi-print'></i>&nbsp;Printable View
            </a>
          )}
        </div>

        <div className='row'>
          {_.map(gig.performances, (p) => (
            <div className='col-12 col-md-6' key={`guest-list-${p.id}`}>
              <ShowGuestList
                performance_id={p.id}
                show_header
              />
            </div>
          ))}
        </div>
      </div>
    )
  }

}
