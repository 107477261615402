import React, { useState } from 'react'
import _ from 'lodash'

import DirectFileUploader from 'components/DirectFileUploader'
import OtherSelect from 'components/OtherSelect'

import { iff } from 'utils'

import {
  MAX_DOCUMENT_SIZE
} from 'static'

export default ({
  attachment,
  updateAttachment,
  onUploadSuccess,
  nameOptions,
  isDisabled,
  isShowDocumentSettings,
  isNewDocumentOnNewFile,
  uploadButtonText,
  children,
}) => {

  const [fileData, setFileData] = useState(null)

  const updateDocument = (update, callback) => {
    const doc = attachment.document || {}
    updateAttachment({
      ...attachment,
      document: {
        ...doc,
        ...update,
      },
    }, callback)
  }

  const uploadSuccess = (signedIds) => {
    const doc = attachment.document || {}
    updateAttachment({
      document: {
        ...doc,
        id: isNewDocumentOnNewFile ? null : doc.id,
        document_file: _.first(signedIds),
      }
    }, () => iff(onUploadSuccess))
  }

  const doc = attachment.document || {}
  return (
    <>
      <div className='d-flex flex-row align-items-center'>
        {doc.url && (
          <div className='flex-grow-0'>
            <div className='mb-2 me-2'>
              <a href={doc.url} target='_blank' className='btn btn-primary text-decoration-none'>
                <i className='fi-page me-2'></i>View
              </a>
            </div>
          </div>
        )}
        <div className='flex-grow-1'>
          <OtherSelect
            name='name'
            placeholder='Document Name'
            classes='mb-2'
            options={_.map(nameOptions || [], (ct) => (
              { value: ct, label: ct }
            ))}
            value={doc.name}
            onChange={(v) => updateDocument({ name: v })}
            disabled={isDisabled}
          />
        </div>
      </div>

      <div className='mb-2'>
        <DirectFileUploader
          onUploadSuccess={uploadSuccess}
          setFileData={setFileData}
          fileData={fileData}
          maxFileSize={MAX_DOCUMENT_SIZE}
          uploadButtonText={uploadButtonText}
        />
      </div>

      {isShowDocumentSettings && (
        <div className='mb-3'>
          <div className='form-check'>
            <input
              name='is_public'
              type='checkbox'
              id='is_public'
              className='form-check-input'
              checked={attachment.is_public}
              onChange={(e) => updateAttachment({ is_public: e.target.checked })}
            />
            <label className='form-check-label' for='is_public'>
              Public Document
            </label>
          </div>
          <div className='form-check'>
            <input
              name='is_primary'
              type='checkbox'
              id='is_primary'
              className='form-check-input'
              checked={attachment.is_primary}
              onChange={(e) => updateAttachment({ is_primary: e.target.checked })}
            />
            <label className='form-check-label' for='is_primary'>
              Default Attachment
            </label>
          </div>
          {attachment.is_primary && (
            <div className='alert alert-primary p-2 mt-2'>
              <div className='form-check'>
                <input
                  name='apply_to_all'
                  id='apply_to_all'
                  type='checkbox'
                  className='form-check-input'
                  checked={attachment.apply_to_all}
                  onChange={(e) => updateAttachment({ apply_to_all: e.target.checked })}
                />

                <label className='form-check-label' for='apply_to_all'>
                  Add document to all upcoming shows?
                </label>
              </div>
            </div>
          )}
        </div>
      )}


      {children}
    </>
  )
}
