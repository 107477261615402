import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import _ from 'lodash'

import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'

export default class LodgingListItem extends React.Component {

  renderMobile() {
    const l = this.props.lodging || {}
    const check_in_moment = moment(l.check_in_date)
    const check_in_date = check_in_moment.format("MMM DD (ddd)")
    const check_out_moment = moment(l.check_out_date)
    const check_out_date = check_out_moment.format("MMM DD (ddd)")

    return (
      <SafeLink className='list-group-item list-group-item-action clearfix' to={l.url}>
        <Avatar
          url={l.avatar_url}
          classes='small-avatar me-2 float-start'
          blank_icon='Band'
        />

        <div>
          <strong>{l.name}</strong>
        </div>

        <div>{check_in_date}&nbsp;&ndash;&nbsp;{check_out_date}</div>
        {l.city_state && (
          <div>{l.city_state}</div>
        )}
      </SafeLink>
    )
  }

  renderDesktop() {
    const l = this.props.lodging || {}
    const check_in_moment = moment(l.check_in_date)
    const check_in_dow = check_in_moment.format("ddd")
    const check_in_date = check_in_moment.format("MMM DD")
    const check_out_moment = moment(l.check_out_date)
    const check_out_dow = check_out_moment.format("ddd")
    const check_out_date = check_out_moment.format("MMM DD")
    return (
      <SafeLink className='list-group-item list-group-item-action' to={l.url}>
        <div className='d-flex flex-row align-items-center'>
          <div className='flex-grow-0'>
            <Avatar
              url={l.avatar_url}
              classes='medium-avatar me-3'
              blank_icon='Band'
            />
          </div>

          <div className='flex-grow-1 row align-items-center'>
            <div className='col-5 col-md-4 col-lg-3 d-flex flex-row align-items-center'>
              <div className='flex-grow-0'>
                <div>{check_in_dow}</div>
                <div>{check_in_date}</div>
              </div>
              <div className='flex-grow-0 mx-3'>
                <i className='fi-arrow-right icon-medium'></i>
              </div>
              <div className='flex-grow-1'>
                <div>{check_out_dow}</div>
                <div>{check_out_date}</div>
              </div>
            </div>

            <div className='col'>
              {l.name}
            </div>

            <div className='col-3'>
              {l.city_state || 'TBA'}
            </div>
          </div>
        </div>
      </SafeLink>
    )
  }

  render() {
    if (window.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

}
