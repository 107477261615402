import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { selectModel } from 'selectors'
import {
  updateGig,
  getGigTicketCounts,
  createGigTicketCount,
  updateGigTicketCount,
} from 'actions/api/gigs'
import { Resources } from 'schema'
import { AUTH_TYPE } from 'static/Auth'

import AddUpdatedTicketCount from 'components/ticket_count/AddUpdatedTicketCount'
import PreviousTicketCountsTable from 'components/ticket_count/PreviousTicketCountsTable'
import FirstTimeCountForm from 'components/ticket_count/FirstTimeCountForm'
import FinancialDocumentsList from 'components/documents/FinancialDocumentsList'
import BreadCrumbs from 'components/BreadCrumbs'

import { DATE_FORMAT } from 'static'
import { trimTime } from 'utils'

const TicketCountInputForm = ({
  gig,
  gigId,
  authContext,
  updateGig,
  getGigTicketCounts,
  createGigTicketCount,
  updateGigTicketCount,
}) => {
  const latestCount = _.last(gig.ticket_counts)
  const latestTicketCount = latestCount
  const isLatestCountToday = !_.isNil(latestCount) && moment().isSameOrBefore(latestCount.date, 'day')
  const isFinalCount = !_.isNil(gig.show_date) && moment().isSameOrAfter(gig.show_date, 'day')
  const isLatestCountFinal = !_.isNil(gig.show_date) && !_.isNil(latestCount) && moment(gig.show_date).isSame(latestCount.date, 'day')

  const hasExistingCounts = !!latestCount
  const existingTicketTypes = latestCount?.counts?.map(c => ({ ...c, count: null }))
  const gigDisplayName = gig.default_name
  const onSaleDate = !_.isNil(gig.on_sale_date) ? moment(gig.on_sale_date).format("MMMM Do YYYY") : null
  const showDate = !_.isNil(gig.show_date) ? moment(gig.show_date).format("MMMM Do YYYY") : null
  const venueCapacity = gig.venue_capacity

  const [isSuccessful, setIsSuccessful] = useState(false)
  const [ticketCountNote, setTicketCountNote] = useState()

  const createCount = data => {
    return createGigTicketCount(gigId, data)
  }

  const updateOnSaleDate = newDate => {
    const newDateMoment = moment(newDate)
    updateGig(gig.id, { on_sale_date: trimTime(newDateMoment.format(DATE_FORMAT)) })
  }

  const updateTicketCountNote = () => {
    updateGigTicketCount(gigId, { id: latestCount.id, note: ticketCountNote })
  }

  useEffect(() => {
    getGigTicketCounts(gigId)
  }, [])

  return (
    <div className='container'>
      {authContext === AUTH_TYPE.USER && (
        <BreadCrumbs
          items={[
            { name: 'Shows', url: '/' },
            { name: gig.default_name, url: gig.url },
            { name: 'Ticket Count Summary', url: `/gigs/${gigId}/ticket_counts` },
            { name: 'Input Ticket Count' },
          ]}
        />
      )}

      <h2>Input Ticket Count</h2>
      <h4>{gigDisplayName} - {showDate}</h4>

      <div className='mb-2'>
        <strong>On Sale Date:</strong>&nbsp;{onSaleDate || <>&mdash;</>}<br/>
        <strong>Venue Capacity:</strong>&nbsp;{venueCapacity}
      </div>

      {isSuccessful ? (
        <>

          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className="alert alert-success">
                We've successfully received your ticket counts. Thanks!
              </div>
              <div className='border rounded p-3 mb-3 bg-light'>
                <h5>Updated Ticket Count</h5>
                <div>
                  <strong>Date:</strong>&nbsp;{moment(latestTicketCount.date).format('MMMM Do YYYY')}
                </div>
                <PreviousTicketCountsTable previousCounts={latestTicketCount} />
              </div>
            </div>
          </div>
        </>
      ): (
        <>
          {hasExistingCounts ? (
            <div className='row mb-3'>
              <div className='col-12 col-lg-6'>
                <div className='border rounded p-3 mb-3 bg-light'>
                  {isFinalCount && isLatestCountFinal && (
                    <h5>Final Ticket Count</h5>
                  )}
                  {!isFinalCount && isLatestCountToday && (
                    <h5>Today's Ticket Count</h5>
                  )}
                  {(
                    (isFinalCount && !isLatestCountFinal) ||
                    (!isFinalCount && !isLatestCountToday)
                  ) && (
                    <h5>Last Ticket Count</h5>
                  )}
                  <div>
                    <strong>Date:</strong>&nbsp;{moment(latestTicketCount.date).format('MMMM Do YYYY')}
                  </div>
                  <PreviousTicketCountsTable previousCounts={latestTicketCount} />
                </div>
                {isLatestCountToday && (
                  <div className='border rounded p-3 mb-3 bg-light'>
                    <label htmlFor="count-note">Attach a note to this count (optional)</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="count-note"
                      placeholder="Note"
                      maxLength="21844"
                      defaultValue={latestCount.note}
                      onChange={e => setTicketCountNote(e.target.value)}
                    />
                    <div className='flex-grow-0 text-end'>
                      <a
                        className='btn btn-primary'
                        onClick={updateTicketCountNote}
                      >Attach Note</a>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-12 col-lg-6'>
                <div className='border rounded p-3 mb-3 bg-light'>
                  {isFinalCount ? isLatestCountFinal ? (
                    <>
                      <h5>Final Ticket Count</h5>
                      <div className='alert alert-success'>
                        Someone has already entered the final ticket counts!  If you'd
                        like to edit them, use the form below to update the values.
                      </div>
                    </>
                  ) : (
                    <>
                      <h5>Final Ticket Count</h5>
                      <div className='mb-2'>
                        Please enter the final ticket count below:
                      </div>
                    </>
                  ) : isLatestCountToday ? (
                    <>
                      <h5>Update Ticket Count</h5>
                      <div className='alert alert-success'>
                        Someone has already entered today's ticket counts!  If you'd
                        like to edit them, use the form below to update the values.
                      </div>
                    </>
                  ) : (
                    <>
                      <h5>Today's Ticket Count</h5>
                      <div className='mb-2'>
                        Please enter today's ticket count below:
                      </div>
                    </>
                  )}

                  <AddUpdatedTicketCount
                    createTicketCount={data => createCount(data)}
                    existingTicketTypes={existingTicketTypes}
                    setIsSuccessful={setIsSuccessful}
                    isFinalCount={isFinalCount}
                    showNoteInput={!isLatestCountToday}
                  />
                </div>
              </div>
            </div>
          ) : (
            <FirstTimeCountForm
              gig={gig}
              updateOnSaleDate={updateOnSaleDate}
              displayShowDate={showDate}
              venueCapacity={venueCapacity}
              setIsSuccessful={setIsSuccessful}
              isSuccessful={isSuccessful}
              createTicketCount={data => createCount(data)}
            />
          )}
        </>
      )}

      {isFinalCount && (
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <div className='border rounded p-3 mb-3'>
              <h5>Ticket Documents</h5>
              <div className='mb-2'>
                <i>
                  Here you can add relevant ticketing and financial documents for future reference.
                  These will only be accessible to members of the venue or headlining band that have
                  the permissions to see Ticket Counts.
                </i>
              </div>
              {gig && <FinancialDocumentsList gig={gig} />}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default connect((state, props) => {
  const gigId = _.get(props, 'match.params.gig_id')
  const gig = selectModel('gigs', gigId, Resources.gig, state)
  const authContext = _.get(state, 'auth_context.auth_type')

  return {
    gig,
    gigId,
    authContext,
  }

}, (dispatch) => bindActionCreators({
  getGigTicketCounts,
  createGigTicketCount,
  updateGigTicketCount,
  updateGig,
}, dispatch))(TicketCountInputForm)
