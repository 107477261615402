import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { withRouter } from 'react-router'
import {
  getScheduleTemplate,
  updateScheduleTemplate
} from 'actions/api/schedule_templates'
import {
  adminGetScheduleTemplate,
  adminUpdateScheduleTemplate
} from 'actions/api/admin/schedule_templates'
import {
  createTemplateScheduleItem,
  updateTemplateScheduleItem,
  deleteTemplateScheduleItem
} from 'actions/api/template_schedule_items'
import {
  adminCreateTemplateScheduleItem,
  adminUpdateTemplateScheduleItem,
  adminDeleteTemplateScheduleItem
} from 'actions/api/admin/template_schedule_items'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import BreadCrumbs from 'components/BreadCrumbs'
import PageTitle from 'components/PageTitle'
import EditableList from 'components/EditableList'
import EditableScheduleName from 'components/schedule_templates/EditableScheduleName'
import EditableTemplateScheduleItem from 'components/schedule_templates/EditableTemplateScheduleItem'
import AdminHeader from 'components/admin/AdminHeader'

import {
  timeToMinutes
} from 'utils'

class EditableScheduleTemplate extends EditableList {

  // Override
  getIdFromProps(props) { return props.match.params.id }

  // Override
  getDataFromListItem(li) {
    return _.pick(li, [ 'band_name', 'label', 'time', 'duration' ])
  }

  // Override
  getListItemFromResponse(response) {
    return _.get(response, 'payload.raw.template_schedule_item', {})
  }

  // Override
  getListFromResponse(response) {
    return _.get(response, 'payload.raw.schedule_template.template_schedule_items', [])
  }

  // Override
  isEditable() {
    const scheduleTemplatable = _.get(this, 'props.schedule_template.schedule_templatable', {})
    return scheduleTemplatable?.is_editable
  }

  // Override
  getAddButtonText() {
    return "+ Add Schedule Item"
  }

  // Override
  getNewListItemProps() {
    return { schedule_template_id: this.props.match.params.id }
  }

  /**********************************
   * REQUESTS
   **********************************/
  // Override
  loadListDataRequest(props) {
    if (this.isAdminPage()) {
      return this.props.adminGetScheduleTemplate(props.match.params.id)
    } else {
      return this.props.getScheduleTemplate(props.match.params.id)
    }
  }

  // Override
  updateListItemRequest(id, data) {
    if (this.isAdminPage()) {
      return this.props.adminUpdateTemplateScheduleItem(id, data)
    } else {
      return this.props.updateTemplateScheduleItem(id, data)
    }
  }

  // Override
  deleteListItemRequest(id) {
    if (this.isAdminPage()) {
      return this.props.adminDeleteTemplateScheduleItem(id)
    } else {
      return this.props.deleteTemplateScheduleItem(id)
    }
  }

  // Override
  createListItemRequest(data) {
    if (this.isAdminPage()) {
      return this.props.adminCreateTemplateScheduleItem(this.props.match.params.id, data)
    } else {
      return this.props.createTemplateScheduleItem(this.props.match.params.id, data)
    }
  }

  // Override
  saveListRequest(data) {
    if (this.isAdminPage()) {
      return this.props.adminUpdateScheduleTemplate(this.props.match.params.id, {
        template_schedule_items: this.state.editable_list
      })
    } else {
      return this.props.updateScheduleTemplate(this.props.match.params.id, {
        template_schedule_items: this.state.editable_list
      })
    }
  }
  /****************************************/
  /****************************************/

  sortEditableList(list) {
    return _.sortBy(list, (t) => timeToMinutes(t.time))
  }

  isAdminPage() {
    return !!this.props.location.pathname.match('admin')
  }

  getUrl(url) {
    if (this.isAdminPage()) {
      return `/admin${url}`
    } else {
      return url
    }
  }

  renderMobile() {
    return (
      <div className='container'>
        {this.renderTitle()}
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='160'>Time</th>
              <th>Details</th>
              {this.isEditable() && (
                <th width='30'></th>
              )}
            </tr>
          </thead>
          <tbody>
            {_.map(this.state.editable_list, (si, i) => (
              <EditableTemplateScheduleItem
                key={`schedule_item-${si.id}-${i}`}
                template_schedule_item={si}
                onUpdate={this.genUpdateListItem(i)}
                onSave={this.genSaveListItem(i)}
                onDelete={() => this.deleteListItem(i)}
                onEdit={() => this.editListItem(i)}
                is_editable={this.isEditable()}
                is_disabled={this.state.loading_all || this.state.is_loading}
                is_mobile
              />
            ))}
          </tbody>
        </table>
        {this.renderButtons()}
        {this.renderLoadingPopup()}
      </div>
    )
  }

  renderDesktop() {
    return (
      <div className='container'>
        {this.renderTitle()}
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th>Time</th>
              {this.isEditing() && (<th width='150'>Length</th>)}
              <th>Label</th>
              <th>Band</th>
              {this.isEditable() && (
                <th width='65'></th>
              )}
            </tr>
          </thead>
          <tbody>
            {_.map(this.state.editable_list, (si, i) => (
              <EditableTemplateScheduleItem
                key={`schedule_item-${si.id}-${i}`}
                template_schedule_item={si}
                onUpdate={this.genUpdateListItem(i)}
                onSave={this.genSaveListItem(i)}
                onDelete={() => this.deleteListItem(i)}
                onEdit={() => this.editListItem(i)}
                is_editable={this.isEditable()}
                is_disabled={this.state.loading_all || this.state.is_loading}
                show_duration={this.isEditing()}
              />
            ))}
          </tbody>
        </table>
        {this.renderButtons()}
        {this.renderLoadingPopup()}
      </div>
    )
  }

  renderTitle() {
    const schedule_template = _.get(this, 'props.schedule_template', {})
    const scheduleTemplatable = _.get(this, 'props.schedule_template.schedule_templatable', {})

    return(
      <>
        {this.isAdminPage() && (<AdminHeader />)}

        <BreadCrumbs
          items={[
            { name: scheduleTemplatable.name, url: this.getUrl(scheduleTemplatable.url) },
            { name: "Schedule Templates", url: this.getUrl(`${scheduleTemplatable.url}/schedule_templates`) },
            { name: schedule_template.name },
          ]}
        />

        <h2>
          Schedule Template
        </h2>

        <EditableScheduleName
          update={(update) => this.props.updateScheduleTemplate(schedule_template.id, update)}
          name={schedule_template.name}
          is_editable={scheduleTemplatable.is_editable}
        />
      </>
    )
  }

  render() {
    if (window.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

}


export default withRouter(connect((state, ownProps) => ({
  schedule_template: selectModel('schedule_templates', ownProps.match.params.id, Resources.schedule_template, state)
}), (dispatch) => bindActionCreators({
  getScheduleTemplate,
  updateScheduleTemplate,
  createTemplateScheduleItem,
  updateTemplateScheduleItem,
  deleteTemplateScheduleItem,
  adminGetScheduleTemplate,
  adminUpdateScheduleTemplate,
  adminCreateTemplateScheduleItem,
  adminUpdateTemplateScheduleItem,
  adminDeleteTemplateScheduleItem,
}, dispatch))(EditableScheduleTemplate))
