import React from 'react'
import SafeLink from 'components/SafeLink'

import LandingInfo from 'components/marketing/LandingInfo'
import LandingScreenshots from 'components/marketing/LandingScreenshots'

import Row from 'components/Row'

import { staticAssetUrl } from 'utils'

export default class LandingDesktop extends React.Component {

  render() {
    return(
      <div className='landing-page'>
        <div className='landing-top'>
          <div className='landing-image-container'>
            <img src={staticAssetUrl('Landing_Page/LandingPicCropped.png')} className='landing-image' />
          </div>
          <div className='landing-text'>
            <div className='header-text'>
              Backline
            </div>
            <div className='main-text'>
              The only collaborative tool for organizing live music
            </div>
            <div>
              <SafeLink to='/waitlist' className='waitlist-button'>
                Join the waitlist
              </SafeLink>
            </div>

            <div className='small-text'>
              Already a member?  <SafeLink to='/login'>Click here</SafeLink> to login
            </div>
          </div>
        </div>

        <div className='padding'></div>

        <div className='text-center margin'>
          <LandingInfo/>
        </div>

        <div className='padding'></div>

        <div className='text-center margin-top'>
          <LandingScreenshots/>
        </div>

        <div className='spacer-2'></div>

        <Row>
          <div className='callout text-center'>
            If you'd like to get in contact with us, you can email us at <a href="mailto:info@backline.me">info@backline.me</a>.
          </div>
        </Row>

        <div className='padding'></div>
      </div>
    )
  }

}
