import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getVenue, updateVenue } from 'actions/api/venues'

import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'
import AddressForm from 'components/addresses/AddressForm'
import LoadingState from 'components/LoadingState'
import DirectFileUploader from 'components/DirectFileUploader'
import ErrorLabel from 'components/ErrorLabel'

import SaveBar from 'components/SaveBar'
import PhoneInput from 'components/people/PhoneInput'

import { MAX_AVATAR_SIZE, MAX_CAPACITY } from 'static'

import { iff } from 'utils'

class VenueForm extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      venue: props.venue || {},
    }
  }

  componentDidMount() {
    this.setLoading(() => this.props.getVenue(this.props.match.params.id)
      .then((res) => {
        if (res.error) {
          this.clearLoading()
        } else {
          this.setState({ venue: res.payload.raw.venue }, () => this.clearLoading())
        }
      })
    )
  }

  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      props.getVenue(props.match.params.id)
    }

    if (_.isEmpty(this.state.venue)) {
      this.setState({ venue: props.venue || {} })
    }
  }

  updateVenue(update, callback) {
    this.setState({
      venue: {
        ...this.state.venue,
        ...update,
      }
    }, () => iff(callback))
  }

  updateCapacity(value) {
    let update_value = value
    if (value) {
      update_value = Math.round(Math.min(Math.max(value, 0), MAX_CAPACITY), 0)
    }
    this.updateVenue({ capacity: update_value })
  }

  save() {
    this.setLoading(() => {
      this.props.updateVenue(this.props.match.params.id, this.state.venue)
        .then((response) => this.clearLoading())
    })
  }

  submit() {
    this.setLoading(() => {
      this.props.updateVenue(this.props.match.params.id, this.state.venue)
        .then((response) => {
          this.clearLoading()
          if (!response.error) {
            this.props.history.push(this.props.venue.url)
          }
        })
    })
  }

  renderPublicDetails() {
    return (
      <div className='border rounded mb-3 p-3'>
        <div className='alert alert-warning'>
          <i>
            This information is visible to all Backline users, and will help other users identify your venue.
          </i>
        </div>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <div className='mb-3'>
              <FormField
                type='text'
                name='name'
                label='Venue Name'
                value={this.state.venue.name || ''}
                errors={this.props.api_errors}
                onChange={(e) => this.updateVenue({ name: e.target.value })}
              />
            </div>
          </div>

          <div className='col-12 col-lg-6'>
            <div className='mb-3'>
              <FormField
                type='number'
                name='capacity'
                label='Capacity'
                value={this.state.venue.name || ''}
                errors={this.props.api_errors}
                value={this.state.venue.capacity || ''}
                onChange={(e) => this.updateCapacity(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-md-6 mb-3'>
            <label className='form-label'>Main Phone</label>
            <PhoneInput
              name='main_phone'
              value={this.state.venue.main_phone || ''}
              onChange={(v) => this.updateVenue({ main_phone: v })}
            />
          </div>

          <div className='col-12 col-md-6 mb-3'>
            <label className='form-label'>Box Office Phone</label>
            <PhoneInput
              name='box_office_phone'
              value={this.state.venue.box_office_phone || ''}
              onChange={(v) => this.updateVenue({ box_office_phone: v })}
            />
          </div>
        </div>

        {this.props.venue.is_owned && (
          <FormField
            type='text'
            name='username'
            label='Venue Username'
            value={this.state.venue.username || ''}
            onChange={(e) => this.updateVenue({ username: e.target.value })}
            errors={this.props.api_errors}
          />
        )}

        <div className='mb-3'>
          <label className='form-label' htmlFor='website'>Website</label>
          <div className='input-group'>
            <span className="input-group-text">https://</span>
            <input
              type='text'
              name='website'
              className='form-control'
              value={this.state.venue.website || ''}
              onChange={(e) => this.updateVenue({ website: e.target.value })}
            />
          </div>
        </div>

        {this.props.venue.is_owned && (
          <FormField
            type='textarea'
            name='bio'
            label='Description'
            value={this.state.venue.bio || ''}
            onChange={(e) => this.updateVenue({ bio: e.target.value })}
            errors={this.props.api_errors}
          />
        )}
      </div>
    )
  }

  renderPrivateDetails() {
    return (
      <div className='border rounded p-3'>
        <div className='alert alert-warning'>
          <i>
            This information will only be visible to Backline users who are connected to a show that you are hosting.
          </i>
        </div>
        <div className='row'>
          <div className='col-12 col-lg-6 mb-3'>
            <FormField
              type='text'
              name='wifi'
              label='Wifi'
              value={this.state.venue.wifi || ''}
              onChange={(e) => this.updateVenue({ wifi: e.target.value })}
              errors={this.props.api_errors}
            />
          </div>

          <div className='col-12 col-lg-6 mb-3'>
            <FormField
              type='text'
              name='wifi_password'
              label='Wifi Password'
              value={this.state.venue.wifi_password || ''}
              onChange={(e) => this.updateVenue({ wifi_password: e.target.value })}
              errors={this.props.api_errors}
            />
          </div>
        </div>

        <div className='mb-3'>
          <FormField
            type='textarea'
            name='parking'
            label='Parking'
            value={this.state.venue.parking || ''}
            onChange={(e) => this.updateVenue({ parking: e.target.value })}
            errors={this.props.api_errors}
          />
        </div>

        <div className='mb-3'>
          <FormField
            type='textarea'
            name='load_in_info'
            label='Load-In Info'
            value={this.state.venue.load_in_info || ''}
            onChange={(e) => this.updateVenue({ load_in_info: e.target.value })}
            errors={this.props.api_errors}
          />
        </div>

        <div className='mb-3'>
          <FormField
            type='textarea'
            name='green_room_info'
            label='Green Room Info'
            value={this.state.venue.green_room_info || ''}
            onChange={(e) => this.updateVenue({ green_room_info: e.target.value })}
            errors={this.props.api_errors}
          />
        </div>

        <div className='border rounded p-3 mb-3'>
          <h4>Amenities</h4>

          <FormCheck
            name='has_showers'
            label='Showers'
            checked={this.state.venue.has_showers || false}
            onChange={(e) => this.updateVenue({ has_showers: e.target.checked })}
            errors={this.props.api_errors}
          />

          <FormCheck
            name='has_laundry'
            label='Laundry'
            checked={this.state.venue.has_laundry || false}
            onChange={(e) => this.updateVenue({ has_laundry: e.target.checked })}
            errors={this.props.api_errors}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        <div className='container mt-2'>
          <h2>Edit Venue - {this.state.venue.name}</h2>

          <div className='row'>
            <div className='col-12 col-md-6'>
              <h4>Address</h4>
              <div className='border rounded p-3 mb-3'>
                <AddressForm
                  address={this.state.venue.address || {}}
                  onChange={(a) => this.updateVenue({ address: a })}
                />
              </div>

              {this.props.venue.is_owned && (
                <div className='mb-4'>
                  <h4>Profile Picture</h4>
                  {this.props.venue.avatar_url && (
                    <div className='mb-2'>
                      <img
                        src={this.props.venue.avatar_url}
                        className='avatar'
                      />
                    </div>
                  )}
                  <DirectFileUploader
                    onUploadSuccess={(signedIds) => this.updateVenue({
                      avatar_image: _.first(signedIds)
                    }, () => this.save())}
                    setFileData={(fd) => this.setState({ fileData: fd })}
                    fileData={this.state.fileData}
                    maxFileSize={MAX_AVATAR_SIZE}
                  />
                </div>
              )}
              <h4>Public Details</h4>
              {this.renderPublicDetails()}
            </div>

            <div className='col-12 col-md-6'>
              <h4>Private Details</h4>
              <div className='mb-4'>
                {this.renderPrivateDetails()}
              </div>

              <h4>Default Information</h4>
              <div className='border rounded p-3'>
                <div className='alert alert-warning'>
                  <i>
                    This information will only be available to Backline users who are connected to a show that you are hosting.<br/><br/>
                    The information you provide here is filled out by default on any new show that you create or join,
                    but can be edited on a show-by-show basis on the advance page.
                  </i>
                </div>

                <div className='mb-3'>
                  <FormField
                    type='textarea'
                    name='default_merch_info'
                    label='Merch Info'
                    value={this.state.venue.default_merch_info || ''}
                    onChange={(e) => this.updateVenue({ default_merch_info: e.target.value })}
                    errors={this.props.api_errors}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    type='textarea'
                    name='hospitality_details'
                    label='Hospitality Details'
                    value={this.state.venue.default_hospitality_details || ''}
                    onChange={(e) => this.updateVenue({ default_hospitality_details: e.target.value })}
                    errors={this.props.api_errors}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    type='textarea'
                    name='default_show_notes'
                    label='Additional Show Notes'
                    value={this.state.venue.default_show_notes || ''}
                    onChange={(e) => this.updateVenue({ default_show_notes: e.target.value })}
                    errors={this.props.api_errors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
        />
      </>
    )
  }
}

export default connect((state, props) => ({
  venue: selectModel('venues', props.match.params.id, Resources.venue, state),
  api_errors: state.api.errors,
}), (dispatch) => bindActionCreators({
  getVenue, updateVenue
}, dispatch))(VenueForm)
