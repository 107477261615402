import React from 'react'

export default class WaitListForm extends React.Component {

  render() {
    return (
      <div className='container mt-3'>
        <div className='text-center'>
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeAgJgwaFiBu-VAXsWKSJsrrIWM0DiCbAVQZApgSYB0YjgOjA/viewform?embedded=true" className='wait-list' frameBorder="0" marginHeight="0" marginWidth="0">Loading...</iframe>
        </div>
      </div>
    )
  }

}

