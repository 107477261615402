import React from 'react'

import LoadingState from 'components/LoadingState'

export default class RequestRedirectPage extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      is_success: false,
    }
  }

  componentWillUnmount() {
    clearTimeout(this.success_timeout)
  }

  componentDidMount() {
    this.sendRequest()
  }

  sendRequest() {
    this.setLoading(() => {
      this.makeRequest().then((res) => {
        if (res.payload.request_status === 404) {
          this.clearLoading()
          this.props.history.replace('/not_found')
        } else if (res.payload.request_status === 401) {
          this.clearLoading()
          this.props.history.replace('/unauthorized')
        } else if (res.error) {
          this.clearLoading()
        } else {
          this.setState({
            is_success: true,
            is_loading: false,
          }, () => this.onSuccess(res))
        }
      })
    })
  }

  // Override
  onSuccess(res) {
    if (this.getSuccessTimeout()) {
      this.success_timeout = setTimeout(() => {
        this.props.history.replace(this.getSuccessUrl(res))
      }, this.getSuccessTimeout())
    } else {
      this.props.history.replace(this.getSuccessUrl(res))
    }
  }

  // Override
  getSuccessTimeout() {
    return null
  }

  // Override
  getSuccessUrl() {
    throw "Please implement getSuccessUrl() in RequestRedirectPage"
  }

  // Override
  makeRequest() {
    throw "Please implement makeRequest() on RequestRedirectPage"
  }

}
