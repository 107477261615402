import React from 'react'

import EditableField from 'components/EditableField'

import {
  PERFORMANCE_TYPES,
  PERFORMANCE_TYPE_LABEL
} from 'static'

export default class EditablePerformanceType extends EditableField {

  getValueFromProps(props) {
    return props.performance_type || 0
  }

  getPostData() {
    return { performance_type: this.state.value }
  }

  onBlur() {
    // no-op -- DO NOT SAVE ON BLUR
  }

  render() {
    if (this.state.is_editing && this.props.is_editable) {
      return (
        <>
          {this.props.is_own_headliner && (
            <div className='alert alert-warning'>
              <strong>WARNING</strong>: If you change the performance type of your own band from "Headliner"
              to "Support" on a show with an owned venue or another headlining band, you will immediately lose your
              "Headliner" privileges and be unable to revert this change.
            </div>
          )}
          {this.props.is_owned_support && (
            <div className='alert alert-warning'>
              <strong>WARNING</strong>: If you change the performance type of an owned band from "Support" to
              "Headliner", they will immediately gain "Headliner" privileges and you will lose the ability to change
              revert the change.
            </div>
          )}

          <div className='input-group mb-3'>
            <select
              ref={(ref) => this.input = ref}
              name='performance_type'
              className='form-select'
              value={this.state.value}
              onChange={(e) => this.onChange(e.target.value)}
              onBlur={(e) => this.onBlur(e.target.value)}
              disabled={this.props.disabled}
            >
              {_.map(PERFORMANCE_TYPES, (pt) => (
                <option value={pt} key={pt}>
                  {PERFORMANCE_TYPE_LABEL[pt]}
                </option>
              ))}
            </select>

            <a
              className='btn btn-primary'
              onClick={() => this.save()}
            >save</a>
            <a
              className='btn btn-gray'
              onClick={() => this.cancel()}
            >cancel</a>
          </div>
        </>
      )
    } else {
      return (
        <>
          <h4>
            {PERFORMANCE_TYPE_LABEL[this.state.value]}
            {this.props.is_editable && (
              <a
                key='edit'
                onClick={() => this.onEdit()}
              ><i className='ms-2 smaller-text edit-icon fi-pencil'></i></a>
            )}
          </h4>
        </>
      )
    }
  }
}
