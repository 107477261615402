import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import moment from 'moment'

import _ from 'lodash'
import { withRouter } from 'react-router'
import Select from 'react-select'

import { connect } from 'react-redux'
import { selectCurrentUser, selectApiList } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import {
  getUpcomingGigs,
  getPastGigs,
  updateGigTicketCount,
  createGigTicketCount,
} from 'actions/api/gigs'
import { parseUrlParams } from 'utils'

import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import Pagination from 'components/Pagination'
import { PERSPECTIVE_FILTER_OPTION_ALL } from 'static'

const SummaryListItem = ({ label, value }) => {
  return (
    <div className="d-flex flex-column py-2 ticket-summary-list__field">
      <strong>{label}</strong>
      <div>
        <div>{value}</div>
      </div>
    </div>
  )
}

const EditableSummaryListItem = ({ label, editingCount, updateEditingCount }) => {
  return (
    <div className="d-flex flex-column py-2 ticket-summary-list__field">
      <strong>{label}</strong>
      <div className="me-2">
        <input
          className="form-control"
          type="number"
          name={`count-type-${label}`}
          value={editingCount.counts.find(
            ct => ct.ticket_type_name === label
          ).count}
          onClick={e => {
            e.stopPropagation()
          }}
          onChange={(e) => updateEditingCount(
              label,
              e.target.value,
            )
          }
        />
      </div>
    </div>
  )
}

const TicketSalesSummary = ({
  getUpcomingGigs,
  getPastGigs,
  updateGigTicketCount,
  createGigTicketCount,
  bands,
  venues,
  gigs,
  history,
  location,
  num_pages,
}) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [activePerspective, setActivePerspective] = useState(PERSPECTIVE_FILTER_OPTION_ALL)
  const [perspectiveOptions, setPerspectiveOptions] = useState([])
  const [isPast, setIsPast] = useState(false)
  const [editingCount, setEditingCount] = useState()

  const visitTicketPage = gigId => {
    window.location = `/gigs/${gigId}/ticket_counts`
  }

  useEffect(() => {
    history.replace(`${location.pathname}?page=${currentPage}&is_past=${isPast}`)
    if (isPast) {
      getPastGigs(currentPage, activePerspective)
    } else {
      getUpcomingGigs(currentPage, activePerspective)
    }
  }, [currentPage, activePerspective, isPast])

  useEffect(() => {
    const bandOptions = bands?.map(band =>
      ({ label: band.name, value: band.id, type: 'Band' })
    ) || []
    const venueOptions = venues?.map(venue =>
      ({ label: venue.name, value: venue.id, type: 'Venue' })
    ) || []

    setPerspectiveOptions([PERSPECTIVE_FILTER_OPTION_ALL, ...bandOptions, ...venueOptions])
  }, [bands, venues])

  const chunkTicketCounts = counts => {
    let groups = []
    for (let i = 0; i < counts.length; i += 3) {
      groups.push(counts.slice(i, i + 3))
    }
    return groups
  }

  const findLatestCount = counts => {
    const sortedCounts = counts.sort((a, b) => new Date(b.date) - new Date(a.date))
    return sortedCounts?.[0] || {}
  }

  const editCount = (e, countToEdit) => {
    setEditingCount(_.cloneDeep(countToEdit))

    e.stopPropagation()
    e.preventDefault()
  }

  const updateEditingCount = (type, newTypeCount) => {
    const newCount = _.cloneDeep(editingCount)

    const newType = newCount.counts.find(ct => ct.ticket_type_name === type)
    newType.count = newTypeCount

    setEditingCount(newCount)
  }

  const shouldCreateNewTicketCount = () => {
    return !moment(editingCount.date).isSame(moment(), 'day')
  }

  const ticketCountCreationPayload = () => {
    const formattedData = {
      ticket_types: editingCount.counts.map(newData => ({
        ticket_type_id: newData.ticket_type_id,
        name: newData.ticket_type_name,
        total_sellable: newData.total_sellable,
        price: newData.price,
        count: parseInt(newData.count, 10) || 0,
      }))
    }

    return formattedData
  }

  const saveChanges = (e, gig) => {
    if (shouldCreateNewTicketCount()) {
      createGigTicketCount(gig.id, ticketCountCreationPayload())
    } else {
      updateGigTicketCount(gig.id, editingCount).then(() => setEditingCount(null))
    }
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div className='container mt-2'>
      <h2>Ticket Counts</h2>

      <div className='d-block d-md-none'>
        <label>View as:</label>
        <Select
          name='active_perspective'
          value={activePerspective || ''}
          options={perspectiveOptions}
          onChange={v => setActivePerspective(v)}
          clearable={false}
          searchable={false}
        />
      </div>

      <div className='d-flex flex-row align-items-end mb-2'>
        <div className='text-start flex-grow-0'>
          <ul className='nav nav-pills'>
            <li className='nav-item'>
              <a
                onClick={() => setIsPast(false)}
                className={classnames('nav-link', 'pointer', { 'active': !isPast })}
              >Upcoming</a>
            </li>
            <li className='nav-item'>
              <a
                onClick={() => setIsPast(true)}
                className={classnames('nav-link', 'pointer', { 'active': isPast })}
              >Past</a>
            </li>
          </ul>
        </div>
        <div className='flex-grow-1'>
          <div className='d-none d-md-block mx-3'>
            <label>Filter By:</label>
            <Select
              className='mb-0'
              name='active_perspective'
              value={activePerspective || ''}
              options={perspectiveOptions}
              onChange={v => setActivePerspective(v)}
              clearable={false}
              searchable={false}
            />
          </div>
        </div>
        <div className='text-end flex-grow-0'>
          <SafeLink
            to="/new/gig"
            className='btn btn-primary'
          >+ New Show</SafeLink>
        </div>
      </div>

      <div className="border rounded ticket-summary-list d-flex flex-column mb-3">
        {gigs?.map(gig => {
            const latestCount = findLatestCount(gig?.ticket_counts || [])
            const ticketCounts = latestCount?.counts || []
            const ticketTypes = ticketCounts.map(count => count.ticket_type_name) || []
            const ticketTypeGroups = chunkTicketCounts(ticketTypes)
            const onSaleDate = !_.isNil(gig.on_sale_date) ? moment(gig.on_sale_date).format("MMMM Do, YYYY") : null
            const showDate = !_.isNil(gig.show_date) ? moment(gig.show_date).format("MMMM Do, YYYY") : null
            const daysOut = gig.days_out >= 0 ? gig.days_out : '-'

            return (
              <div key={gig.id} onClick={() => visitTicketPage(gig.id)} className="d-flex flex-column pointer p-3 ticket-summary-list__item">
                <div className="d-flex flex-column mb-3">
                  <div className='d-flex flex-row align-items-center'>
                    <div className='flex-grow-0'>
                      <Avatar
                        url={_.get(gig, 'perspective.avatar_url')}
                        classes='small-avatar'
                        blank_icon={_.get(gig, 'perspective.type')}
                      />
                    </div>
                    <div className='flex-grow-1'>
                      <strong>{gig.name}</strong>
                    </div>
                  </div>


                  <div>
                    {moment(gig.show_date).format("MMM DD, YYYY")}
                    {gig.city_state && (
                      <>&nbsp;&ndash;&nbsp;{gig.city_state}</>
                    )}
                  </div>
                  {!_.isEmpty(latestCount) && (
                    <div>
                      {moment(latestCount.date).isSame(moment(), 'day') ? (
                        <div className="alert alert-success">
                          Your ticket counts are up-to-date!
                        </div>
                      ) : (
                        <div className="alert alert-warning">
                          Your last ticket count was submitted on <strong>{moment(latestCount.date).format("MMM DD, YYYY")}</strong>. It might be time for an update.
                        </div>
                      )}
                    </div>
                  )}
                  <div className="d-flex px-2 bg-light">
                    <SummaryListItem label={'Show Date'} value={showDate} />
                    <SummaryListItem label={'Days Out'} value={daysOut} />
                    <SummaryListItem label={'On Sale Date'} value={onSaleDate} />
                  </div>
                </div>
                {_.isEmpty(latestCount) ? (
                  <>
                    <div className="alert alert-primary">
                      You haven't submitted any ticket counts for this gig. Hit the button below to set up the initial ticket count.
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={e => {
                        window.location = `${gig.url}/ticket_counts/input`
                        e.stopPropagation()
                        e.preventDefault()
                      }}
                    >Input Initial Count</button>
                  </>
                ) : (
                  <>
                    <div className="mb-3">
                      <strong>Ticket Counts</strong>
                      <div className="d-flex px-2 bg-light mt-2">
                        <SummaryListItem label={'Total Sellable'} value={latestCount.total_sellable || 'N/A'} />
                        <SummaryListItem label={'Total Sold'} value={latestCount.total_sold || 'N/A'} />
                        <SummaryListItem label={'Percent Sold'} value={latestCount.percent_sold || 'N/A'} />
                      </div>
                      <div className="d-flex px-2 bg-light">
                        <SummaryListItem label={'Open'} value={latestCount.open || 'N/A'} />
                        <SummaryListItem label={'Wrap'} value={latestCount.wrap || 'N/A'} />
                        <SummaryListItem label={'Gross Sales'} value={latestCount.gross_sales || 'N/A'} />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center my-2">
                        <strong>Ticket Types</strong>
                        {ticketTypeGroups?.length > 0 && (
                          editingCount && editingCount.id === latestCount?.id ? (
                            <>
                              <button onClick={e => saveChanges(e, gig)}>
                                <i className='fi-check icon-base margin-left success-color'></i>
                              </button>
                              <button onClick={e => editCount(e, null)}>
                                <i className='fi-x red-text icon-base margin-left'></i>
                              </button>
                            </>
                          ) : (
                            <button
                              className='primary-color me-2'
                              onClick={e => editCount(e, latestCount)}
                            >
                              <i className='edit-icon fi-pencil icon-base'></i>
                            </button>
                          )
                        )}
                      </div>
                      {ticketTypeGroups?.map((typeGroup, i) => {
                        return (
                          <div key={i} className="d-flex px-2 bg-light">
                            {editingCount && editingCount.id === latestCount?.id ? (
                              typeGroup.map((ticketType, idx) => {
                                return (
                                  <EditableSummaryListItem
                                    key={`${ticketType}-${idx}`}
                                    label={ticketType}
                                    editingCount={editingCount}
                                    updateEditingCount={updateEditingCount}
                                  />
                                )
                              })
                              ) : (
                                typeGroup.map(ticketType => {
                                  return (
                                    <SummaryListItem
                                      key={ticketType}
                                      label={ticketType}
                                      value={ticketCounts.find(tc => tc.ticket_type_name === ticketType).count}
                                    />
                                  )
                                })
                              )}
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
            )
          })}
      </div>

      {num_pages > 0 && (
        <Pagination
          currentPageNum={currentPage}
          onPageClick={setCurrentPage}
          numPages={num_pages}
        />
      )}
    </div>
  )
}

export default withRouter(connect((state, props) => {
  const page = parseUrlParams(props.location.search).params.page || 0
  const is_past = parseUrlParams(props.location.search).params.is_past === 'true' || false
  const list_key = is_past ? 'past_gigs' : 'upcoming_gigs'
  const list = selectApiList(list_key, Resources.gigs, page, state)
  const current_user = selectCurrentUser(state)
  const { bands, venues } = current_user?.person || {}

  return {
    gigs: list.items,
    num_pages: list.num_pages,
    current_user,
    bands,
    venues,
  }
}, (dispatch) => bindActionCreators({
  getUpcomingGigs,
  getPastGigs,
  updateGigTicketCount,
  createGigTicketCount,
}, dispatch))(TicketSalesSummary))
