import React from 'react'
import _ from 'lodash'

import { denormalize } from 'normalizr'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { selectModel } from 'selectors'
import { getGuestListSpots } from 'actions/api/guest_list_spots'
import { isViewable } from 'utils/PermissionsUtil'

import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import GuestListCount from 'components/guest_list/GuestListCount'
import RenderGuestList from 'components/guest_list/RenderGuestList'
import LoadingState from 'components/LoadingState'

class ShowGuestList extends LoadingState {

  componentWillMount() {
    if (this.props.performance_id) {
      this.setLoading(() => this.props.getGuestListSpots(this.props.performance_id)
        .then(() => this.clearLoading()))
    }
  }

  componentWillReceiveProps(props) {
    if (props.performance_id !== this.props.performance_id) {
      this.setLoading(() => this.props.getGuestListSpots(props.performance_id)
        .then(() => this.clearLoading()))
    }
  }

  render() {
    const performance = this.props.performance || {}
    const perspective = _.get(performance, 'gig.perspective', {})
    const canViewGuestList = isViewable(perspective.guest_list_permissions)

    if (canViewGuestList) {
      return (
        <div className='mb-4'>
          {this.props.show_header && (
            <div className='mb-2'>
              <h5>
                <SafeLink href={performance.url} className='text-decoration-none'>
                  <Avatar
                    url={performance.band_avatar_url}
                    classes='medium-avatar'
                  />
                  {performance.band_name}
                </SafeLink>
                &nbsp;<GuestListCount
                  comps_used={performance.comps_used}
                  num_comps={performance.num_comps}
                  show_parens
                />
              </h5>

              <SafeLink href={`${performance.gig_url}/guest_list/${performance.id}`}>
                View Guest List
              </SafeLink>
            </div>
          )}

          {this.state.is_loading && this.renderLoadingBlock()}
          <RenderGuestList guest_list_spots={performance.guest_list_spots} />
        </div>
      )
    }
    return (
      <div className='alert alert-warning mb-4'>
        You don't have permission to view the guest list for this show.
      </div>
    )
  }
}

export default connect((state, ownProps) => ({
  performance: selectModel('performances', ownProps.performance_id, Resources.performance, state),
}), (dispatch) => bindActionCreators({
  getGuestListSpots,
}, dispatch))(ShowGuestList)
