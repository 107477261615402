import React from 'react'

import SharedGigs from 'components/gigs/SharedGigs'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getPersonSharedGigs } from 'actions/api/people'
import { withRouter } from 'react-router'

class PersonSharedGigs extends SharedGigs {

  componentWillReceiveProps(props) {
    if (props.person_id !== this.props.person_id) {
      this.getListData(props)
    }
  }

  //Override
  requestListData(page, props=this.props) {
    return props.getPersonSharedGigs(props.person_id, this.state.page)
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  getPersonSharedGigs,
}, dispatch))(withRouter(PersonSharedGigs))
