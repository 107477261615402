import { NOTIFICATION_ACTION } from 'actions/api/notifications'

import { isSuccessAction } from 'actions'

export default (state={}, action) => {
  if (action.type.match(NOTIFICATION_ACTION) &&
      isSuccessAction(action) &&
      action.payload) {
    return {
      ...state,
      num_unviewed_notifications: action.payload.num_unviewed,
    }
  }

  return state
}
