import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { basicGet } from 'actions/api'

import SearchSelector from 'components/SearchSelector'

import { iff } from 'utils'

class DocumentSelector extends SearchSelector {

  minSearchLength() { return 0 }

  constructor(props) {
    super(props)

    const documents = []
    if (props.initial_doc && props.initial_doc.id) {
      documents.push(props.initial_doc)
    }

    this.state = {
      ...this.state,
      documents,
    }
  }

  requestOptions() {
    this.props.basicGet(`${this.props.parent_url}/documents`, 'DOCUMENT_SELECTOR')
      .then((response) => {
        if (!response.error) {
          this.setOptions(response.payload.results)
        } else {
          this.setOptions()
        }
      }
    )
  }

  setOptionsCallback(results) {
    this.setState({
      documents: _.uniqBy(this.state.documents.concat(results), 'id'),
    }, () => iff(this.props.onGetOptions, this.state.documents))
  }

  addOptions(options) {
    const new_options = _.map(this.state.documents, (d) => ({ value: d.id, label: d.name }))

    if (this.props.onNew) {
      new_options.push({
        value: this.newValue(),
        label: `+ Create New Document`
      })
    }

    return _.uniqBy(options.concat(new_options), 'value')
  }
}

export default connect(null, (dispatch) => bindActionCreators({
  basicGet
}, dispatch))(DocumentSelector)
