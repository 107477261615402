import React from 'react'
import classnames from 'classnames'

import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectCurrentUser } from 'selectors'
import { getCurrentUser } from 'actions/api/users'
import { withRouter } from 'react-router'

import Selector from 'components/Selector'
import NewGigForm from 'components/gigs/NewGigForm'
import NewLodgingForm from 'components/lodgings/NewLodgingForm'

const DEFAULT_PATH = '/new/gig'

const PATHS = {
  GIG: '/new/gig',
  LODGING: '/new/lodging',
}

const EVENT_TYPE_OPTIONS = [
  { label: 'Show', value: PATHS.GIG },
  { label: 'Housing', value: PATHS.LODGING },
]

class NewEventPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      org: '',
    }
  }

  componentDidMount() {
    this.props.getCurrentUser()
  }

  componentWillReceiveProps(props) {
    if (_.indexOf(_.values(PATHS), props.location.pathname) === -1) {
      props.history.replace(DEFAULT_PATH)
    }

    if (!this.userHasBands(props) && props.location.pathname === PATHS.LODGING) {
      props.history.replace(DEFAULT_PATH)
    }
  }

  userHasBands(props = this.props) {
    const user = props.user || {}
    const person = user.person || {}

    return person.bands && person.bands.length > 0
  }

  renderForm() {
    return (
      <div>
        <Switch>
          <Route path={PATHS.LODGING} render={() => (
            <NewLodgingForm
              user={this.props.user}
              org={this.state.org}
              onUpdateOrganization={(org) => this.setState({ org })}
            />
          )} />
          <Route path={PATHS.GIG} render={() => (
            <NewGigForm
              user={this.props.user}
              org={this.state.org}
              onUpdateOrganization={(org) => this.setState({ org })}
            />
          )} />
        </Switch>
      </div>
    )

  }

  render() {
    return (
      <div className='container'>
        <h2>New Event</h2>

        {this.userHasBands() && (
          <>
            <h5>Event Type</h5>
              <Selector
                name='event_types'
                classes="form-select mb-3"
                onChange={(v) => this.props.history.replace(v)}
                options={EVENT_TYPE_OPTIONS}
              />
          </>
        )}

        {this.renderForm()}
      </div>
    )
  }
}


export default connect((state, props) => ({
  user: selectCurrentUser(state)
}), (dispatch) => bindActionCreators({
  getCurrentUser,
}, dispatch))(withRouter(NewEventPage))
