import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'

import {
  selectPerformanceOrLocationFromPath,
  selectCurrentUser,
} from 'selectors'

import SafeLink from 'components/SafeLink'

export const DISMISSED_ADVANCE_FLOATING_MESSAGE_KEY = 'dismissed_advance_floating_registration'

const AdvanceFloatingRegistration = ({ advanceable, current_user }) => {
  const alreadyDismissed = sessionStorage.getItem(DISMISSED_ADVANCE_FLOATING_MESSAGE_KEY) === 'true'
  const [isDismissed, setIsDismissed] = useState(alreadyDismissed)

  const gigName = advanceable?.gig?.default_name || null
  const isLoggedIn = !_.isNil(current_user?.id)

  const dismiss = () => {
    setIsDismissed(true)
    sessionStorage.setItem(DISMISSED_ADVANCE_FLOATING_MESSAGE_KEY, 'true')
  }

  if (isDismissed || isLoggedIn) return null

  return (
    <div className="alert alert-primary floating-registration shadow">
      <span>
        <SafeLink to={`${_.get(advanceable, 'url', '')}/advance/register`}>Make a Backline account</SafeLink> to stay updated {gigName ? `on ${gigName}!` : ''}
      </span>
      <i
        className="icon-base fi-x floating-registration__close"
        onClick={dismiss}>
      </i>
    </div>
  )
}

export default withRouter(connect((state, ownProps) => {
  const { entity } = selectPerformanceOrLocationFromPath(state, ownProps.match.path, ownProps.match.params.id)
  return {
    current_user: selectCurrentUser(state),
    advanceable: entity,
  }
}, () => ({}))(AdvanceFloatingRegistration))
