import React from 'react'
import 'whatwg-fetch'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'

import appReducer from 'reducers'
import AppBase from 'components/AppBase'
import ErrorHandler from 'components/ErrorHandler'

const loggerMiddleware = (store) => (next) => (action) => {
  let beforeStore = store.getState()
  let result = next(action)
  console.log(action.type, beforeStore, action, store.getState())
  return result
}

let middleware = applyMiddleware(apiMiddleware)
if (window.is_development) {
  middleware = applyMiddleware(apiMiddleware, loggerMiddleware)
}

const store = createStore(appReducer, {}, middleware)

export default class App extends React.Component {

  render() {
    return (
      <Provider store={store}>
        <ErrorHandler>
          <AppBase />
        </ErrorHandler>
      </Provider>
    )
  }
}

