import React from 'react'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { adminGetLocation } from 'actions/api/admin/locations'

import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import LoadingState from 'components/LoadingState'
import AdminHeader from 'components/admin/AdminHeader'

import ShowContacts from 'components/contacts/ShowContacts'
import ShowAddress from 'components/addresses/ShowAddress'
import RenderValue from 'components/RenderValue'
import ShowPhone from 'components/people/ShowPhone'
import PersonnelList from 'components/crew_members/PersonnelList'
import EditableAttachmentsList from 'components/documents/EditableAttachmentsList'
import EditableTextArea from 'components/gigs/editable/EditableTextArea'
import ConfirmationButton from 'components/ConfirmationButton'

import {
  PERSPECTIVE_TYPE,
  VENUE_DOCUMENT_NAMES,
  INVITE_STATUS,
  CREWABLE_TYPE
} from 'static'

class AdminLocationPage extends LoadingState {

  componentWillMount() {
    this.setLoading(() => this.props.adminGetLocation(this.props.match.params.id).then((res) =>
      this.setState({ is_loading: false, is_loaded: true })))
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setState(
        { is_loading: true, is_loaded: false },
        () => props.adminGetLocation(props.match.params.id).then((res) =>
          this.setState({ is_loading: false, is_loaded: true })
        )
      )
    }
  }

  canEditLocation() { return false }

  isVenueOwner() {
    const location = this.props.location_model || {}
    const gig = location.gig || {}
    const perspective = gig.perspective || {}
    return perspective.type === PERSPECTIVE_TYPE.VENUE &&
        perspective.id === location.venue_id
  }

  renderContacts() {
    const location = this.props.location_model || {}
    const contacts = location.contacts || []
    if (contacts.length < 1) { return null }

    return (
      <div>
        <h5>
          Contacts
          {this.canEditLocation() && (
            <SafeLink to={`/admin${location.url}/crew_members`}>
              <i className='half-margin-left edit-icon fi-pencil'></i>
            </SafeLink>
          )}
        </h5>
        <ShowContacts
          contacts={contacts}
          show_avatar
          show_invite
          hide_title
          is_admin_view
        />
      </div>
    )
  }

  render() {
    if (!this.props.location_model) { return null }

    const location = this.props.location_model || {}
    const gig = location.gig || {}
    const venue = location.venue || {}
    const perspective = gig.perspective || {}
    const attachments = location.attachments || []

    return (
      <div className="container">
        <AdminHeader />

        <SafeLink
          to={`/admin${gig.url}`}
          className='mb-2'
        >&lt; back to {gig.gig_name || gig.default_name}</SafeLink>

        <div className="mt-3 mb-4">
          <PageTitle>Location Details</PageTitle>
        </div>

        <div className='card p-0'>
          <div className={classnames('p-3', 'header-bg', {
            'red': location.invite_status === INVITE_STATUS.REJECTED,
            'blue': location.invite_status === INVITE_STATUS.PENDING,
          })}>
            <SafeLink to={`/admin${venue.url}`}>
              <div className='d-flex align-items-center'>
                <div>
                  <Avatar
                    url={venue.avatar_url}
                    classes='medium-avatar'
                    small_classes='smaller-avatar'
                    responsive_size
                    blank_icon='Venue'
                  />
                </div>

                <div className='columns'>
                  <h4 className='m-0'>
                    {venue.name}&nbsp;
                    {venue.username &&
                      <span className='smaller-text'>
                        (@{venue.username})
                      </span>
                    }
                  </h4>
                </div>
              </div>
            </SafeLink>
          </div>

          <hr className='m-0' />

          {location.invite_status === INVITE_STATUS.REJECTED && (
            <div className='p-3 text-center'>
              <div>
                <strong> Location Invite Rejected </strong>
              </div>

              <a
                className='text-danger'
                onClick={() => this.onDelete()}
                disabled={this.state.is_loading}
              >Click here to remove venue</a>
            </div>
          )}

          <div className='p-3'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                {location.invite_status === INVITE_STATUS.PENDING && (
                  <div>
                    <strong>
                      (pending)
                    </strong>
                  </div>
                )}

                <span className='mb-3'>
                  <SafeLink to={`/admin${gig.url}/guest_list/master`}>
                    View Master Guest List
                  </SafeLink>
                </span>

                <h5>
                  Venue Details
                  {this.canEditLocation() && venue.is_editable && (
                    <SafeLink to={`/admin${location.url}/venue_update`}>
                      &nbsp;<i className='edit-icon fi-pencil'></i>
                    </SafeLink>
                  )}
                </h5>

                <div className='card p-3 bg-light'>
                  <a
                    target="_blank"
                    href={venue.address && venue.address.maps_url}
                    className='block mb-3'
                  >
                    <strong>
                      <ShowAddress
                        address={venue.address}
                      />
                    </strong>
                  </a>
                  {venue.main_phone && (
                    <div> <strong>Main Phone</strong>: <ShowPhone number={venue.main_phone}/> </div>
                  )}
                  {venue.box_office_phone && (
                    <div> <strong>Box Office Phone</strong>: <ShowPhone number={venue.box_office_phone}/> </div>
                  )}
                  <RenderValue value={venue.capacity} label='Capacity' />
                  <RenderValue value={venue.wifi} label='Wifi' />
                  <RenderValue value={venue.wifi_password} label='Wifi Password' />
                  <RenderValue class_name='prewrap' value={venue.parking} label='Parking' />
                  <RenderValue class_name='prewrap' value={venue.load_in_info} label='Load-In Info' />
                  <RenderValue class_name='prewrap' value={venue.green_room_info} label='Green Room Info' />
                  {venue.has_laundry && (<div>Laundry Available</div>)}
                  {venue.has_showers && (<div>Showers Available</div>)}
                </div>

                {this.renderContacts()}

                <EditableTextArea
                  update={(update) => this.updateLocation(update)}
                  value={location.merch_info}
                  post_key='merch_info'
                  title='Merch Info'
                  placeholder='Add your merch details here...'
                  is_editable={this.canEditLocation()}
                />

                <EditableTextArea
                  update={(update) => this.updateLocation(update)}
                  value={location.hospitality_details}
                  post_key='hospitality_details'
                  title='Hospitality Details'
                  placeholder='Add your hospitality details here...'
                  is_editable={this.canEditLocation()}
                />
              </div>

              <div className='mt-3 mt-md-0 col-12 col-md-6'>
                <EditableAttachmentsList
                  documentable={location}
                  name_options={VENUE_DOCUMENT_NAMES}
                  is_editable={this.canEditLocation()}
                  is_loaded={this.state.is_loaded}
                />

                <PersonnelList
                  is_editable={this.canEditLocation()}
                  crewable={location}
                  is_admin_view
                />

                <EditableTextArea
                  update={(update) => this.updateLocation(update)}
                  value={location.show_notes}
                  post_key='show_notes'
                  title='Additional Show Notes'
                  placeholder='Add your show notes details here...'
                  is_editable={this.canEditLocation()}
                />
              </div>
            </div>
          </div>
        </div>

        {this.canEditLocation() && (
          <div className='row'>
            <div className='col-12 col-lg-6 offset-lg-3 pt-3'>
              <div className='card p-3'>
                <p>
                  <strong>Removing the venue will delete all existing location information (personnel list, attachments, etc).</strong>
                  {this.isVenueOwner() && (
                    <span>&nbsp;This may also cause you to lose access to this gig advance.</span>
                  )}
                </p>
                <ConfirmationButton
                  button_classes='m-0'
                  button_type='danger'
                  button_text='remove venue'
                  confirm_text='really remove?'
                  onConfirm={() => this.onDelete()}
                  disabled={this.state.is_loading}
                />
              </div>
            </div>
          </div>
        )}

        {this.renderLoadingPopup()}
      </div>
    )
  }


}

export default connect((state, ownProps) => ({
  location_model: selectModel('locations', ownProps.match.params.id, Resources.location, state)
}), (dispatch) => bindActionCreators({
  adminGetLocation
}, dispatch))(AdminLocationPage)
