import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectApiErrors } from 'selectors'
import { getPerson } from 'actions/api/people'

import PersonSelect from 'components/people/PersonSelect'
import OtherSelect from 'components/OtherSelect'
import PhoneInput from 'components/people/PhoneInput'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'
import ShowPhone from 'components/people/ShowPhone'
import ShowEmail from 'components/people/ShowEmail'
import LoadingState from 'components/LoadingState'
import ApiErrors from 'components/ApiErrors'

import {
  INVITE_STATUS
} from 'static'

import { iff } from 'utils'

class AdvanceNewCrewMemberForm extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      selected_person: {},
      is_new_person: false,
    }
  }

  componentDidMount() {
    this.getPersonFromId()
  }

  isOwnedPerson() {
    return !this.state.is_new_person &&
      this.props.crew_member.person_id &&
      this.state.selected_person.is_owned
  }

  selectPerson(id) {
    this.setState({ is_new_person: false }, () =>
      this.props.onUpdate({ person_id: id }, () => this.getPersonFromId()))
  }

  newPerson(name) {
    this.setState({ is_new_person: true }, () =>
      this.props.onUpdate({
        person_id: undefined,
        person: { name: name },
      })
    )
  }

  onCancelNewPerson() {
    this.setState({ is_new_person: false }, () =>
      this.props.onUpdate({ ..._.omit(this.props.crew_member, ['person']) }))
  }

  updatePerson(update) {
    const person = this.props.crew_member.person || {}
    this.props.onUpdate({
      person: {
        ...person,
        ...update,
      }
    })
  }

  updateCrewMember(update) { this.props.onUpdate(update) }

  getPersonFromId() {
    if (this.props.crew_member.person_id) {
      const person_id = this.props.crew_member.person_id
      this.setLoading(() => {
        this.props.getPerson(person_id).then((response) => {
          if (!response.error) {
            const person_data = response.payload.raw.person
            this.setSelectedPersonData(person_data, () => this.clearLoading())
          } else {
            this.clearLoading()
          }
        })
      })
    } else {
      this.setState({ selected_person: {} })
    }
  }

  setSelectedPersonData(person, callback) {
    const person_data = person || {}
    this.setState({ selected_person: person_data }, () => iff(callback))
  }

  renderPersonFields() {
    if (this.state.is_new_person) {
      let person = this.props.crew_member.person || {}
      return (
        <>
          <div className='mb-2'>
            <FormField
              label='Name'
              type='text'
              name='name'
              value={person.name || ''}
              onChange={(e) => this.updatePerson({ name: e.target.value })}
            />
          </div>
          <div className='mb-2'>
            <FormField
              label='Email'
              type='text'
              name='email'
              value={person.email || ''}
              onChange={(e) => this.updatePerson({ email: e.target.value })}
            />
          </div>
          <div className='mb-2'>
            <label className='form-label'>Phone</label>
            <PhoneInput
              name='phone'
              value={person.phone || ''}
              onChange={(v) => this.updatePerson({ phone: v })}
            />
          </div>
        </>
      )
    } else {
      const person_id = this.props.crew_member.person_id || ''
      return (
        <PersonSelect
          person_id={person_id}
          onSelect={(id) => this.selectPerson(id)}
          onNew={(name) => this.newPerson(name)}
        />
      )
    }
  }

  render() {
    const crew_member = this.props.crew_member || {}
    return (
      <>
        <div className='row'>
          <div className='col-12 col-md-6 mb-2'>
            {this.renderPersonFields()}
          </div>

          <div className='col-12 col-md-6 mb-2'>
            <div className='mb-2'>
              <label className='form-label'>Title</label>
              <OtherSelect
                name='title'
                options={_.map(this.props.title_options || [], (ct) => (
                  { value: ct, label: ct }
                ))}
                value={crew_member.title}
                onChange={(v) => this.updateCrewMember({ title: v })}
              />
            </div>

            <FormCheck
              label='Point of Contact'
              name='is_contact'
              className='half-margin-bottom'
              type='checkbox'
              checked={crew_member.is_contact || false}
              onChange={(e) => this.updateCrewMember({ is_contact: e.target.checked })}
              disabled={this.props.disabled}
            />

            <FormCheck
              label='Present Day of Show'
              name='is_on_site'
              className='half-margin-bottom'
              type='checkbox'
              checked={crew_member.is_on_site || false}
              onChange={(e) => this.updateCrewMember({ is_on_site: e.target.checked })}
              disabled={this.props.disabled}
            />
          </div>
        </div>

        <ApiErrors errors={this.props.api_errors} />

        <button
          className='btn btn-primary mb-0'
          onClick={() => this.props.onSave()}
          disabled={this.props.is_loading}
        >save</button>

        <button
          className='btn btn-gray mb-0'
          onClick={() => iff(this.props.onCancel)}
          disabled={this.props.is_loading}
        >cancel</button>
      </>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
}), (dispatch) => bindActionCreators({
  getPerson
}, dispatch))(AdvanceNewCrewMemberForm)
