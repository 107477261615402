import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  selectCurrentUser,
  selectApiErrors
} from 'selectors'

import Selector from 'components/Selector'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'
import AutocompleteAddress from 'components/addresses/AutocompleteAddress'
import ErrorBoundary from 'components/ErrorBoundary'

import {
  COUNTRY_OPTIONS,
  UNITED_STATES,
  STATE_OPTIONS
} from 'static'

const DEFAULT_ADDRESS = { country: UNITED_STATES }

class AddressForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = this.getStateFromProps(props)
  }

  componentWillReceiveProps(props) {
    this.setState(this.getStateFromProps(props))
  }

  getStateFromProps(props = this.props) {
    return {
      address: _.merge({ ...DEFAULT_ADDRESS }, props.address || {}),
      showAutocomplete: (_.isNil(props.address) || _.isEmpty(props.address)),
    }
  }

  updateAddress(update) {
    this.setState({
      address: {
        ...this.state.address,
        ...update,
      }
    }, () => this.props.onChange(this.state.address))
  }

  canShowAutocomplete() {
    return window.maps_api_available
  }

  isShowAutocomplete() {
    return this.canShowAutocomplete() && this.state.showAutocomplete
  }

  render() {
    const address = this.state.address || {}
    return (
      <>
        {this.isShowAutocomplete() ? (
          <>
            <ErrorBoundary
              errorMessage="Error: Google Maps API Address Completion failed to load."
              errorTag="[GoogleApiAutoComplete]"
              showError={window.is_development}
              callback={() => this.setState({ showAutocomplete: false })}
            >
              <div className='mb-3'>
                <AutocompleteAddress updateAddress={val => this.updateAddress(val)} />
              </div>
            </ErrorBoundary>
            <div>
              <a className='link' onClick={() => this.setState({ showAutocomplete: false })}>
                Click here to enter your address manually
              </a>
            </div>
          </>
        ) : (
          <>
            <div className='row'>
              <div className='col-12 col-md-8 mb-3'>
                <FormField
                  type='text'
                  label='Addr. Line 1'
                  name='address_line_one'
                  errorField='address.address_line_one'
                  errors={this.props.api_errors}
                  value={address.address_line_one || ''}
                  onChange={(e) => { this.updateAddress({ address_line_one: e.target.value }) }}
                  disabled={this.props.disabled}
                />
              </div>
              <div className='col-12 col-md-4 mb-3'>
                <FormField
                  type='text'
                  label='Addr. Line 2'
                  name='address_line_two'
                  errorField='address.address_line_two'
                  errors={this.props.api_errors}
                  value={address.address_line_two || ''}
                  onChange={(e) => { this.updateAddress({ address_line_two: e.target.value }) }}
                  disabled={this.props.disabled}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-md-4 mb-3'>
                <FormField
                  type='text'
                  label='City'
                  name='city'
                  errorField='address.city'
                  errors={this.props.api_errors}
                  value={address.city || ''}
                  onChange={(e) => { this.updateAddress({ city: e.target.value }) }}
                  disabled={this.props.disabled}
                />
              </div>
              <div className='col-12 col-md-4 mb-3'>
                {address.country === UNITED_STATES && (
                  <>
                    <label className='form-label'>State</label>
                    <Selector
                      name='state'
                      classes='form-select'
                      value={address.state || ''}
                      onChange={(v) => { this.updateAddress({ state: v }) }}
                      options={[{ value: '', label: '------' }].concat(STATE_OPTIONS)}
                      disabled={this.props.disabled}
                      simpleValue
                    />
                  </>
                )}
                {address.country !== UNITED_STATES && (
                  <FormField
                    type='text'
                    label='Region/Province'
                    name='region'
                    errorField='address.region'
                    errors={this.props.api_errors}
                    value={address.region || ''}
                    onChange={(e) => { this.updateAddress({ region: e.target.value }) }}
                    disabled={this.props.disabled}
                  />
                )}
              </div>
              <div className='col-12 col-md-4 mb-3'>
                <FormField
                  type='text'
                  label='Postal Code'
                  name='zip'
                  errorField='address.zip'
                  errors={this.props.api_errors}
                  value={address.zip || ''}
                  onChange={(e) => { this.updateAddress({ zip: e.target.value }) }}
                  disabled={this.props.disabled}
                />
              </div>

              <div className='col-12'>
                <label className='form-label'>Country</label>
                <Select
                  name='country'
                  value={address.country || ''}
                  onChange={(v) => { this.updateAddress({ country: v }) }}
                  options={COUNTRY_OPTIONS}
                  disabled={this.props.disabled}
                  simpleValue
                />
              </div>
            </div>

            {this.canShowAutocomplete() && (
              <a className='link' onClick={() => this.setState({ showAutocomplete: true })}>
                Click here to search for an address
              </a>
            )}
          </>
        )}
      </>
    )
  }
}

export default connect((state) => {
  return {
    current_user: selectCurrentUser(state),
    api_errors: selectApiErrors(state),
  }
}, null)(AddressForm)
