import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import { Resources } from 'schema'
import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { searchPeople } from 'actions/api/people'

import PaginatedList from 'components/PaginatedList'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import ShowPhone from 'components/people/ShowPhone'
import ShowEmail from 'components/people/ShowEmail'
import Avatar from 'components/Avatar'
import Row from 'components/Row'

const MIN_PERSON_NAME_LENGTH = 3

class PersonSelect extends PaginatedList {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      person_search: '',
    }
  }

  componentDidMount() {
    this.search_input.focus()
  }

  requestListData(page) {
    return this.props.searchPeople(this.state.person_search, page)
  }

  getListFromData(data) {
    return data.results
  }

  onPersonSearch(query) {
    this.setState({ person_search: query, page: 0 }, () => {
      if (!this.state.is_loading) {
        this.getListData()
      }
    })
  }

  canMakeNewPerson() {
    return _.trim(this.state.person_search).length >= MIN_PERSON_NAME_LENGTH
  }

  selectPerson(p) {
    const selected_person = this.props.selected_person || {}
    if (p.id === selected_person.id) { return null }
    this.props.onSelect(p.id)
  }

  renderSelectedPersonDetails() {
    const selected_person = this.props.selected_person || {}
    if (_.isEmpty(selected_person.email) &&
        _.isEmpty(selected_person.phone)) {
      return null
    }

    return (
      <div className='p-2'>
        <div>
          <ShowPhone number={selected_person.phone} show_label no_link />
        </div>
        <div>
          <ShowEmail email={selected_person.email} show_label no_link />
        </div>
      </div>
    )
  }

  render() {
    const gig = this.props.gig || {}
    const selected_person_id = this.props.person_id
    const selected_person = this.props.selected_person || {}

    return (
      <>
        <div className='mb-2'>
          <label className='form-label'>Name</label>
          <input
            type='text'
            name='person_search'
            className='form-control'
            value={this.state.person_search}
            ref={(i) => { this.search_input = i }}
            onChange={(e) => this.onPersonSearch(e.target.value)}
          />
        </div>

        <label>Choose a Person</label>
        <div className='list-group list-group-striped'>
          {_.map(this.state.list, (p) => (
            <a
              key={`search-person-${p.id}`}
              className={classnames('list-group-item list-group-item-action d-block pointer', {
                'list-group-item-primary': p.id === selected_person_id,
              })}
              onClick={() => this.selectPerson(p)}
            >
              <Avatar
                url={p.avatar_url}
                classes='small-avatar'
                blank_icon='Person'
              />
              <strong>{p.name}</strong>&nbsp;
              {p.username && (
                <React.Fragment>(@{p.username})</React.Fragment>
              )}

              {p.id === selected_person.id && this.renderSelectedPersonDetails()}
            </a>
          ))}

          {this.canMakeNewPerson() && (
            <a
              className='list-group-item list-group-item-action d-block'
              onClick={() => this.props.onNew(this.state.person_search)}
            >
              <span className='text-dark'>
                <strong>
                  <i className='fi-plus me-2'></i>Create New Person - {this.state.person_search}
                </strong>
              </span>
            </a>
          )}

          {!this.canMakeNewPerson() && _.isEmpty(this.state.list) && (
            <div className='list-group-item text-center'>
              No Results.  Type at least 3 letters to add a new person or change your search query.
            </div>
          )}
        </div>
      </>
    )
  }
}

export default connect((state, props) => {
  return ({
    selected_person: selectModel('people', props.person_id, Resources.person, state)
  })
}, (dispatch) => bindActionCreators({
  searchPeople
}, dispatch))(PersonSelect)
