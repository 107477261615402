import React from 'react'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { withRouter } from 'react-router'
import { createLocation } from 'actions/api/locations'
import { getGig } from 'actions/api/gigs'

import LocationForm from 'components/locations/LocationForm'

import { isSuccessStatus } from 'utils'

class EditLocationPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    this.props.getGig(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      props.getGig(props.match.params.id)
    }
  }

  onSubmit(location_data) {
    this.setState({ is_loading: true }, () => {
      this.props.createLocation(this.props.match.params.id, location_data)
        .then((response) => {
          if (isSuccessStatus(response.payload.request_status)) {
            this.props.history.push(response.payload.raw.location.url)
          } else {
            this.setState({ is_loading: false })
          }
        })
    })
  }

  render() {
    const gig = this.props.gig || {}
    return (
      <LocationForm
        onSubmit={(l_data) => this.onSubmit(l_data)}
        cancel_url={`/gigs/${this.props.match.params.id}`}
        is_loading={this.state.is_loading}
        gig={gig}
      />
    )
  }
}


export default connect((state, ownProps) => ({
  gig: selectModel('gigs', ownProps.match.params.id, Resources.gig, state),
}), (dispatch) => bindActionCreators({
  createLocation, getGig
}, dispatch))(withRouter(EditLocationPage))
