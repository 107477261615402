import React from 'react'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import { withRouter } from 'react-router'

const WelcomeWrapUpPage = ({
  match: {
    params: {
      org_type,
    },
  },
}) => {
  let organization_label = 'Band'
  if (org_type === 'venue') { organization_label = 'Venue' }
  return (
    <div className='container mt-3'>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-6'>
          <div className='border rounded p-3'>
            <h3>Registration Complete</h3>

            <p>
              Now that you've created your {organization_label}, you can add your upcoming shows to the schedule.
            </p>

            <p>
              Are you ready to get started?
            </p>

            <SafeLink className='btn btn-primary me-2' to="/new/gig">
              yes, let's go
            </SafeLink>

            <SafeLink className='btn btn-gray' to="/">
              not now
            </SafeLink>
          </div>
        </div>
      </div>
    </div>
  )
}


export default withRouter(WelcomeWrapUpPage)
