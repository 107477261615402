import React from 'react'

import { Switch, Route } from 'react-router-dom'

import HelpPersonnel from 'components/help/HelpPersonnel'

import EmailConfirmed from 'components/login/EmailConfirmed'

import PrivacyPolicy from 'components/PrivacyPolicy'
import TermsAndConditions from 'components/TermsAndConditions'

import TestClientError from 'components/TestClientError'

export default class StaticRoutes extends React.Component {

  render() {
    return (
      <Switch>
        <Route exact path='/confirm_email' component={EmailConfirmed} />

        <Route exact path='/help/personnel' component={HelpPersonnel} />
        <Route exact path='/privacy' component={PrivacyPolicy} />
        <Route exact path='/terms' component={TermsAndConditions} />
        <Route exact path='/test_client_error' component={TestClientError} />

        <Route render={() => this.props.children} />
      </Switch>
    )
  }
}
