import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import { format } from 'libphonenumber-js'

export default ({ number, classes, show_icon, show_label, no_link }) => {
  if (_.isNil(number) || _.isEmpty(number)) { return null }

  let formatted_number = format(number, 'National')
  if (!formatted_number) {
    formatted_number = format(number, 'International')
  }
  if (!formatted_number) {
    formatted_number = number
  }

  return (
    <>
      {show_label && (
        <span>
          Phone:&nbsp;
        </span>
      )}
      {no_link && (
        <React.Fragment>
          {show_icon && (
            <span>
              <i className='fi-telephone'></i>&nbsp;
            </span>
          )}
          {formatted_number}
        </React.Fragment>
      )}
      {!no_link && (
        <a href={`tel:${number}`} className={classnames('text-decoration-none', classes)}>
          {show_icon && (
            <span>
              <i className='fi-telephone'></i>&nbsp;
            </span>
          )}
          {formatted_number}
        </a>
      )}
    </>
  )
}
