import React from 'react'
import _ from 'lodash'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import Avatar from 'components/Avatar'
import ShowPhone from 'components/people/ShowPhone'
import ShowEmail from 'components/people/ShowEmail'
import InviteButton from 'components/invite/InviteButton'

import {
  INVITE_STATUS
} from 'static'

export default class ShowContact extends React.Component {

  getPersonId() {
    return this.props.contact.person_id
  }

  getPersonObj() {
    return this.props.contact
  }

  getPersonUrl() {
    const contact = _.get(this.props, 'contact', {})
    if (this.props.is_admin_view) {
      return `/admin/people/${contact.person_id}`
    } else {
      return `/people/${contact.person_id}`
    }
  }

  showInvite() {
    return this.props.show_invite && this.props.contact.invite_status !== INVITE_STATUS.ACCEPTED
  }

  render() {
    const contact = this.props.contact || {}
    return (
      <>
        {!this.props.hide_name && (
          <div className='d-flex flex-row align-items-top mb-1 mt-1'>
            {this.props.show_avatar && (
              <Avatar
                url={contact.avatar_url}
                classes='medium-avatar flex-grow-0 me-2'
                blank_icon='Person'
              />
            )}
            <span>
              <div>
                <strong>
                  <SafeLink to={this.getPersonUrl()} className='text-decoration-none'>
                    {contact.name}&nbsp;
                  </SafeLink>
                </strong>
                {!contact.is_person_owned && !this.props.hide_invite_button && (
                  <InviteButton
                    person={{
                      id: contact.person_id,
                      email: contact.email,
                      name: contact.name,
                    }}
                  />
                )}
                {contact.username && (<span>(@{contact.username})</span>)}
              </div>

              <div>
                {contact.title}
                {this.props.show_admin && contact.is_admin && (
                  <span className='badge bg-danger ms-2'>
                    admin
                  </span>
                )}
                {this.props.show_primary && contact.is_primary && (
                  <span className='badge bg-success ms-2'>
                    default personnel
                  </span>
                )}
                {this.props.show_public && contact.is_public && (
                  <span className='badge bg-warning ms-2'>
                    public
                  </span>
                )}
                {this.props.show_is_on_site && contact.is_on_site && (
                  <span className='badge bg-primary ms-2'>
                    day of show
                  </span>
                )}
              </div>
            </span>
          </div>
        )}

        {this.showInvite() && (
          <div className='mt-3 mb-2 text-center'>
            {contact.invite_status === INVITE_STATUS.PENDING &&
              <span>Invite Sent &mdash; Awaiting Response</span>
            }

            {contact.invite_status === INVITE_STATUS.REJECTED &&
              <span className='text-danger'>Invite Rejected</span>
            }
          </div>
        )}

        {this.props.contact.invite_status === INVITE_STATUS.ACCEPTED && (
          <>
            <div>
              <ShowPhone number={contact.phone} show_label />
            </div>
            <div>
              <ShowEmail email={contact.email} show_label />
            </div>
          </>
        )}
      </>
    )
  }

}
