import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { selectModel, selectCurrentUser } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getPerformance } from 'actions/api/performances'

import Row from 'components/Row'
import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import LoadingState from 'components/LoadingState'
import InviteHeader from 'components/InviteHeader'

import RenderValue from 'components/RenderValue'
import PersonnelList from 'components/crew_members/PersonnelList'
import ShowContacts from 'components/contacts/ShowContacts'
import ShowGuestList from 'components/guest_list/ShowGuestList'
import AttachmentsList from 'components/documents/AttachmentsList'
import EditableNumDayOfShow from 'components/performances/EditableNumDayOfShow'
import EditableProductionDescription from 'components/performances/EditableProductionDescription'
import EditableTextArea from 'components/gigs/editable/EditableTextArea'
import PerformanceAdvanceProgress from 'components/performances/PerformanceAdvanceProgress'

class PerformanceAdvanceSummary extends LoadingState {

  loadPerformance(props = this.props) {
    this.setLoading(() => props.getPerformance(props.performance_id).then(() => this.clearLoading()))
  }

  componentWillMount() {
    this.loadPerformance()
  }

  componentWillReceiveProps(props) {
    if (props.performance_id !== this.props.performance_id) {
      this.loadPerformance(props)
    }
  }

  render() {
    if (!this.props.performance) { return null }

    const performance = _.get(this, 'props.performance', {})
    const gig = _.get(performance, 'gig', {})
    const band = _.get(performance, 'band', {})
    const attachments = _.get(performance, 'attachments', [])
    const contacts = _.get(performance, 'contacts', [])

    return (
      <div className='p-2'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <div className='mb-3'>
              {_.isEmpty(contacts) ? (
                <>
                  <h5>Contacts</h5>
                  <div className='border rounded p-3 bg-light text-center'>
                    <strong>No Contacts</strong>
                  </div>
                </>
              ) : (
                <ShowContacts
                  contacts={contacts}
                  show_avatar
                  show_is_on_site
                  hide_invite_button
                  />
              )}
            </div>

            <div className='mb-3'>
              {_.isEmpty(_.get(performance, 'crew_members', [])) ? (
                <>
                  <h5>Personnel</h5>
                  <div className='border rounded p-3 bg-light text-center'>
                    <strong>No Personnel</strong>
                  </div>
                </>
              ) : (
                <PersonnelList
                  crewable={performance}
                  hide_invite
                />
              )}

              <div className='mt-1'>
                <EditableNumDayOfShow num_day_of_show={performance.num_day_of_show} />
              </div>
            </div>

            <div className='mb-3'>
              <EditableProductionDescription
                production_description={performance.production_description}
                house_sound={performance.house_sound}
                house_lights={performance.house_lights}
              />
            </div>

            <div className='mb-3'>
              <EditableTextArea title='Transportation' value={performance.transportation} />
            </div>
          </div>

          <div className='col-12 col-lg-6'>
            <div className='mb-3'>
              <AttachmentsList attachments={attachments} />
            </div>

            <div className='mb-3'>
              <h5>Guest List</h5>
              <ShowGuestList performance_id={performance.id} />
            </div>

            <div className='mb-3'>
              <EditableTextArea title='Additional Show Notes' value={performance.show_notes} />
            </div>
          </div>
        </div>
        {this.renderLoadingPopup()}
      </div>
    )
  }

}

export default connect((state, ownProps) => ({
  performance: selectModel('performances', ownProps.performance_id, Resources.performance, state),
}), (dispatch) => bindActionCreators({
  getPerformance
}, dispatch))(PerformanceAdvanceSummary)
