import React from 'react'
import _ from 'lodash'

export default class apiErrors extends React.Component {

  render() {
    if (_.isEmpty(this.props.errors)) {
      return null
    }

    const error_keys = _.keys(this.props.errors)
    return (
      <div>
        {_.map(error_keys, (key) => {
          const value = this.props.errors[key]
          return (<div className='red-text'>{key}&nbsp;{value}</div>)
        })}
      </div>
    )
  }
}
