import _ from 'lodash'

export function isTicketCountsEnabledForGig(gig = {}) {
  const perspective = gig.perspective || {}

  const optedInBand = perspective.type === 'Band' && window.ticket_count_band_ids?.includes(perspective.id)
  const optedInVenue = perspective.type === 'Venue' && window.ticket_count_venue_ids?.includes(perspective.id)

  return (optedInBand || optedInVenue)
}

export function isTicketCountsEnabledForUser(user = {}) {
  const hasOptedInBand = _.reduce(_.get(user, 'person.bands', []), (memo, user_band) => {
    return window.ticket_count_band_ids?.includes(_.get(user_band, 'id')) || memo
  }, false)
  const hasOptedInVenue = _.reduce(_.get(user, 'person.venues', []), (memo, user_venue) => {
    return window.ticket_count_venue_ids?.includes(_.get(user_venue, 'id')) || memo
  }, false)

  return (hasOptedInBand || hasOptedInVenue)
}
