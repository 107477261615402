import React from 'react'

import EditableField from 'components/EditableField'

export default class EditableScheduleName extends EditableField {

  getValueFromProps(props) {
    return props.name || ''
  }

  getPostData() {
    return { name: this.state.value }
  }

  render() {
    if (this.state.is_editing && this.props.is_editable) {
      return (
        <div className='d-flex flex-row align-items-center mb-3'>
          <div className='flex-grow-0'>
            <input
              ref={(ref) => this.input = ref}
              type='text'
              className='form-control'
              value={this.state.value}
              placeholder={this.props.default_name}
              onChange={(e) => this.onChange(e.target.value)}
              onBlur={(e) => this.onBlur(e.target.value)}
            />
          </div>

          <div className='flex-grow-1'>
            <a
              key='save'
              onClick={() => this.save()}>
              <i className='fi-check icon-base ms-2 text-success'></i>
            </a>
          </div>

        </div>
      )
    } else {
      return (
        <div>
          <h4>
            {this.state.value}
            {this.props.is_editable && (
              <a
                key='edit'
                onClick={() => this.onEdit()}
              ><i className='ms-2 smaller-text edit-icon fi-pencil'></i></a>
            )}
          </h4>
        </div>
      )
    }
  }
}
