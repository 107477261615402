import React from 'react'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { withRouter } from 'react-router'
import { getLocation, updateLocation } from 'actions/api/locations'

import Row from 'components/Row'
import SaveBar from 'components/SaveBar'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import GigVenueForm from 'components/gigs/GigVenueForm'
import LoadingState from 'components/LoadingState'
import BreadCrumbs from 'components/BreadCrumbs'

import { isSuccessStatus } from 'utils'

class VenueUpdatePage extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...this.getStateFromProps(props),
    }
  }

  componentWillMount() {
    this.setLoading(() => this.props.getLocation(this.props.match.params.id).then((res) => this.clearLoading()))
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setLoading(() => props.getLocation(props.match.params.id).then((res) => this.clearLoading()))
    }

    const new_location = props.location_model || {}
    const old_location = this.props.location_model || {}
    if (new_location.id !== old_location.id) {
      this.setState({ ...this.getStateFromProps(props) })
    }
  }

  getStateFromProps(props) {
    const location = props.location_model || {}
    const venue = location.venue || {}
    return { venue }
  }

  updateVenue(update) {
    this.setState({
      venue: {
        ...this.state.venue,
        ...update,
      }
    })
  }

  submit() {
    const location_data = { venue_updates: this.state.venue }
    this.setLoading(() => {
      this.props.updateLocation(this.props.match.params.id, location_data)
        .then((response) => {
          if (isSuccessStatus(response.payload.request_status)) {
            this.props.history.push(`/locations/${this.props.match.params.id}`)
          } else {
            this.clearLoading()
          }
        })
    })
  }

  render() {
    const location = this.props.location_model || {}
    return (
      <>
        <div className='container'>
          <BreadCrumbs items={[
            { name: 'Shows', url: '/' },
            { name: location.gig_name, url: location.gig_url },
            { name: location.venue_name, url: location.url },
            { name: 'Edit Venue' },
          ]} />

          <h2>Update Venue</h2>

          <GigVenueForm
            onChange={(update) => this.updateVenue(update)}
            venue={this.state.venue}
            show_private_checkbox
            disabled={this.state.is_loading}
          />
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
        />
      </>
    )
  }
}


export default connect((state, ownProps) => ({
  location_model: selectModel('locations', ownProps.match.params.id, Resources.location, state),
}), (dispatch) => bindActionCreators({
  updateLocation, getLocation
}, dispatch))(withRouter(VenueUpdatePage))
