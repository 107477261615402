import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import moment from 'moment'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectPerformanceOrLocationFromPath } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getPerformance } from 'actions/api/performances'
import { getLocation } from 'actions/api/locations'
import { createCrewMember, updateCrewMember, deleteCrewMember } from 'actions/api/crew_members'

import Row from 'components/Row'
import PageTitle from 'components/PageTitle'
import SafeLink from 'components/SafeLink'
import EditableList from 'components/EditableList'
import BottomBar from 'components/BottomBar'
import LocationAdvanceProgress from 'components/locations/LocationAdvanceProgress'
import PerformanceAdvanceProgress from 'components/performances/PerformanceAdvanceProgress'

import AdvanceCrewMemberEdit from 'components/crew_members/advance/AdvanceCrewMemberEdit'
import AdvanceNewCrewMemberForm from 'components/crew_members/advance/AdvanceNewCrewMemberForm'

import {
  BAND_CREW_MEMBER_TITLES,
  VENUE_CREW_MEMBER_TITLES,
  CREWABLE_TYPE
} from 'static'

import { iff, isEditableByPerspective } from 'utils'

class AdvanceCrewMembersPage extends EditableList {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      new_crew_member: {},
      show_new_form: false,
    }
  }

  // Override
  getIdFromProps(props) { return _.get(props, 'match.params.id') }

  // Override
  getDataFromListItem(li) { return { ...li } }

  // Override
  getListItemFromResponse(response) { return _.get(response, 'payload.raw.crew_member', {}) }

  // Override
  getListFromResponse(response) {
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return _.get(response, 'payload.raw.performance.crew_members', [])
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      return _.get(response, 'payload.raw.location.crew_members', [])
    }
    throw "AdvanceCrewMembersPage has an invalid page_type"
  }

  // Override
  isEditable() {
    const perf_or_loc = _.get(this.props, 'crewable', {})
    const perspective = _.get(perf_or_loc, 'gig.perspective', {})
    return isEditableByPerspective(perspective, perf_or_loc, perspective.gig_permissions)
  }

  // Override
  sortEditableList(crew_members) {
    const active_crew_members = _.filter(crew_members, (cm) => !cm['_destroyed'])
    return _.sortBy(active_crew_members, ['is_admin', 'is_primary', 'name'])
  }

  formatListItems(crew_members) {
    return _.map(crew_members, (cm) => ({
      ...cm,
      person: {
        phone: cm.phone,
        email: cm.email,
        name: cm.name,
      }
    }))
  }

  /**********************************
   * REQUESTS
   **********************************/
  // Override
  loadListDataRequest(props) {
    if (props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return props.getPerformance(props.match.params.id)
    } else if (props.page_type === CREWABLE_TYPE.LOCATION) {
      return props.getLocation(props.match.params.id)
    }
  }

  // Override
  updateListItemRequest(id, data) {
    return this.props.updateCrewMember(id, data)
  }

  // Override
  deleteListItemRequest(id) {
    return this.props.deleteCrewMember(id)
  }

  // Override
  createListItemRequest(data) {
    throw "AdvanceCrewMembersPage does not use createListItemRequest()"
  }

  // Override
  saveListRequest() {
    throw "AdvanceCrewMembersPage does not use saveListRequest()"
  }
  /****************************************/
  /****************************************/

  clearNewForm() {
    this.setState({
      show_new_form: false,
      new_crew_member: {},
    })
  }

  createRequest() {
    const crewable = this.props.crewable || {}
    const data = { ...this.state.new_crew_member }
    this.setLoading(() => this.props.createCrewMember(crewable.url, data).then((res) => {
      if (res.error) {
        this.clearLoading()
      } else {
        const crew_members = [
          ...this.state.editable_list,
          ...this.formatListItems([_.get(res, 'payload.raw.crew_member', {})]),
        ]
        this.setState({
          editable_list: this.sortEditableList(crew_members),
          new_crew_member: {},
          show_new_form: false,
          is_loading: false,
        })
      }
    }))
  }

  onUpdateNew(update, cb) {
    this.setState({
      new_crew_member: {
        ...this.state.new_crew_member,
        ...update,
      }
    }, () => iff(cb))
  }

  isEditing() {
    const is_editing = this.state.show_new_form ||
      _.reduce(this.state.editable_list, (memo, cm) => (memo || cm.is_editing), false)

    if (!is_editing && this.state.show_save_warning) {
      this.setState({ show_save_warning: false })
    }

    return is_editing
  }

  hasContact() {
    const has_contact = _.reduce(this.state.editable_list, (memo, cm) => (memo || cm.is_contact), false)

    if (has_contact && this.state.show_contact_error) {
      this.setState({ show_contact_error: false })
    }

    return has_contact
  }

  onNext() {
    if (this.isEditing()) {
      this.setState({ show_save_warning: true })
    } else if (!this.hasContact()) {
      this.setState({ show_contact_error: true })
    } else {
      const crewable = this.props.crewable || {}
      let next_url = ''
      if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
        next_url = `${crewable.url}/advance/guest_list`
      } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
        next_url = `${crewable.url}/advance/schedule`
      }
      this.props.history.push(next_url)
    }
  }

  renderPageText() {
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return (
        <i>
          Click “Add Personnel” to begin adding important people
          to the gig. This may include your band members, management,
          sound person, etc.
        </i>
      )
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      return (
        <i>
          Click “Add Personnel” to begin adding important people to the
          gig. This may include your production manager, sound/light
          person, day-of-show representative, etc.
        </i>
      )
    } else {
      return null
    }
  }

  renderNewForm() {
    const crewable = this.props.crewable || {}
  }

  render() {
    const crewable = this.props.crewable || {}
    const gig = crewable.gig || {}
    let titles = []
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      titles = BAND_CREW_MEMBER_TITLES
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      titles = VENUE_CREW_MEMBER_TITLES
    }

    return (
      <>
        <div className='container mt-3'>
          {this.props.page_type === CREWABLE_TYPE.PERFORMANCE && (
            <PerformanceAdvanceProgress performance={crewable} pageNum={3} />
          )}
          {this.props.page_type === CREWABLE_TYPE.LOCATION && (
            <LocationAdvanceProgress location={crewable} pageNum={3} />
          )}

          <div className='mb-2'>
            <PageTitle
              subtitle={`${moment(gig.show_date).format('MMMM Do YYYY')} - ${gig.name}`}
            >Personnel</PageTitle>
          </div>

          {this.isEditable() ? (
            <div className='alert alert-primary'>
              {this.renderPageText()}
            </div>
          ) : (
            <div className='alert alert-danger'>
              You are currently logged in as a user who does not have permission to
              edit {crewable.owner_name}&#39;s personnel for this show.
            </div>
          )}

          <div className='list-group list-group-striped mb-2'>
            {_.map(this.state.editable_list, (cm, i) => (
              <AdvanceCrewMemberEdit
                key={`crew_member-${cm.id}-${i}`}
                title_options={titles}
                crew_member={cm}
                is_editable={this.isEditable()}
                is_loading={this.props.is_loading}
                onUpdate={this.genUpdateListItem(i)}
                onSave={this.genSaveListItem(i)}
                onDelete={() => this.deleteListItem(i)}
              />
            ))}

            {_.isEmpty(this.state.editable_list) && (
              <div className='list-group-item text-center'>
                You have no Personnel. {/** TODO: ADD DESCRIPTION HERE **/}
              </div>
            )}
          </div>

          {this.state.show_new_form && this.isEditable() && (
            <div className='border rounded p-3'>
              <h5>Add Personnel</h5>
              <AdvanceNewCrewMemberForm
                crew_member={this.state.new_crew_member}
                title_options={titles}
                onSave={() => this.createRequest()}
                onUpdate={(update, cb) => this.onUpdateNew(update, cb)}
                onCancel={() => this.clearNewForm()}
                is_loading={this.props.is_loading}
              />
            </div>
          )}
          {!this.state.show_new_form && this.isEditable() && (
            <>
              <button
                className='btn btn-primary'
                onClick={() => this.setState({ show_new_form: true })}
              >Add Personnel</button>
            </>
          )}
        </div>

        <div className='spacer'></div>
        {this.isEditing() && this.state.show_save_warning && window.is_mobile && (<div className='spacer-2'></div>)}
        {!this.hasContact() && this.state.show_contact_error && window.is_mobile && (<div className='spacer-2'></div>)}

        <BottomBar>
          <div className='container py-2 d-flex flex-row align-items-center'>
            <div className='flex-grow-0'>
              <SafeLink
                to={`${crewable.url}/advance/documents`}
                className='btn btn-gray mb-0'
                disabled={this.state.is_loading}
              >&lt; go back</SafeLink>
            </div>

            <div className='flex-grow-1 text-center'>
              {this.isEditing() && this.state.show_save_warning && (
                <div className={classnames('callout', 'half-padding', 'warning', {
                  'inline-block': !window.is_mobile,
                  'no-margin': !window.is_mobile,
                })}>
                  <strong>Don't forget to save!</strong>
                </div>
              )}
              {!this.state.show_save_warning && this.state.show_contact_error && (
                <div className={classnames('callout', 'half-padding', 'alert', {
                  'inline-block': !window.is_mobile,
                  'no-margin': !window.is_mobile,
                })}>
                  <strong>You must add at least one contact.</strong>
                </div>
              )}
            </div>

            <div className='flex-grow-0 text-end'>
              <a
                onClick={() => this.onNext()}
                className='btn btn-primary mb-0'
                disabled={this.state.is_loading ||
                  (this.isEditing() && this.state.show_save_warning) ||
                  (!this.hasContact() && this.state.show_contact_error)
                }
              >next page &gt;</a>
            </div>
          </div>
        </BottomBar>
      </>
    )
  }

}

export default withRouter(connect((state, props) => {
  const { entity, entity_type } = selectPerformanceOrLocationFromPath(state, props.match.path, props.match.params.id)
  return {
    crewable: entity,
    page_type: entity_type,
  }
}, (dispatch) => bindActionCreators({
  getPerformance, getLocation, createCrewMember, updateCrewMember, deleteCrewMember
}, dispatch))(AdvanceCrewMembersPage))
