import {
  DISMISS_INVITE_USER_OVERLAY,
  SET_INVITE_USER_OVERLAY
} from 'actions/ui'

export default (state=null, action) => {
  if (action.type.match(DISMISS_INVITE_USER_OVERLAY)) {
    return null
  }

  if (action.type.match(SET_INVITE_USER_OVERLAY) && action.payload) {
    return { ...action.payload }
  }

  return state
}
