import { basicGet } from 'actions/api'
import { resourceRSAA, jsonRSAA } from 'utils'

export const PERSON_ACTION = 'PERSON'
export function getPerson(id) {
  return resourceRSAA(PERSON_ACTION, `/api/people/${id}`)
}

export function getPersonSharedGigs(id, page) {
  return basicGet(`/api/people/${id}/shared_gigs`, PERSON_ACTION, { page })
}

export function updatePerson(id, data) {
  return resourceRSAA(PERSON_ACTION, `/api/people/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ person: data }),
  })
}

export const USER_INVITE = 'USER_INVITE'
export function invitePerson(id) {
  return resourceRSAA(USER_INVITE, `/api/invite`, {
    method: 'POST',
    body: JSON.stringify({ person: { id } })
  })
}


export function searchPeople(query, page) {
  return basicGet(`/api/people/search`, PERSON_ACTION, { q: query, page: page })
}


export const PERSON_FIND_BY_EMAIL_ACTION = 'PERSON_FIND_BY_EMAIL'
export function findPersonByEmail(email) {
  return jsonRSAA(PERSON_FIND_BY_EMAIL_ACTION, `/api/people/find_by_email`, null, {
    method: 'POST',
    body: JSON.stringify({ email: email }),
  })
}
