import { resourceRSAA } from 'utils'

export const MEMBERSHIP_ACTION = 'MEMBERSHIP'

export function getMembership(id) {
  return resourceRSAA(MEMBERSHIP_ACTION, `/api/memberships/${id}`)
}

export function getMembershipForPersonId(joinable_url, person_id) {
  return resourceRSAA(MEMBERSHIP_ACTION, `/api${joinable_url}/memberships/${person_id}`)
}

export function updateMembership(id, data) {
  return resourceRSAA(MEMBERSHIP_ACTION, `/api/memberships/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ membership: data }),
  })
}

export function createMembership(org_url, data) {
  return resourceRSAA(MEMBERSHIP_ACTION, `/api${org_url}/memberships/`, {
    method: 'POST',
    body: JSON.stringify({ membership: data })
  })
}

export function deleteMembership(id) {
  return resourceRSAA(MEMBERSHIP_ACTION, `/api/memberships/${id}`, { method: 'DELETE' })
}

export function acceptMembership(id) {
  return resourceRSAA(MEMBERSHIP_ACTION, `/api/memberships/${id}/accept`)
}

export function rejectMembership(id) {
  return resourceRSAA(MEMBERSHIP_ACTION, `/api/memberships/${id}/reject`)
}

export function leaveJoinable(url) {
  return resourceRSAA(MEMBERSHIP_ACTION, `/api${url}/leave`, { method: 'DELETE' })
}
