import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createMembership } from 'actions/api/memberships'
import { selectApiErrors } from 'selectors'
import { getPerson } from 'actions/api/people'

import PersonSelect from 'components/people/PersonSelect'
import OtherSelect from 'components/OtherSelect'
import PhoneInput from 'components/people/PhoneInput'
import ShowPhone from 'components/people/ShowPhone'
import ShowEmail from 'components/people/ShowEmail'
import LoadingState from 'components/LoadingState'
import ApiErrors from 'components/ApiErrors'

import MembershipDetailsForm from 'components/memberships/MembershipDetailsForm'

import {
  INVITE_STATUS
}from 'static'

import { iff } from 'utils'

class NewMembershipForm extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      membership: {},
      selected_person: {},
      is_new_person: false,
      is_loading: false,
    }
  }

  componentDidMount() {
    this.getPersonFromId()
  }

  canSave() {
    const membership = this.state.membership || {}
    return !this.state.is_loading && (!_.isNil(membership.person_id) || this.state.is_new_person)
  }

  submit() {
    if (!this.props.parent_url) {
      return null
    }

    this.setLoading(() =>
      this.props.createMembership(this.props.parent_url, this.state.membership).then((response) => {
        this.clearLoading(() => {
          if (!response.error) {
            iff(this.props.onCancel)
          }
        })
      })
    )
  }

  isOwnedPerson() {
    return !this.state.is_new_person &&
      this.state.membership.person_id &&
      this.state.selected_person.is_owned
  }

  selectPerson(id) {
    this.setState({
      is_new_person: false,
      membership: {
        ...this.state.membership,
        person_id: id,
      },
    }, () => this.getPersonFromId())
  }

  newPerson(name) {
    this.setState({
      is_new_person: true,
      membership: {
        ..._.omit(this.state.membership, ['person_id']),
        person: { name: name },
      },
    })
  }

  onCancelNewPerson() {
    this.setState({
      is_new_person: false,
      membership: { ..._.omit(this.state.membership, ['person']) },
    })
  }

  updateMembership(update, callback) {
    this.setState({
      membership: {
        ...this.state.membership,
        ...update,
      }
    }, () => {
      if (callback) { callback() }
    })
  }

  updatePerson(update) {
    const person = this.state.membership.person || {}
    this.updateMembership({
      person: {
        ...person,
        ...update,
      }
    })
  }

  getPersonFromId() {
    if (this.state.membership.person_id) {
      this.setLoading(() => {
        this.props.getPerson(this.state.membership.person_id).then(
          (response) => {
            if (response.error) {
              this.clearLoading()
            } else {
              const person_data = response.payload.raw.person
              this.setState({
                membership: {
                  ...this.state.membership,
                  title: person_data.default_title || this.state.membership.title
                },
                selected_person: person_data,
              }, () => this.clearLoading())
            }
          }
        )
      })
    } else {
      this.setState({
        selected_person: {}
      })
    }
  }

  renderTitleField() {
    return (
      <>
        <label>Title</label>
        <OtherSelect
          name='title'
          options={_.map(this.props.title_options || [], (ct) => (
            { value: ct, label: ct }
          ))}
          value={this.state.membership.title}
          onChange={(v) => this.updateMembership({ title: v })}
        />
      </>
    )
  }

  renderPersonFields() {
    if (this.state.is_new_person) {
      let person = this.state.membership.person || {}
      return (
        <div>
          <div className="mb-3">
            <a
              className="link-primary"
              onClick={() => this.onCancelNewPerson()}>
              &lt; Back to Person Search
            </a>
          </div>

          <div className="mb-3">
            <label>
              Name
              <input
                className="form-control"
                type='text'
                name='name'
                value={person.name || ''}
                onChange={(e) => this.updatePerson({ name: e.target.value })}
              />
            </label>
          </div>

          <div className="mb-3">
            <label>
              Email
              <input
                className="form-control"
                type='text'
                name='email'
                value={person.email || ''}
                onChange={(e) => this.updatePerson({ email: e.target.value })}
              />
            </label>
          </div>

          <div className="mb-3">
            <label>
              Phone
              <PhoneInput
                name='phone'
                value={person.phone || ''}
                onChange={(v) => this.updatePerson({ phone: v })}
              />
            </label>
          </div>
        </div>
      )
    } else {
      const person_id = this.state.membership.person_id || ''
      return (
        <PersonSelect
          person_id={person_id}
          onSelect={(id) => this.selectPerson(id)}
          onNew={(name) => this.newPerson(name)}
        />
      )
    }
  }

  render() {
    const membership = this.state.membership || {}

    return (
      <>
        <div className='row mb-2'>
          <div className='col-12 col-md-6'>
            {this.renderPersonFields()}
          </div>

          <div className='col-12 col-md-6'>
            {this.renderTitleField()}
            <MembershipDetailsForm
              membership={this.state.membership}
              onChange={(v) => this.updateMembership(v)}
              show_apply_to_all={!this.isOwnedPerson()}
              disabled={this.state.is_loading}
            />
          </div>
        </div>

        <ApiErrors errors={this.props.api_errors} />

        <button
          className='btn btn-primary'
          onClick={() => this.submit()}
        >save</button>

        <button
          className='btn btn-gray'
          onClick={() => iff(this.props.onCancel)}
          disabled={this.state.is_loading}
        >cancel</button>
      </>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
}), (dispatch) => bindActionCreators({
  createMembership, getPerson
}, dispatch))(NewMembershipForm)
