import React from 'react'

import ShowPerson from 'components/people/ShowPerson'

import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { selectCurrentUser } from 'selectors'
import { bindActionCreators } from 'redux'
import { getCurrentUser } from 'actions/api/users'

import { parseUrlParams } from 'utils'

import WelcomeOverlay from 'components/tutorial/WelcomeOverlay'

class ProfilePage extends React.Component {

  constructor(props) {
    super(props)

    const location = props.location || {}
    const url_props = parseUrlParams(location.search) || {}
    const url_params = url_props.params || {}

    if (url_params.tutorial == 'true') {
      this.props.history.replace('/user/profile')
    }

    this.state = {
      show_welcome: url_params.tutorial === 'true'
    }
  }

  componentWillMount() {
    this.props.getCurrentUser()
  }

  render() {
    if (!this.props.user) { return null }

    return (
      <>
        <div className='container'>
          <ShowPerson
            person={this.props.user.person}
            disable_email
          />
        </div>

        <WelcomeOverlay
          is_hidden={!this.state.show_welcome}
          dismiss={() => this.setState({ show_welcome: false })}
        />
      </>
    )
  }

}

export default connect((state, props) => ({
  user: selectCurrentUser(state),
}), (dispatch) => bindActionCreators({
  getCurrentUser,
}, dispatch))(withRouter(ProfilePage))
