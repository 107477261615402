import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import DocumentForm from 'components/documents/DocumentForm'
import DocumentSelector from 'components/documents/DocumentSelector'

import { connect } from 'react-redux'
import { getDocument } from 'actions/api/documents'
import { bindActionCreators } from 'redux'

import { iff } from 'utils'

const AttachmentForm = ({
  documentable,
  attachment,
  updateAttachment,
  nameOptions,
  isShowDocumentSettings,
  apiErrors,
  getDocument,
  onUploadSuccess,
  uploadButtonText,
}) => {

  const getCurrentDocFromAttachment = (attachment={}) => {
    return {
      id: attachment.document_id,
      name: attachment.name,
      url: attachment.url,
    }
  }

  const [selectableDocuments, setSelectableDocuments] = useState([])
  const [currentDoc, setCurrentDoc] = useState(getCurrentDocFromAttachment(attachment))
  const [isLoading, setIsLoading] = useState(false)
  const [isNew, setIsNew] = useState(false)

  const getCurrentDoc = () => {
    if (!attachment.document_id) { return null }
    if (attachment.document_id === currentDoc.id) { return null }

    setIsLoading(true)
    getDocument(attachment.document_id).then((response) => {
      setIsLoading(false)
      if (!response.error) {
        setIsLoading(false)
        setCurrentDoc(response.payload.raw.document)
      }
    })
  }

  const onNew = (name) => {
    updateAttachment({
      ..._.omit(attachment, ['document_id']),
      document: {
        ...attachment.document,
        name,
      },
    })
    setIsNew(true)
  }

  const cancelNew = () => {
    updateAttachment({
      ..._.omit(attachment, ['document']),
      document_id: this.state.current_doc.id,
    })
    setIsNew(false)
  }

  const onGetOptions = (opts) => {
    setSelectableDocuments([...opts])
    if (_.isEmpty(opts)) {
      onNew()
    }
  }

  useEffect(() => {
    getCurrentDoc()
  }, [attachment.document_id])

  if (isNew) {
    return (
      <>
        <DocumentForm
          attachment={attachment}
          updateAttachment={updateAttachment}
          nameOptions={nameOptions}
          onUploadSuccess={onUploadSuccess}
          uploadButtonText={uploadButtonText}
        >
          {!_.isEmpty(selectableDocuments) && (
            <a onClick={() => cancelNew()} className='link mb-2 d-block'>Select Existing Document</a>
          )}
        </DocumentForm>
      </>
    )
  } else {
    return (
      <>
        <DocumentSelector
          value={attachment.document_id}
          initial_doc={currentDoc}
          parent_url={`/api${documentable.owner_url}`}
          onChange={(docId) => updateAttachment({ document_id: docId })}
          onNew={(name) => onNew(name)}
          onGetOptions={(opts) => onGetOptions(opts)}
          className='mb-2'
        />
        {currentDoc && currentDoc.url && (
          <a href={currentDoc.url} target='_blank' className='d-block mb-2 text-decoration-none'>
            View Current Document
          </a>
        )}
      </>
    )
  }
}

export default connect((state, props) => ({
}), (dispatch) => bindActionCreators({
  getDocument
}, dispatch))(AttachmentForm)
