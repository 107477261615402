import React from 'react'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel, selectCurrentUser } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getLocation, updateLocation, deleteLocation } from 'actions/api/locations'
import { updateUserFlags } from 'actions/api/users'
import { setShareAdvanceOverlay } from 'actions/ui'

import Row from 'components/Row'
import BreadCrumbs from 'components/BreadCrumbs'
import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import LoadingState from 'components/LoadingState'
import InviteHeader from 'components/InviteHeader'

import ShowContacts from 'components/contacts/ShowContacts'
import ShowAddress from 'components/addresses/ShowAddress'
import RenderValue from 'components/RenderValue'
import ShowPhone from 'components/people/ShowPhone'
import PersonnelList from 'components/crew_members/PersonnelList'
import EditableAttachmentsList from 'components/documents/EditableAttachmentsList'
import EditableTextArea from 'components/gigs/editable/EditableTextArea'
import ConfirmationButton from 'components/ConfirmationButton'

import {
  PERSPECTIVE_TYPE,
  VENUE_DOCUMENT_NAMES,
  INVITE_STATUS,
  CREWABLE_TYPE
} from 'static'

import {
  isViewable,
  isEditableByPerspective
} from 'utils'

class LocationPage extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      is_loaded: false,
      hide_advance_form_banner: _.get(props, 'current_user.flags.hide_advance_form_banner', false),
    }
  }

  componentWillMount() {
    this.setLoading(() => this.props.getLocation(this.props.match.params.id).then((res) =>
      this.setState({ is_loading: false, is_loaded: true })))
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setState(
        { is_loading: true, is_loaded: false },
        () => props.getLocation(props.match.params.id).then((res) =>
          this.setState({ is_loading: false, is_loaded: true })
        )
      )
    }

    if (_.get(props, 'current_user.id') !== _.get(this.props, 'current_user.id')) {
      this.setState({
        hide_advance_form_banner: _.get(props, 'current_user.flags.hide_advance_form_banner', false)
      })
    }
  }

  updateLocation(location_data) {
    this.setLoading(() =>
      this.props.updateLocation(this.props.match.params.id, location_data).then((response) =>
        this.clearLoading()))
  }

  onDelete() {
    const location = this.props.location_model || {}
    const gig = location.gig || {}
    const perspective = gig.perspective || {}
    this.setLoading(() =>
      this.props.deleteLocation(this.props.match.params.id).then((response) => {
        if (response.error) {
          this.clearLoading()
        } else {
          if (perspective.type === PERSPECTIVE_TYPE.VENUE && perspective.id === location.venue_id) {
            this.props.history.push('/')
          } else {
            this.props.history.push(gig.url)
          }
        }
      }))
  }

  showShareAdvanceOverlay() {
    const location = this.props.location_model || {}
    this.props.setShareAdvanceOverlay(CREWABLE_TYPE.LOCATION, location.id)
  }

  dismissAdvanceFormBanner() {
    this.setState({ hide_advance_form_banner: true }, () => {
      this.props.updateUserFlags({ hide_advance_form_banner: true })
    })
  }

  isVenueOwner() {
    const location = this.props.location_model || {}
    const gig = location.gig || {}
    const perspective = gig.perspective || {}
    return perspective.type === PERSPECTIVE_TYPE.VENUE &&
        perspective.id === location.venue_id
  }

  canViewMasterGuestList() {
    const location = this.props.location_model || {}
    const gig = location.gig || {}
    const perspective = gig.perspective || {}
    return isViewable(perspective.guest_list_permissions) && perspective.is_gig_owner
  }

  canEditLocation() {
    const location = this.props.location_model || {}
    const gig = location.gig || {}
    const perspective = gig.perspective || {}
    return isEditableByPerspective(perspective, location, perspective.gig_permissions)
  }


  render() {
    if (!this.props.location_model) { return null }

    const location = this.props.location_model || {}
    const gig = location.gig || {}
    const venue = location.venue || {}
    const perspective = gig.perspective || {}
    const attachments = location.attachments || []
    const show_advance_form = this.canEditLocation() && !venue.is_owned
    const contacts = location.contacts || []

    return (
      <div className='container'>
        <BreadCrumbs
          items={[
            { name: 'Shows', url: '/' },
            { name: gig.gig_name || gig.default_name, url: gig.url },
            { name: venue.name },
          ]}
        />

        <InviteHeader invite={perspective.pending_invite} />

        <h2>Location Details</h2>

        <div className='border rounded p-0 mb-4'>
          <div className={classnames('p-3', 'bg-light', {
            'red': location.invite_status === INVITE_STATUS.REJECTED,
            'blue': location.invite_status === INVITE_STATUS.PENDING,
          })}>
            <SafeLink to={venue.url} className='text-decoration-none'>
              <div className='d-flex flex-row align-items-center'>
                <div className='flex-grow-0'>
                  <Avatar
                    url={venue.avatar_url}
                    classes='medium-avatar'
                    small_classes='smaller-avatar'
                    responsive_size
                    blank_icon='Venue'
                  />
                </div>

                <div className='flex-grow-1'>
                  <h4 className='m-0'>
                    {venue.name}&nbsp;
                    {venue.username &&
                      <span className='smaller-text'>
                        (@{venue.username})
                      </span>
                    }
                  </h4>
                </div>
              </div>
            </SafeLink>
          </div>

          {location.invite_status === INVITE_STATUS.REJECTED && (
            <div className='p-3 text-center border-top'>
              <div>
                <strong> Location Invite Rejected </strong>
              </div>

              <a
                className='text-danger'
                onClick={() => this.onDelete()}
                disabled={this.state.is_loading}
              >Click here to remove venue</a>
            </div>
          )}

          {location.invite_status !== INVITE_STATUS.REJECTED && (
            <div className='p-3 border-top'>
              {show_advance_form && !this.state.hide_advance_form_banner && (
                <div className='alert alert-warning clearfix'>
                  <div className='float-end'>
                    <a onClick={() => this.dismissAdvanceFormBanner()}>
                      <button type="button" className="btn-close text-dark" aria-label="Close"></button>
                    </a>
                  </div>

                  <h4>Send an Advance Form</h4>
                  <p>
                    Since this venue does not use Backline, you can send them
                    an advance form!  An advance form is an authorized link that
                    allows anyone with the link to directly enter/edit information for this venue.
                  </p>

                  <p className='mb-0'>
                    To get started, click the "Send Advance Form" button below.
                  </p>
                </div>
              )}

              {location.invite_status === INVITE_STATUS.PENDING && (
                <div className='mb-2'>
                  <strong>
                    (pending)
                  </strong>
                </div>
              )}

              {(this.canViewMasterGuestList() || show_advance_form) && (
                <div className='mb-1'>
                  {this.canViewMasterGuestList() && (
                    <>
                      <SafeLink className='btn btn-gray me-1 mb-2' to={`${gig.url}/guest_list/master`}>
                        View Master Guest List
                      </SafeLink>
                    </>
                  )}

                  {show_advance_form && (
                    <a
                      className='btn btn-primary mb-2'
                      onClick={() => this.showShareAdvanceOverlay()}
                    >Send Advance Form</a>
                  )}
                </div>
              )}

              <div className='row'>
                <div className='col-12 col-md-6'>

                  <h5>
                    Venue Details
                    {this.canEditLocation() && venue.is_editable && (
                      <>
                        &nbsp;
                        <SafeLink to={`${location.url}/venue_update`}>
                          <i className='edit-icon fi-pencil'></i>
                        </SafeLink>
                      </>
                    )}
                  </h5>

                  <div className='rounded p-3 border bg-light mb-3'>
                    <a
                      target="_blank"
                      href={venue.address && venue.address.maps_url}
                      className='d-block text-decoration-none'
                    >
                      <ShowAddress
                        address={venue.address}
                      />
                    </a>
                    {venue.main_phone && (
                      <div> <strong>Main Phone</strong>: <ShowPhone number={venue.main_phone}/> </div>
                    )}
                    {venue.box_office_phone && (
                      <div> <strong>Box Office Phone</strong>: <ShowPhone number={venue.box_office_phone}/> </div>
                    )}
                    <RenderValue value={venue.capacity} label='Capacity' />
                    <RenderValue value={venue.wifi} label='Wifi' />
                    <RenderValue value={venue.wifi_password} label='Wifi Password' />
                    <RenderValue class_name='prewrap' value={venue.parking} label='Parking' />
                    <RenderValue class_name='prewrap' value={venue.load_in_info} label='Load-In Info' />
                    <RenderValue class_name='prewrap' value={venue.green_room_info} label='Green Room Info' />

                    {venue.has_laundry ? (
                      <div><strong>Laundry Available</strong></div>
                    ) : (
                      <div><strong>Laundry Not Available</strong></div>
                    )}
                    {venue.has_showers ? (
                      <div><strong>Showers Available</strong></div>
                    ) : (
                      <div><strong>Showers Not Available</strong></div>
                    )}
                  </div>

                  <h5>
                    Contacts
                    {this.canEditLocation() && !_.isEmpty(contacts) && (
                      <SafeLink to={`${location.url}/crew_members`}>
                        <i className='ms-2 edit-icon fi-pencil'></i>
                      </SafeLink>
                    )}
                  </h5>

                  {_.isEmpty(contacts) ? (
                    <SafeLink
                      to={`${location.url}/crew_members`}
                      className='btn rounded bg-light border hover-bg-gray text-center d-block p-3 mb-3 text-decoration-none text-dark'
                    >
                      <h5 className='m-0'>
                        <i className='fi-plus'></i> Add Contacts
                      </h5>
                    </SafeLink>
                  ) : (
                    <div className='mb-3'>
                      <ShowContacts
                        contacts={contacts}
                        show_avatar
                        show_invite
                        hide_title
                      />
                    </div>
                  )}

                  <EditableTextArea
                    update={(update) => this.updateLocation(update)}
                    value={location.merch_info}
                    post_key='merch_info'
                    title='Merch Info'
                    placeholder='Add your merch details here...'
                    is_editable={this.canEditLocation()}
                  />

                  <EditableTextArea
                    update={(update) => this.updateLocation(update)}
                    value={location.hospitality_details}
                    post_key='hospitality_details'
                    title='Hospitality Details'
                    placeholder='Add your hospitality details here...'
                    is_editable={this.canEditLocation()}
                  />
                </div>

                <div className='col-12 col-md-6'>
                  <div className='mb-3'>
                    <EditableAttachmentsList
                      documentable={location}
                      name_options={VENUE_DOCUMENT_NAMES}
                      is_editable={this.canEditLocation()}
                      is_loaded={this.state.is_loaded}
                    />
                  </div>

                  <div className='mb-3'>
                    <PersonnelList
                      is_editable={this.canEditLocation()}
                      crewable={location}
                    />
                  </div>

                  <div className='mb-3'>
                    <EditableTextArea
                      update={(update) => this.updateLocation(update)}
                      value={location.show_notes}
                      post_key='show_notes'
                      title='Additional Show Notes'
                      placeholder='Add your show notes details here...'
                      is_editable={this.canEditLocation()}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {this.canEditLocation() && location.invite_status !== INVITE_STATUS.REJECTED && (
          <div className='row mb-3'>
            <div className='col-12 col-lg-6'>
              <div className='border rounded p-3 bg-light'>
                <p>
                  <strong>Removing the venue will delete all existing location information (personnel list, attachments, etc).</strong>
                  {this.isVenueOwner() && (
                    <span>&nbsp;This may also cause you to lose access to this gig advance.</span>
                  )}
                </p>
                <ConfirmationButton
                  button_classes='m-0'
                  button_type='danger'
                  button_text='remove venue'
                  confirm_text='really remove?'
                  onConfirm={() => this.onDelete()}
                  disabled={this.state.is_loading}
                />
              </div>
            </div>
          </div>
        )}

        {this.renderLoadingPopup()}
      </div>
    )
  }

}

export default connect((state, ownProps) => ({
  current_user: selectCurrentUser(state),
  location_model: selectModel('locations', ownProps.match.params.id, Resources.location, state),
}), (dispatch) => bindActionCreators({
  getLocation,
  deleteLocation,
  updateLocation,
  setShareAdvanceOverlay,
  updateUserFlags
}, dispatch))(withRouter(LocationPage))
