import React from 'react'

import LandingMobile from 'components/marketing/LandingMobile'
import LandingDesktop from 'components/marketing/LandingDesktop'

export default class LandingPage extends React.Component {

  render() {
    if (window.is_mobile) {
      return ( <LandingMobile />)
    } else {
      return ( <LandingDesktop /> )
    }
  }

}

