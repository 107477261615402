import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import MembershipEdit from 'components/memberships/MembershipEdit'
import NewMembershipForm from 'components/memberships/NewMembershipForm'

export default class ManageMembers extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show_new_form: false,
    }
  }

  render() {
    const memberships = this.props.memberships || []
    const active_memberships = _.filter(memberships, (m) => !m['_destroyed'])
    const sorted_memberships = _.sortBy(active_memberships, (m) => m.is_admin ? 0 : m.is_primary ? 1 : 2)
    return (
      <div className='mb-3'>
        <div className='list-group list-group-striped mb-2'>
          {_.map(sorted_memberships, (m, i) => (
            <MembershipEdit
              key={`membership-${m.id}-${i}`}
              title_options={this.props.title_options}
              membership={m}
            />
          ))}
        </div>
        {this.state.show_new_form ? (
          <div className='border rounded p-3'>
            <h5>Add New Member</h5>
            <NewMembershipForm
              parent_url={this.props.parent_url}
              title_options={this.props.title_options}
              onCancel={() => this.setState({ show_new_form: false })}
            />
          </div>
        ) : (
          <button
            className='btn btn-primary'
            onClick={() => this.setState({ show_new_form: true })}
          >Add Member</button>
        )}
      </div>
    )
  }

}
