import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel, selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getBand, updateBand } from 'actions/api/bands'

import LoadingState from 'components/LoadingState'
import SaveBar from 'components/SaveBar'
import DirectFileUploader from 'components/DirectFileUploader'
import ErrorLabel from 'components/ErrorLabel'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'

import { MAX_AVATAR_SIZE, MAX_NUM_DAY_OF_SHOW } from 'static'

import { iff } from 'utils'

class BandForm extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      band: props.band || {},
      fileData: null,
      apply_defaults_to_upcoming_gigs: false,
      is_loading: false,
    }
  }

  componentWillMount() {
    this.setLoading(() => this.props.getBand(this.props.match.params.id)
      .then((res) => {
        if (res.error) {
          this.clearLoading()
        } else {
          this.setState({ band: res.payload.raw.band }, () => this.clearLoading())
        }
      })
    )
  }

  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      props.getBand(props.match.params.id)
    }

    if (_.isEmpty(this.state.band)) {
      this.setState({ band: props.band || {} })
    }
  }

  updateBand(update, callback) {
    this.setState({
      band: {
        ...this.state.band,
        ...update,
      }
    }, () => iff(callback))
  }

  updateNumDayOfShow(num) {
    let update_value = num
    if (num) {
      update_value = Math.round(Math.max(Math.min(num, MAX_NUM_DAY_OF_SHOW), 0), 0)
    }
    this.updateBand({ default_num_day_of_show: update_value })
  }

  hasDefaultChanges() {
    const defaultFields = [
      'default_transportation',
      'default_production_description',
      'default_show_notes',
      'default_num_day_of_show',
      'default_house_sound',
      'default_house_lights',
    ]

    return _.reduce(defaultFields, (memo, defaultField) => {
      const fieldString = `band.${defaultField}`
      return memo || (_.get(this.state, fieldString) !== _.get(this.props, fieldString))
    }, false)
  }

  save() {
    const data = {
      band: { ...this.state.band },
      apply_defaults_to_upcoming_gigs: this.hasDefaultChanges() && this.state.apply_defaults_to_upcoming_gigs,
    }
    this.setLoading(() => {
      this.props.updateBand(this.props.match.params.id, data).then(
        (response) => this.clearLoading()
      )
    })
  }

  submit() {
    const data = {
      band: { ...this.state.band },
      apply_defaults_to_upcoming_gigs: this.hasDefaultChanges() && this.state.apply_defaults_to_upcoming_gigs,
    }
    this.setLoading(() => {
      this.props.updateBand(this.props.match.params.id, data).then(
        (response) => {
          this.clearLoading()
          if (!response.error) {
            this.props.history.push(this.props.band.url)
          }
        }
      )
    })
  }

  render() {
    const band = this.state.band || {}

    return (
      <>
        <div className='container mt-2'>
          <h2>Edit Band - {band.name}</h2>

          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                <FormField
                  type='text'
                  name='name'
                  label='Band Name'
                  value={band.name || ''}
                  errors={this.props.api_errors}
                  onChange={(e) => this.updateBand({ name: e.target.value })}
                />
              </div>

              {this.props.band.is_owned && (
                <div className='mb-3'>
                  <FormField
                    type='text'
                    name='username'
                    label='Band Username'
                    value={band.username || ''}
                    errors={this.props.api_errors}
                    onChange={(e) => this.updateBand({ username: e.target.value })}
                  />
                </div>
              )}

              {band.is_owned && (
                <>
                  <h5>Profile Picture</h5>
                  {this.props.band.avatar_url && (
                    <div className='mb-2'>
                      <img
                        src={this.props.band.avatar_url}
                        className='avatar'
                      />
                    </div>
                  )}
                  <DirectFileUploader
                    onUploadSuccess={(signedIds) => this.updateBand({
                      avatar_image: _.first(signedIds)
                    }, () => this.save())}
                    setFileData={(fd) => this.setState({ fileData: fd })}
                    fileData={this.state.fileData}
                    maxFileSize={MAX_AVATAR_SIZE}
                  />

                  <div className='mb-3'>
                    <FormField
                      type='textarea'
                      name='bio'
                      label='Description'
                      value={band.bio || ''}
                      errors={this.props.api_errors}
                      onChange={(e) => this.updateBand({ bio: e.target.value })}
                    />
                  </div>
                </>
              )}
            </div>

            <div className='col-12 col-lg-6'>
              <label className='form-label' htmlFor='website'>Website</label>
              <div className='input-group'>
                <span className="input-group-text">https://</span>
                <input
                  type='text'
                  name='website'
                  className='form-control'
                  value={band.website || ''}
                  onChange={(e) => this.updateBand({ website: e.target.value })}
                />
              </div>

              {band.is_owned && (
                <>
                  {this.hasDefaultChanges() && (
                    <div className='alert alert-primary'>
                      <FormCheck
                        name='apply_to_all'
                        label="Apply default changes to all upcoming show advances?"
                        checked={this.state.apply_defaults_to_upcoming_gigs}
                        onChange={(e) => this.setState({ apply_defaults_to_upcoming_gigs: e.target.checked })}
                      />
                      If so, it will overwrite your existing advance information for the following fields
                      <ul>
                        <li>Using House Sound Engineer</li>
                        <li>Using House Lighting Engineer</li>
                        <li>Members Present Day of Show</li>
                        <li>Production Description</li>
                        <li>Additional Show Notes</li>
                        <li>Transportation Description</li>
                      </ul>
                    </div>
                  )}
                  <div className='border rounded p-3 my-3'>
                    <h3>Default Production Details</h3>

                    <div className='mb-3'>
                      <FormCheck
                        name='default_house_sound'
                        label='Using House Sound Engineer'
                        checked={band.default_house_sound || false}
                        onChange={(e) => this.updateBand({ default_house_sound: e.target.checked })}
                      />

                      <FormCheck
                        label='Using House Lighting Engineer'
                        name='default_house_lights'
                        checked={band.default_house_lights || false}
                        onChange={(e) => this.updateBand({ default_house_lights: e.target.checked })}
                      />
                    </div>

                    <div className='mb-3'>
                      <FormField
                        type='number'
                        label='Members Present Day of Show'
                        placeholder='0'
                        name='default_num_day_of_show'
                        value={band.default_num_day_of_show || ''}
                        onChange={(e) => this.updateNumDayOfShow(e.target.value)}
                      />
                    </div>

                    <div className='mb-3'>
                      <FormField
                        type='textarea'
                        label='Production Description'
                        name='default_production_description'
                        value={band.default_production_description || ''}
                        onChange={(e) => this.updateBand({ default_production_description: e.target.value })}
                      />
                    </div>

                    <div className='mb-3'>
                      <FormField
                        type='textarea'
                        label='Additional Show Notes'
                        name='default_show_notes'
                        value={band.default_show_notes || ''}
                        onChange={(e) => this.updateBand({ default_show_notes: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className='mb-3'>
                    <FormField
                      type='textarea'
                      label='Default Transportation Description'
                      name='default_transportation'
                      value={band.default_transportation || ''}
                      onChange={(e) => this.updateBand({ default_transportation: e.target.value })}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
        />

      </>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
  band: selectModel('bands', props.match.params.id, Resources.band, state),
}), (dispatch) => bindActionCreators({
  getBand, updateBand
}, dispatch))(BandForm)
