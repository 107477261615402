import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import moment from 'moment'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectPerformanceOrLocationFromPath } from 'selectors'
import { bindActionCreators } from 'redux'
import { getLocation } from 'actions/api/locations'
import { getPerformance } from 'actions/api/performances'
import { createAttachment, updateAttachment, deleteAttachment } from 'actions/api/attachments'

import Row from 'components/Row'
import PageTitle from 'components/PageTitle'
import SafeLink from 'components/SafeLink'
import LocationAdvanceProgress from 'components/locations/LocationAdvanceProgress'
import PerformanceAdvanceProgress from 'components/performances/PerformanceAdvanceProgress'

import NewAttachmentForm from 'components/documents/NewAttachmentForm'
import EditableAttachment from 'components/documents/EditableAttachment'
import BottomBar from 'components/BottomBar'
import EditableList from 'components/EditableList'

import { iff, isSuccessStatus, isEditableByPerspective } from 'utils'

import {
  VENUE_DOCUMENT_NAMES,
  BAND_DOCUMENT_NAMES,
  CREWABLE_TYPE
} from 'static'

class AdvanceDocumentsPage extends EditableList {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      show_new_form: false,
      new_attachment: {},
    }
  }

  // Override
  getIdFromProps(props) { return _.get(props, 'match.params.id') }

  // Override
  getDataFromListItem(li) { return { ...li } }

  // Override
  getListItemFromResponse(response) { return _.get(response, 'payload.raw.attachment', {}) }

  // Override
  getListFromResponse(response) {
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return _.get(response, 'payload.raw.performance.attachments', [])
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      return _.get(response, 'payload.raw.location.attachments', [])
    }
    throw "AdvanceDocumentsPage has an invalid page_type"
  }

  // Override
  isEditable() {
    const perf_or_loc = _.get(this.props, 'documentable', {})
    const perspective = _.get(perf_or_loc, 'gig.perspective', {})
    return isEditableByPerspective(perspective, perf_or_loc, perspective.gig_permissions)
  }

  /**********************************
   * REQUESTS
   **********************************/
  // Override
  loadListDataRequest(props) {
    if (props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return props.getPerformance(props.match.params.id)
    } else if (props.page_type === CREWABLE_TYPE.LOCATION) {
      return props.getLocation(props.match.params.id)
    }
  }

  // Override
  updateListItemRequest(id, data) {
   return this.props.updateAttachment(id, data)
  }

  // Override
  deleteListItemRequest(id) {
   return this.props.deleteAttachment(id)
  }

  // Override
  createListItemRequest(data) {
    throw "AdvanceDocumentsPage does not use createListItemRequest()"
  }

  // Override
  saveListRequest() {
    throw "AdvanceDocumentsPage does not use saveListRequest()"
  }
  /****************************************/
  /****************************************/

  createRequest() {
    const documentable = this.props.documentable || {}
    const data = this.state.new_attachment || {}
    this.setLoading(() => this.props.createAttachment(documentable.url, data).then((res) => {
      if (res.error) {
        this.clearLoading()
      } else {
        this.setState({
          editable_list: [
            ...this.state.editable_list,
            _.get(res, 'payload.raw.attachment', {}),
          ],
          new_attachment: {},
          show_new_form: false,
          is_loading: false,
        })
      }
    }))
  }

  updateNewAttachment(update, callback) {
    this.setState({
      new_attachment: {
        ...this.state.new_attachment,
        ...update,
      }
    }, () => iff(callback))
  }

  isEditing() {
    const is_editing = this.state.show_new_form ||
      _.reduce(this.state.editable_list, (memo, a) => (memo || a.is_editing), false)

    if (!is_editing && this.state.show_save_warning) {
      this.setState({ show_save_warning: false })
    }

    return is_editing
  }

  onNext() {
    if (this.isEditing()) {
      this.setState({ show_save_warning: true })
    } else {
      const documentable = this.props.documentable || {}
      this.props.history.push(`${documentable.url}/advance/crew_members`)
    }
  }

  getDocumentNames() {
    const page_type = this.props.page_type
    if (page_type === CREWABLE_TYPE.PERFORMANCE) {
      return BAND_DOCUMENT_NAMES
    } else if (page_type === CREWABLE_TYPE.LOCATION) {
      return VENUE_DOCUMENT_NAMES
    }
  }

  getBackText() {
    const page_type = this.props.page_type
    if (page_type === CREWABLE_TYPE.PERFORMANCE) {
      return 'band details'
    } else if (page_type === CREWABLE_TYPE.LOCATION) {
      return 'venue details'
    }
  }

  renderPageText() {
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return (
        <i>
          Upload your relevant band documents on this page.
          We suggest at least adding your Stage Plot, Input List,
          and Hospitality Rider.
        </i>
      )
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      return (
        <i>
          Upload your relevant venue documents on this page.
          We suggest at least adding your Tech Pack, Lighting Plot,
          and Stage Dimensions.
        </i>
      )
    } else {
      return null
    }
  }

  render() {
    if (!this.props.documentable) { return null }

    const documentable = this.props.documentable || {}
    const gig = documentable.gig || {}
    const attachments = this.state.editable_list || []

    return (
      <>
        <div className='container mt-3'>
          {this.props.page_type === CREWABLE_TYPE.PERFORMANCE && (
            <PerformanceAdvanceProgress performance={documentable} pageNum={2} />
          )}
          {this.props.page_type === CREWABLE_TYPE.LOCATION && (
            <LocationAdvanceProgress location={documentable} pageNum={2} />
          )}

          <div className='mb-2'>
            <PageTitle
              subtitle={`${moment(gig.show_date).format('MMMM Do YYYY')} - ${gig.name}`}
            >Documents</PageTitle>
          </div>

          {this.isEditable() ? (
            <div className='alert alert-primary'>
              {this.renderPageText()}
            </div>
          ) : (
            <div className='alert alert-danger'>
              You are currently logged in as a user who does not have permission to edit {documentable.owner_name}&#39;s documents for this show.
            </div>
          )}

          <h5>Documents</h5>

          <div className='list-group list-group-striped mb-2'>
            {_.map(attachments, (a, i) => (
              <EditableAttachment
                key={`documentable-${documentable.id}-attachment-${a.id}`}
                attachment={a}
                documentable={documentable}
                isEditable={this.isEditable()}
                isLoading={this.state.is_loading}
                nameOptions={this.getDocumentNames()}
                onUpdate={this.genUpdateListItem(i)}
                onSave={this.genSaveListItem(i)}
                onDelete={() => this.deleteListItem(i)}
              />
            ))}

            {this.isEditable() && !this.state.show_new_form && _.isEmpty(attachments) && (
              <div className='list-group-item'>
                You have no documents. Click the "Add Document" button below to add a new document.
              </div>
            )}

            {this.isEditable() && this.state.show_new_form && (
              <NewAttachmentForm
                documentable={documentable}
                attachment={this.state.new_attachment}
                nameOptions={this.getDocumentNames()}
                onClose={() => this.setState({ show_new_form: false })}
                onSave={() => this.createRequest()}
                onUpdate={(update, callback) => this.updateNewAttachment(update, callback)}
                isLoading={this.state.is_loading}
              />
            )}
          </div>

          {this.isEditable() && !this.state.show_new_form && (
            <a
              className='btn btn-primary mb-3'
              onClick={() => this.setState({ show_new_form: true })}
            ><i className='fi-plus'></i> Add Document</a>
          )}
        </div>

        <div className='spacer'></div>
        {this.isEditing() && this.state.show_save_warning && window.is_mobile && (<div className='spacer-2'></div>)}

        <BottomBar>
          <div className='container py-2 d-flex flex-row align-items-center'>
            <div className='flex-grow-0'>
              <SafeLink
                to={`${documentable.url}/advance/details`}
                className='btn btn-gray mb-0'
                disabled={this.state.is_loading}
              >&lt; go back</SafeLink>
            </div>

            <div className='flex-grow-1 text-center'>
              {this.isEditing() && this.state.show_save_warning && (
                <div className={classnames('alert alert-danger mb-0 p-2', {
                  'd-inline-block': !window.is_mobile,
                  'mb-0': !window.is_mobile,
                })}>
                  Don't forget to save!
                </div>
              )}
            </div>

            <div className='flex-grow-0 text-end'>
              <a
                onClick={() => this.onNext()}
                className='btn btn-primary mb-0'
                disabled={this.state.is_loading || (this.isEditing() && this.state.show_save_warning)}
              >next page &gt;</a>
            </div>
          </div>
        </BottomBar>
      </>
    )
  }

}

export default withRouter(connect((state, ownProps) => {
  const { entity, entity_type } = selectPerformanceOrLocationFromPath(state, ownProps.match.path, ownProps.match.params.id)
  return {
    documentable: entity,
    page_type: entity_type
  }
}, (dispatch) => bindActionCreators({
  getLocation, getPerformance, createAttachment, updateAttachment, deleteAttachment
}, dispatch))(AdvanceDocumentsPage))
