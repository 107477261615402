import { denormalize } from 'normalizr'
import { Resources } from 'schema'
import { AUTH_TYPE } from 'static/Auth'
import _ from 'lodash'

import { CREWABLE_TYPE } from 'static'
export const DELETE_KEY = '_deleted'

export function selectApiErrors(state={}) {
  const api = state.api || {}
  return api.errors || {}
}

export function selectCurrentUser(state={}) {
  if (_.get(state, 'auth_context.auth_type', null) === AUTH_TYPE.USER) {
    return {
      auth_type: state.auth_context.auth_type,
      ...selectModel('users', state.auth_context.id, Resources.user, state),
    }
  } else {
    return null
  }
}

export function selectPerformanceOrLocationFromPath(state, path, id) {
  let entity_type = null
  if (path.match(/\/performances\/:id/)) {
    entity_type = CREWABLE_TYPE.PERFORMANCE
  } else if (path.match(/\/locations\/:id/)) {
    entity_type = CREWABLE_TYPE.LOCATION
  }
  return selectPerformanceOrLocation(state, entity_type, id)
}

export function selectPerformanceOrLocation(state, type, id) {
  let entity = null
  if (type === CREWABLE_TYPE.PERFORMANCE) {
    entity = selectModel('performances', id, Resources.performance, state)
  } else if (type === CREWABLE_TYPE.LOCATION) {
    entity = selectModel('locations', id, Resources.location, state)
  }

  return {
    entity,
    entity_type: type,
  }
}

export function selectAuthContext(state={}) {
  const auth_type = _.get(state, 'auth_context.auth_type', null)
  if (!_.isNil(selectCurrentUser(state))) {
    return selectCurrentUser(state)
  } else if (auth_type === AUTH_TYPE.PERFORMANCE) {
    return {
      auth_type: state.auth_context.auth_type,
      ...selectModel('performances', state.auth_context.id, Resources.performance, state),
    }
  } else if (auth_type === AUTH_TYPE.LOCATION) {
    return {
      auth_type: state.auth_context.auth_type,
      ...selectModel('locations', state.auth_context.id, Resources.location, state),
    }
  } else if (auth_type === AUTH_TYPE.TICKET_INPUTTER) {
    return {
      auth_type: state.auth_context.auth_type,
      ...selectModel('ticket_inputters', state.auth_context.id, Resources.ticket_inputter, state),
    }
  } else if (auth_type === AUTH_TYPE.PERSON) {
    return {
      auth_type: state.auth_context.auth_type,
      ...selectModel('people', state.auth_context.id, Resources.person, state),
    }
  } else {
    return null
  }
}

export function selectApiList(list_name, schema, page, state={}) {
  const api = state.api || {}
  const resources = api.resources || {}
  const list = api[list_name] || {}
  const formatted_list = denormalize(list.pages[page], schema, resources)
  return {
    items: formatted_list,
    num_pages: list.num_pages,
    page
  }
}

export function selectApiIndex(index_name, schema, state={}) {
  const api = state.api || {}
  const resources = api.resources || {}
  const index = resources[index_name] || {}
  const formatted_index = denormalize(index, schema, resources)
  return { index: formatted_index }
}

export function selectModel(modelName, id, schema, state={}) {
  const api = state.api || {}
  const resources = api.resources || {}
  const entities = resources[modelName] || {}
  const selected = entities[id] || {}
  return removeDeleteModels(denormalize(selected, schema, resources))
}

function removeDeleteModels(obj) {
  if (!obj) { return null }
  if (_.isArray(obj) || !_.isObject(obj)) { return obj }
  if (obj[DELETE_KEY]) { return null }

  const ret_obj = {}
  _.each(_.keys(obj), (key) => {
    const value = obj[key]

    // Array, remove deleted children
    if (_.isArray(value)) {
      ret_obj[key] = []
      _.each(value, (child) => {
        if (!child[DELETE_KEY]) {
          ret_obj[key].push(removeDeleteModels(child))
        }
      })

    // Object, remove if deleted
    } else if (_.isObject(value)) {
      if (value[DELETE_KEY]) {
        ret_obj[key] = null
      } else {
        ret_obj[key] = value
      }

    // Leave other values
    } else {
      ret_obj[key] = value
    }
  })

  return ret_obj
}
