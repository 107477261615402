import { resourceRSAA } from 'utils'

export const ADMIN_BAND_ACTION = 'ADMIN//BAND'
export function adminGetBands(page) {
  return resourceRSAA(ADMIN_BAND_ACTION, `/api/admin/bands?page=${page}`)
}

export function adminGetBand(id) {
  return resourceRSAA(ADMIN_BAND_ACTION, `/api/admin/bands/${id}`)
}

export function adminDeleteBand(id) {
  return resourceRSAA(ADMIN_BAND_ACTION, `/api/admin/bands/${id}`, { method: 'DELETE' })
}

export function adminGetBandUpcomingGigs(id, page) {
  return resourceRSAA(ADMIN_BAND_ACTION, `/api/admin/bands/${id}/upcoming_gigs?page=${page}`)
}

export function adminGetBandPreviousGigs(id, page) {
  return resourceRSAA(ADMIN_BAND_ACTION, `/api/admin/bands/${id}/previous_gigs?page=${page}`)
}

export function adminUpdateBand(id, data) {
  return resourceRSAA(ADMIN_BAND_ACTION, `/api/admin/bands/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ band: data }),
  })
}
