import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createBand } from 'actions/api/bands'

import SaveBar from 'components/SaveBar'
import FormField from 'components/FormField'
import LoadingState from 'components/LoadingState'

class BandWelcomePage extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      band: props.band || {},
    }
  }

  updateBand(update) {
    this.setState({
      band: {
        ...this.state.band,
        ...update,
      }
    })
  }

  submit() {
    this.setLoading(() => {
      this.props.createBand(this.state.band).then((response) => {
        this.clearLoading(() => {
          if (!response.error) {
            this.props.history.push(`/welcome/bands/${response.payload.result}/details`)
          }
        })
      })
    })
  }

  render() {
    return (
      <>
        <div className='container mt-3'>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-6'>
              <h2>Create a Band</h2>

              <div className='mb-3'>
                <FormField
                  name='name'
                  label='Band Name'
                  placeholder='Stevie Wonder'
                  type='text'
                  errors={this.props.api_errors}
                  value={this.state.band.name || ''}
                  onChange={(e) => this.updateBand({ name: e.target.value })}
                />
              </div>

              <div className='mb-3'>
                <FormField
                  name='username'
                  label='Band Username'
                  placeholder='steviewonder'
                  type='text'
                  errors={this.props.api_errors}
                  value={this.state.band.username || ''}
                  onChange={(e) => this.updateBand({ username: e.target.value })}
                />
                <div className='form-text'>
                  Usernames must contain only of alphanumeric characters (A-Z/0-9), dashses, and underscores.
                </div>
              </div>

              <div className='alert alert-primary rounded d-flex flex-row align-items-center'>
                <div className='flex-grow-0'>
                  <i className='fi-info me-3 icon-medium'></i>
                </div>
                <div className='flex-grow-1'>
                  Your band username is a unique indentifier that venues and other bands can use to invite you
                  to collaborate on an advance.  Choose wisely!
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
          save_text="continue"
          hide_cancel
        />
      </>
    )
  }
}


export default connect((state, props) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  createBand
}, dispatch))(BandWelcomePage)
