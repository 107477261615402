import React from 'react'
import _ from 'lodash'

import GigListItem from 'components/gigs/GigListItem'

export default class GigsList extends React.Component {

  render() {
    const gigs = this.props.gigs || []
    let sorted_gigs = _.sortBy(this.props.gigs, ['show_date'])

    if (this.props.sort_desc) {
      sorted_gigs = _.reverse(sorted_gigs)
    }

    let colspan = 3
    if (this.props.show_avatars) { colspan += 1 }
    if (this.props.show_edit) { colspan += 1 }

    if (window.is_mobile) {
      return (
        <div>
          {_.map(sorted_gigs, (g) => (
            <GigListItem
              key={g.id}
              gig={g}
              show_avatars={this.props.show_avatars}
              show_edit={this.props.show_edit}
              is_mobile
            />
          ))}
          {this.props.children}
        </div>
      )
    } else {
      return _.map(sorted_gigs, (g) => (
        <GigListItem
          key={g.id}
          gig={g}
          show_avatars={this.props.show_avatars}
        />
      ))
    }
  }

}
