import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel, selectCurrentUser } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getLocation } from 'actions/api/locations'
import { getGig } from 'actions/api/gigs'

import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import LoadingState from 'components/LoadingState'

import PerformanceAdvanceSummary from 'components/performances/PerformanceAdvanceSummary'
import LocationAdvanceSummary from 'components/locations/LocationAdvanceSummary'
import LocationAdvanceProgress from 'components/locations/LocationAdvanceProgress'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import {
  PERFORMANCE_TYPES
} from 'static'

class LocationAdvanceComplete extends LoadingState {

  loadEntities(props = this.props) {
    this.setLoading(() => this.props.getLocation(props.match.params.id).then(
      (res) => this.handleResponse(res, () => {
        if (!res.error) {
          this.setLoading(() => this.props.getGig(_.get(res.payload, 'raw.location.gig_id')).then(
            (res) => this.handleResponse(res)
          ))
        }
      })
    ))
  }

  componentWillMount() {
    this.loadEntities()
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.loadEntities(props)
    }
  }

  renderTabs() {
    const location = _.get(this.props, 'location_model', {})
    const performances = _.get(this.props, 'gig.performances', [])

    if (_.isNil(location.id) && _.isEmpty(performances)) {
      return null
    }

    const owned_ps = _.filter(performances, (p) => p.is_owned)
    const unowned_ps = _.filter(performances, (p) => !p.is_owned)
    const owned_headliners = _.filter(owned_ps, (p) => p.performance_type === PERFORMANCE_TYPES.HEADLINE)
    const owned_support = _.filter(owned_ps, (p) => p.performance_type === PERFORMANCE_TYPES.SUPPORT)
    const unowned_headliners = _.filter(unowned_ps, (p) => p.performance_type === PERFORMANCE_TYPES.HEADLINE)
    const unowned_support = _.filter(unowned_ps, (p) => p.performance_type === PERFORMANCE_TYPES.SUPPORT)
    const sorted_ps = owned_headliners.concat(owned_support).concat(unowned_headliners).concat(unowned_support)

    return (
      <div className='mb-3'>
        <Tabs>
          <TabList>
            {_.map(sorted_ps, (p) => (
              <Tab key={`${p.id}-${p.band_name}-tab`}>
                <Avatar
                  url={_.get(p, 'band.avatar_url')}
                  classes='small-avatar'
                  small_classes='smaller-avatar'
                  blank_icon='Band'
                />
                {p.band_name}
              </Tab>
            ))}
            {!_.isNil(location.id) && (
              <Tab>
                <Avatar
                  url={_.get(location, 'venue.avatar_url')}
                  classes='small-avatar'
                  small_classes='smaller-avatar'
                  blank_icon='Venue'
                />
                {location.venue_name}
              </Tab>
            )}
          </TabList>

          {_.map(sorted_ps, (p) => (
            <TabPanel key={`${p.id}-${p.band_name}-tab_panel`}>
              <PerformanceAdvanceSummary performance_id={p.id} />
            </TabPanel>
          ))}

          {!_.isNil(location.id) && (
            <TabPanel> <LocationAdvanceSummary location_id={location.id} /> </TabPanel>
          )}

        </Tabs>
      </div>
    )
  }

  render() {
    if (!this.props.location_model) { return null }

    const location = this.props.location_model || {}
    const gig = location.gig || {}
    const venue = location.venue || {}
    const current_user = this.props.current_user

    return (
      <div className='container mt-3'>
        <LocationAdvanceProgress location={location} pageNum={5} />

        <div className='mb-2'>
          <PageTitle
            subtitle={`${moment(gig.show_date).format('MMMM Do YYYY')} - ${gig.name}`}
          >Venue Advance Summary</PageTitle>
        </div>

        {_.isNil(current_user) ? (
          <div className='alert alert-primary'>
            <p>You've completed the advance form!</p>
            <p>Below you can view advance info from the bands that are using Backline.  You can come back to this form at any time to view and edit this information.</p>
            <p>If you'd like access to these forms plus our many other time-saving features, then don't forget to create an account below!</p>
            {/*
            <ul>
              <li>Make all of this information instantly available to other Backline bands at the click of a button.</li>
              <li>Ensure that your team always has access to the most up to date information.</li>
              <li>Keep track of all of your upcoming shows.</li>
              <li>Manage multiple artists/venues with ease.</li>
              <li>Use our permissions system to make sure that your crew sees only the information they need.</li>
            </ul>
            */}

            <SafeLink className='btn btn-primary' to={`${location.url}/advance/register`}>
              Register for Backline
            </SafeLink>
          </div>
        ) : (
          <div className='alert alert-primary'>
            <p>
              You are logged in as {current_user.person_name}.
            </p>
            <SafeLink to={gig.url}>Click here to view all show information</SafeLink>.
          </div>
        )}


        {this.renderTabs()}
      </div>
    )
  }

}

export default withRouter(connect((state, ownProps) => {
  const location_model = selectModel('locations', ownProps.match.params.id, Resources.location, state)
  let gig = null
  if (!_.isNil(location_model.id)) {
    gig = selectModel('gigs', location_model.gig.id, Resources.gig, state)
  }
  return {
    location_model,
    gig,
    current_user: selectCurrentUser(state),
  }
}, (dispatch) => bindActionCreators({
  getLocation, getGig
}, dispatch))(LocationAdvanceComplete))
