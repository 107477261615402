import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

export default class Selector extends React.Component {

  render() {
    return (
      <select
        name={this.props.name}
        value={this.props.value}
        onChange={(e) => this.props.onChange(e.target.value)}
        className={classnames(this.props.classes)}
        disabled={this.props.disabled}
      >
        {_.map(this.props.options, (opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    )
  }
}
