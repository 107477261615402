import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel, selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { adminGetPerson, adminUpdatePerson } from 'actions/api/admin/people'

import AdminHeader from 'components/admin/AdminHeader'
import ErrorLabel from 'components/ErrorLabel'
import LoadingState from 'components/LoadingState'
import PhoneInput from 'components/people/PhoneInput'
import SaveBar from 'components/SaveBar'
import DirectFileUploader from 'components/DirectFileUploader'

import { MAX_AVATAR_SIZE } from 'static'

import { iff } from 'utils'

class AdminEditPersonPage extends LoadingState {
  constructor(props) {
    super(props)

    this.state = {
      person: props.person || {},
    }
  }

  componentWillMount() {
    this.props.adminGetPerson(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (_.isEmpty(this.state.person)) {
      this.setState({
        person: props.person
      })
    }
  }

  updatePerson(update, callback) {
    this.setState({
      person: {
        ...this.state.person,
        ...update,
      }
    }, () => iff(callback))
  }

  save() {
    this.setLoading(() => {
      this.props.adminUpdatePerson(this.props.match.params.id, this.state.person)
        .then(() => {
          this.clearLoading()
        })
    })
  }

  submit() {
    this.setLoading(() => {
      this.props.adminUpdatePerson(this.props.match.params.id, this.state.person)
        .then(() => {
          this.clearLoading()
          this.props.history.push(`/admin${this.props.person.url}`)
        })
    })
  }

  render() {
    const person = this.state.person || {}
    return (
      <div className='container'>
        <div className="row">
          <AdminHeader />
          <h2>Edit Person - {person.name}</h2>
        </div>

        <div className='row'>
          <div className='col-12 col-md-6 col-lg-4'>
            <label className="d-block">
              <span className="small">Name</span>
              <ErrorLabel field='name' errors={this.props.api_errors} />
              <input
                className="form-control"
                type='text'
                name='name'
                value={person.name || ''}
                onChange={(e) => this.updatePerson({ name: e.target.value })}
              />
            </label>
          </div>

          {person.is_owned && (
            <div className='col-12 col-md-6 col-lg-4'>
              <label className="d-block">
                <span className="small">Username</span>
                <ErrorLabel field='username' errors={this.props.api_errors} />
                <input
                  className="form-control"
                  type='text'
                  name='username'
                  value={person.username || ''}
                  onChange={(e) => this.updatePerson({ username: e.target.value })}
                />
              </label>
            </div>
          )}

          <div className='col-12 col-md-6 col-lg-4'>
            <label className="d-block">
              <span className="small">Phone</span>
              <PhoneInput
                className="form-control"
                name='phone'
                value={person.phone || ''}
                onChange={(v) => this.updatePerson({ phone: v })}
              />
            </label>
          </div>

          <div className='col-12 col-md-6 col-lg-4'>
            <label className="d-block">
              <span className="small">Email</span>
              <input
                className="form-control"
                type='email'
                name='email'
                disabled={this.props.disable_email}
                value={person.email || ''}
                onChange={(e) => this.updatePerson({ email: e.target.value })}
                />
            </label>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <label>
            <input
              name='is_test'
              type='checkbox'
              checked={person.is_test || false}
              onChange={(e) => this.updatePerson({ is_test: e.target.checked })}
              disabled={this.props.disabled}
            />
            <span className="ps-2">IS TEST DATA</span>
          </label>
        </div>

        {person.is_owned && (
          <div className="row">
            <h5>Profile Picture</h5>
            {this.props.person.avatar_url && (
              <img
                src={this.props.person.avatar_url}
                className='avatar'
              />
            )}
            <DirectFileUploader
              onUploadSuccess={(signedIds) => this.updatePerson({
                avatar_image: _.first(signedIds)
              }, () => this.save())}
              setFileData={(fd) => this.setState({ fileData: fd })}
              fileData={this.state.fileData}
              maxFileSize={MAX_AVATAR_SIZE}
            />
          </div>
        )}

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
        />
      </div>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
  person: selectModel('people', props.match.params.id, Resources.person, state)
}), (dispatch) => bindActionCreators({
  adminGetPerson, adminUpdatePerson,
}, dispatch))(AdminEditPersonPage)
