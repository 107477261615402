import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getLocation } from 'actions/api/locations'
import { getPerformance } from 'actions/api/performances'

import {
  selectPerformanceOrLocationFromPath,
  selectModel,
  selectCurrentUser
} from 'selectors'

import Row from 'components/Row'
import LoadingState from 'components/LoadingState'
import PageTitle from 'components/PageTitle'
import SafeLink from 'components/SafeLink'
import LocationAdvanceProgress from 'components/locations/LocationAdvanceProgress'
import PerformanceAdvanceProgress from 'components/performances/PerformanceAdvanceProgress'

import { CREWABLE_TYPE } from 'static'

class AdvanceIntro extends LoadingState {

  getAdvanceable(props) {
    if (props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return props.getPerformance(props.match.params.id)
    } else if (props.page_type === CREWABLE_TYPE.LOCATION) {
      return props.getLocation(props.match.params.id)
    }
  }

  componentWillMount() {
    this.setLoading(() => this.getAdvanceable(this.props).then((res) =>
      this.handleResponse(res)))
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setLoading(() => this.getAdvanceable(props).then((res) =>
        this.handleResponse(res)))
    }
  }

  render() {
    if (!this.props.advanceable) { return null }

    const advanceable = this.props.advanceable || {}
    const gig = advanceable.gig || {}
    const current_user = this.props.current_user

    let advanceLabel = ""
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      advanceLabel = "Band "
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      advanceLabel = "Venue "
    }

    return (
      <div className='container mb-4'>
        {this.props.page_type === CREWABLE_TYPE.PERFORMANCE && (
          <PerformanceAdvanceProgress performance={advanceable} pageNum={0} />
        )}
        {this.props.page_type === CREWABLE_TYPE.LOCATION && (
          <LocationAdvanceProgress location={advanceable} pageNum={0} />
        )}

        <div className='mb-2'>
          <PageTitle
            subtitle={`${moment(gig.show_date).format('MMMM Do YYYY')} - ${gig.name}`}
          >{advanceLabel}Advance Form</PageTitle>
        </div>

        {!_.isNil(current_user) && (
          <div className='alert alert-warning'>
            <p>
              You are currently logged in as {current_user.person_name}.
            </p>
            <p>
              <SafeLink to={gig.url}>Click here</SafeLink> to return to the main advance page for logged in users.
            </p>
            <p className='mb-0'>
              If you would like to see the rest of the advance form anyways, feel free to proceed below.
            </p>
          </div>
        )}

        <div className='border rounded p-3 bg-light mb-3'>
          <p>You've been invited to advance a show with {gig.headliner} on Backline!</p>

          <p>Click the button below to begin.</p>

          <SafeLink className='btn btn-primary' to={`${advanceable.url}/advance/details`}>
            Get Started
          </SafeLink>
        </div>

      </div>
    )
  }

}

export default withRouter(connect((state, ownProps) => {
  const { entity, entity_type } = selectPerformanceOrLocationFromPath(state, ownProps.match.path, ownProps.match.params.id)
  return {
    current_user: selectCurrentUser(state),
    advanceable: entity,
    page_type: entity_type
  }
}, (dispatch) => bindActionCreators({
  getLocation, getPerformance
}, dispatch))(AdvanceIntro))
