import _ from 'lodash'
import React from 'react'

import Overlay from 'components/Overlay'

import { connect } from 'react-redux'
import { selectPerformanceOrLocation } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { sendPerformanceAdvanceLink, generatePerformanceAdvanceLink } from 'actions/api/performances'
import { sendLocationAdvanceLink, generateLocationAdvanceLink } from 'actions/api/locations'
import { dismissShareAdvanceOverlay } from 'actions/ui'

import LoadingState from 'components/LoadingState'
import PhoneInput from 'components/people/PhoneInput'
import ErrorLabel from 'components/ErrorLabel'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'

import { CREWABLE_TYPE } from 'static'

const SUCCESS_DISMISS_DELAY_TIME = 3 * 1000

const initial_state = {
  is_loading: false,
  show_success: false,
  cc_self: false,
  custom_advance_note: '',
  person: {},
}

class ShareAdvanceOverlay extends LoadingState {

  constructor(props) {
    super(props)
    this.state = { ...initial_state }
  }

  resetState() {
    this.setState({ ...initial_state })
  }

  onDismiss() {
    this.props.dismissShareAdvanceOverlay()
    this.resetState()
  }

  sendAuthLinkRequest() {
    const data = {
      cc_self: this.state.cc_self,
      person: this.state.person,
      custom_advance_note: this.state.custom_advance_note,
    }
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return this.props.sendPerformanceAdvanceLink(this.props.advanceable_id, data)
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      return this.props.sendLocationAdvanceLink(this.props.advanceable_id, data)
    } else {
      throw `Error: ShareAdvanceOverlay has page_type=${this.props.page_type}`
    }
  }

  sendAuthLink() {
    if (_.isNil(this.props.advanceable_id)) { return null }
    this.setLoading(() => this.sendAuthLinkRequest().then((response) => this.setState({
      is_loading: false,
      show_success: !response.error,
    })))
  }

  generateAuthLinkRequest() {
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return this.props.generatePerformanceAdvanceLink(this.props.advanceable_id)
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      return this.props.generateLocationAdvanceLink(this.props.advanceable_id)
    } else {
      throw `Error: ShareAdvanceOverlay has page_type=${this.props.page_type}`
    }
  }

  generateAuthLink() {
    if (_.isNil(this.props.advanceable_id)) { return null }
    this.setLoading(() => this.generateAuthLinkRequest().then((response) => this.clearLoading()))
  }

  updatePerson(update) {
    this.setState({
      person: {
        ...this.state.person,
        ...update,
      }
    })
  }

  copyLinkToClipboard(url) {
    this.advance_link_input.select()
    document.execCommand('copy')
  }

  render() {
    const is_hidden = _.isNil(this.props.advanceable_id) || this.props.server_error
    const person = this.state.person || {}
    const advanceable = this.props.advanceable || {}

    return (
      <Overlay is_hidden={is_hidden} onDismiss={() => this.onDismiss()}>
        <h4>
          Send Advance Form
        </h4>
        <p>
          Send an advance form to a member of {advanceable.owner_name} to allow them to input advance information directly into this show on Backline.
        </p>

        <div className='row'>
          <div className='col-12 col-md-6 mb-3'>
            <FormField
              label='Name'
              errors={this.props.api_errors}
              name='name'
              type='text'
              value={person.name || ''}
              onChange={(e) => this.updatePerson({ name: e.target.value })}
            />
          </div>

          <div className='col-12 col-md-6 mb-3'>
            <label className='form-label'>Phone (optional)</label>
            <PhoneInput
              name='phone'
              value={person.phone || ''}
              onChange={(v) => this.updatePerson({ phone: v })}
            />
          </div>

          <div className='col-12 mb-3'>
            <FormField
              label='Email'
              errors={this.props.api_errors}
              name='email'
              type='email'
              value={person.email || ''}
              onChange={(e) => this.updatePerson({ email: e.target.value })}
            />
          </div>
        </div>

        <div className='mb-3'>
          <FormField
            label='Personalized Note (optional)'
            errors={this.props.api_errors}
            type='textarea'
            className='textarea-small'
            name='custom_advance_note'
            value={this.state.custom_advance_note}
            onChange={(e) => this.setState({ custom_advance_note: e.target.value })}
          />
        </div>

        <div className='mb-3'>
          <FormCheck
            name='cc_self'
            checked={this.state.cc_self}
            onChange={(e) => this.setState({ cc_self: e.target.checked })}
          >Send a copy to myself</FormCheck>
        </div>

        <button
          className='btn btn-primary mb-0'
          onClick={() => this.sendAuthLink()}
          disabled={this.state.is_loading}
        >Send Advance Form</button>

        {this.state.show_success &&
          <div className='alert alert-success mt-3'>
            Email invite successfully sent to {person.name} ({person.email}).
          </div>
        }

        {this.props.error_message &&
          <div className='alert alert-danger mt-3'>
            {this.props.error_message}
          </div>
        }

        <hr/>

        {_.isNil(advanceable.advance_url) ? (
          <button
            className='btn btn-gray mb-0'
            onClick={() => this.generateAuthLink()}
            disabled={this.state.is_loading}
          >Generate Advance Link</button>
        ) : (
          <>
            <div className='input-group'>
              <button
                className='btn btn-primary mb-0'
                onClick={() => this.copyLinkToClipboard(advanceable.advance_url)}
              >copy</button>
              <input
                readOnly
                ref={(input) => this.advance_link_input = input}
                className='share-url form-control mb-0'
                type='text'
                value={advanceable.advance_url}
              />
            </div>
          </>
        )}
      </Overlay>
    )
  }
}

export default connect((state, props) => {
  const { entity, entity_type } = selectPerformanceOrLocation(state, state.overlay.advance_overlay.type, state.overlay.advance_overlay.id)
  return {
    page_type: state.overlay.advance_overlay.type,
    advanceable_id: state.overlay.advance_overlay.id,
    advanceable: entity,
    api_errors: state.api.errors,
    server_error: state.api.server_error,
  }
}, (dispatch) => bindActionCreators({
  sendLocationAdvanceLink,
  sendPerformanceAdvanceLink,
  generateLocationAdvanceLink,
  generatePerformanceAdvanceLink,
  dismissShareAdvanceOverlay
}, dispatch))(ShareAdvanceOverlay)
