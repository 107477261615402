import React from 'react'
import _ from 'lodash'
import Linkify from 'react-linkify'

import { Switch, Route } from 'react-router-dom'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { adminGetBand } from 'actions/api/admin/bands'

import Tabs from 'components/Tabs'
import SafeLink from 'components/SafeLink'
import AdminHeader from 'components/admin/AdminHeader'
import OrgMembersPage from 'components/OrgMembersPage'
import EditableDocumentsList from 'components/documents/EditableDocumentsList'
import PageTitle from 'components/PageTitle'
import Avatar from 'components/Avatar'
import BandGigsList from 'components/admin/bands/BandGigsList'

import {
  formatUrl
} from 'utils'

import {
  BAND_DOCUMENT_NAMES,
  PERSPECTIVE_TYPE
} from 'static'

class BandPage extends React.Component {

  componentWillMount() {
    this.props.adminGetBand(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      props.adminGetBand(props.match.params.id)
    }
  }

  renderBandDetails() {
    const band = this.props.band || {}
    const memberships = band.memberships || []

    return (
      <div className='p-2'>
        <div className='row'>
          {(!_.isNil(band.website) || !_.isNil(band.bio)) && (
            <div className='col-12 col-md-6'>
              <h5>Description</h5>
              <div className='card p-3'>
                <div>
                  <a
                    target="_blank"
                    href={formatUrl(band.website)}
                  >{band.website}</a>
                </div>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap m-0'>{_.trim(band.bio)}</p>
                </Linkify>
              </div>
            </div>
          )}

          {(band.default_house_sound ||
            band.default_house_lights ||
            !_.isNil(band.default_num_day_of_show) ||
            !_.isNil(band.default_production_description)) && (
            <div className='col-12 col-md-6'>
              <h5>Default Production</h5>
              <div className='card p-3'>
                {!_.isNil(band.default_num_day_of_show) && (
                  <div>Default Members Present Day of Show: {band.default_num_day_of_show}</div>
                )}
                {band.default_house_sound && (<div><strong>Using House Sound Engineer</strong></div>)}
                {band.default_house_lights && (<div><strong>Using House Lighting Engineer</strong></div>)}
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap m-0'>{_.trim(band.default_production_description)}</p>
                </Linkify>
              </div>
            </div>
          )}

          {!_.isNil(band.default_transportation) && (
            <div className='col-12 col-md-6'>
              <h5>Default Transportation</h5>
              <div className='card p-3'>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap m-0'>{_.trim(band.default_transportation)}</p>
                </Linkify>
              </div>
            </div>
          )}

          {!_.isNil(band.default_show_notes) && (
            <div className='col-12 col-md-6'>
              <h5>Default Additional Show Notes</h5>
              <div className='card p-3'>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap m-0'>{_.trim(band.default_show_notes)}</p>
                </Linkify>
              </div>
            </div>
          )}
        </div>


      </div>
    )
  }

  render() {
    const band = _.get(this, 'props.band', {})
    const memberships = _.get(band, 'memberships', [])
    const attachments = _.get(band, 'attachments', [])

    if (_.isNil(band.id)) { return null }

    return (
      <div className="container">
        <AdminHeader />

        {band.is_test && (
          <div className='test-data'>
            TEST DATA
          </div>
        )}

        <div className='clearfix'>
          <Avatar
            url={band.avatar_url}
            classes='float-left mt-2'
          />
          <PageTitle
            is_editable
            edit_link={`/admin${band.url}/edit`}
            subtitle={band.username &&
              <h5>@{band.username}</h5>
            }
          >
            {band.name}
          </PageTitle>
        </div>

        <div className='mb-3'>
          <SafeLink to={band.url}>View on Backline.me</SafeLink>
        </div>

        <Tabs tabs={[
          {
            name: 'Details',
            url: `/admin${band.url}`,
            show_tab: true,
          }, {
            name: 'Members',
            url: `/admin${band.url}/members`,
            show_tab: true,
          }, {
            name: 'Documents',
            url: `/admin${band.url}/documents`,
            show_tab: true,
          }, {
            name: 'Shows',
            url: `/admin${band.url}/shows`,
            show_tab: true,
          }
        ]} />

        <Switch>
          <Route exact path='/admin/bands/:id/members' render={() => (
            <div className='p-2'>
              <OrgMembersPage page_type={PERSPECTIVE_TYPE.BAND} org={band} />
            </div>
          )} />
          <Route exact path='/admin/bands/:id/documents' render={() => (
            <div className='p-2'>
              <EditableDocumentsList
                attachable={band}
                attachments={attachments}
                name_options={BAND_DOCUMENT_NAMES}
                is_editable
              />
            </div>
          )} />
          <Route exact path='/admin/bands/:id/shows' render={() => (
            <div className='p-2'>
              <BandGigsList band_id={band.id} />
            </div>
          )} />
          <Route render={() => this.renderBandDetails()} />
        </Switch>
      </div>
    )
  }

}

export default connect((state, ownProps) => ({
  band: selectModel('bands', ownProps.match.params.id, Resources.band, state),
}), (dispatch) => bindActionCreators({
  adminGetBand
}, dispatch))(BandPage)
