import React from 'react'
import _ from 'lodash'
import Linkify from 'react-linkify'

import { Switch, Route } from 'react-router-dom'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel, selectCurrentUser } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getBand } from 'actions/api/bands'
import { leaveJoinable } from 'actions/api/memberships'

import Row from 'components/Row'
import Tabs from 'components/Tabs'
import SafeLink from 'components/SafeLink'
import ShowContacts from 'components/contacts/ShowContacts'
import OrgMembersPage from 'components/OrgMembersPage'
import EditableDocumentsList from 'components/documents/EditableDocumentsList'
import ScheduleTemplateList from 'components/schedule_templates/ScheduleTemplateList'
import InviteHeader from 'components/InviteHeader'
import PageTitle from 'components/PageTitle'
import GigsList from 'components/gigs/GigsList'
import Avatar from 'components/Avatar'
import LoadingState from 'components/LoadingState'
import ConfirmationButton from 'components/ConfirmationButton'
import BandSharedGigs from 'components/bands/BandSharedGigs'

import {
  formatUrl
} from 'utils'

import {
  BAND_DOCUMENT_NAMES,
  PERSPECTIVE_TYPE
} from 'static'

class BandPage extends LoadingState {

  componentDidMount() {
    this.setLoading(() => this.props.getBand(this.props.match.params.id)
      .then(() => this.clearLoading()))
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      props.getBand(props.match.params.id)
    }
  }

  onLeave() {
    const band = this.props.band || {}
    this.setLoading(() => this.props.leaveJoinable(band.url)
      .then((res) => this.clearLoading(() => {
        if (!res.error) {
          this.props.history.push('/')
        }
      })))
  }

  renderBandDetails() {
    const band = this.props.band || {}
    const memberships = band.memberships || []

    return (
      <div className='p-2 clearfix'>
        <div className='row'>
          {(!((_.isNil(band.website) || _.isEmpty(band.website)) &&
              (_.isNil(band.bio) || _.isEmpty(band.bio)))) && (
            <div className='col-12 col-md-6 mb-3'>
              <h5>Description</h5>
              <div className='border rounded p-3'>
                <div>
                  <a
                    target="_blank"
                    href={formatUrl(band.website)}
                  >{band.website}</a>
                </div>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap m-0'>{_.trim(band.bio)}</p>
                </Linkify>
              </div>
            </div>
          )}

          {(band.default_house_sound ||
            band.default_house_lights ||
            !_.isNil(band.default_num_day_of_show) ||
            !_.isNil(band.default_production_description)) && (
            <div className='col-12 col-md-6 mb-3'>
              <h5>Default Production</h5>
              <div className='border rounded p-3'>
                {(!_.isNil(band.default_num_day_of_show) || band.default_house_sound || band.default_house_lights) && (
                  <div className='mb-3'>
                    {!_.isNil(band.default_num_day_of_show) && (
                      <div>Default Members Present Day of Show: {band.default_num_day_of_show}</div>
                    )}
                    {band.default_house_sound && (<div><strong>Using House Sound Engineer</strong></div>)}
                    {band.default_house_lights && (<div><strong>Using House Lighting Engineer</strong></div>)}
                  </div>
                )}
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap mb-0'>{_.trim(band.default_production_description)}</p>
                </Linkify>
              </div>
            </div>
          )}

          {!_.isNil(band.default_transportation) && (
            <div className='col-12 col-md-6 mb-3'>
              <h5>Default Transportation</h5>
              <div className='border rounded p-3'>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap m-0'>{_.trim(band.default_transportation)}</p>
                </Linkify>
              </div>
            </div>
          )}

          {!_.isNil(band.default_show_notes) && (
            <div className='col-12 col-md-6'>
              <h5>Default Additional Show Notes</h5>
              <div className='border rounded p-3'>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap m-0'>{_.trim(band.default_show_notes)}</p>
                </Linkify>
              </div>
            </div>
          )}
        </div>


      </div>
    )
  }

  render() {
    const band = _.get(this, 'props.band', {})
    const memberships = _.get(band, 'memberships', [])
    const attachments = _.get(band, 'attachments', [])
    const schedule_templates = _.get(band, 'schedule_templates', [])

    if (_.isNil(band.id)) { return null }

    return (
      <div className='container mt-2'>
        <InviteHeader invite={band.pending_invite} />

        <div className='d-flex flex-row align-items-center mb-3 mt-2'>
          <div className='flex-grow-0'>
            <Avatar url={band.avatar_url} />
          </div>
          <div className='flex-grow-1'>
            <PageTitle
              edit_link={`${band.url}/edit`}
              is_editable={band.is_editable}
              subtitle={band.username &&
                <h5>@{band.username}</h5>
              }
            >
              {band.name}
            </PageTitle>
          </div>
        </div>

        <Tabs tabs={[
          {
            name: 'Details',
            url: band.url,
            show_tab: true
          }, {
            name: 'Members',
            url: `${band.url}/members`,
            show_tab: memberships.length > 0 || band.is_admin,
          }, {
            name: 'Documents',
            url: `${band.url}/documents`,
            show_tab: attachments.length > 0 || band.is_admin,
          }, {
            name: 'Schedule Templates',
            url: `${band.url}/schedule_templates`,
            show_tab: band.is_admin,
          }, {
            name: 'Shared Shows',
            url: `${band.url}/shared_gigs`,
            show_tab: !band.is_member,
          }
        ]} />

        <div className='mt-3 mb-4'>
          <Switch>
            {(memberships.length > 0 || band.is_admin) && (
              <Route exact path='/bands/:id/members' render={() => (
                <>
                  {band.is_admin ? (
                    <OrgMembersPage page_type={PERSPECTIVE_TYPE.BAND} org={band} />
                  ) : (
                    <ShowContacts
                      contacts={memberships}
                      show_admin={band.is_editable}
                      show_primary={band.is_editable}
                      show_public={band.is_editable}
                      show_invite={band.is_editable}
                      show_avatar
                      split_large
                      hide_title
                    />
                  )}
                </>
              )} />
            )}
            {(attachments.length > 0 || band.is_admin) && (
              <Route exact path='/bands/:id/documents' render={() => (
                <EditableDocumentsList
                  attachable={band}
                  attachments={attachments}
                  name_options={BAND_DOCUMENT_NAMES}
                  is_editable={band.is_admin}
                />
              )} />
            )}
            {band.is_admin && (
              <Route exact path='/bands/:id/schedule_templates' render={() => (
                <ScheduleTemplateList
                  band={band}
                  schedule_templates={schedule_templates}
                  is_editable={band.is_admin}
                />
              )} />
            )}
            {!band.is_member && (
              <Route exact path='/bands/:id/shared_gigs' render={() => (
                <BandSharedGigs band_id={band.id} />
              )} />
            )}
            <Route render={() => this.renderBandDetails()} />
          </Switch>
        </div>

        {band.is_member && (
          <ConfirmationButton
            button_classes='m-0'
            button_type='danger'
            button_text='Leave Band'
            confirm_text='Really Leave?'
            onConfirm={() => this.onLeave()}
            disabled={this.state.is_loading}
          />
        )}
      </div>
    )
  }

}

export default connect((state, ownProps) => ({
  current_user: selectCurrentUser(state),
  band: selectModel('bands', ownProps.match.params.id, Resources.band, state),
}), (dispatch) => bindActionCreators({
  getBand, leaveJoinable
}, dispatch))(withRouter(BandPage))
