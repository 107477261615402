import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { acceptMembership } from 'actions/api/memberships'

import RequestRedirectPage from 'components/RequestRedirectPage'
import SafeLink from 'components/SafeLink'
import Row from 'components/Row'

class MembershipAcceptPage extends RequestRedirectPage {

  // Override
  makeRequest() { return this.props.acceptMembership(this.props.match.params.id) }

  // Override
  getSuccessUrl(res) {
    const payload = res.payload || {}
    if (payload) {
      const raw_payload = payload.raw || {}
      const membership = raw_payload.membership || {}
      return membership.joinable_url || '/'
    }
    // This shouldn't happen, failsafe go to homepage
    return '/'
  }

  render() {
    return (
      <Row>
        {this.state.is_loading && (
          <div className='callout warning'>
            <i className='fi-clock'></i> Loading...
          </div>
        )}

        {this.state.is_success && !this.state.is_loading && (
          <div className='callout success'>
            You have successfully accepted your membership invite!  You'll be redirected shortly.  If for some reason you aren't, <SafeLink to='/'>click here</SafeLink> to go to the homepage.
          </div>
        )}

        {!this.state.is_success && !this.state.is_loading && (
          <div className='callout alert'>
            There was an error accepting your membership invite. Please send us an email at <a href='mailto:membershipus.backline@gmail.com'>membershipus.backline@gmail.com</a> if you continue to experience issues.  <SafeLink to='/'>Click here</SafeLink> to go to the homepage.
          </div>
        )}
      </Row>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  acceptMembership
}, dispatch))(MembershipAcceptPage)
