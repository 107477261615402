import React from 'react'
import _ from 'lodash'

import LoadingState from 'components/LoadingState'
import { iff } from 'utils'

export default class EndlessList extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      page: 0,
      num_pages: 0,
      list: [],
    }
  }

  // Override
  onMount() {
    // no-op
  }

  componentWillMount() {
    this.getListData()
    this.onMount()
  }

  resetList(callback) {
    this.setState({
      page: 0,
      num_pages: 0,
      list: [],
    }, () => iff(callback))
  }

  getMoreItems() {
    this.setState({
      page: this.state.page + 1
    }, () => {
      this.getListData()
    })
  }

  getListData() {
    this.setState({
      is_loading: true
    }, () => {
      this.requestListData(this.state.page).then((res) => {
        if (res.error) {
          this.setState({ is_loading: false })
        } else {
          const data = res.payload.raw || res.payload || {}
          this.setState({
            list: this.state.list.concat(this.getListFromData(data)),
            page: data.page,
            num_pages: data.num_pages,
            is_loading: false,
          })
        }
      })
    })
  }

  // Override
  requestListData(page) {
    throw "Please implement requestListData() for EndlessList object"
  }

  // Override
  getListFromData(data) {
    throw "Please implement getListFromData() for EndlessList object"
  }

}
