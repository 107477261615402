import React from 'react'
import _ from 'lodash'

import PaginatedList from 'components/PaginatedList'
import GigListItem from 'components/gigs/GigListItem'

export default class SharedGigs extends PaginatedList {

  //Override
  getListFromData(data) {
    return _.sortBy(data.gigs || [], ['show_date'])
  }

  //Override
  onSubmitRequest(data) {
    if (this.props.history && this.props.location) {
      this.props.history.replace(`${this.props.location.pathname}?page=${this.state.page}`)
    }
  }

  render() {
    return (
      <div>
        <h4>Shared Shows</h4>
        <div className='callout-list half-margin-bottom'>
          {!this.state.is_loading && _.map(this.state.list, (g) => (
            <GigListItem
              key={`shared-gig-${g.id}`}
              gig={g}
              show_avatar
            />
          ))}

          {this.state.is_loading && (
            <div className='item'>
              <div className='text-center half-padding'> <i className='fi-clock'></i>&nbsp;Loading</div>
            </div>
          )}

          {_.isEmpty(this.state.list) && !this.state.is_loading && (
            <div className='item'>
              <div className='text-center half-padding'>
                You have no shared shows.
              </div>
            </div>
          )}
        </div>
        {this.renderPageButtons()}
      </div>
    )
  }

}
