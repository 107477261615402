import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel, selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import {
  adminGetVenue,
  adminUpdateVenue,
  adminDeleteVenue
} from 'actions/api/admin/venues'

import AdminHeader from 'components/admin/AdminHeader'
import LoadingState from 'components/LoadingState'
import AddressForm from 'components/addresses/AddressForm'
import DirectFileUploader from 'components/DirectFileUploader'
import ErrorLabel from 'components/ErrorLabel'

import SaveBar from 'components/SaveBar'
import PhoneInput from 'components/people/PhoneInput'

import { MAX_AVATAR_SIZE, MAX_CAPACITY } from 'static'

import { iff } from 'utils'

class EditVenuePage extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      venue: props.venue || {},
    }
  }

  componentWillMount() {
    this.props.adminGetVenue(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      props.adminGetVenue(props.match.params.id)
    }

    if (_.isEmpty(this.state.venue)) {
      this.setState({ venue: props.venue || {} })
    }
  }

  updateVenue(update, callback) {
    this.setState({
      venue: {
        ...this.state.venue,
        ...update,
      }
    }, () => iff(callback))
  }

  updateCapacity(value) {
    let update_value = value
    if (value) {
      update_value = Math.round(Math.min(Math.max(value, 0), MAX_CAPACITY), 0)
    }
    this.updateVenue({ capacity: update_value })
  }

  deleteVenue() {
    this.setLoading(() => {
      this.props.adminDeleteVenue(this.props.match.params.id).then(
        (response) => {
          this.clearLoading()
          if (!response.error) { this.props.history.push('/admin') }
        }
      )
    })
  }

  save() {
    this.setLoading(() => {
      this.props.adminUpdateVenue(this.props.match.params.id, this.state.venue).then(
        (response) => this.clearLoading()
      )
    })
  }

  submit() {
    this.setLoading(() => {
      this.props.adminUpdateVenue(this.props.match.params.id, this.state.venue).then(
        (response) => {
          this.clearLoading()
          if (!response.error) {
            this.props.history.push(`/admin${this.props.venue.url}`)
          }
        }
      )
    })
  }


  renderPublicDetails() {
    return (
      <div className='card p-3'>
        <p><i>These details are visible to all backline users, and will help other users identify your venue.</i></p>
        <div className='row'>
          <div className='col-6'>
            <label className="d-block">
              <span className="small">Venue Name</span>
              <ErrorLabel field='name' errors={this.props.api_errors} />
              <input
                className="form-control"
                type='text'
                name='venue_name'
                value={this.state.venue.name || ''}
                onChange={(e) => this.updateVenue({ name: e.target.value })}
              />
            </label>
          </div>

          <div className='col-6'>
            <label className="d-block">
              <span className="small">Capacity</span>
              <input
                className='form-control'
                type='number'
                name='capacity'
                value={this.state.venue.capacity || ''}
                onChange={(e) => this.updateCapacity(e.target.value)}
              />
            </label>
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-12 col-md-6'>
            <label className='d-block'>
              <span className="small">Main Phone</span>
              <PhoneInput
                className='form-control'
                name='main_phone'
                value={this.state.venue.main_phone || ''}
                onChange={(v) => this.updateVenue({ main_phone: v })}
              />
            </label>
          </div>

          <div className='col-12 col-md-6'>
            <label className='d-block'>
              <span className="small">Box Office Phone</span>
              <PhoneInput
                className='form-control'
                name='box_office_phone'
                value={this.state.venue.box_office_phone || ''}
                onChange={(v) => this.updateVenue({ box_office_phone: v })}
              />
            </label>
          </div>
        </div>

        <div className='row mt-3'>
          <label>
            Venue Username
            <ErrorLabel field='username' errors={this.props.api_errors} />
            <input
              className='form-control'
              type='text'
              name='username'
              value={this.state.venue.username || ''}
              onChange={(e) => this.updateVenue({ username: e.target.value })}
            />
          </label>
        </div>

        <label className='d-block mt-3'>
          Website
          <div className="input-group">
            <span className="input-group-text">http://</span>
            <input
              className='form-control'
              type='text'
              name='website'
              value={this.state.venue.website || ''}
              onChange={(e) => this.updateVenue({ website: e.target.value })}
            />
          </div>
        </label>

        <label className='d-block mt-3'>
          Description
          <textarea
            className='form-control'
            name='bio'
            value={this.state.venue.bio || ''}
            onChange={(e) => this.updateVenue({ bio: e.target.value })}
          />
        </label>
      </div>
    )
  }

  renderPrivateDetails() {
    return (
      <div className='card p-3'>
        <p><i>These details will only be visible on shows that you are hosting.</i></p>
        <div className='row'>
          <div className='col-6'>
            <label className="d-block">
              <span className="small">Wifi</span>
              <input
                className='form-control'
                type='text'
                name='wifi'
                value={this.state.venue.wifi || ''}
                onChange={(e) => this.updateVenue({ wifi: e.target.value })}
              />
            </label>
          </div>

          <div className='col-6'>
            <label className="d-block">
              <span className="small">Wifi-Password</span>
              <input
                className='form-control'
                type='text'
                name='wifi_password'
                value={this.state.venue.wifi_password || ''}
                onChange={(e) => this.updateVenue({ wifi_password: e.target.value })}
              />
            </label>
          </div>
        </div>

        <label className='d-block mt-3'>
          <span className="small">Parking</span>
          <textarea
            className='form-control'
            name='parking'
            value={this.state.venue.parking || ''}
            onChange={(e) => this.updateVenue({ parking: e.target.value })}
          />
        </label>

        <label className='d-block mt-3'>
          <span className="small">Load-In Info</span>
          <textarea
            className='form-control'
            name='load_in_info'
            value={this.state.venue.load_in_info || ''}
            onChange={(e) => this.updateVenue({ load_in_info: e.target.value })}
          />
        </label>

        <label className='d-block mt-3'>
          <span className="small">Green Room Info</span>
          <textarea
            className='form-control'
            name='green_room_info'
            value={this.state.venue.green_room_info || ''}
            onChange={(e) => this.updateVenue({ green_room_info: e.target.value })}
          />
        </label>

        <fieldset className='border rounded-3 p-3 pt-1 mt-3'>
          <legend className="float-none w-auto px-3 m-0 small">Amenities</legend>
          <label className="d-block">
            <input
              name='has_showers'
              type='checkbox'
              checked={this.state.venue.has_showers || false}
              onChange={(e) => this.updateVenue({ has_showers: e.target.checked })}
            />
            <span className="ps-2">Showers</span>
          </label>

          <label className="d-block mt-2">
            <input
              name='has_laundry'
              type='checkbox'
              checked={this.state.venue.has_laundry || false}
              onChange={(e) => this.updateVenue({ has_laundry: e.target.checked })}
            />
            <span className="ps-2">Laundry</span>
          </label>
        </fieldset>
      </div>
    )
  }

  render() {
    return (
      <div className='container'>
        <div className='row'>
          <AdminHeader />
          <h2>Edit Venue - {this.state.venue.name}</h2>
        </div>

        <div className='row'>
          <div className='col-12 col-md-6'>
            <h4>Address</h4>
            <div className='card p-3 mb-3'>
              <label>
                <input
                  name='is_test'
                  type='checkbox'
                  checked={this.state.venue.is_test || false}
                  onChange={(e) => this.updateVenue({ is_test: e.target.checked })}
                  disabled={this.props.disabled}
                />
                <span className="ps-2">IS TEST DATA</span>
              </label>

              <label className='d-block mb-3'>
                <input
                  name='is_private'
                  type='checkbox'
                  checked={this.state.venue.is_private || false}
                  onChange={(e) => this.updateVenue({ is_private: e.target.checked })}
                  disabled={this.props.disabled}
                />
                <span className="ps-2">Private Venue</span>
              </label>

              <AddressForm
                address={this.state.venue.address || {}}
                onChange={(a) => this.updateVenue({ address: a })}
              />
            </div>

            <h4>Profile Picture</h4>
            {this.props.venue.avatar_url && (
              <img
                src={this.props.venue.avatar_url}
                className='avatar'
              />
            )}
            <DirectFileUploader
              onUploadSuccess={(signedIds) => this.updateVenue({
                avatar_image: _.first(signedIds)
              }, () => this.save())}
              setFileData={(fd) => this.setState({ fileData: fd })}
              fileData={this.state.fileData}
              maxFileSize={MAX_AVATAR_SIZE}
            />

            <h4 className="mt-3">Public Details</h4>
            {this.renderPublicDetails()}
          </div>

          <div className='col-12 col-md-6'>
            <h4>Private Details</h4>
            {this.renderPrivateDetails()}

            <h4 className='mt-3'>Default Information</h4>
            <div className='card p-3'>
              <p><i>This information is filled out automatically on each show you host, but can be edited on a show-by-show basis.</i></p>

              <label className='d-block'>
                <span className="small">Merch Info</span>
                <textarea
                  className='form-control'
                  name='default_merch_info'
                  value={this.state.venue.default_merch_info || ''}
                  onChange={(e) => this.updateVenue({ default_merch_info: e.target.value })}
                />
              </label>

              <label className='d-block mt-3'>
                <span className="small">Hospitality Details</span>
                <textarea
                  className='form-control'
                  name='hospitality_details'
                  value={this.state.venue.default_hospitality_details || ''}
                  onChange={(e) => this.updateVenue({ default_hospitality_details: e.target.value })}
                />
              </label>

              <label className='d-block mt-3'>
                <span className="small">Additional Show Notes</span>
                <textarea
                  className='form-control'
                  name='default_show_notes'
                  value={this.state.venue.default_show_notes || ''}
                  onChange={(e) => this.updateVenue({ default_show_notes: e.target.value })}
                />
              </label>
            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
        />
      </div>
    )
  }
}

export default connect((state, props) => ({
  venue: selectModel('venues', props.match.params.id, Resources.venue, state),
  api_errors: selectApiErrors(state),
}), (dispatch) => bindActionCreators({
  adminGetVenue,
  adminUpdateVenue,
  adminDeleteVenue
}, dispatch))(EditVenuePage)
