import React from 'react'

export default class BottomBar extends React.Component {

  render() {
    return (
      <div className='bottom-bar'>
        {this.props.children}
      </div>
    )
  }

}
