import React, { useState, useEffect } from 'react'

import DateInput from 'components/DateInput'
import InitialTicketConfigEditArray from 'components/ticket_count/InitialTicketConfigEditArray'

const ErrorMessage = ({ message }) => (<div className="alert alert-danger">{message}</div>)

const FirstTimeCountForm = ({
  gig,
  updateOnSaleDate,
  displayShowDate,
  venueCapacity,
  setIsSuccessful,
  createTicketCount
}) => {
  const exampleTicketTypes = [{ ticket_type_name: 'GA' }, { ticket_type_name: 'VIP' }]
  const [ticketConfig, setTicketConfig] = useState(exampleTicketTypes)
  const [errorMessage, setErrorMessage] = useState()
  const [userHasSubmitted, setUserHasSubmitted] = useState(false)
  const [totalSellableSum, setTotalSellableSum] = useState(0)
  const [potentialGross, setPotentialGross] = useState()

  const gigDisplayName = gig.default_name
  const gigOnSaleDate = gig.on_sale_date || ''

  useEffect(() => {
    const allTotalSellable = ticketConfig.reduce(
      (num, tc) => num + (parseInt(tc.total_sellable, 10) || 0),
      0
    )
    setTotalSellableSum(allTotalSellable)

    const totalGross = ticketConfig.reduce(
      (num, tc) => num + ((parseFloat(tc.price) || 0) * (parseInt(tc.total_sellable, 10) || 0)),
      0
    ).toFixed(2)
    setPotentialGross(totalGross)
  }, [ticketConfig])


  const validateTicketConfig = () => {
    setErrorMessage()
    let newError

    if (ticketConfig.length) {
      // ensure no duplicate ticket types
      const names = ticketConfig.map(tc => tc.ticket_type_name)
      if (names.length !== new Set(names).size) {
        newError = 'Please make sure all of your ticket types are unique.'
      }

      // ensure each present ticket type has a Total Sellable amount
      const allHaveTotalSellable = ticketConfig.every(ticketType => {
        return !!ticketType.total_sellable && !!ticketType.ticket_type_name
      })
      if (!allHaveTotalSellable) {
        newError = 'Please make sure each ticket type has at least a Total Sellable amount.'
      }

      setErrorMessage(newError)
    }

    return !newError
  }

  useEffect(() => {
  }, [ticketConfig, userHasSubmitted])

  const submitTicketConfiguration = () => {
    if (validateTicketConfig()) {
      const formattedData = {
        ticket_types: ticketConfig.map(ticketType => ({
          name: ticketType.ticket_type_name,
          total_sellable: ticketType.total_sellable,
          price: ticketType.price,
          count: parseInt(ticketType.count || '0'),
        }))
      }

      createTicketCount(formattedData).then(res => {
        setIsSuccessful(res?.payload?.request_status === 200)
      })
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-12 col-lg-9 col-xl-8'>
          <div className='alert alert-primary'>
            Welcome to the Backline.me ticket count system!<br/>
            <br/>
            You've been asked to input the ticket counts for <strong>{gigDisplayName}</strong> on <strong>{displayShowDate}</strong>.
            Please use the form below to input the ticket information for this show.
          </div>
          <div>
            {venueCapacity && totalSellableSum > venueCapacity ? (
              <div className="alert alert-warning">
                Heads up: You've allocated {totalSellableSum} tickets, but the venue capacity is only {venueCapacity}
              </div>
            ) : (
              <>
                Venue Capacity: {venueCapacity || 'Unspecified'}
              </>
            )}
          </div>
          <div className="mb-2">
            Potential Gross: ${potentialGross}
          </div>
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <>
            <InitialTicketConfigEditArray
              onChange={v => setTicketConfig(v)}
              ticketConfig={ticketConfig}
            />
            <a
              className='btn btn-primary mb-2'
              onClick={() => submitTicketConfiguration()}
            >Submit Initial Ticket Counts</a>
          </>
        </div>
      </div>
      <div className="mb-3">
        Ticket On Sale Date
        <DateInput
          name='on_sale_date'
          value={gigOnSaleDate}
          onChange={v => updateOnSaleDate(v)}
        />
      </div>
    </>
  )
}

export default FirstTimeCountForm
