import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createMembership } from 'actions/api/memberships'
import { selectApiErrors } from 'selectors'
import { findPersonByEmail, invitePerson } from 'actions/api/people'

import OtherSelect from 'components/OtherSelect'
import PhoneInput from 'components/people/PhoneInput'
import ShowPhone from 'components/people/ShowPhone'
import ShowEmail from 'components/people/ShowEmail'
import LoadingState from 'components/LoadingState'
import ApiErrors from 'components/ApiErrors'
import FormField from 'components/FormField'

import { iff } from 'utils'

const FORM_STATUS = {
  ENTER_EMAIL: 'ENTER_EMAIL',
  MEMBERSHIP_INVITE: 'MEMBERSHIP_INVITE',
  UNOWNED_PERSON: 'UNOWNED_PERSON',
  NEW_PERSON: 'NEW_PERSON',
  NO_EMAIL: 'NO_EMAIL',
  EMAIL_INVITE: 'EMAIL_INVITE',
  SUCCESS: 'SUCCESS',
}

const default_state = {
  email: '',
  membership: {},
  invite_person: {},
  form_status: FORM_STATUS.ENTER_EMAIL,
  is_loading: false,
  person_id: null,
  success_message: '',
}

class WelcomeNewMemberForm extends LoadingState {

  constructor(props) {
    super(props)

    this.state = { ...default_state }
  }

  resetState() {
    this.setState({ ...default_state })
  }

  updateMembership(update, callback) {
    const membership = _.get(this, 'state.membership', {})
    this.setState({
      membership: {
        ...membership,
        ...update,
      }
    }, () => {
      if (callback) { callback() }
    })
  }

  updatePerson(update) {
    const person = _.get(this, 'state.membership.person', {})
    this.updateMembership({
      person: {
        ...person,
        ...update,
      }
    })
  }

  submitEmail() {
    this.setLoading(() =>
      this.props.findPersonByEmail(this.state.email).then((response) => {
        this.clearLoading(() => {
          const request_status = _.get(response, 'payload.request_status')
          if (request_status === 200) {
            const person = _.get(response, 'payload.person', {})
            if (person.is_owned) {
              this.setState({
                form_status: FORM_STATUS.MEMBERSHIP_INVITE,
                person_id: person.id,
              })
            } else {
              this.setState({
                form_status: FORM_STATUS.UNOWNED_PERSON,
                person_id: person.id,
                membership: {
                  person: { ...person },
                },
              })
            }
          } else if (request_status === 404) {
            this.setState({
              form_status: FORM_STATUS.NEW_PERSON,
              membership: {
                person: { email: this.state.email },
              },
            })
          } else {
            // TODO - ERROR RESPONSE
          }
        })
      })
    )
  }

  submitMembership(callback) {
    const person_id = _.get(this, 'state.person_id', null)
    let membership_data = {}
    if (_.isNil(person_id)) {
      membership_data = { ..._.get(this, 'state.membership', {}) }
    } else {
      membership_data = { person_id }
    }
    this.setLoading(() =>
      this.props.createMembership(this.props.parent_url, membership_data).then((response) => {
        this.clearLoading(() => {
          const request_status = _.get(response, 'payload.request_status')
          const person = _.get(response, 'payload.raw.membership.person', {})
          iff(callback, person)
        })
      })
    )
  }

  submitInvite(callback) {
    const person_id = _.get(this, 'state.invite_person.id', null)
    this.setLoading(() =>
      this.props.invitePerson(person_id).then((response) => {
        this.clearLoading(() => {
          const request_status = _.get(response, 'payload.request_status')
          const success_message = _.get(response, 'payload.raw.message')
          iff(callback, success_message)
        })
      })
    )
  }

  renderMembershipFields(show_email) {
    const person = _.get(this, 'state.membership.person', {})
    return (
      <>
        <div className='row'>
          {show_email && (
            <div className='col-12 col-md-6 mb-3'>
              <FormField
                label='Email'
                type='email'
                name='email'
                value={person.email || ''}
                onChange={(e) => this.updatePerson({ email: e.target.value })}
              />
            </div>
          )}

          <div className='col-12 col-md-6 mb-3'>
            <FormField
              label='Name'
              type='text'
              name='name'
              value={person.name || ''}
              onChange={(e) => this.updatePerson({ name: e.target.value })}
            />
          </div>

          <div className='col-12 col-md-6 mb-3'>
            <label className='form-label'>Phone (optional)</label>
            <PhoneInput
              name='phone'
              value={person.phone || ''}
              onChange={(v) => this.updatePerson({ phone: v })}
            />
          </div>

          <div className='col-12 col-md-6 mb-3'>
            <label className='form-label'>Title</label>
            <OtherSelect
              name='title'
              options={_.map(this.props.title_options || [], (ct) => (
                { value: ct, label: ct }
              ))}
              value={this.state.membership.title}
              onChange={(v) => this.updateMembership({ title: v })}
            />
          </div>
        </div>

        <button
          className='btn btn-primary mb-0'
          onClick={() => this.submitMembership((res_person) => {
            if (show_email) {
              this.setState({
                form_status: FORM_STATUS.EMAIL_INVITE,
                invite_person: { ...res_person },
              })
            } else {
              this.setState({
                form_status: FORM_STATUS.SUCCESS,
                invite_person: { ...res_person },
              })
            }
          })}
        >add member</button>

        <button
          className='btn btn-gray mb-0'
          onClick={() => this.resetState()}
          disabled={this.state.is_loading}
        >cancel</button>
      </>
    )
  }

  renderForm() {
    const default_success_message = 'Member successfully added!'
    const person = _.get(this, 'state.membership.person', {})
    switch (this.state.form_status) {
      case FORM_STATUS.ENTER_EMAIL:
        return (
          <div className='row'>
            <div className='col-12 col-md-9'>
              <p>What is their email address?</p>

              <div className='mb-3'>
                <FormField
                  label='Email'
                  type='text'
                  name='email'
                  value={this.state.email || ''}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>

              <div className='mb-3'>
                <a className='link' onClick={() => this.setState({ form_status: FORM_STATUS.NO_EMAIL })}>
                  I would like to add someone without an email address
                </a>
              </div>

              <button
                className='btn btn-primary mb-0'
                onClick={() => this.submitEmail()}
              >continue</button>

              <button
                className='btn btn-gray mb-0'
                onClick={() => iff(this.props.onCancel)}
                disabled={this.state.is_loading}
              >cancel</button>
            </div>
          </div>
        )

      case FORM_STATUS.MEMBERSHIP_INVITE:
        return (
          <div className='row'>
            <div className='col-12 col-md-6'>
              <p>
                A user with that email address was found!
              </p>
              <p>
                Would you like to send them an invite?
              </p>

              <button
                className='btn btn-primary m-0'
                onClick={() => this.submitMembership((res_person) => {
                  this.setState({
                    form_status: FORM_STATUS.SUCCESS,
                    success_message: 'Invite sent!',
                  })
                })}
              >send invite</button>

              <button
                className='btn btn-gray mb-0'
                onClick={() => this.resetState()}
                disabled={this.state.is_loading}
              >no thanks</button>
            </div>
          </div>
        )

      case FORM_STATUS.UNOWNED_PERSON:
        return (
          <>
            <p>
              We've found the contact information for someone you've worked with before.
            </p>

            <p>
              <strong>{person.name}</strong><br/>
              {!_.isEmpty(person.email) && (<div><ShowEmail email={person.email} show_icon /></div>)}
              {!_.isEmpty(person.phone) && (<div><ShowPhone number={person.phone} show_icon /></div>)}
            </p>

            <p>
              Does this information look correct?
            </p>

            <button
              className='btn btn-primary mb-0'
              onClick={() => this.submitMembership((res_person) => {
                this.setState({
                  form_status: FORM_STATUS.EMAIL_INVITE,
                  invite_person: { ...res_person },
                })
              })}
            >yes, add them</button>

            <button
              className='btn btn-primary mb-0'
              onClick={() => this.setState({ form_status: FORM_STATUS.NEW_PERSON })}
              disabled={this.state.is_loading}
            >no, make changes</button>
          </>
        )

      case FORM_STATUS.EMAIL_INVITE:
        return (
          <>
            <p>
              Great!  Would you like to send <strong>{_.get(this, 'state.invite_person.name')}</strong> an invitation to create their own Backline.me login?
            </p>

            <button
              className='btn btn-primary mb-0'
              onClick={() => this.submitInvite((success_message) => {
                this.setState({
                  form_status: FORM_STATUS.SUCCESS,
                  success_message,
                })
              })}
            >send invite</button>

            <button
              className='btn btn-gray mb-0'
              onClick={() => this.setState({ form_status: FORM_STATUS.SUCCESS, })}
              disabled={this.state.is_loading}
            >no thanks</button>
          </>
        )

      case FORM_STATUS.NEW_PERSON:
        return (
          <>
            <p>
              Tell us a little more!
            </p>

            {this.renderMembershipFields(true)}
          </>
        )

      case FORM_STATUS.NO_EMAIL:
        return this.renderMembershipFields(false)

      case FORM_STATUS.SUCCESS:
        return (
          <>
            <p>
              {this.state.success_message || default_success_message}&nbsp;
              Would you like to add another member?
            </p>

            <button
              className='btn btn-primary mb-0'
              onClick={() => this.resetState()}
            >add another</button>

            <button
              className='btn btn-gray mb-0'
              onClick={() => iff(this.props.onCancel)}
              disabled={this.state.is_loading}
            >no thanks</button>
          </>
        )
    }
    return null
  }

  render() {
    const membership = this.state.membership || {}

    return (
      <>
        {this.renderForm()}
        <ApiErrors errors={this.props.api_errors} />
      </>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
}), (dispatch) => bindActionCreators({
  findPersonByEmail,
  createMembership,
  invitePerson
}, dispatch))(WelcomeNewMemberForm)
