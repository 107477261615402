import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    React.createElement(App, { ...window.controller_props }),
    document.body.appendChild(document.createElement('div')),
  )
})
