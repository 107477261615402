import React from 'react'

export default class TestClientError extends React.Component {

  render() {
    return (
      <div>
        <FakeComponent></FakeComponent>
      </div>
    )
  }

}
