import { combineReducers } from 'redux'

import apiReducer from './api'
import authContextReducer from './auth_context'
import overlayReducer from './overlay'
import notificationsReducer from './notifications'
import urlAuthTokenReducer from './url_auth_token'
import forceEditableListReload from './force_editable_list_reload'
import loggingReducer from './logging_reducer'


export default combineReducers({
  api: apiReducer,
  auth_context: authContextReducer,
  overlay: overlayReducer,
  notifications: notificationsReducer,
  url_auth_token: urlAuthTokenReducer,
  force_editable_list_reload: forceEditableListReload,
  //logging_reducer: loggingReducer, // Uncomment this line to log redux actions
})
