import React from 'react'
import classnames from 'classnames'

import SafeLink from 'components/SafeLink'

export default class PageTitle extends React.Component {

  render() {
    return (
      <div className='clearfix'>
        {this.props.is_editable && (
          <div className='float-end'>
            <SafeLink
              className='btn btn-primary'
              to={this.props.edit_link}
            >edit</SafeLink>
          </div>
        )}

        <h2 className='m-0'>{this.props.children}</h2>
        {this.props.subtitle}
      </div>
    )
  }

}
