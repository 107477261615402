import {
  CURRENT_USER_ACTION,
  LOGOUT_ACTION,
  LOGIN_ACTION,
  REGISTER_ACTION,
  CLAIM_INVITE_ACTION,
  CONFIRM_EMAIL_ACTION
} from 'actions/api/users'

import { AUTHENTICATE_ACTION } from 'actions/api/auth'

import {
  isSuccessAction,
  isFailureAction,
  isResourceAction
} from 'actions'

import {
  AUTH_TYPE
} from 'static/Auth'

export default (state={}, action) => {
  if ((action.type.match(CURRENT_USER_ACTION) ||
        action.type.match(LOGIN_ACTION) ||
        action.type.match(REGISTER_ACTION) ||
        action.type.match(CONFIRM_EMAIL_ACTION) ||
        action.type.match(CLAIM_INVITE_ACTION)) &&
      isSuccessAction(action) &&
      isResourceAction(action) &&
      action.payload) {
    return {
      auth_type: AUTH_TYPE.USER,
      id: action.payload.result,
    }
  }

  if (action.type.match(AUTHENTICATE_ACTION) &&
      isSuccessAction(action) &&
      isResourceAction(action) &&
      action.payload) {
    return {
      auth_type: action.payload.raw.auth_type,
      id: action.payload.result,
    }
  }

  if (action.type.match(LOGOUT_ACTION) &&
      isSuccessAction(action)) {
    return {}
  }

  if (action.payload && action.payload.set_logged_out === true) {
    return {}
  }

  return state
}
