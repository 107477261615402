import React from 'react'
import classnames from 'classnames'

import { iff } from 'utils'

import Row from 'components/Row'

export default class Overlay extends React.Component {

  stopProp(e) {
    e.stopPropagation()
  }

  render() {
    return (
      <div className={classnames('overlay', { 'hidden': this.props.is_hidden })} onClick={() => iff(this.props.onDismiss)}>
        <div className={classnames({ 'mobile': window.is_mobile }, 'overlay-box', 'clearfix', this.props.classes)} onClick={(e) => this.stopProp(e)}>
          <div className='float-end'>
            <a onClick={() => iff(this.props.onDismiss)}>
              <button type="button" className="btn-close" aria-label="Close"></button>
            </a>
          </div>

          <div className='overlay-box-inside mt-2'>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }

}
