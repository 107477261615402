import React from 'react'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'

export default class NotFoundPage extends React.Component {

  render() {
    return (
      <Row>
        <div className='callout alert'>
          The page you were looking for was not found.&nbsp;
          <SafeLink to='/'>Click here</SafeLink> to return to the homepage.
        </div>
      </Row>
    )
  }

}
