import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { denormalize } from 'normalizr'
import { searchBands, getBand } from 'actions/api/bands'

import PaginatedList from 'components/PaginatedList'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import SaveBar from 'components/SaveBar'
import Selector from 'components/Selector'
import Avatar from 'components/Avatar'
import FormField from 'components/FormField'
import BreadCrumbs from 'components/BreadCrumbs'

import {
  isEditable,
  getBandNamesFromScheduleItems
} from 'utils'

import {
  PERFORMANCE_TYPES,
  PERFORMANCE_TYPE_LABEL,
  INVITE_STATUS
} from 'static'

const MIN_BAND_NAME_LENGTH = 3

class PerformanceForm extends PaginatedList {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      is_new_band: false,
      selected_band: {},
      band_search: '',
      performance_type: PERFORMANCE_TYPES.HEADLINE,
    }
  }

  componentDidMount() {
    if (!_.isNil(this.search_input)) {
      this.search_input.focus()
    }
  }

  requestListData(page) {
    return this.props.searchBands(this.state.band_search, page)
  }

  getListFromData(data) {
    return data.results
  }

  getSelectedBandInfo() {
    const selected_band = this.state.selected_band || {}
    this.setLoading(() => this.props.getBand(selected_band.id)
      .then((res) => {
        if (res.error) {
          this.clearLoading()
        } else {
          this.setState({
            is_loading: false,
            selected_band: {
              ...selected_band,
              ...res.payload.raw.band,
            }
          })
        }
      }))
  }

  onBandSearch(query) {
    this.setState({ band_search: query, page: 0 }, () => {
      if (!this.state.is_loading) {
        this.getListData()
      }
    })
  }

  canEditPerformanceType() {
    const gig = this.props.gig || {}
    const perspective = gig.perspective || {}
    return isEditable(perspective.gig_permissions) && perspective.is_gig_owner
  }

  canMakeNewBand() {
    return _.trim(this.state.band_search).length >= MIN_BAND_NAME_LENGTH
  }

  canSave() {
    const selected_band = this.state.selected_band || {}
    return !_.isNil(selected_band.id) || (this.state.is_new_band && this.canMakeNewBand())
  }

  selectBand(b) {
    const selected_band = this.state.selected_band || {}
    if (b.id === selected_band.id) { return null }
    this.setState({
      is_new_band: false,
      selected_band: b,
    }, () => {
      this.getSelectedBandInfo()
    })
  }

  formatPerformanceData() {
    let performance_data = {}
    const selected_band = this.state.selected_band || {}

    if (this.state.is_new_band && this.canMakeNewBand()) {
      performance_data = {
        band: { name: this.state.band_search, },
        performance_type: this.state.performance_type,
        schedule_band_name: this.state.schedule_band_name,
      }
    } else if (!_.isNil(selected_band.id)) {
      performance_data = {
        band_id: selected_band.id,
        performance_type: this.state.performance_type,
        schedule_band_name: this.state.schedule_band_name,
      }
    } else {
      throw "[PerformanceForm] Performance saved without a selected band_id or new band."
    }

    return performance_data
  }

  renderSelectedBandDetails() {
    const selected_band = this.state.selected_band || {}
    if (_.isEmpty(selected_band.public_contacts) &&
        _.isEmpty(selected_band.bio) &&
        _.isEmpty(selected_band.website)) {
      return null
    }

    return (
      <div className='row mt-2'>
        {(!_.isEmpty(selected_band.bio) || !_.isEmpty(selected_band.website)) && (
          <div className='col-12 col-md-6'>
            <div className='list-group list-group-striped mb-2'>
              <div className='list-group-item bold text-center'>
                Details
              </div>
              <div className='list-group-item scroll-block max-height'>
                {!_.isEmpty(selected_band.website) && (
                  <a href={selected_band.website} target='_blank'>{selected_band.website}</a>
                )}
                {!_.isEmpty(selected_band.bio) && (
                  <div className='prewrap'>
                    {selected_band.bio}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!_.isEmpty(selected_band.public_contacts) && (
          <div className='col-12 col-md-6'>
            <div className='list-group list-group-striped list-group-striped-inverse mb-2'>
              <div className='list-group-item text-center bold bg-light'>
                Contacts
              </div>
              <div className='scroll-block max-height margin-top-minus-1'>
                {_.map(selected_band.public_contacts, (c) => (
                  <div className='list-group-item clearfix' key={`contact-${c.id}`}>
                    <Avatar
                      url={c.avatar_url}
                      classes='float-start small-avatar'
                    />
                    {c.name}
                    {c.username && (
                      <>&nbsp;(@{c.username})</>
                    )}
                    {c.title && (
                      <i>&nbsp;&ndash;&nbsp;{c.title}</i>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const gig = this.props.gig || {}
    const selected_band = this.state.selected_band || {}
    const schedule_band_names = getBandNamesFromScheduleItems(_.get(gig, 'schedule_items', []))
    const schedule_band_options = [
      { label: '---', value: '' },
      ..._.map(schedule_band_names, (bn) => ({ label: bn, value: bn })),
    ]

    return (
      <>
        <div className='container'>
          <BreadCrumbs items={[
            { name: 'Shows', url: '/' },
            { name: gig.gig_name || gig.default_name, url: gig.url },
            { name: 'Add Band' },
          ]} />

          <h2>Add Band</h2>

          <div className='alert alert-primary'>
            To search for a band, start typing their name into the 'Band Search' field below.  Then just select your desired band and press save!
          </div>

          <div className='row'>
            <div className='col-xl-2 col-lg-3 col-md-4 col-12 mb-3'>
              <label className='form-label'>Performance Type</label>
              <Selector
                name='performance_type'
                classes='form-select'
                value={this.state.performance_type || PERFORMANCE_TYPES.HEADLINE}
                onChange={(v) => this.setState({ performance_type: v })}
                disabled={!this.canEditPerformanceType()}
                options={_.map(PERFORMANCE_TYPES, (pt) => ({ label: PERFORMANCE_TYPE_LABEL[pt], value: pt }))}
              />
            </div>

            {schedule_band_names.length > 0 && (
              <div className='col-xl-2 col-lg-3 col-md-4 col-12 mb-3'>
                <label className='form-label'>Schedule Slot</label>
                <Selector
                  name='schedule_band_name'
                  classes='form-select'
                  value={this.state.schedule_band_name}
                  onChange={(v) => this.setState({ schedule_band_name: v })}
                  options={schedule_band_options}
                />
              </div>
            )}

            <div className='col mb-3'>
              <FormField
                label='Band Search'
                type='text'
                name='band_search'
                value={this.state.band_search}
                passRef={(i) => { this.search_input = i }}
                onChange={(e) => this.onBandSearch(e.target.value)}
              />
            </div>
          </div>

          <div className='list-group list-group-striped mb-3'>
            {_.map(this.state.list, (b) => (
              <a
                key={`search-band-${b.id}`}
                className={classnames('list-group-item list-group-item-action clearfix d-block', {
                  'list-group-item-primary': b.id === selected_band.id,
                })}
                onClick={() => this.selectBand(b)}
              >
                <span className='text-dark'>
                  <Avatar
                    url={b.avatar_url}
                    classes='float-start small-avatar'
                  />
                  <strong>{b.name}</strong>&nbsp;
                  {b.username && (
                    <React.Fragment>(@{b.username})</React.Fragment>
                  )}

                  {b.id === selected_band.id && this.renderSelectedBandDetails()}
                </span>
              </a>
            ))}

            {this.canMakeNewBand() && (
              <a
                className={classnames('list-group-item list-group-item-action clearfix d-block', {
                  'list-group-item-primary': this.state.is_new_band,
                })}
                onClick={() => this.setState({ selected_band: null, is_new_band: true })}
              >
                <span className='text-dark'>
                  <strong>
                    <i className='fi-plus half-margin-right'></i>Create New Band - {this.state.band_search}
                  </strong>
                </span>
              </a>
            )}

            {!this.canMakeNewBand() && _.isEmpty(this.state.list) && (
              <div className='list-group-item text-center'>
                No Results.  Type at least 3 letters to add a new band or change your search query.
              </div>
            )}
          </div>

          {this.state.num_pages > 1 && this.renderPageButtons()}
        </div>


        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.props.onSubmit(this.formatPerformanceData())}
          onDelete={() => this.props.onDelete()}
          hide_delete={!this.props.onDelete}
          disabled={this.props.is_loading}
          save_disabled={!this.canSave()}
        />

      </>
    )
  }
}

export default connect(null, (dispatch) => bindActionCreators({
  searchBands, getBand
}, dispatch))(PerformanceForm)
