import React from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { adminGetGigs } from 'actions/api/admin/gigs'

import BasicListPage from 'components/admin/BasicListPage'

class GigsPage extends BasicListPage {

  requestListData(page) {
    return this.props.adminGetGigs(page)
  }

  getPageName() {
    return 'gigs'
  }

  getItemUrl(url) {
    return `/admin${url}`
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  adminGetGigs
}, dispatch))(withRouter(GigsPage))
