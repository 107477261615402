import _ from 'lodash'
import React from 'react'

import { connect } from 'react-redux'
import { selectCurrentUser } from 'selectors'

import SafeLink from 'components/SafeLink'

const ContactPage = ({ currentUser }) => (
  <div className='container mt-3'>
    <div className='row justify-content-center'>
      <div className='col-12 col-lg-10 col-xl-8'>
        <div className='border rounded p-3 bg-light'>
          <h3>Contact</h3>
          <p>
            If you'd like to get in contact with us, you can send us an email <a href="mailto:info@backline.me">info@backline.me</a>.
          </p>
          {_.isNil(currentUser) && (
            <>
              <p>
                If you'd like to join the site, you can sign up for the waitlist and we'll contact you when you can register.
              </p>
              <SafeLink to="/waitlist" className='btn btn-primary'>
                Sign Up for the Waitlist
              </SafeLink>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default connect((state) => ({
  currentUser: selectCurrentUser(state)
}))(ContactPage)
