import { combineReducers } from 'redux'

import inviteOverlayReducer from 'reducers/overlay/invite_overlay_person'
import advanceOverlayReducer from 'reducers/overlay/advance_overlay'
import saveScheduleOverlayReducer from 'reducers/overlay/save_schedule_overlay'
import applyScheduleOverlayReducer from 'reducers/overlay/apply_schedule_overlay'
import linkScheduleOverlayReducer from 'reducers/overlay/link_schedule_overlay'

export default combineReducers({
  invite_overlay_person: inviteOverlayReducer,
  advance_overlay: advanceOverlayReducer,
  save_schedule_overlay: saveScheduleOverlayReducer,
  apply_schedule_overlay: applyScheduleOverlayReducer,
  link_schedule_overlay: linkScheduleOverlayReducer,
})
