import React from 'react'
import classnames from 'classnames'
import ShowContact from 'components/contacts/ShowContact'

import {
  INVITE_STATUS
} from 'static'

export default class ShowContacts extends React.Component {

  renderContacts() {
    const contacts = this.props.contacts || []
    const sorted_contacts = _.sortBy(contacts, (c) => c.is_admin ? -1 : c.is_primary ? 0 : 1)
    return _.map(sorted_contacts, (c) => (
      <div key={`show-contact-${c.id}`} className={classnames('list-group-item', {
        'list-group-item-primary': this.props.show_invite && c.invite_status === INVITE_STATUS.PENDING,
        'list-group-item-danger': this.props.show_invite && c.invite_status === INVITE_STATUS.REJECTED,
      })}>
        <ShowContact
          contact={c}
          show_admin={this.props.show_admin}
          show_primary={this.props.show_primary}
          show_public={this.props.show_public}
          show_avatar={this.props.show_avatar}
          show_invite={this.props.show_invite}
          show_is_on_site={this.props.show_is_on_site}
          is_admin_view={this.props.is_admin_view}
          hide_invite_button={this.props.hide_invite_button}
        />
      </div>
    ))
  }

  render() {
    return (
      <>
        {!this.props.hide_title && (
          <h5>Contacts</h5>
        )}
        <div className='list-group list-group-striped'>
          {this.renderContacts()}
        </div>
      </>
    )
  }
}
