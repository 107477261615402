import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  updateFinancialDocument,
  deleteFinancialDocument,
} from 'actions/api/financial_documents'

import ConfirmationButton from 'components/ConfirmationButton'
import LoadingState from 'components/LoadingState'
import FinancialDocumentForm from 'components/documents/FinancialDocumentForm'

import { iff } from 'utils'

class EditableFinancialDocument extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      is_editing: false,
      is_confirm_delete: false,
      financial_document: props.financialDocument || {},
    }
  }

  componentWillReceiveProps(props) {
    this.setState({ financial_document: props.financialDocument || {} })
  }

  submit() {
    this.setLoading(() => this.props.updateFinancialDocument(
      this.props.gig.id,
      this.state.financial_document.id,
      this.state.financial_document,
    ).then((res) => {
      if (res.error) {
        this.clearLoading()
      } else {
        this.setState({
          is_loading: false,
          is_editing: false,
          financial_document: {
            ...(res.payload.raw.financial_document || this.state.financial_document),
          },
        })
      }
    }))
  }

  onDelete() {
    this.setLoading(() => this.props.deleteFinancialDocument(
      this.props.gig.id,
      this.state.financial_document.id,
    ).then((res) => {
      if (res.error) {
        this.clearLoading()
      }
    }))
  }

  updateFinancialDocumentState(update, callback) {
    this.setState({
      financial_document: {
        ...this.state.financial_document,
        ...update,
      }
    }, () => iff(callback))
  }

  render() {
    const financialDocument = this.state.financial_document || {}
    if (this.state.is_editing) {
      return (
        <div className='list-group-item'>
          <FinancialDocumentForm
            financialDocument={financialDocument}
            updateFinancialDocumentState={(val, cb) => this.updateFinancialDocumentState(val, cb)}
            onUploadSuccess={() => this.submit()}
          />
          <div className='d-flex flex-row align-items-center'>
            <div className='flex-grow-1'>
              {!this.state.is_confirm_delete && (
                <>
                  <a
                    className='btn btn-primary mb-0'
                    onClick={() => this.submit()}
                    disabled={this.state.is_loading}
                  >save</a>
                  <a
                    className='btn btn-gray mb-0'
                    onClick={() => this.setState({ is_editing: false })}
                    disabled={this.state.is_loading}
                  >cancel</a>
                </>
              )}
            </div>

            <div className='flex-grow-0 text-end'>
              <ConfirmationButton
                button_classes='mb-0'
                button_type='danger'
                button_text='delete'
                confirm_text='really delete?'
                onConfirm={() => this.onDelete()}
                onStateChange={(v) => this.setState({ is_confirm_delete: v })}
                disabled={this.state.is_loading}
              />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='list-group-item d-flex flex-row align-items-center'>
          <div className='flex-grow-1'>
            <a href={financialDocument.url} target='_blank' className='text-decoration-none'>
              <i className='fi-page-filled'></i>&nbsp;{financialDocument.name}
            </a>
          </div>
          <div className='flex-grow-0 text-end'>
            <a onClick={() => this.setState({ is_editing: true })}>
              <i className='edit-icon fi-pencil'></i>
            </a>
          </div>
        </div>
      )
    }
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  updateFinancialDocument, deleteFinancialDocument
}, dispatch))(EditableFinancialDocument)
