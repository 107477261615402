import React from 'react'
import _ from 'lodash'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getUserInvite, claimInvite } from 'actions/api/users'

import { parseUrlParams, isSuccessStatus } from 'utils'

import SafeLink from 'components/SafeLink'
import FormField from 'components/FormField'

class ClaimInviteForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      invite: { }
    }
  }

  componentWillMount() {
    this.props.getUserInvite(this.getInviteKey()).then((res) => {
      this.setState({
        invite: res.payload.invite,
        is_not_authorized: !isSuccessStatus(res.payload.request_status),
      })
    })
  }

  getInviteKey() {
    const url_info = parseUrlParams(this.props.location.search)
    const params = url_info.params || {}
    return params.i_key
  }

  submit(e) {
    if (e) { e.preventDefault() }

    this.setState({ is_loading: true }, () => {
      this.props.claimInvite({
        invite_key: this.getInviteKey(),
        user: {
          ..._.pick(this.state, [
            'password',
            'password_confirmation',
            'username',
          ])
        }
      }).then((response) => {
        if (isSuccessStatus(response.payload.request_status)) {
          this.props.history.push(`/user/claim_orgs`)
        } else {
          this.setState({ is_loading: false })
        }
      })
    })
  }

  render() {
    const invite = this.state.invite || {}
    const invite_person = invite.person || {}
    return (
      <div className='container mt-2'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-6'>
            <div className='border rounded bg-light p-3'>
              <h3>Welcome to Backline!</h3>

              <p>
                <i>
                  {invite.inviter_name} has invited you to join Backline.
                </i>
              </p>

              <p>
                Backline is a collaborative platform for advancing shows.
              </p>

              <p>
                To get started, use this form to create a user account for yourself as a person.  Once you've done so, you can add information about any of the bands or venues with which you are associated.
              </p>

              {this.state.is_not_authorized && (
                <div className='alert alert-warning'>
                  <p>
                    This invite link has expired or is otherwise not working.  If you continue to have trouble, you can send us an email at <a href='mailto:info@backline.me'> info@backline.me.
                    </a>
                  </p>
                </div>
              )}

              {invite.is_claimed && (
                <div className='alert alert-danger'>
                  <p>
                    This invite link has already been used.  If you are the rightful owner of this account invite, please contact us at <a href='mailto:info@backline.me'> info@backline.me.
                    </a>
                  </p>
                </div>
              )}

              <form className='mb-0' onSubmit={(e) => this.submit(e)}>
                <div className='mb-3'>
                  <FormField
                    label='Email'
                    name='email'
                    type='text'
                    value={invite_person.email || ''}
                    disabled
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    errors={this.props.api_errors}
                    type='text'
                    errorField='username'
                    name='username'
                    placeholder='@username'
                    value={this.state.username || ''}
                    label='Username'
                    onChange={(e) => {
                      this.setState({ username: e.target.value })
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    label='Password'
                    name='password'
                    type='password'
                    errors={this.props.api_errors}
                    value={this.state.password || ''}
                    onChange={(e) => this.setState({ password: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    name='password-confirmation'
                    type='password'
                    label='Password Confirmation'
                    value={this.state.password_confirmation || ''}
                    errors={this.props.api_errors}
                    onChange={(e) => this.setState({ password_confirmation: e.target.value })}
                  />
                </div>

                <div class="mb-3 form-check">
                  <input
                    name='agree_to_terms'
                    type='checkbox'
                    className='form-check-input'
                    checked={this.state.agree_to_terms}
                    onChange={(e) => this.setState({ agree_to_terms: e.target.checked })}
                  />
                  <label className='form-check-label terms-and-conditions'>
                    <i>
                      By registering for Backline.me, you agree to our&nbsp;
                      <SafeLink to='/terms'>terms and conditions</SafeLink> and
                      our <SafeLink to='/privacy'>privacy policy</SafeLink>.
                    </i>
                  </label>
                </div>

                <input
                  className='btn btn-primary mb-0'
                  type='submit'
                  value='Register'
                  disabled={this.state.is_loading || this.state.is_not_authorized || invite.is_claimed || !this.state.agree_to_terms}
                  onClick={(e) => this.submit(e)}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

}


export default connect((state, props) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  claimInvite, getUserInvite
}, dispatch))(withRouter(ClaimInviteForm))
