import React from 'react'
import classnames from 'classnames'

export default ({
  className,
  label,
  name,
  checked,
  onChange,
  disabled,
  children,
}) => (
  <div className={classnames('form-check', className)}>
    <input
      name={name}
      className='form-check-input'
      id={`formCheck-${name}`}
      type='checkbox'
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <label className='form-check-label' htmlFor={`formCheck-${name}`}>{label || children}</label>
  </div>
)
