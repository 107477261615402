import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

export default ({ email, classes, show_icon, show_label, no_link }) => {
  if (_.isNil(email) || _.isEmpty(email)) { return null }

  return (
    <>
      {show_label && (
        <span>
          Email:&nbsp;
        </span>
      )}
      {no_link && (
        <React.Fragment>
          {show_icon && (
            <span>
              <i className='fi-mail'></i>&nbsp;
            </span>
          )}
          {email}
        </React.Fragment>
      )}
      {!no_link && (
        <a href={`mailto:${email}`} className={classnames('text-decoration-none', classes)}>
          {show_icon && (
            <span>
              <i className='fi-mail'></i>&nbsp;
            </span>
          )}
          {email}
        </a>
      )}
    </>
  )
}
