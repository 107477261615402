import React from 'react'
import _ from 'lodash'

import AddressForm from 'components/addresses/AddressForm'
import PhoneInput from 'components/people/PhoneInput'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'

export default class GigVenueForm extends React.Component {

  updateVenue(update) {
    this.props.onChange(update)
  }

  render() {
    const venue = this.props.venue || {}
    return (
      <div className='row'>
        <div className='col-lg-6 col-12'>
          <div className='mb-3'>
            <FormField
              label='Venue Name'
              type='text'
              name='venue_name'
              value={venue.name || ''}
              onChange={(e) => this.updateVenue({ name: e.target.value })}
              disabled={this.props.disabled}
            />

            {this.props.show_private_checkbox && (
              <FormCheck
                className='mt-2'
                name='is_private'
                label='Private Venue'
                checked={venue.is_private || false}
                onChange={(e) => this.updateVenue({ is_private: e.target.checked })}
                disabled={this.props.disabled}
              />
            )}
          </div>

          <div className='rounded border p-3 mb-3 bg-light'>
            <h6>Address</h6>
            <AddressForm
              address={venue.address}
              onChange={(a) => this.updateVenue({ address: a })}
              disabled={this.props.disabled}
            />
          </div>

          <div className='row mb-3'>
            <div className='col-12 col-md-6'>
              <FormField
                label='Capacity'
                type='number'
                name='capacity'
                value={venue.capacity || ''}
                onChange={(e) => this.updateVenue({ capacity: e.target.value })}
                disabled={this.props.disabled}
              />
            </div>

            <div className='col-12 col-md-6 mb-3'>
              <label className='form-label'>Main Phone</label>
              <PhoneInput
                name='main_phone'
                value={venue.main_phone || ''}
                onChange={(v) => this.updateVenue({ main_phone: v })}
                disabled={this.props.disabled}
              />
            </div>

            <div className='col-12 col-md-6 mb-3'>
              <label className='form-label'>Box Office Phone</label>
              <PhoneInput
                name='box_office_phone'
                value={venue.box_office_phone || ''}
                onChange={(v) => this.updateVenue({ box_office_phone: v })}
                disabled={this.props.disabled}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <FormField
                label='Wifi'
                type='text'
                name='wifi'
                value={venue.wifi || ''}
                onChange={(e) => this.updateVenue({ wifi: e.target.value })}
                disabled={this.props.disabled}
              />
            </div>
            <div className='col'>
              <FormField
                label='Wifi-Password'
                type='text'
                name='wifi_password'
                value={venue.wifi_password || ''}
                onChange={(e) => this.updateVenue({ wifi_password: e.target.value })}
                disabled={this.props.disabled}
              />
            </div>
          </div>
        </div>

        <div className='col-lg-6 col-12'>
          <div className='mb-3'>
            <FormField
              type='textarea'
              label='Parking'
              name='parking'
              value={venue.parking || ''}
              onChange={(e) => this.updateVenue({ parking: e.target.value })}
              disabled={this.props.disabled}
            />
          </div>

          <div className='mb-3'>
            <FormField
              type='textarea'
              label='Load-In Info'
              name='load_in_info'
              value={venue.load_in_info || ''}
              onChange={(e) => this.updateVenue({ load_in_info: e.target.value })}
              disabled={this.props.disabled}
            />
          </div>

          <div className='mb-3'>
            <FormField
              label='Green Room Info'
              type='textarea'
              name='green_room_info'
              value={venue.green_room_info || ''}
              onChange={(e) => this.updateVenue({ green_room_info: e.target.value })}
              disabled={this.props.disabled}
            />
          </div>

          <div className='border rounded p-3 bg-light'>
            <h6>Amenities</h6>
            <FormCheck
              name='has_showers'
              label='Showers'
              checked={venue.has_showers || false}
              onChange={(e) => this.updateVenue({ has_showers: e.target.checked })}
              disabled={this.props.disabled}
            />

            <FormCheck
              name='has_laundry'
              label='Laundry'
              checked={venue.has_laundry || false}
              onChange={(e) => this.updateVenue({ has_laundry: e.target.checked })}
              disabled={this.props.disabled}
            />
          </div>
        </div>
      </div>
    )
  }
}
