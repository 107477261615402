import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import Linkify from 'react-linkify'

import ShowAddress from 'components/addresses/ShowAddress'

import Row from 'components/Row'
import PageTitle from 'components/PageTitle'
import LodgingGigPanel from 'components/lodgings/LodgingGigPanel'
import AssociatedGig from 'components/lodgings/AssociatedGig'
import LodgingBottomBar from 'components/lodgings/LodgingBottomBar'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getLodging } from 'actions/api/lodgings'

class LodgingPage extends React.Component {

  componentWillMount() {
    this.props.getLodging(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      props.getLodging(props.match.params.id)
    }
  }

  render() {
    const lodging = this.props.lodging || {}
    const band = lodging.band || {}
    const address = lodging.address || {}
    const associated_gigs = lodging.associated_gigs || []
    const sorted_gigs = _.sortBy(associated_gigs, (g) => g.show_date)
    return (
      <>
        <div className='container mt-2'>
          <PageTitle
            is_editable={lodging.is_editable}
            edit_link={`${lodging.url}/edit`}
            subtitle={(
              <h5>Housing ({ band.name })</h5>
            )}
          >
            {lodging.name}
          </PageTitle>

          <div className='row'>
            <div className='col-12 col-lg-6 mb-3'>
              <h4>Details</h4>
              <div className='border rounded p-3 bg-light'>
                <a href={address.maps_url} target="_blank" className='d-block mb-2'>
                  <ShowAddress address={address} />
                </a>

                <div className='row'>
                  <div className='col'> Check In </div>
                  <div className='col'> Check Out </div>
                </div>

                <strong>
                  <div className='row mb-2'>
                    <div className='col'> {moment(lodging.check_in_date).format('ddd, MMM Do')} </div>
                    <div className='col'> {moment(lodging.check_out_date).format('ddd, MMM Do')} </div>
                  </div>
                </strong>
              </div>

            </div>

            {lodging.notes && (
              <div className='col-12 col-md-6 mb-3'>
                <h4>Notes</h4>
                <Linkify properties={{ target: '_blank' }}>
                  <div className='prewrap border rounded p-3 bg-light'>
                    {lodging.notes}
                  </div>
                </Linkify>
              </div>
            )}
          </div>

          {associated_gigs.length > 0 && (
            <div>
              <h4>Associated Gigs</h4>
              <div className='row'>
                {_.map(sorted_gigs, (g) => (
                  <div key={g.id} className='col-12 col-md-6 mb-3'>
                    <div className='border rounded p-3 bg-light'>
                      <AssociatedGig gig={g} lodging={lodging} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <LodgingBottomBar lodging={lodging} />
      </>
    )
  }

}

export default connect((state, props) => {
  return {
    lodging: selectModel('lodgings', props.match.params.id, Resources.lodging, state)
  }
}, (dispatch) => bindActionCreators({
  getLodging,
}, dispatch))(LodgingPage)
