import { resourceRSAA } from 'utils'

export const GUEST_LIST_ACTION = 'GUEST_LIST'

export function getGuestListSpots(id) {
  return resourceRSAA(GUEST_LIST_ACTION, `/api/performances/${id}/guest_list_spots`)
}

export function updateGuestListSpot(id, data) {
  return resourceRSAA(GUEST_LIST_ACTION, `/api/guest_list_spots/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ guest_list_spot: data }),
  })
}

export function createGuestListSpot(id, data) {
  return resourceRSAA(GUEST_LIST_ACTION, `/api/performances/${id}/guest_list_spots`, {
    method: 'POST',
    body: JSON.stringify({ guest_list_spot: data }),
  })
}

export function deleteGuestListSpot(id) {
  return resourceRSAA(GUEST_LIST_ACTION, `/api/guest_list_spots/${id}`, { method: 'DELETE' })
}

export function updateGuestList(id, gls_data) {
  return resourceRSAA(GUEST_LIST_ACTION, `/api/performances/${id}/guest_list_spots`, {
    method: 'PUT',
    body: JSON.stringify({
      performance: { guest_list_spots: gls_data }
    }),
  })
}
