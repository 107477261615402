import { resourceRSAA } from 'utils'

export const FINANCIAL_DOCUMENT_ACTION = 'FINANCIAL_DOCUMENT'

export function deleteFinancialDocument(gig_id, id) {
  return resourceRSAA(FINANCIAL_DOCUMENT_ACTION, `/api/gigs/${gig_id}/financial_documents/${id}`, { method: 'DELETE' })
}

export function updateFinancialDocument(gig_id, id, data) {
  return resourceRSAA(FINANCIAL_DOCUMENT_ACTION, `/api/gigs/${gig_id}/financial_documents/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ financial_document: data }),
  })
}

export function createFinancialDocument(gig_id, data) {
  return resourceRSAA(FINANCIAL_DOCUMENT_ACTION, `/api/gigs/${gig_id}/financial_documents`, {
    method: 'POST',
    body: JSON.stringify({ financial_document: data })
  })
}
