import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createBand } from 'actions/api/bands'

import SaveBar from 'components/SaveBar'
import ErrorLabel from 'components/ErrorLabel'
import LoadingState from 'components/LoadingState'

class NewBandForm extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      band: props.band || {},
    }
  }

  updateBand(update) {
    this.setState({
      band: {
        ...this.state.band,
        ...update,
      }
    })
  }

  submit() {
    this.setLoading(() => {
      this.props.createBand(this.state.band).then((response) => {
        this.clearLoading(() => {
          if (!response.error) {
            this.props.history.push(`/bands/${response.payload.result}/edit`)
          }
        })
      })
    })
  }

  render() {
    return (
      <>
        <div className="container pt-3">
          <div>
            <h2>
              New Band
            </h2>
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-6'>
              <label>
                Band Name
                <ErrorLabel
                  field='name'
                  errors={this.props.api_errors}
                />
                <input
                  className="form-control"
                  type='text'
                  name='name'
                  value={this.state.band.name}
                  onChange={(e) => this.updateBand({ name: e.target.value })}
                />
              </label>
            </div>

            <div className='col-sm-12 col-md-6'>
              <label>
                Band Username
                <ErrorLabel
                  field='username'
                  errors={this.props.api_errors}
                />
                <input
                  className="form-control"
                  type='text'
                  name='name'
                  value={this.state.band.username}
                  onChange={(e) => this.updateBand({ username: e.target.value })}
                />
              </label>
            </div>
          </div>
        </div>
        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
        />
      </>
    )
  }
}


export default connect((state) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  createBand
}, dispatch))(NewBandForm)
