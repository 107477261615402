import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import SafeLink from 'components/SafeLink'
import PaginatedList from 'components/PaginatedList'
import AdminHeader from 'components/admin/AdminHeader'
import AdminHomeNav from 'components/admin/AdminHomeNav'

export default class BasicListPage extends PaginatedList {

  getListFromData(data) {
    return data.data
  }

  getItemUrl(url) {
    return url
  }

  onSubmitRequest() {
    if (this.props.history && this.props.location) {
      this.props.history.replace(`${this.props.location.pathname}?page=${this.state.page}`)
    }
  }

  // Override
  getPageName() {
    throw "Please implement getPageName() on BasicListPage"
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <AdminHeader />
          <AdminHomeNav page={this.getPageName()} />

          <div className="d-flex justify-content-center mt-2">
            {this.renderPageButtons()}
          </div>

          <table className='table table-striped hover'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>User Name</th>
                <th>Create Date</th>
              </tr>
            </thead>

            <tbody>
              {_.map(this.state.list, (o) => (
                <tr key={o.id}>
                  <td> {o.id} </td>
                  <td> <SafeLink to={this.getItemUrl(o.url)}>{o.name}</SafeLink> </td>
                  <td> {o.username} </td>
                  <td> {moment(o.created_at).fromNow()} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}
