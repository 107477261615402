import _ from 'lodash'

export function isScheduleApprovalEnabledForGig(gig = {}) {
  const headlineBandId = gig?.performances?.find(perf => perf.performance_type === 'headline')?.id
  const hasOptedInHeadlineBand = headlineBandId && window.schedule_approval_band_ids?.includes(headlineBandId)

  const venueId = gig?.location?.venue_id
  const hasOptedInVenue = venueId && window.schedule_approval_venue_ids?.includes(venueId)

  return (hasOptedInHeadlineBand || hasOptedInVenue)
}

export const computePartiesYetToApprove = gig => {
  const partiesYetToApprove = []

  // gather any headlining performances that haven't yet approved the schedule
  gig.performances.forEach(perf => {
    if (perf.performance_type === 'headline' && !perf.schedule_approved && perf.is_owned) {
      partiesYetToApprove.push(perf.band_name)
    }
  })

  // also gather the location if it hasn't approved the schedule
  if (!gig.location.schedule_approved && gig.location.is_owned) {
    partiesYetToApprove.push(gig.location.venue_name)
  }

  return partiesYetToApprove
}
