import {
  DISMISS_SHARE_ADVANCE_OVERLAY,
  SET_SHARE_ADVANCE_OVERLAY
} from 'actions/ui'

export default (state={}, action) => {
  if (action.type.match(DISMISS_SHARE_ADVANCE_OVERLAY)) {
    return {}
  }

  if (action.type.match(SET_SHARE_ADVANCE_OVERLAY) && action.payload) {
    return { ...action.payload }
  }

  return state
}
