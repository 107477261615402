import React from 'react'
import Linkify from 'react-linkify'

import EditableField from 'components/EditableField'

export default class EditableTextArea extends EditableField {

  getValueFromProps(props) {
    return props.value || ''
  }

  getPostData() {
    return { [this.props.post_key]: this.state.value }
  }

  render() {
    if (!this.state.value && !this.props.is_editable) {
      if (this.props.show_empty) {
        return (
          <>
            <h5>{this.props.title}</h5>
            <div className='border rounded p-3 bg-light text-center'>
              <strong>No {this.props.title}</strong>
            </div>
          </>
        )
      }
      return null
    }

    if (this.state.is_editing && this.props.is_editable) {
      return (
        <>
          <h5>
            {this.props.title}
            <a
              key='save'
              onClick={() => this.save()}
            >
              <i className='fi-check icon-base ms-2 success-color'></i>
            </a>
          </h5>

          <textarea
            className='form-control w-100 mb-3'
            ref={(ref) => this.input = ref}
            value={this.state.value}
            placeholder={this.props.placeholder}
            onChange={(e) => this.onChange(e.target.value)}
            onBlur={(e) => this.onBlur(e.target.value)}
          />
        </>
      )
    } else {
      return (
        <>
          <h5>
            {this.props.title}
            {this.state.value && this.props.is_editable && (
              <a
                key='edit'
                onClick={() => this.onEdit()}
              ><i className='ms-2 smaller-text edit-icon fi-pencil'></i></a>
            )}
          </h5>
          {this.state.value && (
            <div className='rounded bg-light border p-3 mb-3'>
              <Linkify properties={{ target: '_blank' }}>
                <p className='prewrap mb-0'>{this.state.value}</p>
              </Linkify>
            </div>
          )}
          {!this.state.value && (
            <a
              onClick={() => this.onEdit()}
              className='btn rounded bg-light border hover-bg-gray text-center d-block p-3 mb-3 text-decoration-none text-dark'>
              <h5 className='m-0'>
                <i className='fi-plus'></i> Add {this.props.title}
              </h5>
            </a>
          )}
        </>
      )
    }
  }
}
