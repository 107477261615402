import React from 'react'
import _ from 'lodash'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getClaimableOrgs, claimOrg } from 'actions/api/users'

import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import ErrorLabel from 'components/ErrorLabel'

class ClaimOrganizations extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      orgs: [],
      selected_org: null,
      username: '',
      is_loading: false,
    }
  }

  componentWillMount() {
    this.props.getClaimableOrgs().then((res) => {
      if (!res.error) {
        const orgs = res.payload.orgs || []
        if (_.isEmpty(orgs)) {
          this.props.history.push('/user/profile?tutorial=true')
        } else {
          this.setState({ orgs: orgs })
        }
      }
    })
  }

  claimOrg() {
    const org_data = {
      id: this.state.selected_org.id,
      type: this.state.selected_org.joinable_type,
      username: this.state.username,
    }
    this.setState({ is_loading: true }, () => {
      this.props.claimOrg(org_data).then((res) => {
        if (res.error) {
          this.setState({ is_loading: false })
        } else {
          const orgs = res.payload.orgs || []

          if (_.isEmpty(orgs)) {
            this.props.history.push('/user/profile?tutorial=true')
          } else {
            this.setState({
              orgs: orgs,
              selected_org: null,
              username: '',
              is_loading: false,
            })
          }
        }
      })
    })
  }

  renderClaimForm() {
    const selected_org = this.state.selected_org
    return (
      <div className='card p-3 mb-3'>
        <h4>Claim {selected_org.joinable_type}</h4>

        <label>
          {selected_org.joinable_type} Name
          <input
            className="form-control mb-3"
            type='text'
            value={selected_org.name}
            disabled
          />
        </label>

        <label>
          {selected_org.joinable_type} Username
          <ErrorLabel
            field='username'
            errors={this.props.api_errors}
          />
          <input
            className="form-control mb-3"
            type='text'
            value={this.state.username}
            onChange={(e) => this.setState({ username: e.target.value })}
          />
        </label>

        <div>
          <a
            className='m-0 btn btn-primary me-3'
            onClick={() => this.claimOrg()}
            disabled={this.state.is_loading}
          >claim</a>
          <a
            className='m-0 btn btn-secondary'
            onClick={() => this.setState({ selected_org: null })}
          >cancel</a>
        </div>
      </div>
    )
  }

  renderOrgs() {
    const orgs = this.state.orgs || []
    return (
      <div className='button-list gray-list mb-3'>
        {_.map(orgs, (org) => (
          <a
            className='item hover'
            onClick={() => this.setState({ selected_org: org })}
            key={`${org.joinable_type}-${org.id}`}
          >
            <strong>{org.name}</strong> ({org.joinable_type})
          </a>
        ))}
      </div>
    )
  }

  render() {
    return (
      <div className="container p-2">
        <PageTitle>
          Claim Connected Organizations
        </PageTitle>

        <p className="mt-2">
          We've found at least one band or venue that you are associated with that is not currently being managed by anyone.  Click below to claim any or all of these organizations!
        </p>

        <div className='row'>
          <div className='col-12 col-md-6'>
            {!this.state.selected_org && this.renderOrgs()}
            {this.state.selected_org && this.renderClaimForm()}
          </div>
        </div>

        <SafeLink
          to='/user/profile?tutorial=true'
          className='btn btn-primary'
        >
          continue &gt;
        </SafeLink>
      </div>
    )
  }
}


export default connect((state, props) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  claimOrg, getClaimableOrgs
}, dispatch))(withRouter(ClaimOrganizations))
