import { resourceRSAA } from 'utils'

export const ADMIN_USER_ACTION = 'ADMIN//USER'
export function adminGetUsers(page) {
  return resourceRSAA(ADMIN_USER_ACTION, `/api/admin/users?page=${page}`)
}

export function adminGetUser(id) {
  return resourceRSAA(ADMIN_USER_ACTION, `/api/admin/users/${id}`)
}

export function adminUpdateUser(id, data) {
  return resourceRSAA(ADMIN_USER_ACTION, `/api/admin/users/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ user: data }),
  })
}

export function adminDeleteUser(id) {
  return resourceRSAA(ADMIN_USER_ACTION, `/api/admin/users/${id}`, { method: 'DELETE' })
}
