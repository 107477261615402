import { resourceRSAA } from 'utils'

export const CREATE_TICKET_INPUTTER_ACTION = 'CREATE_TICKET_INPUTTER'
export function createTicketInputter(gigId, d) {
  const path = `/api/ticket_inputters`
  const data = { ...d, gig_id: gigId }
  return resourceRSAA(CREATE_TICKET_INPUTTER_ACTION, path, {
   method: 'POST',
   body: JSON.stringify({ ticket_inputter: data }),
  })
}

export const GET_TICKET_INPUTTERS_ACTION = 'GET_TICKET_INPUTTERS'
export function getTicketInputters(gigId) {
  const path = `/api/ticket_inputters?gig_id=${gigId}`
  return resourceRSAA(GET_TICKET_INPUTTERS_ACTION, path)
}

export const DESTROY_TICKET_INPUTTER_ACTION = 'DESTROY_TICKET_INPUTTER'
export function destroyTicketInputter(id) {
  const path = `/api/ticket_inputters/${id}`
  return resourceRSAA(DESTROY_TICKET_INPUTTER_ACTION, path, {
    method: 'DELETE',
  })
}
