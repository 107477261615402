import React from 'react'
import moment from 'moment'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createGig } from 'actions/api/gigs'

import DateInput from 'components/DateInput'
import LoadingState from 'components/LoadingState'
import Selector from 'components/Selector'

import NoBandOrVenueNotice from 'components/gigs/NoBandOrVenueNotice'

import {
  DATE_FORMAT,
  PERSPECTIVE_TYPE,
  PERFORMANCE_TYPES,
  PERFORMANCE_TYPE_LABEL
} from 'static'

import { iff } from 'utils'

const SEPARATOR = "~~"

class NewGigForm extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      gig: {
        show_date: moment().format(DATE_FORMAT),
        organization: this.getOrganizationFromProps(props),
        performance_type: PERFORMANCE_TYPES.HEADLINE,
      }
    }
  }

  componentWillReceiveProps(props) {
    if (_.isEmpty(this.state.gig.organization)) {
      this.setState({
        gig: {
          ...this.state.gig,
          organization: this.getOrganizationFromProps(props),
        }
      })
    }
  }

  isBandSelected() {
    const org = this.state.gig.organization.split(SEPARATOR)
    return org[0] === PERSPECTIVE_TYPE.BAND
  }

  isVenueSelected() {
    const org = this.state.gig.organization.split(SEPARATOR)
    return org[0] === PERSPECTIVE_TYPE.VENUE
  }

  getSelectedOrg() {
    const org = this.state.gig.organization.split(SEPARATOR)
    const bands = _.get(this, 'props.user.create_gig_orgs.bands', [])
    const venues = _.get(this, 'props.user.create_gig_orgs.venues', [])
    if (org[0] === PERSPECTIVE_TYPE.VENUE) {
      return _.find(venues, { id: parseInt(org[1], 10) }) || {}
    } else if (org[0] === PERSPECTIVE_TYPE.BAND) {
      return _.find(bands, { id: parseInt(org[1], 10) }) || {}
    }
    return {}
  }

  hasScheduleTemplates() {
    const schedule_templates = _.get(this.getSelectedOrg(), 'schedule_templates', [])
    return schedule_templates?.length > 0
  }

  getScheduleTemplateOptions() {
    const schedule_templates = _.get(this.getSelectedOrg(), 'schedule_templates', [])
    const template_options = _.map(schedule_templates, (st) => ({ label: st.name, value: st.id }))
    return [
      { label: '---', value: '' },
      ...template_options,
    ]
  }

  getOrganizationFromProps(props) {
    const {
      create_gig_orgs: {
        bands,
        venues,
      }
    } = props.user

    let first_band = _.first(bands)
    let first_venue = _.first(venues)
    let first_key = ''

    if (first_band) {
      first_key = `${PERSPECTIVE_TYPE.BAND}${SEPARATOR}${first_band.id}`
    } else if (first_venue) {
      first_key = `${PERSPECTIVE_TYPE.VENUE}${SEPARATOR}${first_venue.id}`
    }
    return props.org || first_key
  }

  submit(e) {
    if (!_.isEmpty(this.state.gig.organization)) {
      const org = this.state.gig.organization.split(SEPARATOR)
      this.setLoading(() => {
        this.props.createGig({
          show_date: this.state.gig.show_date,
          org_type: org[0],
          org_id: org[1],
          performance_type: this.state.gig.performance_type,
          schedule_template_id: this.state.gig.schedule_template_id,
        }).then((response) => {
          if (response.error) {
            this.clearLoading()
          } else {
            this.props.history.push(`/gigs/${response.payload.result}`)
          }
        })
      })
    }

    if (e) { e.preventDefault() }
  }

  updateGig(obj, callback) {
    this.setState({
      gig: {
        ...this.state.gig,
        ...obj
      }
    }, () => iff(callback))
  }

  updateOrganization(v) {
    this.updateGig({ organization: v }, () => {
      iff(this.props.onUpdateOrganization, v)
    })
  }

  renderForm(disabled) {
    const {
      create_gig_orgs: {
        bands,
        venues,
      }
    } = this.props.user

    const band_options = _.map(bands, (b) => ({ label: b.name, value: `${PERSPECTIVE_TYPE.BAND}${SEPARATOR}${b.id}` }))
    const venue_options = _.map(venues, (v) => ({ label: v.name, value: `${PERSPECTIVE_TYPE.VENUE}${SEPARATOR}${v.id}` }))
    const org_options = [...band_options, ...venue_options]

    return (
      <form onSubmit={(e) => this.submit(e)}>

        <div className='row'>
          <div className='col-12 col-md-6 mb-3'>
            <label className='form-label'>Create as</label>
            <Selector
              classes='form-select'
              value={this.state.gig.organization}
              onChange={(v) => this.updateOrganization(v)}
              disabled={this.state.is_loading || disabled}
              options={org_options}
            />
          </div>

          <div className='col-12 col-md-6 mb-3'>
            <label className='form-label'>Show Date</label>
            <DateInput
              name='show_date'
              value={this.state.gig.show_date || ''}
              onChange={(v) => this.updateGig({ show_date: v })}
              disabled={this.state.is_loading || disabled}
            />
          </div>

          {this.isBandSelected() && (
            <div className='col-12 col-md-6 mb-3'>
              <label className='form-label'>Performance Type</label>
              <Selector
                name='performance_type'
                classes="form-select"
                value={this.state.gig.performance_type || 0}
                onChange={(v) => this.updateGig({ performance_type: v })}
                disabled={this.state.is_loading}
                options={_.map(PERFORMANCE_TYPES, (pt) => ({ label: PERFORMANCE_TYPE_LABEL[pt], value: pt }))}
              />
            </div>
          )}
          {this.hasScheduleTemplates() && (
            <div className='col-12 col-md-6 mb-3'>
              <label className='form-label'>Schedule Template</label>
              <Selector
                name='schedule_template'
                classes="form-select"
                value={this.state.gig.schedule_template_id || 0}
                onChange={(v) => this.updateGig({ schedule_template_id: v })}
                disabled={this.state.is_loading}
                options={this.getScheduleTemplateOptions()}
              />
            </div>
          )}
        </div>

        <div className='mb-3'>
          <input
            className='btn btn-primary me-2'
            type='submit'
            value='create'
            disabled={this.state.is_loading || _.isEmpty(this.state.gig.organization) || disabled}
          />
          <a
            onClick={() => this.props.history.goBack()}
            className='btn btn-gray'
          >cancel</a>
        </div>
      </form>
    )
  }

  render() {
    const {
      create_gig_orgs: {
        bands,
        venues,
      }
    } = this.props.user

    const is_empty = _.isEmpty(bands) && _.isEmpty(venues)
    return (
      <>
        {is_empty && <NoBandOrVenueNotice />}
        {this.renderForm(is_empty)}
      </>
    )
  }
}


export default connect((state, props) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  createGig
}, dispatch))(withRouter(NewGigForm))
