import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { adminGetUsers } from 'actions/api/admin/users'

import SafeLink from 'components/SafeLink'
import PaginatedList from 'components/PaginatedList'
import AdminHeader from 'components/admin/AdminHeader'
import AdminHomeNav from 'components/admin/AdminHomeNav'

class UsersPage extends PaginatedList {

  requestListData(page) {
    return this.props.adminGetUsers(page)
  }

  onSubmitRequest() {
    if (this.props.history && this.props.location) {
      this.props.history.replace(`${this.props.location.pathname}?page=${this.state.page}`)
    }
  }

  getListFromData(data) {
    return data.data
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <AdminHeader />
          <AdminHomeNav page='users' />

          <div className="d-flex justify-content-center mt-2">
            {this.renderPageButtons()}
          </div>

          <table className='table table-striped hover'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Create Date</th>
              </tr>
            </thead>

            <tbody>
              {_.map(this.state.list, (u) => (
                <tr key={u.id}>
                  <td> {u.id} </td>
                  <td> <SafeLink to={`/admin${u.person_url}`}>{u.person_name}</SafeLink> </td>
                  <td> {u.username} </td>
                  <td> {u.email} </td>
                  <td> {moment(u.created_at).fromNow()} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}


export default connect(null, (dispatch) => bindActionCreators({
  adminGetUsers
}, dispatch))(withRouter(UsersPage))
