import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { selectModel } from 'selectors'
import { getGigTicketCounts } from 'actions/api/gigs'
import { Resources } from 'schema'

import BreadCrumbs from 'components/BreadCrumbs'
import SafeLink from 'components/SafeLink'
import ManageTicketInputters from 'components/ticket_count/ManageTicketInputters'
import TicketCountChart from 'components/ticket_count/TicketCountChart'
import GigTicketCountsTable from 'components/ticket_count/GigTicketCountsTable'
import FinancialDocumentsList from 'components/documents/FinancialDocumentsList'

const CHART = 'chart'
const TABLE = 'table'

const GigTicketCountsPage = ({ getGigTicketCounts, gig, match }) => {
  const { params } = match || {}
  const { id: gigId } = params || {}

  const gigTicketCounts = Object.values(_.get(gig, 'ticket_counts', [])) || []
  const gigName = gig.gig_name || gig.default_name
  const dateFormat = 'MMMM Do YYYY'
  const showDate = moment(gig.show_date).format(dateFormat)
  const isShowPast = moment(gig.show_date).isSameOrBefore(moment(), 'day')
  const onSaleDate = moment(gig.onSaleDate).format(dateFormat)
  const venueCapacity = _.get(gig, 'location.venue.capacity')
  const latestCount = _.last(gigTicketCounts)

  const [chartOrTable, setChartOrTable] = useState(CHART)

  useEffect(() => {
    getGigTicketCounts(gigId)
  }, [gigId])

  return (
    <div className='container mb-4'>
      <BreadCrumbs
        items={[
          { name: 'Shows', url: '/' },
          { name: gigName, url: gig.url },
          { name: 'Ticket Count Summary' },
        ]}
      />

      <h2>Ticket Count Summary</h2>
      <h4 className='mb-3'>{showDate}&nbsp;&ndash;&nbsp;{gigName}</h4>

      {!_.isNil(latestCount) && (
        <div className="border rounded p-3 bg-light mb-3">
          <div className='row'>
            <SummaryItem label="Count Date" value={moment(latestCount.date).format(dateFormat)} />
            <SummaryItem label="On Sale Date" value={onSaleDate} />
            <SummaryItem label="Days Out" value={gig.daysOut || '-'} />
            <SummaryItem label="Sellable" value={latestCount.total_sellable} />
            <SummaryItem label="Total Sold" value={latestCount.total_sold} />
            <SummaryItem label="Pct. Sold" value={latestCount.percent_sold} />
            <SummaryItem label="Open" value={latestCount.open} />
            <SummaryItem label="Wrap" value={latestCount.wrap} />
            <SummaryItem label="Gross Sales" value={latestCount.gross_sales} />
          </div>
        </div>
      )}

      {_.isEmpty(gigTicketCounts) && (
        <div className='border rounded p-3 mb-3 bg-light text-center'>
          This show currently has no ticket count information.
        </div>
      )}


      {!_.isEmpty(gigTicketCounts) && (
        <div className='row mb-3'>
          <div className='col text-start'>
            <ul className='nav nav-pills'>
              <li className='nav-item'>
                <a
                  onClick={() => setChartOrTable(CHART)}
                  className={classnames('nav-link', 'pointer', { 'active': chartOrTable === CHART })}
                >Graph</a>
              </li>
              <li className='nav-item'>
                <a
                  onClick={() => setChartOrTable(TABLE)}
                  className={classnames('nav-link', 'pointer', { 'active': chartOrTable === TABLE })}
                >Table</a>
              </li>
            </ul>
          </div>
          <div className='col text-end'>
            <a
              className="btn btn-gray"
              href={`/gigs/${gigId}/ticket_counts/csv`}
              target="_blank"
            >Export CSV</a>
          </div>
        </div>
      )}

      {!_.isEmpty(gigTicketCounts) && chartOrTable === CHART && (
        <div className='mb-3'>
          <TicketCountChart
            counts={[...gigTicketCounts]}
            yAxis={{ min: 0, max: venueCapacity }}
            xAxis={gig.chart_x_axis}
          />
        </div>
      )}

      {!_.isEmpty(gigTicketCounts) && chartOrTable === TABLE && (
        <div className='mb-3'>
          <GigTicketCountsTable
            reversedTicketCounts={_.reverse([...gigTicketCounts])}
            gig={gig}
          />
        </div>
      )}

      {gigId && (
        <>

          {gigTicketCounts?.length > 0 ? (
            <>
              <SafeLink
                className="btn btn-primary mb-3 me-2"
                to={`${gig.url}/ticket_counts/input`}
              >Input Ticket Counts</SafeLink>
              <SafeLink
                className="btn btn-secondary mb-3"
                to={`${gig.url}/ticket_counts/edit`}
              >Edit Ticket Counts</SafeLink>
            </>
          ) : (
            <SafeLink
              className="btn btn-primary mb-3"
              to={`${gig.url}/ticket_counts/input`}
            >Setup Ticket Counts</SafeLink>
          )}

          <hr className='mb-3' />

          <div className='row'>
            <div className='col-12 col-lg-6 mb-3'>
              <div className='border rounded p-3 mb-3 bg-light'>
                <h5>Ticket Contacts</h5>

                {_.isEmpty(gigTicketCounts) ? (
                  <div className='alert alert-primary'>
                    <p>
                      The people listed here will receive an email every Monday, Wednesday, and Friday with a link to an
                      authorized form where they can input the latest ticket counts.  They will automatically start receiving
                      these emails on the "On Sale Date", and stop receiving them once the show date has passed.
                    </p>
                    <p className='mb-0'>
                      Those counts will automatically populate on this page and be accessible to members of the
                      venue or the headlining band that have the "Ticket Count" permission.
                    </p>
                  </div>
                ) : (
                  <div className='mb-2'>
                    <i>
                      Ticket Contacts receive an email every Monday, Wednesday, and Friday reminding
                      them to input ticket counts for this show.  They will automatically start receiving
                      these emails on the "On Sale Date", and stop receiving them once the "Show Date" has passed.
                    </i>
                  </div>
                )}

                <ManageTicketInputters gigId={gigId} />
              </div>
            </div>

            {isShowPast && (
              <div className='col-12 col-lg-6 mb-3'>
                <div className='border rounded p-3 mb-3 bg-light'>
                  <h5>Ticket Documents</h5>
                  <div className='mb-2'>
                    <i>
                      Here you can add relevant ticketing and financial documents for future reference.
                      These will only be accessible to members of the venue or headlining band that have
                      the permissions to see Ticket Counts.
                    </i>
                  </div>
                  <FinancialDocumentsList gig={gig} />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

const SummaryItem = ({ label, value }) => {
  return (
    <>
      <div className="col-6 col-md-3 col-lg-2"><strong>{label}:</strong></div>
      <div className="col-6 col-md-3 col-lg-2">{value}</div>
    </>
  )
}

export default connect((state, props) => {
  const gigId = _.get(props, 'match.params.id')
  const gig = selectModel('gigs', gigId, Resources.gig, state) || {}

  return { gig }
}, (dispatch) => bindActionCreators({
  getGigTicketCounts,
}, dispatch))(GigTicketCountsPage)
