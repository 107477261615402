import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import SafeLink from 'components/SafeLink'

export default class BreadCrumbs extends React.Component {

  render() {
    return (
      <nav aria-label='breadcrumb' className='p-2 p-md-3 mt-2 mb-3 bg-light rounded'>
        <ol className='breadcrumb mb-0'>
          {_.map(_.get(this, 'props.items', []), (item, nx) => (
            <li
              className={classnames('breadcrumb-item', {
                'active': _.isNil(item.url),
              })}
              key={`breadcrumb-${item.name}-${nx}`}
            >
              {_.get(item, 'url', false) ? (
                <SafeLink className='text-decoration-none' to={item.url}>{item.name}</SafeLink>
              ) : item.name}
            </li>
          ))}
        </ol>
      </nav>
    )
  }

}
