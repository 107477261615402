import _ from 'lodash'
import React, { useState } from 'react'

import TicketCountEditArray from 'components/ticket_count/TicketCountEditArray'

const ErrorMessage = () => {
  return (
    <div className="alert alert-danger">
      Please add an updated count for each existing ticket type.
    </div>
  )
}

const AddUpdatedTicketCount = ({
  existingTicketTypes,
  createTicketCount,
  setIsSuccessful,
  isFinalCount,
  showNoteInput,
}) => {
  const [newTicketCountData, setNewTicketCountData] = useState([...existingTicketTypes])
  const [isFormInvalid, setIsFormInvalid] = useState(false)
  const [ticketCountNote, setTicketCountNote] = useState()

  /***
    * NOTE -- In this form we are assuming that `ticket_type_name` is unique
    * -- this allows us to do a find by `ticket_type_name` in our validation functions
   ***/
  const validateExistingTicketTypes = () => {
    return existingTicketTypes.every(ett => {
      const foundType = newTicketCountData.find(newData => newData.ticket_type_name === ett.ticket_type_name)
      const typeHasCount = !!foundType?.count
      return !!foundType && typeHasCount
    })
  }

  const validateNewTicketTypes = () => {
    const newTicketTypes = newTicketCountData.filter(data => !data.ticket_type_id)
    return newTicketTypes.every(ntt => {
      if (_.isEmpty(ntt.ticket_type_name)) return true

      const unique = !existingTicketTypes.map(ett => ett.ticket_type_name).includes(ntt.ticket_type_name)
      const hasSellable = !!ntt.total_sellable
      return unique && hasSellable
    })
  }

  const validateTicketCountData = () => {
    return validateExistingTicketTypes() && validateNewTicketTypes()
  }

  const submitCount = () => {
    setIsFormInvalid(false)
    if (!validateTicketCountData()) {
      setIsFormInvalid(true)
    } else {
      const filteredData = newTicketCountData.filter(data => !_.isNil(data.ticket_type_name) && !_.isEmpty(data.ticket_type_name))
      const formattedData = {
        ticket_types: filteredData.map(newData => ({
          ticket_type_id: newData.ticket_type_id,
          name: newData.ticket_type_name,
          total_sellable: newData.total_sellable,
          price: newData.price,
          count: parseInt(newData.count, 10) || 0,
        })),
        note: ticketCountNote,
      }

      createTicketCount(formattedData).then(res => {
        setIsSuccessful(res?.payload?.request_status === 200)
      })
    }
  }

  return (
    <>
      {isFormInvalid && <ErrorMessage />}
      {showNoteInput && (
        <div className="alert alert-light">
          <label htmlFor="count-note">Attach a note to this count (optional)</label>
          <input
            type="text"
            className="form-control mb-2"
            name="count-note"
            placeholder="Note"
            maxLength="21844"
            defaultValue={ticketCountNote}
            onChange={e => setTicketCountNote(e.target.value)}
          />
        </div>
      )}
      <TicketCountEditArray
        newTicketCountData={newTicketCountData}
        submitNewCount={() => submitCount()}
        onChange={(v, idx) => {
          const newData = [...newTicketCountData]
          newData[idx] = v

          setNewTicketCountData(newData)
        }}
        onAddEmptyTicketType={() => {
          const newData = [...newTicketCountData, {}]
          setNewTicketCountData(newData)
        }}
        onRemoveNewTicketType={idx => {
          const newData = [...newTicketCountData]
          newData.splice(idx, 1)
          setNewTicketCountData(newData)
        }}
        isFinalCount={isFinalCount}
      />
    </>
  )
}

export default AddUpdatedTicketCount
