import { resourceRSAA } from 'utils'

export const ADMIN_GIG_ACTION = 'ADMIN//GIG'
export function adminGetGigs(page) {
  return resourceRSAA(ADMIN_GIG_ACTION, `/api/admin/gigs?page=${page}`)
}

export function adminGetGig(id) {
  return resourceRSAA(ADMIN_GIG_ACTION, `/api/admin/gigs/${id}`)
}

export function adminUpdateGig(id, data) {
  return resourceRSAA(ADMIN_GIG_ACTION, `/api/admin/gigs/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ gig: data }),
  })
}
