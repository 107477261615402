import React from 'react'
import _ from 'lodash'

import { BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { selectCurrentUser, selectAuthContext } from 'selectors'
import { bindActionCreators } from 'redux'
import { authenticate } from 'actions/api/auth'

import NavigationBar from 'components/navigation/NavigationBar'
import ErrorOverlay from 'components/ErrorOverlay'
import InviteOverlay from 'components/invite/InviteOverlay'
import ShareAdvanceOverlay from 'components/ShareAdvanceOverlay'
import SaveScheduleOverlay from 'components/schedule_templates/SaveScheduleOverlay'
import ApplyScheduleOverlay from 'components/schedule_templates/ApplyScheduleOverlay'
import LinkScheduleOverlay from 'components/schedule/LinkScheduleOverlay'
import LoadingState from 'components/LoadingState'

import LoggedInRoutes from 'components/routes/LoggedInRoutes'
import LoggedOutRoutes from 'components/routes/LoggedOutRoutes'
import AdminRoutes from 'components/routes/AdminRoutes'
import AuthLinkRoutes from 'components/routes/AuthLinkRoutes'
import StaticRoutes from 'components/routes/StaticRoutes'
import { DISMISSED_ADVANCE_FLOATING_MESSAGE_KEY } from 'components/advance/AdvanceFloatingRegistration'

import { setUrlAuthToken } from 'actions/ui'
import { parseUrlParams } from 'utils'

class AppBase extends LoadingState {

  constructor(props) {
    super(props)

    const params = parseUrlParams(window.location.href).params
    if (!_.isNil(params.auth)) {
      props.setUrlAuthToken(params.auth)
    }
  }

  componentWillMount() {
    this.setLoading(() => {
      this.props.authenticate().then((res) => {
        this.clearLoading()
      })
    })
    sessionStorage.removeItem(DISMISSED_ADVANCE_FLOATING_MESSAGE_KEY)
  }

  render() {
    if (this.state.is_loading) { return this.renderLoadingBlock() }

    return (
      <BrowserRouter>
        <div>
          <NavigationBar user={this.props.user} />

          <StaticRoutes>
            <AdminRoutes user={this.props.user}>
              <LoggedInRoutes user={this.props.user}>
                <AuthLinkRoutes auth_context={this.props.auth_context}>
                  <LoggedOutRoutes auth_context={this.props.auth_context} />
                </AuthLinkRoutes>
              </LoggedInRoutes>
            </AdminRoutes>
          </StaticRoutes>

          <ErrorOverlay />
          <InviteOverlay />
          <ShareAdvanceOverlay />
          <SaveScheduleOverlay />
          <ApplyScheduleOverlay />
          <LinkScheduleOverlay />
        </div>
      </BrowserRouter>
    )
  }
}

export default connect((state) => ({
  auth_context: selectAuthContext(state),
  user: selectCurrentUser(state)
}), (dispatch) => bindActionCreators({
  authenticate,
  setUrlAuthToken
}, dispatch))(AppBase)
