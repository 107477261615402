import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createVenue } from 'actions/api/venues'

import SaveBar from 'components/SaveBar'
import FormField from 'components/FormField'
import LoadingState from 'components/LoadingState'

class VenueWelcomePage extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      venue: props.venue || {},
    }
  }

  updateVenue(update) {
    this.setState({
      venue: {
        ...this.state.venue,
        ...update,
      }
    })
  }

  submit() {
    this.setLoading(() => {
      this.props.createVenue(this.state.venue).then((response) => {
        this.clearLoading(() => {
          if (!response.error) {
            this.props.history.push(`/welcome/venues/${response.payload.result}/details`)
          }
        })
      })
    })
  }

  render() {
    return (
      <>
        <div className='container mt-3'>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-6'>
              <h2>Create a Venue</h2>

              <div className='mb-3'>
                <FormField
                  label='Venue Name'
                  type='text'
                  name='name'
                  errors={this.props.api_errors}
                  value={this.state.venue.name}
                  onChange={(e) => this.updateVenue({ name: e.target.value })}
                />
              </div>

              <div className='mb-3'>
                <FormField
                  label='Venue Username'
                  type='text'
                  name='username'
                  field='username'
                  errors={this.props.api_errors}
                  value={this.state.venue.username}
                  onChange={(e) => this.updateVenue({ username: e.target.value })}
                />
                <div className='form-text'>
                  Usernames must contain only of alphanumeric characters (A-Z/0-9), dashses, and underscores.
                </div>
              </div>


              <div className='alert alert-primary rounded d-flex flex-row align-items-center'>
                <div className='flex-grow-0'>
                  <i className='fi-info me-3 icon-medium'></i>
                </div>
                <div className='flex-grow-1'>
                  Your venue username is a unique indentifier that bands can use to add you to show advances.
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
          save_text="continue"
          hide_cancel
        />
      </>
    )
  }
}


export default connect((state, props) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  createVenue
}, dispatch))(VenueWelcomePage)
