import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { getGig } from 'actions/api/gigs'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { selectModel } from 'selectors'

import { PERSPECTIVE_TYPE } from 'static'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import EditableGuestList from 'components/guest_list/EditableGuestList'
import MasterGuestList from 'components/guest_list/MasterGuestList'

class GuestListPage extends React.Component {

  componentWillMount() {
    this.props.getGig(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id && this.props.match.params.id !== props.match.params.id) {
      props.getGig(props.match.params.id)
    }

    // Route generic guest list to specific routes
    if (props.location.pathname === `/gigs/${props.match.params.id}/guest_list`) {
      const gig = props.gig || {}
      const perspective = gig.perspective || {}
      if (perspective.type === PERSPECTIVE_TYPE.VENUE) {
        props.history.replace(`${gig.url}/guest_list/master`)
      } else if (perspective.type === PERSPECTIVE_TYPE.BAND) {
        const performances = gig.performances || []
        const performance = _.find(performances, (p) => p.band_id === perspective.id)
        props.history.replace(`${gig.url}/guest_list/${performance.id}`)
      }
    }
  }

  render() {
    const gig = this.props.gig || {}

    return (
      <>
        <Switch>
          <Route path='/gigs/:id/guest_list/master' render={() => (
            <MasterGuestList gig={gig} />
          )} />
          <Route path='/gigs/:id/guest_list/:performance_id' render={() => (
            <EditableGuestList
              performance_id={this.props.match.params.performance_id}
              perspective={gig.perspective}
            />
          )} />
        </Switch>
      </>
    )
  }

}

export default connect((state, ownProps) => ({
  gig: selectModel('gigs', ownProps.match.params.id, Resources.gig, state),
}), (dispatch) => bindActionCreators({
  getGig
}, dispatch))(withRouter(GuestListPage))
