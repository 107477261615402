
export const PERFORMANCE_TYPES = {
  HEADLINE: 'headline',
  SUPPORT: 'support',
}

export const PERFORMANCE_TYPE_LABEL = {
  [PERFORMANCE_TYPES.HEADLINE]: 'Headline',
  [PERFORMANCE_TYPES.SUPPORT]: 'Support',
}

export const GUEST_TYPES = [
  'GA',
  'VIP',
  'Press',
  'Photo',
  'Guest Musician',
]

export const MAX_NUM_TICKETS = 32767
export const MAX_NUM_COMPS = 32767
export const MAX_NUM_DAY_OF_SHOW = 32767
