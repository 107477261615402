import React from 'react'

import { Switch, Route } from 'react-router-dom'

import AdminSearchPage from 'components/admin/AdminSearchPage'

import AdminGigPage from 'components/admin/gigs/AdminGigPage'
import GigsPage from 'components/admin/gigs/GigsPage'
import AdminPerformancePage from 'components/admin/gigs/AdminPerformancePage'
import AdminLocationPage from 'components/admin/gigs/AdminLocationPage'

import BandPage from 'components/admin/bands/BandPage'
import BandsPage from 'components/admin/bands/BandsPage'
import EditBandPage from 'components/admin/bands/EditBandPage'

import VenuePage from 'components/admin/venues/VenuePage'
import VenuesPage from 'components/admin/venues/VenuesPage'
import EditVenuePage from 'components/admin/venues/EditVenuePage'

import EditableScheduleTemplate from 'components/schedule_templates/EditableScheduleTemplate'

import PeoplePage from 'components/admin/people/PeoplePage'
import AdminPersonPage from 'components/admin/people/AdminPersonPage'
import AdminEditPersonPage from 'components/admin/people/AdminEditPersonPage'

import UsersPage from 'components/admin/users/UsersPage'

export default class AdminRoutes extends React.Component {

  render() {
    if (!this.props.user || !this.props.user.id || !this.props.user.is_site_admin) {
      return this.props.children
    }

    return (
      <Switch>
        <Route exact path='/admin/search' component={AdminSearchPage} />

        <Route exact path='/admin/schedule_templates/:id' component={EditableScheduleTemplate} />

        <Route exact path='/admin/gigs/:id' component={AdminGigPage} />
        <Route exact path='/admin/gigs' component={GigsPage} />

        <Route exact path='/admin/performances/:id' component={AdminPerformancePage} />
        <Route exact path='/admin/locations/:id' component={AdminLocationPage} />

        <Route exact path='/admin/bands/:id/edit' component={EditBandPage} />
        <Route path='/admin/bands/:id' component={BandPage} />
        <Route exact path='/admin/bands' component={BandsPage} />

        <Route exact path='/admin/venues/:id/edit' component={EditVenuePage} />
        <Route path='/admin/venues/:id' component={VenuePage} />
        <Route exact path='/admin/venues' component={VenuesPage} />

        <Route exact path='/admin/users' component={UsersPage} />

        <Route exact path='/admin/people/:id/edit' component={AdminEditPersonPage} />
        <Route exact path='/admin/people/:id/:page' component={AdminPersonPage} />
        <Route exact path='/admin/people/:id' component={AdminPersonPage} />
        <Route exact path='/admin/people' component={PeoplePage} />

        <Route exact path='/admin' component={AdminSearchPage} />
        <Route render={() => this.props.children} />
      </Switch>
    )
  }
}
