import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TicketCountChart = ({ counts, title, xAxis, yAxis }) => {
  const formatCountDate = countDate => {
    const parts = countDate.split('-')
    if (parts.length >= 3) {
      return `${parts[1]}-${parts[2]}`
    }

    return 'Invalid Date'
  }

  const options = {
    responsive: true,
    spanGaps: true,
    scales: {
      y: {
        min: yAxis.min,
        max: yAxis.max,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: item => {
            const hoveredCount = counts.find(count => formatCountDate(count.date) === item.label)
            const perType = hoveredCount.counts.map(count => {
              return `${count.ticket_type_name}: ${count.count}`
            })
            const totalLine = `Total: ${item.formattedValue}`

            if (!_.isNil(hoveredCount.note)) {
              const noteLine = `Note: ${hoveredCount.note}`
              return [noteLine, totalLine, ...perType]
            }

            return [totalLine, ...perType]
          },
        }
      }
    },
  };

  const preparedData = xAxis?.map(dateString => {
    if (counts?.find(count => formatCountDate(count.date) === dateString)) {
      return counts?.find(count => formatCountDate(count.date) === dateString)?.total_sold
    } else {
      return null
    }
  })

  const data = {
    labels: xAxis,
    datasets: [
      {
        label: 'Ticket Counts',
        data: preparedData,
        borderColor: '#2ba6cd',
        backgroundColor: '#2ba6cd',
      }
    ],
  };

  return (
    <Line options={options} data={data} />
  )
}

export default TicketCountChart
