import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectApiList } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getUpcomingLodgings, getPastLodgings } from 'actions/api/lodgings'
import { withRouter } from 'react-router'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import LodgingListItem from 'components/lodgings/LodgingListItem'
import CachedPaginatedList from 'components/CachedPaginatedList'

import { DATE_FORMAT } from 'static'
import { parseUrlParams } from 'utils'

class LodgingsList extends CachedPaginatedList {

  componentWillMount() {
    this.handle_key_press = this.handleKeyPress.bind(this)
    document.addEventListener("keydown", this.handle_key_press);

    this.getListData()
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handle_key_press);
  }

  // Override
  requestListData(page) {
    if (this.isPast()) {
      return this.props.getPastLodgings(this.state.page)
    } else {
      return this.props.getUpcomingLodgings(this.state.page)
    }
  }

  onSubmitRequest(data) {
    if (this.props.history && this.props.location) {
      this.props.history.replace(`${this.props.location.pathname}?page=${this.state.page}`)
    }
  }

  componentWillReceiveProps(props) {
    if (this.isPast() !== this.isPast(props)) {
      this.resetList(props)
    }
  }

  handleKeyPress(event) {
    switch (event.key) {
      case 'ArrowLeft':
        if (this.hasPrevPage()) {
          event.preventDefault()
          this.prevPage()
        }
        break
      case 'ArrowRight':
        if (this.hasNextPage()) {
          event.preventDefault()
          this.nextPage()
        }
        break
    }
  }

  isPast(props=this.props) {
    return props.match.url === '/lodgings/past'
  }

  getLodgings() {
    let lodgings = this.props.lodgings || []
    lodgings = _.sortBy(lodgings, ['check_in_date'])
    if (this.isPast()) {
      lodgings = _.reverse(lodgings)
    }
    return lodgings
  }

  renderLodgings() {
    const sorted_lodgings = this.getLodgings()

    return (
      <div className='list-group list-group-striped mb-2'>
        {_.map(sorted_lodgings, (g) => (
          <LodgingListItem
            key={`homepage-lodging-${g.id}`}
            lodging={g}
          />
        ))}

        {!_.isEmpty(sorted_lodgings) && this.state.is_loading && (
          <div className='list-group-item'>
            <div className='text-center'> <i className='fi-clock'></i>&nbsp;Loading</div>
          </div>
        )}

        {_.isEmpty(sorted_lodgings) && !this.state.is_loading && (
          <div className='list-group-item'>
            <div className='text-center'>
              You have no accommodations.  Press the '<strong>+ New <i className='fi-home'></i></strong>' button to make a new one!
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const sorted_lodgings = this.getLodgings()

    return (
      <div className='container mt-2'>
        <h2>Housing</h2>

        <div className='d-flex flex-row mb-2'>
          <div className='flex-grow-1'>
            <ul className='nav nav-pills'>
              <li className='nav-item'>
                <SafeLink
                  to='/lodgings'
                  className={classnames('nav-link', { 'active': !this.isPast() })}
                >Upcoming</SafeLink>
              </li>
              <li className='nav-item'>
                <SafeLink
                  to='/lodgings/past'
                  className={classnames('nav-link', { 'active': this.isPast() })}
                >Past</SafeLink>
              </li>
            </ul>
          </div>

          <div className='flex-grow-1 text-end'>
            <SafeLink
              to="/new/lodging"
              className='btn btn-primary'
            >+ New <i className='fi-home'></i></SafeLink>
          </div>
        </div>

        {this.renderLodgings()}
        {this.renderPageButtons()}
        {!_.isEmpty(sorted_lodgings) && this.state.is_loading && this.renderLoadingPopup()}
      </div>
    )
  }

}

export default withRouter(connect((state, props) => {
  const is_past = props.match.url === '/lodgings/past'
  const list_key = is_past ? 'past_lodgings' : 'upcoming_lodgings'
  const page = parseUrlParams(props.location.search).params.page || 0
  const list = selectApiList(list_key, Resources.lodgings, page, state)

  return {
    lodgings: list.items,
    num_pages: list.num_pages,
  }
}, (dispatch) => bindActionCreators({
  getUpcomingLodgings,
  getPastLodgings,
}, dispatch))(LodgingsList))
