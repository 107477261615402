import { combineReducers } from 'redux'
import resourceReducer from 'reducers/api/resources'
import errorReducer from 'reducers/api/errors'
import serverErrorReducer from 'reducers/api/server_error'
import generateListReducer from 'reducers/api/list_reducer'

import { UPCOMING_GIGS_ACTION, PAST_GIGS_ACTION } from 'actions/api/gigs'
import { UPCOMING_LODGINGS_ACTION, PAST_LODGINGS_ACTION } from 'actions/api/lodgings'

export default combineReducers({
  resources: resourceReducer,
  errors: errorReducer,
  server_error: serverErrorReducer,
  upcoming_gigs: generateListReducer(UPCOMING_GIGS_ACTION, 'gigs'),
  past_gigs: generateListReducer(PAST_GIGS_ACTION, 'gigs'),
  upcoming_lodgings: generateListReducer(UPCOMING_LODGINGS_ACTION, 'lodgings'),
  past_lodgings: generateListReducer(PAST_LODGINGS_ACTION, 'lodgings'),
})
