import React from 'react'

export default class EditableField extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      is_editing: false,
      value: this.getValueFromProps(props),
    }
  }

  componentWillReceiveProps(props) {
    if (this.getValueFromProps(props) !== this.getValueFromProps(this.props)) {
      this.setState({
        value: this.getValueFromProps(props)
      })
    }
  }

  // Override
  getValueFromProps(props) {
    throw "EditableField must implement getValueFromProps()"
  }

  // Override
  getPostData() {
    throw "EditableField must implement getPostData()"
  }

  onEdit() {
    this.setState({ is_editing: true }, () => {
      this.input.focus()
    })
  }

  onBlur(val) {
    this.setState({ value: val }, () => this.save())
  }

  onChange(val) {
    this.setState({ value: val })
  }

  cancel(props=this.props) {
    this.setState({
      is_editing: false,
      value: this.getValueFromProps(props),
    })
  }

  save() {
    this.setState({ is_editing: false }, () => {
      if (this.props.update) {
        this.props.update(this.getPostData())
      }
    })
  }
}
