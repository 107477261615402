import React from 'react'
import _ from 'lodash'

import ShowGuestListSpot from 'components/guest_list/ShowGuestListSpot'

export default class RenderGuestList extends React.Component {

  renderMobile() {
    const guest_list_spots = _.get(this, 'props.guest_list_spots', [])
    return (
      <div className='list-group'>
        {_.map(guest_list_spots, (g, i) => (
          <ShowGuestListSpot
            key={g.id}
            guest_list_spot={g}
            show_notes={this.props.show_notes}
            is_mobile
          />
        ))}
        {_.isEmpty(guest_list_spots) && (
          <div className='list-group-item'>
            No guest list entered
          </div>
        )}
      </div>
    )
  }

  renderDesktop() {
    return (
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th width='60'># Tix</th>
            <th>Name</th>
            <th>Guest Type</th>
            {this.props.show_notes && (
              <th>Notes</th>
            )}
          </tr>
        </thead>
        <tbody>
          {_.map(this.props.guest_list_spots || [], (g, i) => (
            <ShowGuestListSpot
              key={g.id}
              guest_list_spot={g}
              show_notes={this.props.show_notes}
            />
          ))}
        </tbody>
      </table>
    )
  }

  render() {
    if (window.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }
}
