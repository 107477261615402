import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectCurrentUser } from 'selectors'
import { bindActionCreators } from 'redux'
import { updateCrewMember, deleteCrewMember } from 'actions/api/crew_members'

import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import OtherSelect from 'components/OtherSelect'
import InviteButton from 'components/invite/InviteButton'
import LoadingState from 'components/LoadingState'
import RadioButtons from 'components/RadioButtons'
import ShowEmail from 'components/people/ShowEmail'
import ShowPhone from 'components/people/ShowPhone'
import CrewMemberDetailsForm from 'components/crew_members/CrewMemberDetailsForm'

import {
  INVITE_STATUS,
  PERMISSIONS_OPTIONS
} from 'static'

class CrewMemberEdit extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      crew_member: props.crew_member || {},
      is_expanded: false,
    }
  }

  componentWillReceiveProps(props) {
    if (_.isNil(this.state.crew_member.id)) {
      this.setState({ crew_member: props.crew_member || {} })
    }
  }

  isCurrentUser() {
    const current_user = this.props.current_user || {}
    const crew_member = this.state.crew_member || {}
    return crew_member.person_id === current_user.person_id
  }

  canDelete() {
    const crew_member = this.state.crew_member || {}
    return !this.isCurrentUser() || crew_member.invite_status === INVITE_STATUS.REJECTED ||
      crew_member.can_view_all_gigs || !this.props.is_crewable_owner
  }

  updateCrewMember(update) {
    this.setState({
      crew_member: {
        ...this.state.crew_member,
        ...update,
      }
    })
  }

  save() {
    this.setLoading(() => {
      this.props.updateCrewMember(this.state.crew_member.id, this.state.crew_member)
        .then((res) => {
          if (res.error) {
            this.clearLoading()
          } else {
            this.setState({ is_loading: false, is_expanded: false })
          }
        })
    })
  }

  onDelete() {
    this.setLoading(() => this.props.deleteCrewMember(this.state.crew_member.id)
      .then((res) => { if (res.error) { this.clearLoading() } }))
  }

  renderEditForm() {
    const crew_member = this.state.crew_member || {}
    return (
      <div className={classnames('height-transition', { 'expanded': this.state.is_expanded })}>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-4'>
            <label className='form-label'>Title</label>
            <OtherSelect
              name='title'
              classes='mb-2'
              options={_.map(this.props.title_options || [], (ct) => (
                { value: ct, label: ct }
              ))}
              onChange={(v) => this.updateCrewMember({ title: v })}
              value={crew_member.title}
              disabled={this.state.is_loading}
            />
          </div>
        </div>

        <CrewMemberDetailsForm
          crewable={this.props.crewable}
          crew_member={this.state.crew_member}
          onChange={(v) => this.updateCrewMember(v)}
          can_edit_permissions={this.props.can_edit_permissions}
          disabled={this.state.is_loading}
          two_column
        />

        <div>
          <a
            className='btn btn-primary'
            onClick={() => this.save()}
          >save</a>
          <a
            className='btn btn-gray'
            onClick={() => this.setState({ is_expanded: false }) }
          >cancel</a>
        </div>

        {this.state.is_loading && (
          <div><strong>loading...</strong></div>
        )}
      </div>
    )
  }

  render() {
    const crew_member = this.state.crew_member || {}
    return (
      <div className={classnames('list-group-item', {
        'faded': this.state.is_loading,
        'list-group-item-primary': this.state.crew_member.invite_status === INVITE_STATUS.PENDING,
        'list-group-item-danger': this.state.crew_member.invite_status === INVITE_STATUS.REJECTED,
      })}>
        <a onClick={() => this.setState({ is_expanded: !this.state.is_expanded })}>
          <div className='d-flex flex-row align-items-center'>
            <div className='flex-grow-1'>
              <span className='text-dark me-2'>
                <span className={classnames('d-inline-block me-2 transition', {
                  'rotate-arrow-down': this.state.is_expanded
                })}>
                  <i className='fi-play'></i>
                </span>
                <Avatar
                  classes='small-avatar'
                  blank_icon='Person'
                  url={crew_member.avatar_url}
                />
                <strong>{crew_member.name}</strong>

                {crew_member.username && (<span className='d-none d-md-inline-block'>&nbsp;@{crew_member.username}</span>)}
                {crew_member.title && (<i className='d-none d-md-inline-block'>&nbsp;&ndash;&nbsp;{crew_member.title}</i>)}
              </span>
              {!crew_member.is_person_owned && (
                <InviteButton
                  person={{
                    id: crew_member.person_id,
                    name: crew_member.name,
                    email: crew_member.email,
                  }}
                />
              )}
            </div>

            <div className='flex-grow-1 text-end'>
              {crew_member.invite_status === INVITE_STATUS.PENDING && (
                <span className='me-3'><strong>Invite Pending</strong></span>
              )}
              {crew_member.invite_status === INVITE_STATUS.REJECTED && (
                <span className='me-3'><strong>Invite Rejected</strong></span>
              )}

              {!this.state.is_loading && this.canDelete() && (
                <a
                  className='text-danger'
                  onClick={() => this.onDelete()}
                >
                  <i className='fi-trash delete-icon small'></i>
                </a>
              )}
            </div>
          </div>

          {(crew_member.username || crew_member.title) && (
            <div className='d-md-none'>
              {crew_member.username && (<span>@{crew_member.username}</span>)}
              {crew_member.username && crew_member.title && (<span>&nbsp;&ndash;&nbsp;</span>)}
              {crew_member.title && (<i>{crew_member.title}</i>)}
            </div>
          )}
        </a>

        {this.renderEditForm()}
        {this.renderLoadingPopup()}
      </div>
    )
  }
}

export default connect((state) => ({
  current_user: selectCurrentUser(state)
}), (dispatch) => bindActionCreators({
  updateCrewMember, deleteCrewMember
}, dispatch))(CrewMemberEdit)
