import {
  LOGOUT_ACTION,
  LOGIN_ACTION,
  REGISTER_ACTION,
  CLAIM_INVITE_ACTION,
  CONFIRM_EMAIL_ACTION
} from 'actions/api/users'

import { SET_URL_AUTH_TOKEN } from 'actions/ui'

import {
  isSuccessAction,
  isResourceAction
} from 'actions'

export default (state=null, action) => {
  if ((action.type.match(LOGIN_ACTION) ||
        action.type.match(LOGOUT_ACTION) ||
        action.type.match(REGISTER_ACTION) ||
        action.type.match(CONFIRM_EMAIL_ACTION) ||
        action.type.match(CLAIM_INVITE_ACTION)) &&
      isSuccessAction(action) &&
      isResourceAction(action) &&
      action.payload) {
    return null
  }

  if (action.type.match(SET_URL_AUTH_TOKEN)) {
    return action.payload
  }

  return state
}
