import React from 'react'

import AdvanceProgress from 'components/advance/AdvanceProgress'

const PerformanceAdvanceProgress = ({
  performance,
  pageNum,
}) => {

  const sectionData = [
    { name: 'Welcome', url: `/advance` },
    { name: 'Details', url: `/advance/details` },
    { name: 'Documents', url: `/advance/documents` },
    { name: 'Personnel', url: `/advance/crew_members` },
    { name: 'Guest List', url: `/advance/guest_list` },
    { name: 'Summary', url: `/advance/summary` },
  ]

  return (
    <AdvanceProgress sectionData={sectionData} advanceable={performance} pageNum={pageNum} />
  )
}

export default PerformanceAdvanceProgress
