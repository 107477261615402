import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import OtherSelect from 'components/OtherSelect'

import { TICKET_TYPES } from 'static'

const TicketCountEditArray = ({
  newTicketCountData,
  onChange,
  submitNewCount,
  buttonText,
  onAddEmptyTicketType,
  onRemoveNewTicketType,
  isFinalCount,
}) => {
  const existingTicketTypes = newTicketCountData.filter(data => !_.isNil(data.ticket_type_id))
  const newTicketTypes = newTicketCountData.filter(data => _.isNil(data.ticket_type_id))

  const submitButtonText = buttonText ? buttonText : `Submit ${isFinalCount ? 'Final' : 'New'} Count`

  return (
    <>
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th>Ticket Type</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {existingTicketTypes.map((ticketType, i) => (
            <ExistingTicketTypeForm
              key={`ticket-type-${i}-${ticketType.ticket_type_name}`}
              ticketType={ticketType}
              idx={i}
              onChange={onChange}
            />
          ))}
        </tbody>
      </table>
      {!_.isEmpty(newTicketTypes) && (
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th>Ticket Type</th>
              <th width='120'>Sellable</th>
              <th width='120'>Price</th>
              <th width='120'>Count</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {newTicketTypes.map((ticketType, i) => (
              <NewTicketTypeForm
                key={`ticket-type-${i}-${ticketType.ticket_type_name}`}
                ticketType={ticketType}
                idx={i + existingTicketTypes.length}
                onChange={onChange}
                onDelete={() => onRemoveNewTicketType(i + existingTicketTypes.length)}
              />
            ))}
          </tbody>
        </table>
      )}
      <div className='d-flex flex-row mb-3'>
        <div className='flex-grow-1'>
          <a
            className='btn btn-secondary'
            onClick={onAddEmptyTicketType}
          >Add New Ticket Type</a>
        </div>
        <div className='flex-grow-0 text-end'>
          <a
            className='btn btn-primary'
            onClick={() => submitNewCount()}
          >{submitButtonText}</a>
        </div>
      </div>
    </>
  )
}

const ExistingTicketTypeForm = ({ onChange, ticketType, idx }) => {
  const [currentTicketType, setCurrentTicketType] = useState(ticketType)

  useEffect(() => {
    onChange(currentTicketType, idx)
  }, [currentTicketType])

  const updateTicketTypeCount = (update) => {
    const newTicketType = {
      ...ticketType,
      ...update,
    }
    setCurrentTicketType(newTicketType)
  }

  return (
    <tr>
      <td>
        {ticketType.ticket_type_name}
      </td>

      <td>
        <input
          className='mb-0 form-control'
          type='number'
          name='ticket_count'
          value={currentTicketType.count || ''}
          onChange={e => updateTicketTypeCount({ count: e.target.value })}
        />
      </td>
    </tr>
  )
}

const NewTicketTypeForm = ({ ticketType, onChange, onDelete, idx }) => {
  const [currentTicketType, setCurrentTicketType] = useState(ticketType)

  useEffect(() => {
    onChange(currentTicketType, idx)
  }, [currentTicketType])

  const updateTicketType = update => {
    const newTicketType = {
      ...ticketType,
      ...update,
    }

    setCurrentTicketType(newTicketType)
  }

  return (
    <tr>
      <td>
        <OtherSelect
          classes='no-margin'
          name='ticket_type'
          value={currentTicketType.ticket_type_name || ''}
          options={_.map(TICKET_TYPES, (tt) => {
            return { label: tt, value: tt }
          })}
          onChange={(v) => updateTicketType({ ticket_type_name: v })}
        />
      </td>

      <td>
        <input
          className='mb-0 form-control'
          type='number'
          name='total_sellable'
          value={currentTicketType.total_sellable || ''}
          onChange={(e) => updateTicketType({ total_sellable: e.target.value })}
        />
      </td>

      <td>
        <input
          className='mb-0 form-control'
          type='number'
          name='price'
          value={currentTicketType.price || ''}
          onChange={(e) => updateTicketType({ price: e.target.value })}
        />
      </td>

      <td>
        <input
          className='mb-0 form-control'
          type='number'
          name='count'
          value={currentTicketType.count || ''}
          onChange={(e) => updateTicketType({ count: e.target.value })}
        />
      </td>

      <td>
        <a onClick={() => onDelete()}>
          <i className='fi-trash delete-icon'></i>
        </a>
      </td>
    </tr>
  )
}
export default TicketCountEditArray
