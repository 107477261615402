import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import Selector from 'components/Selector'
import LodgingForm from 'components/lodgings/LodgingForm'

import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createLodging } from 'actions/api/lodgings'

import { parseUrlParams, iff } from 'utils'

import {
  DATE_FORMAT,
  PERSPECTIVE_TYPE
} from 'static'

class NewLodgingForm extends React.Component {

  constructor(props) {
    super(props)

    const location = props.location || {}
    const url_props = parseUrlParams(location.search) || {}
    const url_params = url_props.params || {}

    let check_in_date = moment().format(DATE_FORMAT)
    if (url_params.start_date) {
      check_in_date = moment(url_params.start_date).format(DATE_FORMAT)
    }
    const check_out_date = moment(check_in_date).add(1, 'days').format(DATE_FORMAT)

    this.state = {
      lodging: { check_in_date, check_out_date },
      band_id: url_params.band_id ? parseInt(url_params.band_id, 10) : this.getBandIdFromProps(props)
    }
  }

  componentWillReceiveProps(props) {
    if (!this.state.band_id) {
      this.setState({ band_id: this.getBandIdFromProps(props) })
    }
  }

  getBandIdFromProps(props) {
    let props_band_id = null
    if (props.org) {
      const props_org_split = props.org.split('-')
      if (props_org_split.length === 2 && props_org_split[0] === PERSPECTIVE_TYPE.BAND) {
        props_band_id = props_org_split[1]
      }
    }

    const { person: { bands } } = props.user
    const first_band = _.first(bands) || {}
    return props_band_id || first_band.id
  }

  updateLodging(update, callback) {
    this.setState({
      lodging: {
        ...this.state.lodging,
        ...update,
      }
    }, () => iff(callback))
  }

  updateBandId(v) {
    this.setState({ band_id: v }, () => {
      iff(this.props.onUpdateOrganization, `Band-${v}`)
    })
  }

  submit(e) {
    this.setState({ is_loading: true }, () => {
      this.props.createLodging(this.state.band_id, { ...this.state.lodging }).then(
        (response) => {
          if (response.error) {
            this.setState({ is_loading: false })
          } else {
            this.props.history.push(`/lodgings/${response.payload.result}`)
          }
        }
      )
    })

    if (e) {
      e.preventDefault()
    }
  }

  render() {
    const { person: { bands } } = this.props.user
    const band_options = _.map(bands, (b) => ({ label: b.name, value: b.id }))

    return (
      <form onSubmit={(e) => this.submit(e)}>
        <div className='row'>
          <div className='col-12 col-md-6 mb-3'>
            <label className='form-label'>Create as</label>
            <Selector
              classes='form-select'
              value={this.state.band_id}
              onChange={(v) => this.updateBandId(v)}
              disabled={this.state.is_loading}
              options={band_options}
            />
          </div>
        </div>

        <LodgingForm
          lodging={this.state.lodging}
          onChange={(u) => this.updateLodging(u)}
        />

        <div>
          <input
            className='btn btn-primary me-2'
            type='submit'
            value='create'
          />
          <a
            onClick={() => this.props.history.goBack()}
            className='btn btn-gray'
          >cancel</a>
        </div>

      </form>
    )
  }

}


export default connect((state, props) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  createLodging
}, dispatch))(withRouter(NewLodgingForm))
