import React, { useEffect } from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getGig } from 'actions/api/gigs'
import { isViewable } from 'utils/PermissionsUtil'

import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import Avatar from 'components/Avatar'

import ShowContacts from 'components/contacts/ShowContacts'
import ShowAddress from 'components/addresses/ShowAddress'
import ShowScheduleItems from 'components/schedule/ShowScheduleItems'
import RenderValue from 'components/RenderValue'
import ShowPhone from 'components/people/ShowPhone'
import PersonnelList from 'components/crew_members/PersonnelList'
import AttachmentsList from 'components/documents/AttachmentsList'
import EditableTextArea from 'components/gigs/editable/EditableTextArea'
import EditableNumDayOfShow from 'components/performances/EditableNumDayOfShow'
import EditableProductionDescription from 'components/performances/EditableProductionDescription'

import { PERFORMANCE_TYPES } from 'static'

const PANEL_TYPES = {
  PERFORMANCE: 'performance',
  LOCATION: 'location',
  SCHEDULE: 'schedule',
}

const SCHEDULE_PANEL = {
  type: PANEL_TYPES.SCHEDULE,
  label: "Schedule",
}

const NonUserGigSummary = ({ gigId, gig = {}, getGig }) => {
  const perspective = _.get(gig, 'perspective', {})

  useEffect(() => {
    if (!_.isNil(gigId)) {
      getGig(gigId)
    }
  }, [gigId])

  const location = _.get(gig, 'location', {})
  const venue = _.get(location , 'venue', {})
  const performances = _.get(gig, 'performances', [])

  if (_.isNil(gigId)) return null

  return (
    <div className="container mt-3">
      <div className="alert alert-primary mb-3">
        You are currently viewing this page as an unregistered user who has access to view this show's information.
        You can stay up to date on everything about this performance by
        {' '}
        <SafeLink className='link-primary' to={`/register_person`}>
          registering for a Backline account
        </SafeLink>
        {'.'}
      </div>

      <div className='mb-2'>
        <PageTitle
          subtitle={`${moment(gig.show_date).format('MMMM Do YYYY')} - ${gig.name}`}
        >Advance Summary</PageTitle>
      </div>

      <div className='mb-3'>
        <div className='row'>
          <div className='col-12 col-lg-6 mb-3'>
            <h5>Schedule</h5>
            <div className='mb-3'>
              <ShowScheduleItems
                perspective={perspective}
                gig={gig}
                force_tba_empty
              />
            </div>
          </div>

          <div className='col-12 col-lg-6 mb-3'>
            {!is_mobile && (<h5>Venue</h5>)}
            <div className='border rounded p-3 bg-light mb-3'>
              <h5>{venue.name}</h5>
              <a
                target="_blank"
                href={venue.address && venue.address.maps_url}
                className='d-block text-decoration-none'
              >
                <ShowAddress
                  address={venue.address}
                />
              </a>
            </div>
          </div>
        </div>

        <div className='accordion'>
          <LocationInfoAccordionItem location={location} />
          {_.map(performances, (p, i) => (
            <PerformanceInfoAccordionItem performance={p} key={`${i}-performance-${p.id}`} />
          ))}
        </div>

      </div>
    </div>
  )
}

export default withRouter(connect((state, ownProps) => {
  const gigId = _.get(ownProps, 'match.params.id')
  if (!_.isNil(gigId)) {
    return {
      gig: selectModel('gigs', gigId, Resources.gig, state),
      gigId,
    }
  }
  return {
    gig: {}
  }

}, (dispatch) => bindActionCreators({
  getGig
}, dispatch))(NonUserGigSummary))




const LocationInfoAccordionItem = ({ location }) => {
  if (_.isNil(location) || _.isEmpty(location)) {
    return null
  }

  const venue = _.get(location, 'venue', {})
  const contacts = _.get(location, 'contacts', [])
  const attachments = _.get(location, 'attachments', [])
  const bodyId = `locationInfo-${location.id}-body`

  return (
    <div className='accordion-item'>
      <h2 className='accordion-header'>
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${bodyId}`}>
          <Avatar
            url={venue.avatar_url}
            classes='small-avatar'
            blank_icon='Venue'
          />
          Venue - {venue.name}
        </button>
      </h2>
      <div id={bodyId} className='accordion-collapse collapse'>
        <div className='accordion-body'>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <h5>Venue Details</h5>
              <div className='border rounded p-3 bg-light mb-3'>
                {venue.main_phone && (
                  <div> <strong>Main Phone</strong>: <ShowPhone number={venue.main_phone}/> </div>
                )}
                {venue.box_office_phone && (
                  <div> <strong>Box Office Phone</strong>: <ShowPhone number={venue.box_office_phone}/> </div>
                )}
                <RenderValue value={venue.capacity} label='Capacity' />
                <RenderValue class_name='prewrap' value={venue.parking} label='Parking' />
                <RenderValue class_name='prewrap' value={venue.load_in_info} label='Load-In Info' />
                <RenderValue class_name='prewrap' value={venue.green_room_info} label='Green Room Info' />
                <RenderValue value={venue.wifi} label='Wifi' />
                <RenderValue value={venue.wifi_password} label='Wifi Password' />
                {venue.has_laundry && (<div>Laundry Included</div>)}
                {venue.has_showers && (<div>Showers Included</div>)}
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                {_.isEmpty(contacts) ? (
                  <>
                    <h5>Contacts</h5>
                    <div className='border rounded p-3 bg-light text-center mb-3'>
                      <strong>No Contacts</strong>
                    </div>
                  </>
                ) : (
                  <ShowContacts
                    contacts={contacts}
                    show_avatar
                    show_is_on_site
                    hide_invite_button
                  />
                )}
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              {_.isEmpty(attachments) ? (
                <>
                  <h5>Documents</h5>
                  <div className='border rounded p-3 bg-light text-center mb-3'>
                    <strong>No Documents</strong>
                  </div>
                </>
              ) : (
                <div className='mb-3'>
                  <AttachmentsList attachments={attachments} />
                </div>
              )}
            </div>

            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                {_.isEmpty(_.get(location, 'crew_members', [])) ? (
                  <>
                    <h5>Personnel</h5>
                    <div className='border rounded p-3 bg-light text-center'>
                      <strong>No Personnel</strong>
                    </div>
                  </>
                ) : (
                  <PersonnelList
                    crewable={location}
                    hide_invite
                  />
                )}

                <div className='mt-1'>
                  <EditableNumDayOfShow num_day_of_show={performance.num_day_of_show} />
                </div>
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                <EditableTextArea value={location.merch_info} title='Merch Info' show_empty />
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                <EditableTextArea value={location.hospitality_details} title='Hospitality Details' show_empty />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PerformanceInfoAccordionItem = ({ performance }) => {
  if (_.isNil(performance) || _.isEmpty(performance)) {
    return null
  }

  const band = _.get(performance, 'band', {})
  const contacts = _.get(performance, 'contacts', [])
  const attachments = _.get(performance, 'attachments', [])
  const bodyId = `performanceInfo-${performance.id}-body`

  return (
    <div className='accordion-item'>
      <h2 className='accordion-header'>
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${bodyId}`}>
          <Avatar
            url={band.avatar_url}
            classes='small-avatar'
            blank_icon='Band'
          />
          Band - {band.name}
        </button>
      </h2>
      <div id={bodyId} className='accordion-collapse collapse'>
        <div className='accordion-body'>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                {_.isEmpty(contacts) ? (
                  <>
                    <h5>Contacts</h5>
                    <div className='border rounded p-3 bg-light text-center mb-3'>
                      <strong>No Contacts</strong>
                    </div>
                  </>
                ) : (
                  <ShowContacts
                    contacts={contacts}
                    show_avatar
                    show_is_on_site
                    hide_invite_button
                  />
                )}
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              {_.isEmpty(attachments) ? (
                <>
                  <h5>Documents</h5>
                  <div className='border rounded p-3 bg-light text-center mb-3'>
                    <strong>No Documents</strong>
                  </div>
                </>
              ) : (
                <div className='mb-3'>
                  <AttachmentsList attachments={attachments} />
                </div>
              )}
            </div>

            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                {_.isEmpty(_.get(performance, 'crew_members', [])) ? (
                  <>
                    <h5>Personnel</h5>
                    <div className='border rounded p-3 bg-light text-center'>
                      <strong>No Personnel</strong>
                    </div>
                  </>
                ) : (
                  <PersonnelList
                    crewable={performance}
                    hide_invite
                  />
                )}

                <div className='mt-1'>
                  <EditableNumDayOfShow num_day_of_show={performance.num_day_of_show} />
                </div>
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                <EditableProductionDescription
                  production_description={performance.production_description}
                  house_sound={performance.house_sound}
                  house_lights={performance.house_lights}
                  show_empty
                />
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                <EditableTextArea title='Transportation' value={performance.transportation} show_empty />
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                <EditableTextArea title='Additional Show Notes' value={performance.show_notes} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
