import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import Select from 'react-select'

import { connect } from 'react-redux'
import { selectCurrentUser, selectApiList } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getUpcomingGigs, getPastGigs } from 'actions/api/gigs'
import { withRouter } from 'react-router'

import SafeLink from 'components/SafeLink'
import ShowInvites from 'components/home/ShowInvites'
import GigListItem from 'components/gigs/GigListItem'
import NoBandOrVenueNotice from 'components/gigs/NoBandOrVenueNotice'
import CachedPaginatedList from 'components/CachedPaginatedList'

import { parseUrlParams } from 'utils'
import { PERSPECTIVE_FILTER_OPTION_ALL } from 'static'

class HomeGigsList extends CachedPaginatedList {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      activePerspective: 'All',
    }
  }

  componentWillMount() {
    this.handle_key_press = this.handleKeyPress.bind(this)
    document.addEventListener("keydown", this.handle_key_press);

    this.getListData()
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handle_key_press);
  }

  componentWillReceiveProps(props) {
    if (this.isPast() !== this.isPast(props)) {
      this.resetList(props)
    }
  }

  // Override
  requestListData(page) {
    if (this.isPast()) {
      return this.props.getPastGigs(this.state.page, this.state.activePerspective)
    } else {
      return this.props.getUpcomingGigs(this.state.page, this.state.activePerspective)
    }
  }

  onSubmitRequest(data) {
    if (this.props.history && this.props.location) {
      this.props.history.replace(`${this.props.location.pathname}?page=${this.state.page}`)
    }
  }

  handleKeyPress(event) {
    switch (event.key) {
      case 'ArrowLeft':
        if (this.hasPrevPage()) {
          event.preventDefault()
          this.prevPage()
        }
        break
      case 'ArrowRight':
        if (this.hasNextPage()) {
          event.preventDefault()
          this.nextPage()
        }
        break
    }
  }

  isPast(props=this.props) {
    return props.match.url === '/past'
  }

  sortedGigs() {
    let gigs = this.props.gigs || []
    gigs = _.sortBy(gigs, ['show_date'])
    if (this.isPast()) {
      gigs = _.reverse(gigs)
    }
    return gigs
  }

  renderGigs(noBandsOrVenues = false) {
    const sorted_gigs = this.sortedGigs()

    return (
      <div className='list-group list-group-striped mb-2'>
        {_.map(sorted_gigs, (g) => (
          <GigListItem
            key={`homepage-gig-${g.id}`}
            gig={g}
            show_avatar
          />
        ))}

        {_.isEmpty(sorted_gigs) && this.state.is_loading && (
          <div className='list-group-item'>
            <div className='text-center p-2'> <i className='fi-clock'></i>&nbsp;Loading</div>
          </div>
        )}

        {_.isEmpty(sorted_gigs) && !this.state.is_loading && !noBandsOrVenues && (
          <div className='list-group-item'>
            <div className='text-center p-2'>
              You have no events.  Press the '<strong>+ New Show</strong>' button to make a new one!
            </div>
          </div>
        )}

        {_.isEmpty(sorted_gigs) && !this.state.is_loading && noBandsOrVenues && (
          <NoBandOrVenueNotice />
        )}
      </div>
    )
  }

  render() {
    const title = this.isPast() ? "Past Shows" : "Upcoming Shows"
    const sorted_gigs = this.sortedGigs()
    const bands = _.get(this, 'props.current_user.person.bands', [])
    const venues = _.get(this, 'props.current_user.person.venues', [])
    const bandPerspectiveOptions = _.map(bands, (band) => {
      return { label: band.name, value: band.id, type: 'Band' }
    }) || []

    const venuePerspectiveOptions = _.map(venues, (venue) => {
      return { label: venue.name, value: venue.id, type: 'Venue' }
    }) || []

    const perspectiveOptions = [
      PERSPECTIVE_FILTER_OPTION_ALL,
      ...bandPerspectiveOptions,
      ...venuePerspectiveOptions,
    ]

    const noBandsOrVenues = bandPerspectiveOptions?.length === 0 && venuePerspectiveOptions?.length === 0

    return (
      <div className='container mt-2'>
        <ShowInvites person={this.props.person} />

        <h2>{title}</h2>

        {perspectiveOptions.length > 1 && (
          <div className='d-block d-md-none mb-3'>
            <label>Filter:</label>
            <Select
              name='active_perspective'
              value={this.state.activePerspective || ''}
              options={perspectiveOptions}
              onChange={v => {
                this.setState({ activePerspective: v }, () => {
                  this.requestListData()
                })
              }}
              clearable={false}
              searchable={false}
            />
          </div>
        )}

        <div className='d-flex flex-row mb-3 align-items-end'>
          <div className='text-start flex-grow-0'>
            <ul className='nav nav-pills'>
              <li className='nav-item'>
                <SafeLink
                  to='/'
                  className={classnames('nav-link', { 'active': !this.isPast() })}
                >Upcoming</SafeLink>
              </li>
              <li className='nav-item'>
                <SafeLink
                  to='/past'
                  className={classnames('nav-link', { 'active': this.isPast() })}
                >Past</SafeLink>
              </li>
            </ul>
          </div>

          <div className='flex-grow-1'>
            {perspectiveOptions.length > 1 && (
                <div className='d-none d-md-block mx-3'>
                  <label>Filter:</label>
                  <Select
                    name='active_perspective'
                    className='mb-0'
                    value={this.state.activePerspective || ''}
                    options={perspectiveOptions}
                    onChange={v => {
                      this.setState({ activePerspective: v }, () => {
                        this.requestListData()
                      })
                    }}
                    clearable={false}
                    searchable={false}
                  />
                </div>
            )}
          </div>

          {!noBandsOrVenues && (
            <div className='text-end flex-grow-0'>
              <SafeLink
                to="/new/gig"
                className='btn btn-primary'
              >+ New Show</SafeLink>
            </div>
          )}
        </div>

        {this.renderGigs(noBandsOrVenues)}
        {this.renderPageButtons()}

        {!_.isEmpty(sorted_gigs) && this.state.is_loading && this.renderLoadingPopup()}
      </div>
    )
  }

}

export default withRouter(connect((state, props) => {
  const is_past = props.match.url === '/past'
  const list_key = is_past ? 'past_gigs' : 'upcoming_gigs'
  const page = parseUrlParams(props.location.search).params.page || 0
  const list = selectApiList(list_key, Resources.gigs, page, state)

  return {
    gigs: list.items,
    num_pages: list.num_pages,
    current_user: selectCurrentUser(state),
  }
}, (dispatch) => bindActionCreators({
  getUpcomingGigs,
  getPastGigs,
}, dispatch))(HomeGigsList))
