
export const DISMISS_ERROR_OVERLAY = 'DISMISS_ERROR_OVERLAY'
export function dismissErrorOverlay() {
  return {
    type: DISMISS_ERROR_OVERLAY
  }
}

export const DISMISS_INVITE_USER_OVERLAY = 'DISMISS_INVITE_USER_OVERLAY'
export function dismissInviteUserOverlay() {
  return {
    type: DISMISS_INVITE_USER_OVERLAY
  }
}

export const SET_INVITE_USER_OVERLAY = 'SET_INVITE_USER_OVERLAY'
export function setInviteUserOverlay(person) {
  return {
    type: SET_INVITE_USER_OVERLAY,
    payload: person,
  }
}

export const DISMISS_SHARE_ADVANCE_OVERLAY = 'DISMISS_SHARE_ADVANCE_OVERLAY'
export function dismissShareAdvanceOverlay() {
  return {
    type: DISMISS_SHARE_ADVANCE_OVERLAY
  }
}

export const SET_SHARE_ADVANCE_OVERLAY = 'SET_SHARE_ADVANCE_OVERLAY'
export function setShareAdvanceOverlay(type, id) {
  return {
    type: SET_SHARE_ADVANCE_OVERLAY,
    payload: {
      type, // CREWABLE_TYPE.[PERFORMANCE, LOCATION]
      id,
    }
  }
}

export const SET_SAVE_SCHEDULE_OVERLAY = 'SET_SAVE_SCHEDULE_OVERLAY'
export function setSaveScheduleOverlay(gig_id, schedule_templatable_id, schedule_templatable_type) {
  return {
    type: SET_SAVE_SCHEDULE_OVERLAY,
    payload: {
      gig_id,
      schedule_templatable_id,
      schedule_templatable_type,
    }
  }
}

export const DISMISS_SAVE_SCHEDULE_OVERLAY = 'DISMISS_SAVE_SCHEDULE_OVERLAY'
export function dismissSaveScheduleOverlay() {
  return {
    type: DISMISS_SAVE_SCHEDULE_OVERLAY
  }
}

export const SET_APPLY_SCHEDULE_OVERLAY = 'SET_APPLY_SCHEDULE_OVERLAY'
export function setApplyScheduleOverlay(gig_id, schedule_templatable_id, schedule_templatable_type) {
  return {
    type: SET_APPLY_SCHEDULE_OVERLAY,
    payload: {
      gig_id,
      schedule_templatable_id,
      schedule_templatable_type,
    }
  }
}

export const DISMISS_APPLY_SCHEDULE_OVERLAY = 'DISMISS_APPLY_SCHEDULE_OVERLAY'
export function dismissApplyScheduleOverlay() {
  return {
    type: DISMISS_APPLY_SCHEDULE_OVERLAY
  }
}

export const SET_LINK_SCHEDULE_OVERLAY = 'SET_LINK_SCHEDULE_OVERLAY'
export function setLinkScheduleOverlay(gig_id) {
  return {
    type: SET_LINK_SCHEDULE_OVERLAY,
    payload: { gig_id }
  }
}

export const DISMISS_LINK_SCHEDULE_OVERLAY = 'DISMISS_LINK_SCHEDULE_OVERLAY'
export function dismissLinkScheduleOverlay() {
  return {
    type: DISMISS_LINK_SCHEDULE_OVERLAY
  }
}

export const SET_URL_AUTH_TOKEN = 'SET_URL_AUTH_TOKEN'
export function setUrlAuthToken(token_value) {
  return {
    type: SET_URL_AUTH_TOKEN,
    payload: token_value,
  }
}

/**
 * FORCE_EDITABLE_LIST_RELOAD is a terrible hack that forces the "EditableList"
 * component to refresh the list data in it's local state.
 * TODO: Figure out a better way to do this!
 */
export const SET_FORCE_EDITABLE_LIST_RELOAD = 'SET_FORCE_EDITABLE_LIST_RELOAD'
export function setForceEditableListReload(value) {
  return {
    type: SET_FORCE_EDITABLE_LIST_RELOAD,
    payload: value
  }
}
