import React from 'react'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { withRouter } from 'react-router'
import { Resources } from 'schema'
import { bindActionCreators } from 'redux'
import {
  adminGetBandUpcomingGigs,
  adminGetBandPreviousGigs
} from 'actions/api/admin/bands'

import OrganizationGigsList from 'components/admin/OrganizationGigsList'

class BandGigsList extends OrganizationGigsList {

  requestListData(page) {
    if (this.isPast()) {
      return this.props.adminGetBandPreviousGigs(this.props.band_id, page)
    } else {
      return this.props.adminGetBandUpcomingGigs(this.props.band_id, page)
    }
  }

}

export default connect((state, ownProps) => ({
  org: selectModel('bands', ownProps.band_id, Resources.band, state)
}), (dispatch) => bindActionCreators({
  adminGetBandUpcomingGigs,
  adminGetBandPreviousGigs,
}, dispatch))(withRouter(BandGigsList))
