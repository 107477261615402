import React from 'react'
import _ from 'lodash'

import Avatar from 'components/Avatar'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { resourceGet } from 'actions/api'

class InviteHeader extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  accept() {
    if (!this.props.invite) { return null }
    this.setState({ is_loading: true }, () => {
      this.props.resourceGet(`/api${this.props.invite.url}/accept`).then(
        () => this.setState({ is_loading: false })
      )
    })
  }

  reject() {
    if (!this.props.invite) { return null }
    this.setState({ is_loading: true }, () => {
      this.props.resourceGet(`/api${this.props.invite.url}/reject`).then(
        () => this.setState({ is_loading: false })
      )
    })
  }

  render() {
    if (!this.props.invite) { return null }

    return (
      <div className='alert alert-primary d-flex flex-row align-items-center'>
        <div className='flex-grow-0 me-1'>
          <Avatar
            url={this.props.invite.avatar_url}
          />
        </div>
        <div className='flex-grow-1'>
          <div className='mb-2'>
            <strong> {this.props.invite.text} </strong>
          </div>

          <div>
            <a
              className='btn btn-primary mb-0 me-1'
              onClick={() => this.accept()}
              disabled={this.state.is_loading}
            >accept</a>
            <a
              className='btn btn-danger mb-0'
              onClick={() => this.reject()}
              disabled={this.state.is_loading}
            >reject</a>
          </div>
        </div>
      </div>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  resourceGet
}, dispatch))(InviteHeader)
