import { resourceRSAA } from 'utils'

export const SCHEDULE_ITEM_ACTION = 'SCHEDULE_ITEM'
export function createScheduleItem(id, data) {
  return resourceRSAA(SCHEDULE_ITEM_ACTION, `/api/gigs/${id}/schedule_items`, {
    method: 'POST',
    body: JSON.stringify({ schedule_item: data }),
  })
}

export function updateScheduleItem(id, data) {
  return resourceRSAA(SCHEDULE_ITEM_ACTION, `/api/schedule_items/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ schedule_item: data }),
  })
}

export function deleteScheduleItem(id) {
  return resourceRSAA(SCHEDULE_ITEM_ACTION, `/api/schedule_items/${id}`, {
    method: 'DELETE',
  })
}

export function updateGigScheduleItems(id, data) {
  return resourceRSAA(SCHEDULE_ITEM_ACTION, `/api/gigs/${id}/schedule_items`, {
    method: 'PUT',
    body: JSON.stringify({ gig: {
      schedule_items: data
    }}),
  })
}

export function linkAllGigScheduleItems(id, band_names) {
  return resourceRSAA(SCHEDULE_ITEM_ACTION, `/api/gigs/${id}/link_all`, {
    method: 'PUT',
    body: JSON.stringify({ band_names }),
  })
}
