import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { basicSearch } from 'actions/api'

import SafeLink from 'components/SafeLink'
import LoadingBlock from 'components/LoadingBlock'

import {
  parseUrlParams,
  setUrlParams
} from 'utils'

class SearchPage extends React.Component {

  constructor(props) {
    super(props)

    this.searchDebounce = _.debounce(() => this.search(), 1000)
    this.state = {
      results: [],
      show_loading: false,
      is_search_request: false,
      ...this.getSearchItemsFromProps(props),
    }
  }

  getSearchItemsFromProps(props) {
    const url_info = parseUrlParams(props.location.search)
    const url_params = url_info.params || {}
    const query = _.isNil(url_params.q) ? '' : url_params.q
    return {
      query: decodeURIComponent(query),
      page: url_params.page || 0,
    }
  }

  componentDidMount() {
    this.search_input.focus()
    if (this.state.query) {
      this.search()
    }
  }

  parseResponse(response) {
    this.setState({
      is_search_request: false,
      show_loading: false,
      results: response.results || [],
    })
  }

  search() {
    if (this.state.is_search_request) { return null }
    if (_.isEmpty(this.state.query)) { return null }

    const url_info = parseUrlParams(this.props.location.search)
    const url_params = url_info.params || {}
    if (this.state.query !== url_params.q || this.state.page !== url_params.page) {
      this.props.history.push(setUrlParams('/search', { q: this.state.query, page: this.state.page }))
    }

    this.setState({ is_search_request: true, show_loading: true }, () => {
      this.props.basicSearch(this.state.query, { page: this.state.page }).then(
        (res) => this.parseResponse(res.payload)
      )
    })
  }

  onChangeQuery(query) {
    this.setState({
      query: query
      }, () => {
        this.setState({ results: [], show_loading: true })
        this.searchDebounce()
      })
  }

  renderResults() {
    if (_.isEmpty(this.state.query)) {
      return (
        <div className='alert alert-warning'>
          Please enter a search query to see results!
        </div>
      )
    } else if (this.state.show_loading) {
      return (
        <LoadingBlock/>
      )
    } else if (this.state.results.length === 0) {
      return (
        <div className='alert alert-secondary'>
          Your search yielded no results.  Try searching something else!
        </div>
      )
    } else {
      return (
        <div className='list-group list-group-striped'>
          { _.map(this.state.results, (obj) => (
            <SafeLink key={`${obj.type}-${obj.id}`} to={obj.url} className='list-group-item list-group-item-action'>
              <div className='row'>
                <div className='col-4 col-md-2'>
                  <div className='p-2'>{obj.type}</div>
                </div>
                <div className='col-8 col-md-10'>
                  <div className='p-2'>{obj.name}</div>
                </div>
              </div>
            </SafeLink>
          ))}
        </div>
      )
    }
  }

  render() {
    return (
      <div className='container mt-2'>
        <div className='mb-2'>
          <h2>Search</h2>
          <input
            type='text'
            name='search'
            className='form-control'
            ref={(i) => { this.search_input = i }}
            value={this.state.query}
            onChange={(e) => this.onChangeQuery(e.target.value)}
          />
        </div>

        {this.renderResults()}
      </div>
    )
  }
}

export default connect(null, (dispatch) => bindActionCreators({
  basicSearch
}, dispatch))(SearchPage)
