import React from 'react'

import LoadingBlock from 'components/LoadingBlock'

import { iff } from 'utils'

export default class LoadingState extends React.Component {

  constructor(props) {
    super(props)
    this.state = { is_loading: false, is_loaded: false }
  }

  setLoading(callback) {
    this.setState({ is_loading: true }, () => {
      iff(callback)
    })
  }

  clearLoading(callback) {
    this.setState({ is_loading: false }, () => {
      iff(callback)
    })
  }

  handleResponse(response, callback) {
    if (response.payload.request_status === 404) {
      iff(this.props.history.push, '/not_found')
    } else if (response.payload.request_status === 401) {
      iff(this.props.history.push, '/unauthorized')
    } else {
      this.clearLoading(callback)
    }
  }

  renderLoadingBlock() {
    return (<LoadingBlock />)
  }

  renderLoadingPopup() {
    if (!this.state.is_loading) { return null }

    return (
      <div className='loading-popup'>
        <div className='d-flex align-items-center'>
          <div className="spinner-border loading-smaller me-2" role="status"></div>
          <strong>Loading...</strong>
        </div>
      </div>
    )
  }

}
