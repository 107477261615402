import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import DirectFileUploader from 'components/DirectFileUploader'
import OtherSelect from 'components/OtherSelect'

import {
  MAX_DOCUMENT_SIZE,
  FINANCIAL_DOCUMENT_NAMES,
} from 'static'

export default ({
  financialDocument,
  updateFinancialDocumentState,
  onUploadSuccess,
}) => {

  const doc = financialDocument || {}
  const [fileData, setFileData] = useState(null)
  const [docName, setDocName] = useState(doc.name)

  const uploadSuccess = (signedIds) => {
    updateFinancialDocumentState({
      ...doc,
      document_file: _.first(signedIds),
      name: docName || doc.name,
    }, onUploadSuccess)
  }

  useEffect(() => {
    updateFinancialDocumentState({
      name: docName,
    })
  }, [docName])

  return (
    <>
      {doc.url && (
        <div className='mb-2'>
          <a href={doc.url} target='_blank'>
            View Current Document
          </a>
        </div>
      )}

      <div className='mb-2'>
        <OtherSelect
          name='name'
          placeholder='Document Name'
          classes='mb-2'
          options={FINANCIAL_DOCUMENT_NAMES.map(docName => (
            { value: docName, label: docName }
          ))}
          value={docName}
          onChange={v => setDocName(v)}
        />
      </div>

      <DirectFileUploader
        onUploadSuccess={uploadSuccess}
        setFileData={setFileData}
        fileData={fileData}
        maxFileSize={MAX_DOCUMENT_SIZE}
        uploadButtonText={'Upload and Save'}
      />
    </>
  )
}
