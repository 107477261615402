import React from 'react'
import moment from 'moment'

export default class ShowGuestListSpot extends React.Component {

  renderMobile() {
    const guest_spot = this.props.guest_list_spot || {}
    return (
      <div className='list-group-item'>
        {guest_spot.num_tickets && (
          <span>{guest_spot.num_tickets}&nbsp;&ndash;&nbsp;</span>
        )}
        {guest_spot.name}
        {guest_spot.guest_type && (
          <span>&nbsp;({guest_spot.guest_type})</span>
        )}

        {this.props.is_editable && this.props.onEdit && !guest_spot.is_loading && (
          <a
            onClick={() => this.props.onEdit()}
            disabled={guest_spot.is_loading}
          ><i className='ms-2 edit-icon fi-pencil'></i></a>
        )}

        {this.props.is_editable && guest_spot.is_loading && (
          <i className='ms-2 fi-clock'></i>
        )}
        {this.props.show_notes && guest_spot.notes && (
          <div>
            <i className='ms-3'>&mdash; {guest_spot.notes}</i>
          </div>
        )}
      </div>
    )
  }

  renderDesktop() {
    const guest_spot = this.props.guest_list_spot || {}
    return (
      <tr>
        <td>{guest_spot.num_tickets}</td>
        <td>{guest_spot.name}</td>
        <td>{guest_spot.guest_type}</td>
        {this.props.show_notes && (
          <td>{guest_spot.notes}</td>
        )}
        {this.props.onEdit && (
          <td>{ moment(guest_spot.created_at).format("MM/DD/YYYY") }</td>
        )}

        {this.props.is_editable && this.props.onEdit && !guest_spot.is_loading && (
          <td className='text-end'>
            <a
              onClick={() => this.props.onEdit()}
              disabled={guest_spot.is_loading}
            ><i className='edit-icon fi-pencil'></i></a>
            {guest_spot.is_loading && (
              <i className='fi-clock'></i>
            )}
          </td>
        )}
      </tr>
    )
  }

  render() {
    if (this.props.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }
}
