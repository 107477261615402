import React from 'react'
import _ from 'lodash'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { register } from 'actions/api/users'

import { selectApiErrors } from 'selectors'

import SafeLink from 'components/SafeLink'
import ErrorLabel from 'components/ErrorLabel'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'

import { isSuccessStatus } from 'utils'

class RegisterForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {}
  }

  submit(e) {
    if (e) { e.preventDefault() }

    this.setState({ is_loading: true }, () => {
      this.props.register(this.getPersonData()).then((response) => {
        if (isSuccessStatus(response.payload.request_status)) {
          this.props.history.push(`/welcome`)
        } else {
          this.setState({ is_loading: false })
        }
      })
    })
  }

  getPersonData() {
    return {
      user: {
        ..._.pick(this.state, [
          'email',
          'password',
          'password_confirmation',
        ]),
        person: {
          ..._.pick(this.state, [
            'email',
            'name',
            'username',
          ]),
        }
      }
    }
  }

  render() {
    return (
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-6'>
            <div className='mt-3 border bg-light p-3 rounded'>
              <h3>Register</h3>
              <p>
                <i>Welcome to Backline.me!</i>
              </p>

              <p>
                Backline is a collaborative platform for advancing shows.
              </p>

              <p>
                To get started, fill out this form to create a user account.  Once you're done, you will be able add any bands or venues with which you are associated.
              </p>

              <form onSubmit={(e) => this.submit(e)}>
                <div className='mb-3'>
                  <FormField
                    name='email'
                    label='Email'
                    placeholder='example@example.com'
                    type='email'
                    errors={this.props.api_errors}
                    value={this.state.email || ''}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    name='name'
                    label='Full Name'
                    placeholder='Stevland Morris'
                    type='text'
                    errors={this.props.api_errors}
                    value={this.state.name || ''}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    name='username'
                    label='Username'
                    errorField='person.username'
                    placeholder='stevlandmorris'
                    type='text'
                    errors={this.props.api_errors}
                    value={this.state.username || ''}
                    onChange={(e) => this.setState({ username: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    name='password'
                    label='Password'
                    type='password'
                    errors={this.props.api_errors}
                    value={this.state.password || ''}
                    onChange={(e) => this.setState({ password: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    name='password_confirmation'
                    label='Password Confirmation'
                    type='password'
                    errors={this.props.api_errors}
                    value={this.state.password_confirmation || ''}
                    onChange={(e) => this.setState({ password_confirmation: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormCheck
                    name='agree_to_terms'
                    errors={this.props.api_errors}
                    checked={this.state.agree_to_terms}
                    onChange={(e) => this.setState({ agree_to_terms: e.target.checked })}
                  >
                    By registering for Backline.me, you agree to our <SafeLink to='/terms'>terms and conditions</SafeLink> and our <SafeLink to='/privacy'>privacy policy</SafeLink>.
                  </FormCheck>
                </div>

                <input
                  className='btn btn-primary'
                  type='submit'
                  value='Register'
                  disabled={this.state.is_loading || !this.state.agree_to_terms}
                  onClick={(e) => this.submit(e)}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
}), (dispatch) => bindActionCreators({
  register
}, dispatch))(withRouter(RegisterForm))
