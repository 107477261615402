
export const INVITE_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
}

export const JOINABLE_TYPE = {
  BAND: 'Band',
  VENUE: 'Venue',
}
