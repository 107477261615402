import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { selectCurrentUser } from 'selectors'
import { getCurrentUser } from 'actions/api/users'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import ChangeEmail from 'components/users/ChangeEmail'
import ChangePassword from 'components/users/ChangePassword'

class AccountPage extends React.Component {

  componentWillMount() {
    this.props.getCurrentUser()
  }

  render() {
    const user = this.props.user || {}
    return (
      <div className='container mb-4'>
        <h2>Account Settings</h2>

        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='border rounded p-3 bg-light'>
              <h4>Change your email</h4>
              <ChangeEmail current_email={user.email} />
            </div>
          </div>

          <div className='col-12 col-md-6'>
            <div className='border rounded p-3 bg-light'>
              <h4>Change your password</h4>
              <ChangePassword />
            </div>
          </div>
        </div>

      </div>
    )
  }

}

export default connect((state, props) => ({
  user: selectCurrentUser(state)
}), (dispatch) => bindActionCreators({
  getCurrentUser,
}, dispatch))(AccountPage)
