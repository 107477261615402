import {
  DISMISS_APPLY_SCHEDULE_OVERLAY,
  SET_APPLY_SCHEDULE_OVERLAY
} from 'actions/ui'

export default (state=null, action) => {
  if (action.type.match(DISMISS_APPLY_SCHEDULE_OVERLAY)) {
    return null
  }

  if (action.type.match(SET_APPLY_SCHEDULE_OVERLAY) && action.payload) {
    return { ...action.payload } // GIG ID
  }

  return state
}
