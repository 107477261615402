import React from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { adminGetVenues } from 'actions/api/admin/venues'

import BasicListPage from 'components/admin/BasicListPage'

class VenuesPage extends BasicListPage {

  requestListData(page) {
    return this.props.adminGetVenues(page)
  }

  getPageName() {
    return 'venues'
  }

  getItemUrl(url) {
    return `/admin${url}`
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  adminGetVenues
}, dispatch))(withRouter(VenuesPage))
