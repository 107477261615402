import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getGigTickets, updateGigTickets } from 'actions/api/gigs'

import EditTicketSales from 'components/ticket_sales/EditTicketSales'
import SaveBar from 'components/SaveBar'
import BreadCrumbs from 'components/BreadCrumbs'

class EditGigTicketSalesPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      gig: props.gig || {},
      is_loading: false,
    }
  }

  componentWillMount() {
    this.props.getGigTickets(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      props.getGigTickets(props.match.params.id)
    }

    if (_.isEmpty(this.state.gig)) {
      this.setState({ gig: props.gig || {} })
    }
  }

  updateGig(update) {
    this.setState({
      gig: {
        ...this.state.gig,
        ...update,
      }
    })
  }

  submit() {
    const gig_data = _.pick(this.state.gig, [
      'attendance', 'ticket_sales'
    ])
    this.setState({ is_loading: true }, () => {
      this.props.updateGigTickets(this.props.match.params.id, gig_data)
        .then((res) => {
          if (res.error) {
            this.setState({ is_loading: false })
          } else {
            this.props.history.push(`${this.props.gig.url}/ticket_sales`)
          }
        })
    })
  }

  render() {
    const gig = this.props.gig || {}
    return (
      <div>
        <div className='container'>
          <BreadCrumbs items={[
            { name: 'Shows', url: '/' },
            { name: gig.gig_name || gig.default_name, url: gig.url },
            { name: 'Ticket Sales', url: `${gig.url}/ticket_sales` },
            { name: 'Edit' },
          ]} />

          <h2>{gig.name}</h2>

          <h3>
            {gig.city_state}
            &nbsp;-&nbsp;
            {moment(gig.show_date).format("MMMM Do YYYY")}
          </h3>

          <div className='border rounded bg-light p-3'>
            <h5>Ticket Sales</h5>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <EditTicketSales
                  ticket_sales={this.state.gig.ticket_sales}
                  onChange={(v) => this.updateGig({ ticket_sales: v })}
                />
              </div>

              <div className='col-12 col-lg-6'>
                <label className='form-label'>Attendance</label>
                <input
                  type='number'
                  className='form-control'
                  value={this.state.gig.attendance || ''}
                  onChange={(e) => this.updateGig({ attendance: e.target.value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
        />

      </div>
    )
  }

}

export default connect((state, props) => ({
  gig: selectModel('gigs', props.match.params.id, Resources.gig, state)
}), (dispatch) => {
  return bindActionCreators({ getGigTickets, updateGigTickets }, dispatch)
})(EditGigTicketSalesPage)
