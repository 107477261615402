import { basicGet } from 'actions/api'
import { resourceRSAA, jsonRSAA } from 'utils'

export const CURRENT_USER_ACTION = 'CURRENT_USER'
export function getCurrentUser() {
  return resourceRSAA(CURRENT_USER_ACTION, `/api/user`)
}

export const CHANGE_EMAIL_ACTION = 'CHANGE_EMAIL'
export function changeEmail(data) {
  return resourceRSAA(CHANGE_EMAIL_ACTION, `/api/user/change_email`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
}

export const CHANGE_PASSWORD_ACTION = 'CHANGE_PASSWORD'
export function changePassword(data) {
  return resourceRSAA(CHANGE_PASSWORD_ACTION, `/api/user/change_password`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
}

export const UPDATE_FLAGS_ACTION = 'UPDATE_FLAGS'
export function updateUserFlags(updates) {
  return resourceRSAA(UPDATE_FLAGS_ACTION, `/api/user/update_flags`, {
    method: 'PUT',
    body: JSON.stringify({ flag_updates: updates })
  })
}

export const LOGOUT_ACTION = 'LOGOUT'
export function logout() {
  return jsonRSAA(LOGOUT_ACTION, `/api/logout`)
}

export const LOGIN_ACTION = 'LOGIN'
export function login(data) {
  return resourceRSAA(LOGIN_ACTION, `/api/login`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const REGISTER_ACTION = 'REGISTER'
export function register(data) {
  return resourceRSAA(REGISTER_ACTION, `/api/register`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export function registerPerformanceAdvanceUser(id, data) {
  return resourceRSAA(REGISTER_ACTION, `/api/performances/${id}/register/`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export function registerLocationAdvanceUser(id, data) {
  return resourceRSAA(REGISTER_ACTION, `/api/locations/${id}/register/`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export function registerPerson(id, data) {
  return resourceRSAA(REGISTER_ACTION, `/api/people/${id}/register/`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const SEND_EMAIL_CONFIRMATION_ACTION = 'SEND_EMAIL_CONFIRMATION'
export function sendEmailConfirmation() {
  return resourceRSAA(SEND_EMAIL_CONFIRMATION_ACTION, `/api/email_confirmation`)
}

export const CONFIRM_EMAIL_ACTION = 'CONFIRM_EMAIL'
export function confirmEmail(c_key) {
  return resourceRSAA(CONFIRM_EMAIL_ACTION, `/api/confirm_email`, {
    method: 'PUT',
    body: JSON.stringify({ c_key })
  })
}

export const SEND_PASSWORD_RESET_ACTION = 'SEND_PASSWORD_RESET'
export function sendPasswordReset(email) {
  return resourceRSAA(SEND_PASSWORD_RESET_ACTION, `/api/password_reset`, {
    method: 'POST',
    body: JSON.stringify({ email: email })
  })
}

export const RESET_PASSWORD_ACTION = 'RESET_PASSWORD'
export function resetPassword(data) {
  return resourceRSAA(RESET_PASSWORD_ACTION, `/api/user/reset_password`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const GET_USER_INVITE = 'GET_USER_INVITE'
export function getUserInvite(invite_key) {
  return basicGet(`/api/invite`, GET_USER_INVITE, { i_key: invite_key })
}

export const CLAIM_INVITE_ACTION = 'CLAIM_INVITE'
export function claimInvite(data) {
  return resourceRSAA(CLAIM_INVITE_ACTION, `/api/claim_invite`, {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const GET_CLAIMABLE_ORGS = 'GET_CLAIMABLE_ORGS'
export function getClaimableOrgs() {
  return basicGet(`/api/user/claimable_orgs`, 'CLAIMABLE_ORGS')
}

export const CLAIM_ORG_ACTION = 'CLAIM_ORG'
export function claimOrg(data) {
  return jsonRSAA(CLAIM_ORG_ACTION, `/api/user/claim_org`, null, {
    method: 'PUT',
    body: JSON.stringify({ organization: data })
  })
}
