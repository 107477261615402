import React from 'react'
import classnames from 'classnames'
import Linkify from 'react-linkify'

export default ({ value, label, always_show, class_name, linkify }) => {
  if (value || always_show) {
    if (linkify) {
      return (
        <Linkify properties={{ target: '_blank' }}>
          <div className={classnames(class_name)}><strong>{label}</strong>: {value}</div>
        </Linkify>
      )
    } else {
      return (
        <div className={classnames(class_name)}><strong>{label}</strong>: {value}</div>
      )
    }
  } else {
    return null
  }
}
