import React from 'react'
import _ from 'lodash'

export default class EditArray extends React.Component {

  constructor(props) {
    super(props)

    const all_array = this.getDataFromProps(props) || []

    this.state = {
      ...this.getArraysFromData(all_array)
    }
  }

  componentWillReceiveProps(props) {
    const data = this.getDataFromProps(props) || []
    this.setState({
      ...this.getArraysFromData(data)
    })
  }

  getArraysFromData(data) {
    return {
      deleted_array: _.filter(data, (a) => a._destroy),
      array: _.filter(data, (a) => !a._destroy)
    }
  }

  getDataFromProps() {
    // no-op
  }

  genUpdateObject(i) {
    return (obj) => {
      this.setState({
        array: [
          ...this.state.array.slice(0, i),
          obj,
          ...this.state.array.slice(i+1),
        ]
      }, () => this.onChange())
    }
  }

  addObject() {
    this.setState({
      array: [ ...this.state.array, {} ]
    }, () => this.onChange())
  }

  deleteObject(i) {
    const obj = this.state.array[i]
    const new_state = {
      array: [
        ...this.state.array.slice(0, i),
        ...this.state.array.slice(i+1),
      ]
    }

    if (obj.id) {
      new_state.deleted_array = [
        ...this.state.deleted_array,
        {
          id: obj.id,
          _destroy: true,
        }
      ]
    }
    this.setState(new_state, () => {
      this.onChange()
    })
  }

  onChange() {
    this.props.onChange(this.getFullArray())
  }

  getFullArray() {
    return this.state.array.concat(this.state.deleted_array)
  }

}
