import React from 'react'
import classnames from 'classnames'

import TimeInput from 'components/TimeInput'

export default class SwitchedTimeInput extends React.Component {

  render() {
    if (window.is_mobile) {
      return (
        <input
          type='time'
          ref={this.props.reference}
          name={this.props.name}
          value={this.props.value}
          onChange={(e) => this.props.onChange(e.target.value)}
          className={classnames('form-control', this.props.classes)}
          disabled={this.props.disabled}
        />
      )
    } else {
      return (
        <TimeInput
          reference={this.props.reference}
          classes={this.props.classes}
          value={this.props.value}
          onChange={(v) => this.props.onChange(v)}
          disabled={this.props.disabled}
        />
      )
    }
  }

}
