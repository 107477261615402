import React from 'react'

import EditableField from 'components/EditableField'

export default class EditableGigName extends EditableField {

  getValueFromProps(props) {
    return props.name || ''
  }

  getPostData() {
    return { gig_name: this.state.value }
  }

  render() {
    if (this.state.is_editing && this.props.is_editable) {
      return (
        <div className='d-flex flex-row mb-1'>
          <div className='flex-grow-1'>
            <form onSubmit={() => this.save()}>
              <input
                ref={(ref) => this.input = ref}
                type='text'
                className='form-control'
                value={this.state.value}
                placeholder={this.props.default_name}
                onChange={(e) => this.onChange(e.target.value)}
                onBlur={(e) => this.onBlur(e.target.value)}
              />
            </form>
          </div>

          <div className='flex-grow-0'>
            <a
              key='save'
              onClick={() => this.save()}>
              <i className='fi-check icon-base margin-left success-color'></i>
            </a>
          </div>

        </div>
      )
    } else {
      return (
        <div>
          <h2>
            {this.state.value || this.props.default_name}
            {this.props.is_editable && (
              <a
                key='edit'
                onClick={() => this.onEdit()}
              ><i className='half-margin-left smaller-text edit-icon fi-pencil'></i></a>
            )}
          </h2>
        </div>
      )
    }
  }
}
