import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { selectModel } from 'selectors'
import { getPerformance } from 'actions/api/performances'
import {
  getGuestListSpots,
  updateGuestListSpot,
  updateGuestList,
  createGuestListSpot,
  deleteGuestListSpot
} from 'actions/api/guest_list_spots'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import EditableList from 'components/EditableList'
import EditableGuestListSpot from 'components/guest_list/EditableGuestListSpot'
import BottomBar from 'components/BottomBar'
import PerformanceAdvanceProgress from 'components/performances/PerformanceAdvanceProgress'

import { PERSPECTIVE_TYPE } from 'static'

import { isEditableByPerspective } from 'utils'


class AdvanceGuestListPage extends EditableList {

  constructor(props) {
    super(props)

    this.state = {
      show_mobile_notes: false,
      ...this.state,
    }
  }

  // Override
  getIdFromProps(props) {
    return props.match.params.id
  }

  // Override
  getDataFromListItem(li) {
    return _.pick(li, ['name', 'guest_type', 'notes', 'num_tickets'])
  }

  // Override
  getListItemFromResponse(response) {
    return _.get(response, 'payload.raw.guest_list_spot', {})
  }

  // Override
  getListFromResponse(response) {
    return _.get(response, 'payload.raw.performance.guest_list_spots', [])
  }

  // Override
  getAddButtonText() {
    return "+ Add Guest"
  }

  // Override
  getNewListItemProps() {
    return {}
  }

  /**********************************
   * REQUESTS
   **********************************/
  // Override
  loadListDataRequest(props) {
    return this.props.getGuestListSpots(this.getIdFromProps(props))
  }

  // Override
  updateListItemRequest(id, data) {
    return this.props.updateGuestListSpot(id, data)
  }

  // Override
  deleteListItemRequest(id) {
    return this.props.deleteGuestListSpot(id)
  }

  // Override
  createListItemRequest(data) {
    return this.props.createGuestListSpot(this.getIdFromProps(this.props), data)
  }

  // Override
  saveListRequest() {
    return this.props.updateGuestList(this.getIdFromProps(this.props), this.state.editable_list)
  }

  showNotes() {
    return this.isEditable()
  }

  isEditable() {
    const performance = _.get(this.props, 'performance', {})
    const perspective = _.get(performance, 'gig.perspective', {})
    return isEditableByPerspective(perspective, performance, perspective.guest_list_permissions)
  }

  saveAndNext() {
    this.removeEmptyItems(() => {
      this.setState({ loading_all: true }, () => {
        this.saveListRequest().then((response) => {
          if (response.error) {
            this.setState({ loading_all: false })
          } else {
            this.setState({
              loading_all: false,
              editable_list: this.getListFromResponse(response),
            }, () => this.pushNextUrl())
          }
        })
      })
    })
  }

  pushNextUrl() {
    const performance = _.get(this.props, 'performance', {})
    this.props.history.push(`${performance.url}/advance/summary`)
  }

  onNext() {
    if (this.isEditing() && this.isEditable()) {
      this.saveAndNext()
    } else {
      this.pushNextUrl()
    }
  }

  renderMobile() {
    return (
      <>
        {this.showNotes() && this.state.show_mobile_notes && (
          <a
            onClick={() => this.setState({ show_mobile_notes: false })}
          >hide notes</a>
        )}
        {this.showNotes() && !this.state.show_mobile_notes && (
          <a
            onClick={() => this.setState({ show_mobile_notes: true })}
          >show notes</a>
        )}
        <div className='mb-2'>
          {_.map(this.state.editable_list, (g, i) => (
            <EditableGuestListSpot
              key={`guest_list_spot-${g.id}-${i}`}
              guest_list_spot={g}
              onUpdate={this.genUpdateListItem(i)}
              onSave={this.genSaveListItem(i)}
              onDelete={() => this.deleteListItem(i)}
              onEdit={() => this.editListItem(i)}
              show_notes={this.showNotes() && this.state.show_mobile_notes}
              is_editable={this.isEditable()}
              is_disabled={this.state.loading_all || this.state.is_loading}
              is_mobile
            />
          ))}
        </div>
        {this.state.is_loading && this.renderLoadingBlock()}
        {this.renderButtons()}
      </>
    )
  }

  renderDesktop() {
    return (
      <>
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              <th width='80'># Tix</th>
              <th>Name</th>
              <th>Guest Type</th>
              {this.showNotes() && (
                <th>Notes</th>
              )}
              {this.isEditable() && (
                <th width='65'></th>
              )}
            </tr>
          </thead>
          <tbody>
            {_.map(this.state.editable_list, (g, i) => (
              <EditableGuestListSpot
                key={`guest_list_spot-${g.id}-${i}`}
                guest_list_spot={g}
                onUpdate={this.genUpdateListItem(i)}
                onSave={this.genSaveListItem(i)}
                onDelete={() => this.deleteListItem(i)}
                onEdit={() => this.editListItem(i)}
                show_notes={this.showNotes()}
                is_editable={this.isEditable()}
                is_disabled={this.state.loading_all || this.state.is_loading}
              />
            ))}
          </tbody>
        </table>
        {this.state.is_loading && this.renderLoadingBlock()}
        {this.renderButtons()}
      </>
    )
  }

  render() {
    const performance = _.get(this.props, 'performance', {})
    const gig = _.get(this.props, 'performance.gig', {})

    return (
      <>
        <div className='container mt-3'>
          <PerformanceAdvanceProgress performance={performance} pageNum={4} />

          <div className='mb-2'>
            <PageTitle
              subtitle={`${moment(gig.show_date).format('MMMM Do YYYY')} - ${gig.name}`}
            >Guest List</PageTitle>
          </div>

          {this.isEditable() ? (
            <i>Enter your guests for the show here.</i>
          ) : (
            <div className='alert alert-danger'>
              You are currently logged in as a user who does not have permission to edit {performance.band_name}&#39;s guest list for this show.
            </div>
          )}

          {window.is_mobile ? this.renderMobile() : this.renderDesktop()}
        </div>

        <div className='spacer'></div>

        <BottomBar>
          <div className='container py-2 d-flex flex-row align-items-center'>
            <div className='flex-grow-1'>
              <SafeLink
                to={`${performance.url}/advance/crew_members`}
                className='button no-margin secondary'
              >&lt; go back</SafeLink>
            </div>

            <div className='flex-grow-1 text-end'>
              <a
                onClick={() => this.onNext()}
                className='button no-margin'
              >next page &gt;</a>
            </div>
          </div>
        </BottomBar>
      </>
    )
  }

}

export default withRouter(connect((state, ownProps) => ({
  performance: selectModel('performances', ownProps.match.params.id, Resources.performance, state),
}), (dispatch) => bindActionCreators({
  getGuestListSpots,
  updateGuestListSpot,
  updateGuestList,
  createGuestListSpot,
  deleteGuestListSpot
}, dispatch))(AdvanceGuestListPage))
