import React from 'react'
import Linkify from 'react-linkify'

import EditableField from 'components/EditableField'

export default class EditableGigNotes extends EditableField {

  getValueFromProps(props) {
    return props.notes || ''
  }

  getPostData() {
    return { notes: this.state.value }
  }

  render() {
    if (!this.state.value && !this.props.is_editable) {
      return (
        <div>
          <h5>
            Public Notes
          </h5>

          <div className='rounded bg-light border hover-bg-gray text-center p-3'>
            <h5 className='m-0'>
              No Public Notes
            </h5>
          </div>

        </div>
      )
    }

    if (this.state.is_editing && this.props.is_editable) {
      return (
        <>
          <h5>
            Public Notes
            <a
              key='save'
              onClick={() => this.save()}
            >
              <i className='fi-check icon-base ms-2 success-color'></i>
            </a>
          </h5>

          <textarea
            className='form-control w-100 mb-3'
            ref={(ref) => this.input = ref}
            value={this.state.value}
            placeholder='Write your public notes here...'
            onChange={(e) => this.onChange(e.target.value)}
            onBlur={(e) => this.onBlur(e.target.value)}
          />
        </>
      )

    } else {
      return (
        <>
          <h5>
            Public Notes
            {this.state.value && this.props.is_editable && (
              <a
                key='edit'
                onClick={() => this.onEdit()}
              ><i className='ms-2 edit-icon fi-pencil'></i></a>
            )}
          </h5>
          {this.state.value && (
            <div className='rounded bg-light border p-3 mb-3'>
              <Linkify properties={{ target: '_blank' }}>
                <p className='prewrap mb-0'>{this.state.value}</p>
              </Linkify>
            </div>
          )}
          {!this.state.value && (
            <a
              onClick={() => this.onEdit()}
              className='btn rounded bg-light border hover-bg-gray text-center d-block p-3 mb-3 text-decoration-none text-dark'>
              <h5 className='m-0'>
                <i className='fi-plus'></i> Add Public Notes
              </h5>
            </a>
          )}
        </>
      )
    }
  }
}
