import React from 'react'
import classnames from 'classnames'

import LoadingState from 'components/LoadingState'
import Pagination from 'components/Pagination'

import {
  iff,
  parseUrlParams
} from 'utils'

export default class PaginatedList extends LoadingState {

  // Override
  onSubmitRequest() {
    // Helper for request submit
  }

  // Override
  requestListData(page) {
    throw "Please implement requestListData() for PaginatedList object"
  }

  // Override
  getListFromData(data) {
    throw "Please implement getListFromData() for PaginatedList object"
  }

  constructor(props) {
    super(props)

    const location = props.location || {}
    const url_props = parseUrlParams(location.search) || {}
    const url_params = url_props.params || {}

    this.state = {
      ...this.state,
      page: url_params.page || 0,
      num_pages: 0,
      list: [],
    }
  }

  componentWillMount() {
    this.getListData()
  }

  getNumPages() { return this.state.num_pages }

  hasNextPage() {
    return this.state.page < this.getNumPages() - 1
  }

  hasPrevPage() {
    return this.state.page > 0
  }

  resetList(props=this.props) {
    const location = props.location || {}
    const url_props = parseUrlParams(location.search) || {}
    const url_params = url_props.params || {}

    this.setState({
      page: url_params.page || 0,
      num_pages: 0,
      list: [],
    }, () => this.getListData(props))
  }

  onPage(pageNum) {
    this.setState({
      page: pageNum,
    }, () => this.getListData())
  }

  nextPage() {
    if (this.hasNextPage()) {
      this.setState({
        page: this.state.page + 1
      }, () => this.getListData())
    }
  }

  prevPage() {
    if (this.hasPrevPage()) {
      this.setState({
        page: this.state.page - 1
      }, () => this.getListData())
    }
  }

  getListData(props=this.props) {
    this.setLoading(() => {
      this.requestListData(this.state.page, props).then((res) => {
        if (res.error) {
          this.clearLoading()
        } else {
          const data = res.payload.raw || res.payload || {}
          this.setState({
            list: this.getListFromData(data),
            page: data.page,
            num_pages: data.num_pages,
            is_loading: false,
          })
        }
      })
      this.onSubmitRequest()
    })
  }

  renderPageButtons() {
    return <Pagination currentPageNum={this.state.page} numPages={this.getNumPages()} onPageClick={(pNum) => this.onPage(pNum)} />
  }

}
