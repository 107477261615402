import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getLodging, updateLodging, deleteLodging } from 'actions/api/lodgings'

import SaveBar from 'components/SaveBar'
import PageTitle from 'components/PageTitle'
import LodgingForm from 'components/lodgings/LodgingForm'
import Row from 'components/Row'

import { isSuccessStatus } from 'utils'

class EditLodgingPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      lodging: props.lodging || {}
    }
  }

  componentWillMount() {
    this.props.getLodging(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (_.isEmpty(this.state.lodging)) {
      this.setState({ lodging: props.lodging || {} })
    }
  }

  updateLodging(update) {
    this.setState({
      lodging: {
        ...this.state.lodging,
        ...update,
      }
    })
  }

  submit(e) {
    this.setState({ is_loading: true }, () => {
      this.props.updateLodging(this.props.match.params.id, {...this.state.lodging })
        .then((response) => {
          if (isSuccessStatus(response.payload.request_status)) {
            this.props.history.push(this.props.lodging.url)
          } else {
            this.setState({ is_loading: false })
          }
        })
    })

    if (e) { e.preventDefault() }
  }

  destroy() {
    this.setState({ is_loading: true }, () => {
      this.props.deleteLodging(this.props.match.params.id)
        .then((response) => {
          if (isSuccessStatus(response.payload.request_status)) {
            this.props.history.push('/')
          } else {
            this.setState({ is_loading: false })
          }
        })
    })
  }

  render() {
    return (
      <form onSubmit={(e) => this.submit(e)}>
        <div className='container mt-2'>
          <PageTitle>Edit Housing</PageTitle>

          <LodgingForm
            lodging={this.state.lodging}
            onChange={(u) => this.updateLodging(u)}
          />
        </div>

        <div>
          <div className='spacer'></div>
          <SaveBar
            onSubmit={() => this.submit()}
            onDelete={() => this.destroy()}
            disabled={this.state.is_loading}
          />
        </div>
      </form>
    )
  }
}


export default connect((state, props) => ({
  lodging: selectModel('lodgings', props.match.params.id, Resources.lodging, state)
}), (dispatch) => bindActionCreators({
  getLodging, updateLodging, deleteLodging
}, dispatch))(withRouter(EditLodgingPage))
