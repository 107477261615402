import React from 'react'
import moment from 'moment'

import EditableField from 'components/EditableField'
import DateInput from 'components/DateInput'

import { DATE_FORMAT } from 'static'

export default class EditableGigName extends EditableField {

  getValueFromProps(props) {
    return props.date || moment().format(DATE_FORMAT)
  }

  getPostData() {
    return { show_date: this.state.value }
  }

  render() {
    if (this.state.is_editing && this.props.is_editable) {
      return (
        <div className='d-flex flex-row mb-1 align-items-center'>
          <div className='flex-grow-0'>
            <DateInput
              name='show_date'
              value={this.state.value}
              onChange={(v) => this.onChange(v)}
              onBlur={() => this.save()}
              onRef={(ref) => this.input = ref}
              auto_focus
            />
          </div>

          <div className='flex-grow-1'>
            <a
              key='save'
              onClick={() => this.save()}
            >
              <i className='fi-check icon-base ms-2 text-success'></i>
            </a>
          </div>

        </div>
      )
    } else {
      return (
        <div>
          <h4 className='not-bold'>
            {moment(this.state.value).format('dddd, MMMM Do YYYY')}
            {this.props.is_editable && (
              <a
                key='edit'
                onClick={() => this.setState({ is_editing: true })}
              ><i className='ms-2 edit-icon fi-pencil'></i></a>
            )}
          </h4>
        </div>
      )
    }
  }
}
