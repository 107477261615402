import React from 'react'
import _ from 'lodash'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createVenue } from 'actions/api/venues'

import Row from 'components/Row'
import AddressForm from 'components/addresses/AddressForm'
import SaveBar from 'components/SaveBar'
import ErrorLabel from 'components/ErrorLabel'

class NewVenueForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      venue: props.venue || {},
    }
  }

  updateVenue(update) {
    this.setState({
      venue: {
        ...this.state.venue,
        ...update,
      }
    })
  }

  submit() {
    this.setState({
      is_loading: true,
    }, () => {
      this.props.createVenue(this.state.venue).then((response) => {
        this.setState({
          is_loading: false,
        })
        if (!response.error) {
          this.props.history.push(`/venues/${response.payload.result}/edit`)
        }
      })
    })
  }

  render() {
    return (
      <div>
        <Row>
          <h2>New Venue</h2>
        </Row>

        <div className='row medium-unstack'>

          <div className='columns'>
            <label>
              Venue Name
              <ErrorLabel
                field='name'
                errors={this.props.api_errors}
              />
              <input
                type='text'
                name='name'
                value={this.state.venue.name || ''}
                onChange={(e) => this.updateVenue({ name: e.target.value })}
              />
            </label>

            <label>
              Venue Username
              <ErrorLabel
                field='username'
                errors={this.props.api_errors}
              />
              <input
                type='text'
                name='username'
                value={this.state.venue.username || ''}
                onChange={(e) => this.updateVenue({ username: e.target.value })}
              />
            </label>
          </div>

          <div className='columns'>
            <h4>Address</h4>
            <div className='callout'>
              <AddressForm
                address={this.state.venue.address || {}}
                onChange={(a) => this.updateVenue({ address: a })}
              />
            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
        />
      </div>
    )
  }

}

export default connect((state, props) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  createVenue
}, dispatch))(NewVenueForm)
