import React from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel, selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getPerformance, updatePerformance } from 'actions/api/performances'

import Row from 'components/Row'
import LoadingState from 'components/LoadingState'
import ErrorLabel from 'components/ErrorLabel'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'
import PageTitle from 'components/PageTitle'
import SafeLink from 'components/SafeLink'

import BottomBar from 'components/BottomBar'
import PerformanceAdvanceProgress from 'components/performances/PerformanceAdvanceProgress'

import {
  isSuccessStatus,
  isEditableByPerspective
} from 'utils'

import { MAX_NUM_DAY_OF_SHOW } from 'static'

class PerformanceAdvanceDetails extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...this.getStateFromProps(props),
    }
  }

  componentWillMount() {
    this.setLoading(() => this.props.getPerformance(this.props.match.params.id).then((res) =>
      this.handleResponse(res)))
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setLoading(() => props.getPerformance(props.match.params.id).then((res) =>
        this.handleResponse(res)))
    }

    const new_performance = props.performance || {}
    const old_performance = this.props.performance || {}
    if (new_performance.id !== old_performance.id) {
      this.setState({ ...this.getStateFromProps(props) })
    }
  }

  isEditable() {
    const performance = _.get(this.props, 'performance', {})
    const perspective = _.get(performance, 'gig.perspective', {})
    return isEditableByPerspective(perspective, performance, perspective.gig_permissions)
  }

  getStateFromProps(props) {
    const performance = props.performance || {}
    return { performance }
  }

  updateNumDayOfShow(num) {
    let update_value = num
    if (num) {
      update_value = Math.round(Math.max(Math.min(num, MAX_NUM_DAY_OF_SHOW), 0), 0)
    }
    this.updatePerformance({ num_day_of_show: update_value })
  }

  updatePerformance(update) {
    this.setState({
      performance: {
        ...this.state.performance,
        ...update,
      }
    })
  }

  updateBand(update) {
    const band = _.get(this.state, 'performance.band', {})
    this.updatePerformance({
      band: {
        ...band,
        ...update,
      }
    })
  }

  submit() {
    const performance_data = { ...this.state.performance }
    this.setLoading(() => {
      this.props.updatePerformance(this.props.match.params.id, performance_data)
        .then((response) => {
          if (isSuccessStatus(response.payload.request_status)) {
            this.props.history.push(`${this.props.performance.url}/advance/documents`)
          } else {
            this.clearLoading()
          }
        })
    })
  }

  render() {
    if (this.state.is_loading) { return this.renderLoadingBlock() }

    const state_performance = this.state.performance || {}
    const state_band = state_performance.band || {}
    const performance = _.get(this.props, 'performance', {})
    const band = _.get(performance, 'band', {})
    const gig = _.get(this.props, 'performance.gig', {})

    return (
      <>
        <div className='container mt-3'>
          <PerformanceAdvanceProgress performance={performance} pageNum={1} />

          <div className='mb-2'>
            <PageTitle
              subtitle={`${moment(gig.show_date).format('MMMM Do YYYY')} - ${gig.name}`}
            >Band Details</PageTitle>
          </div>

          {!this.isEditable() && (
            <div className='alert alert-danger'>
              You are currently logged in as a user who does not have permission to this information.
            </div>
          )}

          {this.isEditable() && !band.is_editable && (
            <div className='alert alert-danger'>
              You do not have permission to edit some of the information on this page.
            </div>
          )}

          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='mb-3'>
                <FormField
                  label='Band Name'
                  errors={this.props.api_errors}
                  type='text'
                  name='name'
                  value={state_band.name || ''}
                  onChange={(e) => this.updateBand({ name: e.target.value })}
                  disabled={!band.is_editable}
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Website</label>
                <div className="input-group">
                  <span className="input-group-text">http://</span>
                  <input
                    className='form-control'
                    type='text'
                    name='website'
                    value={state_band.website || ''}
                    onChange={(e) => this.updateBand({ website: e.target.value })}
                    disabled={!band.is_editable}
                  />
                </div>
              </div>

              <div className='mb-3'>
                <FormField
                  label='Members Present Day of Show'
                  type='number'
                  name='num_day_of_show'
                  value={state_performance.num_day_of_show || ''}
                  onChange={(e) => this.updateNumDayOfShow(e.target.value)}
                  disabled={!this.isEditable()}
                />
              </div>

              <div className='mb-3'>
                <FormField
                  label='Transportation'
                  type='textarea'
                  name='transportation'
                  value={state_performance.transportation || ''}
                  onChange={(e) => this.updatePerformance({ transportation: e.target.value })}
                  disabled={!this.isEditable()}
                />
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <h5>Production Details</h5>
              <div className='border rounded p-3 mb-3'>
                <div className='mb-2'>
                  <FormCheck
                    name='house_sound'
                    type='checkbox'
                    checked={state_performance.house_sound || false}
                    onChange={(e) => this.updatePerformance({ house_sound: e.target.checked })}
                    label='Using House Sound Engineer'
                  />

                  <FormCheck
                    label='Using House Lighting Engineer'
                    name='house_lights'
                    type='checkbox'
                    checked={state_performance.house_lights || false}
                    onChange={(e) => this.updatePerformance({ house_lights: e.target.checked })}
                  />
                </div>

                <FormField
                  label='Production Description'
                  type='textarea'
                  name='production_description'
                  value={state_performance.production_description || ''}
                  onChange={(e) => this.updatePerformance({ production_description: e.target.value })}
                  disabled={!this.isEditable()}
                />
              </div>

              <FormField
                label='Additional Notes'
                name='show_notes'
                type='textarea'
                value={state_performance.show_notes || ''}
                onChange={(e) => this.updatePerformance({ show_notes: e.target.value })}
                disabled={!this.isEditable()}
              />
            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <BottomBar>
          <div className='container py-2 d-flex flex-row align-items-center'>
            <div className='flex-grow-1'>
              <SafeLink
                to={`${performance.url}/advance`}
                className='btn btn-gray mb-0'
                disabled={this.state.is_loading}
              >&lt; go back</SafeLink>
            </div>

            <div className='flex-grow-1 text-end'>
              {this.isEditable() ? (
                <button
                  className='btn btn-primary mb-0'
                  onClick={() => this.submit()}
                  disabled={this.state.is_loading}
                >save &amp; next &gt;</button>
              ) : (
                <SafeLink
                  to={`${performance.url}/advance/documents`}
                  className='btn btn-primary mb-0'
                  disabled={this.state.is_loading}
                >next &gt;</SafeLink>
              )}
            </div>
          </div>
        </BottomBar>
      </>
    )
  }

}

export default withRouter(connect((state, ownProps) => ({
  api_errors: selectApiErrors(state),
  performance: selectModel('performances', ownProps.match.params.id, Resources.performance, state),
}), (dispatch) => bindActionCreators({
  updatePerformance, getPerformance
}, dispatch))(PerformanceAdvanceDetails))
