import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import SwitchedTimeInput from 'components/SwitchedTimeInput'
import OtherSelect from 'components/OtherSelect'
import Selector from 'components/Selector'
import Row from 'components/Row'

import showToast from 'showToast'

import { SCHEDULE_LABELS, MAX_DURATION } from 'static'

import { connect } from 'react-redux'
import { Resources } from 'schema'
import { selectModel } from 'selectors'

const BLANK_VALUE = "blank"
const BLANK_LABEL = "---"
const NEW_BAND_LABEL = "+ Enter Band Name"
const NEW_BAND_VALUE = "newband"
const STRING_VALUE = "string"
const NUMBER_VALUE = "number"
const VALUE_SEPARATOR = "~~~"

class ScheduleItemForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      schedule_item: props.schedule_item || {},
    }
  }

  componentDidMount() {
    if (!window.is_mobile) {
      this.first_input.focus()
    }
  }

  updateDuration(value) {
    this.updateScheduleItem({
      duration: Math.round(Math.max(Math.min(value, MAX_DURATION), 0), 0) // 0 < value < MAX_DURATION
    })
  }

  updateScheduleItem(update) {
    this.setState({
      schedule_item: {
        ...this.state.schedule_item,
        ...update,
      }
    }, () => this.props.onUpdate(this.state.schedule_item))
  }

  validateScheduleItem() {
    const data = this.state.schedule_item
    const isInvalid = !(data.time?.length > 0 && data.label?.length > 0)

    if (isInvalid) {
      const toastProps = {
        message: 'Schedule items need at least a time and a label.',
        isSuccess: false,
      }
      showToast(toastProps)
    }
    return !isInvalid
  }

  save(e) {
    if (e) {
      e.preventDefault()
    }

    if (!this.validateScheduleItem()) return null

    if (this.props.onSave) {
      this.props.onSave(this.state.schedule_item)
    }
  }

  destroy() {
    if (this.props.onDelete) {
      this.props.onDelete()
    }
  }

  updateBand(value) {
    const value_split = _.split(value, VALUE_SEPARATOR)
    const prefix = value_split[0]
    const actual = _.join(_.slice(value_split, 1), VALUE_SEPARATOR) // accommodate for the existance of value separator in band names
    if (prefix === NEW_BAND_VALUE) {
      this.setState({ show_new_band_input: true }, () => {
        this.updateScheduleItem({ band_name: '', performance_id: null })
      })
    } else if (prefix === BLANK_VALUE) {
      this.updateScheduleItem({ band_name: '', performance_id: null })
    } else if (prefix === STRING_VALUE) {
      this.updateScheduleItem({ band_name: actual, performance_id: null })
    } else if (prefix === NUMBER_VALUE) {
      this.updateScheduleItem({ band_name: '', performance_id: actual })
    }
  }

  getBandOptions() {
    const performances = _.get(this, 'props.gig.performances', [])
    const perf_options = _.map(performances, (p) => ({
      label: p.band_name,
      value: `${NUMBER_VALUE}${VALUE_SEPARATOR}${p.id}`,
    }))

    const schedule_items = _.get(this, 'props.gig.schedule_items', [])
    const schedule_band_names = _.map(schedule_items, (tsi) => tsi.band_name)
    const filtered_band_names = _.uniq(_.filter(schedule_band_names, (bn) => !(_.trim(bn) === "" || _.isNil(bn))))
    const name_options = _.map(filtered_band_names, (band_name) => ({
      label: band_name,
      value: `${STRING_VALUE}${VALUE_SEPARATOR}${band_name}`,
    }))

    return [
      {
        label: BLANK_LABEL,
        value: `${BLANK_VALUE}${VALUE_SEPARATOR}`,
      },
      ...perf_options,
      ...name_options,
      {
        label: NEW_BAND_LABEL,
        value: `${NEW_BAND_VALUE}${VALUE_SEPARATOR}`,
      },
    ]
  }

  getBandValue() {
    const band_name = _.get(this, 'state.schedule_item.band_name', '')
    const performance_id = _.get(this, 'state.schedule_item.performance_id', null)

    if (!_.isNil(performance_id)) {
      return `${NUMBER_VALUE}${VALUE_SEPARATOR}${performance_id}`
    } else if (band_name === '') {
      return `${BLANK_VALUE}${VALUE_SEPARATOR}`
    } else if (_.isString(band_name)) {
      return `${STRING_VALUE}${VALUE_SEPARATOR}${band_name}`
    }

    return null
  }

  renderBandSelect() {
    if (this.state.show_new_band_input) {
      return (
        <div className='d-flex flex-row align-items-center m-0'>
          <div className='flex-grow-1'>
            <form className="mb-0" onSubmit={(e) => this.save(e)}>
              <input
                type='text'
                className='form-control m-0'
                value={this.state.schedule_item.band_name}
                onChange={(e) => this.updateScheduleItem({ band_name: e.target.value })}
                disabled={this.props.is_disabled}
              />
            </form>
          </div>
          <div className='flex-grow-0'>
            <a onClick={() => this.setState({ show_new_band_input: false })}>
              <button type="button" className="btn-close text-dark ms-2" aria-label="Close"></button>
            </a>
          </div>
        </div>
      )
    } else {
      return (
        <Selector
          name='band'
          classes='form-select m-0'
          options={this.getBandOptions()}
          value={this.getBandValue()}
          onChange={(v) => this.updateBand(v)}
          disabled={this.props.is_disabled}
        />
      )
    }
  }


  renderDesktop() {
    return (
      <tr>
        <td width="240">
          <SwitchedTimeInput
            classes='form-select d-inline-block mb-0'
            reference={(ref) => this.first_input = ref}
            value={this.state.schedule_item.time || ''}
            onChange={(v) => this.updateScheduleItem({ time: v })}
            disabled={this.props.is_disabled}
          />
        </td>

        <td width="120">
          <form className='mb-0 me-2' onSubmit={(e) => this.save(e)}>
            <div className='input-group mb-0'>
              <input
                id='duration'
                className='form-control'
                type='number'
                name='duration'
                value={this.state.schedule_item.duration || ''}
                onChange={(e) => this.updateDuration(e.target.value)}
                disabled={this.props.is_disabled}
              />
              <span className='input-group-text'>min</span>
            </div>
          </form>
        </td>

        <td>
          <OtherSelect
            classes='mb-0'
            name='label'
            options={_.map(SCHEDULE_LABELS, (sl) => (
              { value: sl, label: sl }
            ))}
            value={this.state.schedule_item.label}
            onChange={(v) => this.updateScheduleItem({ label: v })}
            placeholder='Select or type...'
            disabled={this.props.is_disabled}
          />
        </td>

        <td>
          {this.renderBandSelect()}
        </td>

        <td>
          <a onClick={() => this.save()}>
            <i className='fi-check icon-base success-color'></i>
          </a>

          <a onClick={() => this.destroy()}>
            <i className='fi-trash ms-2 delete-icon'></i>
          </a>
        </td>
      </tr>
    )
  }

  renderMobile() {
    return (
      <tr>
        <td colspan='3'>
          <div className='row'>
            <div className='col-6 mb-3'>
              <label className='form-label'>
                Time
              </label>
              <SwitchedTimeInput
                ref={(ref) => this.first_input = ref}
                value={this.state.schedule_item.time || ''}
                onChange={(v) => this.updateScheduleItem({ time: v })}
                disabled={this.props.is_disabled}
              />
            </div>

            <div className='col-6 mb-3'>
              <label htmlFor='duration' className='form-label'>Length</label>
              <div className='input-group'>
                <input
                  id='duration'
                  className='form-control'
                  type='number'
                  name='duration'
                  value={this.state.schedule_item.duration || ''}
                  onChange={(e) => this.updateScheduleItem({ duration: e.target.value })}
                  disabled={this.props.is_disabled}
                />
                <span className='input-group-text'>min</span>
              </div>
            </div>

            <div className='col-12 col-md-6'>
              <label className='form-label'>Item Label</label>
              <OtherSelect
                name='label'
                options={_.map(SCHEDULE_LABELS, (sl) => (
                  { value: sl, label: sl }
                ))}
                value={this.state.schedule_item.label}
                onChange={(v) => this.updateScheduleItem({ label: v })}
                placeholder='Select or type...'
                disabled={this.props.is_disabled}
              />
            </div>

            <div className='col-12 col-md-6'>
              <label className='form-label'>Band</label>
              <div className='mb-3'>{this.renderBandSelect()}</div>
            </div>
          </div>

          <div className='btn-group'>
            <a
              className='btn btn-success'
              onClick={() => this.save()}
            >Save</a>

            <a
              className='btn btn-danger'
              onClick={() => this.destroy()}
            >Delete</a>
          </div>
        </td>
      </tr>
    )
  }

  render() {
    if (this.props.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

}

export default connect((state, ownProps) => ({
  gig: selectModel('gigs', ownProps.schedule_item.gig_id, Resources.gig, state)
}))(ScheduleItemForm)
