import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { rejectMembership } from 'actions/api/memberships'

import RequestRedirectPage from 'components/RequestRedirectPage'
import SafeLink from 'components/SafeLink'
import Row from 'components/Row'

class MembershipRejectPage extends RequestRedirectPage {

  // Override
  makeRequest() { return this.props.rejectMembership(this.props.match.params.id) }

  // Override
  getSuccessUrl(res) { return '/user/profile' }

  render() {
    return (
      <Row>
        {this.state.is_loading && (
          <div className='callout warning'>
            <i className='fi-clock'></i> Loading...
          </div>
        )}

        {this.state.is_success && !this.state.is_loading && (
          <div className='callout success'>
            You have successfully rejected your membership invite!  You'll be redirected shortly.  If for some reason you aren't, <SafeLink to='/user/profile'>click here</SafeLink> to go to your profile.
          </div>
        )}

        {!this.state.is_success && !this.state.is_loading && (
          <div className='callout alert'>
            There was an error rejecting your membership invite. Please send us an email at <a href='mailto:membershipus.backline@gmail.com'>info@backline.me</a> if you continue to experience issues.  <SafeLink to='/user/profile'>Click here</SafeLink> to go to your profile.
          </div>
        )}
      </Row>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  rejectMembership
}, dispatch))(MembershipRejectPage)
