import React, { useState } from 'react'
import classNames from 'classnames'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

import { formatGoogleAddress } from 'utils'

import 'components/addresses/AutocompleteAddress/styles.scss'

const AutocompleteAddress = ({ updateAddress }) => {
  const [autocompleteAddress, setAutocompleteAddress] = useState('')

  const handleAddressChange = newAddress => {
    setAutocompleteAddress(newAddress)
  }

  const handleAddressSelect = newAddress => {
    geocodeByAddress(newAddress).then(results => {
      const formattedAddress = formatGoogleAddress(results[0])
      updateAddress(formattedAddress)
    })
  }

  return (
    <PlacesAutocomplete value={autocompleteAddress} onChange={val => handleAddressChange(val)} onSelect={val => handleAddressSelect(val)}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="autocomplete-container">
          <label className='form-label'>Search for an address</label>
          <input
            className='form-control'
            {...getInputProps({
              placeholder: 'Start typing address...',
            })}
          />
          <div className={classNames('autocomplete-suggestions', {
            "d-none": !loading && _.isEmpty(suggestions)
          })}>
            {loading && (
              <div className='autocomplete-suggestion text-center'>
                <div className="spinner-grow" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {suggestions.map((suggestion, idx) => {
              const style = suggestion.active ?
                { backgroundColor: 'rgba(43, 166, 205, 0.4)', cursor: 'pointer' } :
                { backgroundColor: '#ffffff', cursor: 'pointer' }

              return (
                <div {...getSuggestionItemProps(suggestion, { style })} key={idx} className="autocomplete-suggestion">
                  {suggestion.description}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default AutocompleteAddress
