import React from 'react'

import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'

const WelcomePage = ({ }) => {
  return (
    <div className='container mt-3'>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-6'>
          <h3>Welcome</h3>

          <p>
            Now that you have a user profile, what would you like to do?
          </p>

          <SafeLink
            to="/welcome/bands"
            className='d-flex mb-3 border bg-light hover-bg-gray rounded
              p-3 text-dark text-decoration-none align-items-center'
          >
            <div className='flex-grow-0'>
              <Avatar
                blank_icon="Band"
                classes="smaller-avatar"
              />
            </div>
            <div className='flex-grow-1'>
              Create a Band
            </div>
          </SafeLink>

          <SafeLink
            to="/welcome/venues"
            className='d-flex mb-3 border bg-light hover-bg-gray rounded
              p-3 text-dark text-decoration-none align-items-center'
          >
            <div className='flex-grow-0'>
              <Avatar
                blank_icon="Venue"
                classes="smaller-avatar"
              />
            </div>
            <div className='flex-grow-1'>
              Create a Venue
            </div>
          </SafeLink>

          <SafeLink className='d-block' to="/">
            Take me to the home page
          </SafeLink>
        </div>
      </div>
    </div>
  )
}


export default WelcomePage
