import React from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getLocation, updateLocation } from 'actions/api/locations'

import Row from 'components/Row'
import LoadingState from 'components/LoadingState'
import PageTitle from 'components/PageTitle'
import SafeLink from 'components/SafeLink'

import GigVenueForm from 'components/gigs/GigVenueForm'
import BottomBar from 'components/BottomBar'
import FormField from 'components/FormField'
import LocationAdvanceProgress from 'components/locations/LocationAdvanceProgress'

import {
  isSuccessStatus,
  isEditableByPerspective
} from 'utils'

class LocationAdvanceDetails extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...this.getStateFromProps(props),
    }
  }

  componentWillMount() {
    this.setLoading(() => this.props.getLocation(this.props.match.params.id).then((res) =>
      this.handleResponse(res)))
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setLoading(() => props.getLocation(props.match.params.id).then((res) =>
        this.handleResponse(res)))
    }

    const new_location = props.location_model || {}
    const old_location = this.props.location_model || {}
    if (new_location.id !== old_location.id) {
      this.setState({ ...this.getStateFromProps(props) })
    }
  }

  getStateFromProps(props) {
    const location = props.location_model || {}
    const venue = location.venue || {}
    return {
      location: {
        ..._.omit(location, 'venue'),
        venue_updates: { ...venue },
      }
    }
  }

  isEditable() {
    const location = _.get(this.props, 'location_model', {})
    const perspective = _.get(location, 'gig.perspective', {})
    return isEditableByPerspective(perspective, location, perspective.gig_permissions)
  }

  updateLocation(update) {
    this.setState({
      location: {
        ...this.state.location,
        ...update,
      }
    })
  }

  updateVenue(update) {
    const location = _.get(this.state, 'location', {})
    this.updateLocation({
      venue_updates: {
        ...location.venue_updates,
        ...update,
      }
    })
  }

  submit() {
    const location_data = { ...this.state.location }
    this.setLoading(() => {
      this.props.updateLocation(this.props.match.params.id, location_data)
        .then((response) => {
          if (isSuccessStatus(response.payload.request_status)) {
            this.props.history.push(`${this.props.location_model.url}/advance/documents`)
          } else {
            this.clearLoading()
          }
        })
    })
  }

  render() {
    if (!this.props.location_model) { return null }

    const location = this.props.location_model || {}
    const gig = location.gig || {}
    const venue = location.venue || {}
    const perspective = gig.perspective || {}
    const attachments = location.attachments || []

    const state_location = _.get(this.state, 'location', {})

    return (
      <>
        <div className='container'>
          <LocationAdvanceProgress location={location} pageNum={1} />

          <PageTitle
            subtitle={`${moment(gig.show_date).format('MMMM Do YYYY')} - ${gig.name}`}
          >Venue Details</PageTitle>

          {!this.isEditable() && (
            <div className='alert alert-danger'>
              You are currently logged in as a user who does not have permission to edit this information.
            </div>
          )}

          {this.isEditable() && !venue.is_editable && (
            <div className='alert alert-danger'>
              You do not have permission to edit some of the information on this page.
            </div>
          )}

          <div className='mb-3'>
            <GigVenueForm
              onChange={(update) => this.updateVenue(update)}
              venue={state_location.venue_updates}
              disabled={!venue.is_editable || this.state.is_loading}
            />
          </div>

          <div className='row'>
            <div className='col-12 col-md-6 mb-3'>
              <FormField
                label='Merch Info'
                type='textarea'
                name='merch_info'
                value={state_location.merch_info || ''}
                onChange={(e) => this.updateLocation({ merch_info: e.target.value })}
                disabled={!this.isEditable()}
              />
            </div>
            <div className='col-12 col-md-6 mb-3'>
              <FormField
                label='Hospitality Details'
                type='textarea'
                name='hospitality_details'
                value={state_location.hospitality_details || ''}
                onChange={(e) => this.updateLocation({ hospitality_details: e.target.value })}
                disabled={!this.isEditable()}
              />
            </div>
            <div className='col-12 col-md-6 mb-3'>
              <FormField
                label='Additional Show Notes'
                type='textarea'
                name='show_notes'
                value={state_location.show_notes || ''}
                onChange={(e) => this.updateLocation({ show_notes: e.target.value })}
                disabled={!this.isEditable()}
              />
            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <BottomBar>
          <div className='container py-2 d-flex flex-row align-items-center'>
            <div className='flex-grow-1'>
              <SafeLink
                to={`${location.url}/advance`}
                className='btn btn-gray mb-0'
                disabled={this.state.is_loading}
              >&lt; go back</SafeLink>
            </div>

            <div className='flex-grow-1 text-end'>
              {this.isEditable() ? (
                <button
                  className='btn btn-primary mb-0'
                  onClick={() => this.submit()}
                  disabled={this.state.is_loading}
                >save &amp; next &gt;</button>
              ) : (
                <SafeLink
                  to={`${location.url}/advance/documents`}
                  className='btn btn-priary mb-0'
                  disabled={this.state.is_loading}
                >next &gt;</SafeLink>
              )}
            </div>
          </div>
        </BottomBar>
      </>
    )
  }

}

export default withRouter(connect((state, ownProps) => ({
  location_model: selectModel('locations', ownProps.match.params.id, Resources.location, state),
}), (dispatch) => bindActionCreators({
  updateLocation, getLocation
}, dispatch))(LocationAdvanceDetails))
