import { resourceRSAA } from 'utils'

export const ADMIN_TEMPLATE_SCHEDULE_ITEM_ACTION = 'ADMIN_TEMPLATE_SCHEDULE_ITEM'
export function adminCreateTemplateScheduleItem(id, data) {
  return resourceRSAA(ADMIN_TEMPLATE_SCHEDULE_ITEM_ACTION, `/api/admin/schedule_templates/${id}/template_schedule_items`, {
    method: 'POST',
    body: JSON.stringify({ template_schedule_item: data }),
  })
}

export function adminGetTemplateScheduleItem(id) {
  return resourceRSAA(ADMIN_TEMPLATE_SCHEDULE_ITEM_ACTION, `/api/admin/template_schedule_items/${id}`)
}

export function adminUpdateTemplateScheduleItem(id, data) {
  return resourceRSAA(ADMIN_TEMPLATE_SCHEDULE_ITEM_ACTION, `/api/admin/template_schedule_items/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ template_schedule_item: data }),
  })
}

export function adminDeleteTemplateScheduleItem(id) {
  return resourceRSAA(ADMIN_TEMPLATE_SCHEDULE_ITEM_ACTION, `/api/admin/template_schedule_items/${id}`, {
    method: 'DELETE',
  })
}
