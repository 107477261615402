import React from 'react'

import Phone from 'react-phone-number-input'
import rrui from 'react-phone-number-input/rrui.css'
import rpni from 'react-phone-number-input/style.css'

export default class PhoneInput extends React.Component {

  render() {
    if (window.is_mobile) {
      return (
        <input
          type='tel'
          name={this.props.name}
          value={this.props.value}
          className='form-control'
          onChange={(e) => this.props.onChange(e.target.value)}
          disabled={this.props.disabled}
        />
      )
    } else {
      return (
        <Phone
          country='US'
          name={this.props.name}
          value={this.props.value}
          onChange={(v) => this.props.onChange(v)}
          disabled={this.props.disabled}
        />
      )
    }
  }

}
