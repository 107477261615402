import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectPerformanceOrLocationFromPath } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { selectApiErrors } from 'selectors'
import { getPerformance } from 'actions/api/performances'
import { getLocation } from 'actions/api/locations'
import { registerPerformanceAdvanceUser, registerLocationAdvanceUser } from 'actions/api/users'

import Row from 'components/Row'
import PageTitle from 'components/PageTitle'
import SafeLink from 'components/SafeLink'
import LoadingState from 'components/LoadingState'
import ErrorLabel from 'components/ErrorLabel'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'
import BottomBar from 'components/BottomBar'

import { CREWABLE_TYPE } from 'static'

import { isSuccessStatus } from 'utils'

const NEW_PERSON_ID = -1

class AdvanceRegisterPage extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      selected_person_id: null,
      user: { },
      set_crewable_owner_defaults: true,
      apply_defaults_to_upcoming_gigs: true,
    }
  }

  componentWillMount() {
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      this.setLoading(() => this.props.getPerformance(this.props.match.params.id).then((res) => this.handleResponse(res)))
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      this.setLoading(() => this.props.getLocation(this.props.match.params.id).then((res) => this.handleResponse(res)))
    }
  }

  updateUser(update) {
    this.setState({
      user: {
        ...this.state.user,
        ...update,
      }
    })
  }

  updatePerson(update) {
    this.updateUser({
      person: {
        ..._.get(this.state, 'user.person', {}),
        ...update,
      }
    })
  }

  onSelectPerson(crew_member) {
    this.setState({
      selected_person_id: crew_member.person_id,
      user: {
        person_id: crew_member.person_id,
        person: {
          email: crew_member.email,
          name: crew_member.name,
        }
      }
    })
  }

  onSelectNewPerson() {
    this.setState({
      selected_person_id: NEW_PERSON_ID,
      user: { person: {} }
    })
  }

  registerRequest() {
    const crewable = _.get(this.props, 'crewable', {})

    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      return this.props.registerPerformanceAdvanceUser(crewable.id, {
        user: this.state.user,
        set_crewable_owner_defaults: this.state.set_crewable_owner_defaults,
        apply_defaults_to_upcoming_gigs: this.state.apply_defaults_to_upcoming_gigs,
      })
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      return this.props.registerLocationAdvanceUser(crewable.id, {
        user: this.state.user,
        set_crewable_owner_defaults: this.state.set_crewable_owner_defaults,
        apply_defaults_to_upcoming_gigs: this.state.apply_defaults_to_upcoming_gigs,
      })
    } else {
      throw `AdvanceRegisterPage called registerRequest() without a valid page_type=${this.props.page_type}`
    }
  }

  submit(e) {
    if (e) { e.preventDefault() }

    this.setLoading(() => {
      this.registerRequest().then((response) => {
        if (isSuccessStatus(response.payload.request_status)) {
          this.props.history.push(`/user/profile?tutorial=true`)
        } else {
          this.clearLoading()
        }
      })
    })
  }

  getSelectedPerson() {
    const crew_members = _.get(this.props, 'crewable.crew_members', [])
    return _.find(crew_members, { person_id: this.state.selected_person_id })
  }

  renderPersonSelect() {
    const crew_members = _.get(this.props, 'crewable.crew_members', [])
    return (
      <div className='callout-list margin-bottom'>
        {_.map(crew_members, (cm) => (
          <a
            key={`crew_member-${cm.id}`}
            className={classnames('item text-decoration-none pointer hover', { 'primary': cm.person_id === this.state.selected_person_id })}
            onClick={() => this.onSelectPerson(cm)}
          >{cm.name}</a>
        ))}

        <a
          className={classnames('item text-decoration-none pointer hover', { 'primary': -1 === this.state.selected_person_id })}
          onClick={() => this.onSelectNewPerson()}
        >Register as New Person</a>
      </div>
    )
  }

  renderRegisterForm() {
    if (_.isNil(this.state.selected_person_id)) { return null }
    const selected_person = this.getSelectedPerson()
    const user = _.get(this.state, 'user', {})
    const person = _.get(user, 'person', {})
    const crewable = _.get(this.props, 'crewable', {})
    let org_username_text = ''
    let org_username_place_holder = ''
    let org_type = ''
    if (this.props.page_type === CREWABLE_TYPE.PERFORMANCE) {
      org_username_text = 'Band Username'
      org_username_place_holder = 'band username'
      org_type = 'band'
    } else if (this.props.page_type === CREWABLE_TYPE.LOCATION) {
      org_username_text = 'Venue Username'
      org_username_place_holder = 'venue username'
      org_type = 'venue'
    }


    return (
      <div className='rounded bg-light p-3 border mb-3'>
        <form onSubmit={(e) => this.submit(e)} className='mb-0'>
          <div className='mb-3'>
            <FormField
              name='email'
              label='Email'
              placeholder='example@example.com'
              type='text'
              errors={this.props.api_errors}
              value={person.email || ''}
              onChange={(e) => this.updatePerson({ email: e.target.value })}
            />
          </div>

          <div className='mb-3'>
            <FormField
              type='text'
              name='name'
              label='Full Name'
              errorField='person.name'
              errors={this.props.api_errors}
              placeholder='Stevland Morris'
              value={person.name || ''}
              onChange={(e) => { this.updatePerson({ name: e.target.value }) }}
            />
          </div>

          <div className='mb-3'>
            <FormField
              type='text'
              name='username'
              label='Username'
              errors={this.props.api_errors}
              placeholder='username'
              value={person.username || ''}
              onChange={(e) => { this.updatePerson({ username: e.target.value }) }}
            />
          </div>

          <div className='mb-3'>
            <FormField
              name='password'
              type='password'
              label='Password'
              errors={this.props.api_errors}
              value={user.password || ''}
              onChange={(e) => this.updateUser({ password: e.target.value })}
            />
          </div>

          <div className='mb-3'>
            <FormField
              name='password_confirmation'
              type='password'
              label='Password Confirmation'
              errors={this.props.api_errors}
              value={user.password_confirmation || ''}
              onChange={(e) => this.updateUser({ password_confirmation: e.target.value })}
            />
          </div>

          <div className='mb-3'>
            <p><i>Lastly, we need you to create a username for {crewable.owner_name}.  This is a unique identifier to help other users find your {org_type} on Backline.</i></p>

            <FormField
              type='text'
              name='org_username'
              label={org_username_text}
              errors={this.props.api_errors}
              placeholder={org_username_place_holder}
              value={user.org_username || ''}
              onChange={(e) => { this.updateUser({ org_username: e.target.value }) }}
            />
          </div>

          <div className='mb-3'>
            <FormCheck
              name='set_crewable_owner_defaults'
              type='checkbox'
              checked={this.state.set_crewable_owner_defaults}
              onChange={(e) => this.setState({ set_crewable_owner_defaults: e.target.checked })}
            >
              Set advance information as default for your {org_type}
            </FormCheck>
          </div>

          <div className='mb-3'>
            <FormCheck
              name='apply_defaults_to_upcoming_gigs'
              type='checkbox'
              checked={this.state.apply_defaults_to_upcoming_gigs}
              onChange={(e) => this.setState({ apply_defaults_to_upcoming_gigs: e.target.checked })}
            >
              Automatically apply this advance information to other upcoming shows for your {org_type}
            </FormCheck>
          </div>

          <div className='mb-3'>
            <FormCheck
              name='agree_to_terms'
              type='checkbox'
              checked={this.state.agree_to_terms}
              onChange={(e) => this.setState({ agree_to_terms: e.target.checked })}
            >
              By registering for Backline.me, you agree to our <SafeLink to='/terms'>terms and conditions</SafeLink> and our <SafeLink to='/privacy'>privacy policy</SafeLink>.
            </FormCheck>
          </div>

          <input
            className='btn btn-primary mb-0'
            type='submit'
            value='Register'
            disabled={this.state.is_loading || !this.state.agree_to_terms}
            onClick={(e) => this.submit(e)}
          />
        </form>
      </div>
    )
  }

  render() {
    const crewable = this.props.crewable || {}

    return (
      <div className='container mt-3'>
        <div className='row justify-content-center'>
          <div className='col-sm-12 col-md-9 col-lg-6'>
            <div className='mb-2'>
              <SafeLink to={`${crewable.url}/advance/summary`} className='text-decoration-none'>
                &lt; Back to Advance Summary
              </SafeLink>
            </div>

            <PageTitle>Register for Backline</PageTitle>

            {crewable.is_owned ? (
              <div className='callout warning'>
                {crewable.owner_name} is already registered on Backline.  If you would like to become a member, contact an administrator for {crewable.owner_name} to request an invitation.
              </div>
            ) : (
              <React.Fragment>
                <p>
                  Please select yourself to begin.  If you are not listed, choose &quot;Register as New Person&quot;.
                </p>

                {this.renderPersonSelect()}
                {this.renderRegisterForm()}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }

}

export default connect((state, props) => {
  const { entity, entity_type } = selectPerformanceOrLocationFromPath(state, props.match.path, props.match.params.id)
  return {
    crewable: entity,
    page_type: entity_type,
    api_errors: selectApiErrors(state),
  }
}, (dispatch) => bindActionCreators({
  getPerformance, getLocation, registerPerformanceAdvanceUser, registerLocationAdvanceUser
}, dispatch))(AdvanceRegisterPage)
