import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import {
  parseTime,
  formatMinutes,
  AM, PM,
  NUM_HOURS,
  NUM_MINUTES
} from 'utils'

const DEFAULT_TIME = {
  hours: 0,
  minutes: 0,
  am_pm: PM,
}

export default class TimeInput extends React.Component {

  constructor(props) {
    super(props)

    let init_state = { ...DEFAULT_TIME }
    if (props.value) {
      init_state = { ...parseTime(props.value) }
    }

    this.state = { ...init_state }
  }

  componentWillReceiveProps(props) {
    if (props.value !== this.props.value) {
      const parse_time = parseTime(props.value)
      this.setState({
        ...parse_time
      })
    }
  }

  onUpdate(update) {
    this.setState(update, () => this.updateTime())
  }

  updateTime() {
    if (this.state.hours !== '') {
      let hours = parseInt(this.state.hours, 10)
      if (hours === 12) { hours -= NUM_HOURS }
      if (this.state.am_pm === PM) { hours += NUM_HOURS }
      const hh = hours.toString()
      const mm = formatMinutes(this.state.minutes)
      const formatted_time = `${hh}:${mm}`
      this.props.onChange(formatted_time)
    } else {
      this.props.onChange('')
    }
  }

  render() {
    return (
      <div className='time-input'>
        <select
          className={classnames(this.props.classes)}
          ref={this.props.reference}
          value={this.state.hours}
          onChange={(e) => this.onUpdate({ hours: e.target.value })}
          disabled={this.props.disabled}
        >
          <option value=''>--</option>
          { _.range(1, NUM_HOURS + 1).map((h) => (
            <option key={h} value={h}>
              {h.toString()}
            </option>
          ))}
        </select>

        <select
          className={classnames(this.props.classes)}
          value={this.state.minutes}
          onChange={(e) => this.onUpdate({ minutes: e.target.value })}
          disabled={this.props.disabled}
        >
          { _.range(NUM_MINUTES).map((m) => (
            <option key={m} value={m}>
              {formatMinutes(m)}
            </option>
          ))}
        </select>

        <select
          className={classnames(this.props.classes)}
          value={this.state.am_pm}
          onChange={(e) => this.onUpdate({ am_pm: e.target.value })}
          disabled={this.props.disabled}
        >
          <option value={AM}> am </option>
          <option value={PM}> pm </option>
        </select>
      </div>
    )
  }
}
