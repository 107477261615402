import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Resources } from 'schema'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { createMembership } from 'actions/api/memberships'
import { getBandCrewMemberPeople } from 'actions/api/bands'
import { getVenueCrewMemberPeople } from 'actions/api/venues'

import LoadingState from 'components/LoadingState'
import Avatar from 'components/Avatar'

import {
  PERSPECTIVE_TYPE
} from 'static'

import { iff } from 'utils'

class QuickAddMembers extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      is_hidden: true,
    }
  }

  componentWillMount() {
    if (this.props.joinable_type === PERSPECTIVE_TYPE.BAND) {
      this.setLoading(() => this.props.getBandCrewMemberPeople(this.props.joinable_id)
        .then((res) => this.clearLoading()))
    } else if (this.props.joinable_type === PERSPECTIVE_TYPE.VENUE) {
      this.setLoading(() => this.props.getVenueCrewMemberPeople(this.props.joinable_id)
        .then((res) => this.clearLoading()))
    }
  }

  genAddMembership(person_id) {
    const joinable = this.props.joinable || {}
    return () => {
      if (!this.state.is_loading) {
        this.setLoading(() =>
          this.props.createMembership(joinable.url, { person_id }).then((res) => this.clearLoading()))
      }
    }
  }

  render() {
    const joinable = this.props.joinable || {}

    const memberships = joinable.memberships || []
    const active_memberships = _.filter(memberships, (m) => !m['_destroyed'])
    const member_person_ids = _.map(active_memberships, (m) => m.person_id)

    const crew_people = joinable.crew_member_people || []
    const unused_crew_people = _.filter(crew_people, (p) => _.indexOf(member_person_ids, p.id) === -1)
    const sorted_crew_people = _.sortBy(unused_crew_people, ['default_title', 'name'])

    if (_.isEmpty(sorted_crew_people)) { return null }
    if (this.state.is_hidden) {
      return (
        <>
          <a className='btn btn-gray' onClick={() => this.setState({ is_hidden: false })}>view suggested members</a>
        </>
      )
    }

    return (
      <>
        <h5>
          Suggested Members&nbsp;
          <a className='smaller-text' onClick={() => this.setState({ is_hidden: true })}>(hide)</a>
        </h5>

        <div className={classnames('list-group list-group-striped', {
          'faded': this.state.is_loading,
        })}>
          {_.map(sorted_crew_people, (p) => (
            <a
              key={`quick-add-crew_member-person-${p.id}`}
              className='d-block list-group-item list-group-item-action'
              onClick={this.genAddMembership(p.id)}
            >
              <span className='text-dark me-2'>
                <i className='fi-plus'></i>
              </span>

              <Avatar
                classes='small-avatar'
                blank_icon='Person'
                url={p.avatar_url}
              />

              <span className='text-dark'>
                <strong>{p.name}</strong>
                {p.username && (<span className='d-none d-md-inline-block'>&nbsp;@{p.username}</span>)}
                {p.default_title && (<i className='d-none d-md-inline-block'>&nbsp;&ndash;&nbsp;{p.default_title}</i>)}
              </span>

              {(p.username || p.title) && (
                <div className='d-block d-md-none text-dark'>
                  {p.username && (<span>@{p.username}</span>)}
                  {p.username && p.default_title && (<span>&nbsp;&ndash;&nbsp;</span>)}
                  {p.default_title && (<i>{p.default_title}</i>)}
                </div>
              )}
            </a>
          ))}
        </div>
        {this.renderLoadingPopup()}
      </>
    )
  }

}

export default connect((state, props) => {
  let joinable = {}
  if (props.joinable_type === PERSPECTIVE_TYPE.BAND) {
    joinable = selectModel('bands', props.joinable_id, Resources.band, state)
  } else if (props.joinable_type === PERSPECTIVE_TYPE.VENUE) {
    joinable = selectModel('venues', props.joinable_id, Resources.venue, state)
  }
  return { joinable }
}, (dispatch) => bindActionCreators({
  createMembership, getBandCrewMemberPeople, getVenueCrewMemberPeople
}, dispatch))(QuickAddMembers)
