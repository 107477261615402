import React, { useState, Fragment } from 'react'
import classnames from 'classnames'

const GigTicketCountsTable = ({ title, reversedTicketCounts, gig }) => {
  const [expandedCountIndices, setExpandedCountIndices] = useState(new Set())

  const toggleExpandedIndex = idx => {
    const newIndices = new Set(expandedCountIndices)
    if (newIndices.has(idx)) {
      newIndices.delete(idx)
    } else {
      newIndices.add(idx)
    }

    setExpandedCountIndices(newIndices)
  }

  const toggleAllExpanded = () => {
    if (expandedCountIndices.size === reversedTicketCounts.length) {
      setExpandedCountIndices(new Set())
    } else {
      setExpandedCountIndices(new Set(_.range(0, reversedTicketCounts.length)))
    }
  }

  return (
    <>
      <table style={{ borderCollapse: 'collapse' }} className="table table-striped mb-2">
        <thead>
          <tr>
            <th width='120'>Count Date</th>
            <th width='60'></th>
            <th width='160'>Gross Sales</th>
            <th width='120'>Sellable</th>
            <th width='120'>% Sold</th>
            <th width='120'>Open</th>
            <th width='120'>Wrap</th>
            <th width='180'>Total Sold</th>
          </tr>
        </thead>
        <tbody>
          {reversedTicketCounts?.map((ticketCount, i) => {
            return (
              <Fragment key={i}>
                <tr>
                  <td>{ticketCount.date}</td>
                  <td className="text-right larger-text">
                    <a
                      className={classnames('gig-ticket-count__arrow', 'transition', 'inline-block', 'pointer', {
                        'gig-ticket-count__arrow--flipped': expandedCountIndices.has(i),
                      })}
                      onClick={() => toggleExpandedIndex(i)}
                    >
                      <i className="fi-play"></i>
                    </a>
                  </td>
                  <td>{ticketCount.gross_sales}</td>
                  <td>{ticketCount.total_sellable}</td>
                  <td>{ticketCount.percent_sold}</td>
                  <td>{ticketCount.open}</td>
                  <td>{ticketCount.wrap}</td>
                  <td>{ticketCount.total_sold}</td>
                </tr>
                {expandedCountIndices.has(i) && (
                  ticketCount.counts.map(singleTypeCount => {
                    return (
                      <tr key={`${ticketCount.id + singleTypeCount.ticket_type_name}`}>
                        <td className="text-right"></td>
                        <td className="text-right nowrap">{singleTypeCount.ticket_type_name}</td>
                        <td>{singleTypeCount.gross_sales}</td>
                        <td>{singleTypeCount.total_sellable}</td>
                        <td>{singleTypeCount.percent_sold}</td>
                        <td>{singleTypeCount.open}</td>
                        <td>{singleTypeCount.wrap}</td>
                        <td>{singleTypeCount.count}</td>
                      </tr>
                    )
                  })
                )}
              </Fragment>
            )
          })}
        </tbody>
      </table>

      <a onClick={() => toggleAllExpanded()} className="btn btn-gray mb-3">
        {expandedCountIndices.size === reversedTicketCounts?.length ? 'Collapse All' : 'Expand All'}
      </a>
    </>
  )
}

export default GigTicketCountsTable
