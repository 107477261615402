import React from 'react'
import SafeLink from 'components/SafeLink'

import LandingInfo from 'components/marketing/LandingInfo'
import LandingScreenshots from 'components/marketing/LandingScreenshots'

import Row from 'components/Row'

import { staticAssetUrl } from 'utils'

export default class LandingMobile extends React.Component {
  render() {
    return (
      <div className='landing-page'>
        <div className='small-landing-top'>
          <div className='landing-image-container'>
            <img src={staticAssetUrl('Landing_Page/LandingPicCropped.png')} className='landing-image with-overlay'/>
          </div>
          <div className='image-overlay'></div>
          <div className='landing-cover'>
            <div className='landing-text'>
              <div className='header-text'>
                Backline
              </div>

              <div className='main-text'>
                The only collaborative organizational tool for live music events
              </div>
            </div>
          </div>
        </div>

        <div className='small-landing-top-buttons text-center'>
          <SafeLink to='/waitlist' className='small-waitlist-button'>
            Join the waitlist
          </SafeLink>

          <div className='small-text margin-top'>
            Already a member?  <SafeLink to='/login'>Click here</SafeLink> to login
          </div>
        </div>

        <div className='text-center margin'>
          <LandingInfo/>
        </div>

        <div className='text-center'>
          <LandingScreenshots/>
        </div>

        <div className='text-center padding'>
          <SafeLink to='/waitlist' className='small-waitlist-button half-margin-bottom'>
            Join the waitlist
          </SafeLink>

          <SafeLink to='/contact' className='small-waitlist-button'>
            Contact Us
          </SafeLink>
        </div>

        <div className='padding'></div>
      </div>
    )
  }

}
