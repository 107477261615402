import {
  SET_FORCE_EDITABLE_LIST_RELOAD
} from 'actions/ui'

export default (state=false, action) => {
  if (action.type.match(SET_FORCE_EDITABLE_LIST_RELOAD)) {
    return !!action.payload
  }
  return state
}
