import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { sendPasswordReset } from 'actions/api/users'

import SafeLink from 'components/SafeLink'
import FormField from 'components/FormField'

import { isSuccessStatus } from 'utils'

class PasswordReset extends React.Component {

  constructor(props) {
    super(props)

    this.state = { email: '' }
  }

  submit(e) {
    this.setState({ isLoading: true }, () => {
      this.props.sendPasswordReset(this.state.email).then((response) => {
        const isSuccess = isSuccessStatus(response.payload.request_status)
        this.setState({
          isLoading: false,
          isSuccess,
        })

        if (isSuccess) {
          setTimeout(() => { this.setState({ isSuccess: false }) })
        }
      })
    })

    if (e) { e.preventDefault() }
  }

  render() {
    return (
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-8 col-lg-6'>
            <div className='border rounded my-3 p-4 bg-light'>
              <h3>Reset Password</h3>

              <p className='mb-1'>
                Fill out the form below and we will send you an email with instructions on how to reset your password.
              </p>

              <form onSubmit={(e) => this.submit(e)} className='mb-2'>
                <div className='mb-3'>
                  <FormField
                    className='form-control'
                    name='email'
                    type='email'
                    placeholder='reset@password.com'
                    value={this.state.email}
                    errors={this.props.api_errors}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>

                <input
                  className='btn btn-primary mb-0'
                  type='submit'
                  value='Reset Password'
                  disabled={this.state.isLoading}
                  onClick={(e) => this.submit(e)}
                />
              </form>

              {this.state.isSuccess &&
                <div className='alert alert-success'>
                  A password reset email has been successfully sent.   Please check your email or <SafeLink to="/">return to the homepage</SafeLink>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

}


export default connect((state, props) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  sendPasswordReset
}, dispatch))(PasswordReset)
