import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { withRouter } from 'react-router'

import SafeLink from 'components/SafeLink'

class Tabs extends React.Component {

  isSelected(url) {
    return _.get(this, 'props.location.pathname') === url
  }

  render() {
    return (
      <ul className='nav nav-tabs'>
        {_.map(_.get(this, 'props.tabs', []), (tab, nx) => (tab.show_tab ? (
          <li className='nav-item' key={`navtab-${tab.name}-${nx}`}>
            <SafeLink to={tab.url} className={classnames('nav-link', { 'active': this.isSelected(tab.url) })}>{tab.name}</SafeLink>
          </li>
        ) : null))}
      </ul>
    )
  }

}

export default withRouter(Tabs)
