import React from 'react'

import ShowAddress from 'components/addresses/ShowAddress'
import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'

import RenderValue from 'components/RenderValue'

import { INVITE_STATUS } from 'static'

export default class LocationCard extends React.Component {

  getUrl() {
    const location = this.props.location || {}
    if (this.props.admin_url) {
      return `/admin${location.url}`
    } else {
      return location.url
    }
  }

  render() {
    const location = this.props.location || {}
    const venue = location.venue || {}

    return (
      <>
        <div className='p-3'>
          <SafeLink to={this.getUrl()} className='text-decoration-none'>
            <Avatar
              url={venue.avatar_url}
              classes='float-start'
              blank_icon='Venue'
            />

            <h6>
              {venue.name}&nbsp;
              {venue.username &&
                <span className='smaller-text'>
                  (@{venue.username})
                </span>
              }
            </h6>
          </SafeLink>

          <div>
            <a
              target="_blank"
              className='text-decoration-none'
              href={venue.address && venue.address.maps_url}
            >
              <ShowAddress
                address={venue.address}
              />
            </a>
          </div>
        </div>

        {location.invite_status === INVITE_STATUS.PENDING && (
          <div className='text-center mb-2'>
            (invite pending)
          </div>
        )}

        {location.invite_status === INVITE_STATUS.REJECTED && (
          <div className='text-center mb-2'>
            (invite rejected)
          </div>
        )}

        {this.props.show_view && (
          <SafeLink
            className='btn btn-light p-1 w-100 m-0'
            to={this.getUrl()}
          >View Details</SafeLink>
        )}

      </>
    )
  }

}
