import React from 'react'

import LoadingBlock from 'components/LoadingBlock'

export default () => (
  <div className='container mt-5 text-center'>
    <div className="spinner-grow text-primary loading-large" role="status">
       <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)
