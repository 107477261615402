import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { acceptPerformance } from 'actions/api/performances'

import RequestRedirectPage from 'components/RequestRedirectPage'
import SafeLink from 'components/SafeLink'
import Row from 'components/Row'

class PerformanceAcceptPage extends RequestRedirectPage {

  // Override
  makeRequest() { return this.props.acceptPerformance(this.props.match.params.id) }

  // Override
  getSuccessUrl() { return `/performances/${this.props.match.params.id}/` }

  render() {
    return (
      <Row>
        {this.state.is_loading && (
          <div className='callout warning'>
            <i className='fi-clock'></i> Loading...
          </div>
        )}

        {this.state.is_success && !this.state.is_loading && (
          <div className='callout success'>
            You have successfully accepted your performance invite!  You'll be redirected shortly.  If it appears you are stuck, <SafeLink to={this.getSuccessUrl()}>click here</SafeLink> to see your performance details.
          </div>
        )}

        {!this.state.is_success && !this.state.is_loading && (
          <div className='callout alert'>
            There was an error accepting your performance invite.  Please send us an email at <a href='mailto:info@backline.me'>info@backline.me</a> if you continue to experience issues.  <SafeLink to={this.getSuccessUrl()}>Click here</SafeLink> to view your performance details.
          </div>
        )}
      </Row>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  acceptPerformance
}, dispatch))(PerformanceAcceptPage)
