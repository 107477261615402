import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import Select from 'react-select'
import 'react-select/dist/react-select.css'

const INPUT_DEBOUNCE_TIME = 600 // 0.6s

export default class SearchSelector extends React.Component {

  // STATIC VALUE HACK
  newValue() { return "_new" }
  minSearchLength() { return 0 }
  minSearchOptions() { return [{
    value: '',
    label: `Enter at least ${this.minSearchLength()} letters`,
    disabled: true
  }] }

  constructor(props) {
    super(props)

    this.debounceOnInputChange = _.debounce(this.onInputChange, INPUT_DEBOUNCE_TIME)
    this.state = {
      input: '',
      selected: props.value || '',
      ...this.getStateFromProps(props),
    }
  }

  componentDidMount() {
    this.requestOptions()
  }

  componentWillReceiveProps(props) {
    if (!this.state.selected && !this.state.input && this.state.options.length === 0) {
      this.setState(this.getStateFromProps(props))
    }
  }

  getStateFromProps(props) {
    let options = []
    if (props.value) {
      options.push({
        value: props.value,
        label: props.default_label || props.value
      })
    }

    return {
      selected: props.value || '',
      options,
    }
  }


  // OVERRIDE
  requestOptions() {
    // no-op
    throw "Please override requestOptions() on your SearchSelector"
  }

  setOptions(results=[]) {
    let options = _.map(results, (r) => ({
      value: r.id, label: r.name
    }))

    if (this.props.onNew && this.state.input) {
      options.push({
        value: this.newValue(),
        label: `${this.state.input} - Create New`,
        id: -1,
      })
    }

    if (this.addOptions) {
      options = this.addOptions(options)
    }

    this.setState({ options }, () => {
      if (this.setOptionsCallback) {
        this.setOptionsCallback(results)
      }
    })
  }

  onInputChange(input) {
    if (input.length >= this.minSearchLength()) {
      this.setState({ input }, () => this.requestOptions())
    } else if (this.state.options.length === 0) {
      this.setState({
        options: this.minSearchOptions()
      })
    }
  }

  onChange(opt) {
    if (opt && opt.value === this.newValue()) {
      this.props.onNew(this.state.input)
    } else {
      const new_val = opt ? opt.value : ''
      this.setState({ selected: new_val },
        () => this.props.onChange(this.state.selected))
    }
  }

  render() {
    return (
      <Select
        className={classnames(this.props.className)}
        value={this.state.selected}
        onChange={(opt) => this.onChange(opt)}
        onInputChange={(v) => this.debounceOnInputChange(v)}
        options={this.state.options}
      />
    )
  }
}
