export const GIG_COMPLETENESS = {
  0: 'green',
  1: 'yellow',
  2: 'red',
}

export const PERSPECTIVE_TYPE = {
  BAND: 'Band',
  VENUE: 'Venue',
  GIG: 'Gig',
}

export const CREWABLE_TYPE = {
  PERFORMANCE: 'Performance',
  LOCATION: 'Location',
}

export const TICKET_STATUS = {
  UNCONFIRMED: 'unconfirmed',
  CONFIRMED: 'confirmed',
}

export const TICKET_TYPES = [
  'GA',
  'VIP',
  'Floor',
  'Balcony',
  'Guest',
]

export const EVENT_TYPE = {
  GIG: 'Gig',
  LODGING: 'Lodging',
}

export const FINANCIAL_DOCUMENT_NAMES = [
  'Final Ticket Summary',
  'Deal Memo',
]

export const PERSPECTIVE_FILTER_OPTION_ALL = {
  type: 'All',
  value: null,
  label: 'All Organizations',
}
