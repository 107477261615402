import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel, selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getBand, updateBand } from 'actions/api/bands'

import LoadingState from 'components/LoadingState'
import SaveBar from 'components/SaveBar'
import DirectFileUploader from 'components/DirectFileUploader'
import ErrorLabel from 'components/ErrorLabel'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'

import { MAX_AVATAR_SIZE, MAX_NUM_DAY_OF_SHOW } from 'static'

import { iff } from 'utils'

class BandWelcomeDetailsPage extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      band: props.band || {},
      is_loading: false,
    }
  }

  componentDidMount() {
    this.setLoading(() => this.props.getBand(this.props.match.params.id)
      .then((res) => {
        if (res.error) {
          this.clearLoading()
        } else {
          this.setState({ band: res.payload.raw.band }, () => this.clearLoading())
        }
      })
    )
  }

  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      props.getBand(props.match.params.id)
    }

    if (_.isEmpty(this.state.band)) {
      this.setState({ band: props.band || {} })
    }
  }

  updateBand(update, callback) {
    this.setState({
      band: {
        ...this.state.band,
        ...update,
      }
    }, () => iff(callback))
  }

  updateNumDayOfShow(num) {
    let update_value = num
    if (num) {
      update_value = Math.round(Math.max(Math.min(num, MAX_NUM_DAY_OF_SHOW), 0), 0)
    }
    this.updateBand({ default_num_day_of_show: update_value })
  }

  save() {
    const bandData = { band: { ...this.state.band } }
    this.setLoading(() => {
      this.props.updateBand(this.props.match.params.id, bandData).then((response) => this.clearLoading())
    })
  }

  submit() {
    const bandData = { band: { ...this.state.band } }
    this.setLoading(() => {
      this.props.updateBand(this.props.match.params.id, bandData).then(
        (response) => {
          this.clearLoading()
          if (!response.error) {
            this.props.history.push(`/welcome/bands/${this.props.band.id}/members`)
          }
        }
      )
    })
  }

  render() {
    const band = this.state.band || {}

    return (
      <>
        <div className='container mt-3'>
          <h3>
            Band Information
          </h3>

          <p>
            If you have PDFs or other documents detailing your setup, there will be a space to upload those later in the process.
          </p>

          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='border p-3 rounded mb-3'>
                <h5>Public Profile</h5>
                <p>
                  This is the information available to all venues and bands (regardless of whether or not they have advanced a show with you).
                </p>

                <div className='mb-2'>
                  Artist Image
                </div>

                {this.props.band.avatar_url && (
                  <img
                    src={this.props.band.avatar_url}
                    className='avatar mb-2'
                  />
                )}

                <div className='mb-3'>
                  <DirectFileUploader
                    onUploadSuccess={(signedIds) => this.updateBand({
                      avatar_image: _.first(signedIds)
                    }, () => this.save())}
                    setFileData={(fd) => this.setState({ fileData: fd })}
                    fileData={this.state.fileData}
                    maxFileSize={MAX_AVATAR_SIZE}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>Website</label>
                    <div className="input-group">
                      <span className="input-group-text">http://</span>
                      <input
                        className='form-control'
                        type='text'
                        name='website'
                        value={band.website || ''}
                        onChange={(e) => this.updateBand({ website: e.target.value })}
                      />
                    </div>
                </div>

                <div className='mb-3'>
                  <FormField
                    name='bio'
                    label='Description'
                    type='textarea'
                    placeholder='Stevie Wonder has been singing and playing music since he was...'
                    value={band.bio || ''}
                    onChange={(e) => this.updateBand({ bio: e.target.value })}
                  />
                </div>
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <div className='border p-3 rounded mb-3'>
                <h5>Default Production Details</h5>
                <p>
                  This information is private, and is only shared with bands and veues you are actively advancing shows with.  You can edit this information on a show-by-show basis, but the answers you give here will show up by automatically when you advance a show.
                </p>

                <div className='mb-3'>
                  <FormCheck
                    label='Using House Sound Engineer'
                    name='default_house_sound'
                    checked={band.default_house_sound || false}
                    onChange={(e) => this.updateBand({ default_house_sound: e.target.checked })}
                  />

                  <FormCheck
                    label='Using House Lighting Engineer'
                    name='default_house_lights'
                    type='checkbox'
                    checked={band.default_house_lights || false}
                    onChange={(e) => this.updateBand({ default_house_lights: e.target.checked })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    name='default_num_day_of_show'
                    label='Members Present Day of Show'
                    type='number'
                    placeholder='0'
                    value={band.default_num_day_of_show || ''}
                    onChange={(e) => this.updateNumDayOfShow(e.target.value)}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    label='Production Description'
                    type='textarea'
                    name='default_production_description'
                    placeholder='We bring our own IEM rig and mix our own monitors, but...'
                    value={band.default_production_description || ''}
                    onChange={(e) => this.updateBand({ default_production_description: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    label='Transportation Description'
                    type='textarea'
                    placeholder='We travel in a Ford E-350 15 passenger van with a trailer...'
                    name='default_transportation'
                    value={band.default_transportation || ''}
                    onChange={(e) => this.updateBand({ default_transportation: e.target.value })}
                  />
                </div>

                <div className='mb-3'>
                  <FormField
                    label='Additional Show Notes'
                    type='textarea'
                    placeholder='We travel with a young dog named sparky who pins all of our cables...'
                    name='default_show_notes'
                    value={band.default_show_notes || ''}
                    onChange={(e) => this.updateBand({ default_show_notes: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
          save_text='continue'
          hide_cancel
        />

      </>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
  band: selectModel('bands', props.match.params.id, Resources.band, state),
}), (dispatch) => bindActionCreators({
  getBand, updateBand
}, dispatch))(BandWelcomeDetailsPage)
