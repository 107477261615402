import React from 'react'

import PermissionFields from 'components/PermissionFields'
import FormCheck from 'components/FormCheck'

import SafeLink from 'components/SafeLink'
import { iff } from 'utils'

export default class CrewMemberDetailsForm extends React.Component {

  getKey() {
    const crew_member = this.props.crew_member || {}
    const idKey = this.props.idKey || ''
    return `${crew_member.id}-${idKey}`
  }

  updateCrewMember(update) {
    iff(this.props.onChange, update)
  }

  renderContent() {
    const crewable = this.props.crewable || {}
    const crew_member = this.props.crew_member || {}
    return (
      <div className='border rounded p-3 bg-light mb-2'>
        <div><strong>Personnel Attributes</strong></div>

        <div className='mb-2'>
          <FormCheck
            className='mb-1 me-3 d-inline-block'
            label='Show Contact'
            name={`${this.getKey()}-is_contact`}
            checked={crew_member.is_contact}
            onChange={(e) => this.updateCrewMember({ is_contact: e.target.checked })}
            disabled={this.props.disabled}
          />

          <FormCheck
            className='mb-1 me-3 d-inline-block'
            label='Present Day of Show'
            name={`${this.getKey()}-is_on_site`}
            checked={crew_member.is_on_site || false}
            onChange={(e) => this.updateCrewMember({ is_on_site: e.target.checked })}
            disabled={this.props.disabled}
          />
        </div>

        {!crew_member.can_view_all_gigs && this.props.can_edit_permissions && (
          <React.Fragment>
            <PermissionFields
              label='Show Detail Permissions'
              name={`crew_member-${this.getKey()}-gig_permissions`}
              value={crew_member.gig_permissions}
              onChange={(v) => this.updateCrewMember({ gig_permissions: v })}
              disabled={this.props.disabled}
            />
            <PermissionFields
              label='Guest List Permissions'
              name={`crew_member-${this.getKey()}-guest_list_permissions`}
              value={crew_member.guest_list_permissions}
              onChange={(v) => this.updateCrewMember({ guest_list_permissions: v })}
              disabled={this.props.disabled}
            />
            <PermissionFields
              label='Ticket Counts Permissions'
              name={`crew_member-${this.getKey()}-ticket_sales_permissions`}
              value={crew_member.ticket_sales_permissions}
              onChange={(v) => this.updateCrewMember({ ticket_sales_permissions: v })}
              disabled={this.props.disabled}
            />
          </React.Fragment>
        )}

        {crew_member.can_view_all_gigs && this.props.can_edit_permissions && (
          <div className='alert alert-warning'>
            <div className='text-center'>
              <strong>This user can view all gigs</strong>
            </div>
            <div>
              Their permission settings must be edited through the <a href={`${crewable.owner_url}/members`} target='_blank'>{crewable.owner_type} members page</a>.
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    if (this.props.two_column) {
      return (
        <div className='row'>
          <div className='col-12 col-md-6'>
            {this.renderContent()}
          </div>
        </div>
      )
    } else {
      return this.renderContent()
    }
  }
}
