import React from 'react'
import _ from 'lodash'

import CrewMemberEdit from 'components/crew_members/CrewMemberEdit'
import NewCrewMemberForm from 'components/crew_members/NewCrewMemberForm'
import QuickAddCrewMembers from 'components/crew_members/QuickAddCrewMembers'

export default class ManageCrewMembers extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show_new_form: false,
    }
  }

  clearNewForm() {
    this.setState({
      show_new_form: false,
    })
  }

  renderNewForm() {
    const crewable = this.props.crewable || {}
    if (this.state.show_new_form) {
      return (
        <div className='border rounded p-3'>
          <h5>Add Personnel</h5>
          <NewCrewMemberForm
            crewable={crewable}
            title_options={this.props.title_options}
            can_edit_permissions={this.props.can_edit_permissions}
            onCancel={() => this.clearNewForm()}
          />
        </div>
      )
    } else {
      return (
        <>
          <button
            className='btn btn-primary'
            onClick={() => this.setState({ show_new_form: true })}
          >Add New Personnel</button>
        </>
      )
    }
  }

  render() {
    const crewable = this.props.crewable || {}
    const crew_members = crewable.crew_members || []
    const active_crew_members = _.filter(crew_members, (cm) => !cm['_destroyed'])
    const sorted_crew_members = _.sortBy(active_crew_members, (cm) => cm.is_admin ? 0 : cm.is_primary ? 1 : 2)

    return (
      <>
        <div className='list-group list-group-striped mb-3'>
          {_.map(sorted_crew_members, (cm, i) => (
            <CrewMemberEdit
              key={`crew_member-${cm.id}-${i}`}
              crewable={crewable}
              is_crewable_owner={this.props.is_crewable_owner}
              can_edit_permissions={this.props.can_edit_permissions}
              title_options={this.props.title_options}
              crew_member={cm}
            />
          ))}

          {_.isEmpty(sorted_crew_members) && (
            <div className='p-3 text-center'>
              You have no Personnel.
            </div>
          )}
        </div>

        {crewable.is_owned && this.props.is_crewable_owner && (
          <QuickAddCrewMembers crewable={crewable} />
        )}

        {this.renderNewForm()}
      </>
    )
  }
}
