import React from 'react'
import SafeLink from 'components/SafeLink'

import { withRouter } from 'react-router'

import ConfirmationButton from 'components/ConfirmationButton'

class SaveBar extends React.Component {

  render() {
    return (
      <div className='bottom-bar'>
        <div className='container py-2 d-flex flex-row align-items-center'>
          {this.props.onDelete && !this.props.hide_delete && (
            <div className='flex-grow-1'>
              <ConfirmationButton
                button_classes='m-0'
                button_type='danger'
                button_text='delete'
                confirm_text='really delete?'
                onConfirm={() => this.props.onDelete()}
                disabled={this.props.disabled || this.props.delete_disabled}
              />
            </div>
          )}

          <div className='flex-grow-1 text-end'>
            {!this.props.hide_cancel && (
              <a
                className='btn btn-gray mb-0 me-2'
                onClick={() => this.props.history.goBack()}
              >cancel</a>
            )}
            <button
              className='btn btn-primary mb-0'
              onClick={() => this.props.onSubmit()}
              disabled={this.props.disabled || this.props.save_disabled}
            >{this.props.save_text || "save"}</button>
          </div>
        </div>
      </div>
    )
  }

}

export default withRouter(SaveBar)
