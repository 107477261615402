import React from 'react'
import _ from 'lodash'

import { Switch, Route } from 'react-router-dom'

import { AUTH_TYPE } from 'static/Auth'

import LoginForm from 'components/login/LoginForm'
import RegisterForm from 'components/login/RegisterForm'

import LandingPage from 'components/marketing/LandingPage'
import WaitListForm from 'components/marketing/WaitListForm'
import ContactPage from 'components/marketing/ContactPage'

import PasswordReset from 'components/password_reset/PasswordReset'
import UserResetPassword from 'components/password_reset/UserResetPassword'

import ClaimInviteForm from 'components/invite/ClaimInviteForm'

export default class LoggedOutRoutes extends React.Component {

  render() {
    const auth_context = this.props.auth_context || {}
    if (auth_context.auth_type === AUTH_TYPE.USER && !_.isNil(auth_context.id)) {
      return this.props.children
    }

    return (
      <Switch>
        <Route exact path='/claim_invite' component={ClaimInviteForm} />
        <Route exact path='/user/reset_password' component={UserResetPassword} />
        <Route exact path='/password_reset' component={PasswordReset} />
        <Route exact path='/waitlist' component={WaitListForm} />
        <Route exact path='/contact' component={ContactPage} />
        <Route exact path='/register' component={RegisterForm} />
        <Route exact path='/login' component={LoginForm} />
        <Route exact path='/' component={LandingPage} />
        <Route path='/*' component={LoginForm}/>
        <Route render={() => this.props.children} />
      </Switch>
    )
  }
}
