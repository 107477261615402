import { resourceRSAA } from 'utils'

export const SCHEDULE_TEMPLATE_ACTION = 'SCHEDULE_TEMPLATE'
export function createScheduleTemplate(id, data) {
  return resourceRSAA(SCHEDULE_TEMPLATE_ACTION, `/api/schedule_templatable/${id}/schedule_templates`, {
    method: 'POST',
    body: JSON.stringify({ schedule_template: data }),
  })
}

export function getScheduleTemplate(id) {
  return resourceRSAA(SCHEDULE_TEMPLATE_ACTION, `/api/schedule_templates/${id}`)
}

export function updateScheduleTemplate(id, data) {
  return resourceRSAA(SCHEDULE_TEMPLATE_ACTION, `/api/schedule_templates/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ schedule_template: data }),
  })
}

export function deleteScheduleTemplate(id) {
  return resourceRSAA(SCHEDULE_TEMPLATE_ACTION, `/api/schedule_templates/${id}`, {
    method: 'DELETE',
  })
}

export function saveScheduleTemplateFromGig(gig_id, data) {
  return resourceRSAA(SCHEDULE_TEMPLATE_ACTION, `/api/gigs/${gig_id}/save_schedule`, {
    method: 'POST',
    body: JSON.stringify({ schedule_template: data }),
  })
}

export function applyScheduleTemplateToGig(id, gig_id, band_names) {
  return resourceRSAA(SCHEDULE_TEMPLATE_ACTION, `/api/schedule_templates/${id}/apply`, {
    method: 'PUT',
    body: JSON.stringify({
      gig_id,
      band_names,
    }),
  })
}
