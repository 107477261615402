import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { basicSearch } from 'actions/api'
import { withRouter } from 'react-router'

import LoadingState from 'components/LoadingState'
import SearchResults from 'components/navigation/SearchResults'
import { setUrlParams } from 'utils'

const INPUT_DEBOUNCE_TIME = 600 // 0.6s

class SearchBar extends LoadingState {

  constructor(props) {
    super(props)

    let options = []

    this.debounceRequestOptions = _.debounce(this.requestOptions, INPUT_DEBOUNCE_TIME)
    this.state = {
      ...this.state,
      input: '',
      options,
    }
  }

  stopProp(e) {
    e.stopPropagation()
  }

  requestOptions() {
    this.props.basicSearch(this.state.input).then(
      (response) => {
        if (response.error) {
          this.clearLoading()
        } else {
          this.setState({
            results: response.payload.results,
            is_loading: false,
          })
        }
      }
    )
  }

  onInputChange(e) {
    const input = e.target.value
    this.setState({ input, is_loading: true }, () => this.debounceRequestOptions())
  }

  onSubmit(e) {
    e.preventDefault()
    this.props.history.push(setUrlParams('/search', { q: this.state.input, page: 0 }))
    this.setState({ input: '' })
  }

  resetDropdown() {
    this.setState({ input: '' })
  }

  render() {
    return (
      <>
        <form className='mb-0 me-3' onSubmit={(e) => this.onSubmit(e)}>
          <input
            type='text'
            value={this.state.input || ''}
            onChange={(e) => this.onInputChange(e)}
            placeholder="Search Backline..."
            className='search-bar form-control me-2 mb-0'
          />
        </form>

        <SearchResults
          results={this.state.results || []}
          is_loading={this.state.is_loading}
          onDismiss={() => this.resetDropdown()}
          is_hidden={_.isEmpty(this.state.input)}
        />
      </>
    )
  }
}

export default connect(null, (dispatch) => bindActionCreators({
  basicSearch
}, dispatch))(withRouter(SearchBar))
