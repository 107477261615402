import { schema } from 'normalizr'

// TICKET_SALE
const ticket_sale_schema = new schema.Entity('ticket_sales')

// SCHEDULE_TEMPLATE
const schedule_template_schema = new schema.Entity('schedule_templates', {
  venue: new schema.Entity('venues'),
  band: new schema.Entity('bands'),
})

// DOCUMENT
const document_schema = new schema.Entity('documents')

// FINANCIAL DOCUMENT
const financial_document_schema = new schema.Entity('financial_documents')

// GUEST LIST SPOT
const guest_list_spot_schema = new schema.Entity('guest_list_spots')

// DRIVING DISTANCE
const driving_distance_schema = new schema.Entity('driving_distances')

// LODGING
const lodging_schema = new schema.Entity('lodgings', {
  band: new schema.Entity('bands'),
  associated_gigs: [
    new schema.Entity('gigs', {
      venue: new schema.Entity('venues')
    })
  ],
  driving_distance: driving_distance_schema,
})

// PERSON
const person_schema = new schema.Entity('people', {
  user: new schema.Entity('users'),
  bands: [new schema.Entity('bands')],
  venues: [new schema.Entity('venues')],
  pending_memberships: [new schema.Entity('memberships')],
})


// ATTACHMENT
const attachment_schema = new schema.Entity('attachments', { document: document_schema })

// CREW_MEMBER
const crew_member_schema = new schema.Entity('crew_members', {
  person: new schema.Entity('people'),
})

// MEMBERSHIP
const membership_schema = new schema.Entity('memberships', {
  person: new schema.Entity('people'),
})

// BAND
const band_schema = new schema.Entity('bands', {
  performances: new schema.Entity('performances'),
  schedule_templates: [ new schema.Entity('schedule_templates') ],
  memberships: [ membership_schema ],
  attachments: [ attachment_schema ],
})

// VENUE
const venue_schema = new schema.Entity('venues', {
  locations: new schema.Entity('locations'),
  schedule_templates: [ new schema.Entity('schedule_templates') ],
  memberships: [ membership_schema ],
  attachments: [ attachment_schema ],
})

// PERFORMANCE
const performance_schema = new schema.Entity('performances', {
  gig: new schema.Entity('gigs'),
  band: band_schema,
  contacts: [ crew_member_schema ],
  crew_members: [ crew_member_schema ],
  attachments: [ attachment_schema ],
  guest_list_spots: [ guest_list_spot_schema ],
})

// LOCATION
const location_schema = new schema.Entity('locations', {
  gig: new schema.Entity('gigs'),
  venue: venue_schema,
  contacts: [ crew_member_schema ],
  crew_members: [ crew_member_schema ],
  attachments: [ attachment_schema ],
})

// TICKET COUNT
const ticket_count_schema = new schema.Entity('ticket_counts')

// GIG
const gig_schema = new schema.Entity('gigs', {
  performances: [ performance_schema ],
  location: location_schema,
  lodgings: [ lodging_schema ],
  ticket_sales: [ ticket_sale_schema ],
  ticket_counts: [ ticket_count_schema ],
  financial_documents: [ financial_document_schema ],
  driving_distances: [ driving_distance_schema ],
})

// USER
const user_schema = new schema.Entity('users', {
  person: new schema.Entity('people', {
    bands: [new schema.Entity('bands')],
    venues: [new schema.Entity('venues')],
    pending_memberships: [new schema.Entity('memberships')],
  })
})

// TICKET INPUTTER
const ticket_inputter_schema = new schema.Entity('ticket_inputters')

export const Resources = {
  guest_list_spot: guest_list_spot_schema,
  guest_list_spots: [guest_list_spot_schema],

  schedule_template: schedule_template_schema,
  schedule_templates: [schedule_template_schema],

  user: user_schema,
  users: [user_schema],

  document: document_schema,
  documents: [document_schema],

  financial_document: financial_document_schema,
  financial_documents: [financial_document_schema],

  ticket_sale: ticket_sale_schema,
  ticket_sales: [ticket_sale_schema],

  person: person_schema,
  people: [person_schema],

  lodging: lodging_schema,
  lodgings: [lodging_schema],

  attachment: attachment_schema,
  attachments: [attachment_schema],

  crew_member: crew_member_schema,
  crew_members: [crew_member_schema],

  membership: membership_schema,
  memberships: [membership_schema],

  band: band_schema,
  bands: [band_schema],

  venue: venue_schema,
  venues: [venue_schema],

  performance: performance_schema,
  performances: [performance_schema],

  location: location_schema,
  locations: [location_schema],

  gig: gig_schema,
  gigs: [gig_schema],

  ticket_inputter: ticket_inputter_schema,
  ticket_inputters: [ticket_inputter_schema],

  ticket_count: ticket_count_schema,
  ticket_counts: [ticket_count_schema],

  driving_distance: driving_distance_schema,
  driving_distances: [driving_distance_schema],
}

