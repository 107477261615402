import React from 'react'
import moment from 'moment'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { DATE_FORMAT } from 'static'

export default class DateInput extends React.Component {

  onBlur() {
    if (this.props.onBlur) {
      this.props.onBlur()
    }
  }

  onChange(v) {
    if (this.props.onChange) {
      this.props.onChange(v)
    }
  }

  onRef(ref) {
    if (this.props.onRef) {
      this.props.onRef(ref)
    }
  }

  render() {
    if (window.is_mobile) {
      return (
        <input
          type='date'
          className='form-control'
          name={this.props.name}
          value={this.props.value}
          onChange={(e) => this.onChange(e.target.value)}
          onBlur={() => this.onBlur()}
          ref={(ref) => this.onRef(ref)}
          disabled={this.props.disabled}
        />
      )
    } else {
      return (
        <DatePicker
          className='form-control'
          selected={this.props.value ? moment(this.props.value) : null}
          onChange={(v) => this.onChange(v.format(DATE_FORMAT))}
          onBlur={() => this.onBlur()}
          autoFocus={this.props.auto_focus}
          disabled={this.props.disabled}
        />
      )
    }
  }

}
