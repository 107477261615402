import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getLocation } from 'actions/api/locations'

import Row from 'components/Row'
import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import LoadingState from 'components/LoadingState'

import ShowContacts from 'components/contacts/ShowContacts'
import ShowAddress from 'components/addresses/ShowAddress'
import ShowScheduleItems from 'components/schedule/ShowScheduleItems'
import RenderValue from 'components/RenderValue'
import ShowPhone from 'components/people/ShowPhone'
import PersonnelList from 'components/crew_members/PersonnelList'
import AttachmentsList from 'components/documents/AttachmentsList'
import EditableTextArea from 'components/gigs/editable/EditableTextArea'
import LocationAdvanceProgress from 'components/locations/LocationAdvanceProgress'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

class LocationAdvanceSummary extends LoadingState {

  loadLocation(props = this.props) {
    this.setLoading(() => props.getLocation(props.location_id).then(() => this.clearLoading()))
  }

  componentWillMount() {
    this.loadLocation()
  }

  componentWillReceiveProps(props) {
    if (props.location_id !== this.props.location_id) {
      this.loadLocation(props)
    }
  }

  render() {
    if (!this.props.location_model) { return null }

    const location = this.props.location_model || {}
    const gig = location.gig || {}
    const venue = location.venue || {}
    const perspective = gig.perspective || {}
    const attachments = location.attachments || []
    const contacts = location.contacts || []

    return (
      <div className='p-2'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <h5>Venue Details</h5>

            <div className='border rounded p-3 bg-light mb-3'>
              <a
                target="_blank"
                href={venue.address && venue.address.maps_url}
                className='d-block mb-3'
              >
                <strong>
                  <ShowAddress
                    address={venue.address}
                  />
                </strong>
              </a>
              {venue.main_phone && (
                <div> <strong>Main Phone</strong>: <ShowPhone number={venue.main_phone}/> </div>
              )}
              {venue.box_office_phone && (
                <div> <strong>Box Office Phone</strong>: <ShowPhone number={venue.box_office_phone}/> </div>
              )}
              <RenderValue value={venue.capacity} label='Capacity' />
              <RenderValue class_name='prewrap' value={venue.parking} label='Parking' />
              <RenderValue class_name='prewrap' value={venue.load_in_info} label='Load-In Info' />
              <RenderValue class_name='prewrap' value={venue.green_room_info} label='Green Room Info' />
              <RenderValue value={venue.wifi} label='Wifi' />
              <RenderValue value={venue.wifi_password} label='Wifi Password' />
              {venue.has_laundry && (<div>Laundry Included</div>)}
              {venue.has_showers && (<div>Showers Included</div>)}
            </div>

            <div className='mb-3'>
              <AttachmentsList attachments={attachments} />
            </div>

            <div className='mb-3'>
              <EditableTextArea
                value={location.merch_info}
                title='Merch Info'
              />
            </div>

            <div className='mb-3'>
              <EditableTextArea
                value={location.hospitality_details}
                title='Hospitality Details'
              />
            </div>
          </div>

          <div className='col-12 col-lg-6 mb-3'>
            <h5>Schedule</h5>
            <div className='mb-3'>
              <ShowScheduleItems
                perspective={perspective}
                gig={gig}
                force_tba_empty
              />
            </div>

            <div className='mb-3'>
              {_.isEmpty(contacts) ? (
                <>
                  <h5>Contacts</h5>
                  <div className='border rounded p-3 bg-light text-center'>
                    <strong>No Contacts Entered</strong>
                  </div>
                </>
              ) : (
                <ShowContacts
                  contacts={contacts}
                  show_avatar
                  show_is_on_site
                  hide_invite_button
                />
              )}
            </div>

            <div className='mb-3'>
              {_.isEmpty(_.get(location, 'crew_members', [])) ? (
                <>
                  <h5>Personnel</h5>
                  <div className='border rounded p-3 bg-light text-center'>
                    <strong>No Personnel</strong>
                  </div>
                </>
              ) : (
                <PersonnelList
                  crewable={location}
                  hide_invite
                />
              )}
            </div>

            <div className='mb-3'>
              <EditableTextArea title='Additional Show Notes' value={location.show_notes} />
            </div>
          </div>
        </div>
        {this.renderLoadingPopup()}
      </div>
    )
  }
}

export default connect((state, ownProps) => ({
  location_model: selectModel('locations', ownProps.location_id, Resources.location, state),
}), (dispatch) => bindActionCreators({
  getLocation
}, dispatch))(LocationAdvanceSummary)
