import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { adminGetPersonEmailInvites } from 'actions/api/admin/people'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import PaginatedList from 'components/PaginatedList'

class EmailInvitesList extends PaginatedList {

  requestListData(page) {
    return this.props.adminGetPersonEmailInvites(this.props.person_id, page)
  }

  getListFromData(data) {
    return data.data
  }

  render() {
    return (
      <div>
        {this.renderPageButtons()}
        <table className='table table-striped hover'>
          <thead>
            <tr>
              <th>Invite ID</th>
              <th>Name</th>
              <th>User Name</th>
              <th>Created</th>
            </tr>
          </thead>

          <tbody>
            {_.map(this.state.list, (i) => (
              <tr key={i.id}>
                <td> {i.id} </td>
                <td> <SafeLink href={`/admin${i.inviter_url}`}>{i.inviter_name}</SafeLink> </td>
                <td> {i.inviter_username} </td>
                <td> {moment(i.created_at).fromNow()} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  adminGetPersonEmailInvites
}, dispatch))(EmailInvitesList)
