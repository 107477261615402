import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import { connect } from 'react-redux'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { selectModel } from 'selectors'
import {
  getGuestListSpots,
  updateGuestListSpot,
  updateGuestList,
  createGuestListSpot,
  deleteGuestListSpot
} from 'actions/api/guest_list_spots'
import { updatePerformance } from 'actions/api/performances'

import { PERSPECTIVE_TYPE } from 'static'

import { isEditableByPerspective } from 'utils'

import SafeLink from 'components/SafeLink'
import BreadCrumbs from 'components/BreadCrumbs'
import PageTitle from 'components/PageTitle'
import EditableList from 'components/EditableList'
import EditableGuestListSpot from 'components/guest_list/EditableGuestListSpot'
import EditableNumComps from 'components/performances/EditableNumComps'

const SORT_ENUM = {
  TIX_ASC: 'num_tickets;asc',
  TIX_DESC: 'num_tickets;desc',
  NAME_ASC: 'name;asc',
  NAME_DESC: 'name;desc',
  GUEST_ASC: 'guest_type;asc',
  GUEST_DESC: 'guest_type;desc',
  NOTES_ASC: 'notes;asc',
  NOTES_DESC: 'notes;desc',
  CREATE_ASC: 'created_at;asc',
  CREATE_DESC: 'created_at;desc',
}

class EditableGuestList extends EditableList {

  constructor(props) {
    super(props)

    this.state = {
      show_mobile_notes: false,
      sort_value: SORT_ENUM.NAME_ASC,
      ...this.state,
    }
  }

  canEditNumComps() {
    const performance = this.props.performance || {}
    const perspective = this.props.perspective || {}
    return isEditableByPerspective(perspective, performance, perspective.gig_permissions)
  }

  updatePerformance(update_data) {
    const performance = this.props.performance || {}
    this.setLoading(() =>
      this.props.updatePerformance(performance.id, update_data).then((response) =>
        this.clearLoading()))
  }

  // Override
  getIdFromProps(props) { return props.performance_id }

  // Override
  getDataFromListItem(li) {
    return _.pick(li, ['name', 'guest_type', 'notes', 'num_tickets', 'created_at'])
  }

  // Override
  getListItemFromResponse(response) {
    return _.get(response, 'payload.raw.guest_list_spot', {})
  }

  // Override
  getListFromResponse(response) {
    return _.get(response, 'payload.raw.performance.guest_list_spots', [])
  }

  // Override
  isEditable() {
    const performance = _.get(this.props, 'performance', {})
    const perspective = _.get(this.props, 'perspective', {})
    return isEditableByPerspective(perspective, performance, perspective.guest_list_permissions)
  }

  // Override
  getAddButtonText() {
    return "+ Add Guest"
  }

  /**********************************
   * REQUESTS
   **********************************/
  // Override
  loadListDataRequest(props) {
    return this.props.getGuestListSpots(props.performance_id)
  }

  // Override
  updateListItemRequest(id, data) {
    return this.props.updateGuestListSpot(id, data)
  }

  // Override
  deleteListItemRequest(id) {
    return this.props.deleteGuestListSpot(id)
  }

  // Override
  createListItemRequest(data) {
    return this.props.createGuestListSpot(this.props.performance_id, data)
  }

  // Override
  saveListRequest() {
    return this.props.updateGuestList(this.props.performance_id, this.state.editable_list)
  }

  showNotes() {
    return this.isEditable()
  }

  sortEditableList(list) {
    if (this.state.sort_value) {
      const [fieldName, direction] = this.state.sort_value.split(';')
      return _.orderBy(list, [fieldName], [direction])
    }

    return list
  }

  onSortList() {
    this.setState({ editable_list: this.sortEditableList(this.state.editable_list) })
  }

  updateSortValue(prefix) {
    let new_sort_value = SORT_ENUM[`${prefix}_ASC`]
    if (this.state.sort_value === SORT_ENUM[`${prefix}_ASC`]) {
      new_sort_value = SORT_ENUM[`${prefix}_DESC`]
    }

    this.setState({ sort_value: new_sort_value }, () => {
      this.onSortList()
    })
  }

  getSortOptions() {
    return [
      { label: '# Tix Ascending', value: SORT_ENUM.TIX_ASC },
      { label: '# Tix Descending', value: SORT_ENUM.TIX_DESC },
      { label: 'Name Ascending', value: SORT_ENUM.NAME_ASC },
      { label: 'Name Descending', value: SORT_ENUM.NAME_DESC },
      { label: 'Guest Type Ascending', value: SORT_ENUM.GUEST_ASC },
      { label: 'Guest Type Descending', value: SORT_ENUM.GUEST_DESC },
      { label: 'Notes Ascending', value: SORT_ENUM.NOTES_ASC },
      { label: 'Notes Descending', value: SORT_ENUM.NOTES_DESC },
    ]
  }

  renderMobileSort() {
    return (
      <Select
        name="guest_list_sort"
        placeholder="Sort by..."
        className="mt-2 mb-2"
        options={this.getSortOptions()}
        value={this.state.sort_value}
        onChange={(v) => this.setState({ sort_value: v.value })}
      />
    )
  }

  renderSortableHeader({ width = '', prefix, label }) {
    const active = [SORT_ENUM[`${prefix}_ASC`], SORT_ENUM[`${prefix}_DESC`]].includes(this.state.sort_value)
    if (active) {
      return (
        <th
          className={classNames("sort-header", { active })}
          width={width}
          onClick={() => this.updateSortValue(prefix)}
        >
          <span>{label}</span>
          <i className={classNames("fi-play", "icon-medium", "sort-icon", {
            "ascending": this.state.sort_value === SORT_ENUM[`${prefix}_ASC`],
            "descending": this.state.sort_value === SORT_ENUM[`${prefix}_DESC`],
          })} />
        </th>
      )
    }

    return <th className="sort-header" width={width} onClick={() => this.updateSortValue(prefix)}>{label}</th>
  }

  renderMobile() {
    const gig = _.get(this, 'props.performance.gig', {})
    const performance = _.get(this, 'props.performance', {})
    return (
      <div className='container'>
        <BreadCrumbs items={[
          { name: 'Shows', url: '/' },
          { name: gig.gig_name || gig.default_name, url: gig.url },
          { name: performance.band_name, url: performance.url },
          { name: 'Guest List' },
        ]} />

        {this.renderTitle()}
        {this.renderMobileSort()}
        {this.showNotes() && (
          <div>
            <a
              onClick={() => this.setState({ show_mobile_notes: !this.state.show_mobile_notes })}
              className='link text-decoration-none'
            >
              {this.state.show_mobile_notes ? 'hide notes' : 'show notes'}
            </a>
          </div>
        )}
        <div className='half-margin-bottom'>
          {_.map(this.state.editable_list, (g, i) => (
            <EditableGuestListSpot
              key={`guest_list_spot-${g.id}-${i}`}
              guest_list_spot={g}
              onUpdate={this.genUpdateListItem(i)}
              onSave={this.genSaveListItem(i)}
              onDelete={() => this.deleteListItem(i)}
              onEdit={() => this.editListItem(i)}
              show_notes={this.showNotes() && this.state.show_mobile_notes}
              is_editable={this.isEditable()}
              is_disabled={this.state.loading_all || this.state.is_loading}
              is_mobile
            />
          ))}
        </div>
        {this.state.is_loading && this.renderLoadingBlock()}
        {this.renderButtons()}
      </div>
    )
  }

  renderDesktop() {
    const gig = _.get(this, 'props.performance.gig', {})
    const performance = _.get(this, 'props.performance', {})
    return (
      <div className='container'>
        <BreadCrumbs items={[
          { name: 'Shows', url: '/' },
          { name: gig.gig_name || gig.default_name, url: gig.url },
          { name: performance.band_name, url: performance.url },
          { name: 'Guest List' },
        ]} />

        {this.renderTitle()}
        <table className='table table-striped align-middle'>
          <thead>
            <tr>
              {this.renderSortableHeader({ width: '80', label: '# Tix', prefix: 'TIX' })}
              {this.renderSortableHeader({ label: 'Name', prefix: 'NAME' })}
              {this.renderSortableHeader({ label: 'Guest Type', prefix: 'GUEST' })}
              {this.showNotes() && this.renderSortableHeader({ label: 'Notes', prefix: 'NOTES' })}
              {this.renderSortableHeader({ label: 'Date Added', prefix: 'CREATE' })}
              {this.isEditable() && (
                <th width='65'></th>
              )}
            </tr>
          </thead>
          <tbody>
            {_.map(this.state.editable_list, (g, i) => (
              <EditableGuestListSpot
                key={`guest_list_spot-${g.id}-${i}`}
                guest_list_spot={g}
                onUpdate={this.genUpdateListItem(i)}
                onSave={this.genSaveListItem(i)}
                onDelete={() => this.deleteListItem(i)}
                onEdit={() => this.editListItem(i)}
                show_notes={this.showNotes()}
                is_editable={this.isEditable()}
                is_disabled={this.state.loading_all || this.state.is_loading}
              />
            ))}
          </tbody>
        </table>
        {this.state.is_loading && this.renderLoadingBlock()}
        {this.renderButtons()}
      </div>
    )
  }

  renderTitle() {
    if (this.props.hide_title) { return null }
    const perspective = this.props.perspective || {}
    const performance = this.props.performance || {}
    return(
      <>
        <PageTitle>
          Guest List
        </PageTitle>
        <h4>
          {performance.band_name}
        </h4>

        <div>
          <EditableNumComps
            update={(update) => this.updatePerformance(update)}
            num_comps={performance.num_comps}
            is_editable={this.canEditNumComps()}
            comps_used={performance.comps_used}
          />
        </div>

        <div>
          {(perspective.is_headline || perspective.type === PERSPECTIVE_TYPE.VENUE) && (
            <span>
              <SafeLink href={`${performance.gig_url}/guest_list/master`}>
                See Master Guest List
              </SafeLink>&nbsp;|&nbsp;
            </span>
          )}
          {window.is_mobile ? (
            <a href={`${performance.url}/printable_guest_list`}>
              <i className='fi-print'></i>&nbsp;Printable View
            </a>
          ) : (
            <a target='_blank' href={`${performance.url}/printable_guest_list`}>
              <i className='fi-print'></i>&nbsp;Printable View
            </a>
          )}
        </div>
      </>
    )
  }

  render() {
    if (window.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

}


export default connect((state, ownProps) => ({
  performance: selectModel('performances', ownProps.performance_id, Resources.performance, state),
}), (dispatch) => bindActionCreators({
  getGuestListSpots,
  updateGuestListSpot,
  updateGuestList,
  createGuestListSpot,
  deleteGuestListSpot,
  updatePerformance
}, dispatch))(EditableGuestList)
