import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sendClientError } from 'actions/api'

import { iff } from 'utils'

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error, info) {
    this.setState({ isError: true })
    this.props.sendClientError(`${this.props.errorTag}${error.message}`)
    iff(this.props.callback)
  }

  render() {
    if (this.state.isError) {
      if (this.props.showError) {
        return (
          <div className='text-center alert alert-warning'>
            {this.props.errorMessage}
          </div>
        )
      } else {
        return null
      }
    }

    return this.props.children
  }
}


export default connect(null, (dispatch) => bindActionCreators({
  sendClientError
}, dispatch))(ErrorBoundary)
