import React from 'react'

import { iff } from 'utils'
import { Link } from 'react-router-dom'

export default ({ to, href, className, disabled, onClick, children }) => {
  const url = to || href
  if (disabled) {
    return (
      <a className={className} disabled={disabled}>{children}</a>
    )
  } else if (url) {
    return (
      <Link to={url} className={className} disabled={disabled} onClick={() => iff(onClick)}>{children}</Link>
    )
  } else {
    return (
      <a href={url} className={className} disabled={disabled}>{children}</a>
    )
  }
}
