import React from 'react'
import _ from 'lodash'
import Linkify from 'react-linkify'

import { Switch, Route } from 'react-router-dom'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { adminGetVenue } from 'actions/api/admin/venues'

import Tabs from 'components/Tabs'
import ShowAddress from 'components/addresses/ShowAddress'
import ShowPhone from 'components/people/ShowPhone'
import OrgMembersPage from 'components/OrgMembersPage'
import EditableDocumentsList from 'components/documents/EditableDocumentsList'
import ScheduleTemplateList from 'components/schedule_templates/ScheduleTemplateList'
import RenderValue from 'components/RenderValue'
import AdminHeader from 'components/admin/AdminHeader'
import PageTitle from 'components/PageTitle'
import SafeLink from 'components/SafeLink'
import Avatar from 'components/Avatar'
import VenueGigsList from 'components/admin/venues/VenueGigsList'

import {
  formatUrl
} from 'utils'

import {
  VENUE_DOCUMENT_NAMES,
  PERSPECTIVE_TYPE
} from 'static'

class VenuePage extends React.Component {

  componentWillMount() {
    this.props.adminGetVenue(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      props.adminGetVenue(props.match.params.id)
    }
  }

  renderDetails() {
    const venue = this.props.venue || {}
    return (
      <div className='card p-3'>
        <div> Main Phone: <ShowPhone number={venue.main_phone} /> </div>
        <div> Box Office Phone: <ShowPhone number={venue.box_office_phone} /> </div>
        <RenderValue value={venue.capacity} label='Capacity' />
        <RenderValue value={venue.wifi} label='Wifi' />
        <RenderValue value={venue.wifi_password} label='Wifi Password' />
        <RenderValue class_name='prewrap' value={venue.parking} label='Parking' />
        <RenderValue class_name='prewrap' value={venue.load_in_info} label='Load-In Info' />
        {venue.has_showers && (
          <div><strong>Showers Available</strong></div>
        )}
        {venue.has_laundry && (
          <div><strong>Laundry Available</strong></div>
        )}
      </div>
    )
  }

  renderVenueDetails() {
    const venue = this.props.venue || {}
    return (
      <div className='p-2'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <h5>Public Details</h5>
            <div className='card p-3'>
              <a
                target="_blank"
                className='mb-3 block'
                href={venue.address && venue.address.maps_url}
              > <ShowAddress address={venue.address} /> </a>

              <div className='mb-3'>
                {venue.main_phone && (
                  <div> <strong>Main Phone:</strong> <ShowPhone number={venue.main_phone}/> </div>
                )}
                {venue.box_office_phone && (
                  <div> <strong>Box Office Phone:</strong> <ShowPhone number={venue.box_office_phone}/> </div>
                )}
                <RenderValue value={venue.capacity} label='Capacity' />
              </div>

              <a
                target="_blank"
                href={formatUrl(venue.website)}
              >{venue.website}</a>
              <Linkify properties={{ target: '_blank' }}>
                <p className='prewrap'>{_.trim(venue.bio)}</p>
              </Linkify>
            </div>
          </div>

          <div className='col-12 col-md-6 mt-3 mt-md-0'>
            <h5>Private Details</h5>
            <div className='card p-3'>
              <RenderValue value={venue.wifi} label='Wifi' />
              <RenderValue value={venue.wifi_password} label='Wifi Password' />
              <RenderValue linkify class_name='prewrap' value={venue.parking} label='Parking' />
              <RenderValue linkify class_name='prewrap' value={venue.load_in_info} label='Load-In Info' />
              <RenderValue linkify class_name='prewrap' value={venue.green_room_info} label='Green Room Info' />
              {venue.has_showers && (
                <div><strong>Showers Available</strong></div>
              )}
              {venue.has_laundry && (
                <div><strong>Laundry Available</strong></div>
              )}
            </div>
          </div>

          {!_.isNil(venue.default_merch_info) && (
            <div className='col-12 col-md-6'>
              <h5>Default Merch Info</h5>
              <div className='card p-3'>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap m-0'>{_.trim(venue.default_merch_info)}</p>
                </Linkify>
              </div>
            </div>
          )}

          {!_.isNil(venue.default_hospitality_details) && (
            <div className='col-12 col-md-6'>
              <h5>Default Hospitality Details</h5>
              <div className='card p-3'>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap m-0'>{_.trim(venue.default_hospitality_details)}</p>
                </Linkify>
              </div>
            </div>
          )}

          {!_.isNil(venue.default_show_notes) && (
            <div className='col-12 col-md-6'>
              <h5>Default Show Notes</h5>
              <div className='card p-3'>
                <Linkify properties={{ target: '_blank' }}>
                  <p className='prewrap m-0'>{_.trim(venue.default_show_notes)}</p>
                </Linkify>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const venue = _.get(this, 'props.venue', {})
    const memberships = _.get(venue, 'memberships', [])
    const attachments = _.get(venue, 'attachments', [])
    const schedule_templates = _.get(venue, 'schedule_templates', [])

    if (_.isNil(venue.id)) { return null }

    return (
      <div className="container">
        <AdminHeader />
        {venue.is_test && (
          <div className='test-data'>
            TEST DATA
          </div>
        )}

        <div className='clearfix'>
          <Avatar
            url={venue.avatar_url}
            classes='float-left mt-2'
          />
          <PageTitle
            is_editable
            edit_link={`/admin${venue.url}/edit`}
            subtitle={venue.username &&
              <h5>@{venue.username}</h5>
            }
          >
            {venue.name}
          </PageTitle>
        </div>

        <div className='mb-3'>
          <SafeLink to={venue.url}>View on Backline.me</SafeLink>
        </div>

        <Tabs tabs={[
          {
            name: 'Details',
            url: `/admin${venue.url}`,
            show_tab: true,
          }, {
            name: 'Members',
            url: `/admin${venue.url}/members`,
            show_tab: true,
          }, {
            name: 'Documents',
            url: `/admin${venue.url}/documents`,
            show_tab: true,
          }, {
            name: 'Schedule Templates',
            url: `/admin${venue.url}/schedule_templates`,
            show_tab: true,
          }, {
            name: 'Shows',
            url: `/admin${venue.url}/shows`,
            show_tab: true,
          }
        ]} />

        <Switch>
          <Route exact path='/admin/venues/:id/members' render={() => (
            <div className='p-2'>
              <OrgMembersPage page_type={PERSPECTIVE_TYPE.VENUE} org={venue} />
            </div>
          )} />
          <Route exact path='/admin/venues/:id/documents' render={() => (
            <div className='p-2'>
              <EditableDocumentsList
                attachable={venue}
                attachments={attachments}
                name_options={VENUE_DOCUMENT_NAMES}
                is_editable
              />
            </div>
          )} />
          <Route exact path='/admin/venues/:id/schedule_templates' render={() => (
            <div className='p-2'>
              <ScheduleTemplateList
                venue={venue}
                schedule_templates={schedule_templates}
                is_editable
              />
            </div>
          )} />
          <Route exact path='/admin/venues/:id/shows' render={() => (
            <div className='p-2'>
              <VenueGigsList venue_id={venue.id} />
            </div>
          )} />
          <Route render={() => this.renderVenueDetails()} />
        </Switch>

      </div>
    )
  }

}

export default connect((state, ownProps) => ({
  venue: selectModel('venues', ownProps.match.params.id, Resources.venue, state),
}), (dispatch) => bindActionCreators({
  adminGetVenue
}, dispatch))(VenuePage)
