import React from 'react'
import ReactTooltip from 'react-tooltip'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setInviteUserOverlay } from 'actions/ui'

class InviteButton extends React.Component {

  render() {
    const person = this.props.person || {}
    const invite_hover_id = `person-invite-hover-${person.id}`
    return (
      <span>
        <a
          onClick={() => this.props.setInviteUserOverlay(person)}
          className='d-inline-block badge bg-primary text-light text-decoration-none pointer hover'
          data-for={invite_hover_id}
          data-tip
        >
          <i className='fi-mail'></i> invite
        </a>
        <ReactTooltip
          id={invite_hover_id}
          effect='solid'
          place='bottom'
        >
          Send Invite to Backline.me
        </ReactTooltip>
      </span>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  setInviteUserOverlay
}, dispatch))(InviteButton)
