import React from 'react'

import SafeLink from 'components/SafeLink'
import BottomBar from 'components/BottomBar'

import { withRouter } from 'react-router'

class LodgingBottomBar extends React.Component {

  componentWillMount() {
    this.handle_key_press = this.handleKeyPress.bind(this)
  }

  componentWillUnmount() {
    if (!window.is_mobile && window.use_gig_arrow_keys) {
      document.removeEventListener("keydown", this.handle_key_press)
    }
  }

  handleKeyPress(event) {
    switch (event.key) {
      case 'ArrowLeft':
        if (this.props.lodging.previous_lodging_url) {
          event.preventDefault()
          this.props.history.push(this.props.lodging.previous_lodging_url)
        }
        break;
      case 'ArrowRight':
        if (this.props.lodging.next_lodging_url) {
          event.preventDefault()
          this.props.history.push(this.props.lodging.next_lodging_url)
        }
        break;
    }
  }

  render() {
    const lodging = this.props.lodging || {}
    if (!lodging.previous_lodging_url && !lodging.next_lodging_url) {
      return null
    }

    return (
      <div>
        <div className='spacer'></div>

        <div className='mb-5 pb-3'></div>
        <div className='bottom-bar'>
          <div className='container d-flex flex-row p-1'>
            <div className='flex-grow-1 text-start'>
              {lodging.previous_lodging_url && (
                <SafeLink
                  className='btn btn-link fw-bold text-decoration-none'
                  to={lodging.previous_lodging_url}
                >&lt; Previous</SafeLink>
              )}
            </div>

            <div className='flex-grow-1 text-end'>
              {lodging.next_lodging_url && (
                <SafeLink
                  className='btn btn-link fw-bold text-decoration-none'
                  href={lodging.next_lodging_url}
                >Next &gt;</SafeLink>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(LodgingBottomBar)
