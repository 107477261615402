import { basicGet } from 'actions/api'
import { resourceRSAA } from 'utils'

export const VENUE_ACTION = 'VENUE'

export function getVenue(id) {
  return resourceRSAA(VENUE_ACTION, `/api/venues/${id}`)
}

export function updateVenue(id, data) {
  return resourceRSAA(VENUE_ACTION, `/api/venues/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ venue: data }),
  })
}

export function createVenue(data) {
  return resourceRSAA(VENUE_ACTION, `/api/venues/`, {
    method: 'POST',
    body: JSON.stringify({ venue: data }),
  })
}

export function searchVenues(query, page) {
  return basicGet(`/api/venues/search`, VENUE_ACTION, { q: query, page: page })
}

export function getVenueSharedGigs(id, page) {
  return basicGet(`/api/venues/${id}/shared_gigs`, VENUE_ACTION, { page })
}

export function getVenueCrewMemberPeople(id) {
  return resourceRSAA(VENUE_ACTION, `/api/venues/${id}/crew_member_people`)
}
