import { resourceRSAA } from 'utils'

export const PERFORMANCE_ACTION = 'PERFORMANCE'

export function getPerformance(id) {
  return resourceRSAA(PERFORMANCE_ACTION, `/api/performances/${id}`)
}

export function updatePerformance(id, data) {
  return resourceRSAA(PERFORMANCE_ACTION, `/api/performances/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ performance: data }),
  })
}

export function deletePerformance(id) {
  return resourceRSAA(PERFORMANCE_ACTION, `/api/performances/${id}`, {
    method: 'DELETE',
  })
}

export function createPerformance(gig_id, data) {
  return resourceRSAA(PERFORMANCE_ACTION, `/api/gigs/${gig_id}/performances/`, {
    method: 'POST',
    body: JSON.stringify({ performance: data }),
  })
}

export function acceptPerformance(id) {
  return resourceRSAA(PERFORMANCE_ACTION, `/api/performances/${id}/accept`)
}

export function rejectPerformance(id) {
  return resourceRSAA(PERFORMANCE_ACTION, `/api/performances/${id}/reject`)
}

export function generatePerformanceAdvanceLink(id) {
  return resourceRSAA(PERFORMANCE_ACTION, `/api/performances/${id}/advance_link`)
}

export function sendPerformanceAdvanceLink(id, data) {
  return resourceRSAA(PERFORMANCE_ACTION, `/api/performances/${id}/send_advance_link`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}
