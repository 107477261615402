import React from 'react'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'

import {
  GIG_COMPLETENESS
} from 'static'

export default class GigCompleteness extends React.Component {

  render() {
    const gig = this.props.gig || {}
    if (!gig.completeness) { return null }

    const completeness = gig.completeness || {}
    const button_color = GIG_COMPLETENESS[completeness.status]
    const hover_id = `gig-tooltip-${gig.id}`
    return (
      <>
        <div
          className={classnames([ 'gig-status', button_color ])}
          data-for={hover_id}
          data-tip
        ></div>
        {this.props.children}
        {!_.isEmpty(completeness.missing_info) && completeness.missing_info.length > 0 &&
          <ReactTooltip
            id={hover_id}
            effect='solid'
            place='bottom'
          >
            <h5>Missing Info</h5>
            <ul>
              {_.map(completeness.missing_info.split("\n"), (message, i) => (
                <li key={i}>{message}</li>
              ))}
            </ul>
          </ReactTooltip>
        }
      </>
    )
  }

}
