import { resourceRSAA, jsonRSAA } from 'utils'

export const GIG_ACTION = 'GIG'
export function getGig(id) {
  return resourceRSAA(GIG_ACTION, `/api/gigs/${id}`)
}

export function updateGig(id, data) {
  return resourceRSAA(GIG_ACTION, `/api/gigs/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ gig: data }),
  })
}

export function leaveGig(id) {
  return resourceRSAA(GIG_ACTION, `/api/gigs/${id}/leave`)
}

export function createGig(data) {
  return resourceRSAA(GIG_ACTION, `/api/gigs`, {
    method: 'POST',
    body: JSON.stringify({ gig: data }),
  })
}

export const PENDING_GIGS_ACTION = 'PENDING_GIGS'
export function getPendingGigs() {
  return jsonRSAA(PENDING_GIGS_ACTION,`/api/user/pending_gigs`)
}

export const GIG_TICKETS_ACTION = 'GIG_TICKETS'
export function getGigTickets(id) {
  return resourceRSAA(GIG_TICKETS_ACTION, `/api/gigs/${id}/tickets`)
}

export function updateGigTickets(id, data) {
  return resourceRSAA(GIG_TICKETS_ACTION, `/api/gigs/${id}/tickets`, {
    method: 'PUT',
    body: JSON.stringify({ gig: data })
  })
}

export const UPCOMING_GIGS_ACTION = 'UPCOMING_GIGS_ACTION'
export function getUpcomingGigs(page, perspective = null) {
  const { value, type } = perspective || {}
  let path = `/api/gigs?page=${page}`

  if (!_.isNil(value) && !_.isNil(type)) {
    path = `${path}&perspective_id=${value}&perspective_type=${type}`
  }

  return resourceRSAA(UPCOMING_GIGS_ACTION, path)
}

export const PAST_GIGS_ACTION = 'PAST_GIGS_ACTION'
export function getPastGigs(page, perspective = null) {
  const { value, type } = perspective || {}
  let path = `/api/gigs?page=${page}&is_past=true`

  if (!_.isNil(value) && !_.isNil(type)) {
    path = `${path}&perspective_id=${value}&perspective_type=${type}`
  }

  return resourceRSAA(PAST_GIGS_ACTION, path)
}

export const GIG_TICKET_COUNTS_ACTION = 'GIG_TICKET_COUNTS'
export function getGigTicketCounts(id) {
  return resourceRSAA(GIG_TICKET_COUNTS_ACTION, `/api/gigs/${id}/ticket_counts`)
}

export function createGigTicketCount(id, data) {
  let path = `/api/gigs/${id}/ticket_counts`

  return resourceRSAA(GIG_TICKET_COUNTS_ACTION, path, {
    method: 'POST',
    body: JSON.stringify({ ticket_count: data }),
  })
}

export function updateGigTicketCount(gigId, data) {
  let path = `/api/gigs/${gigId}/ticket_counts`

  return resourceRSAA(GIG_TICKET_COUNTS_ACTION, path, {
    method: 'PUT',
    body: JSON.stringify({ ticket_count: data }),
  })
}

export function approveSchedule(gigId) {
  let path = `/api/gigs/${gigId}/approve_schedule`
  return resourceRSAA(GIG_TICKET_COUNTS_ACTION, path, { method: 'PUT' })
}
