import { resourceRSAA } from 'utils'

export const CREW_MEMBER_ACTION = 'CREW_MEMBER'

export function getCrewMember(id) {
  return resourceRSAA(CREW_MEMBER_ACTION, `/api/crew_members/${id}`)
}

export function updateCrewMember(id, data) {
  return resourceRSAA(CREW_MEMBER_ACTION, `/api/crew_members/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ crew_member: data }),
  })
}

export function createCrewMember(org_url, data) {
  return resourceRSAA(CREW_MEMBER_ACTION, `/api${org_url}/crew_members/`, {
    method: 'POST',
    body: JSON.stringify({ crew_member: data })
  })
}

export function deleteCrewMember(id) {
  return resourceRSAA(CREW_MEMBER_ACTION, `/api/crew_members/${id}`, { method: 'DELETE' })
}

export function acceptCrewMember(id) {
  return resourceRSAA(CREW_MEMBER_ACTION, `/api/crew_members/${id}/accept`)
}

export function rejectCrewMember(id) {
  return resourceRSAA(CREW_MEMBER_ACTION, `/api/crew_members/${id}/reject`)
}
