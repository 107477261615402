import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getNotifications,
  dismissAllNotifications,
  notificationClicked
} from 'actions/api/notifications'

import EndlessList from 'components/EndlessList'

class NotificationDropdown extends EndlessList {

  stopProp(e) {
    e.stopPropagation()
  }

  requestListData(page) {
    return this.props.getNotifications(page)
  }

  getListFromData(data) {
    return data.notifications
  }

  markNoteClicked(index) {
    this.setState({
      list: [
        ...this.state.list.slice(0, index),
        {
          ...this.state.list[index],
          is_clicked: true,
          is_viewed: true,
        },
        ...this.state.list.slice(index+1),
      ]
    })
  }

  markAllRead() {
    this.props.dismissAllNotifications()
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  noteClicked(note, i) {
    this.markNoteClicked(i)

    if (note.url) {
      this.props.notificationClicked(note.id)
      window.location = note.url
    }
  }

  renderNotifications() {
    if (this.state.list.length > 0) {
      return _.map(this.state.list, (note, i) => (
        <a
          className='item clearfix text-decoration-none'
          key={`notification-${note.id}-${i}`}
          onClick={() => this.noteClicked(note, i)}
        >
          {note.image_url &&
            <img
              className='notification-image'
              src={note.image_url}
            />
          }
          {(!note.is_viewed || (!note.is_clicked && note.url)) && (
            <div className={classnames({
              'dot': true,
              'unread': !note.is_viewed,
              'unclicked': note.is_viewed && !note.is_clicked && note.url,
            })}></div>
          )}
          <div className='date'>
            {moment(note.created_at).fromNow()}
          </div>
          {note.description}
        </a>
      ))
    } else {
      return (
        <div className='item'>
          No notifications
        </div>
      )
    }
  }

  renderSeeMore() {
    if (this.state.page + 1 < this.state.num_pages) {
      if (this.state.is_loading) {
        return (
          <span>Loading...</span>
        )
      } else {
        return (
          <a
            className='item small-button text-decoration-none'
            onClick={() => this.getMoreItems()}
          >
            see more
          </a>
        )
      }
    }
  }

  render() {
    return (
      <>
        <div className='notifications-dropdown-wrapper d-none d-md-block'>
          <div
            className={classnames('notifications-dropdown', { 'hidden': this.props.is_hidden })}
            onClick={(e) => this.stopProp(e)}
          >
            <a
              className='item small-button text-decoration-none'
              onClick={() => this.markAllRead()}
            >
              mark all read
            </a>
            {this.renderNotifications()}
            {this.renderSeeMore()}
          </div>
        </div>

        <div className='notifications-dropdown-wrapper mobile d-block d-md-none'>
          <div
            className={classnames('notifications-dropdown', 'mobile', { 'hidden': this.props.is_hidden })}
            onClick={(e) => this.stopProp(e)}
          >
            <a
              className='item small-button text-decoration-none'
              onClick={() => this.markAllRead()}
            >
              mark all read
            </a>
            {this.renderNotifications()}
            {this.renderSeeMore()}
          </div>
        </div>
      </>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  getNotifications,
  dismissAllNotifications,
  notificationClicked
}, dispatch))(NotificationDropdown)
