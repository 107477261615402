import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sendEmailConfirmation } from 'actions/api/users'
import { selectCurrentUser } from 'selectors'

import { isSuccessStatus } from 'utils'

class EmailConfirmation extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      is_confirmation_sent: false,
    }
  }

  resendEmail() {
    this.setState({ is_loading: true }, () => {
      this.props.sendEmailConfirmation().then((res) => {
        this.setState({
          is_loading: false,
          is_confirmation_sent: isSuccessStatus(res.payload.request_status),
        })
      })
    })
  }

  render() {
    return (
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-6'>
            <div className='border bg-light rounded p-3 mt-3'>
              <h3>Confirm Your Email</h3>
              <p>
                Our records show that you have not yet confirmed your email address ({this.props.user.email}).  Check your inbox for a Backline.me confirmation email and click the link provided.
              </p>

              <p>
                If your confirmation email has expired or is otherwise missing, click the button below to send a new one.
              </p>

              <p>
                <button
                  className='btn btn-primary'
                  onClick={() => this.resendEmail()}
                  disabled={this.state.is_loading}>
                  Send Confirmation Email
                </button>
              </p>

              {this.state.is_confirmation_sent && (
                <div className='alert alert-success'>
                  A confirmation email has been sent to {this.props.user.email}.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default connect((state, props) => ({
  user: selectCurrentUser(state)
}), (dispatch) => bindActionCreators({
  sendEmailConfirmation
}, dispatch))(EmailConfirmation)
