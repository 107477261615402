import React from 'react'
import _ from 'lodash'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import EditableDocument from 'components/documents/EditableDocument'
import NewDocumentForm from 'components/documents/NewDocumentForm'

export default class EditableDocumentsList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show_new_form: false,
    }
  }

  render() {
    const attachments = this.props.attachments || []
    const attachable = this.props.attachable || {}
    if (!this.props.is_editable && _.isEmpty(attachments)) { return null }

    return (
      <>
        <h5>Documents</h5>
        <div className='list-group list-group-striped mb-2'>
          {_.map(attachments, (a) => (
            <EditableDocument
              key={`attachable-${attachable.id}-attachment-${a.id}`}
              attachment={a}
              name_options={this.props.name_options}
              is_editable={this.props.is_editable}
            />
          ))}

          {this.props.is_editable && !this.state.show_new_form && _.isEmpty(attachments) && (
            <div className='list-group-item text-center'>
              You have no documents. Click the "+ Add Document" button below to add a new document.
            </div>
          )}

          {this.props.is_editable && this.state.show_new_form && (
            <NewDocumentForm
              attachable={attachable}
              name_options={this.props.name_options}
              onClose={() => this.setState({ show_new_form: false })}
            />
          )}
        </div>

        {this.props.is_editable && !this.state.show_new_form && (
          <a
            className='btn btn-primary text-center mb-0'
            onClick={() => this.setState({ show_new_form: true })}
          ><i className='fi-plus'></i> Add Document</a>
        )}
      </>
    )
  }

}

