import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import GigCompleteness from 'components/gigs/GigCompleteness'

import SafeLink from 'components/SafeLink'
import Avatar from 'components/Avatar'

import { isTicketCountsEnabledForGig } from 'utils'

export default class GigListItem extends React.Component {
  renderMobile() {
    const gig = this.props.gig || {}
    const completeness = gig.completeness || {}
    const perspective = gig.perspective || {}
    const avatar_urls = gig.avatar_urls || []
    let date = moment(gig.show_date).format("MMM DD (ddd)")
    if (moment(gig.show_date).year() !== moment().year()) {
      date = moment(gig.show_date).format("MMM DD, YYYY (ddd)")
    }

    return (
      <SafeLink className='list-group-item list-group-item-action p-2' to={gig.url}>
        <div className='d-flex flex-row align-items-center'>
          {this.props.show_avatar && (
            <div className='flex-grow-0'>
              <Avatar
                url={perspective.avatar_url}
                classes='small-avatar'
                blank_icon={perspective.type}
              />
            </div>
          )}
          <div className='flex-grow-1'>
            <strong>{gig.name}</strong>
          </div>

          <div className='flex-grow-0 d-flex flex-row'>
            {completeness.has_lodging && (
              <i className='icon-medium line-height fi-home pe-2'></i>
            )}
            <GigCompleteness gig={gig} />
          </div>
        </div>


        <div>{date}</div>
        {gig.city_state && (
          <div>{gig.city_state}</div>
        )}
      </SafeLink>
    )
  }

  renderDesktop() {
    const gig = this.props.gig || {}
    const completeness = gig.completeness || {}
    const perspective = gig.perspective || {}
    const gig_moment = moment(gig.show_date)
    const date = moment(gig.show_date).format("MMM DD")
    let date_upper = moment(gig.show_date).format("ddd")
    if (moment(gig.show_date).year() !== moment().year()) {
      date_upper = moment(gig.show_date).format("ddd (YYYY)")
    }

    return (
      <SafeLink className='list-group-item list-group-item-action' to={gig.url}>
        <div className='d-flex flex-row align-items-center'>
          <div className='flex-grow-1'>
            <div className='row align-items-center'>
              <div className='col-4 col-md-3 col-lg-2'>
                <div className='d-flex flex-row align-items-center'>
                  {this.props.show_avatar && (
                    <div className='flex-grow-0'>
                      <Avatar
                        url={perspective.avatar_url}
                        classes='medium-avatar margin-right'
                        blank_icon={perspective.type}
                      />
                    </div>
                  )}

                  <div className='flex-grow-0'>
                    <div>
                      {date_upper}
                    </div>
                    <div>
                      {date}
                    </div>
                  </div>
                </div>
              </div>

              <div className='col'>
                <div className='row justify-content-start align-items-center'>
                  <div className='col-4 col-lg-6'>{gig.name}</div>
                  <div className='col-4'>{gig.city_state || 'TBA'}</div>

                  {!_.isEmpty(gig?.ticket_counts) && isTicketCountsEnabledForGig(gig) && (
                    <div className='col-4 col-lg-2'>
                      <div className='d-none d-lg-block'>Tickets Sold</div>
                      <div>
                        {gig.ticket_counts[0].total_sold} / {gig.ticket_counts[0].total_sellable}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='flex-grow-0 d-flex'>
            <div className='w-50px d-flex flex-row align-items-center justify-content-end'>
              {completeness.has_lodging && (
                <i className='icon-medium line-height me-2 fi-home'></i>
              )}
              <GigCompleteness gig={gig} />
            </div>
          </div>
        </div>
      </SafeLink>
    )
  }

  render() {
    if (window.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

}
