import React from 'react'

import AdvanceProgress from 'components/advance/AdvanceProgress'

const LocationAdvanceProgress = ({
  location,
  pageNum,
}) => {

  const sectionData = [
    { name: 'Welcome', url: `/advance` },
    { name: 'Details', url: `/advance/details` },
    { name: 'Documents', url: `/advance/documents` },
    { name: 'Personnel', url: `/advance/crew_members` },
    { name: 'Schedule', url: `/advance/schedule` },
    { name: 'Summary', url: `/advance/summary` },
  ]

  return (
    <AdvanceProgress sectionData={sectionData} advanceable={location} pageNum={pageNum} />
  )
}

export default LocationAdvanceProgress
