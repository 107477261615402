import React from 'react'
import SafeLink from 'components/SafeLink'

const NoBandOrVenueNotice = () => {
  return (
    <div className='container'>
      <div className='alert alert-warning'>
        <p>
          You must be the administrator of a band/venue to create a show.
        </p>

        <ul>
          <li>
            To create a new band or venue, go to your <SafeLink to='/user/profile'>profile page</SafeLink>
          </li>
        </ul>
      </div>
    </div>
  )

}

export default NoBandOrVenueNotice
