import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { iff } from 'utils'
import { withRouter } from 'react-router'

import SafeLink from 'components/SafeLink'

class SearchResults extends React.Component {

  dismissSearch() {
    iff(this.props.onDismiss)
  }

  stopProp(e) {
    e.stopPropagation()
  }

  selectItem(url) {
    this.props.history.push(url)
    iff(this.props.onDismiss)
  }

  render() {
    const results = this.props.results || []
    return (
      <div
        className={classnames('search-results-wrapper', { 'hidden': this.props.is_hidden })}
        onClick={() => this.dismissSearch()}
      >
        <div
          className='search-results callout-list'
          onClick={(e) => this.stopProp(e)}
        >
          {this.props.is_loading && (
            <div className='item'>
              <div className='text-center half-padding'> <i className='fi-clock'></i>&nbsp;Loading...</div>
            </div>
          )}

          {!this.props.is_loading && _.map(results, (r) => (
            <div className='item hover' key={`${r.type}-${r.id}`}
              onClick={() => this.selectItem(r.url)}>
              <div className='row'>
                <div className='columns small-3 large-2'>
                  <div className='half-padding'> {r.type} </div>
                </div>
                <div className='columns small-9 large-10'>
                  <div className='half-padding'> {r.name} </div>
                </div>
              </div>
            </div>
          ))}

          {!this.props.is_loading && _.isEmpty(results) && (
            <div className='item'>
              <div className='text-center half-padding'>
                No results.  Try a different search!
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(SearchResults)
