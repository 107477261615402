import React from 'react'
import _ from 'lodash'

import WelcomeMembersList from 'components/welcome/WelcomeMembersList'

import {
  BAND_CONTACT_TITLES,
  VENUE_CONTACT_TITLES,
  PERSPECTIVE_TYPE
} from 'static'

export default class WelcomeAddMembersPage extends React.Component {

  render() {
    const org = _.get(this, 'props.org', {})
    const page_type = _.get(this, 'props.page_type')
    let titles = []
    if (page_type === PERSPECTIVE_TYPE.BAND) {
      titles = BAND_CONTACT_TITLES
    } else if (page_type === PERSPECTIVE_TYPE.VENUE) {
      titles = VENUE_CONTACT_TITLES
    }

    return (
      <>
        <h5>Members</h5>
        <div className='alert alert-primary'>
          <i className='fi-info me-2'></i>
          To learn more about membership settings, see our <a href='/help/members' target='_blank'>help pages</a>.
        </div>
        <WelcomeMembersList
          memberships={org.memberships}
          parent_url={org.url}
          title_options={titles}
        />
      </>
    )
  }

}
