import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createAttachment } from 'actions/api/attachments'

import DocumentForm from 'components/documents/DocumentForm'
import LoadingState from 'components/LoadingState'

import { iff } from 'utils'

class NewDocumentForm extends LoadingState {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      attachment: {
        document: {}
      },
    }
  }

  submit() {
    const attachable = this.props.attachable || {}
    this.setLoading(() => this.props.createAttachment(attachable.url, this.state.attachment).then((res) => {
      if (res.error) {
        this.clearLoading()
      } else {
        iff(this.props.onClose)
      }
    }))
  }

  updateAttachment(update, callback) {
    this.setState({
      attachment: {
        ...this.state.attachment,
        ...update,
      }
    }, () => iff(callback))
  }

  render() {
    const attachment = this.state.attachment || {}
    const attachable = this.props.attachable || {}
    return (
      <div className='list-group-item p-2'>
        <DocumentForm
          attachment={attachment}
          updateAttachment={(update, callback) => this.updateAttachment(update, callback)}
          onUploadSuccess={() => this.submit()}
          nameOptions={this.props.name_options}
          isDisabled={this.state.is_loading}
          uploadButtonText='Upload and Save'
          isShowDocumentSettings
        />

        <div className='btn-group'>
          <a
            className='btn btn-primary m-0'
            onClick={() => this.submit()}
            disabled={this.state.is_loading}
          >save</a>
          <a
            className='btn btn-gray m-0'
            onClick={() => iff(this.props.onClose)}
            disabled={this.state.is_loading}
          >cancel</a>
        </div>
      </div>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  createAttachment
}, dispatch))(NewDocumentForm)
