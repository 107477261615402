import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import Select from 'react-select'
import 'react-select/dist/react-select.css'

export default class OtherSelect extends React.Component {

  constructor(props) {
    super(props)


    this.state = {
      ...this.getStateFromProps(props),
      input: ''
    }
  }

  componentWillReceiveProps(props) {
    this.setState(this.getStateFromProps(props))
  }

  getStateFromProps(props) {
    const value = props.value || ''
    const options = [].concat(props.options)
    if (value && !_.find(options, (o) => o.value === value)) {
      options.push({
        label: value,
        value,
      })
    }

    return { value, options }
  }

  updateState(changes, callback) {
    this.setState(changes, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.value)
      }
      if (callback) { callback() }
    })
  }

  onInputChange(input) {
    this.setState({ input })
  }

  onDeselect() {
    this.updateState({ value: this.state.value || this.state.input }, () => {
      if (this.props.onBlur) {
        this.props.onBlur(this.state.value)
      }
    })
  }

  onChange(obj) {
    if (obj) {
      this.updateState({ value: obj.value })
    } else {
      this.updateState({ value: '', input: '' })
    }
  }

  getOptions() {
    const options = [].concat(this.state.options)
    if (this.state.input) {
      let input_exists = _.reduce(options, (memo, opt) => (
        memo || opt.value === this.state.input
      ), false)
      // If current input doesn't exist, add it as an option!
      if (!input_exists) {
        options.push({ label: this.state.input, value: this.state.input })
      }
    }
    return options
  }

  render() {
    return (
      <Select
        name={this.props.name}
        placeholder={this.props.placeholder}
        className={this.props.classes || ''}
        options={this.getOptions()}
        value={this.state.value}
        onInputChange={(v) => this.onInputChange(v)}
        onChange={(v) => this.onChange(v)}
        onBlur={() => this.onDeselect()}
        disabled={this.props.disabled}
      />
    )
  }
}
