import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { Resources } from 'schema'
import { selectModel } from 'selectors'

import {
  formatDisplayTime,
  addMinutesToTime
} from 'utils'

import SafeLink from 'components/SafeLink'

class ShowScheduleItem extends React.Component {

  renderBandName() {
    const si = _.get(this, 'props.schedule_item', {})
    if (!_.isNil(si.performance_id)) {
      return (
        <SafeLink to={this.props.performance_url} className='text-decoration-none'>
          {this.props.band_name}
        </SafeLink>
      )
    } else if (!_.isNil(si.band_name) && si.band_name.length > 0) {
      return (
        <span>{si.band_name}</span>
      )
    } else {
      return null
    }
  }

  renderMobile() {
    const si = this.props.schedule_item || {}
    return (
      <tr>
        <td>
          <strong>
            {formatDisplayTime(si.time)}
            {!_.isNil(si.duration) && si.duration > 0 && (
              <span>-{addMinutesToTime(si.time, si.duration)}</span>
            )}
          </strong>
        </td>
        <td>
          {si.label}
          {!_.isNil(this.renderBandName()) && (
            <span>&nbsp;({this.renderBandName()})</span>
          )}
        </td>
        {this.props.is_editable && (
          <td>
            {this.props.onEdit && !si.is_loading && (
              <a
                onClick={() => this.props.onEdit()}
                disabled={si.is_loading}
              ><i className='ms-2 edit-icon fi-pencil'></i></a>
            )}
            {si.is_loading && (
              <i className='ms-2 fi-clock'></i>
            )}
          </td>
        )}
      </tr>
    )
  }

  renderDesktop() {
    const si = this.props.schedule_item || {}
    const band_name = this.props.band_name || si.band_name
    return (
      <tr>
        <td>
          <strong>
            {formatDisplayTime(si.time)}
            {!_.isNil(si.duration) && si.duration > 0 && (
              <span>&nbsp;-&nbsp;{addMinutesToTime(si.time, si.duration)}</span>
            )}
          </strong>
        </td>
        {this.props.show_duration && (
          <td>
            {si.duration > 0 && si.duration}{si.duration > 0 && 'm'}
          </td>
        )}
        <td title={si.label} className="truncate-schedule-item-label">{si.label}</td>
        <td>
          {this.renderBandName()}
        </td>
        {this.props.is_editable && this.props.onEdit && !si.is_loading && (
          <td>
            <a
              onClick={() => this.props.onEdit()}
              disabled={si.is_loading}
            ><i className='edit-icon fi-pencil'></i></a>
            {si.is_loading && (
              <i className='fi-clock'></i>
            )}
          </td>
        )}
      </tr>
    )
  }

  render() {
    if (this.props.is_mobile) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

}

export default connect((state, ownProps) => {
  const performance = selectModel('performances', ownProps.schedule_item.performance_id, Resources.performance, state)
  return {
    band_name: performance.band_name,
    performance_url: performance.url,
  }
})(ShowScheduleItem)
