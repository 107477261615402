import React from 'react'
import Overlay from 'components/Overlay'

export default class WelcomeOverlay extends React.Component {

  render() {
    return (
      <Overlay is_hidden={this.props.is_hidden} onDismiss={() => this.props.dismiss()} classes='overlay-wide'>
        <div className='pt-2 px-2'>
          <div className='mb-3'>
            <h4>Welcome to Backline!</h4>
          </div>

          <p>
            This is your profile page.
          </p>

          <p>
            Here you can see and edit your personal information. You can also see your bands and venues on this page.
          </p>

          <p>
            If you'd like to <strong>create a new band or venue</strong> use the&nbsp;
            <strong>"+ Create New Band"</strong> or <strong>"+ Create New Venue"</strong> buttons on this page.
          </p>

          <p>
            To see your existing shows and create new ones, click the "Shows" button
            on the navigation bar.
          </p>

          <p>
            <a className='btn btn-primary mb-0' onClick={() => this.props.dismiss()}>Start using Backline.me</a>
          </p>
        </div>
      </Overlay>
    )
  }

}
