import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel, selectCurrentUser } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getPerformance } from 'actions/api/performances'
import { getGig } from 'actions/api/gigs'

import Row from 'components/Row'
import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import PageTitle from 'components/PageTitle'
import LoadingState from 'components/LoadingState'
import InviteHeader from 'components/InviteHeader'

import PerformanceAdvanceProgress from 'components/performances/PerformanceAdvanceProgress'
import PerformanceAdvanceSummary from 'components/performances/PerformanceAdvanceSummary'
import LocationAdvanceSummary from 'components/locations/LocationAdvanceSummary'
import LocationAdvanceProgress from 'components/locations/LocationAdvanceProgress'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import { PERFORMANCE_TYPES } from 'static'

class PerformanceAdvanceComplete extends LoadingState {

  loadEntities(props = this.props) {
    this.setLoading(() => this.props.getPerformance(props.match.params.id).then(
      (res) => this.handleResponse(res, () => {
        if (!res.error) {
          this.setLoading(() => this.props.getGig(_.get(res.payload, 'raw.performance.gig_id')).then(
            (res) => this.handleResponse(res)
          ))
        }
      })
    ))
  }

  componentWillMount() {
    this.loadEntities()
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.loadEntities(props)
    }
  }

  renderTabs() {
    const performance = _.get(this.props, 'performance', {})
    const performances = _.get(this.props, 'gig.performances', [])
    const other_performances = _.filter(performances, (p) => (p.id !== performance.id))
    const location = _.get(this.props, 'gig.location', {})

    if (_.isNil(location.id) && _.isEmpty(performances)) {
      return null
    }

    return (
      <div className='mb-3'>
        <Tabs>
          <TabList>
            {!_.isNil(location.id) && (
              <Tab>
                <Avatar
                  url={_.get(location, 'venue.avatar_url')}
                  classes='small-avatar'
                  small_classes='smaller-avatar'
                  blank_icon='Venue'
                />
                {location.venue_name}
              </Tab>
            )}
            {!_.isNil(performance.id) && (
              <Tab>
                <Avatar
                  url={_.get(performance, 'band.avatar_url')}
                  classes='small-avatar'
                  small_classes='smaller-avatar'
                  blank_icon='Band'
                />
                {performance.band_name}
              </Tab>
            )}
            {performance.performance_type === PERFORMANCE_TYPES.HEADLINE && _.map(other_performances, (p) => (
              <Tab key={`${p.id}-${p.band_name}-tab`}>
                <Avatar
                  url={_.get(p, 'band.avatar_url')}
                  classes='small-avatar'
                  small_classes='smaller-avatar'
                  blank_icon='Band'
                />
                {p.band_name}
              </Tab>
            ))}
          </TabList>


          {!_.isNil(location.id) && (
            <TabPanel> <LocationAdvanceSummary location_id={location.id} /> </TabPanel>
          )}
          {!_.isNil(performance.id) && (
            <TabPanel>
              <PerformanceAdvanceSummary performance_id={performance.id} />
            </TabPanel>
          )}
          {performance.performance_type === PERFORMANCE_TYPES.HEADLINE && _.map(other_performances, (p) => (
            <TabPanel key={`${p.id}-${p.band_name}-tab_panel`}>
              <PerformanceAdvanceSummary performance_id={p.id} />
            </TabPanel>
          ))}

        </Tabs>
      </div>
    )
  }

  render() {
    if (!this.props.performance) { return null }

    const performance = this.props.performance || {}
    const gig = performance.gig || {}
    const band = performance.band || {}
    const current_user = this.props.current_user

    return (
      <div className='container mt-3'>
        <PerformanceAdvanceProgress performance={performance} pageNum={5} />

        <div className='mb-2'>
          <PageTitle
            subtitle={`${moment(gig.show_date).format('MMMM Do YYYY')} - ${gig.name}`}
          >Band Advance Summary</PageTitle>
        </div>

        {_.isNil(current_user) ? (
          <div className='alert alert-primary'>
            <p>You can come back to this form at any time to view and edit this information.</p>
            {band.is_owned ? (
              <>
                <p>
                  {band.name} is already registered for Backline.
                </p>
                <p>
                  If you'd like to become a member, contact an
                  administrator of {band.name} to request an invite.
                </p>
              </>
            ) : (
              <>
                <p>
                  If you'd like access to these forms plus our many other
                  time-saving features, then don't forget to create an account below!
                </p>
                {/*
                <ul>
                  <li>Make all of this information instantly available to other Backline venues at the click of a button.</li>
                  <li>Ensure that your team always has access to the most up to date information.</li>
                  <li>Keep track of all of your upcoming shows.</li>
                  <li>Manage multiple artists/venues with ease.</li>
                  <li>Use our permissions system to make sure that your crew sees only what they need.</li>
                </ul>
                */}

                <SafeLink className='btn btn-primary mb-0' to={`${performance.url}/advance/register`}>
                  Register for Backline
                </SafeLink>
              </>
            )}
          </div>
        ) : (
          <div className='alert alert-primary'>
            <p>
              You are logged in as {current_user.person_name}.
            </p>
            <SafeLink to={gig.url}>Click here to view all show information</SafeLink>.
          </div>
        )}

        {this.renderTabs()}
      </div>
    )
  }

}

export default withRouter(connect((state, ownProps) => {
  const performance = selectModel('performances', ownProps.match.params.id, Resources.performance, state)
  let gig = null
  if (!_.isNil(performance.id)) {
    gig = selectModel('gigs', performance.gig.id, Resources.gig, state)
  }
  return {
    performance,
    gig,
    current_user: selectCurrentUser(state),
  }
}, (dispatch) => bindActionCreators({
  getPerformance, getGig
}, dispatch))(PerformanceAdvanceComplete))
