
export const TIME_REGEX = /(\d?\d):?(\d\d)(am|pm)?/i
export const DATE_REGEX = /(\d\d\d\d-\d\d-\d\d)/

export const AM = "am"
export const PM = "pm"

export const NUM_HOURS = 12
export const NUM_MINUTES = 60

export function trimTime(date) {
  const date_match = date.match(DATE_REGEX)
  if (!date_match) { return date }
  return date_match[1]
}

export function formatMinutes(num) {
  if (num < 10) {
    return "0" + num
  } else {
    return num.toString()
  }
}

export function formatDisplayTime(str_time) {
  const time = parseTime(str_time)
  let hours = time.hours
  if (hours === 0) { hours = 12 }
  return `${hours}:${formatMinutes(time.minutes)}${time.am_pm}`
}

export function flipAmPm(am_pm) {
  if (am_pm === AM) {
    return PM
  } else {
    return AM
  }
}

export function addMinutesToTime(str_time, add_minutes) {
  const num_minutes = timeToMinutes(str_time, false)
  return minutesToTime(num_minutes + add_minutes)
}


export function getMinDifference(time_a, time_b, wrap=true) {
  const minutes_a = timeToMinutes(time_a, wrap)
  const minutes_b = timeToMinutes(time_b, wrap)
  return minutes_b - minutes_a
}


/*******************************
 * Params
 *  - time (string)
 * Return
 *  - obj
 *    - hours (int) 1-12
 *    - minutes (int) 0-59
 *    - am_pm (string) 'am' or 'pm'
*********************************/
export function parseTime(time) {
  let am_pm = AM
  let hours = 0
  let minutes = 0

  if (time) {
    const time_match = time.match(TIME_REGEX)
    if (time_match) {

      // Get number of hours and minutes
      hours = parseInt(time_match[1], 10)
      minutes = parseInt(time_match[2], 10)

      // Grab AM or PM and set it
      if (time_match[3]) {
        am_pm = time_match[3].toLowerCase()
      }

      // If the hour is past 12, it's gotta be PM
      if (hours >= NUM_HOURS) {
        am_pm = PM
        while (hours > NUM_HOURS) { hours -= NUM_HOURS }

      // If the hour is 0, its actually 12am
      } else if (hours === 0) {
        am_pm = AM
        hours = NUM_HOURS
      }
    }
  }

  return {
    hours,
    minutes,
    am_pm,
  }
}


/*******************************
 * Params
 *  - time (string)
 *  - wrap (boolean) (optional default=true)
 * Return
 *  - num_minutes (integer)
 *    number of minutes into the day that
 *    this time is.  When wrap is true,
 *    morning times before 6am are considered
 *    as being the next day (+24hrs)
*********************************/
export function timeToMinutes(str_time, wrap=true) {
  const time = parseTime(str_time)
  let hours = parseInt(time.hours, 10)
  const minutes = parseInt(time.minutes, 10)

  // Move PM times 12 hours ahead
  if (time.am_pm === PM) { hours += 12 }

  // Move 12am and 12pm to be before 1am and 1pm
  if (hours === NUM_HOURS || hours === NUM_HOURS*2) {
    hours -= NUM_HOURS
  }

  // Make 12am - 6am the lastest times (because of late night stuff)
  if (wrap && hours < 6) {
    hours += NUM_HOURS * 2
  }

  // Add minutes and return
  return (hours * NUM_MINUTES) + minutes
}


/*******************************
 * Params
 *  - num_minutes (integer)
 *    number of minutes into the day that
 *    this time is.
 * Return
 *  - time (string)
*********************************/
export function minutesToTime(num_minutes) {
  let hours = Math.floor(num_minutes / NUM_MINUTES)
  let minutes = num_minutes % NUM_MINUTES
  let am_pm = AM
  while (hours >= NUM_HOURS * 2) { hours -= NUM_HOURS * 2 }
  if (hours >= NUM_HOURS) {
    am_pm = PM
    hours -= NUM_HOURS
  }
  if (hours === 0) { hours += NUM_HOURS }
  return `${hours}:${formatMinutes(minutes)}${am_pm}`
}

