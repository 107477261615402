import React from 'react'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { withRouter } from 'react-router'
import { createPerformance } from 'actions/api/performances'
import { getGig } from 'actions/api/gigs'

import PerformanceForm from 'components/performances/PerformanceForm'

import { isSuccessStatus } from 'utils'

class NewPerformancePage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    this.props.getGig(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      props.getGig(props.match.params.id)
    }
  }

  onSubmit(performance_data) {
    this.setState({ is_loading: true }, () => {
      this.props.createPerformance(this.props.match.params.id, performance_data)
        .then((response) => {
          if (isSuccessStatus(response.payload.request_status)) {
            this.props.history.push(`/performances/${response.payload.raw.performance.id}`)
          } else {
            this.setState({ is_loading: false })
          }
        })
    })
  }

  render() {
    return (
      <PerformanceForm
        onSubmit={(p_data) => this.onSubmit(p_data)}
        cancel_url={`/gigs/${this.props.match.params.id}`}
        is_loading={this.state.is_loading}
        gig={this.props.gig}
      />
    )
  }
}


export default connect((state, ownProps) => ({
  gig: selectModel('gigs', ownProps.match.params.id, Resources.gig, state),
}), (dispatch) => bindActionCreators({
  createPerformance, getGig
}, dispatch))(withRouter(NewPerformancePage))
