import React from 'react'

import Row from 'components/Row'
import LoadingState from 'components/LoadingState'
import ShowPerson from 'components/people/ShowPerson'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getPerson } from 'actions/api/people'

class PersonPage extends LoadingState {

  componentWillMount() {
    this.setLoading(() => {
      this.props.getPerson(this.props.match.params.id).then(() => this.clearLoading())
    })
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      this.setLoading(() => {
        props.getPerson(props.match.params.id).then(() => this.clearLoading())
      })
    }
  }

  render() {
    return (
      <div className='container'>
        <ShowPerson
          person={this.props.person}
          disable_email
        />

        {this.renderLoadingPopup()}
      </div>
    )
  }

}

export default connect((state, props) => {
  return {
    person: selectModel('people', props.match.params.id, Resources.person, state)
  }
}, (dispatch) => bindActionCreators({
  getPerson,
}, dispatch))(PersonPage)
