import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getGigTickets, updateGigTickets } from 'actions/api/gigs'

import PageTitle from 'components/PageTitle'
import LocationCard from 'components/gigs/LocationCard'
import PerformanceList from 'components/performances/PerformanceList'

import SafeLink from 'components/SafeLink'
import GigBottomBar from 'components/gigs/GigBottomBar'
import BreadCrumbs from 'components/BreadCrumbs'

import {
  isEditable
} from 'utils'

import {
  PERSPECTIVE_TYPE,
  TICKET_STATUS,
  INVITE_STATUS
} from 'static'

class GigTicketSalesPage extends React.Component {

  componentWillMount() {
    this.props.getGigTickets(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id !== this.props.match.params.id) {
      props.getGigTickets(props.match.params.id)
    }
  }

  isEditable() {
    const gig = this.props.gig || {}
    const perspective = gig.perspective || {}
    return isEditable(perspective.ticket_sales_permissions) && perspective.is_gig_owner
  }

  getTotalTickets() {
    return _.reduce(this.props.gig.ticket_sales, (sum, ts) => (
      sum + ts.tickets_sold
    ), 0)
  }

  getTotalComps() {
    return _.reduce(this.props.gig.performances, (memo, p) => {
      return memo + p.comps_used
    }, 0)
  }

  confirm() {
    const gig = this.props.gig || {}
    const gig_data = { attendance: gig.attendance }
    this.setState({ is_loading: true }, () => {
      this.props.updateGigTickets(this.props.match.params.id, gig_data)
        .then(() => {
          this.setState({ is_loading: false })
        })
    })
  }

  renderConfirmButton() {
    const gig = this.props.gig || {}
    const perspective = gig.perspective|| {}
    if (perspective.type === PERSPECTIVE_TYPE.VENUE &&
        gig.ticket_status === TICKET_STATUS.UNCONFIRMED) {
      return (
        <a
          className='btn btn-primary mb-3'
          onClick={() => this.confirm()}
        >confirm</a>
      )
    }
  }

  renderAfterGig() {
    const gig = this.props.gig || {}
    const tickets = _.map(this.props.gig.ticket_sales, (ts, i) => (
      <tr key={`ticket-sales-${i}-${ts.id}`}>
        <td>{ts.ticket_type}</td>
        <td>{ts.tickets_sold}</td>
      </tr>
    ))

    return (
      <>
        <div> Venue Confirmation: { gig.ticket_status } </div>
        <div> Attendance: { gig.attendance } </div>
        <div> Total Tickets Sold: { this.getTotalTickets() } </div>
        <div> Total Comps Used: { this.getTotalComps() } </div>

        {this.renderConfirmButton()}

        <hr/>

        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Ticket Type</th>
              <th># Sold</th>
            </tr>
          </thead>
          <tbody>
            {tickets}
          </tbody>
        </table>
      </>
    )
  }

  render() {
    const gig = this.props.gig || {}
    const perspective = gig.perspective || {}
    return (
      <div>
        <div className='container'>
          <BreadCrumbs items={[
            { name: 'Shows', url: '/' },
            { name: gig.gig_name || gig.default_name, url: gig.url },
            { name: 'Ticket Sales' },
          ]} />

          <PageTitle
            is_editable={this.isEditable()}
            edit_link={`${gig.url}/ticket_sales/edit`}
          >{gig.name}</PageTitle>

          <h3>
            {gig.city_state}
            &nbsp;&ndash;&nbsp;
            {moment(gig.show_date).format("MMMM Do YYYY")}
          </h3>


          <div className='row'>
            <div className='col-12 col-lg-6'>
              <h5>Tickets</h5>
              <div className='border bg-light rounded p-3 mb-3'>
                {this.renderAfterGig()}
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              {gig.location && gig.location.venue_id &&
                  gig.location.invite_status !== INVITE_STATUS.REJECTED && (
                <div>
                  <h5>Venue</h5>
                  <div className='border bg-light rounded mb-3'>
                    <LocationCard
                      gig_id={gig.id}
                      perspective={perspective}
                      location={gig.location || {}}
                    />
                  </div>

                  <h5>Bands</h5>
                  <PerformanceList performances={gig.performances} />
                </div>
              )}
            </div>
          </div>
        </div>

        <GigBottomBar gig={gig} />
      </div>
    )
  }

}

export default connect((state, ownProps) => ({
  gig: selectModel('gigs', ownProps.match.params.id, Resources.gig, state)
}), (dispatch) => {
  return bindActionCreators({ getGigTickets, updateGigTickets }, dispatch)
})(GigTicketSalesPage)
