import React from 'react'

import FormCheck from 'components/FormCheck'

export default class EditableProductionDescription extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      is_editing: false,
      values: this.getValuesFromProps(props),
    }
  }

  componentWillReceiveProps(props) {
    if (this.getValuesFromProps(props) !== this.getValuesFromProps(this.props)) {
      this.setState({ values: this.getValuesFromProps(props) })
    }
  }

  getValuesFromProps(props) {
    return {
      production_description: props.production_description || '',
      house_lights: props.house_lights || false,
      house_sound: props.house_sound || false,
    }
  }

  getPostData() {
    return { ...this.state.values }
  }

  onEdit() {
    this.setState({ is_editing: true })
  }

  onChange(update) {
    this.setState({
      values: {
        ...this.state.values,
        ...update,
      },
    })
  }

  save() {
    this.setState({ is_editing: false }, () => {
      if (this.props.update) {
        this.props.update(this.getPostData())
      }
    })
  }

  isValuesEmpty() {
    const values = this.state.values || {}
    return !values.production_description && !values.house_sound && !values.house_lights
  }

  getKey() {
    return this.props.idKey || ''
  }

  render() {
    const values = this.state.values || {}
    if (this.state.is_editing && this.props.is_editable) {
      return (
        <>
          <h5>
            Production Details
            <a
              key='save'
              onClick={() => this.save()}
            >
              <i className='fi-check icon-base ms-2 success-color'></i>
            </a>
          </h5>

          <div className='border rounded p-3 mb-3'>
            <div className='mb-3'>
              <FormCheck
                label='Using House Sound Engineer'
                name={`${this.getKey()}-house_sound`}
                checked={values.house_sound || false}
                onChange={(e) => this.onChange({ house_sound: e.target.checked })}
                disabled={this.props.disabled}
              />

              <FormCheck
                label='Using House Lighting Engineer'
                name={`${this.getKey()}-house_lights`}
                checked={values.house_lights || false}
                onChange={(e) => this.onChange({ house_lights: e.target.checked })}
                disabled={this.props.disabled}
              />
            </div>

            <textarea
              className='form-control'
              value={values.production_description || ''}
              placeholder='Add your production details here...'
              onChange={(e) => this.onChange({ production_description: e.target.value })}
            />
          </div>
        </>
      )
    } else if (!this.isValuesEmpty() || this.props.is_editable) {
      return (
        <>
          <h5>
            Production Details
            {!this.isValuesEmpty() && this.props.is_editable && (
              <a
                key='edit'
                onClick={() => this.onEdit()}
              ><i className='ms-2 smaller-text edit-icon fi-pencil'></i></a>
            )}
          </h5>
          {!this.isValuesEmpty() && (
            <div className='border rounded p-3 mb-3'>
              {(values.house_sound || values.house_lights) && (
                <div className='mb-3'>
                  {values.house_sound && <div><strong>Using House Sound Engineer</strong></div>}
                  {values.house_lights && <div><strong>Using House Lighting Engineer</strong></div>}
                </div>
              )}
              <p className='prewrap mb-0'>{values.production_description}</p>
            </div>
          )}
          {this.isValuesEmpty() && this.props.is_editable && (
            <a
              onClick={() => this.onEdit()}
              className='btn rounded bg-light border hover-bg-gray text-center d-block p-3 mb-3 text-decoration-none text-dark'>
              <h5 className='m-0'>
                <i className='fi-plus'></i> Add Production Details
              </h5>
            </a>
          )}
        </>
      )
    } else if(this.props.show_empty) {
      return (
        <>
          <h5>Production Details</h5>
          <div className='border rounded p-3 bg-light text-center'>
            <strong>No Production Details</strong>
          </div>
        </>
      )
    } else {
      return null
    }
  }
}
