import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'

import SafeLink from 'components/SafeLink'

import {
  PERSPECTIVE_TYPE,
  SCHEDULE_LABELS
} from 'static'

import {
  timeToMinutes,
  formatDisplayTime,
  addMinutesToTime,
  getMinDifference
} from 'utils'

export default class ShowScheduleItems extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      is_expanded: false
    }
  }

  showAll() {
    return !this.canExpand() || this.state.is_expanded
  }

  canExpand() {
    const all_times = this.getAllTimes()
    const collapsed_times = this.getPerspectiveTimes()
    const perspective = this.props.perspective || {}

    return perspective.type === PERSPECTIVE_TYPE.BAND &&
      all_times.length > collapsed_times.length
  }

  formatTimes(times) {
    const gig = this.props.gig || {}
    const performances = gig.performances || []
    const formatted_times = _.sortBy(times, (t) => timeToMinutes(t.time))

    _.each(formatted_times, (t) => {
      if (t.performance_id) {
        const p = _.find(performances, { id: t.performance_id })
        if (p) {
          const band = (<SafeLink to={p.url} className='text-decoration-none'>{p.band_name}</SafeLink>)
          if (t.label === SCHEDULE_LABELS.SET) {
            t.label = band
          } else if (this.showAll()) {
            t.after = band
          }
        }
      } else if (!_.isNil(t.band_name) && t.band_name.length > 0) {
        if (t.label === SCHEDULE_LABELS.SET) {
          t.label = t.band_name
        } else if (this.showAll()) {
          t.after = t.band_name
        }
      }
    })

    return formatted_times
  }

  // NOTE: CLONES ALL TIME OBJECTS so that they're mutable
  getAllTimes() {
    const gig = this.props.gig || {}
    const gig_times = gig.schedule_items || []
    return _.map(gig_times, (t) => ({ ...t }))
  }

  getPerspectiveTimes() {
    const perspective = this.props.perspective || {}
    if (perspective.type === PERSPECTIVE_TYPE.VENUE) { return this.getAllTimes() }

    const gig_times = this.getAllTimes()
    const gig_only_times = _.filter(gig_times, (t) => !t.performance_id && _.isEmpty(t.band_name))
    const performance_times = _.filter(gig_times, (t) => t.performance_id === perspective.relation_id)
    const other_sets = _.filter(gig_times, (t) =>
      t.label === SCHEDULE_LABELS.SET && t.performance_id !== perspective.relation_id)

    const times = [
      ...gig_only_times,
      ...performance_times,
      ...other_sets,
    ]

    return times
  }

  getDisplayTimes() {
    if (this.showAll()) {
      return this.formatTimes(this.getAllTimes())
    } else {
      return this.formatTimes(this.getPerspectiveTimes())
    }
  }

  renderScheduleDesktop() {
    return (
      <div className='mb-3'>
        <table className='table table-striped mb-0'>
          <tbody>
            {_.map(this.getDisplayTimes(), (t, i) => (
              <tr key={`schedule-time-${i}`}>
                <td width='170'>
                  <strong>
                    {formatDisplayTime(t.time)}
                    {!_.isNil(t.duration) && t.duration > 0 && (
                      <span>&nbsp;-&nbsp;{addMinutesToTime(t.time, t.duration)}</span>
                    )}
                  </strong>
                  {t.overlap_alert && (
                    <span className='label alert'>
                      set overlap
                    </span>
                  )}
                </td>
                <td>
                  {t.label}
                  {t.after && (
                    <span>&nbsp;({t.after})</span>
                  )}
                </td>
              </tr>
            ))}

          </tbody>
        </table>

        {this.canExpand() && (
          <a
            className='btn w-100 p-1 btn-light m-0'
            onClick={() => this.setState({ is_expanded: !this.state.is_expanded })}
          >
            {!this.state.is_expanded ? 'see more' : 'see less'}
          </a>
        )}
      </div>
    )
  }

  renderSchedule() {
    const times = this.getAllTimes()
    const perspective = this.props.perspective || {}

    if (times.length < 1) {
      if (this.props.is_editable && !this.props.force_tba_empty) {
        return (
          <SafeLink
            to={`${this.props.gig.url}/schedule`}
            className='btn rounded bg-light border hover-bg-gray text-center d-block p-3 mb-3 text-decoration-none text-dark'
          >
            <h5 className='m-0'>
              <i className='fi-plus'></i> Add Schedule
            </h5>
          </SafeLink>
        )
      } else {
        return (
          <div className='rounded border bg-light p-3 text-center'>
            <h5 className='m-0'>
              Schedule TBA
            </h5>
          </div>
        )
      }
    }

    return this.renderScheduleDesktop()
  }

  render() {
    const gig = _.get(this.props, 'gig', {})
    const times = this.getAllTimes()
    const perspective = _.get(this.props, 'perspective', {})

    if (this.props.show_header) {
      return (
        <>
          <h4>
            {this.props.is_editable ? 'Schedule' : (
              <SafeLink to={`${gig.url}/schedule`}>Schedule</SafeLink>
            )}
            {times.length > 0 && (
              <SafeLink to={`${gig.url}/schedule`}>
                {this.props.is_editable && (
                  <i className='edit-icon fi-pencil icon-base half-margin-left'></i>
                )}
              </SafeLink>
            )}
          </h4>
          {this.renderSchedule()}
        </>
      )
    } else {
      return this.renderSchedule()
    }
  }

}
