import React from 'react'

import { Switch, Route } from 'react-router-dom'

import NewEventPage from 'components/home/NewEventPage'
import HomeGigsList from 'components/home/HomeGigsList'

import GigTicketSalesPage from 'components/ticket_sales/GigTicketSalesPage'
import GigTicketCountsPage from 'components/ticket_count/GigTicketCountsPage'
import EditGigTicketSalesPage from 'components/ticket_sales/EditGigTicketSalesPage'
import TicketCountInputIndex from 'components/ticket_count/TicketCountInputIndex'
import TicketCountInputForm from 'components/ticket_count/TicketCountInputForm'

import EditableGigPage from 'components/gigs/editable/EditableGigPage'
import EditableSchedule from 'components/schedule/EditableSchedule'
import GuestListPage from 'components/guest_list/GuestListPage'

import EditableScheduleTemplate from 'components/schedule_templates/EditableScheduleTemplate'

import PerformanceAcceptPage from 'components/performances/PerformanceAcceptPage'
import PerformanceRejectPage from 'components/performances/PerformanceRejectPage'
import PerformancePage from 'components/performances/PerformancePage'
import NewPerformancePage from 'components/performances/NewPerformancePage'

import LocationAcceptPage from 'components/locations/LocationAcceptPage'
import LocationRejectPage from 'components/locations/LocationRejectPage'
import NewLocationPage from 'components/locations/NewLocationPage'
import LocationPage from 'components/locations/LocationPage'
import VenueUpdatePage from 'components/locations/VenueUpdatePage'

import PersonPage from 'components/people/PersonPage'
import PersonForm from 'components/people/PersonForm'

import MembershipAcceptPage from 'components/memberships/MembershipAcceptPage'
import MembershipRejectPage from 'components/memberships/MembershipRejectPage'

import OrgMembersPage from 'components/OrgMembersPage'
import CrewMembersPage from 'components/CrewMembersPage'

import BandPage from 'components/bands/BandPage'
import BandForm from 'components/bands/BandForm'
import NewBandForm from 'components/bands/NewBandForm'

import VenuePage from 'components/venues/VenuePage'
import VenueForm from 'components/venues/VenueForm'
import NewVenueForm from 'components/venues/NewVenueForm'

import SearchPage from 'components/home/SearchPage'

import WelcomePage from 'components/welcome/WelcomePage'
import WelcomeWrapUpPage from 'components/welcome/WelcomeWrapUpPage'

import BandWelcomePage from 'components/welcome/BandWelcomePage'
import BandWelcomeDetailsPage from 'components/welcome/BandWelcomeDetailsPage'
import BandWelcomeDocumentsPage from 'components/welcome/BandWelcomeDocumentsPage'
import BandWelcomeMembersPage from 'components/welcome/BandWelcomeMembersPage'

import VenueWelcomePage from 'components/welcome/VenueWelcomePage'
import VenueWelcomeDetailsPage from 'components/welcome/VenueWelcomeDetailsPage'
import VenueWelcomeDocumentsPage from 'components/welcome/VenueWelcomeDocumentsPage'
import VenueWelcomeMembersPage from 'components/welcome/VenueWelcomeMembersPage'

import ProfilePage from 'components/users/ProfilePage'
import AccountPage from 'components/users/AccountPage'
import ClaimOrganizations from 'components/users/ClaimOrganizations'
import RedirectClaimOrganizations from 'components/redirects/RedirectClaimOrganizations'

import EmailConfirmation from 'components/login/EmailConfirmation'

import EditLodgingPage from 'components/lodgings/EditLodgingPage'
import LodgingPage from 'components/lodgings/LodgingPage'
import LodgingsList from 'components/lodgings/LodgingsList'

import TicketSalesSummary from 'components/ticket_sales/TicketSalesSummary'

import AdvanceIntro from 'components/advance/AdvanceIntro'
import LocationAdvanceDetails from 'components/locations/LocationAdvanceDetails'
import LocationAdvanceComplete from 'components/locations/LocationAdvanceComplete'
import PerformanceAdvanceDetails from 'components/performances/PerformanceAdvanceDetails'
import PerformanceAdvanceComplete from 'components/performances/PerformanceAdvanceComplete'

import AdvanceDocumentsPage from 'components/documents/AdvanceDocumentsPage'
import AdvanceCrewMembersPage from 'components/crew_members/advance/AdvanceCrewMembersPage'
import AdvanceSchedulePage from 'components/schedule/AdvanceSchedulePage'
import AdvanceGuestListPage from 'components/guest_list/AdvanceGuestListPage'

import ContactPage from 'components/marketing/ContactPage'
import PrivacyPolicy from 'components/PrivacyPolicy'
import TermsAndConditions from 'components/TermsAndConditions'
import UnauthorizedPage from 'components/UnauthorizedPage'
import NotFoundPage from 'components/NotFoundPage'

export default class LoggedInRoutes extends React.Component {

  render() {
    if (!this.props.user || !this.props.user.id) {
      return this.props.children
    }

    // Email Confirmation Pages
    if (!this.props.user.is_email_confirmed) {
      return (
        <Switch>
          <Route path='/' component={EmailConfirmation} />
        </Switch>
      )
    }

    return (
      <Switch>
        <Route exact path='/bands/new' component={NewBandForm} />
        <Route exact path='/bands/:id/edit' component={BandForm} />
        <Route path='/bands/:id' component={BandPage} />

        <Route exact path='/venues/new' component={NewVenueForm} />
        <Route exact path='/venues/:id/edit' component={VenueForm} />
        <Route path='/venues/:id' component={VenuePage} />

        <Route exact path='/lodgings/past' component={LodgingsList} />
        <Route exact path='/lodgings/:id/edit' component={EditLodgingPage} />
        <Route exact path='/lodgings/:id' component={LodgingPage} />
        <Route exact path='/lodgings' component={LodgingsList} />

        <Route exact path='/ticket_sales' component={TicketSalesSummary} />

        <Route exact path='/schedule_templates/:id' component={EditableScheduleTemplate} />

        <Route exact path='/people/:id/edit' component={PersonForm} />
        <Route exact path='/people/:id' component={PersonPage} />

        <Route exact path='/performances/:id/advance' component={AdvanceIntro} />
        <Route exact path='/performances/:id/advance/details' component={PerformanceAdvanceDetails} />
        <Route exact path='/performances/:id/advance/documents' component={AdvanceDocumentsPage} />
        <Route exact path='/performances/:id/advance/crew_members' component={AdvanceCrewMembersPage} />
        <Route exact path='/performances/:id/advance/guest_list' component={AdvanceGuestListPage} />
        <Route exact path='/performances/:id/advance/summary' component={PerformanceAdvanceComplete} />

        <Route exact path='/gigs/:id/performances/new' component={NewPerformancePage} />
        <Route exact path='/performances/:id/accept' component={PerformanceAcceptPage} />
        <Route exact path='/performances/:id/reject' component={PerformanceRejectPage} />
        <Route exact path='/performances/:id/crew_members' component={CrewMembersPage} />
        <Route exact path='/performances/:id' component={PerformancePage} />

        <Route exact path='/locations/:id/advance' component={AdvanceIntro} />
        <Route exact path='/locations/:id/advance/details' component={LocationAdvanceDetails} />
        <Route exact path='/locations/:id/advance/documents' component={AdvanceDocumentsPage} />
        <Route exact path='/locations/:id/advance/crew_members' component={AdvanceCrewMembersPage} />
        <Route exact path='/locations/:id/advance/schedule' component={AdvanceSchedulePage} />
        <Route exact path='/locations/:id/advance/summary' component={LocationAdvanceComplete} />

        <Route exact path='/gigs/:id/locations/new' component={NewLocationPage} />
        <Route exact path='/locations/:id/accept' component={LocationAcceptPage} />
        <Route exact path='/locations/:id/reject' component={LocationRejectPage} />
        <Route exact path='/locations/:id/crew_members' component={CrewMembersPage} />
        <Route exact path='/locations/:id/venue_update' component={VenueUpdatePage} />
        <Route exact path='/locations/:id' component={LocationPage} />

        <Route exact path='/memberships/:id/accept' component={MembershipAcceptPage} />
        <Route exact path='/memberships/:id/reject' component={MembershipRejectPage} />

        <Route exact path='/user/profile' component={ProfilePage} />
        <Route exact path='/user/account' component={AccountPage} />
        <Route exact path='/user/claim_orgs' component={ClaimOrganizations} />
        <Route exact path='/claim_invite' component={RedirectClaimOrganizations} />

        <Route exact path='/gigs/:id/schedule' component={EditableSchedule} />
        <Route exact path='/gigs/:id/guest_list/:performance_id' component={GuestListPage} />
        <Route exact path='/gigs/:id/guest_list/master' component={GuestListPage} />
        <Route exact path='/gigs/:id/guest_list' component={GuestListPage} />
        <Route exact path='/gigs/:id/ticket_sales/edit' component={EditGigTicketSalesPage} />
        <Route exact path='/gigs/:id/ticket_sales' component={GigTicketSalesPage} />
        <Route exact path='/gigs/:id/ticket_counts' component={GigTicketCountsPage} />
        <Route exact path='/gigs/:gig_id/ticket_counts/edit' component={TicketCountInputIndex} />
        <Route exact path='/gigs/:gig_id/ticket_counts/input' component={TicketCountInputForm} />
        <Route exact path='/gigs/:id' component={EditableGigPage} />
        <Route exact path='/search' component={SearchPage} />

        <Route exact path='/new/lodging' component={NewEventPage} />
        <Route exact path='/new/gig' component={NewEventPage} />
        <Route exact path='/new' component={NewEventPage} />

        <Route exact path='/welcome/' component={WelcomePage} />
        <Route exact path='/welcome/wrap_up/:org_type' component={WelcomeWrapUpPage} />

        <Route exact path='/welcome/bands' component={BandWelcomePage} />
        <Route exact path='/welcome/bands/:id/details' component={BandWelcomeDetailsPage} />
        <Route exact path='/welcome/bands/:id/documents' component={BandWelcomeDocumentsPage} />
        <Route exact path='/welcome/bands/:id/members' component={BandWelcomeMembersPage} />

        <Route exact path='/welcome/venues' component={VenueWelcomePage} />
        <Route exact path='/welcome/venues/:id/details' component={VenueWelcomeDetailsPage} />
        <Route exact path='/welcome/venues/:id/documents' component={VenueWelcomeDocumentsPage} />
        <Route exact path='/welcome/venues/:id/members' component={VenueWelcomeMembersPage} />

        <Route exact path='/contact' component={ContactPage} />
        <Route exact path='/unauthorized' component={UnauthorizedPage} />
        <Route exact path='/not_found' component={NotFoundPage} />
        <Route exact path='/privacy' component={PrivacyPolicy} />
        <Route exact path='/terms' component={TermsAndConditions} />

        <Route exact path='/past' component={HomeGigsList} />
        <Route path='/' component={HomeGigsList} />
        <Route render={() => this.props.children} />
      </Switch>
    )
  }
}
