import React, { useEffect, useState } from 'react'

import OtherSelect from 'components/OtherSelect'

import { TICKET_TYPES } from 'static'

const InitialTicketConfigEditArray = ({ ticketConfig, onChange }) => {
  const [currentTicketConfig, setCurrentTicketConfig] = useState(ticketConfig)

  useEffect(() => {
    onChange(currentTicketConfig)
  }, [currentTicketConfig])

  const updateTicketConfig = (update, idx) => {
    const newTicketConfig = [...currentTicketConfig]
    newTicketConfig[idx] = update
    setCurrentTicketConfig(newTicketConfig)
  }

  const addBlankTicketType = () => {
    const newTicketConfig = [...currentTicketConfig, {}]
    setCurrentTicketConfig(newTicketConfig)
  }

  const removeTicketType = idx => {
    const newTicketConfig = [...currentTicketConfig]
    newTicketConfig.splice(idx, 1)
    setCurrentTicketConfig(newTicketConfig)
  }

  return (
    <>
      <table className='table table-striped align-middle'>
        <thead>
          <tr>
            <th>Ticket Type</th>
            <th width='120'>Total Sellable</th>
            <th width='120'>Price</th>
            <th width='120'>Count</th>
            <th width='40'></th>
          </tr>
        </thead>
        <tbody>
          {currentTicketConfig.map((tt, i) => (
            <TicketTypeForm
              key={`ticket-type-${i}-${tt.ticket_type_name}`}
              ticketType={tt}
              onChange={(v) => updateTicketConfig(v, i)}
              onDelete={() => removeTicketType(i)}
            />
          ))}
        </tbody>
      </table>
      <a
        className='btn btn-secondary mb-2 me-2'
        onClick={addBlankTicketType}
      >Add Ticket Type</a>
    </>
  )
}

const TicketTypeForm = ({ ticketType, onChange, onDelete }) => {
  const [currentTicketType, setCurrentTicketType] = useState(ticketType)

  useEffect(() => {
    onChange(currentTicketType)
  }, [currentTicketType])

  const updateTicketType = update => {
    const newTicketType = {
      ...ticketType,
      ...update,
    }

    setCurrentTicketType(newTicketType)
  }

  return (
    <tr>
      <td>
        <OtherSelect
          classes='mb-0'
          name='ticket_type'
          value={currentTicketType.ticket_type_name || ''}
          options={_.map(TICKET_TYPES, (tt) => {
            return { label: tt, value: tt }
          })}
          onChange={(v) => updateTicketType({ ticket_type_name: v })}
        />
      </td>

      <td>
        <input
          className='form-control mb-0'
          type='number'
          name='total_sellable'
          value={currentTicketType.total_sellable || ''}
          onChange={(e) => updateTicketType({ total_sellable: e.target.value })}
        />
      </td>

      <td>
        <input
          className='form-control mb-0'
          type='number'
          name='price'
          value={currentTicketType.price || ''}
          onChange={(e) => updateTicketType({ price: e.target.value })}
        />
      </td>

      <td>
        <input
          className='form-control mb-0'
          type='number'
          name='count'
          value={currentTicketType.count || ''}
          onChange={(e) => updateTicketType({ count: e.target.value })}
        />
      </td>

      <td>
        <a onClick={() => onDelete()}>
          <i className='fi-trash delete-icon'></i>
        </a>
      </td>
    </tr>
  )
}

export default InitialTicketConfigEditArray
