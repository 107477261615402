import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { denormalize } from 'normalizr'
import { searchVenues, getVenue } from 'actions/api/venues'

import PaginatedList from 'components/PaginatedList'
import GigVenueForm from 'components/gigs/GigVenueForm'
import ShowAddress from 'components/addresses/ShowAddress'
import ShowPhone from 'components/people/ShowPhone'
import RenderValue from 'components/RenderValue'
import PageTitle from 'components/PageTitle'
import SaveBar from 'components/SaveBar'
import Avatar from 'components/Avatar'
import SafeLink from 'components/SafeLink'
import FormField from 'components/FormField'
import BreadCrumbs from 'components/BreadCrumbs'

import {
  isEditable
} from 'utils'

import {
  PERFORMANCE_TYPES,
  PERFORMANCE_TYPE_LABEL,
  INVITE_STATUS
} from 'static'

const MIN_VENUE_NAME_LENGTH = 3

class LocationForm extends PaginatedList {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      is_new_venue: false,
      selected_venue: {},
      new_venue: {},
      venue_search: '',
    }
  }

  componentDidMount() {
    if (!_.isNil(this.search_input)) {
      this.search_input.focus()
    }
  }

  requestListData(page) {
    return this.props.searchVenues(this.state.venue_search, page)
  }

  getListFromData(data) {
    return data.results
  }

  getSelectedVenueInfo() {
    const selected_venue = this.state.selected_venue || {}
    this.setLoading(() => this.props.getVenue(selected_venue.id)
      .then((res) => {
        if (res.error) {
          this.clearLoading()
        } else {
          this.setState({
            is_loading: false,
            selected_venue: {
              ...selected_venue,
              ...res.payload.raw.venue,
            }
          })
        }
      }))
  }

  onVenueSearch(query) {
    this.setState({ venue_search: query, page: 0 }, () => {
      if (!this.state.is_loading) {
        this.getListData()
      }
    })
  }

  canEditLocationType() {
    const gig = this.props.gig || {}
    const perspective = gig.perspective || {}
    return isEditable(perspective.gig_permissions) && perspective.is_gig_owner
  }

  canMakeNewVenue() {
    return _.trim(this.state.venue_search).length >= MIN_VENUE_NAME_LENGTH
  }

  canSave() {
    const selected_venue = this.state.selected_venue || {}
    return !_.isNil(selected_venue.id) || (this.state.is_new_venue && this.canMakeNewVenue())
  }

  updateNewVenue(update) {
    this.setState({
      new_venue: {
        ...this.state.new_venue,
        ...update,
      }
    })
  }

  selectVenue(v) {
    const selected_venue = this.state.selected_venue || {}
    if (v.id === selected_venue.id) { return null }
    this.setState({
      is_new_venue: false,
      selected_venue: v,
    }, () => {
      this.getSelectedVenueInfo()
    })
  }

  formatLocationData() {
    let location_data = {}
    const selected_venue = this.state.selected_venue || {}

    if (this.state.is_new_venue) {
      location_data = {
        venue: { ...this.state.new_venue },
      }
    } else if (!_.isNil(selected_venue.id)) {
      location_data = {
        venue_id: selected_venue.id,
      }
    } else {
      throw "[LocationForm] Location saved without a selected venue_id or new venue."
    }

    return location_data
  }

  renderSelectedVenueDetails() {
    const selected_venue = this.state.selected_venue || {}
    if (_.isEmpty(selected_venue.address) &&
        _.isEmpty(selected_venue.bio) &&
        _.isEmpty(selected_venue.public_contacts) &&
        _.isEmpty(selected_venue.website)) {
      return null
    }

    return (
      <div className='row mt-2'>
        {(!_.isEmpty(selected_venue.bio) ||
          !_.isEmpty(selected_venue.website) ||
          !_.isEmpty(selected_venue.address)) && (
          <div className='col-12 col-md-6'>
            <div className='list-group list-group-striped mb-2'>
              <div className='list-group-item bold text-center'>
                Details
              </div>
              <div className='list-group-item scroll-block max-height'>
                {!_.isEmpty(selected_venue.address) && (
                  <ShowAddress address={selected_venue.address} />
                )}
                {!_.isEmpty(selected_venue.website) && (
                  <a href={selected_venue.website} target='_blank'>{selected_venue.website}</a>
                )}
                {!_.isEmpty(selected_venue.bio) && (
                  <div className='prewrap'>
                    {selected_venue.bio}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!_.isEmpty(selected_venue.public_contacts) && (
          <div className='col-12 col-md-6'>
            <div className='list-group list-group-striped mb-2'>
              <div className='list-group-item text-center bold'>
                Contacts
              </div>
              <div className='scroll-block max-height margin-top-minus-1'>
                {_.map(selected_venue.public_contacts, (c) => (
                  <div className='list-group-item clearfix' key={`contact-${c.id}`}>
                    <Avatar
                      url={c.avatar_url}
                      classes='float-start small-avatar'
                    />
                      {c.name}
                      {c.username && (<>&nbsp;(@{c.username})</>)}
                      {c.title && (<i>&nbsp;&ndash;&nbsp;{c.title}</i>)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderNewVenueForm() {
    return (
      <>
        <a className='link mb-2 d-block' onClick={() => this.setState({
          is_new_venue: false, selected_venue: null,
          new_venue: {},
        })}>&lt; back to venue select</a>

        <GigVenueForm
          venue={this.state.new_venue}
          onChange={(v) => this.updateNewVenue(v)}
        />
      </>
    )
  }

  renderSearch() {
    const selected_venue = this.state.selected_venue || {}
    return (
      <>
        <div className='alert alert-primary'>
          To search for a venue, start typing their name into the 'Venue Search' field below.  Then just select your desired venue and press save!
        </div>

        <div className='row'>
          <div className='col-lg-6 col-md-8 col-12 mb-3'>
            <FormField
              label='Venue Search'
              type='text'
              name='venue_search'
              value={this.state.venue_search}
              passRef={(i) => { this.search_input = i }}
              onChange={(e) => this.onVenueSearch(e.target.value)}
            />
          </div>
        </div>

        <div className='list-group list-group-striped mb-3'>
          {_.map(this.state.list, (b) => (
            <a
              key={`search-venue-${b.id}`}
              className={classnames('list-group-item list-group-item-action clearfix d-block', {
                'list-group-item-primary': b.id === selected_venue.id,
              })}
              onClick={() => this.selectVenue(b)}
            >
              <span className='text-dark'>
                <Avatar
                  url={b.avatar_url}
                  classes='float-start small-avatar'
                />
                <strong>{b.name}</strong>&nbsp;
                {b.username && (
                  <React.Fragment>(@{b.username})</React.Fragment>
                )}

                {b.id === selected_venue.id && this.renderSelectedVenueDetails()}
              </span>
            </a>
          ))}

          {this.canMakeNewVenue() && (
            <a
              className={classnames('list-group-item list-group-item-action clearfix d-block', {
                'list-group-item-primary': this.state.is_new_venue
              })}
              onClick={() => this.setState({
                selected_venue: null,
                is_new_venue: true,
                new_venue: { name: this.state.venue_search },
              })}
            >
              <span className='text-dark'>
                <strong>
                  <i className='fi-plus me-2'></i>Create New Venue - {this.state.venue_search}
                </strong>
              </span>
            </a>
          )}

          {!this.canMakeNewVenue() && _.isEmpty(this.state.list) && (
            <div className='list-group-item text-center'>
              No Results.  Type at least 3 letters to add a new venue or change your search query.
            </div>
          )}
        </div>

        {this.state.num_pages > 1 && this.renderPageButtons()}
      </>
    )
  }

  render() {
    const gig = this.props.gig || {}
    return (
      <>
        <div className='container'>
          <BreadCrumbs items={[
            { name: 'Shows', url: '/' },
            { name: gig.gig_name || gig.default_name, url: gig.url },
            { name: 'Add Venue' },
          ]} />

          <PageTitle>Add Venue</PageTitle>

          {this.state.is_new_venue && this.renderNewVenueForm()}
          {!this.state.is_new_venue && this.renderSearch()}
        </div>


        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.props.onSubmit(this.formatLocationData())}
          onDelete={() => this.props.onDelete()}
          hide_delete={!this.props.onDelete}
          disabled={this.props.is_loading}
          save_disabled={!this.canSave()}
        />

      </>
    )
  }
}

export default connect(null, (dispatch) => bindActionCreators({
  getVenue, searchVenues,
}, dispatch))(LocationForm)
