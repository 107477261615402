import { isSuccessAction, isFailureAction } from 'actions'

export default function errorReducer(state={}, action) {
  if (isSuccessAction(action)) {
    return {}
  } else if (isFailureAction(action) && action.payload && action.payload.errors) {
    return action.payload.errors || {}
  }

  return state
}

