import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeEmail } from 'actions/api/users'

import FormField from 'components/FormField'

class ChangeEmail extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show_success: false,
      current_email: props.current_email,
      user: {},
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      current_email: props.current_email
    })
  }

  submit() {
    this.setState({
      is_loading: true,
    }, () => {
      this.props.changeEmail(this.state.user).then(
        (response) => {
          if (response.error) {
            this.setState({
              is_loading: false,
              show_success: false,
            })
          } else {
            this.setState({
              is_loading: false,
              show_success: true,
              user: {},
            })
          }
        }
      )
    })
  }

  updateUser(updates) {
    this.setState({
      user: {
        ...this.state.user,
        ...updates,
      }
    })
  }

  render() {
    return (
      <>
        <div className='mb-3'>
          <label className='form-label'>Current Email</label>
          <input
            type='text'
            name='current_email'
            className='form-control'
            value={this.state.current_email}
            disabled
          />
        </div>

        <div className='mb-3'>
          <FormField
            label='Current Password'
            type='password'
            name='current_password'
            autoComplete='current-password'
            errors={this.props.api_errors}
            value={this.state.user.current_password || ''}
            onChange={(e) => this.updateUser({ current_password: e.target.value })}
          />
        </div>

        <div className='mb-3'>
          <FormField
            label='New Email'
            type='text'
            name='new_email'
            autoComplete='email'
            errors={this.props.api_errors}
            value={this.state.user.email || ''}
            onChange={(e) => this.updateUser({ email: e.target.value })}
          />
        </div>

        <div>
          <a
            className='btn btn-primary mb-0'
            onClick={() => this.submit()}
            disabled={this.state.is_loading}
          >Update Email</a>
        </div>

        {this.state.show_success && (
          <div className='alert alert-success mt-3'>
            You have successfully changed your email!&nbsp;
            <a onClick={() => this.setState({ show_success: false })}>
              (dismiss)
            </a>
          </div>
        )}
      </>
    )
  }

}

export default connect((state, props) => {
  const api = state.api || {}
  return {
    api_errors: api.errors
  }
}, (dispatch) => bindActionCreators({
  changeEmail
}, dispatch))(ChangeEmail)
