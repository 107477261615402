import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectModel, selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import {
  adminGetBand,
  adminUpdateBand,
  adminDeleteBand
} from 'actions/api/admin/bands'

import LoadingState from 'components/LoadingState'
import ConfirmationButton from 'components/ConfirmationButton'
import SaveBar from 'components/SaveBar'
import DirectFileUploader from 'components/DirectFileUploader'
import AdminHeader from 'components/admin/AdminHeader'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'

import { MAX_AVATAR_SIZE, MAX_NUM_DAY_OF_SHOW } from 'static'

import { iff } from 'utils'

class EditBandPage extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      band: props.band || {},
      is_loading: false,
    }
  }

  componentWillMount() {
    this.props.adminGetBand(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      props.adminGetBand(props.match.params.id)
    }

    if (_.isEmpty(this.state.band)) {
      this.setState({ band: props.band || {} })
    }
  }

  deleteBand() {
    this.setLoading(() => {
      this.props.adminDeleteBand(this.props.match.params.id).then(
        (response) => {
          this.clearLoading()
          if (!response.error) { this.props.history.push('/admin') }
        }
      )
    })
  }

  updateNumDayOfShow(num) {
    let update_value = num
    if (num) {
      update_value = Math.round(Math.max(Math.min(num, MAX_NUM_DAY_OF_SHOW), 0), 0)
    }
    this.updateBand({ default_num_day_of_show: update_value })
  }

  updateBand(update, callback) {
    this.setState({
      band: {
        ...this.state.band,
        ...update,
      }
    }, () => iff(callback))
  }

  save() {
    this.setLoading(() => {
      this.props.adminUpdateBand(this.props.match.params.id, this.state.band).then(
        (response) => this.clearLoading()
      )
    })
  }

  submit() {
    this.setLoading(() => {
      this.props.adminUpdateBand(this.props.match.params.id, this.state.band).then(
        (response) => {
          this.clearLoading()
          if (!response.error) {
            this.props.history.push(`/admin${this.props.band.url}`)
          }
        }
      )
    })
  }

  render() {
    const band = this.state.band

    return (
      <>
        <div className='container'>
          <AdminHeader />
          <h2>
            Edit Band - {band.name}
          </h2>

          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className='mb-3'>
                <FormField
                  type='text'
                  name='name'
                  label='Band Name'
                  value={band.name || ''}
                  errors={this.props.api_errors}
                  onChange={(e) => this.updateBand({ name: e.target.value })}
                />
              </div>

              <div className='mb-3'>
                <FormField
                  type='text'
                  name='username'
                  label='Username'
                  value={band.username || ''}
                  errors={this.props.api_errors}
                  onChange={(e) => this.updateBand({ username: e.target.value })}
                />
              </div>

              <FormCheck
                name='is_test'
                label='IS TEST DATA'
                errors={this.props.api_errors}
                checked={this.state.band.is_test || false}
                onChange={(e) => this.updateBand({ is_test: e.target.checked })}
              />

              <h5 className="mt-3">Profile Picture</h5>
              {this.props.band.avatar_url && (
                <img
                  src={this.props.band.avatar_url}
                  className='avatar'
                />
              )}
              <DirectFileUploader
                onUploadSuccess={(signedIds) => this.updateBand({
                  avatar_image: _.first(signedIds)
                }, () => this.save())}
                setFileData={(fd) => this.setState({ fileData: fd })}
                fileData={this.state.fileData}
                maxFileSize={MAX_AVATAR_SIZE}
              />
            </div>

            <div className='col-12 col-md-6'>
              <label className="d-block mb-3 small">
                Website
                <div className="input-group">
                  <span className="input-group-text">http://</span>
                  <input
                    className='form-control'
                    type='text'
                    name='website'
                    value={band.website || ''}
                    onChange={(e) => this.updateBand({ website: e.target.value })}
                  />
                </div>
              </label>

              <div>
                <fieldset className='border rounded-3 p-3 pt-1'>
                  <legend className="float-none w-auto px-3 m-0 small">Default Production Details</legend>
                  <label>
                    <input
                      name='default_house_sound'
                      type='checkbox'
                      checked={band.default_house_sound || false}
                      onChange={(e) => this.updateBand({ default_house_sound: e.target.checked })}
                    />
                    <span className="ps-2">Using House Sound Engineer</span>
                  </label>

                  <label>
                    <input
                      name='default_house_lights'
                      type='checkbox'
                      checked={band.default_house_lights || false}
                      onChange={(e) => this.updateBand({ default_house_lights: e.target.checked })}
                    />
                    <span className="ps-2">Using House Lighting Engineer</span>
                  </label>

                  <label className="d-block mt-3">
                    <span className="small d-block">Members Present Day of Show</span>
                    <input
                      className="form-control"
                      name='default_num_day_of_show'
                      type='number'
                      placeholder='0'
                      value={band.default_num_day_of_show || ''}
                      onChange={(e) => this.updateNumDayOfShow(e.target.value)}
                    />
                  </label>

                  <label className="d-block mt-3">
                    <span className="small d-block">Production Description</span>
                    <textarea
                      className="form-control"
                      name='default_production_description'
                      value={band.default_production_description || ''}
                      onChange={(e) => this.updateBand({ default_production_description: e.target.value })}
                    />
                  </label>

                  <label className="d-block mt-3">
                    <span className="small d-block">Additional Show Notes</span>
                    <textarea
                      className="form-control"
                      name='default_show_notes'
                      value={band.default_show_notes || ''}
                      onChange={(e) => this.updateBand({ default_show_notes: e.target.value })}
                    />
                  </label>
                </fieldset>
                <label className="d-block mt-3">
                  <span className="small d-block">Default Transportation Description</span>
                  <textarea
                    className="form-control"
                    name='default_transportation'
                    value={band.default_transportation || ''}
                    onChange={(e) => this.updateBand({ default_transportation: e.target.value })}
                  />
                </label>
              </div>
            </div>
          </div>

          <ConfirmationButton
            button_type="danger"
            button_text="Delete Band"
            confirm_text="Really Delete Band"
            onConfirm={() => this.deleteBand()}
            disabled={this.state.is_loading}
          />
        </div>

        <div className='spacer'></div>

        <SaveBar
          onSubmit={() => this.submit()}
          disabled={this.state.is_loading}
        />
      </>
    )
  }

}

export default connect((state, props) => ({
  api_errors: selectApiErrors(state),
  band: selectModel('bands', props.match.params.id, Resources.band, state),
}), (dispatch) => bindActionCreators({
  adminGetBand, adminUpdateBand, adminDeleteBand
}, dispatch))(EditBandPage)
