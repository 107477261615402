import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sendClientError } from 'actions/api'

class ErrorHandler extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error, info) {
    this.setState({ is_error: true })
    this.props.sendClientError(error.message)
  }

  render() {
    if (this.state.is_error) {
      return (
        <div className='container'>
          <div className='row justify-content-center mt-4'>
            <div className='col-12 col-md-8 col-lg-6'>
              <div className='text-center alert alert-primary'>
                <p>
                  There was an unexpected error!
                </p>

                <p>
                  Please send us an email (
                    <a href='mailto:info@backline.me'>
                      info@backline.me
                    </a>
                  ) with details about the error.  Make sure to include the time and date!
                </p>

                <p>
                  To continue using Backline, please <a onClick={() => location.reload()}>reload the page</a>.  If the error persists, please <a href="/">go to the homepage</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}


export default connect(null, (dispatch) => bindActionCreators({
  sendClientError
}, dispatch))(ErrorHandler)
