import React from 'react'
import classnames from 'classnames'

import { iff } from 'utils'

export default class ConfirmationButton extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      is_open: false
    }
  }

  onConfirm() {
    this.setState({
      is_open: false
    }, () => iff(this.props.onConfirm))
  }

  setIsOpen(is_open) {
    this.setState({ is_open }, () => iff(this.props.onStateChange, is_open))
  }

  render() {
    const cancel_text = this.props.cancel_text || "cancel"
    const confirm_text = this.props.confirm_text || "confirm"
    const button_text = this.props.button_text || "button"
    const button_type_class = this.props.button_type && `btn-${this.props.button_type}`
    if (this.state.is_open) {
      return (
        <span>
          <button
            className={classnames('btn', button_type_class, this.props.button_classes)}
            onClick={() => this.onConfirm()}
            disabled={this.props.disabled}
          >{confirm_text}</button>
          <button
            className={classnames('btn', 'btn-gray', this.props.button_classes)}
            onClick={() => this.setIsOpen(false)}
          >{cancel_text}</button>
        </span>
      )

    } else {
      return (
        <button
          className={classnames('btn', button_type_class, this.props.button_classes)}
          onClick={() => this.setIsOpen(true)}
          disabled={this.props.disabled}
        >{button_text}</button>
      )
    }
  }
}
