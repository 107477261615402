import React from 'react'

import SafeLink from 'components/SafeLink'
import BottomBar from 'components/BottomBar'

import { withRouter } from 'react-router'

const SWIPE_SENSITIVITY = 50
const SWIPE_SLOPE = 8
const SWIPE_TIME = 250

class GigBottomBar extends React.Component {

  componentWillMount() {
    this.handle_key_press = this.handleKeyPress.bind(this)
    this.handle_touch_start = this.handleTouchStart.bind(this)
    this.handle_touch_move = this.handleTouchMove.bind(this)
    this.handle_touch_end = this.handleTouchEnd.bind(this)

    if (window.is_mobile && window.use_gig_swipe) {
      document.addEventListener('touchstart', this.handle_touch_start, false)
      document.addEventListener('touchmove', this.handle_touch_move, false)
      document.addEventListener('touchend', this.handle_touch_end, false)
    }

    if (!window.is_mobile && window.use_gig_arrow_keys) {
      document.addEventListener("keydown", this.handle_key_press)
    }
  }

  componentWillUnmount() {
    if (window.is_mobile && window.use_gig_swipe) {
      document.removeEventListener('touchstart', this.handle_touch_start)
      document.removeEventListener('touchmove', this.handle_touch_move)
      document.removeEventListener('touchend', this.handle_touch_end)
    }

    if (!window.is_mobile && window.use_gig_arrow_keys) {
      document.removeEventListener("keydown", this.handle_key_press)
    }
  }

  handleTouchStart(e) {
    this.x_start = e.touches[0].clientX
    this.y_start = e.touches[0].clientY
    setTimeout(() => this.evaluateSwipe(), SWIPE_TIME)
  }

  handleTouchMove(e) {
    if (!this.x_start || !this.y_start) { return }

    this.x_end = e.touches[0].clientX
    this.y_end = e.touches[0].clientY
  }

  handleTouchEnd(e) {
    this.x_start = null
    this.y_start = null
    this.x_end = null
    this.y_end = null
  }

  evaluateSwipe() {
    if (!this.x_start || !this.y_start) { return }
    if (!this.x_end || !this.y_end) { return }

    const x_diff = this.x_end - this.x_start
    const y_diff = this.y_end - this.y_start
    const x_abs = Math.abs(x_diff)
    const y_abs = Math.abs(y_diff)

    // Strong Horizontal Swipe Only (steep slope)
    if (x_abs > y_abs * SWIPE_SLOPE && x_abs > SWIPE_SENSITIVITY) {
      if (x_diff > 0) { // left -> right
        if (this.props.gig.previous_gig_url) {
          this.props.history.push(this.props.gig.previous_gig_url)
        }
      } else { // left <- right
        if (this.props.gig.next_gig_url) {
          this.props.history.push(this.props.gig.next_gig_url)
        }
      }
    }
  }

  handleKeyPress(event) {
    switch (event.key) {
      case 'ArrowLeft':
        if (this.props.gig.previous_gig_url) {
          event.preventDefault()
          this.props.history.push(this.props.gig.previous_gig_url)
        }
        break;
      case 'ArrowRight':
        if (this.props.gig.next_gig_url) {
          event.preventDefault()
          this.props.history.push(this.props.gig.next_gig_url)
        }
        break;
    }
  }

  render() {
    const gig = this.props.gig || {}
    if (!gig.previous_gig_url && !gig.next_gig_url) {
      return null
    }

    return (
      <>
        <div className='mb-5 pb-3'></div>
        <div className='bottom-bar'>
          <div className='container d-flex flex-row p-1'>
            {gig.previous_gig_url && (
              <div className='flex-grow-1 text-start'>
                <SafeLink
                  className='btn btn-link fw-bold text-decoration-none'
                  to={gig.previous_gig_url}
                >&lt; Previous</SafeLink>
              </div>
            )}

            {gig.next_gig_url && (
              <div className='flex-grow-1 text-end'>
                <SafeLink
                  className='btn btn-link fw-bold text-decoration-none'
                  href={gig.next_gig_url}
                >Next &gt;</SafeLink>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(GigBottomBar)
