import React from 'react'
import _ from 'lodash'

import ErrorLabel from 'components/ErrorLabel'
import LoadingState from 'components/LoadingState'
import PhoneInput from 'components/people/PhoneInput'
import SaveBar from 'components/SaveBar'
import SafeLink from 'components/SafeLink'
import AccountPage from 'components/users/AccountPage'
import DirectFileUploader from 'components/DirectFileUploader'
import Row from 'components/Row'
import FormField from 'components/FormField'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectModel, selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { getPerson, updatePerson } from 'actions/api/people'

import { MAX_AVATAR_SIZE } from 'static'

import { iff } from 'utils'

class PersonForm extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      person: props.person || {},
    }
  }

  componentWillMount() {
    this.props.getPerson(this.props.match.params.id)
  }

  componentWillReceiveProps(props) {
    if (_.isEmpty(this.state.person)) {
      this.setState({
        person: props.person
      })
    }
  }

  updatePerson(update, callback) {
    this.setState({
      person: {
        ...this.state.person,
        ...update,
      }
    }, () => iff(callback))
  }

  save() {
    this.setLoading(() => {
      this.props.updatePerson(this.props.match.params.id, this.state.person)
        .then((response) => this.clearLoading())
    })
  }

  submit() {
    this.setLoading(() => {
      this.props.updatePerson(this.props.match.params.id, this.state.person)
        .then((response) => {
          this.clearLoading()
          if (!response.error) {
            this.props.history.push(this.props.person.url)
          }
        })
    })
  }

  render() {
    const person = this.state.person || {}
    return (
      <>
        <div className='container mb-4'>
          {person.is_owner && (
            <SafeLink to='/user/profile' className='d-block mb-1'>
              &lt;&nbsp;Back to Your Profile
            </SafeLink>
          )}
          <h2>Edit Contact - {person.name}</h2>

          <div className='row'>
            <div className='col-12 col-md-6 col-lg-4 mb-3'>
              <FormField
                type='text'
                name='name'
                label='Name'
                value={person.name || ''}
                errors={this.props.api_errors}
                onChange={(e) => this.updatePerson({ name: e.target.value })}
              />
            </div>

            {person.is_owner && (
              <div className='col-12 col-md-6 col-lg-4 mb-3'>
                <input
                  type='text'
                  name='username'
                  label='Username'
                  value={person.username || ''}
                  onChange={(e) => this.updatePerson({ username: e.target.value })}
                  errors={this.props.api_errors}
                />
              </div>
            )}

            <div className='col-12 col-md-6 col-lg-4 mb-3'>
              <label className='form-label'>Phone</label>
              <PhoneInput
                name='phone'
                value={person.phone || ''}
                onChange={(v) => this.updatePerson({ phone: v })}
              />
            </div>

            {!person.is_owned && (
              <div className='col-12 col-md-6 col-lg-4 mb-3'>
                <FormField
                  type='email'
                  name='email'
                  label='Email'
                  disabled={this.props.disable_email}
                  value={person.email || ''}
                  onChange={(e) => this.updatePerson({ email: e.target.value })}
                />
              </div>
            )}
          </div>

          {person.is_owner && (
            <div className='row'>
              <div className='col-12 col-md-6 col-lg-4 mb-3'>
                <h5>Profile Picture</h5>
                {this.props.person.avatar_url && (
                  <img
                    src={this.props.person.avatar_url}
                    className='avatar mb-2'
                  />
                )}
                <DirectFileUploader
                  onUploadSuccess={(signedIds) => this.updatePerson({
                    avatar_image: _.first(signedIds)
                  }, () => this.save())}
                  setFileData={(fd) => this.setState({ fileData: fd })}
                  fileData={this.state.fileData}
                  maxFileSize={MAX_AVATAR_SIZE}
                />
              </div>
            </div>
          )}
          <button
            className='btn btn-primary me-2'
            onClick={() => this.submit()}
            disabled={this.state.is_loading}
          >save</button>
          <button
            className='btn btn-gray'
            onClick={() => this.props.history.goBack()}
            disabled={this.state.is_loading}
          >cancel</button>
        </div>

        {person.is_owner && (
          <>
            <div className='container'><hr/></div>
            <AccountPage />
          </>
        )}
      </>
    )
  }

}

export default withRouter(connect((state, props) => ({
  api_errors: selectApiErrors(state),
  person: selectModel('people', props.match.params.id, Resources.person, state),
}), (dispatch) => bindActionCreators({
  getPerson, updatePerson,
}, dispatch))(PersonForm))
