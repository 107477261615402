import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import AdminHeader from 'components/admin/AdminHeader'
import EndlessList from 'components/EndlessList'

export default class OrganizationGigsList extends EndlessList {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      is_past: false,
    }
  }

  getListFromData(data) {
    return data.gigs
  }

  onPastSwitch() {
    this.setState({ is_past: !this.state.is_past }, () => {
      this.resetList(() => this.getListData())
    })
  }

  isPast() {
    return this.state.is_past
  }

  render() {
    const title = this.isPast() ? "Past Shows" : "Upcoming Shows"
    const button_text = this.isPast() ? "See Upcoming" : "See Past"

    const gigs = this.state.list || []
    let sorted_gigs = _.sortBy(gigs, ['show_date'])

    if (this.isPast()) {
      sorted_gigs = _.reverse(sorted_gigs)
    }

    return (
      <Row>
        <div>
          <strong className='largest-text'>{title}</strong> - <a className="link-primary" role="button" onClick={() => this.onPastSwitch()}>{button_text}</a>
        </div>

        <table className='table table-striped hover'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {_.map(sorted_gigs, (g) => (
              <tr key={g.id}>
                <td>{moment(g.show_date).format("MMM Do, YYYY")}</td>
                <td>
                  {g.url && (
                    <SafeLink to={`/admin${g.url}`}>{g.name}</SafeLink>
                  )}
                  {!g.url && g.name}
                </td>
                <td>{g.city_state || 'TBA'}</td>
              </tr>
            ))}
            {this.state.num_pages > this.state.page + 1 && (
              <tr>
                <td colSpan='3'>
                    <div className='text-center'>
                      <a onClick={() => this.getMoreItems()}>
                        See More Shows
                      </a>
                    </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </Row>
    )
  }

}
