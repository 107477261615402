import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { connect } from 'react-redux'
import { selectCurrentUser } from 'selectors'

import SafeLink from 'components/SafeLink'
import Avatar from 'components/Avatar'
import OtherSelect from 'components/OtherSelect'
import PhoneInput from 'components/people/PhoneInput'
import FormField from 'components/FormField'
import FormCheck from 'components/FormCheck'
import ShowEmail from 'components/people/ShowEmail'
import ShowPhone from 'components/people/ShowPhone'

import {
  INVITE_STATUS,
  PERMISSIONS_OPTIONS
} from 'static'

class AdvanceCrewMemberEdit extends React.Component {

  isCurrentUser() {
    const current_user = this.props.current_user || {}
    const crew_member = this.props.crew_member || {}
    return crew_member.person_id === current_user.person_id
  }

  updateCrewMember(update) {
    this.props.onUpdate(update)
  }

  updatePerson(update) {
    const person = this.props.crew_member.person || {}
    this.props.onUpdate({
      person: {
        ...person,
        ...update,
      }
    })
  }

  onEdit() {
    this.props.onUpdate({ is_editing: true })
  }

  renderEditForm() {
    const crew_member = this.props.crew_member || {}
    const person = crew_member.person || {}
    return (
      <div className='p-1'>
        <div className='row'>
          <div className='col-12 col-md-6 col-lg-3 mb-1'>
            <FormField
              label='Name'
              type='text'
              name='name'
              value={person.name || ''}
              onChange={(e) => this.updatePerson({ name: e.target.value })}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-3 mb-1'>
            <FormField
              label='Email'
              type='text'
              name='email'
              value={person.email || ''}
              onChange={(e) => this.updatePerson({ email: e.target.value })}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-3 mb-1'>
            <label className='form-label'>Phone</label>
            <PhoneInput
              name='phone'
              value={person.phone || ''}
              onChange={(v) => this.updatePerson({ phone: v })}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-3 mb-1'>
            <label className='form-label'>Title</label>
            <OtherSelect
              name='title'
              classes='mb-0'
              options={_.map(this.props.title_options || [], (ct) => (
                { value: ct, label: ct }
              ))}
              onChange={(v) => this.updateCrewMember({ title: v })}
              value={crew_member.title}
              disabled={this.props.is_loading}
            />
          </div>
        </div>

        <div className='margin-bottom'>
          <FormCheck
            label='Point of Contact'
            name='is_contact'
            className='half-margin-bottom'
            type='checkbox'
            checked={crew_member.is_contact || false}
            onChange={(e) => this.updateCrewMember({ is_contact: e.target.checked })}
            disabled={this.props.disabled}
          />

          <FormCheck
            label='Present Day of Show'
            name='is_on_site'
            className='half-margin-bottom'
            type='checkbox'
            checked={crew_member.is_on_site || false}
            onChange={(e) => this.updateCrewMember({ is_on_site: e.target.checked })}
            disabled={this.props.disabled}
          />
        </div>

        <div>
          <a
            className='btn btn-primary mb-0'
            onClick={() => this.props.onSave()}
          >save</a>
          <a
            className='btn btn-gray mb-0'
            onClick={() => this.props.onUpdate({ is_editing: false }) }
          >cancel</a>
        </div>

        {this.props.is_loading && (
          <div> <strong> loading...  </strong> </div>
        )}
      </div>
    )
  }

  render() {
    const crew_member = this.props.crew_member || {}
    return (
      <div className={classnames('p-2 list-group-item clearfix', {
        'list-group-item-gray': this.props.is_loading,
        'list-group-item-danger': this.props.crew_member.invite_status === INVITE_STATUS.REJECTED,
      })}>

        {crew_member.is_editing && this.props.is_editable ? this.renderEditForm() : (
          <>
            {this.props.is_editable && (
              <>
                <a
                  className='text-danger float-end'
                  onClick={() => this.props.onDelete()}
                >
                  <i className='fi-trash delete-icon'></i>
                </a>
                <a
                  className='text-primary float-end me-2'
                  onClick={() => this.onEdit()}
                >
                  <i className='edit-icon fi-pencil icon-base'></i>
                </a>
              </>
            )}

            <span className='text-dark me-2'>
              <Avatar
                classes='small-avatar mb-0'
                blank_icon='Person'
                url={crew_member.avatar_url}
              />&nbsp;

              <strong>{crew_member.name}</strong>

              {crew_member.title && (<i className='d-none d-lg-inline-block'>&nbsp;&ndash;&nbsp;{crew_member.title}</i>)}
              {crew_member.email && (
                <span className='d-none d-lg-inline-block'>&nbsp;&ndash;&nbsp;<ShowEmail email={crew_member.email} /></span>
              )}
              {crew_member.phone && (
                <span className='d-none d-lg-inline-block'>&nbsp;&ndash;&nbsp;<ShowPhone number={crew_member.phone} show_icon /></span>
              )}
              {crew_member.is_on_site && (
                <span className='badge bg-primary ms-2'>
                  day of show
                </span>
              )}
              {crew_member.is_contact && (
                <span className='badge bg-success ms-2'>
                  primary contact
                </span>
              )}
            </span>

            {crew_member.invite_status === INVITE_STATUS.REJECTED && (
              <div className='float-end'><strong>Invite Rejected</strong>&nbsp;</div>
            )}

            {(crew_member.username || crew_member.title) && (
              <div className='d-lg-none'>
                {crew_member.username && (<span>@{crew_member.username}</span>)}
                {crew_member.username && crew_member.title && (<span>&nbsp;&ndash;&nbsp;</span>)}
                {crew_member.title && (<i>{crew_member.title}</i>)}
              </div>
            )}
            <div className='d-lg-none'> <ShowEmail email={crew_member.email} show_icon /> </div>
            <div className='d-lg-none'> <ShowPhone phone={crew_member.phone} show_icon /> </div>
          </>
        )}
      </div>
    )
  }
}

export default connect((state) => ({
  current_user: selectCurrentUser(state)
}))(AdvanceCrewMemberEdit)
