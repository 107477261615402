import React from 'react'

import ShowGuestListSpot from 'components/guest_list/ShowGuestListSpot'
import GuestListSpotForm from 'components/guest_list/GuestListSpotForm'

export default class EditableGuestListSpot extends React.Component {

  onUpdate(update) {
    if (this.props.onUpdate) {
      this.props.onUpdate(update)
    }
  }

  onSave(update) {
    if (this.props.onSave) {
      this.props.onSave(update)
    }
  }

  onEdit() {
    if (this.props.onEdit) {
      this.props.onEdit()
    }
  }

  onDelete() {
    if (this.props.onDelete) {
      this.props.onDelete()
    }
  }

  renderEdit() {
    return (
      <GuestListSpotForm
        guest_list_spot={this.props.guest_list_spot}
        onUpdate={(u) => this.onUpdate(u)}
        onSave={(u) => this.onSave(u)}
        onDelete={() => this.onDelete()}
        is_mobile={this.props.is_mobile}
        is_disabled={this.props.is_disabled}
      />
    )
  }

  renderShow() {
    return (
      <ShowGuestListSpot
        guest_list_spot={this.props.guest_list_spot}
        onEdit={() => this.onEdit()}
        show_notes={this.props.show_notes}
        is_mobile={this.props.is_mobile}
        is_editable={this.props.is_editable}
      />
    )
  }

  render() {
    if (this.props.guest_list_spot.is_editing) {
      return this.renderEdit()
    } else {
      return this.renderShow()
    }
  }
}
