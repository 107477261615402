import React from 'react'
import classnames from 'classnames'
import moment from 'moment'
import _ from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { resourceGet } from 'actions/api'
import { getPendingGigs } from 'actions/api/gigs'

import SafeLink from 'components/SafeLink'
import LoadingState from 'components/LoadingState'

class ShowInvites extends LoadingState {

  constructor(props) {
    super(props)

    const person = props.person || {}

    this.state = {
      pending_gigs: person.pending_gigs || [],
    }
  }

  componentWillMount() {
    this.props.getPendingGigs().then((response) => {
      this.setState({ pending_gigs: response.payload.pending_gigs })
    })
  }

  accept(invite, index) {
    this.setLoading(() => {
      this.props.resourceGet(`/api${invite.url}/accept`).then((response) => {
        this.clearLoading(() => {
          if (!response.error) {
            const payload = response.payload.raw
            this.props.history.push(payload.redirect_url)
          }
        })
      })
    })
  }

  reject(invite, index) {
    this.setLoading(() => {
      this.props.resourceGet(`/api${invite.url}/reject`).then((response) => {
        this.clearLoading(() => {
          if (!response.error) {
            this.removePendingGig(index)
          }
        })
      })
    })
  }

  removePendingGig(nx, callback) {
    let new_pending_gigs = this.state.pending_gigs.slice(0,nx)
    this.setState({
      pending_gigs: [
        ...this.state.pending_gigs.slice(0,nx),
        ...this.state.pending_gigs.slice(nx+1),
      ]
    }, () => { if (callback) { callback() } })
  }


  renderInvite(invite, index) {
    const gig = invite.gig || {}
    const mobile_date = moment(gig.show_date).format("MMMM Do YYYY")
    const dow = moment(gig.show_date).format("ddd")
    const date = moment(gig.show_date).format("MMM DD")

    // MOBILE
    if (window.is_mobile) {
      return (
        <div key={invite.id} className='alert alert-warning'>
          <h6>
            <SafeLink to={gig.url}>
              {gig.name}
            </SafeLink>
          </h6>

          <div className='mb-2'>
            <strong>
              {mobile_date}
            </strong>
          </div>

          <div>
            <a
              className='btn btn-primary mb-0'
              onClick={() => this.accept(invite, index)}
              disabled={this.state.is_loading}
            >accept</a>
            <a
              className='btn btn-danger mb-0'
              onClick={() => this.reject(invite, index)}
              disabled={this.state.is_loading}
            >reject</a>
          </div>
        </div>
      )

    // DESKTOP
    } else {
      return (
      <div className='list-group-item d-flex flex-row align-items-center' key={invite.id}>
        <div className='flex-grow-1 row align-items-center justify-content-start'>
          <div className='col-4 col-md-3 col-lg-2'>
            <div>
              {dow}
            </div>
            <div>
              {date}
            </div>
          </div>

          <div className='col'>
            <SafeLink to={gig.url}>
              {gig.name}
            </SafeLink>
          </div>

          <div className='col'>
            {invite.owner_name}
          </div>
        </div>

        <div className='flex-grow-0'>
          <a
            className='btn btn-primary mb-0'
            onClick={() => this.accept(invite, index)}
            disabled={this.state.is_loading}
          >accept</a>
          <a
            className='btn btn-danger mb-0'
            onClick={() => this.reject(invite, index)}
            disabled={this.state.is_loading}
          >reject</a>
        </div>
      </div>
      )
    }
  }

  render() {
    const pending_gigs = this.state.pending_gigs || []
    if (pending_gigs.length <= 0) { return null }

    return (
      <div>
        <h3>Your Show Invites</h3>

        <div className={classnames('mb-3', { 'list-group': !window.is_mobile })}>
          {_.map(pending_gigs, (inv, nx) => this.renderInvite(inv, nx))}
        </div>

      </div>
    )
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  resourceGet,
  getPendingGigs,
}, dispatch))(withRouter(ShowInvites))
