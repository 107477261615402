import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectApiErrors } from 'selectors'
import { bindActionCreators } from 'redux'
import {
  createScheduleTemplate,
  deleteScheduleTemplate
} from 'actions/api/schedule_templates'

import {
  adminCreateScheduleTemplate,
  adminDeleteScheduleTemplate
} from 'actions/api/admin/schedule_templates'

import PageTitle from 'components/PageTitle'
import SafeLink from 'components/SafeLink'
import ErrorLabel from 'components/ErrorLabel'
import FormField from 'components/FormField'
import LoadingState from 'components/LoadingState'

class ScheduleTemplateList extends LoadingState {

  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      schedule_template: {}
    }
  }

  updateScheduleTemplate(update) {
    this.setState({
      schedule_template: {
        ...this.state.schedule_template,
        ...update,
      }
    })
  }

  isAdminPage() {
    return !!this.props.location.pathname.match('admin')
  }

  getUrl(url) {
    if (this.isAdminPage()) {
      return `/admin${url}`
    } else {
      return url
    }
  }

  createScheduleTemplateRequest(schedule_templatable_id, data) {
    if (this.isAdminPage()) {
      return this.props.adminCreateScheduleTemplate(schedule_templatable_id, data)
    } else {
      return this.props.createScheduleTemplate(schedule_templatable_id, data)
    }
  }

  deleteScheduleTemplateRequest(id) {
    if (this.isAdminPage()) {
      return this.props.adminDeleteScheduleTemplate(id)
    } else {
      return this.props.deleteScheduleTemplate(id)
    }
  }

  onCreateScheduleTemplate() {
    const venue_id = _.get(this, 'props.venue.id')
    const band_id = _.get(this, 'props.band.id')

    let createRequest
    if (band_id) {
      const payload = { ...this.state.schedule_template, schedule_templatable_type: 'Band' }
      createRequest = this.createScheduleTemplateRequest(band_id, payload)
    } else if (venue_id) {
      const payload = { ...this.state.schedule_template, schedule_templatable_type: 'Venue' }
      createRequest = this.createScheduleTemplateRequest(venue_id, payload)
    }
    this.setLoading(createRequest.then((res) => {
      this.clearLoading()
      if (!res.error) {
        this.props.history.push(_.get(res, 'payload.raw.schedule_template.url'))
      }
    }))
  }

  onDestroyScheduleTemplate(id) {
    this.setLoading(this.deleteScheduleTemplateRequest(id).then((res) => this.clearLoading()))
  }

  render() {
    const schedule_templates = _.get(this, 'props.schedule_templates', [])

    return (
      <>
        <h4>Schedule Templates</h4>
        <div className='list-group list-group-striped mb-3'>
          {_.map(schedule_templates, (st, i) => (
            <div key={`${st.name}-${i}`} className='list-group-item'>
              <div className='d-flex flex-row align-items-center'>
                <div className='flex-grow-1'>
                  <SafeLink to={this.getUrl(st.url)} className='text-decoration-none'>{st.name}</SafeLink>
                </div>
                <div className='flex-grow-1 text-end'>
                  <a
                    className='text-danger'
                    onClick={() => this.onDestroyScheduleTemplate(st.id)}
                  ><i className='fi-trash icon-medium'></i></a>
                </div>
              </div>
            </div>
          ))}

          {schedule_templates.length <= 0 && (
            <div className='list-group-item text-center'>
              You have no schedule templates!
            </div>
          )}
        </div>

        {this.props.is_editable && (
          <>
            {this.state.show_new_form ? (
              <div className='row'>
                <div className='col-lg-6 col-md-9 col-12'>
                  <div className='border rounded p-3'>
                    <h5>Add New Schedule</h5>
                    <div className='mb-3'>
                      <FormField
                        label="Schedule Template Name"
                        type='text'
                        name='name'
                        value={this.state.schedule_template.name}
                        onChange={(e) => this.updateScheduleTemplate({ name: e.target.value })}
                      />
                    </div>
                    <div>
                      <a
                        className='btn btn-primary me-2'
                        onClick={() => this.onCreateScheduleTemplate()}
                      > Create </a>
                      <a
                        className='btn btn-gray'
                        onClick={() => this.setState({ show_new_form: false })}
                      > Cancel </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <a
                className='btn btn-primary'
                onClick={() => this.setState({ show_new_form: true })}
              > Create Schedule Template </a>
            )}
          </>
        )}
      </>
    )
  }
}

export default withRouter(connect((state, props) => ({
  api_errors: selectApiErrors(state),
}), (dispatch) => bindActionCreators({
  createScheduleTemplate,
  deleteScheduleTemplate,
  adminCreateScheduleTemplate,
  adminDeleteScheduleTemplate,
}, dispatch))(ScheduleTemplateList))
