import { resourceRSAA, jsonRSAA } from 'utils'

export const ADMIN_PERSON_ACTION = 'ADMIN//PERSON'
export function adminGetPeople(page) {
  return resourceRSAA(ADMIN_PERSON_ACTION, `/api/admin/people?page=${page}`)
}

export function adminGetPerson(id) {
  return resourceRSAA(ADMIN_PERSON_ACTION, `/api/admin/people/${id}`)
}

export function adminGetPersonCrewMembers(id, page) {
  return jsonRSAA(ADMIN_PERSON_ACTION, `/api/admin/people/${id}/crew_members?page=${page}`, null)
}

export function adminGetPersonVisibilities(id, page) {
  return jsonRSAA(ADMIN_PERSON_ACTION, `/api/admin/people/${id}/visibilities?page=${page}`, null)
}

export function adminGetPersonEmailInvites(id, page) {
  return jsonRSAA(ADMIN_PERSON_ACTION, `/api/admin/people/${id}/email_invites?page=${page}`, null)
}

export function adminUpdatePerson(id, data) {
  return resourceRSAA(ADMIN_PERSON_ACTION, `/api/admin/people/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ person: data }),
  })
}

export function adminDeletePerson(id) {
  return resourceRSAA(ADMIN_PERSON_ACTION, `/api/admin/people/${id}`, { method: 'DELETE' })
}
