import { resourceRSAA } from 'utils'

export const ATTACHMENT_ACTION = 'ATTACHMENT'

export function deleteAttachment(id) {
  return resourceRSAA(ATTACHMENT_ACTION, `/api/attachments/${id}`, { method: 'DELETE' })
}

export function updateAttachment(id, data) {
  return resourceRSAA(ATTACHMENT_ACTION, `/api/attachments/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ attachment: data }),
  })
}

export function createAttachment(attachable_url, data) {
  return resourceRSAA(ATTACHMENT_ACTION, `/api${attachable_url}/attachments/`, {
    method: 'POST',
    body: JSON.stringify({ attachment: data })
  })
}

