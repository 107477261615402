import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createFinancialDocument } from 'actions/api/financial_documents'

import DirectFileUploader from 'components/DirectFileUploader'
import OtherSelect from 'components/OtherSelect'

import {
  MAX_DOCUMENT_SIZE,
  FINANCIAL_DOCUMENT_NAMES,
} from 'static'
import { iff } from 'utils'
import _ from 'lodash'

const DEFAULT_DOC_NAME = 'Final Ticket Summary'
const NewFinancialDocumentForm = ({ gigId, createFinancialDocument, onUploadSuccess }) => {
  const [fileData, setFileData] = useState(null)
  const [doc, setDoc] = useState({})
  const [docName, setDocName] = useState('')

  useEffect(() => {
    if (!_.isEmpty(doc)) {
      iff(submitToServer)
    }
  }, [doc])

  const submitToServer = () => {
    createFinancialDocument(gigId, doc).then(onUploadSuccess)
  }

  const uploadSuccess = (signedIds) => {
    setDoc({
      ...doc,
      id: null,
      document_file: _.first(signedIds),
      name: docName || DEFAULT_DOC_NAME,
    })
  }

  return (
    <>
      <div className='mb-2'>
        <OtherSelect
          name='name'
          placeholder='Document Name'
          classes='mb-2'
          options={FINANCIAL_DOCUMENT_NAMES.map(docName => (
            { value: docName, label: docName }
          ))}
          value={docName}
          onChange={v => setDocName(v)}
        />
      </div>
      <div className='mb-2'>
        <DirectFileUploader
          onUploadSuccess={uploadSuccess}
          setFileData={setFileData}
          fileData={fileData}
          maxFileSize={MAX_DOCUMENT_SIZE}
          uploadButtonText={'Upload and Save'}
        />
      </div>
    </>
  )
}

export default connect(null, (dispatch) => bindActionCreators({
  createFinancialDocument
}, dispatch))(NewFinancialDocumentForm)
