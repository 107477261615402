import React from 'react'

import SharedGigs from 'components/gigs/SharedGigs'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getBandSharedGigs } from 'actions/api/bands'
import { withRouter } from 'react-router'

class BandSharedGigs extends SharedGigs {

  componentWillReceiveProps(props) {
    if (props.band_id !== this.props.band_id) {
      this.getListData(props)
    }
  }

  //Override
  requestListData(page, props=this.props) {
    return props.getBandSharedGigs(props.band_id, this.state.page)
  }

}

export default connect(null, (dispatch) => bindActionCreators({
  getBandSharedGigs,
}, dispatch))(withRouter(BandSharedGigs))
