import React from 'react'
import _ from 'lodash'

import Overlay from 'components/Overlay'

import { connect } from 'react-redux'
import { selectModel } from 'selectors'
import { bindActionCreators } from 'redux'
import { Resources } from 'schema'
import { saveScheduleTemplateFromGig } from 'actions/api/schedule_templates'
import { dismissSaveScheduleOverlay } from 'actions/ui'

import LoadingState from 'components/LoadingState'
import FormField from 'components/FormField'
import ErrorLabel from 'components/ErrorLabel'

import { PERSPECTIVE_TYPE } from 'static'

const initial_state = {
  is_loading: false,
  show_success: false,
  schedule_template: {},
}

class SaveScheduleOverlay extends LoadingState {

  constructor(props) {
    super(props)
    this.state = { ...initial_state }
  }

  resetState() {
    this.setState({ ...initial_state })
  }

  onDismiss() {
    this.props.dismissSaveScheduleOverlay()
    this.resetState()
  }

  saveScheduleTemplate() {
    const data = { ...this.state.schedule_template }
    this.setLoading(() => this.props.saveScheduleTemplateFromGig(this.props.gig_id, data).then((res) => {
      this.setState({
        is_loading: false,
        show_success: !res.error,
      })
    }))
  }

  updateScheduleTemplate(update) {
    this.setState({
      schedule_template: {
        ...this.state.schedule_template,
        ...update,
      }
    })
  }

  render() {
    const is_hidden = _.isNil(this.props.gig_id) || this.props.server_error
    const gig = _.get(this, 'props.gig', {})
    const schedule_templatable = _.get(this, 'props.schedule_templatable', {})
    const schedule_template = _.get(this, 'state.schedule_template', {})

    return (
      <Overlay is_hidden={is_hidden} onDismiss={() => this.onDismiss()}>
        <h4>
          Create Schedule Template
        </h4>
        <p>
          This will copy the schedule for this show into a new schedule template for {schedule_templatable.name}.
        </p>

        <div className='mb-3'>
          <FormField
            label='Template Name'
            name='name'
            errors={this.props.api_errors}
            type='text'
            className='form-control'
            value={schedule_template.name || ''}
            onChange={(e) => this.updateScheduleTemplate({ name: e.target.value })}
          />
        </div>

        <button
          className='btn btn-primary mb-0'
          onClick={() => this.saveScheduleTemplate()}
          disabled={this.state.is_loading}
        >Create</button>

        {this.state.show_success &&
          <div className='alert alert-success mt-3'>
            Template saved!
          </div>
        }

        {this.props.error_message &&
          <div className='alert alert-danger mt-3'>
            {this.props.error_message}
          </div>
        }
      </Overlay>
    )
  }
}

export default connect((state, props) => {
  const gig_id = _.get(state, 'overlay.save_schedule_overlay.gig_id')

  let schedule_templatable = {}
  const schedule_templatable_id = _.get(state, 'overlay.save_schedule_overlay.schedule_templatable_id')
  const schedule_templatable_type = _.get(state, 'overlay.save_schedule_overlay.schedule_templatable_type')
  if (schedule_templatable_type === PERSPECTIVE_TYPE.VENUE) {
    schedule_templatable = selectModel('venues', schedule_templatable_id, Resources.venue, state)
  } else if (schedule_templatable_type === PERSPECTIVE_TYPE.BAND) {
    schedule_templatable = selectModel('bands', schedule_templatable_id, Resources.band, state)
  }

  return {
    gig_id,
    schedule_templatable,
    gig: selectModel('gigs', gig_id, Resources.gig, state),
    api_errors: state.api.errors,
    server_error: state.api.server_error,
  }
}, (dispatch) => bindActionCreators({
  saveScheduleTemplateFromGig,
  dismissSaveScheduleOverlay,
}, dispatch))(SaveScheduleOverlay)
