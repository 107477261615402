import React from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { adminSearch } from 'actions/api/admin/search'

import Row from 'components/Row'
import SafeLink from 'components/SafeLink'
import PaginatedList from 'components/PaginatedList'
import AdminHeader from 'components/admin/AdminHeader'
import AdminHomeNav from 'components/admin/AdminHomeNav'

const SEARCH_DEBOUNCE_TIME = 600
const MIN_QUERY_LENGTH = 1

class AdminSearchPage extends PaginatedList {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      query: '',
    }

    this.debounceOnChange = _.debounce(this.onChange, SEARCH_DEBOUNCE_TIME)
  }

  getListFromData(data) {
    return data.results
  }

  getItemUrl(url) {
    return url
  }

  onSubmitRequest(data) {
    if (this.props.history && this.props.location) {
      this.props.history.replace(`${this.props.location.pathname}?page=${this.state.page}`)
    }
  }

  requestListData(page) {
    return this.props.adminSearch(this.state.query, page)
  }

  onChange(query) {
    this.setState({ query }, () => {
      if (!this.state.is_loading) {
        this.getListData()
      }
    })
  }

  renderSearch() {
    if (this.state.query.length < MIN_QUERY_LENGTH) {
      return (
        <div>
          <h4>Please enter a search</h4>
        </div>
      )
    } else if (_.isEmpty(this.state.list)) {
      return (
        <div>
          <h4>No Results</h4>
        </div>
      )
    } else {
      return (
        <div>
          <table className='table table-striped hover'>
            <thead>
              <tr>
                <th>Type</th>
                <th>ID</th>
                <th>Name</th>
              </tr>
            </thead>

            <tbody>
              {_.map(this.state.list, (o, idx) => (
                <tr key={`${o.id}-${idx}`}>
                  <td> {o.type} </td>
                  <td> {o.id} </td>
                  <td> <SafeLink to={`/admin${o.url}`}>{o.name}</SafeLink> </td>
                </tr>
              ))}
            </tbody>
          </table>

          {this.renderPageButtons()}
        </div>
      )
    }
  }

  render() {
    return (
      <Row>

        <AdminHeader />
        <AdminHomeNav page='search' />

        <div className="container">
          <div className='row p-2'>
            <div className='col-12 col-md-9 col-lg-6'>
              <h5>Search</h5>
              <input
                className='form-control'
                type='text'
                name='query'
                placeholder='search'
                onChange={(e) => this.debounceOnChange(e.target.value)}
              />
            </div>
          </div>
          {this.renderSearch()}
        </div>

      </Row>
    )
  }

}


export default connect(null, (dispatch) => bindActionCreators({
  adminSearch
}, dispatch))(withRouter(AdminSearchPage))
