import _ from 'lodash'
import { RSAA, getJSON } from 'redux-api-middleware'
import { normalize } from 'normalizr'
import { Resources } from 'schema'

import {
  RSAA_POSTFIX,
  RESOURCE_POSTFIX
} from 'actions'

function addResponseData(res, payload) {
  return _.merge({
    request_status: res.status,
    request_status_text: res.statusText,
    request_url: res.url,
  }, payload)
}

export function genRSAA(action_type, endpoint, parseData=null, options={}) {
  return {
    [RSAA]: _.merge({
      credentials: 'same-origin',
      method: 'GET',
      endpoint: endpoint,
      types: [
        `${action_type}${RSAA_POSTFIX.REQUEST}`,
        {
          type: `${action_type}${RSAA_POSTFIX.SUCCESS}`,
          payload: (action, state, res) => {
            if (parseData) {
              return parseData(res)
            } else {
              return getJSON(res).then((json) => addResponseData(res, json))
            }
          }
        },
        {
          type: `${action_type}${RSAA_POSTFIX.FAILURE}`,
          payload: (action, state, res) => {
            return getJSON(res).then((json) => addResponseData(res, json))
          }
        }
      ],
    }, options)
  }
}

export function jsonRSAA(action_type, endpoint, parseData=null, options={}) {
  return genRSAA(action_type, endpoint, parseData, _.merge({
    headers: (state) => {
      const header_out = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
      if (!_.isNil(state.url_auth_token)) {
        header_out['Url-Auth-Token'] = state.url_auth_token
      }
      return header_out
    },
  }, options))
}

export function parseResources(res) {
  return getJSON(res).then((json) => {
    let output = { raw: json }

    _.each(_.keys(json), (key) => {
      const resource = Resources[key]
      if (resource) {
        const normalized = normalize(json[key], resource)
        output = _.merge(output, normalized)
      } else {
        output = _.merge(output, { result: { [key]: json[key] } })
      }
    })

    return addResponseData(res, output)
  })
}

export function resourceRSAA(action_type, endpoint, options={}) {
  return jsonRSAA(`${action_type}${RESOURCE_POSTFIX}`, endpoint, (res) => parseResources(res), options)
}

export const SUCCESS_STATUSES = [200, 201]

export function isSuccessStatus(status) {
  return _.includes(SUCCESS_STATUSES, status)
}
