import React from 'react'
import classnames from 'classnames'

export default class Avatar extends React.Component {

  getUrl() {
    if (!_.isEmpty(this.props.url)) {
      return this.props.url
    }

    const icon_url = `${window.static_asset_url}/Blank_Icons`

    switch (this.props.blank_icon) {
      case 'Band':
        return `${icon_url}/BandBlankSquare.png`
      case 'Venue':
        return `${icon_url}/VenueBlankSquare.png`
      case 'Person':
        return `${icon_url}/PersonBlankSquare.png`
      default:
        return null
    }
  }

  renderAvatar() {
    if (this.props.responsive_size) {
      return (
        <span>
          <img
            src={this.getUrl()}
            className={classnames('avatar', 'd-md-none', this.props.small_classes)}
          />
          <img
            src={this.getUrl()}
            className={classnames('avatar', 'd-none', 'd-md-block', this.props.classes)}
          />
        </span>
      )
    } else {
      return (
        <img
          src={this.getUrl()}
          className={classnames('avatar', this.props.classes, {
            'invert-colors': this.props.invert_blank && _.isEmpty(this.props.url)
          })}
        />
      )
    }
  }

  render() {
    if (this.getUrl()) {
      return this.renderAvatar()
    } else {
      return null
    }
  }

}
