import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import moment from 'moment'

import ShowAddress from 'components/addresses/ShowAddress'
import SafeLink from 'components/SafeLink'

import { MAPS_URL_BASE } from 'static'

export default class AssociatedGig extends React.Component {

  render() {
    const lodging = this.props.lodging || {}
    const gig = this.props.gig || {}
    const venue = gig.venue || {}

    const lodging_address = lodging.address || {}
    const venue_address = venue.address || {}

    const directions_link = `${MAPS_URL_BASE}/dir/?api=1&origin=${encodeURI(venue_address.address_string)}&destination=${encodeURI(lodging_address.address_string)}`

    return (
      <>
        <h5>
          <SafeLink to={gig.url}> { gig.name } </SafeLink>
        </h5>
        <strong>{moment(gig.show_date).format('dddd, MMM Do')}</strong>

        {venue.id && (
          <div className='row'>
            <div className='col'>
              <a href={venue_address.maps_url} target="_blank" className='block'>
                <ShowAddress address={venue_address} />
              </a>
            </div>

            {!_.isEmpty(lodging.address) && (
              <div className='col'>
                <a className='block' target='_blank' href={directions_link}>Get Directions</a>
              </div>
            )}
          </div>
        )}

        {!venue.id && (
          <div>No Venue Found</div>
        )}
      </>
    )
  }

}
