import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { selectCurrentUser } from 'selectors'
import { getNotificationCount } from 'actions/api/notifications'
import { logout } from 'actions/api/users'
import {
  parseUrlParams,
  setUrlParams,
  staticAssetUrl,
  isSuccessStatus,
  isTicketCountsEnabledForUser
} from 'utils'

import SearchBar from 'components/navigation/SearchBar'
import Avatar from 'components/Avatar'
import NotificationDropdown from 'components/navigation/NotificationDropdown'
import SafeLink from 'components/SafeLink'

const NOTIFICATION_REFRESH_INTERVAL = 60 * 1000 // 1 minutes

class NavigationBar extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      search: '',
      is_expanded: false,
      show_notifications: false,
    }
  }

  componentWillMount() {
    this.getNotifications()
  }

  componentWillReceiveProps(props) {
    const old_user = this.props.user || {}
    const new_user = props.user || {}
    if (old_user.id !== new_user.id) {
      this.getNotifications(props)
    }

    if (!_.isNil(props.auth_token) && _.isNil(props.user) && props.location !== this.props.location) {
      const location = _.get(props, 'location', {})
      const url_props = parseUrlParams(location.search) || {}
      const url_params = url_props.params || {}
      if (url_params.auth !== props.auth_token) {
        props.history.replace(setUrlParams(location.pathname, {
          ...url_params,
          auth: props.auth_token
        }))
      }
    }
  }

  componentDidMount() {
    window.onclick = () => this.resetDropdowns()
    document.body.addEventListener('click', this.toggleMenuForOffClicks.bind(this))
  }

  componentWillUnmount() {
    window.onclick = null
    clearInterval(this.get_notifications_interval_id)
  }

  resetDropdowns() {
    this.setState({
      show_notifications: false,
    })
  }

  showNotifications(e) {
    e.stopPropagation()
    this.setState({
      show_notifications: !this.state.show_notifications,
    })
  }

  toggleMenuForOffClicks(e) {
    const navbarEl = document.getElementById('navbar')

    if (!_.isNil(navbarEl) && !navbarEl.contains(e.target)) {
      this.collapseNavbar('hide')
    }
  }

  currentUserHasBands() {
    const currentUserBands = _.get(this, 'props.current_user.person.bands', {})
    return !_.isEmpty(currentUserBands)
  }

  getNotifications(props = this.props) {
    clearInterval(this.get_notifications_interval_id)
    const user_id = _.get(props, 'user.id')
    if (_.isNil(user_id)) {
      setTimeout(() => this.getNotifications(), NOTIFICATION_REFRESH_INTERVAL)
    } else {
      this.get_notifications_interval_id = this.props.getNotificationCount()
        .then((result) => {
          setTimeout(() => this.getNotifications(), NOTIFICATION_REFRESH_INTERVAL)
        })
    }
  }

  collapseNavbar(value) {
    $("#navbar").collapse(value);
  }

  logout() {
    this.props.logout().then((response) => {
      if (isSuccessStatus(response.payload.request_status)) {
        this.setState({ is_expanded: false }, () => {
          this.props.history.push(`/`)
        })
      }
    })
  }

  searchSubmit(e) {
    this.props.history.push(`/search?q=${this.state.search}`)
    e.preventDefault()
  }

  renderLoggedIn() {
    return (
      <>
        <div className='navbar navbar-dark navbar-expand-lg navbar-backline fixed-top'>
          <div className='container d-flex flex-row align-items-center'>
            <SafeLink className='navbar-brand' to='/'>
              <img className='site-logo' src={staticAssetUrl('Logo_White_small.png')}/>
            </SafeLink>

            <div className='d-flex d-lg-none align-items-center ms-auto'>
              {this.renderNotificationsTrigger({ 'me-3': true })}
              {this.state.show_notifications && (
                <NotificationDropdown onClose={() => this.resetDropdowns()} />
              )}

              <SafeLink className='nav-link p-0 me-2' to='/user/profile'>
                <Avatar
                  classes='nav-avatar m-0'
                  url={this.props.user.person_avatar_url}
                  blank_icon='Person'
                  no_avatar_class
                  invert_blank
                />
              </SafeLink>
              &nbsp;
            </div>

            <a
              className="navbar-toggler d-flex d-lg-none align-items-center"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar"
              aria-controls="navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </a>

            <div className="collapse navbar-collapse" id="navbar">
              <ul className="navbar-nav me-auto">
                <li className="nav-item">
                  <SafeLink className='nav-link' to="/" onClick={() => this.collapseNavbar('hide')}>Shows</SafeLink>
                </li>
                {this.currentUserHasBands() && (
                  <li className="nav-item">
                    <SafeLink className='nav-link' to="/lodgings" onClick={() => this.collapseNavbar('hide')}>Housing</SafeLink>
                  </li>
                )}
                {isTicketCountsEnabledForUser(this.props.current_user) && (
                  <li className="nav-item">
                    <SafeLink className='nav-link' to="/ticket_sales" onClick={() => this.collapseNavbar('hide')}>Tickets</SafeLink>
                  </li>
                )}
              </ul>
              <ul className="navbar-nav ms-auto me-2">
                <li className="nav-item d-lg-none">
                  <SafeLink className='nav-link' to="/search" onClick={() => this.collapseNavbar('hide')}>Search</SafeLink>
                </li>
                <li className="nav-item">
                  <SafeLink className='nav-link' to="/contact" onClick={() => this.collapseNavbar('hide')}>Contact</SafeLink>
                </li>
                {this.props.current_user.is_site_admin && (
                  <li className='nav-item'>
                    <SafeLink className='nav-link' to='/admin' onClick={() => this.collapseNavbar('hide')}>Admin</SafeLink>
                  </li>
                )}
                <li className="nav-item">
                  <a className='nav-link' onClick={() => this.logout()}>Logout</a>
                </li>
              </ul>
              <div className='d-none d-lg-flex'>
                {this.props.location.pathname !== '/search' && (
                  <SearchBar />
                )}
              </div>
            </div>


            <div className='d-none d-lg-flex align-items-center'>
              {this.renderNotificationsTrigger({ 'me-3': true })}
              {this.state.show_notifications && (
                <NotificationDropdown onClose={() => this.resetDropdowns()} />
              )}

              <SafeLink className='nav-link p-0 me-2' to='/user/profile'>
                <Avatar
                  classes='nav-avatar m-0'
                  url={this.props.user.person_avatar_url}
                  blank_icon='Person'
                  no_avatar_class
                  invert_blank
                />
              </SafeLink>
            </div>
          </div>
        </div>
        <div className='mt-5 pt-3'></div>
      </>
    )
  }

  renderNotificationsTrigger(classes={}) {
    if (!this.props.user) { return null }
    return (
      <a
        className={classnames({
          'nav-link': true,
          'notifications': true,
          'selected': this.state.show_notifications,
          ...classes,
        })}
        onClick={(e) => this.showNotifications(e)}
      >
        {this.props.num_unviewed_notifications}
      </a>
    )
  }

  renderLoggedOut() {
    return (
      <>
        <div className='navbar navbar-dark navbar-backline fixed-top'>
          <div className='container'>
            <SafeLink className='navbar-brand me-2' to='/'>
              <img className='site-logo' src={staticAssetUrl('Logo_White_small.png')}/>
            </SafeLink>
            <div className='d-flex'>
              <SafeLink className='nav-link' to='/login'>Login</SafeLink>
              <SafeLink className='nav-link' to='/contact'>Contact</SafeLink>
            </div>
          </div>
        </div>

        <div className='mt-5 pt-3'></div>
      </>
    )
  }

  render() {
    if (this.props.user && this.props.user.id) {
      return this.renderLoggedIn()
    } else {
      // DO NOT SHOW ON HOME PAGE
      const location = this.props.location || {}
      if (location.pathname === "/") {
        return null
      } else {
        return this.renderLoggedOut()
      }
    }
  }
}

export default withRouter(connect((state, props) => ({
  num_unviewed_notifications: state.notifications.num_unviewed_notifications,
  location: props.location,
  auth_token: state.url_auth_token,
  current_user: selectCurrentUser(state),
}), (dispatch) => bindActionCreators({
  logout, getNotificationCount
},dispatch))(NavigationBar))
