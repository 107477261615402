import { basicGet } from 'actions/api'
import { resourceRSAA, jsonRSAA } from 'utils'

export const BAND_ACTION = 'BAND'

export function getBand(id) {
  return resourceRSAA(BAND_ACTION, `/api/bands/${id}`)
}

export function updateBand(id, data) {
  return resourceRSAA(BAND_ACTION, `/api/bands/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
}

export function createBand(data) {
  return resourceRSAA(BAND_ACTION, `/api/bands/`, {
    method: 'POST',
    body: JSON.stringify({ band: data }),
  })
}

export function searchBands(query, page) {
  return basicGet(`/api/bands/search`, BAND_ACTION, { q: query, page: page })
}

export function getBandSharedGigs(id, page) {
  return basicGet(`/api/bands/${id}/shared_gigs`, BAND_ACTION, { page })
}

export function getBandCrewMemberPeople(id) {
  return resourceRSAA(BAND_ACTION, `/api/bands/${id}/crew_member_people`)
}
